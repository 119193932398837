import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfoBanner from '../../../Common/Components/InfoBanner';
import Skeleton from '../../../Common/Components/Skeleton';
import { getFunnelInformationAction } from '../../../redux/assessmentDetailVol2/actions';
import {
  clickedFunnelSelector,
  funnelIdSelector,
  funnelInfoSelector,
  funnelLoadingSelector,
  funnelOpenSelector,
  infoBannerErrorFlagSelector,
  infoBannerShowStateSelector,
  infoBannerTextSelector,
  isMoveEnabledFlagSelector,
  popupShowStateSelector,
  popupTypeStateSelector,
  rejectModalSelectedCountSelector,
  rejectModalSelector,
  rejectSuccessSelector,
  selectedAcceptedCandidateListSelector,
  selectedCandidateListSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  setFunnelOpen,
  setInfoBannerErrorFlag,
  setInfoBannerShowState,
  setInfoBannerText,
  setIsMoveEnabledFlag,
  setPopupShowState,
  setPopupTypeState,
  setRejectModalState,
  setRejectSuccesState
} from '../../../redux/assessmentDetailVol2/slice';
import styles from './Funnel.module.css';
import FunnelCategory from './FunnelCategory';
import FunnelPopup from './FunnelPopup';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';
import { moveCandidateToFunnel, useWindowSize } from '../../../utils/helpers';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import CloseButton from '../../../Common/Components/CloseButton';
import RejectCandidatesModal from '../Modals/RejectionMail/RejectCandidatesModal';
import RejectSuccessModal from '../Modals/RejectionMail/RejectSuccessModal';

export default function Funnel({ caID, webHeight, mobileHeight }) {
  const dispatch = useDispatch();
  const funnels = useSelector(funnelInfoSelector);
  const [hoverStyle, setHoverStyle] = useState();
  const [screenW, screenH] = useWindowSize();

  /* Popup related selectors */
  const popupShowStateRedux = useSelector(popupShowStateSelector);
  const popupTypeStateRedux = useSelector(popupTypeStateSelector);
  const clickedFunnelRedux = useSelector(clickedFunnelSelector);
  const selectedCandidateListRedux = useSelector(selectedCandidateListSelector);
  const selectedAcceptedCandidateListRedux = useSelector(selectedAcceptedCandidateListSelector);
  const selectedFunnelIdRedux = useSelector(funnelIdSelector);
  const loading = useSelector(funnelLoadingSelector);

  const [popupShowState2, setPopupShowState2] = useState(false);
  const [disableTriggerFlag, setDisableTriggerFlag] = useState(false);
  const [wrapperElement, setWrapperElement] = useState();
  const funnelOpen = useSelector(funnelOpenSelector);

  // Top-bottom blur for indicating scrollable funnel content
  // const [topShadow, setTopShadow] = useState(false);
  // const [bottomShadow, setBottomShadow] = useState(false);
  // const [wrapperWidth, setWrapperWidth] = useState();
  // const [wrapperHeight, setWrapperHeight] = useState();
  // const [funnelContainer, setFunnelContainer] = useState();
  const moveActive = useSelector(isMoveEnabledFlagSelector);

  useEffect(() => {
    if (popupShowStateRedux) {
      setPopupShowState2(true);
    } else if (!disableTriggerFlag) {
      setTimeout(() => {
        setPopupShowState2(false);
      }, 300);
    }
  }, [popupShowStateRedux]);

  /* Info banner related selectors */
  const infoBannerShowStateRedux = useSelector(infoBannerShowStateSelector);
  const infoBannerTextRedux = useSelector(infoBannerTextSelector);
  const infoBannerErrorFlagRedux = useSelector(infoBannerErrorFlagSelector);

  useEffect(() => {
    const funnelData = {
      CompanyAssessmentId: caID
    };
    dispatch(getFunnelInformationAction(funnelData));
  }, []);

  useEffect(() => {
    if (funnels) {
      const w = document.getElementById('funnelCatWrapper');
      setWrapperElement(w);

      if (w?.scrollHeight > w?.offsetHeight) {
        w?.classList?.add(`${styles.scrollPresent}`);
      } else {
        w?.classList?.remove(`${styles.scrollPresent}`);
      }
    }
  }, [funnels, screenW, funnelOpen]);

  // Cookie setter for tips popups
  useEffect(async () => {
    if (funnels) {
      if (
        document.cookie.split('; ').find(row => row.startsWith('scored=')) === undefined &&
        (funnels?.filter(f => f.funnelId === selectedFunnelIdRedux)[0]?.funnelName === 'Scored' ||
          selectedFunnelIdRedux === '')
      ) {
        dispatch(setPopupTypeState('Tips-Scored'));
        setTimeout(() => {
          dispatch(setPopupShowState(true));
        }, 500);
        document.cookie = `scored=scored;max-age=15552000;path=/`;
      }

      if (
        document.cookie.split('; ').find(row => row.startsWith('Notscored=')) === undefined &&
        funnels?.filter(f => f.funnelId === selectedFunnelIdRedux)[0]?.funnelName === 'Not Scored'
      ) {
        dispatch(setPopupTypeState('Tips-NotScored'));
        setTimeout(() => {
          dispatch(setPopupShowState(true));
        }, 500);
        document.cookie = `Notscored=Notscored;max-age=15552000; path=/`;
      }
    }
  }, [funnels, selectedFunnelIdRedux]);
  // Cookie setter for tips popups

  let counter = 0;

  const [mobileShow, setMobileShow] = useState(funnelOpen);

  useEffect(() => {
    setMobileShow(funnelOpen);
  }, [funnelOpen]);

  useEffect(() => {
    if (!mobileShow && screenW && screenW <= 576) {
      setTimeout(() => {
        dispatch(setFunnelOpen(false));
        dispatch(setIsMoveEnabledFlag(false));
      }, 200);
    }
  }, [mobileShow]);

  const showRejectionPopup = useSelector(rejectModalSelector);
  const [showRejectionPopup2, setShowRejectionPopup2] = useState(false);
  const showRejectionSuccess = useSelector(rejectSuccessSelector);
  const [showRejectionSuccess2, setShowRejectionSuccess2] = useState(false);

  useEffect(() => {
    if (showRejectionPopup) {
      setShowRejectionPopup2(true);
    } else {
      setTimeout(() => {
        setShowRejectionPopup2(false);
      }, 300);
    }
  }, [showRejectionPopup]);

  useEffect(() => {
    if (showRejectionSuccess) {
      setShowRejectionSuccess2(true);
    } else {
      setTimeout(() => {
        setShowRejectionSuccess2(false);
      }, 300);
    }
  }, [showRejectionSuccess]);

  const Popups = (
    <>
      {/* Funnel related popups */}
      {popupShowState2 && (
        <FunnelPopup
          popupType={popupTypeStateRedux}
          show={popupShowStateRedux}
          setShow={e => dispatch(setPopupShowState(e))}
          funnelInfo={clickedFunnelRedux}
          /* Allows Delete popup to show after Edit popup */
          setDeleteClicked={() => {
            setDisableTriggerFlag(true);
            dispatch(setPopupShowState(false));
            setTimeout(() => {
              dispatch(setPopupTypeState('Delete'));
              dispatch(setPopupShowState(true));
              setDisableTriggerFlag(false);
            }, 300);
          }}
          /* Allows Edit popup to show after Delete popup */
          setBackClicked={() => {
            setDisableTriggerFlag(true);
            dispatch(setPopupShowState(false));
            setTimeout(() => {
              dispatch(setPopupTypeState('Edit'));
              dispatch(setPopupShowState(true));
              setDisableTriggerFlag(false);
            }, 300);
          }}
          /* For selected candidate count */
          selectedCandidates={selectedCandidateListRedux}
          selectedAcceptedCandidates={selectedAcceptedCandidateListRedux}
          selectedFunnelId={selectedFunnelIdRedux}
        />
      )}
      <InfoBanner
        text={infoBannerTextRedux}
        show={infoBannerShowStateRedux}
        CustomIcon={infoBannerErrorFlagRedux ? Error : null}
        setStatus={() => {
          dispatch(setInfoBannerShowState(false));
          dispatch(setInfoBannerText());
          dispatch(setInfoBannerErrorFlag(false));
        }}
        bannerStyle={{
          border: infoBannerErrorFlagRedux
            ? '1px solid rgba(221, 58, 58, 0.1)'
            : '1px solid rgba(74, 195, 137, 0.1)'
        }}
      />
    </>
  );

  const WebFunnel = (
    <div
      className={`${styles.container} `}
      style={{ height: `calc(100vh - ${webHeight}px)` }}
      id="funnelContainer"
    >
      {loading ? (
        <Skeleton type="funnel" />
      ) : (
        <div
          onMouseEnter={() => {
            if (wrapperElement.scrollHeight > wrapperElement.offsetHeight) {
              wrapperElement.classList.add(`${styles.mouseEnter}`);
            }
          }}
          onMouseLeave={() => {
            if (wrapperElement.scrollHeight > wrapperElement.offsetHeight) {
              wrapperElement.classList.remove(`${styles.mouseEnter}`);
            }
          }}
          onDragEnter={e => {
            e.preventDefault();
            counter += 1;
            if (wrapperElement.scrollHeight > wrapperElement.offsetHeight) {
              wrapperElement.classList.add(`${styles.mouseEnter}`);
            }
          }}
          onDragOver={e => {
            e.preventDefault();
            if (wrapperElement.scrollHeight > wrapperElement.offsetHeight) {
              wrapperElement.classList.add(`${styles.mouseEnter}`);
            }
          }}
          onDragLeave={e => {
            counter -= 1;
            if (counter === 0) {
              wrapperElement.classList.remove(`${styles.mouseEnter}`);
            }
          }}
          className={`${styles.CategoryWrapper}`}
          style={{ maxHeight: `calc(100vh - ${webHeight}px)`, hoverStyle }}
          id="funnelCatWrapper"
        >
          <FunnelCategory funnels={funnels} categoryOrder={10} />
          <FunnelCategory funnels={funnels} categoryOrder={20} />
          <FunnelCategory
            funnels={funnels}
            categoryOrder={30}
            sortableFunnels={funnels.filter(funnel => funnel.funnelCategory === 30)}
            draggable
          />
          <FunnelCategory funnels={funnels} categoryOrder={40} />
        </div>
      )}

      {Popups}
    </div>
  );

  const MobileFunnel = (
    <MobileModal
      id="mobile-funnel"
      onClose={() => setMobileShow(false)}
      show={mobileShow}
      // outsideClickClose
      outsideClickClose={!(popupShowStateRedux || showRejectionPopup || showRejectionSuccess)}
      backdropStyle={{
        backgroundColor: (!popupShowStateRedux || moveActive) && 'transparent',
        // backgroundColor: ((!popupShowStateRedux && !showRejectionSuccess && !showRejectionPopup) || moveActive ) && 'transparent',
        overflowY: 'hidden',
        transition: mobileShow && !popupShowState2 && 'background-color 0.3s linear 0.125s'
        // transition: mobileShow && (!popupShowState2  || !showRejectionSuccess2 || !showRejectionPopup2) && 'background-color 0.3s linear 0.125s'
      }}
      contentStyle={{
        height: `${mobileHeight}px`,
        minWidth: '375px',
        overflow: 'hidden',
        borderRadius: '0px'
      }}
    >
      <div
        className={`${styles.container} `}
        style={{ maxHeight: `${mobileHeight}px`, height: `${mobileHeight}px` }}
        id="funnelContainer"
      >
        <div className={styles.mobileFunnelHeader}>
          <div className={styles.mobileFunnelHeaderText}>
            {moveActive ? 'Select a stage to move candidates' : 'Select or add a stage'}
          </div>
          <CloseButton width={28} height={28} onClick={() => setMobileShow(false)} />
        </div>
        {loading ? (
          <Skeleton type="funnel" />
        ) : (
          <div
            className={`${styles.CategoryWrapper}`}
            style={{ maxHeight: `${mobileHeight - 50}px` }}
            id="funnelCatWrapper"
          >
            <FunnelCategory
              funnels={funnels}
              categoryOrder={10}
              isMobile
              mobileCloseFunnel={() => setMobileShow(false)}
            />
            <FunnelCategory
              funnels={funnels}
              categoryOrder={20}
              isMobile
              mobileCloseFunnel={() => setMobileShow(false)}
            />
            <FunnelCategory
              funnels={funnels}
              categoryOrder={30}
              sortableFunnels={funnels.filter(funnel => funnel.funnelCategory === 30)}
              isMobile
              mobileCloseFunnel={() => setMobileShow(false)}
            />
            <FunnelCategory
              funnels={funnels}
              categoryOrder={40}
              isMobile
              mobileCloseFunnel={() => setMobileShow(false)}
            />
          </div>
        )}
      </div>

      {Popups}
    </MobileModal>
  );

  const [isMailSentFlag, setIsMailSentFlag] = useState(false);
  const selectedCandCountForReject = useSelector(rejectModalSelectedCountSelector);
  const { id } = useParams();

  return (
    <>
      {screenW > 576 ? WebFunnel : MobileFunnel}

      {showRejectionPopup2 ? (
        <RejectCandidatesModal
          assessmentDetailFlag
          show={showRejectionPopup}
          onClose={() => dispatch(setRejectModalState(false))}
          showSuccess={bool => dispatch(setRejectSuccesState(bool))}
          setMailSentFlag={setIsMailSentFlag}
          moveMethod={bool =>
            moveCandidateToFunnel(
              funnels.filter(e => e.funnelCategory === 40 && e.funnelName === 'Rejected')[0],
              id,
              selectedCandidateListRedux?.map(e => e.userId),
              selectedAcceptedCandidateListRedux?.map(e => e.userId),
              dispatch,
              selectedFunnelIdRedux,
              bool
            )
          }
        />
      ) : null}
      {showRejectionSuccess2 ? (
        <RejectSuccessModal
          show={showRejectionSuccess}
          onClose={() => dispatch(setRejectSuccesState(false))}
          isMailSent={isMailSentFlag}
          selectedCandCount={selectedCandCountForReject}
        />
      ) : null}
    </>
  );
}
