/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/SettingsBar.module.css';
import { ReactComponent as PersonalIcon } from '../../images/personalSettings.svg';
import { ReactComponent as CompanyIcon } from '../../images/companySettings.svg';
import { viewHeight } from '../../redux/user/selectors';
import {
  addNewUserHighlight,
  currentPlanRedux,
  onboardingStepRedux
} from '../../redux/plan/selectors';
import { userSelector } from '../../redux/auth/selectors';
import { ReactComponent as Back } from '../../images/backButtonSettings.svg';
import Highlight from '../../Common/Components/Highlight';
import { setTeamHighlight } from '../../redux/plan/slice';
import { useWindowSize } from '../../utils/helpers';

const BarItem = ({ text, category, icon, disabled, title, style, notClickable }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onboardingStep = useSelector(onboardingStepRedux);

  const { place } = useParams();
  return (
    <div className={styles.barItemContainer}>
      {icon && (
        <div
          onClick={() => {
            if (title) {
              history.goBack();
            }
          }}
        >
          {icon}
        </div>
      )}
      <div
        onClick={() => {
          if (!category && !disabled && !notClickable) {
            history.replace(`/settings/${text.toLowerCase().replace(' ', '')}`);
          }
          if (onboardingStep === 2) {
            dispatch(setTeamHighlight(false));
          }
        }}
        className={`${
          title
            ? styles.title
            : category
            ? styles.category
            : place === text.toLowerCase().replace(' ', '')
            ? `${styles.barItemText} ${styles.active} `
            : styles.barItemText
        } ${disabled && styles.disabled} ${text === 'Company' && styles.companyText}`}
      >
        {text}
      </div>
    </div>
  );
};

function SettingsBar() {
  const height = useSelector(viewHeight);
  const currentPlan = useSelector(currentPlanRedux);
  const currentUser = useSelector(userSelector);
  const onboardingStep = useSelector(onboardingStepRedux);
  const addNewUserHighlighted = useSelector(addNewUserHighlight);
  const dispatch = useDispatch();

  useEffect(() => {
    if (onboardingStep === 2) {
      dispatch(setTeamHighlight(true));
    }
  }, [onboardingStep]);
  return (
    <div className={styles.barWrapper}>
      <BarItem
        style={{ marginTop: '1.5rem' }}
        text="Settings"
        title
        icon={<Back className={styles.titleIcon} />}
        notClickable
      />

      <BarItem text="Personal" category icon={<PersonalIcon className={styles.icon} />} />
      <BarItem
        text="My profile"
        icon={<PersonalIcon className={styles.icon} style={{ opacity: '0' }} />}
      />

      <BarItem
        text="Company"
        category
        icon={<CompanyIcon className={`${styles.icon} ${styles.companyText}`} />}
      />

      <BarItem
        text="General"
        icon={<PersonalIcon className={styles.icon} style={{ opacity: '0' }} />}
      />

      {/* {onboardingStep === 2 && !addNewUserHighlighted ? ( */}
      <div className={styles.barItemContainer}>
        <PersonalIcon className={styles.icon} style={{ opacity: '0' }} />
        <Highlight
          style={{ width: 'fit-content' }}
          child={<BarItem text="Team" />}
          isHighlighted={onboardingStep === 2 && !addNewUserHighlighted}
          borderRadius={12}
          paddingPixel={4}
        />
      </div>
      {/* ) : (
        <BarItem
          text="Team"
          icon={<PersonalIcon className={styles.icon} style={{ opacity: '0' }} />}
        />
      )} */}

      <BarItem
        text="Plan"
        icon={<PersonalIcon className={styles.icon} style={{ opacity: '0' }} />}
      />
      <BarItem
        text="Billing"
        disabled={
          (!currentUser?.isAdmin && currentUser?.userGroupType !== 'Super Admin') ||
          currentPlan?.name === 'Free Trial' ||
          currentPlan?.isCustom
        }
        icon={<PersonalIcon className={styles.icon} style={{ opacity: '0' }} />}
      />
    </div>
  );
}
function SettingsTabSwitch() {
  const currentPlan = useSelector(currentPlanRedux);
  const currentUser = useSelector(userSelector);
  const tabs = ['My Profile', 'General', 'Team', 'Plan', 'Billing'];
  const [selectedTabIndex, setSelectedTabIndex] = useState();
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);
  const [windowW] = useWindowSize();
  const { place } = useParams();
  const history = useHistory();
  const [leftBlur, setLeftBlur] = useState(false);
  const [rightBlur, setRightBlur] = useState(false);

  function handleBlurShow() {
    const tabs = document
      .getElementById('scrollable-mobile-settings-tab-switch')
      .getBoundingClientRect();
    const settingsbar = document.getElementById('settings-bar-container').getBoundingClientRect();
    const billingTab = document
      .getElementById('settings-mobile-tab-Billing')
      .getBoundingClientRect();
    if (tabs.right < billingTab.right) {
      setRightBlur(true);
    } else {
      setRightBlur(false);
    }
    if (tabs.left > settingsbar.left) {
      setLeftBlur(true);
    } else {
      setLeftBlur(false);
    }
  }

  useEffect(() => {
    tabs.forEach((tab, index) => {
      if (tab.replace(' ', '').toLowerCase() === place) {
        setSelectedTabIndex(index);
      }
    });
  }, [place]);

  useEffect(() => {
    handleBlurShow();
    function setTabPosition() {
      const currentTab = tabsRef.current[selectedTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [selectedTabIndex, windowW]);

  const disabled =
    (!currentUser?.isAdmin && currentUser?.userGroupType !== 'Super Admin') ||
    currentPlan?.name === 'Free Trial' ||
    currentPlan?.isCustom;

  return (
    <>
      <div style={{ opacity: leftBlur ? 1 : 0 }} className={styles.blurLeft} />
      <div
        id="scrollable-mobile-settings-tab-switch"
        className={styles.mobileWrapper}
        onScrollCapture={() => handleBlurShow()}
      >
        <div className={styles.container} id="settings-bar-container">
          {tabs?.map((tab, index) => (
            <>
              <div
                id={`settings-mobile-tab-${tab}`}
                ref={el => (tabsRef.current[index] = el)}
                className={`${styles.mobileTabWrapper} ${
                  index === selectedTabIndex ? styles.active : ''
                } ${disabled && tab === 'Billing' && styles.disabled}`}
                onClick={() => {
                  if (!(disabled && tab === 'Billing')) {
                    setSelectedTabIndex(index);
                    history.replace(`/settings/${tab.toLowerCase().replace(' ', '')}`);
                  }
                }}
              >
                {tab}
              </div>
              <span
                className={styles.borderBottom}
                style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
              />
            </>
          ))}
        </div>
      </div>
      <div style={{ opacity: rightBlur ? 1 : 0 }} className={styles.blurRight} />
    </>
  );
}

function SettingsMenu() {
  const [screenW] = useWindowSize();
  return <>{screenW > 576 ? <SettingsBar /> : <SettingsTabSwitch />}</>;
}
export default SettingsMenu;
