import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './SignupComponent.module.css';
import { ReactComponent as Tick } from '../../Images/purpleTick.svg';
import { ReactComponent as CoensioLogo } from '../../../images/Coensio_newLogo.svg';
import EmailText from '../../../Common/Components/EmailText';
import CustomButton from '../../../Common/Components/CustomButton';
import { quickEmailVerificationAction, signupByEmailAction } from '../../../redux/signup/actions';
import { signupEmailRedux, signUpStep1ErrorMessageRedux } from '../../../redux/signup/selectors';
import { setSignupEmail, setSignUpStep1ErrorMessage } from '../../../redux/signup/slice';
import route from '../../../constant/route';
import isEmail from '../../../utils/isEmail';

import mixpanel from '../../../utils/mixpanel';

const CatchPhrase = ({ text }) => {
  return (
    <>
      <div className={styles.catchPhrase}>
        <div>
          <Tick className={styles.tick} />
        </div>

        <div className={styles.catchphraseText}>{text}</div>
      </div>
    </>
  );
};

const SignupComponent = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const errorMessage = useSelector(signUpStep1ErrorMessageRedux);
  const [error, setError] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const signupEmail = useSelector(signupEmailRedux);

  useEffect(() => {
    setEmail('');
    setError(false);

    /* --------------- Mixpanel pageview --------------- */

    mixpanel.track('Signup Page 1 - Pageview');

    /* ------------------------------------------------- */
  }, []);

  const signUpByEmailRequest = async () => {
    setButtonLoading(true);
    dispatch(setSignupEmail(email));
    const data = {
      email: email
    };
    const validCheck = await dispatch(quickEmailVerificationAction(data));

    if (validCheck.meta.requestStatus === 'fulfilled') {
      const parsedResponse = JSON.parse(validCheck?.payload?.response);

      const isInvalidEmailFlag =
        parsedResponse?.disposable === 'true' ||
        parsedResponse?.result !== 'valid' ||
        parsedResponse?.free === 'true' ||
        parsedResponse?.domain?.includes('edu.tr');

      if (!isInvalidEmailFlag) {
        const resp = await dispatch(signupByEmailAction(data));
        if (resp.meta.requestStatus !== 'fulfilled') {
          setButtonLoading(false);
        }
      } else {
        dispatch(setSignUpStep1ErrorMessage('Please enter a corporate email'));
        setButtonLoading(false);
      }
    } else {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    setError(false);
    dispatch(setSignUpStep1ErrorMessage(null));
  }, [email]);

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [errorMessage]);

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.InnerCard}>
          <div className={styles.Left}>
            <CoensioLogo className={styles.logo} />
            <CatchPhrase text="Optimize intial screening time by 88%" />
            <CatchPhrase text="Catch potentially overlooked candidates without bias" />
            <CatchPhrase text="Eliminate the candidates which are not suitable for the job" />
          </div>
          <div className={styles.Right}>
            <div className={styles.signup}>
              <label className={styles.trialText}>Start your free trial!</label>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1.7rem' }}>
                <EmailText
                  value={email}
                  setValue={setEmail}
                  placeholder="Email*"
                  showInvisibleError
                  errorMessage={error}
                  trimWhiteSpaces
                />
              </div>
              <CustomButton
                isDisabled={!email || !isEmail(email)}
                textField="Sign Up"
                customStyle={{ width: '100%', marginBottom: '3.2rem' }}
                size="medium"
                buttonOnClick={() => signUpByEmailRequest()}
                loading={buttonLoading}
                enterEnabled
              />
            </div>
            <div className={styles.login}>
              <div className={styles.text}>You already have an account?</div>
              <Link to={route.login}>
                <div className={styles.loginText}>&nbsp;Login</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
