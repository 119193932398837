import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from '../Styles/FilterColumn.module.css';
import { ReactComponent as DropDown } from '../../../Candidate/Images/arrow_assessment.svg';
import SearchBar from '../../../Common/Components/SearchBar';
import FilterItem from './FilterItem';
import Checkbox from '../../../Common/Components/Checkbox';
import {
  customAssessmentId,
  filterState,
  jobDescription,
  pageIndex,
  skillsOfJobRole
} from '../../../redux/assessmentsVol2/selectors';
import {
  getQuestionByFilterAction,
  getSkillsOfJobRoleAction
} from '../../../redux/assessmentsVol2/actions';
import {
  setFilterState,
  setPageIndex,
  setFilterLoading,
  resetCandidateQuestionList
} from '../../../redux/assessmentsVol2/slice';
import CustomDropdown from '../../../Common/Components/CustomDropdown/CustomDropdown';
import CustomButton from '../../../Common/Components/CustomButton';
import { useWindowSize } from '../../../utils/helpers';
import { currentPlanRedux } from '../../../redux/plan/selectors';

import mixpanel from '../../../utils/mixpanel';
import { userSelector } from '../../../redux/auth/selectors';

const QuestionItem = ({ item, filter, id }) => {
  const [checkBoxState, setCheckBoxState] = React.useState(false);
  const dispatch = useDispatch();
  // 1 multipleChoice
  // 2 Coding
  // 3 FreeText

  useEffect(() => {
    const type = item === 'Coding' ? 2 : item === 'Free Text' ? 3 : 1;
    if (filter?.questionTypeIds.indexOf(type) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    const type = item === 'Coding' ? 2 : item === 'Free Text' ? 3 : 1;
    let temp = [];
    if (checkBoxState) {
      temp = [...filter.questionTypeIds];
      temp.splice(temp.indexOf(type), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      questionTypeIds: checkBoxState ? temp : [...filter.questionTypeIds, type]
    };

    dispatch(setFilterState(data));
    // dispatch(getQuestionByFilterAction(data));
  };

  return (
    <div className={styles.questionRow}>
      <Checkbox myChecked={checkBoxState} myOnClick={onClickBehavior} />
      <div className={styles.question} onClick={onClickBehavior}>
        {item}
      </div>
    </div>
  );
};

const DifficultyItem = ({ item, filter, id }) => {
  const [checkBoxState, setCheckBoxState] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const type = item;
    if (filter?.difficultyList.indexOf(type) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    const type = item;
    let temp = [];
    if (checkBoxState) {
      temp = [...filter.difficultyList];
      temp.splice(temp.indexOf(type), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      difficultyList: checkBoxState ? temp : [...filter.difficultyList, type]
    };

    dispatch(setFilterState(data));
    // dispatch(getQuestionByFilterAction(data));
  };

  return (
    <div className={styles.questionRow}>
      <Checkbox myChecked={checkBoxState} myOnClick={onClickBehavior} />
      <div className={styles.question} onClick={onClickBehavior}>
        {item}
      </div>
    </div>
  );
};

const LanguageItem = ({ item, filter, id, filterKey }) => {
  const [checkBoxState, setCheckBoxState] = React.useState(false);
  const dispatch = useDispatch();
  // 1 multipleChoice
  // 2 Coding
  // 3 FreeText

  useEffect(() => {
    if (filter && filter[filterKey]?.indexOf(item === 'EN' ? 'EN' : item) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    let temp = [];
    if (checkBoxState) {
      temp = [...filter[filterKey]];
      temp.splice(temp.indexOf(item === 'EN' ? 'EN' : item), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      [filterKey]: checkBoxState ? temp : [...filter[filterKey], item === 'EN' ? 'EN' : item]
    };

    dispatch(setFilterState(data));
    // dispatch(getQuestionByFilterAction(data));
  };

  return (
    <div className={styles.questionRow}>
      <Checkbox myChecked={checkBoxState} myOnClick={onClickBehavior} />
      <div className={styles.question} onClick={onClickBehavior}>
        {item}
      </div>
    </div>
  );
};

const PayStatusItem = ({ item, filter, id, filterKey, premiumFlag }) => {
  const [checkBoxState, setCheckBoxState] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let type;
    switch (item) {
      case 'Free':
        type = 20;
        break;
      case 'Premium':
        type = premiumFlag ? 10 : 30;
        break;
      case 'Purchased':
        type = 10;
        break;
      default:
        break;
    }
    if (filter?.purchaseTypes?.indexOf(type) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    let type;
    switch (item) {
      case 'Free':
        type = 20;
        break;
      case 'Premium':
        type = premiumFlag ? 10 : 30;
        break;
      case 'Purchased':
        type = 10;
        break;
      default:
        break;
    }
    let temp = [];
    if (checkBoxState) {
      temp = [...filter.purchaseTypes];
      temp.splice(temp.indexOf(type), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      purchaseTypes: checkBoxState ? temp : [...filter.purchaseTypes, type]
    };

    dispatch(setFilterState(data));
  };

  return (
    <div className={styles.questionRow}>
      <Checkbox myChecked={checkBoxState} myOnClick={onClickBehavior} />
      <div className={styles.question} onClick={onClickBehavior}>
        {item}
      </div>
    </div>
  );
};

export default function FilterColumn({
  jobDescriptionList,
  setResetScrollPositionFlag,
  setResetFilterFlag
}) {
  const [dropDownState, setDropDownState] = React.useState(true);
  const [questionDropDown, setQuestionDropDown] = React.useState(false);
  const [difficultyDropdown, setDifficultyDropdown] = useState(false);
  const [payDropdown, setPayDropdown] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState(false);
  const filter = useSelector(filterState);
  const dispatch = useDispatch();
  const { id } = useParams();
  // const id = useSelector(customAssessmentId);
  const [searchTerm, setSearchTerm] = useState('');
  const jobRoleList = useSelector(jobDescription)?.jobDescriptionList;
  const skillsOfSelectedJobRole = useSelector(skillsOfJobRole);
  const [changeInList, setChangeInList] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);

  const myPageIndex = useSelector(pageIndex);
  const [resetJobRole, setResetJobRole] = useState(false);
  const [jobRole, setJobRole] = useState();

  const [resetDropdownSelection, setResetDropdownSelection] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const [dropdownWrapperHeight, setDropdownWrapperHeight] = useState();
  const [screenWidth, screenHeight] = useWindowSize();
  const [filterApplied, setFilterApplied] = useState();

  const [resetExtendedState, setResetExtendedState] = useState(false);

  const currentPlan = useSelector(currentPlanRedux);
  const [premiumFlag, setPremiumFlag] = useState(false);

  const [innerList, setInnerList] = useState(jobDescriptionList);

  useEffect(() => {
    if (jobDescriptionList && searchTerm?.length >= 2) {
      setInnerList(
        Object.entries(jobDescriptionList)?.filter(
          item =>
            item[0].toLowerCase().includes(searchTerm?.toLocaleLowerCase('tr')) ||
            item[1]
              .map(label => label.toLocaleLowerCase('tr'))
              .some(elem => elem.includes(searchTerm?.toLocaleLowerCase('tr')))
        )
      );
      setShowMore(true);
    } else if (jobDescriptionList) {
      setInnerList(Object.entries(jobDescriptionList));
    }
  }, [searchTerm, jobDescriptionList]);

  // Mixpanel variable
  const user = useSelector(userSelector);

  const resetFilterFunc = async () => {
    // Bu nasıl olur daha iyi bir şekilde
    dispatch(resetCandidateQuestionList());
    setResetFilterFlag(true);
    await dispatch(
      setFilterState({
        pageSize: 50,
        currentPage: 1,
        rootAssessmentId: id,
        skills: [],
        questionTypeIds: [],
        searchParameter: '',
        difficultyList: [],
        questionLanguages: [],
        purchaseTypes: []
      })
    );
    setShowMore(true);
    setResetExtendedState(!resetExtendedState);
    setSearchTerm('');
    setQuestionDropDown(false);
    setDifficultyDropdown(false);
    setPayDropdown(false);
    setLanguageDropdown(false);
    setJobRole(null);
    setResetJobRole(true);
  };

  const fetchDefaultQuestionList = () => {
    setChangeInList(!changeInList);
    dispatch(setPageIndex(1));
    dispatch(
      getQuestionByFilterAction({
        pageSize: 50,
        currentPage: 1,
        rootAssessmentId: id,
        skills: [],
        questionTypeIds: [],
        searchParameter: '',
        difficultyList: [],
        questionLanguages: [],
        purchaseTypes: []
      })
    );
  };

  useEffect(() => {
    resetFilterFunc();

    return () => {
      resetFilterFunc();
    };
  }, []);

  async function applyFilter() {
    setResetScrollPositionFlag(true);
    /* Mixpanel Apply Filter Click */
    mixpanel.track('Question Based Assessment - Apply Filter Button Click', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      ),
      'Difficulty List': filter.difficultyList || [],
      'Question Language List': filter.questionLanguages || [],
      'Question Types List':
        filter.questionTypeIds.length > 0
          ? filter.questionTypeIds.map(e => {
              if (e === 2) {
                return 'Coding';
              }
              if (e === 3) {
                return 'Free Text';
              }
              if (e === 1) {
                return 'Multiple Choice';
              }
              return e;
            })
          : [],
      'Search Parameter': filter.searchParameter || '',
      'Skills List': filter.skills || [],
      'Question Pay Status List':
        filter.purchaseTypes.length > 0
          ? filter.purchaseTypes.map(e => {
              if (e === 10 && !premiumFlag) {
                return 'Purchased';
              }
              if (e === 10 && premiumFlag) {
                return 'Premium';
              }
              if (e === 20) {
                return 'Free';
              }
              if (e === 30) {
                return 'Purchased';
              }
              return e;
            })
          : []
    });
    /* --------------------------- */
    const a = await dispatch(getQuestionByFilterAction(filter));
  }

  useEffect(() => {
    if (resetJobRole) {
      setResetJobRole(false);
    }
  }, [resetJobRole]);

  function handleDropdownList() {
    jobRoleList?.forEach((role, index) => {
      const a = { id: index, key: index, title: role.item1 };
      setDropdownList(prev => [...prev, a]);
    });
    setChangeInList(!changeInList);
  }

  useEffect(() => {
    setDropdownList([]);
    handleDropdownList();
    setChangeInList(!changeInList);
  }, [jobRoleList]);

  useEffect(() => {
    setChangeInList(!changeInList);
  }, [dropdownList]);

  useEffect(async () => {
    if (skillsOfSelectedJobRole) {
      let temp = [];
      const a = Object.entries(skillsOfSelectedJobRole).forEach(x => {
        temp = [...temp, ...x[1]];
      });

      await dispatch(setFilterState({ ...filter, rootAssessmentId: id, skills: [...temp] }));
    }
  }, [skillsOfSelectedJobRole]);
  useEffect(async () => {
    if (jobRole) {
      const data = jobRoleList.filter(a => a.item1 === jobRole.title)[0].item2;
      const b = await dispatch(getSkillsOfJobRoleAction(data));
    }
  }, [jobRole]);

  const filterCategory = item => {
    let flag = false;

    if (item[0].toLocaleLowerCase('tr').includes((searchTerm?.length >= 2 ? searchTerm : '').toLocaleLowerCase('tr'))) {
      flag = true;
    } else {
      item[1].forEach(e => {
        if (e.toLocaleLowerCase('tr').includes((searchTerm?.length >= 2 ? searchTerm : '').toLocaleLowerCase('tr'))) {
          flag = true;
        }
      });
    }

    return flag;
  };

  useEffect(() => {
    const wrapperElemHeight = document.getElementById('wrapper')?.getBoundingClientRect().height;
    setDropdownWrapperHeight(wrapperElemHeight);
  }, [dropdownList, screenWidth]);

  useEffect(() => {
    if (
      filter?.searchParameter !== '' ||
      filter?.skills?.length > 0 ||
      filter?.questionLanguages?.length > 0 ||
      filter?.purchaseTypes?.length > 0 ||
      filter?.difficultyList?.length > 0 ||
      filter?.questionTypeIds?.length > 0 ||
      searchTerm !== '' ||
      filter?.skills?.length > 0
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [JSON.stringify(filter), searchTerm]);

  useEffect(() => {
    if (currentPlan) {
      setPremiumFlag(currentPlan.currentBundleItem.filter(x => x.code === 60)[0]);
    } else {
      setPremiumFlag(false);
    }
  }, [currentPlan]);

  return (
    // <div className={styles.wrapper}>
    <div className={`${styles.container}`}>
      <div className={styles.headerBorder}>
        <div className={styles.header}>
          <div className={styles.title}>Filter by</div>
          <div
            className={styles.reset}
            onClick={async () => {
              await resetFilterFunc();
              /* Mixpanel Reset Filter Click */
              mixpanel.track('Question Based Assessment - Reset Filter Button Click', {
                'User Id': user.userId,
                'Company Name': user.companyName,
                'Name Surname': user.userNameSurname,
                'Root Assessment Id': id,
                'Root Assessment Name': localStorage.getItem(
                  'Mixpanel_AssessmentCustomizationRootAssessmentName'
                )
              });
              /* --------------------------- */
              fetchDefaultQuestionList();
            }}
          >
            Reset Filter
          </div>
        </div>
      </div>
      <div className={styles.headerTitle}>POSITION</div>
      <div
        style={{
          width: 'calc(100% - 30px)',
          height: dropdownWrapperHeight,
          marginTop: 20,
          borderRadius: '10px',
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: '#F4F4F4'
        }}
      >
        {dropdownList && dropdownList.length !== 0 && (
          <CustomDropdown
            placeholder="Select a job role"
            title="Select a Job Role"
            visibleItemNo={5}
            list={dropdownList}
            setSelectedOption={setJobRole}
            changeInList={changeInList}
            searchBar
            resetSelected={resetJobRole}
            wrapperStyle={{ width: '100%' }}
            containerStyle={{
              width: '100%',
              maxHeight: '50px',
              justifyContent: 'space-between',
              paddingRight: 'calc(10px - 1rem)'
            }}
          />
        )}
      </div>
      <div
        className={styles.skillDropdown}
        onClick={() => {
          setDropDownState(!dropDownState);
        }}
      >
        <div className={styles.dropDownSkills}>Skills</div>
        <DropDown className={`${styles.icon} ${dropDownState ? styles.extented : ''}`} />
      </div>
      {/* <CustomScrollDiv> */}
      <div className={styles.skillContainer} style={dropDownState ? {} : { width: 0, height: 0 }}>
        <div className={styles.search}>
          <SearchBar
            inputFieldValue={searchTerm}
            inputFieldOnChange={event => {
              setSearchTerm(event.target.value);
            }}
            inputFieldPlaceholder="Search"
            isExtended={1}
            size="small"
            color="white"
          />
        </div>
        {innerList &&
          innerList?.map((item, index) => {
            return (
              filterCategory(item) &&
              (!showMore || index <= 5) && (
                <FilterItem
                  searchTerm={searchTerm}
                  id={id}
                  key={index}
                  filter={filter}
                  item={item}
                  index={index}
                  isExtended={resetExtendedState}
                />
              )
            );
          })}
        {innerList?.length > 6 && (
          <div
            className={styles.showMore}
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            {showMore ? 'show more' : 'show less'}
          </div>
        )}
      </div>

      <div
        className={styles.skillDropdown}
        onClick={() => {
          setQuestionDropDown(!questionDropDown);
        }}
      >
        <div className={styles.dropDownQuestions}>Question Type</div>
        <DropDown className={`${styles.QuestionIcon} ${questionDropDown ? styles.extented : ''}`} />
      </div>
      <div
        className={styles.skillContainer}
        style={questionDropDown ? { marginTop: '14px' } : { width: 0, height: 0 }}
      >
        <QuestionItem item="Coding" filter={filter} id={id} />
        <QuestionItem item="Free Text" filter={filter} id={id} />
        <QuestionItem item="Multiple Choice" filter={filter} id={id} />
      </div>
      <div
        className={styles.skillDropdown}
        onClick={() => {
          setDifficultyDropdown(!difficultyDropdown);
        }}
      >
        <div className={styles.dropDownQuestions}>QUESTION DIFFICULTY</div>
        <DropDown
          className={`${styles.QuestionIcon} ${difficultyDropdown ? styles.extented : ''}`}
        />
      </div>
      <div
        className={styles.skillContainer}
        style={difficultyDropdown ? { marginTop: '14px' } : { width: 0, height: 0 }}
      >
        <DifficultyItem filterKey="difficultyList" item="Easy" filter={filter} id={id} />
        <DifficultyItem filterKey="difficultyList" item="Medium" filter={filter} id={id} />
        <DifficultyItem filterKey="difficultyList" item="Hard" filter={filter} id={id} />
      </div>

      <div
        className={styles.skillDropdown}
        onClick={() => {
          setPayDropdown(!payDropdown);
        }}
      >
        <div className={styles.dropDownQuestions}>QUESTION STATUS</div>
        <DropDown className={`${styles.QuestionIcon} ${payDropdown ? styles.extented : ''}`} />
      </div>
      <div
        className={styles.skillContainer}
        style={payDropdown ? { marginTop: '14px' } : { width: 0, height: 0 }}
      >
        <PayStatusItem
          filterKey="purchaseTypes"
          item="Free"
          filter={filter}
          id={id}
          premiumFlag={premiumFlag}
        />
        {!premiumFlag && (
          <PayStatusItem
            filterKey="purchaseTypes"
            item="Purchased"
            filter={filter}
            id={id}
            premiumFlag={premiumFlag}
          />
        )}
        <PayStatusItem
          filterKey="purchaseTypes"
          item="Premium"
          filter={filter}
          id={id}
          premiumFlag={premiumFlag}
        />
      </div>

      <div
        className={styles.skillDropdown}
        onClick={() => {
          setLanguageDropdown(!languageDropdown);
        }}
      >
        <div className={styles.dropDownQuestions}>QUESTION LANGUAGE</div>
        <DropDown className={`${styles.QuestionIcon} ${languageDropdown ? styles.extented : ''}`} />
      </div>
      <div
        className={styles.skillContainer}
        style={languageDropdown ? { marginTop: '14px' } : { width: 0, height: 0 }}
      >
        <LanguageItem filterKey="questionLanguages" item="TR" filter={filter} id={id} />
        <LanguageItem filterKey="questionLanguages" item="EN" filter={filter} id={id} />
        <LanguageItem filterKey="questionLanguages" item="DE" filter={filter} id={id} />
        <LanguageItem filterKey="questionLanguages" item="RU" filter={filter} id={id} />
        <LanguageItem filterKey="questionLanguages" item="IT" filter={filter} id={id} />
        <LanguageItem filterKey="questionLanguages" item="AR" filter={filter} id={id} />
      </div>

      <div className={styles.buttonWrapper} style={{ pointerEvents: 'none' }}>
        <CustomButton
          customStyle={{ backgroundColor: filterApplied && 'rgba(116, 88, 211, 0.2) ' }}
          textField="Apply Filter"
          type={3}
          size="small"
          style={{ fontSize: '16px', width: '279px', pointerEvents: 'auto' }}
          buttonOnClick={() => {
            dispatch(setFilterLoading(true));
            applyFilter();
            dispatch(setPageIndex(1));
          }}
        />
      </div>
    </div>
    // </div>
  );
}
