import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from '../Styles/AuthorizeUser.module.css';
import { AssessmentName } from '../../../redux/addAssessment/selectors';
import CustomButton from '../../../Common/Components/CustomButton';
import AuthorizeUserList from './AuthorizeUserList';
import { userSelector } from '../../../redux/auth/selectors';
import { getUserListByCompanyAction } from '../../../redux/assessmentsVol2/actions';
import { ReactComponent as InfoIcon } from '../../../images/purpleInfoIcon.svg';

import mixpanel from '../../../utils/mixpanel';
import { useWindowSize } from '../../../utils/helpers';

export default function AuthorizeUser({ id }) {
  const assessmentName = useSelector(AssessmentName);
  const dispatch = useDispatch();
  const history = useHistory();
  const myUser = useSelector(userSelector);
  const [windowW, windowH] = useWindowSize();

  useEffect(() => {
    if (myUser) {
      dispatch(getUserListByCompanyAction({ CompanyId: myUser.companyId }));
    }
  }, [myUser]);

  /* Mixpanel pageview */
  useEffect(() => {
    mixpanel.track('Create Assessment Page - Authorize User Pageview', {
      'User Id': myUser.userId,
      'Company Name': myUser.companyName,
      'Name Surname': myUser.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      )
    });
  }, []);

  return (
    <div className={styles.container}>
      <div>
        {windowW > 700 && <div className={styles.title}>Authorize User</div>}
        <div className={styles.subtitle}>Please select users to authorize for this assesment.</div>
        <AuthorizeUserList userRole="rec" style={{ marginBottom: '16px' }} />
        <AuthorizeUserList userRole="hr" />
        {windowW > 700 && (
          <div className={styles.purpleTextWrapper}>
            {/* <span className={styles.okayImg} /> */}
            <InfoIcon style={{ marginRight: '15px' }} />
            <span className={styles.purpleText}>
              Don&apos;t forget to authorize users. Only selected users can see the candidates.
            </span>
          </div>
        )}
      </div>
      <div className={styles.buttonsWrapper}>
        <CustomButton
          size={windowW <= 700 && 'mobile'}
          type={windowW > 700 ? 5 : 8}
          textField="Back"
          buttonOnClick={() => {
            history.replace(`/create-assessment/${id}/${1}`);
          }}
        />
        <CustomButton
          size={windowW <= 700 && 'mobile'}
          textField="Next"
          style={{ marginRight: 0, marginLeft: 'auto' }}
          buttonOnClick={() => {
            history.replace(`/create-assessment/${id}/${3}`);
          }}
        />
      </div>
    </div>
  );
}
