import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import CloseButton from '../../../../Common/Components/CloseButton';
import CustomButton from '../../../../Common/Components/CustomButton';
import SwitchButton from '../../../../Common/Components/CustomSwitchButton/SwitchButton';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import TextInput from '../../../../Common/Components/TextInput';
import Modal from '../../../../Common/CustomModal/Modal';
import Dropdown from '../../../../Common/Dropdown/Dropdown';
import { rejectModalSelectedCountSelector, selectedAcceptedCandidateListSelector, selectedCandidateListSelector } from '../../../../redux/assessmentDetailVol2/selectors';
import { userSelector } from '../../../../redux/auth/selectors';
import {
  createRejectedEmailContent,
  getCompanySubjectAndEmailContent,
  sendRejectedEmailContent
} from '../../../../redux/rejectionMail/actions';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import styles from './RejectCandidatesModal.module.css';
import RejectionMailTextEditor from './RejectionMailTextEditor/RejectionMailTextEditor';
import { candidateProfileSelector } from '../../../../redux/candidateDetail/selectors';

const RejectCandidatesModal = ({ show, onClose, showSuccess, setMailSentFlag, moveMethod, newCandidateDetailFlag = false, assessmentDetailFlag = false }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [rejectionStep, setRejectionStep] = useState(false);

  const selectedCandidateListRedux = useSelector(selectedCandidateListSelector) || [];
  const selectedAcceptedCandidateListRedux = useSelector(selectedAcceptedCandidateListSelector) || [];
  const allSelectedCandidatesList = [...selectedCandidateListRedux, ...selectedAcceptedCandidateListRedux];

  const currentUserRedux = useSelector(userSelector);

  // For Candidate detail - locked check
  const profile = useSelector(candidateProfileSelector);

  const [newTemplateName, setNewTemplateName] = useState('');

  const [templateSubjectField, setTemplateSubjectField] = useState('');
  const [templateMailContent, setTemplateMailContent] = useState('');

  const initialDataForMailDropdown = isMobile
    ? []
    : [
        {
          name: 'Create New Template',
          id: 1,
          // subject: templateSubjectField,
          emailContent: templateMailContent
        }
      ];
  const [mailListForDropdown, setMailListForDropdown] = useState(initialDataForMailDropdown);
  const [selectedMail, setSelectedMail] = useState();
  const [clickedMail, setClickedMail] = useState();

  const initialDelayDataForDelayDropdown = [
    { name: 'Now', id: 1 },
    { name: '1 day later', id: 2 },
    { name: '2 days later', id: 3 },
    { name: '3 days later', id: 4 }
  ];
  const [delayDataForDelayDropdown, setDelayDataForDelayDropdown] = useState(
    initialDelayDataForDelayDropdown
  );
  const [selectedDelay, setSelectedDelay] = useState();

  const fetchMailTemplates = async () => {
    const resp = await dispatch(
      getCompanySubjectAndEmailContent({ CompanyId: currentUserRedux?.companyId })
    );
    const innerMailList = resp?.payload?.data;
    const temp = [];
    innerMailList.forEach(mail => {
      temp.push({
        name: mail.reservedName,
        id: mail.id,
        subject: mail.subject,
        emailContent: mail.emailContent
      });
    });
    setMailListForDropdown(mailListForDropdown => [...mailListForDropdown, ...temp]);
  };

  useEffect(async () => {
    setRejectionStep(false);
    if (show) {
      fetchMailTemplates();
    } else {
      setMailListForDropdown(initialDataForMailDropdown);
    }
    setDelayDataForDelayDropdown(initialDelayDataForDelayDropdown);
  }, [show]);

  const switchToCreateNewTemplate = () => {
    setSelectedMail(initialDataForMailDropdown[0]);
  };

  useEffect(() => {
    if (templateMailContent !== '' && !isMobile) {
      switchToCreateNewTemplate();
    }
  }, [templateMailContent]);

  useEffect(() => {
    if (clickedMail) {
      setTemplateSubjectField(clickedMail.subject || '');
      setNewTemplateName('');
      setTemplateMailContent('');
    }
  }, [clickedMail]);

  useEffect(() => {
    if (selectedMail?.id === 1) {
      setClickedMail();
    }
  }, [selectedMail]);

  const delayTime = () => {
    switch (selectedDelay.id) {
      case 1:
        return 0;
      case 2:
        return 1440;
      case 3:
        return 2880;
      case 4:
        return 4320;
      default:
        return 0;
    }
  };

  const createMailTemplate = async () => {
    const delay = delayTime();
    const data = {
      companyId: currentUserRedux?.companyId,
      subject: templateSubjectField,
      candidateList: !newCandidateDetailFlag ? allSelectedCandidatesList?.map(e => e.userId) : [id],
      candidateEmailSubmissionTime: delay,
      emailContent: templateMailContent,
      title: newTemplateName,
      companyAssessmentId: !newCandidateDetailFlag ? id : getParam(location.search, 'currentAssessmentId'),
    };
    const resp = await dispatch(createRejectedEmailContent(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      await moveMethod(true);
      setMailSentFlag(true);
      onClose();
      showSuccess(true);
    }
  };

  const sendMail = async () => {
    const delay = delayTime();
    const data = {
      companyEmailId: selectedMail.id,
      candidateList: !newCandidateDetailFlag ? allSelectedCandidatesList?.map(e => e.userId) : [id],
      candidateEmailSubmissionTime: delay,
      companyAssessmentId: !newCandidateDetailFlag ? id : getParam(location.search, 'currentAssessmentId'),
    };
    const resp = await dispatch(sendRejectedEmailContent(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      await moveMethod(true);
      setMailSentFlag(true);
      onClose();
      showSuccess(true);
    }
  };

  const rejectCandidate = async () => {
    // TODO: move method integration
    await moveMethod(false);
    setMailSentFlag(false);
    onClose();
    showSuccess(false);
  };

  const selectedCandCountForReject = useSelector(rejectModalSelectedCountSelector)

  const WebRejectionModal = (
    <Modal
      contentStyle={{
        border: '1px solid rgba(88, 88, 88, 0.2)',
        borderRadius: '24px',
        padding: '2.7rem 4rem 3.2rem 4rem',
        transition: 'max-height 1s',
        maxHeight: rejectionStep ? '900px' : '300px'
      }}
      onClose={onClose}
      size={{ width: 'min(84rem, 840px)', height: 'fit-content' }}
      centerHorizontally
      centerVertically
      show={show}
      outsideClickClose
    >
      <div className={styles.contentWrapper}>
        <text className={styles.contentHeader}>Reject Candidates</text>
        <text className={styles.candidateCountRow}>
          <text style={{ fontFamily: 'Jost-400' }}>
            {assessmentDetailFlag ? (selectedCandCountForReject || 1) : 1} candidate
            {assessmentDetailFlag && selectedCandCountForReject > 1 && 's'}
          </text>
          &nbsp;{assessmentDetailFlag && selectedCandCountForReject > 1 ? 'were' : 'was'} selected&nbsp;
          <text style={{ fontFamily: 'Jost-400' }}>to be rejected.</text>
        </text>
        <div className={styles.closeButton}>
          <CloseButton onClick={onClose} />
        </div>

        
        <div className={styles.SwitchButtonRow} style={(newCandidateDetailFlag && profile?.status === 4) || (assessmentDetailFlag && allSelectedCandidatesList.every(x => x.objStatusId === 4))  ? {pointerEvents: 'none', opacity: '0.4'} : {}}>
          <SwitchButton
            switchState={rejectionStep}
            setSwitchState={setRejectionStep}
            isResponsive
          />
          Send rejection mail
        </div>

        {/* Button Row for rejection step = 0 */}
        <div
          className={styles.ButtonRow}
          style={{
            opacity: !rejectionStep ? '1' : '0',
            visibility: !rejectionStep ? 'visible' : 'hidden',
            maxHeight: !rejectionStep ? '4.2rem' : '0rem',
            marginTop: !rejectionStep ? '3.2rem' : '0rem',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <CustomButton
            textField="Cancel"
            type={2}
            buttonOnClick={() => onClose()}
            isResponsive
          />
          <CustomButton
            textField="Confirm"
            type={1}
            buttonOnClick={() => rejectCandidate()}
            isResponsive
          />
        </div>

        {/* rejection step = 1 below */}

        <div
          className={styles.MailPart}
          style={{
            opacity: rejectionStep ? '1' : '0',
            visibility: rejectionStep ? 'visible' : 'hidden',
            maxHeight: rejectionStep ? '84rem' : '0rem',
            marginTop: rejectionStep ? '1.6rem' : '0rem',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <div
            className={styles.DropdownRow}
            style={{
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              marginTop: rejectionStep ? '1.6rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s'
            }}
          >
            <Dropdown
              height="4.8rem"
              list={mailListForDropdown}
              openItemCount={4}
              title="Mail Template"
              placeholder="Select mail template"
              setSelectedOption={setSelectedMail}
              selectedOption={selectedMail}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={1}
              selectOnClick={setClickedMail}
            />
            <Dropdown
              height="4.8rem"
              list={delayDataForDelayDropdown}
              openItemCount={4}
              title="Time to send"
              placeholder="Select mail template"
              setSelectedOption={setSelectedDelay}
              selectedOption={selectedDelay}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={-1}
              selectOnClick={setSelectedDelay}
            />
          </div>

          {/* newTemplateState conditional part */}
          <TextInput
            style={{
              width: '100%',
              marginTop: rejectionStep && selectedMail?.id === 1 ? '1.2rem' : '0rem',
              maxHeight: rejectionStep && selectedMail?.id === 1 ? '4.8rem' : '0rem',
              opacity: rejectionStep && selectedMail?.id === 1 ? '1' : '0',
              visibility: rejectionStep && selectedMail?.id === 1 ? '1' : '0',
              transition: 'max-height 1s, margin-top 1s, opacity 1s, visibility 1s',
              transitionDelay:
                rejectionStep && selectedMail?.id === 1
                  ? '0s, 0s, 0.2s, 0.2s'
                  : '0.2s, 0.2s, 0s, 0s'
            }}
            value={newTemplateName}
            placeholder="Name Your Template"
            onChange={e => setNewTemplateName(e.target.value)}
          />
          {/* ---------------------------------------- */}

          <TextInput
            style={{
              width: '100%',
              marginTop: rejectionStep ? '1.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s'
            }}
            inputstyle={{ borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}
            value={templateSubjectField}
            placeholder="Mail Subject"
            onChange={e => setTemplateSubjectField(e.target.value)}
            disabled={selectedMail?.id !== 1}
          />

          <RejectionMailTextEditor
            setStyle={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              transition: 'opacity 1s, visibility 1s'
            }}
            sampleMail={clickedMail}
            passMailToParent={setTemplateMailContent}
          />

          <div
            className={styles.ButtonRow}
            style={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              marginTop: rejectionStep ? '3.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.2rem' : '0rem',
              transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
            }}
          >
            <CustomButton
              textField="Cancel"
              type={2}
              buttonOnClick={() => setRejectionStep(false)}
              isResponsive
            />
            <CustomButton
              textField={selectedMail?.id === 1 ? 'Save & Send' : 'Send'}
              type={1}
              buttonOnClick={selectedMail?.id === 1 ? createMailTemplate : sendMail}
              isResponsive
              isDisabled={
                selectedMail?.id === 1 &&
                (templateSubjectField?.trim() === '' ||
                  selectedMail?.emailContent?.trim() === '<p></p>' ||
                  selectedMail?.emailContent?.trim() === '' ||
                  newTemplateName?.trim() === '')
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );

  const MobileRejectionModal = (
    <MobileModal
      id="mobile-rejection-modal"
      contentStyle={{
        padding: '20px 20px 24px',
        transition: 'max-height 1s',
        maxHeight: rejectionStep ? '550px' : '250px',
        minWidth: '375px',
        overflow: 'hidden'
      }}
      onClose={onClose}
      backdropStyle={{ overflow: 'hidden' }}
      show={show}
      outsideClickClose
    >
      <div className={styles.contentWrapper}>
        <text className={styles.contentHeader}>Reject Candidates</text>
        <text className={styles.candidateCountRow}>
          <text style={{ fontFamily: 'Jost-400' }}>
            {assessmentDetailFlag ? (selectedCandCountForReject || 1) : 1} candidate
            {assessmentDetailFlag && selectedCandCountForReject > 1 && 's'}
          </text>
          &nbsp;{assessmentDetailFlag && selectedCandCountForReject > 1 ? 'were' : 'was'} selected&nbsp;
          <text style={{ fontFamily: 'Jost-400' }}>to be rejected.</text>
        </text>
        <div className={styles.closeButton}>
          <CloseButton onClick={onClose} />
        </div>
        <div className={styles.SwitchButtonRow} style={(newCandidateDetailFlag && profile?.status === 4) || (assessmentDetailFlag && allSelectedCandidatesList.every(x => x.objStatusId === 4))  ? {pointerEvents: 'none', opacity: '0.4'} : {}}>
          <SwitchButton switchState={rejectionStep} setSwitchState={setRejectionStep} isMobile />
          Send rejection mail
        </div>

        {/* Button Row for rejection step = 0 */}
        <div
          className={styles.ButtonRow}
          style={{
            opacity: !rejectionStep ? '1' : '0',
            visibility: !rejectionStep ? 'visible' : 'hidden',
            maxHeight: !rejectionStep ? '42px' : '0px',
            marginTop: !rejectionStep ? '24px' : '0px',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <CustomButton
            textField="Cancel"
            type={8}
            buttonOnClick={() => onClose()}
            size="mobile"
          />
          <CustomButton
            textField="Confirm"
            type={1}
            buttonOnClick={() => rejectCandidate()}
            size="mobile"
          />
        </div>

        {/* rejection step = 1 below */}

        <div
          className={styles.MailPart}
          style={{
            opacity: rejectionStep ? '1' : '0',
            visibility: rejectionStep ? 'visible' : 'hidden',
            maxHeight: rejectionStep ? '840px' : '0px',
            marginTop: rejectionStep ? '16px' : '0px',
            transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
          }}
        >
          <div
            className={styles.DropdownRow}
            style={{
              maxHeight: rejectionStep ? '50px' : '0px',
              marginTop: rejectionStep ? '16px' : '0px',
              transition: 'max-height 1s, margin-top 1s'
            }}
          >
            <Dropdown
              height="50px"
              list={mailListForDropdown}
              openItemCount={4}
              title="Mail Template"
              placeholder="Select mail template"
              setSelectedOption={setSelectedMail}
              selectedOption={selectedMail}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={isMobile ? -1 : 1}
              selectOnClick={setClickedMail}
              overflowOption
              wrapperId="rejectMailDropdownWrapper"
            />
            <Dropdown
              height="50px"
              list={delayDataForDelayDropdown}
              openItemCount={4}
              title="Time to send"
              placeholder="Select mail template"
              setSelectedOption={setSelectedDelay}
              selectedOption={selectedDelay}
              wrapperStyle={{ width: '100%' }}
              defaultSelectedIndex={-1}
              selectOnClick={setSelectedDelay}
              overflowOption
            />
          </div>

          {/* ---------------------------------------- */}

          {/* <TextInput
            style={{
              width: '100%',
              marginTop: rejectionStep ? '1.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s'
            }}
            inputstyle={{ borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px' }}
            value={templateSubjectField}
            placeholder="Mail Subject"
            onChange={e => setTemplateSubjectField(e.target.value)}
            disabled={selectedMail?.id !== 1}
          /> */}
          <div
            style={{
              width: 'calc(100% + 2px)',
              marginTop: rejectionStep ? '1.2rem' : '0rem',
              maxHeight: rejectionStep ? '4.8rem' : '0rem',
              transition: 'max-height 1s, margin-top 1s',

              border: '1px solid rgba(88, 88, 88, 0.2)',
              borderBottomStyle: 'none',
              margin: '-1px -1px 0px -1px',
              borderRadius: '8px 8px 0px 0px',
              height: '43px',
              padding: '0px 16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <input
              style={{
                width: '100%',
                background: '#ffffff',
                border: 'none',
              }}
              value={templateSubjectField}
              disabled
            />
          </div>

          <RejectionMailTextEditor
            setStyle={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              transition: 'opacity 1s, visibility 1s'
            }}
            sampleMail={clickedMail}
            passMailToParent={setTemplateMailContent}
          />

          <div
            className={styles.ButtonRow}
            style={{
              opacity: rejectionStep ? '1' : '0',
              visibility: rejectionStep ? 'visible' : 'hidden',
              marginTop: rejectionStep ? '24px' : '0rem',
              maxHeight: rejectionStep ? '42px' : '0rem',
              transition: 'opacity 1s, visibility 1s, max-height 1s, margin-top 1s'
            }}
          >
            <CustomButton
              textField="Cancel"
              type={8}
              buttonOnClick={() => setRejectionStep(false)}
              size="mobile"
            />
            <CustomButton
              textField={selectedMail?.id === 1 ? 'Save & Send' : 'Send'}
              type={1}
              buttonOnClick={selectedMail?.id === 1 ? createMailTemplate : sendMail}
              size="mobile"
              isDisabled={
                selectedMail?.id === 1 &&
                (templateSubjectField?.trim() === '' ||
                  selectedMail?.emailContent?.trim() === '<p></p>' ||
                  selectedMail?.emailContent?.trim() === '' ||
                  newTemplateName?.trim() === '')
              }
            />
          </div>
        </div>
      </div>
    </MobileModal>
  );

  const [screenW, screenH] = useWindowSize();

  return screenW > 576 ? WebRejectionModal : MobileRejectionModal;
};
export default RejectCandidatesModal;
