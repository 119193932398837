import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCompanySubjectAndEmailContentService,
  createRejectedEmailContentService,
  sendRejectedEmailContentService
} from './service';

export const getCompanySubjectAndEmailContent = createAsyncThunk(
  'GetCompanySubjectAndEmailContent',
  async (data, thunkAPI) => {
    try {
      return await getCompanySubjectAndEmailContentService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createRejectedEmailContent = createAsyncThunk(
  'CreateRejectedEmailContent',
  async (data, thunkAPI) => {
    try {
      return await createRejectedEmailContentService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendRejectedEmailContent = createAsyncThunk(
  'SendRejectedEmailContent',
  async (data, thunkAPI) => {
    try {
      return await sendRejectedEmailContentService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
