import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isPossiblePhoneNumber } from 'react-phone-number-input/input';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD,
  isJsonString,
  useWindowSize
} from '../../../../utils/helpers';

import { ReactComponent as Saved } from '../../../../images/savedCV.svg';
import { ReactComponent as Close } from '../../../../images/resultCloseButton.svg';
import { ReactComponent as ShowCV } from '../../../../images/hasCvIcon.svg';
import { ReactComponent as Linkedin } from '../../../../images/resultLinkedin.svg';
import styles from './CvCardComponent.module.css';
import {
  AssessmentTitle,
  buttonLoading,
  CandidateEmail,
  candidateLinkedIn,
  candidateName,
  candidateSurname,
  CompanyName,
  CvPath,
  rootAssessmentNameRedux,
  showNewOpFlagRedux
} from '../../../../redux/AssessmentSession/selectors';
import TextInput from '../../../../Common/Components/TextInput';
import {
  setLinkedIn,
  setName,
  setSaveButtonLoading,
  setSurname
} from '../../../../redux/AssessmentSession/slice';
import CustomPhoneInput from '../../../../Common/Components/CustomPhoneInput/PhoneInput';
import CustomButton from '../../../../Common/Components/CustomButton';
import {
  applicantAssessmentDetailWithEmailAction,
  ChangeOrAddApplicantDetailAction
} from '../../../../redux/AssessmentSession/actions';
import mixpanel from '../../../../utils/mixpanel';
import { useIp } from '../../../../hooks/useIp';

const CLOSED = 0;
const OPEN = 1;

const LINKEDIN_MOBIL = '/linkedin/howToLinkedinMobil.gif';
const LINKEDIN_DESKTOP = '/linkedin/howToLinkedinDesktop.png';

const CvCardComponent = ({
  id,
  cardState,
  setCardState = () => {},
  path,
  setPath = () => {},
  innerPhone,
  setParentSavedState = () => {},
  application,
  cvReminderFlag = false // Flag for cv reminder page - overwrites default texts and behavior
}) => {
  const dispatch = useDispatch();
  const [screenW, screenH] = useWindowSize();
  /* State for onsave text and icon */
  const [saved, setSaved] = useState(false);
  /* ReCaptcha functions */
  const { executeRecaptcha } = useGoogleReCaptcha();
  /* File submit states */
  const [dropLabel, setDropLabel] = React.useState(`.doc, .docx, .pdf (max. 5 MB)`);
  const [base64, setBase64] = React.useState();
  const [file, setFile] = React.useState(null);

  const [savedPhone, setSavedPhone] = useState(innerPhone);
  const [innerPhoneState, setInnerPhoneState] = useState(innerPhone);
  const [phone, setPhone] = useState();
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [resetPhoneInput, setResetPhoneInput] = useState(false);
  const [linkedInError, setLinkedInError] = useState(false);

  const [locationInfo, currency] = useIp();

  const cvPath = useSelector(CvPath);
  const name = useSelector(candidateName);
  const surname = useSelector(candidateSurname);
  const linkedin = useSelector(candidateLinkedIn);
  const [linkedinFlag, setLinkedinFlag] = useState(linkedin);
  const email = useSelector(CandidateEmail);
  const loading = useSelector(buttonLoading);

  /* Mixpanel Variables */
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const companyName = useSelector(CompanyName);
  const showNewOp = useSelector(showNewOpFlagRedux);

  useEffect(() => {
    if (innerPhoneState) {
      if (isPossiblePhoneNumber(innerPhoneState)) {
        const ph = innerPhoneState.slice(1);
        setPhone(ph);
        setPhoneIsValid(true);
      } else {
        setPhoneIsValid(false);
      }
    } else {
      setPhoneIsValid(false);
    }
  }, [innerPhoneState]);

  useEffect(() => {
    setResetPhoneInput(false);
  }, [resetPhoneInput]);

  const onCloseClick = () => {
    setCardState(prev => (prev === CLOSED ? OPEN : CLOSED));
  };

  const filesUpload = mainFile => {
    if (
      (mainFile[0].type === 'application/msword' ||
        mainFile[0].type === 'application/pdf' ||
        mainFile[0].type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
      mainFile[0].size < 5 * 1024 * 1024
    ) {
      const reader = new FileReader();
      reader.onload = event => {
        setBase64(event.target.result.split(';base64,')[1]);
      };
      reader.readAsDataURL(mainFile[0]);
      setFile(mainFile[0]);
      setDropLabel(mainFile[0].name);

      mixpanel.track(path ? 'Update Your CV Button Success' : 'Upload Your CV Button Success', {
        'CV Reminder Flag': cvReminderFlag,
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag':
          isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
          JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
        'CV Flag': !!path,
        'NewOpp Flag': showNewOp
      });
    } else {
      mixpanel.track(path ? 'Update Your CV Button Fail' : 'Upload Your CV Button Fail', {
        'CV Reminder Flag': cvReminderFlag,
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag':
          isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
          JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
        'CV Flag': !!path,
        'NewOpp Flag': showNewOp,
        'Error message':
          mainFile[0].size >= 5 * 1024 * 1024
            ? 'Max allowed file size is 5MB'
            : 'Allowed file formats: pdf,doc,docx'
      });
      if (mainFile[0].size >= 5 * 1024 * 1024) {
        alert('Max allowed file size is 5MB');
      } else {
        alert('Allowed file formats: pdf,doc,docx');
      }
    }
  };

  const fileUploadCancel = () => {
    mixpanel.track(path ? 'Update Your CV Button Fail' : 'Upload Your CV Button Fail', {
      'CV Reminder Flag': cvReminderFlag,
      'Application Flag': !!application,
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': email,
      'Already Scored Flag':
        isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
        JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
      'CV Flag': !!path,
      'NewOpp Flag': showNewOp,
      'Error message': 'Clicked Cancel'
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: filesUpload,
    onFileDialogCancel: fileUploadCancel
  });

  useEffect(() => {
    if (cvPath) {
      let newCvPath = cvPath.replace('https://api.coens.io', '');
      newCvPath = newCvPath.replace('https://coensioapi.solvera.com.tr', '');
      newCvPath = `${process.env.REACT_APP_IMAGE_API}${newCvPath}`;
      setPath(newCvPath);
      // setCardState(CLOSED);
      setDropLabel('Your CV');
    }
  }, [cvPath]);

  const checkLinkedinStatus = text => {
    if (
      text.startsWith('https://www.linkedin.com/in/') ||
      text.startsWith('http://www.linkedin.com/in/') ||
      text.startsWith('www.linkedin.com/in/') ||
      text.startsWith('https://linkedin.com/in/') ||
      text.startsWith('http://linkedin.com/in/') ||
      text.startsWith('linkedin.com/in/') ||
      text === ''
    ) {
      setLinkedInError(false);
    } else {
      setLinkedInError(true);
    }
  };

  const [linkedinTimeoutState, setLinkedinTimeoutState] = useState(-1);

  useEffect(() => {
    if (!linkedin) {
      setLinkedInError(false);
    }
    if (linkedinTimeoutState !== -1) {
      clearTimeout(linkedinTimeoutState);
      setLinkedinTimeoutState(-1);
    }
    setLinkedinTimeoutState(
      setTimeout(() => {
        if (linkedin) {
          checkLinkedinStatus(linkedin);
        }
      }, 500)
    );
  }, [linkedin]);

  const onSave = async () => {
    dispatch(setSaveButtonLoading(true));

    mixpanel.track('CV Save Button Click', {
      'CV Reminder Flag': cvReminderFlag,
      'Application Flag': !!application,
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': email,
      'Already Scored Flag':
        isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
        JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
      'CV Flag': !!path,
      'NewOpp Flag': showNewOp
    });

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      mixpanel.track('CV Save Button Fail', {
        'CV Reminder Flag': cvReminderFlag,
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag':
          isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
          JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
        'CV Flag': !!path,
        'NewOpp Flag': showNewOp,
        'Error message': 'Execute recaptcha not yet available'
      });
      dispatch(setSaveButtonLoading(false));
      return;
    }
    const recaptchaToken = await executeRecaptcha('startAssessment');

    if (recaptchaToken) {
      let resp;
      if (typeof file?.name !== 'undefined') {
        let extension = 'pdf';
        const fileType = file?.type;
        if (fileType === 'application/msword') {
          extension = 'doc';
        } else if (
          fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
          extension = 'docx';
        }
        const rawData = {
          email,
          name,
          surname,
          phone,
          linkedIn: linkedin,
          cv: typeof base64 === 'undefined' || !base64 ? '' : base64,
          extension,
          recaptchaToken
        };
        resp = await dispatch(ChangeOrAddApplicantDetailAction(rawData));
      } else {
        const rawData = {
          email,
          name,
          surname,
          phone,
          linkedIn: linkedin,
          recaptchaToken
        };
        resp = await dispatch(ChangeOrAddApplicantDetailAction(rawData));
      }

      if (resp.meta.requestStatus === 'fulfilled') {
        setSavedPhone(innerPhoneState);
        setSaved(true);
        setParentSavedState(true);
        setCardState(CLOSED);

        mixpanel.track('CV Save Button Success', {
          'CV Reminder Flag': cvReminderFlag,
          'Application Flag': !!application,
          'Assessment Name': assessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': companyName,
          'Candidate Email': email,
          'Already Scored Flag':
            isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
            JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
          'CV Flag': !!path,
          'NewOpp Flag': showNewOp
        });
      }
    } else {
      console.log('Recaptcha error');
      mixpanel.track('CV Save Button Fail', {
        'CV Reminder Flag': cvReminderFlag,
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag':
          isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
          JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
        'CV Flag': !!path,
        'NewOpp Flag': showNewOp,
        'Error message': 'Recaptcha error'
      });
      dispatch(setSaveButtonLoading(false));
    }
  };

  const cvUploadOnClickMixpanelEvent = () => {
    mixpanel.track(path ? 'Update Your CV Button Click' : 'Upload Your CV Button Click', {
      'CV Reminder Flag': cvReminderFlag,
      'Application Flag': !!application,
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': email,
      'Already Scored Flag':
        isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
        JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
      'CV Flag': !!path,
      'NewOpp Flag': showNewOp
    });
  };

  function openHowToLinkedin() {
    let path = '';
    if (screenW <= ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD) {
      path = `${process.env.REACT_APP_IMAGE_API}${LINKEDIN_MOBIL}`;
    } else {
      path = `${process.env.REACT_APP_IMAGE_API}${LINKEDIN_DESKTOP}`;
    }
    window.open(path, '_blank');
  }

  return (
    <ExtendableCard>
      <div className={styles.header} onClick={onCloseClick}>
        <div>
          {/* {!saved && !path ? (
            <>
              <span>Last but not least!</span>&nbsp;
              <span className={styles.bold}>Upload your CV:</span>
            </>
          ) : !saved ? (
            <>
              <span>We have your CV, is it up to date? If not,</span>&nbsp;
              <span className={styles.bold}>you can update it easily!</span>
            </>
          ) : (
            <div>
              <Saved style={{ marginRight: '1.6rem' }} />
              <span className={styles.bold}>
                {`Your CV has been successfully ${path ? 'updated!' : 'saved!'}`}
              </span>
            </div>
          )} */}

          {!saved && !linkedinFlag ? (
            <>
              <span>👇🏼 Boost your potential -</span>&nbsp;
              <span className={styles.bold}>Share your LinkedIn!</span>
            </>
          ) : !saved ? (
            <>
              <span>👇🏼 Thanks for sharing -</span>&nbsp;
              <span className={styles.bold}>edit if you want!</span>
            </>
          ) : (
            <div>
              <Saved style={{ marginRight: '1.6rem' }} />
              <span className={styles.bold}>
                {`Your information has been successfully ${path ? 'updated!' : 'saved!'}`}
              </span>
            </div>
          )}
        </div>
        <Close
          className={`${styles.baseIcon} ${
            cardState === CLOSED ? styles.closedIcon : styles.openIcon
          }`}
        />
      </div>

      <div
        className={`${styles.baseInputBody} ${
          cardState === CLOSED ? styles.closedInputBody : styles.openInputBody
        } `}
      >
        <div className={styles.linkedinMainWrapper}>
          <text className={styles.linkedinHeaderText}>LinkedIn Account: </text>
          <div className={styles.linkedInWrapper}>
            <TextInput
              type="new-password"
              value={linkedin || ''}
              haserror={linkedInError}
              onChange={event => {
                dispatch(setLinkedIn(event.target.value));
              }}
              id="linkedin"
              style={{ width: '100%' }}
              placeholder="LinkedIn Profile"
            />
            {!linkedin && <Linkedin className={styles.linkedIn} />}
          </div>
          <div className={styles.linkedinGifLink} onClick={openHowToLinkedin}>
            How to get your LinkedIn profile link?
          </div>
        </div>

        <div className={styles.personalInformationMainWrapper}>
          <text className={styles.linkedinHeaderText}>Personal Information: </text>
          <div className={styles.namePart}>
            <div className={`${styles.inputWrapper} ${styles.topInput}`}>
              <TextInput
                value={name}
                onChange={event => dispatch(setName(event.target.value))}
                style={{ width: '100%' }}
                placeholder="Name*"
              />
            </div>
            <div className={styles.inputWrapper}>
              <TextInput
                value={surname}
                onChange={event => dispatch(setSurname(event.target.value))}
                style={{ width: '100%' }}
                placeholder="Surname*"
              />
            </div>
          </div>
          <div className={styles.result}>
            <CustomPhoneInput
              country={locationInfo?.country || 'TR'}
              phoneNum={innerPhoneState}
              setPhoneNum={e => setInnerPhoneState(e)}
              reset={resetPhoneInput}
            />
          </div>
        </div>

        <div className={styles.cvMainWrapper}>
          <text className={styles.linkedinHeaderText}>CV/Resume: </text>

          <div className={styles.cvPart}>
            <div
              {...getRootProps()}
              className={styles.fileInputBase}
              onClick={
                path
                  ? () => {
                      window.open(path, '_blank').focus();
                    }
                  : () => {
                      cvUploadOnClickMixpanelEvent();
                      open();
                    }
              }
            >
              <input
                {...getInputProps()}
                id="input"
                accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
              {path ? (
                <div className={styles.cvName}>
                  <ShowCV
                    style={{ minWidth: '2rem', minHeight: '2rem', width: '2rem', height: '2rem' }}
                  />
                  <text className={styles.cvNameText} style={{ marginLeft: '1rem' }}>
                    {dropLabel}
                  </text>
                </div>
              ) : (
                <text className={styles.inputPlaceholder}>{dropLabel}</text>
              )}
            </div>

            <div
              onClick={() => {
                cvUploadOnClickMixpanelEvent();
                open();
              }}
              className={styles.cvParser}
            >
              {path ? 'Update your CV' : 'Upload your CV'}
            </div>
          </div>
        </div>

        <div className={styles.buttonRow}>
          {cvPath && !cvReminderFlag && (
            <CustomButton
              type={2}
              buttonOnClick={() => {
                setCardState(CLOSED);
                setInnerPhoneState(savedPhone);
                setResetPhoneInput(true);
                const jsonData = {
                  companyAssessmentKey: id,
                  email
                };
                dispatch(applicantAssessmentDetailWithEmailAction(jsonData));
              }}
              textField="Cancel"
            />
          )}
          <CustomButton
            isDisabled={
              !(path || file) || !name || !surname || !phone || !phoneIsValid || linkedInError
            }
            style={{ marginLeft: '0.4rem' }}
            type={1}
            loading={loading}
            buttonOnClick={onSave}
            textField="Save"
          />
        </div>
      </div>
    </ExtendableCard>
  );
};

export default CvCardComponent;

const ExtendableCard = styled.div`
  background-color: white;
  margin-top: 2.4rem;
  box-shadow: 0px 1px 24px rgba(208, 208, 208, 0.15);
  border-radius: 12px;
  width: 100%;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  position: relative;
  padding-left: 3.2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  overflow: hidden;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 3.2rem;
  overflow: hidden;

  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;
