import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as Measure } from '../FilterRow/Images/score.svg';
// import { ReactComponent as Measure } from './industryAverageMeasure.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Arrow } from './average_arrow.svg';
import styles from './ProgressBarComponent.module.css';
import { useWindowSize } from '../../../utils/helpers';
import { isCustomFlagSelector } from '../../../redux/assessmentDetailVol2/selectors';

const ProgressBarComponent = ({
  industryAverage,
  assessmentAverage = 0,
  enoughScores = false,
  infoPresent = false,
  isCandidate = false,
  industryAverageTooltipDirection = 'top',
  assessmentAverageTooltipDirection = 'right',
  candidateInfo = {},
  arrowColor = '#585858',
  hoverStateForCandidate,
  isBarVisible = true,
  mobileModal = false,
  setModalShow,
  id
}) => {
  const [screenW, screenH] = useWindowSize();

  const MOBILE_THRESHOLD = 576;

  const IndustryAverage = () => {
    const isCustomFlagRedux = useSelector(isCustomFlagSelector)
    
    return (
      <>
        {industryAverage ? (
          isCandidate ? (
            hoverStateForCandidate ? (
              <Tooltip
                customStyle={{
                  marginLeft: `calc(${industryAverage}% - 6px)`
                }}
                tooltipColor="#585858"
                textColor="#FFFFFF"
                text={`Industry average: ${industryAverage}`}
                direction={industryAverageTooltipDirection}
                marginTop="2px"
                fontSize="12px"
                padding="4px 8px"
              >
                <Arrow className={`${styles.arrow} ${styles.candidateArrow}`} />
              </Tooltip>
            ) : (
              <Arrow
                className={`${styles.arrow} ${styles.candidateArrow}`}
                style={{ marginLeft: `calc(${industryAverage}% - 6px)` }}
              />
            )
          ) : (
            <Tooltip
              customStyle={{
                marginLeft: `calc(${industryAverage}% - 6px)`
                // marginLeft: enoughScores ? `calc(${industryAverage}% - 6px)` : 'calc(50% - 6px)'
              }}
              tooltipColor="#585858"
              textColor="#FFFFFF"
              text={`Industry Average: ${industryAverage}`}
              text2={isCustomFlagRedux ? "Average score of top 10 candidates who completed this assessment.": "Average score for each top 10 candidates of all companies using this assessment."}
              direction={industryAverageTooltipDirection}
              marginTop="2px"
              fontSize="12px"
              padding="4px 8px"
            >
              <Arrow className={styles.arrow} />
            </Tooltip>
          )
        ) : !isCandidate ? (
          <div className={styles.NanWrapper}>
            <div className={styles.smallText}>Industry Average: NaN</div>
            {screenW > 576 && (
              <Tooltip
                text="There are not enough scores yet to calculate industry average."
                direction="right"
                fontSize="12px"
                padding="4px 8px"
                fontFamilyForText1="Jost-300"
              >
                <Info />
              </Tooltip>
            )}
          </div>
        ) : hoverStateForCandidate ? (
          <Tooltip
            text="Industry Average: NaN"
            tooltipColor="#DBDBDB"
            direction="top"
            textColor="#585858"
            padding="4px 5px"
            marginTop="2px"
          >
            <Arrow
              style={{
                filter:
                  'invert(84%) sepia(4%) saturate(5%) hue-rotate(314deg) brightness(103%) contrast(96%)'
              }}
              className={`${styles.arrow} ${isCandidate && styles.candidateArrow}`}
            />
          </Tooltip>
        ) : (
          <Arrow
            style={{
              filter:
                'invert(84%) sepia(4%) saturate(5%) hue-rotate(314deg) brightness(103%) contrast(96%)'
            }}
            className={`${styles.arrow} ${isCandidate && styles.candidateArrow}`}
          />
        )}
      </>
    );
  };

  const ProgressBarDiv = () => {
    return (
      <div className={`${styles.progressBar} ${isCandidate && styles.candidateProgressBar}`}>
        <div
          className={styles.progress}
          style={{
            background: !industryAverage
              ? 'rgba(255, 214, 0, 0.6)' // yellow
              : isCandidate
              ? candidateInfo?.points >= industryAverage
                ? 'rgba(74, 195, 137, 0.8)' // green
                : candidateInfo?.points >= industryAverage * 0.8
                ? 'rgba(255, 214, 0, 0.6)' // yellow
                : 'rgba(255, 138, 117, 0.8)' // red
              : assessmentAverage
              ? assessmentAverage < industryAverage * 0.8
                ? 'linear-gradient(270deg, #FF7E62 0%, rgba(255, 126, 98, 0.6) 100%)' // red with gradient
                : assessmentAverage < industryAverage * 1
                ? 'linear-gradient(270deg, rgba(255, 214, 0, 1) 0%, rgba(255, 214, 0, 0.6) 100%)' // yellow with gradient
                : 'linear-gradient(270deg, #4ac389 0%, rgba(74, 195, 137, 0.6) 100%)' // green with gradient
              : '',
            // not visible -> since there is no assessmentAverage
            width: `${isCandidate ? candidateInfo?.points : assessmentAverage}%`
          }}
        />
        <div
          className={styles.empty}
          style={{
            width: `${isCandidate ? 100 - candidateInfo?.points : 100 - assessmentAverage}%`,
            background: isCandidate ? 'rgba(88, 88, 88, 0.2)' : '',
            // border left for 0 score cases
            borderRadius:
              (isCandidate && candidateInfo?.points <= 0) ||
              (!isCandidate && assessmentAverage === null) ||
              (!isCandidate && assessmentAverage === 0)
                ? '16px'
                : ''
          }}
        />
      </div>
    );
  };

  const ProgressBar = () => {
    return (
      <div className={styles.progressBarWrapper}>
        <IndustryAverage />
        <ProgressBarDiv />
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        if (mobileModal) {
          setModalShow(true);
        }
      }}
      className={styles.wrapper}
      id={id}
    >
      <Measure className={`${styles.measure} ${isCandidate && styles.candidateMeasure}`} />
      {isBarVisible && <ProgressBar />}
      {isCandidate ? (
        <label
          className={styles.text}
          style={{
            width: '2.4rem',
            color: isBarVisible
              ? '#585858'
              : !industryAverage
              ? '#585858'
              : isCandidate
              ? candidateInfo?.points >= industryAverage
                ? 'rgba(74, 195, 137, 0.8)' // green
                : candidateInfo?.points >= industryAverage * 0.8
                ? 'rgba(255, 214, 0, 0.6)' // yellow
                : 'rgba(255, 138, 117, 0.8)' // red
              : !industryAverage &&
                ' linear-gradient(270deg, #FF7E62 0%, rgba(255, 126, 98, 0.6) 100%)'
          }}
        >
          {candidateInfo?.points !== -1 ? candidateInfo?.points : 'NA'}
        </label>
      ) : (
        <label
          className={styles.text}
          style={{
            color:
              screenW <= MOBILE_THRESHOLD && industryAverage
                ? assessmentAverage >= industryAverage
                  ? 'rgba(74, 195, 137, 0.8)' // green
                  : assessmentAverage > industryAverage * 0.8
                  ? 'rgba(255, 214, 0, 0.6)' // yellow
                  : 'rgba(255, 138, 117, 0.8)' // orange
                : '#393939'
          }}
        >
          {enoughScores ? assessmentAverage?.toFixed(1) : '-'}
        </label>
      )}
      {infoPresent && screenW > MOBILE_THRESHOLD && (
        <Tooltip
          text={
            enoughScores
              ? 'Average score of your top 10 candidates.'
              : 'There are not enough scores yet to calculate the average score of your candidates.'
          }
          direction={assessmentAverageTooltipDirection}
          fontFamilyForText1="Jost-300"
          fontSize="12px"
          padding="6px 8px"
        >
          <Info className={styles.info} />
        </Tooltip>
      )}
    </div>
  );
};

export default ProgressBarComponent;
