import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FilterColumn from './FilterColumn';
import QuestionBasedMiddleColumn from './QuestionBasedMiddleColumn';
import QuestionBasedRightColumn from './QuestionBasedRightColumn';
import styles from '../Styles/QuestionBasedFilterBody.module.css';
import {
  filterState,
  jobDescription,
  pageIndex,
  rootAssessmentQuestionList
} from '../../../redux/assessmentsVol2/selectors';
import {
  getJobDescriptionListAction,
  getQuestionByFilterAction,
  updateCustomAssessmentAction
} from '../../../redux/assessmentsVol2/actions';
import { setCustomAssessment } from '../../../redux/assessmentsVol2/slice';
import { viewHeight } from '../../../redux/user/selectors';
import { userSelector } from '../../../redux/auth/selectors';

import mixpanel from '../../../utils/mixpanel';

export default function QuestionBasedFilterBody() {
  const user = useSelector(userSelector);
  const jobDescriptionList = useSelector(jobDescription);
  const dispatch = useDispatch();
  // const id = useSelector(customAssessmentId);
  const { id } = useParams(); //
  const qList = useSelector(rootAssessmentQuestionList);
  const data2 = useSelector(filterState);
  const height = useSelector(viewHeight);
  useEffect(async () => {
    if (!qList) {
      const data = {
        objStatus: 4,
        rootAssessmentId: id
      };
      await dispatch(updateCustomAssessmentAction(data));
    }

    await dispatch(setCustomAssessment(id));

    /* Mixpanel pageview */
    mixpanel.track('Question Based Assessment - Pageview', {
      "User Id": user.userId,
      "Company Name": user.companyName,
      "Name Surname": user.userNameSurname,
      "Root Assessment Id": id,
      "Root Assessment Name": localStorage.getItem('Mixpanel_AssessmentCustomizationRootAssessmentName')
      })

  }, []);


  const qPerPage = 50;
  const myPageIndex = useSelector(pageIndex);

  // Assessmenta soru eklenip çıkarıldığında getQuestionByFilter'ın tekrar çağrılmasını
  // istiyoruz. Fakat aynı zamanda index'in de tutulmasını istiyoruz. Dolayısıyla currentPage
  // değerini değiştirmeden istek attırtıp pageSize'ı istediğimiz değere getirmemiz gerekli
  const [resetCurrentListFlag, setResetCurrentFlagList] = useState(false);
  const [resetScrollPositionFlag, setResetScrollPositionFlag] = useState(false);
  const [resetFilterFlag, setResetFilterFlag] = useState(false);

  useEffect(() => {
    if (user.companyId && id && qList) {
      const data = {
        CompanyId: user.companyId
      };

      const rawData = { ...data2, currentPage: 1, pageSize: qPerPage * myPageIndex, rootAssessmentId: id };
      setResetCurrentFlagList(true);
      dispatch(getQuestionByFilterAction(rawData));
      dispatch(getJobDescriptionListAction(data));
    }
  }, [user, id, qList]);

  useEffect(() => {
    if (resetFilterFlag) {
      setResetFilterFlag(false)
    }
  }, [resetFilterFlag])

  return (
    <div className={styles.contentBody} style={{ height: `calc(100vh - ${height}px` }}>
      <div className={styles.pageWrapper}>
        <div className={styles.filterColumn} style={{ height: `calc(100vh - ${height}px` }}>
          <FilterColumn jobDescriptionList={jobDescriptionList?.categoryLabels} setResetScrollPositionFlag={setResetScrollPositionFlag} setResetFilterFlag={setResetFilterFlag} />
        </div>
        <div className={styles.middleColumn} id="questionBasedMiddleColumn" style={{ height: `calc(100vh - ${height}px` }}>
          <QuestionBasedMiddleColumn resetCurrentListFlag={resetCurrentListFlag} setResetCurrentFlagList={setResetCurrentFlagList} resetScrollPositionFlag={resetScrollPositionFlag} setResetScrollPositionFlag={setResetScrollPositionFlag} resetFilterFlag={resetFilterFlag}  />
        </div>
        <div className={styles.rightColumn} style={{ height: `calc(100vh - ${height}px` }}>
          <QuestionBasedRightColumn />
        </div>
      </div>
    </div>
  );
}
