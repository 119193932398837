import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../../Common/RawCheckbox/Checkbox';
import { setScreeningWorkTypeList } from '../../../../redux/AssessmentSession/slice';
import { useWindowSize } from '../../../../utils/helpers';
import styles from './ScreeningCardComponent.module.css';

// Input Type: 40
const MultiSelectCheckbox = ({ text, id, isSelected, handleClick }) => {
  const [screenW, screenH] = useWindowSize()
   
  return (
    <div
      className={styles.checkboxWrapper}
      onClick={() => {
        handleClick();
      }}
    >
      <Checkbox isChecked={isSelected} size={screenW < 576 ? 19 : 16} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};
const WorkTypeQuestion = ({ content, index }) => {
  const dispatch = useDispatch();
  // Multi Select
  const [selectedArr, setSelectedArr] = useState(content?.currentWorkTypeAnswers || []);

  useEffect(() => {
    dispatch(setScreeningWorkTypeList(selectedArr));
  }, [selectedArr]);

  const handleClick = id => {
    if (!selectedArr.includes(id)) {
      const temp = [...selectedArr];
      temp.push(id);
      setSelectedArr(temp);
    } else {
      const temp = [...selectedArr];
      const ind = temp.indexOf(id);
      temp.splice(ind, 1);
      setSelectedArr(temp);
    }
  };

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>{content?.workTypeQuestion}</div>
      <div className={styles.multiWrapper}>
        {content?.workTypes?.map((e, i) => (
          <MultiSelectCheckbox
            text={e.name}
            id={e.id}
            isSelected={selectedArr.includes(e.id)}
            handleClick={() => handleClick(e.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default WorkTypeQuestion;
