/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getRootAssessmentDropdownListAction,
  publishAssessmentControlAction
} from '../../../redux/addAssessment/actions';
import { userSelector } from '../../../redux/auth/selectors';
import styles from '../Styles/AddAssessmentContent.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import CustomAssessmentModal from '../Modals/CustomAssessmentModal';
import AssessmentDetailsModal from '../Modals/AssessmentDetailsModal';
import GoBackButton from '../../../Common/Components/GoBackButton';
import TabSwitch from '../../../Common/Components/TabSwitch';
import Loading from '../../../Common/Components/Loading';
import Highlight from '../../../Common/Components/Highlight';
import { ReactComponent as Plus } from '../Images/plusButton.svg';
import {
  assessmentActiveTab,
  continueToAssessmentCustomizationRedux,
  filteredRootAssessmentList,
  mostPopularRootAssessmentListRedux,
  selectedRootAssessmentQuestionListRedux,
  updateCustomAssessmentCheckRedux,
  selectedRootAssmFiltersRedux
} from '../../../redux/assessmentsVol2/selectors';
import NameAssessmentModal from '../Modals/NameAssessmentModal';
import {
  setAssessmentActiveTab,
  setAssessmentId,
  setContinueToAssessmentCustomization,
  setFilteredRootAssessmentList,
  setMostPopularRootAssessmentList,
  setRootQuestionList,
  setSelectedRootAssessmentQuestionList,
  setUpdateCustomAssessmentCheck,
  setSelectedRootAssmFilters
} from '../../../redux/assessmentsVol2/slice';
import DeleteAssessmentModal from '../Modals/DeleteAssessmentModal';
import {
  publishEnabled,
  rootAssmCategories,
  rootAssmJobRoles,
  rootAssmSeniorities
} from '../../../redux/addAssessment/selectors';
import HeadsUpModal from '../../../Auth/Modals/HeadsUpModal';
import {
  completedOnboardingNoRedux,
  currentPlanRedux,
  onboardingStepRedux,
  premiumQControlResultredux,
  premiumQuestionCheckRedux,
  toBePurchasedAddOnRedux
} from '../../../redux/plan/selectors';
import ContactSalesSuccess from '../../../Auth/Modals/UnsubscribeSuccessModal';
import {
  getCurrentPlanAction,
  getCurrentUsageAction,
  premiumQAvailableInAssessmentControlAction
} from '../../../redux/plan/actions';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';
import FreeTrialModal from '../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import {
  setOnboardingStep,
  setQuickStartOpen,
  setToBePurchasedAddOn
} from '../../../redux/plan/slice';
import PurchaseAddOnSuccessModal from '../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import QuickStart from '../../../Auth/Components/QuickStart/QuickStart';
import imageSrc from '../../AssessmentList/Components/onboardingComplete.svg';

import mixpanel from '../../../utils/mixpanel';
import CreateAssessmentPopup from '../../AssessmentList/Components/CreateAssessmentPopup';
import BackgroundImageWrapper from '../../../Common/Components/BackgroundImageWrapper/BackgroundImageWrapper';
import noCustomAssessImg from '../../../Common/Components/BackgroundImageWrapper/noCustomAssessment.svg';
import CustomSearchbar from '../../../Common/Components/CustomSearchbar/CustomSearchbar';
import RootAssessmentCard from './RootAssessmentCard/RootAssessmentCard';
import leftScroll from './RootAssessmentCard/Images/leftScroll.svg';
import rightScroll from './RootAssessmentCard/Images/rightScroll.svg';
import leftScrollLight from './RootAssessmentCard/Images/leftScrollLight.svg';
import rightScrollLight from './RootAssessmentCard/Images/rightScrollLight.svg';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import noResult from '../../../Common/Components/BackgroundImageWrapper/noResult-addAssm.svg';
import { fetchRootAssessmentList, useWindowSize } from '../../../utils/helpers';
import { setRootAssmCategories, setRootAssmJobRoles } from '../../../redux/addAssessment/slice';
import FilterIcon from '../Images/filter.svg';
import FilterIconPurple from '../Images/filterPurple.svg';
import purpleCircle1 from '../Images/filter1.svg';
import purpleCircle2 from '../Images/filter2.svg';
import purpleCircle3 from '../Images/filter3.svg';

import MobileAssessmentFilterModal from '../Modals/MobileAssessmentFilterModal';
import useHideKeyboard from '../../../hooks/useHideKeyboard';

const CreateAssessmentContent = styled.div`
  padding-top: 1rem !important;

  .title {
    font-size: 36px;
    line-height: 59px;
    letter-spacing: -0.02em;
    color: #585858;
  }

  .subtitle {
    color: rgba(88, 88, 88, 0.8);
    font-size: 24px;
    line-height: 39px;
    letter-spacing: -0.02em;
  }
`;

export default function AddAssessmentContent() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [windowW, windowH] = useWindowSize();

  const [category, setCategory] = React.useState({});
  const user = useSelector(userSelector);
  const selectedRootAssessmentQuestionList = useSelector(selectedRootAssessmentQuestionListRedux);
  const [showCustomModal, setCustomModal] = useState(false);
  const [showAssessmentDetail, setDetail] = useState(false);

  // Mobile Filter variables
  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);
  const [filteredCount, setFilteredCount] = useState(0);
  const filtersReduxMobile = useSelector(selectedRootAssmFiltersRedux);
  const [showmobileFilter, setShowMobileFilter] = useState(false);

  // eslint-disable-next-line no-unused-vars

  const tab = useSelector(assessmentActiveTab);
  const [showNameModal, setNameModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  /*
    React state that keeps the current page for back button functionality.
   */
  const [searchTerm, setSearchTerm] = useState('');

  const publishEnabledCheck = useSelector(publishEnabled);

  const myPlan = useSelector(currentPlanRedux);
  const continueToAssessmentCustomizationFlag = useSelector(continueToAssessmentCustomizationRedux);
  const updateCustomAssessmentCheckFlag = useSelector(updateCustomAssessmentCheckRedux);

  // Variables for controls after "Continue" clicked: assessment availability & pr Q control
  const [assessmentUnavailableInFT, setAssessmentUnavailableInFT] = useState(false);
  const [assessmentUnavailable, setAssessmentUnavailable] = useState(false);
  const [premiumQExistInFT, setPremiumQExistInFT] = useState(false);
  const [premiumQExistModal, setPremiumQExistModal] = useState(false);
  const [premiumQuestionInFT, setPremiumQuestionInFT] = useState(false);
  const [premiumQuestion, setPremiumQuestion] = useState(false);
  const toBePurchasedAddOn = useSelector(toBePurchasedAddOnRedux);

  // Variables for mobile success banners of duplicate & delete assessments
  const [duplicated, setDuplicated] = useState(false);
  const [deleted, setDeleted] = useState(false);

  // Conditional render for assm details modal
  const [showAssmDetailModal, setShowAssmDetailModal] = useState(false);
  const [nameAssmShow, setNameAssmShow] = useState(false);

  useEffect(() => {
    if (showNameModal) {
      setNameAssmShow(true);
    } else {
      setTimeout(() => {
        setNameAssmShow(false);
      }, 300);
    }
  }, [showNameModal]);

  useEffect(() => {
    if (showAssessmentDetail) {
      setShowAssmDetailModal(true);
    } else {
      setTimeout(() => {
        setShowAssmDetailModal(false);
      }, 300);
    }
  }, [showAssessmentDetail]);

  useEffect(() => {
    if (showMobileFilterModal) {
      setShowMobileFilter(true);
    } else {
      setTimeout(() => {
        setShowMobileFilter(false);
      }, 300);
    }
  }, [showMobileFilterModal]);

  useEffect(() => {
    dispatch(setContinueToAssessmentCustomization('pending'));
    dispatch(setUpdateCustomAssessmentCheck('pending'));
  }, []);

  async function handleAddAssessment() {
    const data = {};
    const checkData = {
      RootAssessmentId: selectedRootAssessmentQuestionList.rootAssessmentId
    };
    const publishControl = await dispatch(publishAssessmentControlAction(data));
    const premiumQControl = await dispatch(premiumQAvailableInAssessmentControlAction(checkData));

    if (publishControl?.payload?.result && !premiumQControl?.payload?.isPremiumQuestionExist) {
      history.push(
        `/create-assessment/${selectedRootAssessmentQuestionList.rootAssessmentId}/${1}`
      );
      setDetail(false);
    } else if (!publishControl?.payload?.result) {
      if (myPlan?.name === 'Free Trial') {
        await setDetail(false);
        await setAssessmentUnavailableInFT(true);
      } else {
        const data = {
          bundleItemCode: 10
        };
        await dispatch(setToBePurchasedAddOn(data));
        await setDetail(false);
        await setAssessmentUnavailable(true);
      }
    } else if (premiumQControl?.payload?.isPremiumQuestionExist) {
      if (myPlan?.name === 'Free Trial') {
        await setDetail(false);
        await setPremiumQExistInFT(true);
      } else {
        await setDetail(false);
        await setPremiumQExistModal(true);
      }
    }
  }
  const rootCategories = useSelector(filteredRootAssessmentList);

  const sendCategoryDetailModal = async category => {
    // await dispatch(GetQuestionList(category.rootAssessmentId));
    rootCategories.forEach(e => {
      e.rootAssesments.forEach(innerE => {
        if (innerE.rootAssessmentId === category.rootAssessmentId) {
          dispatch(setSelectedRootAssessmentQuestionList(innerE));
        }
      });
    });
  };

  const ref = useRef();

  useEffect(async () => {
    if (user) {
      const rawData = {
        companyId: user.companyId,
        isCompanyAssessment: tab !== 0
      };

      fetchRootAssessmentList(rawData, setLoading, ref, dispatch);
    }
  }, [tab, user]);

  const updateAssessment = async () => {
    setDuplicated(true);
    const rawData = {
      companyId: user.companyId,
      isCompanyAssessment: tab !== 0
    };
    await fetchRootAssessmentList(rawData, setLoading, ref, dispatch);
  };

  const deleteAssessment = async () => {
    setDeleted(true);
    const rawData = {
      companyId: user.companyId,
      isCompanyAssessment: tab !== 0
    };
    await fetchRootAssessmentList(rawData, setLoading, ref, dispatch);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    // Empty the question list every time we leave the customization page
    dispatch(setRootQuestionList(null));
    dispatch(setAssessmentId(null));
    return () => {
      dispatch(setAssessmentActiveTab(0));
      dispatch(setFilteredRootAssessmentList([]));
    };
  }, []);

  /* ----------- Payment Related Checks Below ----------- */

  // SUCCESS Scenarios for add on purchases
  // 1 - Purchase Assessment
  function goToAddAssessmentFlow() {
    history.push(`/create-assessment/${selectedRootAssessmentQuestionList.rootAssessmentId}/${1}`);
    const data = {
      CompanyId: user?.companyId
    };
    dispatch(getCurrentPlanAction(data));
    dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
  }

  function previewQuestion() {
    window.open(`/question/${toBePurchasedAddOn?.questionId}`, '_blank').focus();
    const data = {
      CompanyId: user?.companyId
    };
    dispatch(getCurrentPlanAction(data));
    dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
  }

  const [assessmentPurchaseSuccess, setAssessmentPurchaseSuccess] = useState(false);
  const [questionPurchaseSuccess, setQuestionPurchaseSuccess] = useState(false);
  const [multiQuestionPurchaseSuccess, setMultiQuestionPurchaseSuccess] = useState(false);

  const premiumQControl = useSelector(premiumQControlResultredux);
  const isPremiumQuestion = useSelector(premiumQuestionCheckRedux);

  /* ----------- Payment Related Checks Above ----------- */

  /* ------------------- Onboarding Related Stuff Below ------------------- */
  const parent = useRef();

  const completedOnboardingNo = useSelector(completedOnboardingNoRedux);
  const onboardingStep = useSelector(onboardingStepRedux);
  const [QSopen, setQSopen] = useState(false);

  const AddAssessmentButtonId = document?.getElementById('AddAssessmentButtonId');

  function handleOutsideInAsssessment(event) {
    const createCustomId = document?.getElementById('createCustomButtonId');
    const searchId = document?.getElementById('searchBarIdinAddAssm');
    const QSid = document?.getElementById('QSid2');
    const OnboardingAddLogoId = document?.getElementById('OnboardingAddLogoId');
    const profileBanner = document?.getElementById('profile_Banner');
    const switchTab = document?.getElementById('tabSwitchContainer');

    if (
      createCustomId &&
      searchId &&
      QSid &&
      OnboardingAddLogoId &&
      // !AddAssessmentButtonId &&
      !createCustomId?.contains(event?.target) &&
      !searchId?.contains(event?.target) &&
      !QSid?.contains(event?.target) &&
      !OnboardingAddLogoId?.contains(event?.target) &&
      !profileBanner?.contains(event?.target) &&
      !AddAssessmentButtonId?.contains(event?.target) &&
      !switchTab?.contains(event?.target)
    ) {
      if (QSopen) {
        setQSopen(false);
      } else {
        dispatch(setOnboardingStep(0));
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideInAsssessment);

    return () => {
      document.removeEventListener('click', handleOutsideInAsssessment);
    };
  }, [QSopen]);

  const [onboardingCompletePopupShow, setOnboardingCompletePopupShow] = useState(false);

  useEffect(() => {
    if (completedOnboardingNo === 4 && localStorage.getItem('onboarding')) {
      setOnboardingCompletePopupShow(true);
      localStorage.removeItem('onboarding');
    }
  }, [completedOnboardingNo]);

  /* ------------------- Onboarding Related Stuff Above ------------------- */

  /* ---------------------- Search Related Methods ---------------------- */

  const [filteredList, setFilteredList] = useState([]);

  const filterAssessments = () => {
    const temp = [];
    rootCategories.forEach(e => {
      if (e.categoryName.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr'))) {
        temp.push(e);
        return;
      }
      const suitableAssessmentList = [];
      e.rootAssesments.forEach(rootAssessment => {
        if (rootAssessment.rootAssessmentName.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr'))) {
          suitableAssessmentList.push(rootAssessment);
        }
      });
      if (suitableAssessmentList.length !== 0) {
        const innerCategory = {
          categoryId: e.categoryId,
          categoryName: e.categoryName,
          rootAssesments: suitableAssessmentList
        };
        temp.push(innerCategory);
      }
    });
    setFilteredList(temp);
  };

  const [searchTimerId, setSearchTimerId] = useState(-1);

  useEffect(() => {
    if (searchTerm !== '') {
      if (searchTimerId !== -1) {
        clearTimeout(searchTimerId);
        setSearchTimerId(-1);
      }
      setSearchTimerId(
        setTimeout(() => {
          filterAssessments();
        }, 300)
      );
    } else {
      clearTimeout(searchTimerId);
      setFilteredList(rootCategories);
    }
  }, [searchTerm, rootCategories]);

  /* ---------------------- Search Related Methods Above ---------------------- */

  /* ------------------- Category Slider Related Stuff Below ------------------- */

  const [scrollArray, setScrollArray] = useState([]);
  const [icons, setIcons] = useState(false);
  const [scrollInAction, setScrollInAction] = useState(false);
  const [scrollPresent, setScrollPresent] = useState(true);

  function handleScrollIcons(listIndex) {
    const listLength = filteredList[listIndex].rootAssesments.length;
    const lastCard = document
      .getElementById(`card-${listIndex}-${listLength - 1}`)
      ?.getBoundingClientRect();
    const firstCard = document.getElementById(`card-${listIndex}-${0}`)?.getBoundingClientRect();
    const wrapper = document.getElementById(`catWrapper-${listIndex}`)?.getBoundingClientRect();
    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;

    const temp = scrollArray;
    if (scrollArray?.length !== 0) {
      if (lastCard.right <= wrapperRight) {
        temp[listIndex].right = false;
        setScrollArray(temp);
      } else {
        temp[listIndex].right = true;
        setScrollArray(temp);
      }

      if (firstCard?.left < wrapperLeft) {
        temp[listIndex].left = true;
        setScrollArray(temp);
      } else {
        temp[listIndex].left = false;
        setScrollArray(temp);
      }

      setIcons(!icons);
    }
    setScrollInAction(false);
  }

  function handleHorizontalScroll(elementId, direction, listIndex) {
    setScrollInAction(true);
    let availableCardCount = 0;
    let halfCard = 0;
    let halfCardLeft = 0;

    const wrapper = document.getElementById(`catWrapper-${listIndex}`)?.getBoundingClientRect();

    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;
    let scrollX = 0;
    let scrollXleft = 0;

    filteredList[listIndex]?.rootAssesments.every((catElem, index) => {
      const card = document.getElementById(`card-${listIndex}-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardRight < wrapperRight && cardLeft > wrapperLeft) {
        availableCardCount += 1;
      }

      if (windowW > 576) {
        if (cardRight + 14 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      } else {
        if (cardRight + 21 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      }
      return true;
    });

    filteredList[listIndex]?.rootAssesments.every((catElem, index) => {
      const card = document.getElementById(`card-${listIndex}-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardLeft < wrapperLeft && cardRight > wrapperLeft) {
        halfCardLeft = index;
        scrollXleft = wrapperRight - cardRight;
        return false; // works as break
      }
      return true;
    });

    if (windowW > 576) {
      // RIGHT scroll
      if (direction === 'right') {
        document.getElementById(elementId).scrollLeft -= scrollX + 14;
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById(elementId).scrollLeft -= scrollXleft - 14;
      } else {
        document.getElementById(elementId).scrollLeft -= availableCardCount * 451 - 14;
      }
    } else {
      // RIGHT scroll

      if (direction === 'right') {
        if (windowW === 375 && scrollXleft === 0) {
          document.getElementById(elementId).scrollLeft += 335 + 20;
        } else {
          document.getElementById(elementId).scrollLeft -= scrollX + 20;
        }
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById(elementId).scrollLeft -= scrollXleft - 20;
      } else {
        document.getElementById(elementId).scrollLeft -= availableCardCount * 375 - 20;
      }
    }

    setTimeout(() => {
      handleScrollIcons(listIndex);
    }, 800);
  }

  useEffect(() => {
    const temp = [];
    if (filteredList) {
      filteredList.forEach(cat => {
        temp.push({ left: false, right: true });
      });
      setScrollArray(temp);
    }
  }, [filteredList]);

  useEffect(() => {
    if (scrollArray && scrollArray.length !== 0 && !tab) {
      // tab parameter is used to ensure this does not work on Custom tab
      filteredList.forEach((cat, i) => {
        handleScrollIcons(i);
      });
    }
  }, [scrollArray]);

  /* ------------------- Category Slider Related Stuff Above ------------------- */

  /* ----------- Most Popular Related Scroll Methods ----------- */
  const mostPopularRootAssessmentList = useSelector(mostPopularRootAssessmentListRedux);
  const [scrollArrayForMostPopular, setScrollArrayForMostPopular] = useState([]);

  useEffect(() => {
    const temp = [];
    if (mostPopularRootAssessmentList && mostPopularRootAssessmentList.length !== 0) {
      temp.push({ left: false, right: true });
      setScrollArrayForMostPopular(temp);
    }
  }, [mostPopularRootAssessmentList]);

  function handleScrollIconsForMostPopular() {
    const listLength = mostPopularRootAssessmentList.length;
    const lastCard = document
      .getElementById(`card-mostPopular-${listLength - 1}`)
      ?.getBoundingClientRect();
    const firstCard = document.getElementById(`card-mostPopular-${0}`)?.getBoundingClientRect();
    const wrapper = document.getElementById('catWrapper-mostPopular')?.getBoundingClientRect();
    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;

    const temp = scrollArrayForMostPopular;
    if (scrollArrayForMostPopular.length !== 0) {
      if (lastCard?.right <= wrapperRight) {
        temp[0].right = false;
        setScrollArrayForMostPopular(temp);
      } else {
        temp[0].right = true;
        setScrollArrayForMostPopular(temp);
      }

      if (firstCard?.left < wrapperLeft) {
        temp[0].left = true;
        setScrollArrayForMostPopular(temp);
      } else {
        temp[0].left = false;
        setScrollArrayForMostPopular(temp);
      }

      setIcons(!icons);
    }
    setScrollInAction(false);
  }

  function handleHorizontalScrollForMostPopular(direction) {
    setScrollInAction(true);
    let availableCardCount = 0;
    let halfCard = 0;
    let halfCardLeft = 0;

    const wrapper = document.getElementById('catWrapper-mostPopular')?.getBoundingClientRect();

    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;
    let scrollX = 0;
    let scrollXleft = 0;

    mostPopularRootAssessmentList.every((catElem, index) => {
      const card = document.getElementById(`card-mostPopular-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardRight < wrapperRight && cardLeft > wrapperLeft) {
        availableCardCount += 1;
      }

      if (windowW > 576) {
        if (cardRight + 14 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      } else {
        if (cardRight + 21 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      }
      return true;
    });

    mostPopularRootAssessmentList.every((catElem, index) => {
      const card = document.getElementById(`card-mostPopular-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardLeft < wrapperLeft && cardRight > wrapperLeft) {
        halfCardLeft = index;
        scrollXleft = wrapperRight - cardRight;
        return false; // works as break
      }
      return true;
    });

    if (windowW > 576) {
      // RIGHT scroll
      if (direction === 'right') {
        document.getElementById('scrollable-mostPopular').scrollLeft -= scrollX + 14;
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById('scrollable-mostPopular').scrollLeft -= scrollXleft - 14;
      } else {
        document.getElementById('scrollable-mostPopular').scrollLeft -=
          availableCardCount * 451 - 14;
      }
    } else {
      // RIGHT scroll
      if (direction === 'right') {
        if (windowW === 375 && scrollXleft === 0) {
          document.getElementById('scrollable-mostPopular').scrollLeft += 335 + 20;
        } else {
          document.getElementById('scrollable-mostPopular').scrollLeft -= scrollX + 20;
        }
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById('scrollable-mostPopular').scrollLeft -= scrollXleft - 20;
      } else {
        document.getElementById('scrollable-mostPopular').scrollLeft -=
          availableCardCount * 375 - 20;
      }
    }

    setTimeout(() => {
      handleScrollIconsForMostPopular();
    }, 800);
  }

  useEffect(() => {
    if (scrollArrayForMostPopular && scrollArrayForMostPopular.length !== 0 && !tab) {
      handleScrollIconsForMostPopular();
    }
  }, [scrollArrayForMostPopular]);
  /* ----------- Most Popular Related Scroll Methods Above ----------- */

  const [selectedCategory, selectCategory] = useState();
  const [selectedJobRole, selectJobRole] = useState();
  const [selectedSeniority, selectSeniority] = useState();

  useEffect(async () => {
    const data = {};
    const a = await dispatch(getRootAssessmentDropdownListAction(data));
  }, []);

  const assmCategories = useSelector(rootAssmCategories);
  const assmJobRoles = useSelector(rootAssmJobRoles);
  const assmSeniorities = useSelector(rootAssmSeniorities);

  async function handleJobRoleSelected(item) {
    if (item?.name) {
      const data = { JobRoleId: item?.roleId };
      const a = await dispatch(getRootAssessmentDropdownListAction(data));
      const tempCategory = assmCategories.filter(
        cat => cat.catId === a.payload.selectedCategory
      )[0];
      selectCategory(tempCategory);
    }
  }

  useEffect(() => {
    if (window.innerHeight < document.body.scrollHeight) {
      setScrollPresent(true);
    } else {
      setScrollPresent(false);
    }
  }, [rootCategories, filteredList]);

  /* ----------------------- Scroll position for kebab menu ----------------------- */

  const [scrollPos, setScrollPos] = useState();

  useEffect(() => {
    if (tab) {
      document.addEventListener('scroll', e => {
        setScrollPos(window.scrollY);
      });
    }
    return () => {
      document.removeEventListener('scroll', e => {
        setScrollPos(window.scrollY);
      });
    };
  }, [tab]);

  // Handles fetch event for assessment filters
  function applyMobileFilter() {
    setSearchTerm('');
    const listData = {
      companyId: user.companyId,
      isCompanyAssessment: tab !== 0,
      CategoryId: selectedCategory?.catId,
      JobRoleId: selectedJobRole?.roleId,
      SeniorityLevel: selectedSeniority?.seniorityId
    };
    dispatch(
      setSelectedRootAssmFilters({
        category: selectedCategory,
        role: selectedJobRole,
        seniority: selectedSeniority
      })
    );
    fetchRootAssessmentList(listData, setLoading, ref, dispatch);
    setTimeout(() => {
      updateFilterButton();
    }, 700);
  }

  function clearAllMobile() {
    setSearchTerm('');
    const listData = {
      companyId: user.companyId,
      isCompanyAssessment: tab !== 0
    };
    fetchRootAssessmentList(listData, setLoading, ref, dispatch);
    setFilteredCount(0);
  }

  // Handles disability of clear all button
  function updateFilterButton() {
    if (selectedCategory && selectedJobRole && selectedSeniority) {
      setFilteredCount(3);
    } else if (
      (selectedCategory && selectedJobRole && !selectedSeniority) ||
      (selectedCategory && selectedSeniority && !selectedJobRole) ||
      (selectedJobRole && selectedSeniority && !selectedCategory)
    ) {
      setFilteredCount(2);
    } else if (
      (selectedCategory && !selectedJobRole && !selectedSeniority) ||
      (!selectedCategory && selectedSeniority && !selectedJobRole) ||
      (selectedJobRole && !selectedSeniority && !selectedCategory)
    ) {
      setFilteredCount(1);
    } else {
      setFilteredCount(0);
    }
  }

  // Hide keyboard
  useHideKeyboard();

  return (
    <>
      {windowW <= 576 && showmobileFilter && (
        <MobileAssessmentFilterModal
          show={showMobileFilterModal}
          onHide={() => setShowMobileFilterModal(false)}
          id="mobile-assessment-filter-modal"
          setLoading={setLoading}
          setfilterNo={setFilteredCount}
          selectedCategory={selectedCategory}
          selectCategory={selectCategory}
          selectedJobRole={selectedJobRole}
          selectJobRole={selectJobRole}
          selectedSeniority={selectedSeniority}
          selectSeniority={selectSeniority}
          applyFilter={applyMobileFilter}
          clearAll={clearAllMobile}
        />
      )}
      {windowW > 700 && (
        <QuickStart
          quickStartId="QSid2"
          parentRef={parent}
          show={user?.isFirstAdmin && completedOnboardingNo !== 4}
          open={QSopen}
          setOpen={setQSopen}
          relativeItemId="createCustomButtonId"
        />
      )}
      <CreateAssessmentPopup
        show={onboardingCompletePopupShow}
        setShow={setOnboardingCompletePopupShow}
        xCenter
        yCenter
        imageSrc={imageSrc}
        title="Coensio Master"
        text="Congratulations! You have completed all steps to be a master user"
      />

      <CreateAssessmentContent
        id="firstAddAssessmentPage"
        className="firstAddAssessmentPage"
        style={{ paddingRight: scrollPresent ? '0px' : '12px' }}
      >
        {/* START  of ASSESMENT ADD-ON PURCHASE */}
        <HeadsUpModal
          addOnCode={10}
          show={assessmentUnavailable}
          onHide={() => setAssessmentUnavailable(false)}
          title="Hello there!"
          description={
            !myPlan?.isCustom
              ? `Your current plan seems to include ${
                  publishEnabledCheck?.publishAssessmentRightCount
                } published assessment${publishEnabledCheck?.publishAssessmentRightCount > 1 ? `s` : ''}.
         Add an additional published assessment only for $${publishEnabledCheck?.price}.`
              : `Your plan seems to include ${publishEnabledCheck?.publishAssessmentRightCount} published assessments.
         Please contact our sales team to upgrade your plan.`
          }
          isCustom={myPlan?.isCustom}
          setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setAssessmentPurchaseSuccess}
          tryAgain={() => console.log('try again premium Q')}
        />
        {/* Purchase Assessment AddOn Success */}
        <PurchaseAddOnSuccessModal
          show={assessmentPurchaseSuccess}
          onHide={() => {
            setAssessmentPurchaseSuccess(false);
            goToAddAssessmentFlow();
          }}
          addOnCode={10}
        />

        {/* USED everywhere in this page no need to multiply the code */}
        <ContactSalesSuccess
          icon="tick"
          show={contactSalesSuccessShow}
          onHide={() => setContactSalesSuccessShow(false)}
          title="Your request has been received!"
          description="One of our experts will contact you shortly."
        />
        <FreeTrialModal
          show={assessmentUnavailableInFT}
          onHide={() => setAssessmentUnavailableInFT(false)}
          addOnCode={10}
        />
        {/* END of ASSESMENT ADD-ON PURCHASE */}

        <CustomAssessmentModal
          nextStage={() => {
            setNameModal(true);
            setCustomModal(false);
          }}
          show={showCustomModal}
          onHide={() => {
            setCustomModal(false);
          }}
        />
        {nameAssmShow && (
          <NameAssessmentModal
            onDuplicate={updateAssessment}
            prevStage={() => {
              setNameModal(false);
            }}
            show={showNameModal}
            onHide={() => {
              dispatch(setAssessmentId(null));
              setNameModal(false);
            }}
          />
        )}

        <InfoBanner
          bannerStyle={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 24px rgba(134, 134, 134, 0.3)'
          }}
          text="Assessment duplicated!"
          show={duplicated}
          setStatus={type => {
            setDuplicated(type);
          }}
        />
        {showAssmDetailModal && (
          <AssessmentDetailsModal
            show={showAssessmentDetail}
            category={category}
            questions={selectedRootAssessmentQuestionList}
            onDetailHide={() => {
              setDetail(false);
              dispatch(setAssessmentId(null));
            }}
            nextStage={() => {
              setNameModal(true);
              setDetail(false);
            }}
            onCreate={async () => {
              await handleAddAssessment();
            }}
            setSinglePremiumQuestion={
              myPlan?.name === 'Free Trial' ? setPremiumQuestionInFT : setPremiumQuestion
            }
          />
        )}

        {/* START OF MULTI QUESTION ADD-ON PURCHASE */}
        <HeadsUpModal
          addOnCode={63}
          show={premiumQExistModal}
          onHide={() => setPremiumQExistModal(false)}
          title="Heads up!"
          description={
            myPlan?.isCustom
              ? `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Please contact our sales team to upgrade your plan.`
              : `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Total price will be only $${premiumQControl?.totalPrice}. Do you want to proceed?`
          }
          isCustom={myPlan?.isCustom}
          setSuccess={
            myPlan?.isCustom ? setContactSalesSuccessShow : setMultiQuestionPurchaseSuccess
          }
          tryAgain={() => console.log('try again multiple premium Q')}
        />

        <FreeTrialModal
          show={premiumQExistInFT}
          onHide={() => setPremiumQExistInFT(false)}
          addOnCode={63}
        />
        {/* Purchase Multiple Question AddOn Success */}
        <PurchaseAddOnSuccessModal
          show={multiQuestionPurchaseSuccess}
          onHide={() => {
            setMultiQuestionPurchaseSuccess(false);
            goToAddAssessmentFlow();
          }}
          addOnCode={63}
        />
        {/* END OF MULTI QUESTION ADD-ON PURCHASE */}

        {/* START OF SINGLE QUESTION ADD-ON PURCHASE */}
        <HeadsUpModal
          addOnCode={65}
          show={premiumQuestion}
          onHide={() => setPremiumQuestion(false)}
          title="This one is a premium question"
          description={
            myPlan?.isCustom
              ? ` Your plan does not include this question.
              Please contact our sales team to upgrade your plan.`
              : `Get this premium question for only $${isPremiumQuestion?.price} to take your assessment to the next level.`
          }
          isCustom={myPlan?.isCustom}
          setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setQuestionPurchaseSuccess}
          tryAgain={() => console.log('try again premium Q')}
        />

        <FreeTrialModal
          show={premiumQuestionInFT}
          onHide={() => setPremiumQuestionInFT(false)}
          addOnCode={65}
        />

        {/* Purchase Single Question AddOn Success */}
        <PurchaseAddOnSuccessModal
          show={questionPurchaseSuccess}
          onHide={() => {
            setQuestionPurchaseSuccess(false);
            previewQuestion();
          }}
          addOnCode={65}
        />
        {/* END OF SINGLE QUESTION ADD-ON PURCHASE */}

        <InfoBanner
          text="An error occurred. Please try again."
          show={
            continueToAssessmentCustomizationFlag === false ||
            updateCustomAssessmentCheckFlag === false
          }
          CustomIcon={Error}
          setStatus={type => {
            dispatch(setContinueToAssessmentCustomization('pending'));
            dispatch(setUpdateCustomAssessmentCheck('pending'));
          }}
        />

        <DeleteAssessmentModal
          onDelete={deleteAssessment}
          onHide={() => {
            setDeleteModal(false);
          }}
          show={deleteModal}
        />

        <InfoBanner
          bannerStyle={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 24px rgba(134, 134, 134, 0.3)'
          }}
          text="Assessment deleted!"
          show={deleted}
          setStatus={type => {
            setDeleted(type);
          }}
        />
        <div className={`row ${styles['first-header']} ${styles.row}`}>
          {windowW > 576 && (
            <GoBackButton onClick={() => history.goBack()} style={{ marginRight: '0px' }} />
          )}
          <div style={{ marginRight: 'auto' }} id="tabSwitchContainer">
            <TabSwitch
              tabNameList={['Coensio Library', 'My Company Library']}
              onClickFuncList={[
                () => {
                  // setTab(0);
                  setLoading(true);
                  setSearchTerm('');
                  selectCategory(null);
                  selectJobRole(null);
                  selectSeniority(null);
                  dispatch(setAssessmentActiveTab(0));
                  dispatch(getRootAssessmentDropdownListAction({}));
                },
                () => {
                  // setTab(1);
                  setLoading(true);
                  setSearchTerm('');
                  selectCategory(null);
                  selectJobRole(null);
                  selectSeniority(null);
                  dispatch(setAssessmentActiveTab(1));
                }
              ]}
              height={windowW > 576 ? '40px' : '36px'}
              marginBetweenTabs="15px"
              switchHighlighted={onboardingStep === 1 && tab === 1}
              myStyle={{ fontSize: windowW <= 576 && '14px' }}
            />
          </div>
          {windowW > 710 ? (
            <Highlight
              paddingPixel={4}
              borderRadius={12}
              isHighlighted={onboardingStep === 4}
              child={
                <CustomButton
                  type={1}
                  buttonId="createCustomButtonId"
                  textField="Create Custom Assessment"
                  buttonOnClick={() => {
                    setCustomModal(true);
                    dispatch(setOnboardingStep(0));
                    dispatch(setQuickStartOpen(false));
                    /* Mixpanel Button Click Event */
                    mixpanel.track('Create Custom Assessment - Button Click', {
                      'User Id': user.userId,
                      'Company Name': user.companyName,
                      'Name Surname': user.userNameSurname
                    });
                  }}
                />
              }
            />
          ) : null}
        </div>

        {/* FILTER ROW */}
        {!tab && windowW > 576 ? (
          <div className={styles.dropdownContainer}>
            <Dropdown
              height="48px"
              list={assmCategories}
              headerStyle={{ fontSize: '16px' }}
              listStyle={{ fontSize: '16px' }}
              isSearchable
              isCancellable
              openItemCount={assmCategories?.length < 4 ? assmCategories?.length : 4}
              placeholder="Select category"
              title="Category"
              setSelectedOption={selectCategory}
              selectedOption={selectedCategory}
              crossOnClick={async () => {
                await dispatch(setRootAssmJobRoles([]));
                await setLoading(true);
                selectJobRole(null);
                selectCategory(null);
                dispatch(getRootAssessmentDropdownListAction({}));
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
              selectOnClick={async item => {
                await setSearchTerm('');
                await dispatch(setRootAssmJobRoles([]));
                await setLoading(true);
                selectJobRole(null);
                dispatch(getRootAssessmentDropdownListAction({ Category: item.catId }));
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: item.catId,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
            />
            <Dropdown
              height="48px"
              list={assmJobRoles}
              headerStyle={{ fontSize: '16px' }}
              listStyle={{ fontSize: '16px' }}
              isSearchable
              isCancellable
              openItemCount={assmJobRoles?.length < 4 ? assmJobRoles?.length : 4}
              placeholder="Select job role"
              title="Job Role"
              setSelectedOption={selectJobRole}
              selectedOption={selectedJobRole}
              selectOnClick={async item => {
                await setSearchTerm('');
                await setLoading(true);
                await dispatch(setRootAssmCategories([]));
                await handleJobRoleSelected(item);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  JobRoleId: item.roleId,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
              crossOnClick={async () => {
                setLoading(true);
                selectJobRole(null);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: selectedCategory?.catId,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
            />
            <Dropdown
              height="48px"
              list={assmSeniorities}
              headerStyle={{ fontSize: '16px' }}
              listStyle={{ fontSize: '16px' }}
              isCancellable
              openItemCount={3}
              placeholder="Select seniority"
              title="Seniority"
              setSelectedOption={selectSeniority}
              selectedOption={selectedSeniority}
              selectOnClick={async item => {
                setSearchTerm('');
                setLoading(true);
                selectSeniority(null);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: selectedCategory?.catId,
                  JobRoleId: selectedJobRole?.roleId,
                  SeniorityLevel: item?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
              crossOnClick={async () => {
                setLoading(true);
                selectSeniority(null);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: selectedCategory?.catId,
                  JobRoleId: selectedJobRole?.roleId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
            />
            <Highlight
              paddingPixel={4}
              borderRadius={12}
              isHighlighted={onboardingStep === 1 && tab === 0}
              child={
                <CustomSearchbar
                  searchbarId="searchBarIdinAddAssm"
                  isExtended
                  wrapperStyle={{
                    height: '48px',
                    background: '#FFFFFF',
                    border: '1px solid rgba(57, 57, 57, 0.15)',
                    borderRadius: '10px'
                  }}
                  inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
                  inputFieldValue={searchTerm}
                  inputFieldPlaceholder="Search"
                  inputFieldOnChange={event => {
                    dispatch(setOnboardingStep(0));
                    setSearchTerm(event.target.value);
                    setQSopen(false);
                  }}
                />
              }
            />
          </div>
        ) : !tab ? (
          <div className={styles.dropdownContainer}>
            <Highlight
              paddingPixel={4}
              borderRadius={12}
              isHighlighted={onboardingStep === 1 && tab === 0}
              child={
                <CustomSearchbar
                  searchbarId="searchBarIdinAddAssm"
                  isExtended
                  wrapperStyle={{
                    height: '42px',
                    background: '#FFFFFF',
                    border: '1px solid rgba(57, 57, 57, 0.15)',
                    borderRadius: '10px'
                  }}
                  inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
                  inputFieldValue={searchTerm}
                  inputFieldPlaceholder="Search"
                  inputFieldOnChange={event => {
                    dispatch(setOnboardingStep(0));
                    setSearchTerm(event.target.value);
                    setQSopen(false);
                  }}
                />
              }
            />
            <div
              className={`${styles.filterButton} ${filteredCount > 0 && styles.active}`}
              onClick={() => setShowMobileFilterModal(true)}
            >
              {filteredCount === 0 ? (
                <img alt="filter-icon" src={FilterIcon} />
              ) : (
                <img alt="filter-icon-p" src={FilterIconPurple} />
              )}
              <div>Filter</div>
              <div style={{ height: '18px' }}>
                {filteredCount === 1 ? (
                  <img style={{ verticalAlign: 'baseline' }} alt="filter-1" src={purpleCircle1} />
                ) : filteredCount === 2 ? (
                  <img style={{ verticalAlign: 'baseline' }} alt="filter-2" src={purpleCircle2} />
                ) : filteredCount === 3 ? (
                  <img style={{ verticalAlign: 'baseline' }} alt="filter-3" src={purpleCircle3} />
                ) : (
                  <div style={{ height: '18px', width: '18px' }} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <CustomSearchbar
            searchbarId="searchBarIdinAddAssm"
            isExtended
            wrapperStyle={{
              height: windowW > 576 ? '48px' : '42px',
              background: '#FFFFFF',
              border: '1px solid rgba(57, 57, 57, 0.15)',
              borderRadius: '10px',
              margin: '0.6rem 0rem 2.4rem'
            }}
            inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
            inputFieldValue={searchTerm}
            inputFieldPlaceholder="Search"
            inputFieldOnChange={event => {
              dispatch(setOnboardingStep(0));
              setSearchTerm(event.target.value);
              setQSopen(false);
            }}
          />
        )}

        {!loading && rootCategories?.length === 0 && tab ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10rem' }}>
            <BackgroundImageWrapper
              image={noCustomAssessImg}
              header="Build your own library"
              subtext="Create custom assessments and pre-assess candidates your own way"
            />
          </div>
        ) : null}

        {!loading && filteredList?.length === 0 && rootCategories?.length !== 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10rem' }}>
            <BackgroundImageWrapper
              image={noResult}
              header={tab === 0 ? 'Keep searching for the best' : 'No assessments found'}
              subtext={
                tab === 0
                  ? 'We couldn’t find an assessment. Please rearrange filters or create custom assessment'
                  : 'Please make sure you are looking for the right name'
              }
            />
          </div>
        ) : null}

        {/* Most Popular category for Coensio Library + No search parameter */}
        {!tab && mostPopularRootAssessmentList?.length > 0 && searchTerm === '' ? (
          <div
            key="mostPopularWrapper"
            className={`${styles.categoryWrapper}`}
            id="catWrapper-mostPopular"
          >
            {windowW > 576 ? (
              <div className={styles.headerWrapper}>
                <span className={styles.mainCategory}>🚀 MOST POPULAR</span>
                {/* TODO: Add slider for Most Popular category */}
                <div className={styles.scrollWrapper}>
                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArrayForMostPopular[0]?.left ? leftScroll : leftScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScrollForMostPopular('left');
                        }
                      }}
                      style={{ cursor: scrollArrayForMostPopular[0]?.left ? 'pointer' : 'auto' }}
                    />
                  </span>
                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArrayForMostPopular[0]?.right ? rightScroll : rightScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScrollForMostPopular('right');
                        }
                      }}
                      style={{ cursor: scrollArrayForMostPopular[0]?.right ? 'pointer' : 'auto' }}
                    />
                  </span>
                </div>
              </div>
            ) : (
              <div className={styles.headerWrapper}>
                <span className={styles.scrollIcon}>
                  <img
                    aria-current={icons}
                    alt=""
                    src={scrollArrayForMostPopular[0]?.left ? leftScroll : leftScrollLight}
                    onClick={() => {
                      if (!scrollInAction) {
                        handleHorizontalScrollForMostPopular('left');
                      }
                    }}
                    style={{ cursor: scrollArrayForMostPopular[0]?.left ? 'pointer' : 'auto' }}
                  />
                </span>
                <span className={styles.mainCategory}>🚀 MOST POPULAR</span>

                <span className={styles.scrollIcon}>
                  <img
                    aria-current={icons}
                    alt=""
                    src={scrollArrayForMostPopular[0]?.right ? rightScroll : rightScrollLight}
                    onClick={() => {
                      if (!scrollInAction) {
                        handleHorizontalScrollForMostPopular('right');
                      }
                    }}
                    style={{ cursor: scrollArrayForMostPopular[0]?.right ? 'pointer' : 'auto' }}
                  />
                </span>
                {/* </div> */}
              </div>
            )}
            <div
              className={styles.categoryInnerWrapper}
              id="scrollable-mostPopular"
              onTouchEnd={() => handleScrollIconsForMostPopular()}
            >
              {mostPopularRootAssessmentList?.map((e, i) => (
                <RootAssessmentCard
                  rootAssessmentDetails={e}
                  setSelectedRootAssessmentDetails={setCategory}
                  setSelectedRootAssessmentQuestionList={sendCategoryDetailModal}
                  setDetailsModalState={setDetail}
                  setDeleteModalState={setDeleteModal}
                  setDuplicateModalState={setNameModal}
                  wrapperStyle={{ marginTop: '10px', marginBottom: '10px' }}
                  cardId={`card-mostPopular-${i}`}
                />
              ))}
            </div>
          </div>
        ) : null}

        {/* Coensio Library - categories with sliders */}
        {!tab &&
          filteredList?.map((assess, index) => (
            <div key={index} className={`${styles.categoryWrapper}`} id={`catWrapper-${index}`}>
              {windowW > 576 ? (
                <div className={styles.headerWrapper}>
                  <span className={styles.mainCategory}>{assess.categoryName}</span>
                  <div className={styles.scrollWrapper}>
                    <span className={styles.scrollIcon}>
                      <img
                        aria-current={icons}
                        alt=""
                        src={scrollArray[index]?.left ? leftScroll : leftScrollLight}
                        onClick={() => {
                          if (!scrollInAction) {
                            handleHorizontalScroll(`scrollable-${index}`, 'left', index);
                          }
                        }}
                        style={{ cursor: scrollArray[index]?.left ? 'pointer' : 'auto' }}
                      />
                    </span>
                    <span className={styles.scrollIcon}>
                      <img
                        aria-current={icons}
                        alt=""
                        src={scrollArray[index]?.right ? rightScroll : rightScrollLight}
                        onClick={() => {
                          if (!scrollInAction) {
                            handleHorizontalScroll(`scrollable-${index}`, 'right', index);
                          }
                        }}
                        style={{ cursor: scrollArray[index]?.right ? 'pointer' : 'auto' }}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div className={styles.headerWrapper}>
                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArray[index]?.left ? leftScroll : leftScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScroll(`scrollable-${index}`, 'left', index);
                        }
                      }}
                      style={{ cursor: scrollArray[index]?.left ? 'pointer' : 'auto' }}
                    />
                  </span>
                  <span className={styles.mainCategory}>{assess.categoryName}</span>

                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArray[index]?.right ? rightScroll : rightScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScroll(`scrollable-${index}`, 'right', index);
                        }
                      }}
                      style={{ cursor: scrollArray[index]?.right ? 'pointer' : 'auto' }}
                    />
                  </span>
                </div>
              )}

              <div
                className={styles.categoryInnerWrapper}
                onTouchEnd={() => handleScrollIcons(index)}
                id={`scrollable-${index}`}
              >
                {Array.isArray(assess.rootAssesments) &&
                  assess.rootAssesments.map((cat, innerIndex) => (
                    <>
                      <RootAssessmentCard
                        rootAssessmentDetails={cat}
                        isCustom={tab}
                        setSelectedRootAssessmentDetails={setCategory}
                        setSelectedRootAssessmentQuestionList={sendCategoryDetailModal}
                        setDetailsModalState={setDetail}
                        setDeleteModalState={setDeleteModal}
                        setDuplicateModalState={setNameModal}
                        wrapperStyle={{
                          marginTop: '10px',
                          marginBottom: '10px'
                        }}
                        cardId={`card-${index}-${innerIndex}`}
                      />
                    </>
                  ))}
              </div>
            </div>
          ))}

        {/* My Company Library (Custom) - categories with scrolldown */}
        {tab
          ? filteredList?.map((assess, index) => (
              <div
                key={index}
                className={`${styles.CustomCategoryWrapper}`}
                id={`catWrapperCustom-${index}`}
              >
                <div className={styles.headerWrapper}>
                  <span className={styles.mainCategory}>Custom assessments</span>
                </div>
                <div className={styles.CustomWrapper}>
                  {assess?.rootAssesments?.map((cat, innerIndex) => (
                    <div className={styles.CustomCardWrapper}>
                      <RootAssessmentCard
                        rootAssessmentDetails={cat}
                        isCustom={tab}
                        setSelectedRootAssessmentDetails={setCategory}
                        setSelectedRootAssessmentQuestionList={sendCategoryDetailModal}
                        setDetailsModalState={setDetail}
                        setDeleteModalState={setDeleteModal}
                        setDuplicateModalState={setNameModal}
                        wrapperStyle={{ margin: '12px 0px' }}
                        cardId={`card-${index}-${innerIndex}`}
                        scrollPos={scrollPos}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          : null}

        {loading && (
          <div className="pb-5">
            <div
              className="d-flex justify-content-center"
              style={{
                alignItems: 'center',
                height: 'calc(100vh - 280px)'
              }}
            >
              <Loading />
            </div>
          </div>
        )}
      </CreateAssessmentContent>
    </>
  );
}
