import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from '../Styles/CreateAssessmentBody.module.css';
import NameCustomAssessment from './NameCustomAssessment';
import AddAssessmentHeader from './AddAssessmentHeader';
import AddConnection from './AddConnection';
import AuthorizeUser from './AuthorizeUser';
import PublishBody from './PublishBody';
import InviteUserModal from '../Modals/InviteUserModal';
import { inviteUserShow } from '../../../redux/assessmentsVol2/selectors';
import { setInviteUserShow } from '../../../redux/assessmentsVol2/slice';
import AssessmentCreationPage from './AssessmentCreationPage';
import { AssessmentName } from '../../../redux/addAssessment/selectors';
import { viewHeight } from '../../../redux/user/selectors';
import { useWindowSize } from '../../../utils/helpers';
import GoBackButton from '../../../Common/Components/GoBackButton';
import goBackPurple from '../Images/goBackPurple.svg';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';

export default function CreateAssessmentBody({ setShowCopy }) {
  const { index, id } = useParams();
  const inviteShow = useSelector(inviteUserShow);
  const dispatch = useDispatch();
  const assessmentName = useSelector(AssessmentName);
  const height = useSelector(viewHeight);
  const history = useHistory();

  const [onboarding, setOnboarding] = useState(false);

  const [windowW, windowH] = useWindowSize();

  const [errorMessage, setErrorMessage] = useState('');
  const [errorBannerShow, setErrorBannerShow] = useState(false);
  const [successBannerShow, setSuccessBannerShow] = useState(false);

  useEffect(() => {
    // sayfaya refresh atıldığındaki caseler
    if (!assessmentName && index === '4' && localStorage.getItem('addAssessmentCompanyId')) {
      history.replace(`/create-assessment/${id}/${5}`);
    } else if (!assessmentName && (index === '2' || index === '3' || index === '4')) {
      history.replace(`/create-assessment/${id}/${1}`);
    }
  }, [assessmentName]);

  const handlePageIndex = index => {
    switch (index) {
      case 1:
        return <NameCustomAssessment id={id} />;
      case 2:
        return <AuthorizeUser id={id} />;
      case 3:
        return <AddConnection id={id} />;
      case 4:
        return <PublishBody id={id} setShowCopy={setShowCopy} setOnboardingOpen={setOnboarding} />;
      default:
        return <AssessmentCreationPage />;
    }
  };

  const MobileBackToAssessmentsHeader = () => {
    return (
      <div className={styles.goBackRow} onClick={() => history.replace(`/add-assessment`)}>
        <img style={{ marginRight: '7px' }} alt="go-back-mobile" src={goBackPurple} />
        <div className={styles.goBackText}>Back to Assessment Library</div>
      </div>
    );
  };

  return (
    <div
      className={styles.contentBody}
      style={{
        height: windowW >= 700 && `calc(100vh - ${height}px)`,
        overflow: onboarding && 'hidden'
      }}
    >
      <InviteUserModal
        show={inviteShow}
        onHide={() => dispatch(setInviteUserShow(false))}
        createAssessment
        setErrorText={setErrorMessage}
        setErrorShow={setErrorBannerShow}
        setSuccessShow={setSuccessBannerShow}
      />
      <InfoBanner
        text={errorMessage}
        show={errorBannerShow}
        CustomIcon={Error}
        setStatus={type => {
          setErrorBannerShow(type);
        }}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />

      <InfoBanner
        bannerStyle={{
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 4px 24px rgba(134, 134, 134, 0.3)'
        }}
        text="New user invitation sent!"
        show={successBannerShow}
        setStatus={type => {
          setSuccessBannerShow(type);
        }}
      />

      <div
        className={`${windowW > 700 ? styles.contentWrapper : styles.mobileContentWrapper} ${
          parseInt(index, 10) !== 5 && windowW > 700 && styles.gridWrapper
        }`}
      >
        {windowW < 700 && index !== '5' && index !== '4' && <MobileBackToAssessmentsHeader />}

        {index !== '5' && <AddAssessmentHeader activeNumber={parseInt(index, 10)} />}

        {handlePageIndex(parseInt(index, 10))}
      </div>
    </div>
  );
}
