import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const getCompanySubjectAndEmailContentService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanySubjectAndEmailContent, { params: data }).then(resolve).catch(reject);
  });

export const sendRejectedEmailContentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendRejectedEmailContent, data).then(resolve).catch(reject);
  });

export const createRejectedEmailContentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createRejectedEmailContent, data).then(resolve).catch(reject);
  });
