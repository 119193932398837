import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CandidateResultPageContent from '../Components/CandidateResultPageContent';
import Loading from '../../../Common/Components/Loading';
import ellipse from '../../../images/resultBackground.svg';
import { applicantAssessmentDetailWithEmailAction, getNewOpsCompanyListAction, getScreeningQuestionsAction } from '../../../redux/AssessmentSession/actions';
import { CandidateEmail, newOpLoadingRedux, screeningLoadingRedux } from '../../../redux/AssessmentSession/selectors';
import {
  ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD,
  ASSESSMENT_FLOW_MOBILE_THRESHOLD,
} from '../../../utils/helpers';
import Header from '../Components/Header/Header';
import styles from '../Styles/CandidateResultPage.module.css'

const CandidateResultPage = ({ application }) => {
  const { search } = useLocation();
  const { id } = useParams();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const email = useSelector(CandidateEmail);
  const dispatch = useDispatch();
  const [style, setStyle] = useState({
    position: 'absolute',
    height: '20%',
    transition: '0.5s',
    width: '100%',
    backgroundColor: '#fafafa'
  });
  const [innerPhone, setInnerPhone] = useState();
  const [isAlreadyPassed, setIsAlreadyPassed] = useState();
  const [loadingAssessmentInfo, setLoadingAssessmentInfo] = useState(true);

  const [ellipseStyle, setEllipseStyle] = useState({
    marginTop: '-30vh'
  });
  
  useEffect(async () => {
    setLoadingAssessmentInfo(true);
    const jsonData = {
      companyAssessmentKey: id || assessmentId,
      email,
      kariyerAtsUtm: localStorage.getItem('kariyernetToken'),
    };
    const resp = await dispatch(applicantAssessmentDetailWithEmailAction(jsonData));
    const innerPhone = resp.payload.phone;
    setIsAlreadyPassed(localStorage.getItem('isCandidateAlreadyPassed'));
    // setIsAlreadyPassed(resp.payload.isAlreadyPassed);
    if (innerPhone && !innerPhone.startsWith('+')) {
      setInnerPhone(`+${innerPhone}`);
    }
    setLoadingAssessmentInfo(false);
    setTimeout(() => {
      setStyle({ ...style, height: '100%' });
      setEllipseStyle({ marginTop: '-15vh' });
    }, 200);
  }, []);

  const newOpLoadingFlag = useSelector(newOpLoadingRedux);

  useEffect(async () => {
    if (assessmentId || id) {
      const data = {
        email: email,
        companyAssessmentKey: assessmentId || id,
      };
      const resp = await dispatch(getNewOpsCompanyListAction(data));
    }
  }, [assessmentId, id]);

  const screeningLoadingFlag = useSelector(screeningLoadingRedux);

  useEffect(() => {
    // Email = 10,
    // EncryptUserId = 20,
    // EncryptEmail = 30
    if (assessmentId || id) {
      const data = {
        UserInfo: email,  // email
        UserInfoType: 10,
      };
      
    dispatch(getScreeningQuestionsAction(data));
  }
  }, [assessmentId, id])


  return (
    <ContentBody>
      { 
      loadingAssessmentInfo || newOpLoadingFlag || screeningLoadingFlag ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <>
          <PageWrapper>
            <div className={styles.CandidateResultPageWrapper}>
              {/* <Background className={styles.background */}
              <Ellipse />
              <div style={{ zIndex: 1 }}>
                <Header transparent />
              </div>
              <ContentWrapper>
                <CandidateResultPageContent
                  application={application}
                  id={id || assessmentId}
                  innerPhone={innerPhone}
                  isAlreadyPassed={isAlreadyPassed}
                />
              </ContentWrapper>
            </div>
          </PageWrapper>
        </>
      )}
    </ContentBody>
  );
};

export default CandidateResultPage;



const ContentBody = styled.div`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: #FAFAFA;
  position: relative;
  display: block;
  /*background-size: 100vw auto;
  background-position-y: top;
  background-repeat: no-repeat;
  background-image: url(${ellipse});
  z-index: 1;*/
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: flex-start;
  display: flex;
  z-index: 0;
  flex-direction: column;
`;
const Ellipse = styled.div`
  width: 120vw;
  position: absolute;
  z-index: 1;
  transform: rotate(180deg);
  height: 40vh;
  background: #f1eff7;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  left: -10vw;
  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    border-radius: 100% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 60%;
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px;
  max-width: 700px;
  padding: 0.4rem 2.6rem 2.6rem 2.6rem; // FIXME
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    max-width: none;
    min-width: 0;
    width: 95%;
    padding: 2.4rem;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;