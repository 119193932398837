import React, { cloneElement, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import styles from '../Styles/Tooltip.module.css';


const StyledReactTooltip = styled(ReactTooltip)`
background-color: ${props => props.tooltipColor} !important;
width: fit-content;
max-width: 300px;
font-family: 'Jost-300';
color: #585858 !important;
opacity: 1 !important;
border-radius: 12px !important;
border: 1px solid ${props => props.tooltipColor !== 'white' ? props => props.tooltipColor : 'rgba(88, 88, 88, 0.2)'} !important;
box-shadow: 0 1px 18px rgb(88 88 88 / 20%) !important;
display: flex !important;
z-index: 20000 !important;
word-break: break-word;
font-size: 14px !important;
margin-top: ${props => props.marginTop} !important;
padding: ${props => props.padding} !important;
`;

const StyledSpan = styled.span`
  display: flex;
  height: fit-content;
  opacity: 1 !important;
  width: max-content;
  max-width: ${props => props.maxWidth};
  max-height: fit-content;
  max-block-size: fit-content;
  word-break: break-word;
  word-wrap: break-word;
  font-family: ${props => props.fontFamilyForText1 ? props => props.fontFamilyForText1 : 'Jost-400'} !important;
  font-size: ${props => props.fontSize} !important;
  color: ${props => props.textColor};
`;

const Tooltip = ({
  className,
  children,
  direction = 'top',
  text,
  text2,
  delay,
  fontSize,
  show = true,
  tooltipColor = 'white',
  textColor = '#585858',
  customStyle = {},
  marginTop,
  padding,
  fontFamilyForText1,
  tooltipId,
  maxWidth = 'unset',
}) => {
  const [active, setActive] = useState(false);

  const ref = useRef();

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [id, setId] = useState(makeid(10));
  useEffect(() => {
    let localRef = null;
    let localid = null;
    if (id) localid = id;
    if (ref.current) localRef = ref.current;
    return () => {
      ReactTooltip.hide(localRef);
      document.getElementById(localid)?.remove();
    };
  }, []);

  return (
    <span
      style={{ width: 'fit-content', height: 'fit-content', display: 'flex', ...customStyle }}
      data-for={id}
      data-tip="hello"
      ref={ref}
      id={tooltipId}
      onPointerEnter={event => {
        if (show) {
          const p = document.createElement('span');
          p.id = id;
          ReactDOM.render(
            <StyledReactTooltip
              className={styles.tooltip}
              multiline
              arrowColor={tooltipColor}
              effect="solid"
              type="light"
              place={direction}
              id={id}
              tooltipColor={tooltipColor}
              marginTop={marginTop}
              padding={padding}
            >
              {text2 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <StyledSpan fontSize={fontSize} fontFamilyForText1={fontFamilyForText1} textColor={textColor}>{text}</StyledSpan>
                  <div style={{ border: '0.2px solid #F4F4F4', width: '100%' }} />
                  <div
                    style={{
                      color: textColor,
                      wordBreak: 'break-word',
                      textAlign: 'center',
                      fontSize: fontSize,
                      fontFamily: 'Jost-300'
                    }}
                  >
                    {text2}
                  </div>
                </div>
              ) : (
                <StyledSpan maxWidth={maxWidth} fontSize={fontSize} fontFamilyForText1={fontFamilyForText1} textColor={textColor}>{text}</StyledSpan>
              )}
            </StyledReactTooltip>,
            document.body.appendChild(p)
          );
          ReactTooltip.show(ref);
        }
      }}
      onPointerLeave={event => {
        if (show) {
          ReactTooltip.hide(ref);
          document.getElementById(id)?.remove();
        }
      }}
    >
      {children}
    </span>
  );
  /* return React.cloneElement(children, {
    'data-for': id,
    'data-tip': 'hello',
    ref,
    onMouseEnter: () => {
      const p = document.createElement('span');
      p.id = id;
      ReactDOM.render(
        <StyledReactTooltip multiline effect="solid" type="light" place={direction} id={id}>
          <StyledSpan>{text}</StyledSpan>
        </StyledReactTooltip>,
        document.body.appendChild(p)
      );
      ReactTooltip.show(ref);
    },
    onMouseLeave: () => {
      ReactTooltip.hide(ref);
      document.getElementById(id).remove();
    }
  }); */
};

// eslint-disable-next-line react/prefer-stateless-function

export default Tooltip;
