import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './VerifyEmailComponent.module.css';
import { ReactComponent as CoensioLogo } from '../../../images/Coensio_newLogo.svg';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { sendVerificationEmailAction } from '../../../redux/signup/actions';
import { signedUserIdRedux } from '../../../redux/signup/selectors';
import { useInterval } from '../../../utils/useInterval';

const VerifyEmailComponent = () => {
  const VERIF_MAIL_INTERVAL = 60;
  const [calculateTime, setCalculateTime] = useState(true);

  const [showTime, setShowTime] = useState(true);
  const [timer, setTimer] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [resendClicked, setResendClicked] = useState(false);

  const dispatch = useDispatch();
  const userId = useSelector(signedUserIdRedux);

  useInterval(() => {
    if (calculateTime) {
      const initDate = localStorage.getItem('verificationMailSentDate');
      const msecs = new Date() - new Date(initDate);
      const remaining = VERIF_MAIL_INTERVAL - Math.ceil(msecs / 1000);
      setTimer('0:' + (remaining < 10 ? '0' : '') + String(remaining));
      if (remaining <= 0) {
        setCalculateTime(false);
        setDisabled(false);
        setTimer('');
        setShowTime(false);
      }
    }
  }, 500);

  const handleResendClicked = async () => {
    if (!isDisabled) {
      const data = {
        userId: userId
      };
      const resp = await dispatch(sendVerificationEmailAction(data));
      if (resp.meta.requestStatus === 'fulfilled') {
        localStorage.setItem('verificationMailSentDate', new Date());
        setResendClicked(true);
        setShowTime(true);
        setDisabled(true);
        setCalculateTime(true);
      } else {
        alert('Error');
      }
    }
  };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.card}>
          <div>
            <CoensioLogo className={styles.logo} />
          </div>

          <div className={styles.cardTextBox}>
            <div className={styles.cardTitle}>Please verify your email!</div>
            <div className={styles.cardText}>
              We have sent you a verification email. Please verify your email to start your free
              trial.
            </div>
            <text className={styles.resendWrapper}>
              <text className={styles.cardSubText}>Didn’t recieve an email?&nbsp;</text>
              <text className={styles.cardSubText}>Check your&nbsp;</text>
              <text className={styles.cardSubText}>spam box or&nbsp;</text>
              <text
                onClick={handleResendClicked}
                className={styles.link}
                style={{
                  pointerEvents: isDisabled ? 'none' : 'inherit',
                  opacity: isDisabled ? '0.4' : '1'
                }}
              >
                resend email.&nbsp;
              </text>
              {showTime && <label className={styles.cardSubText}>Time remaining: {timer}</label>}
            </text>
          </div>
          <InfoBanner
            setStatus={setResendClicked}
            show={resendClicked}
            text="Mail Resent"
            textColor="#4AC389"
          />
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailComponent;
