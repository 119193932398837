import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styles from './RequestCandidate.module.css';
import { dateFormatter, urlDynamicQueryUpdateLite, useWindowSize } from '../../../utils/helpers';
import {
  funnelOpenSelector,
  industryAvgSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import { ReactComponent as BlackLocked } from './blackLocked.svg';
import { ReactComponent as PurpleLocked } from './purpleLocked.svg';

export default function RequestCandidateListComponent({
  myKey,
  candidate,
  isHidden,
  listLength,
  keyPressed,
  status
}) {
  const { id } = useParams();
  const history = useHistory();
  const industryAverage = useSelector(industryAvgSelector);
  const isFunnelOpen = useSelector(funnelOpenSelector);
  const [screenWidth, screenHeight] = useWindowSize();
  const [indAverageDiff, setIndAvgDiff] = useState(0);
  const [hideRow, setHideRow] = useState(isHidden);

  const fetchParametersFromUrl = () => {
    urlDynamicQueryUpdateLite('candidateFlag', 'true', false);
    const params = `?defaultAssessmentId=${id}&currentAssessmentId=${id}&${
      window.location.search.split('?')[1]
    }&requestStatus=${candidate?.acceptStatus}`;
    return `/candidate-detail/${candidate.userId}${params}`;
  };

  useEffect(() => {
    if (industryAverage && candidate.points !== -1) {
      const diff = Math.round(
        (Math.abs(industryAverage - candidate.points) / industryAverage) * 100
      );
      if (industryAverage > candidate.points) {
        setIndAvgDiff(diff * -1);
      } else {
        setIndAvgDiff(diff);
      }
    }
  }, [industryAverage, candidate]);

  useEffect(() => {
    if (keyPressed) {
      const timeHide = (listLength - myKey - 1) * (300 / (listLength + 1));
      const timeShow = (myKey + 1) * (500 / (listLength + 1));

      if (isHidden) {
        setTimeout(() => {
          setHideRow(true);
        }, timeHide);
      } else {
        setTimeout(() => {
          setHideRow(false);
        }, timeShow);
      }
    }
  }, [keyPressed]);


  const hoverableFlag = (screenWidth >= 960 || !isFunnelOpen) && screenWidth > 576;


  return (
    <>
      {!hideRow ? (
        <div
          key={myKey}
          className={styles.candWrapper}
          onClick={() => {
            history.push(fetchParametersFromUrl());
          }}
          onAuxClick={e => {
            e.preventDefault();
            window.open(fetchParametersFromUrl(), '_blank');
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className={styles.lockPart}>
              {status === 0 ? <PurpleLocked /> : <BlackLocked />}
            </div>
            <div className={styles.border} />
            <div className={styles.candTextWrapperNoHover}>
              <span className={styles.candText}>Coensio Candidate</span>
              {status !== 0 ? (
                <div className={styles.tagWrapper}>{status === 1 ? 'Pending' : 'Declined'}</div>
              ) : null}
            </div>
          </div>

          <div className={styles.rightPartWrapper}>
            {hoverableFlag ? (
              <>
                <div className={styles.rightPartHovered}>
                  {(screenWidth >= 960 || !isFunnelOpen) &&
                  screenWidth > 576 &&
                  candidate.points !== -1 &&
                  industryAverage &&
                  candidate.points >= industryAverage * 0.8 &&
                  candidate.points < industryAverage ? (
                    <>
                      <div className={styles.indAverageText} style={{ color: '#FFD600' }}>
                        Approximately&nbsp;
                      </div>
                      <div className={styles.indAverageText}>industry average</div>
                    </>
                  ) : (screenWidth >= 960 || !isFunnelOpen) &&
                    screenWidth > 576 &&
                    candidate.points !== -1 &&
                    industryAverage &&
                    (candidate.points < industryAverage * 0.8 ||
                      candidate.points >= industryAverage) ? (
                    <>
                      <div
                        className={styles.indAverageText}
                        style={{ color: indAverageDiff >= 0 ? '#4AC389' : '#FF7E62' }}
                      >
                        {Math.abs(indAverageDiff)}%&nbsp;{indAverageDiff >= 0 ? 'better' : 'worse'}
                        &nbsp;
                      </div>
                      <div className={styles.indAverageText}>than industry average</div>
                    </>
                  ) : (screenWidth >= 960 || !isFunnelOpen) &&
                    screenWidth > 576 &&
                    !industryAverage ? (
                    <div className={styles.indAverageText}>Industry average not available.</div>
                  ) : (screenWidth >= 960 || !isFunnelOpen) &&
                    screenWidth > 576 &&
                    candidate.points === -1 &&
                    industryAverage ? (
                    <>
                      <div className={styles.indAverageText} style={{ color: '#FF7E62' }}>
                        Not take
                      </div>
                      <div className={styles.indAverageText}>&nbsp;the assessment yet.</div>
                    </>
                  ) : null}
                </div>
                <div className={styles.candDate}>{dateFormatter(candidate?.addDate, true)}</div>
              </>
            ) : null}

            {hoverableFlag && <div className={styles.border} />}
            

            <div className={styles.scoreWrapper}>
              <div
                className={`${
                  candidate?.points >= industryAverage
                    ? styles.greenCircle
                    : candidate?.points >= industryAverage * 0.8
                    ? styles.yellowCircle
                    : styles.redCircle
                } ${styles.circle}`}
              />
              <div className={styles.candScore}>{candidate?.points}</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
