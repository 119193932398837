import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import styles from '../Styles/QuestionDescTextEditor.module.css';
import { questionDescriptionRedux } from '../../../redux/addQuestion/selectors';
import { setQuestionDescriptionRedux } from '../../../redux/addQuestion/slice';

export default function QuestionDescTextEditor() {
  const dispatch = useDispatch();
  const reduxQuestionDescription = useSelector(questionDescriptionRedux);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const handleEditorChange = state => {
    setEditorState(state);
    dispatch(setQuestionDescriptionRedux(draftToHtml(convertToRaw(state.getCurrentContent()))));
  };

  // useEffect(() => {
  //   const blocksFromHtml = htmlToDraft(reduxQuestionDescription);
  //   const { contentBlocks, entityMap } = blocksFromHtml;
  //   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //   setEditorState(EditorState.createWithContent(contentState));
  // }, []);

  useEffect(() => {
    if (reduxQuestionDescription === '') {
      setEditorState(() => EditorState.createEmpty())
    }
  }, [reduxQuestionDescription])

  function uploadImage(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${process.env.REACT_APP_PRODUCT_API_URL}/api/common/ImageSave`);
      const data = new FormData();
      data.append('file', file);
      data.append('directoryName', 'questiondescriptionimages');
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        const path = `${process.env.REACT_APP_IMAGE_API}/${response.model.path}`;
        resolve({ data: { link: path } });
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  return (
    <div id="wrapper" className={styles.MainWrapper}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          image: { urlEnabled: false, uploadEnabled: true, previewImage: true, uploadCallback: uploadImage }
        }}
        toolbarClassName={styles.Toolbar}
        wrapperClassName={styles.Wrapper}
        editorClassName={styles.Editor}
      />
    </div>
  );
}
