/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './modal.module.css';
import { ReactComponent as Success } from '../../../images/SFR_success.svg';
import CloseButton from '../../../Common/Components/CloseButton';

export default function PurchaseAddOnSuccessModal({
  show,
  onHide = () => {},
  addOnCode,
  ...props
}) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [footer, setFooter] = useState('');

  useEffect(() => {
    if (addOnCode === 10) {
      // assessment add on
      setTitle('Done!');
      setDescription('You got this. Continue glowing more and more');
    } else if (addOnCode === 30) {
      // candidate report add on
      setTitle('Done!');
      setDescription(`Now, you can see what's in THE candidate`);
    } else if (
      addOnCode === 60 ||
      addOnCode === 61 ||
      addOnCode === 62 ||
      addOnCode === 63 ||
      addOnCode === 65 ||
      addOnCode === 66 ||
      addOnCode === 67
    ) {
      // premium question
      setTitle('Done!');
      setDescription(`You discovered a new diamond. Now, it's in your question library`);
    } else {
      setTitle('Done!');
      setDescription('You got this. Continue glowing more and more');
    }
  }, [show]);

  return (
    <Modal
      {...props}
      // size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      show={show}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
      dialogClassName={styles.container}
    >
      <Modal.Body>
        <>
          <div className={styles.successContainer}>
            <CloseButton width={24} height={24} onClick={onHide} />

            <Success className={styles.successIcon} />

            {title && <div className={styles.successTitle}>{title}</div>}
            {description && <div className={styles.descText}>{description}</div>}
            {footer && <div className={styles.successFootNote}>{footer}</div>}
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}
