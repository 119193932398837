/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  changeCompanyAction, getCompanyListAction,
} from '../../redux/auth/actions';
import styles from '../Styles/CompanySelectModal.module.css';
import CloseButton from '../Components/CloseButton';
import { resetAssessmentList } from '../../redux/assessments/slice';
import { setLogo } from '../../redux/logo/slice';
import { clearUserData } from '../../redux/auth/slice';

export default function companySelectModal({ currentUser, onHide, onHideSuccess, ...props }) {
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);

  // const getLogo = async encryptedCompId => {
  //   const formData = new FormData();
  //   formData.append('companyId', encryptedCompId);
  //   await dispatch(getCompanyLogoAction(formData));
  // };

  const onChange = async (company, id) => {
    const formData = new FormData();
    formData.append('companyId', id);
    const rawData = {
      companyId: id,
    }
    localStorage.setItem('activeCompany', JSON.stringify(company));
    await dispatch(changeCompanyAction(rawData))
    onHide();
    dispatch(setLogo(null));
    dispatch(resetAssessmentList());
    // dispatch(currentUserDetailAction());
    // dispatch(getCurrentUserDetailAction());
    dispatch(clearUserData());
  };

  useEffect(async () => {
    if (props.show) {
      const response = await dispatch(getCompanyListAction());
      setCompanies(response?.payload);
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={styles.CompanySelectModalContent}
      dialogClassName={styles.CompanySelectModalDialog}
      onHide={onHide}
    >
      <div className={styles['modal-title']}>
        <h3>Select Corporate</h3>
        <p>{currentUser?.companyName}</p>
        {/* <span className={`${styles.CloseModalButton} ${styles['top-right']} top-right`}>
          <RiCloseFill
            size='30'
            onClick={onHide}
            className={styles.CloseCandidateModal}
          />
        </span> */}
        <span className={styles['top-right']}>
          <CloseButton onClick={onHide} size="large" />
        </span>
      </div>
      <Modal.Body>
        <div className="row">
          {companies  &&
            companies.map((item, index) => (
              <div key={index} className="col-md-3" onClick={() => onChange(item, item.id)}>
                <div className={`${styles['company-card']}`}>
                  <span>{JSON.stringify(item.name)}</span>
                </div>
              </div>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
