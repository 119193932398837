import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import Modal from '../../../../Common/CustomModal/Modal';
import styles from './EditCompanyRelatedModal.module.css';
import CloseButton from '../../../../Common/Components/CloseButton';
import TextInput from '../../../../Common/Components/TextInput';
import CustomButton from '../../../../Common/Components/CustomButton';
import { companyInfo, userSelector } from '../../../../redux/auth/selectors';
import addLogoIcon from '../../../Images/logoUploadIcon.svg';
import { updateCompanyProfileAction } from '../../../../redux/auth/actions';
import { getLogoByCompanyIdAction } from '../../../../redux/logo/actions';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../../images/errorSubmit.svg';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import { useWindowSize } from '../../../../utils/helpers';

const EditCompanyRelatedModal = ({ show, onClose, type, showSuccess }) => {
  const dispatch = useDispatch();
  const profileInfo = useSelector(companyInfo);
  const user = useSelector(userSelector);
  const [screenW] = useWindowSize();
  const isMobile = screenW <= 576;

  const existingLogoPathConstant = profileInfo?.companyLogoUrl;
  const companyNameConstant = profileInfo?.companyName;
  const existingCompanyInformationConstant = profileInfo?.companyInformation;
  const existingCompanyDomain = profileInfo?.website;

  // Company related states
  const [logoPresent, setLogoPresent] = useState(!!existingLogoPathConstant);
  const [name, setName] = useState(companyNameConstant);
  const [domain, setDomain] = useState();
  const [logoSrc, setlogoSrc] = useState('');
  const [logoFile, setLogoFile] = useState();
  const [hasLogoChanged, setHasLogoChanged] = useState(false);

  // Info related states
  const [companyDesc, setCompanyDesc] = useState(
    existingCompanyInformationConstant === '' ? null : existingCompanyInformationConstant
  );
  const companyDescription = useRef(null);
  const [wordCount, setWordCount] = useState(0);

  const [isSuccessfullySaved, setIsSuccessfullySaved] = useState();
  const [correctImageFormat, setCorrectImageFormat] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  // Methods
  // #region
  const imageUpload = acceptedFiles => {
    if (
      (acceptedFiles[0].type === 'image/jpeg' || acceptedFiles[0].type === 'image/png') &&
      acceptedFiles[0].size < 1 * 1024 * 1024
    ) {
      const reader = new FileReader();
      const file = acceptedFiles[0];
      reader.onloadend = () => {
        setlogoSrc(reader.result);
        setLogoFile(acceptedFiles[0]);
      };
      reader.readAsDataURL(file);
      setLogoPresent(true);
      setHasLogoChanged(true);
    } else {
      setCorrectImageFormat(false);
      setErrorMessage('Invalid image type or size!');
    }
  };

  const refreshLogoPath = () => {
    if (profileInfo && existingLogoPathConstant) {
      let temp = '';
      if (existingLogoPathConstant?.startsWith('CompanyLogo')) {
        temp = temp.concat(`${process.env.REACT_APP_IMAGE_API}/`);
      }
      temp = temp.concat(existingLogoPathConstant);
      setlogoSrc(temp);
      setLogoPresent(!!existingLogoPathConstant);
    } else {
      setTimeout(() => {
        setLogoPresent(false);
        setlogoSrc('');
      }, 100);
    }
  };

  async function updateProfile(data) {
    const resp = await dispatch(updateCompanyProfileAction(data));
    if (resp.meta.requestStatus === 'fulfilled') {
      setIsSuccessfullySaved(true);
    } else {
      setErrorMessage(resp.payload.messages.join('\n'));
      setIsSuccessfullySaved(false);
    }
    setSaveButtonLoading(false);
  }

  async function handleUpdateProfile() {
    const formData = new FormData();
    formData.append('CompanyId', user.companyId);
    formData.append('CompanyName', name.trim());
    if (existingCompanyInformationConstant) {
      formData.append('CompanyInformation', existingCompanyInformationConstant);
    }
    if (hasLogoChanged) {
      if (logoFile) {
        formData.append('CompanyLogoUrl', logoFile);
      } else {
        formData.append('CompanyLogoUrl', null);
      }
    }

    if (domain) {
      formData.append('Website', domain);
    }
    formData.append('HasLogoChanged', hasLogoChanged);
    await updateProfile(formData);

    const data = {
      CompanyId: user.companyId
    };
    await dispatch(getLogoByCompanyIdAction(data));
  }

  async function handleUpdateDescription() {
    const formData = new FormData();
    formData.append('CompanyId', user.companyId);
    formData.append('CompanyName', companyNameConstant);
    formData.append('CompanyInformation', companyDesc);
    // formData.append('CompanyLogoUrl', existingLogoPathConstant);
    if (existingCompanyDomain) {
      formData.append('Website', existingCompanyDomain);
    }
    formData.append('HasLogoChanged', false);
    await updateProfile(formData);
  }

  const isThereAnyChangeForCompany = () => {
    if (name !== companyNameConstant || domain !== existingCompanyDomain || hasLogoChanged) {
      return true;
    }
    return false;
  };

  // #endregion

  // Use effects
  // #region
  useEffect(() => {
    setIsSuccessfullySaved();
    setCorrectImageFormat();
    setErrorMessage();
    setSaveButtonLoading(false);
    refreshLogoPath();
    setHasLogoChanged(false);
    setCompanyDesc(
      existingCompanyInformationConstant === '' ? null : existingCompanyInformationConstant
    );
    setName(companyNameConstant);
    setDomain(existingCompanyDomain);
    setWordCount(companyDescription?.current?.value?.length);

    if (!show) {
      setLogoFile();
      setName();
      setDomain();
      setCompanyDesc();
    }
  }, [show]);

  useEffect(() => {
    if (isSuccessfullySaved === true) {
      showSuccess(true);
      onClose();
    }
  }, [isSuccessfullySaved]);

  useEffect(() => {
    refreshLogoPath();
  }, [existingLogoPathConstant]);

  useEffect(() => {
    setName(companyNameConstant);
    setDomain(existingCompanyDomain);
    if (existingCompanyInformationConstant === ' ') {
      setCompanyDesc(null);
    } else {
      setCompanyDesc(existingCompanyInformationConstant);
    }
  }, [profileInfo]);

  // #endregion

  // Components
  // #region
  const ModalHeader = ({ text }) => (
    <div className={styles.headerWrapper}>
      <text className={styles.headerText}>{text}</text>
      <CloseButton onClick={onClose} size="small" />
    </div>
  );

  const CompanyNameFields = (
    <div className={styles.companyInputsWrapper}>
      <TextInput
        type="text"
        placeholder="Company Name"
        value={name}
        onChange={event => setName(event.target.value)}
      />
      <TextInput
        type="text"
        placeholder="Company Domain (example.com)"
        value={domain}
        onChange={event => setDomain(event.target.value)}
      />
    </div>
  );

  const CompanyDropzone = (
    <div className={styles.dropzoneWrapper}>
      <Dropzone
        onDrop={acceptedFiles => {
          imageUpload(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={`${styles.logoEmptyContainer} ${logoSrc && styles.logoPresent}`}
          >
            <input {...getInputProps()} />
            {logoSrc ? (
              <img src={logoSrc} alt="" className={styles.existingLogo} />
            ) : (
              <div className={styles.emptyLogoTextWrapper}>
                <img src={addLogoIcon} alt="" style={{ width: 'fit-content' }} />
                <span className={styles.title}>Add your company logo</span>
                <span className={styles.subtitle}>
                  Increase your company visibility by adding your logo!
                </span>
              </div>
            )}

            <div className={styles.dropzoneButtonWrapper}>
              <CustomButton
                style={{ fontSize: '16px' }}
                type={7}
                newDesign
                textField="Remove"
                isDisabled={!logoPresent}
                buttonOnClick={event => {
                  event.stopPropagation();
                  setLogoPresent(false);
                  setlogoSrc('');
                  setLogoFile();
                  if (!existingLogoPathConstant) {
                    setHasLogoChanged(false);
                  } else {
                    setHasLogoChanged(true);
                  }
                }}
              />
              <CustomButton
                style={{ fontSize: '16px' }}
                type={4}
                newDesign
                textField={logoSrc ? 'Change' : 'Upload'}
              />
            </div>
          </div>
        )}
      </Dropzone>

      <text className={styles.footerText}>.png or .jpg | Max. 1MB</text>
    </div>
  );

  const CompanyButtonWrapper = (
    <div className={styles.bottomButtonWrapper}>
      <CustomButton
        style={{ fontSize: '16px' }}
        newDesign
        type={2}
        textField="Cancel"
        buttonOnClick={onClose}
        customStyle={isMobile ? { width: '50%' } : {}}
      />
      <CustomButton
        style={{ fontSize: '16px' }}
        newDesign
        type={1}
        textField="Save"
        isDisabled={!show || name?.trim() === '' || !isThereAnyChangeForCompany()}
        buttonOnClick={() => {
          setSaveButtonLoading(true);
          handleUpdateProfile();
        }}
        loading={saveButtonLoading}
        customStyle={isMobile ? { width: '50%' } : {}}
      />
    </div>
  );

  const InfoButtonWrapper = (
    <div className={styles.bottomButtonWrapper}>
      <CustomButton
        style={{ fontSize: '16px' }}
        newDesign
        type={2}
        textField="Cancel"
        buttonOnClick={onClose}
        customStyle={isMobile ? { width: '50%' } : {}}
      />
      <CustomButton
        style={{ fontSize: '16px' }}
        newDesign
        type={1}
        textField="Save"
        buttonOnClick={() => {
          setSaveButtonLoading(true);
          handleUpdateDescription();
        }}
        loading={saveButtonLoading}
        customStyle={isMobile ? { width: '50%' } : {}}
        isDisabled={companyDesc === existingCompanyInformationConstant}
      />
    </div>
  );

  const InfoTextArea = (
    <div className={styles.descriptionBox}>
      <textarea
        ref={companyDescription}
        id="companyDescription"
        maxLength={300}
        className={styles.descInput}
        placeholder="Type a short description here.."
        value={companyDesc}
        onChange={event => {
          setCompanyDesc(event.target.value);
          setWordCount(event.target.value.length);
        }}
      />
      <span className={styles.wordCountText}>{wordCount}/300</span>
    </div>
  );

  // #endregion

  const CompanyTypeContent = (
    <>
      <ModalHeader text="Edit Company" />
      {CompanyNameFields}
      {CompanyDropzone}
      {CompanyButtonWrapper}
    </>
  );

  const InfoTypeContent = (
    <>
      <ModalHeader text="Edit Company Information" />
      {InfoTextArea}
      {InfoButtonWrapper}
    </>
  );

  const Web = (
    <Modal
      show={show}
      onClose={onClose}
      centerHorizontally
      centerVertically
      size={{ width: '572px' }}
      contentStyle={{ borderRadius: '24px', padding: '20px 28px 28px' }}
      outsideClickClose
    >
      <div className={styles.modalWrapper}>
        {type === 'company' ? CompanyTypeContent : InfoTypeContent}
      </div>
    </Modal>
  );

  const Mobile = (
    <MobileModal
      show={show}
      onClose={onClose}
      outsideClickClose
      contentStyle={{ padding: '20px 12px 20px 12px', minWidth: '375px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      <div className={styles.modalWrapper}>
        {type === 'company' ? CompanyTypeContent : InfoTypeContent}
      </div>
    </MobileModal>
  );

  return (
    <>
      {screenW <= 576 ? Mobile : Web}
      <InfoBanner
        text={errorMessage}
        show={isSuccessfullySaved === false || correctImageFormat === false}
        CustomIcon={Error}
        setStatus={type => {
          setIsSuccessfullySaved();
          setCorrectImageFormat(true);
          setErrorMessage();
        }}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
    </>
  );
};

export default EditCompanyRelatedModal;
