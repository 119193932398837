import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../../Common/Components/CustomButton';
import Skeleton from '../../../../Common/Components/Skeleton';
import UnlabeledSingleSelectDropdown from '../../../../Common/Components/UnlabeledSingleSelectDropdown/UnlabeledSingleSelectDropdown';
import { getPositionsForCurrentFunnelListAction } from '../../../../redux/candidateDetail/actions';
import {
  assessmentSummaryPositionRequestPayStatusSelector,
  assessmentSummaryPositionRequestStatusSelector,
  candidatePositionsSelector,
  currentAssessmentFunnelListSelector
} from '../../../../redux/candidateDetail/selectors';
import {
  setInfoBannerShow,
  setInfoBannerText,
  setShowRejectionPopupFlag
} from '../../../../redux/candidateDetail/slice';
import { getParam, urlDynamicQueryUpdateLite } from '../../../../utils/helpers';
import { moveCandidateToFunnel, orderPositions, sortOnPriority } from '../helpers';
import styles from './AssessmentSummary.module.css';
import ProgressBar from './ProgressBar';
import constants from '../../../../utils/constants'

const AssessmentSummary = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [orderedListForDropdown, setOrderedListForDropdown] = useState();
  const candidatePositionsRedux = useSelector(candidatePositionsSelector);
  const [selectedValue, setSelectedValue] = useState();
  const currentAssessmentFunnelListRedux = useSelector(currentAssessmentFunnelListSelector);
  const [funnelListForDropdown, setFunnelListForDropdown] = useState();
  const [selectedFunnelValue, setSelectedFunnelValue] = useState();
  const [isSelectedFlag, setIsSelectedFlag] = useState(false);
  const [initFlagForFunnel, setInitFlagForFunnelInfo] = useState(true);

  /* Position dropdown related methods */
  // #region
  useEffect(() => {
    const orderedList = orderPositions(candidatePositionsRedux);

    const temp = [];
    orderedList.forEach(e =>
      temp.push({
        value: e.companyAssessmentId,
        label: e.reservedName,
        score: e.score,
        indAvg: e.allScoreAverage,
        currentFunnel: e.currentFunnel
      })
    );
    setOrderedListForDropdown(temp);
    setSelectedValue(
      temp.filter(e => e.value === getParam(location.search, 'currentAssessmentId'))[0]
    );
  }, [candidatePositionsRedux]);

  function setSelectedPosition() {
    setSelectedValue(
      orderedListForDropdown.filter(
        e => e.value === getParam(location.search, 'currentAssessmentId')
      )[0]
    );
  }

  useEffect(() => {
    if (orderedListForDropdown) {
      window.addEventListener('locationchange', setSelectedPosition);
    }
    return () => {
      window.removeEventListener('locationchange', setSelectedPosition);
    };
  }, [orderedListForDropdown]);
  // #endregion

  useEffect(() => {
    setIsSelectedFlag(false);
    setInitFlagForFunnelInfo(true);
    if (
      selectedValue &&
      currentAssessmentFunnelListRedux &&
      currentAssessmentFunnelListRedux?.length > 0
    ) {
      setInitFlagForFunnelInfo(true);
      let temp = [...currentAssessmentFunnelListRedux].sort(sortOnPriority);
      const isScored = selectedValue?.score !== -1;
      temp = temp.filter(e =>
        isScored
          ? e.funnelName !== 'Not Scored' && e.funnelName !== 'Rejected'
          : e.funnelName !== 'Scored' && e.funnelName !== 'Rejected'
      );
      const funnelListForDropdown = [];
      temp.forEach(e => funnelListForDropdown.push({ value: e.funnelId, label: e.funnelName }));
      setFunnelListForDropdown(funnelListForDropdown);

      if (selectedValue?.currentFunnel === 'Rejected') {
        const rejectedFunnel = currentAssessmentFunnelListRedux.filter(
          e => e.funnelName === selectedValue.currentFunnel
        )[0];
        setSelectedFunnelValue({
          value: rejectedFunnel.funnelId,
          label: rejectedFunnel.funnelName
        });
        setIsSelectedFlag(true);
      } else {
        setSelectedFunnelValue(
          funnelListForDropdown.filter(e => e.label === selectedValue.currentFunnel)[0]
        );
        setIsSelectedFlag(true);
      }
    }
  }, [currentAssessmentFunnelListRedux, selectedValue]);

  function moveCandidateToFunnelInner() {
    moveCandidateToFunnel(dispatch, id, selectedFunnelValue);
  }

  useEffect(() => {
    if (selectedFunnelValue) {
      if (!initFlagForFunnel) {
        moveCandidateToFunnelInner();
      } else {
        setInitFlagForFunnelInfo(false);
      }
    }
  }, [selectedFunnelValue]);

  function fetchFunnelListForCurrentAssessment() {
    const data = {
      companyAssessmentId: getParam(location.search, 'currentAssessmentId'),
      candidateId: id
    };
    dispatch(getPositionsForCurrentFunnelListAction(data));
  }

  useEffect(() => {
    fetchFunnelListForCurrentAssessment();
    window.addEventListener('locationchange', fetchFunnelListForCurrentAssessment);
    return () => {
      window.removeEventListener('locationchange', fetchFunnelListForCurrentAssessment);
    };
  }, []);

  const assessmentSummaryPositionRequestStatusRedux = useSelector(
    assessmentSummaryPositionRequestStatusSelector
  );
  const assessmentSummaryPositionRequestPayStatusRedux = useSelector(
    assessmentSummaryPositionRequestPayStatusSelector
  );
  const isRequestCandidate =
    assessmentSummaryPositionRequestStatusRedux &&
    (assessmentSummaryPositionRequestStatusRedux !== constants.REQUEST_STATUS_ACCEPTED ||
      (assessmentSummaryPositionRequestStatusRedux === constants.REQUEST_STATUS_ACCEPTED &&
        assessmentSummaryPositionRequestPayStatusRedux === false));

  return (
    <div className={styles.AssessmentSummaryWrapper}>
      <text className={styles.AssessmentSummaryHeader}>Assessment Summary</text>
      <div className={styles.AssessmentSummaryDropdownWrapper}>
        {orderedListForDropdown && (
          <UnlabeledSingleSelectDropdown
            list={orderedListForDropdown}
            selectedValue={selectedValue}
            setSelectedValue={e => {
              if (e.value !== getParam(location.search, 'currentAssessmentId')) {
                urlDynamicQueryUpdateLite('currentAssessmentId', e.value);
              }
              setSelectedValue(orderedListForDropdown.filter(x => x.value === e.value)[0]);
            }}
            placeholder="Positions"
          />
        )}
      </div>

      <div className={styles.AssessmentSummaryBottomWrapper}>
        <div
          className={styles.AssessmentSummaryBottomBox}
          style={isRequestCandidate ? { width: '100%' } : {}}
        >
          <text className={styles.BoxText}>Candidate’s Score</text>
          <ProgressBar
            score={selectedValue?.score}
            average={selectedValue?.indAvg}
            tooltipText={`Industry average: ${selectedValue?.indAvg || 'NaN'}`}
          />
        </div>
        {!isRequestCandidate ? (
          <>
            <div className={styles.VerticalBorder} />
            <div className={styles.AssessmentSummaryBottomBox}>
              <text className={styles.BoxText}>Hiring Status</text>
              <div className={styles.AssessmentSummaryFunnelWrapper}>
                {isSelectedFlag ? (
                  <>
                    <UnlabeledSingleSelectDropdown
                      list={funnelListForDropdown}
                      selectedValue={selectedFunnelValue}
                      setSelectedValue={setSelectedFunnelValue}
                      placeholder="Stages"
                      wrapperStyle={{ width: 'calc(100% - 108px)' }}
                    />
                    <CustomButton
                      type={6}
                      textField="Reject"
                      size="small"
                      style={{ marginLeft: '18px', height: '42px' }}
                      isDisabled={selectedFunnelValue?.label === 'Rejected'}
                      buttonOnClick={() => dispatch(setShowRejectionPopupFlag(true))}
                    />
                  </>
                ) : (
                  <Skeleton type="funnelDropdown" />
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default AssessmentSummary;
