import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BillingTab from './BillingTab';
import { viewHeight } from '../../../../redux/user/selectors';
import { userSelector } from '../../../../redux/auth/selectors';
import {
  billingInfoRedux,
  currentPlanRedux,
  editBillingModalShow
} from '../../../../redux/plan/selectors';
import { getBillingInfoAction, getInvoiceListAction } from '../../../../redux/plan/actions';
import { setEditBillingShow } from '../../../../redux/plan/slice';
import EditBillingModal from '../../../Modals/EditBillingModal';
import NotAuthorizedPopup from '../../../Modals/NotAuthorizedPopup';

export const BillingContext = createContext();
function BillingPage({ disabled }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const height = useSelector(viewHeight);
  const user = useSelector(userSelector);
  const billingInfo = useSelector(billingInfoRedux);
  const editBillingShow = useSelector(editBillingModalShow);
  const currentPlan = useSelector(currentPlanRedux);

  useEffect(async () => {
    if (user) {
      const rawData = {
        CompanyId: user.companyId
      };
      const invoiceData = {
        CompanyId: user.companyId,
        PageSize: 10,
        CurrentPage: 0
      };
      await dispatch(getBillingInfoAction(rawData));
      await dispatch(getInvoiceListAction(invoiceData));
    }
  }, [user]);

  useEffect(() => {
    if (user && currentPlan) {
      if (!user.isAdmin || currentPlan?.name === 'Free Trial') {
        history.replace('/assessments');
      }
    }
  }, [user, currentPlan]);

  return (
    <BillingContext.Provider value={billingInfo}>
      <EditBillingModal show={editBillingShow} onHide={() => dispatch(setEditBillingShow(false))} />
      {!disabled && <BillingTab />}
      {disabled && <NotAuthorizedPopup show={disabled} />}
    </BillingContext.Provider>
  );
}

export default BillingPage;
