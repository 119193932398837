import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './SalesMain.module.css';
import { setCompanyName, setCreateCompSuccess, setCustomBundleId, setEmail, setName, setPassword, setPhoneNumber, setSurname } from '../../../redux/salesScreen/slice';

const SalesMain = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Cleanup
  useEffect(() => {
    // Company signup
    dispatch(setCreateCompSuccess(null));
    dispatch(setName(''));
    dispatch(setSurname(''));
    dispatch(setEmail(''));
    dispatch(setCompanyName(''));
    dispatch(setPassword(''));
    dispatch(setPhoneNumber(null));

    // Create new template
    dispatch(setCustomBundleId(''))
  }, [])

  return (
    <div className={styles.contentBody}>
      <button
        onClick={() => {
          history.push(`/nRdDOsdIFdwEdfdsaSd/1`);
        }}
        type="button"
        className={styles.btn}
      >
        Create new company
      </button>
      <button
        onClick={() => {
          history.push(`/nRdDOsdIFdwEdfdsaSd/2`);
        }}
        type="button"
        className={styles.btn}
      >
        Update Existing Company
      </button>
      <button
        onClick={() => {
          history.push(`/nRdDOsdIFdwEdfdsaSd/3`);
        }}
        type="button"
        className={styles.btn}
      >
        Create new package template
      </button>
    </div>
  );
};

export default SalesMain;
