import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  arriveTypeSelector,
  endDateSelector,
  endScoreSelector,
  funnelIdSelector,
  industryIncludeTypeSelector,
  industryListSelector,
  orderTypeSelector,
  pageNumberSelector,
  schoolListSelector,
  screeningExperiencesSelector,
  screeningNationalitiesSelector,
  screeningSalaryCurrencySelector,
  screeningSalaryMaxValueSelector,
  screeningSalaryMinValueSelector,
  screeningWorkTypesSelector,
  searchParameterSelector,
  startDateSelector,
  startScoreSelector
} from '../../../redux/assessmentDetailVol2/selectors';

const filterDataModel = () => {
  const { id } = useParams();

  const searchParameterRedux = useSelector(searchParameterSelector);
  const orderTypeRedux = useSelector(orderTypeSelector);
  const funnelIdRedux = useSelector(funnelIdSelector);
  const startDateRedux = useSelector(startDateSelector);
  const endDateRedux = useSelector(endDateSelector);
  const startScoreRedux = useSelector(startScoreSelector);
  const endScoreRedux = useSelector(endScoreSelector);
  const pageNumberRedux = useSelector(pageNumberSelector);
  const arriveTypeRedux = useSelector(arriveTypeSelector);

  const industryListRedux = useSelector(industryListSelector);
  const industryIncludeTypeRedux = useSelector(industryIncludeTypeSelector);
  const screeningExperiencesRedux = useSelector(screeningExperiencesSelector);
  const screeningWorkTypesRedux = useSelector(screeningWorkTypesSelector);
  const screeningNationalitiesRedux = useSelector(screeningNationalitiesSelector);
  const screeningSalaryCurrencyRedux = useSelector(screeningSalaryCurrencySelector);
  const screeningSalaryMinValueRedux = useSelector(screeningSalaryMinValueSelector);
  const screeningSalaryMaxValueRedux = useSelector(screeningSalaryMaxValueSelector);

  const schoolListRedux = useSelector(schoolListSelector);


  // TODO: redux selector
  // const schoolListRedux = useSelector(schoolListSelector);

  const data = {
    companyAssessmentId: id,
    search: searchParameterRedux,
    orderType: orderTypeRedux,
    funnelId: funnelIdRedux,
    startDate: startDateRedux,
    endDate: endDateRedux,
    startScore: startScoreRedux,
    endScore: endScoreRedux,
    arriveTypes: arriveTypeRedux,
    // For pageNumber and items, read the comment at the bottom

    schoolList: schoolListRedux || null,

    // For industry filter to be enabled, industryList must be provided
    industry: industryListRedux
      ? {
          industryList: industryListRedux, // as array
          industryInclude: industryIncludeTypeRedux // as integer
        }
      : null,
    // For screening filter to be enabled, even one value is enough
    screening:
      screeningExperiencesRedux ||
      screeningWorkTypesRedux ||
      screeningNationalitiesRedux ||
      screeningSalaryMinValueRedux ||
      screeningSalaryMaxValueRedux
        ? {
            experiences: screeningExperiencesRedux, // as array
            workTypes: screeningWorkTypesRedux, // as array
            nationalities: screeningNationalitiesRedux, // as array

            // For salary filter to be enabled, even one value is enough
            salary:
              screeningSalaryMinValueRedux || screeningSalaryMaxValueRedux
                ? {
                    currency: screeningSalaryCurrencyRedux, // as integer
                    minSalary: screeningSalaryMinValueRedux || 0, // as integer
                    maxSalary: screeningSalaryMaxValueRedux || 1000000 // as integer
                  }
                : null
          }
        : null,

        // TODO
    // schoolList: schoolListRedux,
  };

  /**
   * For normal filter requests (i.e., pagination, funnel click, filter etc.),
   *    pageNumber: pageNumberRedux,
   *    items: 50
   * 
   * However for the request after move funnel,
   *    pageNumber: 0,
   *    items: (pageNumberRedux + 1) * 50,
   * 
   * That is why we return 2 params with both cases
   */

  return {
    filterData: { ...data, pageNumber: pageNumberRedux, items: 50 },
    filterDataAfterMove: { ...data, pageNumber: 0, items: (pageNumberRedux + 1) * 50 }
  };
};

export default filterDataModel;
