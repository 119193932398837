import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import BackgroundImageWrapper from '../../../Common/Components/BackgroundImageWrapper/BackgroundImageWrapper';
import Loading from '../../../Common/Components/Loading';
import Skeleton from '../../../Common/Components/Skeleton';
import {
  activeFilterCountSelector,
  blurCandidateWrapperAfterMoveSelector,
  candidateCountWithCurrentFilterSelector,
  candidateListSelector,
  funnelIdSelector,
  funnelInfoSelector,
  loadingForFirstFetchSelector,
  loadingForScrollCandidatesSelector,
  pageNumberSelector,
  requestAcceptedCandidateListSelector,
  requestCandidatesLoadingFlagSelector,
  requestDeclinedCandidateListSelector,
  requestListedCandidateListSelector,
  requestPendingCandidateListSelector,
  searchParameterSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import { LoadingWrapper, urlDynamicQueryUpdateLite } from '../../../utils/helpers';
import CandidateListComponent from '../Candidate/CandidateListComponent';
import styles from './CandidateWrapper.module.css';
import noCandidateFilter from './Images/noCandidateFilter.svg';
import noCoensioCandidate from './Images/noCoensioCandidate.svg';
import noCandidateNewStage from './Images/noCandidateNewStage.svg';
import RequestCandidateWrapper from '../RequestCandidate/RequestCandidateWrapper';

const CandidateWrapper = ({ allowedHeightForCandidateWrapper }) => {
  const candidateWrapperRef = useRef();

  /* Selector for candidate list */
  const candidateListRedux = useSelector(candidateListSelector);
  const candidateCountWithCurrentFilterRedux = useSelector(candidateCountWithCurrentFilterSelector);
  const pageNumberRedux = useSelector(pageNumberSelector);
  const loadingForScrollCandidatesRedux = useSelector(loadingForScrollCandidatesSelector);
  const loadingForFirstFetchRedux = useSelector(loadingForFirstFetchSelector);

  const loadingForRequestCandidatesRedux = useSelector(requestCandidatesLoadingFlagSelector);
  const listedCandidatesRedux = useSelector(requestListedCandidateListSelector);
  const pendingCandidatesRedux = useSelector(requestPendingCandidateListSelector);
  const declinedCandidatesRedux = useSelector(requestDeclinedCandidateListSelector);
  const acceptedCandidatesRedux = useSelector(requestAcceptedCandidateListSelector);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = candidateWrapperRef.current;
    if (candidateWrapperRef.current) {
      if (
        scrollTop + clientHeight < scrollHeight + 20 &&
        scrollTop + clientHeight > scrollHeight - 20 &&
        pageNumberRedux < Math.floor((candidateCountWithCurrentFilterRedux - 1) / 50) &&
        !loadingForScrollCandidatesRedux
      ) {
        let pageIndex = parseInt(pageNumberRedux, 10) || 0;
        pageIndex += 1;
        urlDynamicQueryUpdateLite('pageNumber', pageIndex.toString());
      }
    }
  };

  const [enableSelectAllFlag, setEnableSelectAllFlag] = useState(false);
  const [disableSelectAllFlag, setDisableSelectAllFlag] = useState(false);

  useEffect(() => {
    /* 
      dispatch event on select all clicks
      results in faster state update for individual checkbox states compared 
      to flag selection with redux state
    */

    window.addEventListener('enableSelectAll', () => setEnableSelectAllFlag(true));
    window.addEventListener('disableSelectAll', () => setDisableSelectAllFlag(true));

    return () => {
      window.removeEventListener('enableSelectAll', () => setEnableSelectAllFlag(null));
      window.removeEventListener('disableSelectAll', () => setDisableSelectAllFlag(null));
    };
  }, []);

  const activeFilterCountRedux = useSelector(activeFilterCountSelector);
  const searchParameterRedux = useSelector(searchParameterSelector);
  const funnelId = useSelector(funnelIdSelector);
  const funnels = useSelector(funnelInfoSelector);
  const [backgroundImg, setBackgroundImg] = useState(noCandidateFilter);
  const [backgroundText, setBackgroundText] = useState('No results were found for your criteria.');
  const [backgroundSubText, setBackgroundSubText] = useState(null);

  useEffect(() => {
    if (funnels && funnelId) {
      // If funnel parameter is on url
      const temp = funnels.filter(f => f.funnelId === funnelId);
      if (temp[0]?.funnelCategory === 25) {
        if ((activeFilterCountRedux || searchParameterRedux) && temp[0].userCount !== 0) {
          setBackgroundText('No results were found for your criteria.');
          setBackgroundImg(noCandidateFilter);
        } else {
          setBackgroundText('Explore the Coensio Candidates!');
          setBackgroundSubText(
            'You can find the recommended candidates for your company in the Scored Stage.'
          );
          setBackgroundImg(noCoensioCandidate);
        }
      } else if (temp[0]?.funnelCategory === 20 && temp[0]?.funnelName === 'Scored') {
        if ((activeFilterCountRedux || searchParameterRedux) && temp[0].userCount !== 0) {
          setBackgroundText('No results were found for your criteria.');
          setBackgroundImg(noCandidateFilter);
        } else {
          setBackgroundText('Scores will be here soon');
          setBackgroundImg(noCandidateNewStage);
        }
      } else if (temp[0]?.funnelCategory === 20 && temp[0]?.funnelName === 'Not Scored') {
        if ((activeFilterCountRedux || searchParameterRedux) && temp[0].userCount !== 0) {
          setBackgroundText('No results were found for your criteria.');
          setBackgroundImg(noCandidateFilter);
        } else {
          setBackgroundText('Candidates will be here soon');
          setBackgroundImg(noCandidateNewStage);
        }
      } else {
        setBackgroundText('No results were found for your criteria.');
        setBackgroundImg(noCandidateFilter);
      }
    } else if (funnels) {
      // No funnel parameter on url => Scored funnel
      const temp = funnels.filter(f => f.funnelCategory === 20 && f.funnelName === 'Scored');
      if ((activeFilterCountRedux || searchParameterRedux) && temp[0]?.userCount !== 0) {
        setBackgroundText('No results were found for your criteria.');
        setBackgroundImg(noCandidateFilter);
      } else {
        setBackgroundText('Scores will be here soon');
        setBackgroundImg(noCandidateNewStage);
      }
    }
  }, [funnels, funnelId, candidateListRedux]);

  const loadingStateForFetchCandidateAfterMove = useSelector(blurCandidateWrapperAfterMoveSelector);

  return allowedHeightForCandidateWrapper ? (
    <div
      id="candidateListWrapper"
      className={`${styles.CandidateWrapper} ${
        candidateListRedux?.length === 0 && styles.emptyWrapper // empty wrapper only valid for mobile
      }`}
      style={{
        height: `calc(100vh - ${allowedHeightForCandidateWrapper}px)`,
        filter: loadingStateForFetchCandidateAfterMove ? 'blur(3px)' : ''
      }}
      ref={candidateWrapperRef}
      onScroll={() => handleScroll()}
    >
      {loadingForFirstFetchRedux || loadingForRequestCandidatesRedux ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : candidateListRedux?.length === 0 &&
        listedCandidatesRedux?.length === 0 &&
        pendingCandidatesRedux?.length === 0 &&
        declinedCandidatesRedux?.length === 0 &&
        acceptedCandidatesRedux?.length === 0 ? (
        <BackgroundImageWrapper
          image={backgroundImg}
          header={backgroundText}
          subtext={backgroundSubText}
          style={{ alignSelf: 'center', margin: 'auto' }}
        />
      ) : (
        <>
          <RequestCandidateWrapper />

          {acceptedCandidatesRedux?.length > 0 && (
            <div className={styles.candidateTypeWrapper} style={{marginBottom: '2.4rem'}}>
              <text className={styles.candidateTypeText}>Sourced Candidates</text>
              {acceptedCandidatesRedux?.map((e, i) => (
                <CandidateListComponent
                  item={e}
                  index={i}
                  enableSelectAllFlagState={[enableSelectAllFlag, setEnableSelectAllFlag]}
                  disableSelectAllFlagState={[disableSelectAllFlag, setDisableSelectAllFlag]}
                  isAcceptedCandidate
                />
              ))}
            </div>
          )}

          {candidateListRedux?.length > 0 && (
            <div className={styles.candidateTypeWrapper}>
              <text className={styles.candidateTypeText}>Applied Candidates</text>
              {candidateListRedux?.map((e, i) => (
                <CandidateListComponent
                  item={e}
                  index={i}
                  enableSelectAllFlagState={[enableSelectAllFlag, setEnableSelectAllFlag]}
                  disableSelectAllFlagState={[disableSelectAllFlag, setDisableSelectAllFlag]}
                />
              ))}
              {loadingForScrollCandidatesRedux ? <Skeleton type="candidateListComponent" /> : null}
            </div>
          )}
        </>
      )}
    </div>
  ) : null;
};

export default CandidateWrapper;
