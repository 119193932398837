export default {
  pageNumber: 0,
  billingPeriod: '',
  assessmentNumber: null,
  candidateNumber: null,
  candidateReport: false,
  premiumQuestion: false,
  customQuestion: false,
  newOpsCandidate: null,
  maxNumberOfRequests:null,
  packageName: '',
  price: 0,
  name: '',
  surname: '',
  email: '',
  companyName: '',
  phoneNumber: null,
  password: '',
  currentPeriod: '',
  currentName: '',
  templateName: '',
  infoModal: false,
  infoText: 'Deneme',
  CSList: [],
  CompanyList: [],
  selectedCompany: { label: '', value: '' },
  templateList: [],
  customBundleId: '',
  createdCompanyId: null,
  createCompSuccess: null,
  createTempAndConnectTempActionSuccessCheck: null,
  cancelBundleSuccessCheck: null
};
