import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import styles from '../Styles/StartPage.module.css';
import Checkbox from '../../../Common/Components/Checkbox';
import CustomButton from '../../../Common/Components/CustomButton';
import { ReactComponent as Start } from '../../../images/startAssessmentIcon.svg';
import { ReactComponent as Coding } from '../../../images/coding.svg';
import { ReactComponent as FreeText } from '../../../images/freetext.svg';
import { ReactComponent as MultipleChoice } from '../../../images/multiple.svg';
import { ReactComponent as Info } from '../../../images/assessmentInfoIcon.svg';
import Tooltip from '../../../Common/Components/Tooltip';
import {
  AssessmentTitle,
  CandidateEmail,
  CodingCount,
  CompanyName,
  FreeCount,
  LanguageList,
  MultiCount,
  QuestionCount,
  rootAssessmentNameRedux,
  ShowContract,
  TotalTime
} from '../../../redux/AssessmentSession/selectors';
import { questionLanguage, SecToMin, useWindowSize } from '../../../utils/helpers';
import {
  candidateContractConfirmAction,
  getNextQuestionAction,
  startAssessmentSessionAction
} from '../../../redux/AssessmentSession/actions';

import mixpanel from '../../../utils/mixpanel';

const InfoCard = styled.div`
  width: 100%;
  border: 1px solid rgba(57, 57, 57, 0.15);
  border-radius: 12px;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.8rem;
  min-height: 151px;
  max-height: 200px;
`;

const StartPage = () => {
  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const history = useHistory();
  const [width, height] = useWindowSize();
  const reduxEmail = useSelector(CandidateEmail);
  const reduxAssessmentTitle = useSelector(AssessmentTitle);
  const reduxCompanyName = useSelector(CompanyName);
  const reduxQuestionCount = useSelector(QuestionCount);
  const reduxTotalTime = useSelector(TotalTime);
  const codingCount = useSelector(CodingCount);
  const freeCount = useSelector(FreeCount);
  const multiCount = useSelector(MultiCount);
  const languageList = useSelector(LanguageList);
  const showContract = useSelector(ShowContract);
  const dispatch = useDispatch();
  const [contractStatus, setContractStatus] = useState({ privacy: false, posted: false });
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);

  const [cookies, setCookie] = useCookies(['acListSecret']);
  const isSuspectFlag = !!cookies.acListSecret;
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [buttonLoading, setButtonLoading] = useState(false);

  function toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen ||
      docEl.webkitEnterFullscreen;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  const onSubmitHandler = async () => {
    setButtonLoading(true);

    // Mixpanel Start Assessment Click Event
    mixpanel.track('Assessment Session Information - Start Assessment Button Click', {
      'Assessment Name': reduxAssessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': reduxCompanyName,
      'Candidate Email': reduxEmail,
      'Contract Flag': showContract,
      'Language List': languageList,
      'Total Question Count': reduxQuestionCount,
      'Multiple Choice Question Count': multiCount,
      'Coding Question Count': codingCount,
      'Free Text Question Count': freeCount,
      Duration: reduxTotalTime
    });

    if (showContract) {
      // Mixpanel Start Assessment Click - Contract Check Click
      mixpanel.track('Assessment Session Information - Start Assessment - Contract Check Click', {
        'Assessment Name': reduxAssessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': reduxCompanyName,
        'Candidate Email': reduxEmail,
        'Contract Flag': showContract,
        'Language List': languageList,
        'Total Question Count': reduxQuestionCount,
        'Multiple Choice Question Count': multiCount,
        'Coding Question Count': codingCount,
        'Free Text Question Count': freeCount,
        Duration: reduxTotalTime
      });
      const contractResp = await dispatch(
        candidateContractConfirmAction({ candidateEmail: reduxEmail })
      );
      if (contractResp?.meta?.requestStatus === 'fulfilled') {
        // Mixpanel Start Assessment Click - Contract Check Success
        mixpanel.track(
          'Assessment Session Information - Start Assessment - Contract Check Success',
          {
            'Assessment Name': reduxAssessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': reduxCompanyName,
            'Candidate Email': reduxEmail,
            'Contract Flag': showContract,
            'Language List': languageList,
            'Total Question Count': reduxQuestionCount,
            'Multiple Choice Question Count': multiCount,
            'Coding Question Count': codingCount,
            'Free Text Question Count': freeCount,
            Duration: reduxTotalTime
          }
        );
      } else {
        // Mixpanel Start Assessment Click - Contract Check Fail
        mixpanel.track('Assessment Session Information - Start Assessment - Contract Check Fail', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'Error message': contractResp?.payload
        });
        // Mixpanel Start Assessment Click Fail Event
        mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'Error message': 'Error on contract check'
        });
        setButtonLoading(false);
        // alert('Error on contract check')
        return;
      }
    }

    const data = {
      email: reduxEmail,
      secretKey: cookies.acListSecret ? cookies.acListSecret : '',
      companyAssessmentKey: assessmentId,
      kariyerAtsUtm: localStorage.getItem('kariyernetToken')
    };
    const resp = await dispatch(startAssessmentSessionAction(data));

    if (resp?.meta?.requestStatus === 'fulfilled') {
      const cookieInfo = resp.payload.secretKey;
      if (cookieInfo) {
        setCookie('acListSecret', resp.payload.secretKey, { path: '/' });
      }
      const token = resp.payload.assessmentSessionToken;
      if (token) {
        const data2 = {
          Token: token
        };
        const qResp = await dispatch(getNextQuestionAction(data2));
        if (qResp?.meta?.requestStatus === 'rejected') {
          // Mixpanel Start Assessment Click Fail Event
          mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
            'Assessment Name': reduxAssessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': reduxCompanyName,
            'Candidate Email': reduxEmail,
            'Contract Flag': showContract,
            'Language List': languageList,
            'Total Question Count': reduxQuestionCount,
            'Multiple Choice Question Count': multiCount,
            'Coding Question Count': codingCount,
            'Free Text Question Count': freeCount,
            Duration: reduxTotalTime,
            'Error message': 'Error on fetching first question'
          });
          setButtonLoading(false);
          return;
        }

        try {
          if (width > 710) {
            toggleFullScreen();
          }
        } catch (error) {
          console.log('Fullscreen error:', error);
        }

        // Mixpanel Start Assessment Click Success Event
        mixpanel.track('Assessment Session Information - Start Assessment Button Success', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'isSuspect Flag': isSuspectFlag
        });

        history.replace(`/assessment-session?assessment=${assessmentId}`);
      } else {
        console.log('This email is already started an assessment session');
        // Mixpanel Start Assessment Click Fail Event
        mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
          'Assessment Name': reduxAssessmentTitle,
          'Root Assessment Name': rootAssessmentName,
          'Company Name': reduxCompanyName,
          'Candidate Email': reduxEmail,
          'Contract Flag': showContract,
          'Language List': languageList,
          'Total Question Count': reduxQuestionCount,
          'Multiple Choice Question Count': multiCount,
          'Coding Question Count': codingCount,
          'Free Text Question Count': freeCount,
          Duration: reduxTotalTime,
          'Error message': 'This email is already started an assessment session'
        });
        setButtonLoading(false);
        return;
      }

      setButtonLoading(false);
    } else {
      // Mixpanel Start Assessment Click Fail Event
      mixpanel.track('Assessment Session Information - Start Assessment Button Fail', {
        'Assessment Name': reduxAssessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': reduxCompanyName,
        'Candidate Email': reduxEmail,
        'Contract Flag': showContract,
        'Language List': languageList,
        'Total Question Count': reduxQuestionCount,
        'Multiple Choice Question Count': multiCount,
        'Coding Question Count': codingCount,
        'Free Text Question Count': freeCount,
        Duration: reduxTotalTime,
        'Error message': resp?.payload
      });
      setButtonLoading(false);
      // alert('Error on starting assessment session')
      return;
    }

    setButtonLoading(false);
  };

  const [languagesText, setLanguagesText] = useState('');

  useEffect(() => {
    if (languageList) {
      const temp = [];
      languageList?.forEach(e => {
        temp.push(questionLanguage[e]);
      });
      temp.sort();

      let tempStr = '';
      temp.forEach((e, i) => {
        if (i < temp.length - 2) {
          tempStr = tempStr.concat(e);
          tempStr = tempStr.concat(', ');
        } else if (i === temp.length - 2) {
          tempStr = tempStr.concat(e);
          tempStr = tempStr.concat(' and ');
        } else {
          tempStr = tempStr.concat(e);
        }
      });
      setLanguagesText(tempStr);
    }
  }, [languageList]);

  const codingMobileThreshold = 710;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (width < codingMobileThreshold) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  /* ------------------------- Mixpanel related methods -------------------------*/
  useEffect(() => {
    if (rootAssessmentName) {
      mixpanel.track('Assessment Session Information - Pageview', {
        'Assessment Name': reduxAssessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': reduxCompanyName,
        'Candidate Email': reduxEmail,
        'Contract Flag': showContract, // Whether user sees contract options or not
        'Language List': languageList,
        'Total Question Count': reduxQuestionCount,
        'Multiple Choice Question Count': multiCount,
        'Coding Question Count': codingCount,
        'Free Text Question Count': freeCount,
        Duration: reduxTotalTime
      });
    }
  }, [rootAssessmentName]);

  /* ------------------------- Mixpanel related methods -------------------------*/

  return reduxCompanyName === 'Doğuş Teknoloji' ? (
    <>
      <div>
        {width > 576 && <div className={styles.companyName}>{reduxCompanyName}</div>}
        <div className={styles.assessmentTitle}>{reduxAssessmentTitle}</div>
        <InfoCard>
          <div className={styles.questionWrapper}>
            <div className={styles.questionTitle}>Soru</div>
            <div className={styles.infoPart}>
              {width > 576 ? (
                <>
                  <div className={styles.infoText}>{reduxQuestionCount}</div>
                  <Tooltip
                    direction="right"
                    text={
                      <ul className={styles.skillList}>
                        <li className={styles.codingElement}>
                          <span className={styles.infoText} style={{ marginBottom: '1.8rem' }}>
                            {`Kodlama: ${codingCount}`}
                          </span>
                        </li>
                        <li className={styles.multiElement}>
                          <span className={styles.infoText} style={{ marginBottom: '1.8rem' }}>
                            {`Çoktan Seçmeli: ${multiCount}`}
                          </span>
                        </li>
                        <li className={styles.FreeTextElement}>
                          <span className={styles.infoText}>{`Açık Uçlu: ${freeCount}`}</span>
                        </li>
                      </ul>
                    }
                  >
                    <Info className={styles.infoIcon} />
                  </Tooltip>
                </>
              ) : (
                <div className={styles.questionPart}>
                  <div className={styles.iconWrapper}>
                    <Coding />
                    <div className={styles.questionNumber}>{codingCount}</div>
                  </div>
                  <div className={styles.iconWrapper}>
                    <MultipleChoice />
                    <div className={styles.questionNumber}>{multiCount}</div>
                  </div>
                  <div className={styles.iconWrapper}>
                    <FreeText />
                    <div className={styles.questionNumber}>{freeCount}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {width <= 576 && <div className={styles.divider} />}
          <div className={`${styles.questionWrapper}`}>
            <div className={styles.questionTitle}>Süre</div>
            <div className={styles.infoText}>
              {SecToMin(parseInt(reduxTotalTime, 10))?.replace('min', 'dk')?.replace('sec', 'sn')}
            </div>
          </div>
          <div className={styles.divider} />
          <div
            style={{ border: 'none', paddingBottom: 0 }}
            className={`${styles.questionWrapper} ${styles.inner}`}
          >
            <div className={styles.questionTitle}>TAMAMLAMA SÜRESİ</div>
            <div className={styles.infoText}>5 gün</div>
          </div>
        </InfoCard>

        {/* {isMobile && codingCount > 0 && (
          <>
            <div className={`${styles.infoText} ${styles.desc}`}>
              Wait! This assessment includes coding question. We recommend using a computer, but
              your choice. We support all devices 👀
            </div>
          </>
        )} */}

        <div className={`${styles.infoTitle}`}>👇🏼 Sınava başlamadan önce mutlaka okuyun;</div>

        <div className={`${styles.infoText} ${styles.bullet}`}>
          Değerlendirmedeki bazı içerikler/terimler İngilizce olabilir.
        </div>
        
        <div className={`${styles.infoText} ${styles.bullet}`}>
          Sınavın mobil cihazlar kullanılarak yapılması önerilmez.
        </div>
        
        <div className={`${styles.infoText} ${styles.bullet}`}>
          Puanlamada yanlış cevap doğru cevabı götürmemektedir.
        </div>
        <div className={`${styles.infoText} ${styles.bullet}`}>
          Lütfen test sırasında sayfayı yenilemeyiniz veya geri/ileri butonlarını kullanmayınız.
        </div>

        <div className={styles.infoText} style={{ marginTop: '0.6rem', marginBottom: '2.4rem' }}>
          İyi Şanslar!
        </div>
      </div>
      <div>
        {showContract ? (
          <div className={styles.checkboxGroup}>
            <div
              className={styles.checkBoxItem}
              style={{
                marginBottom: '1.5rem',
                cursor: 'pointer',
                paddingBottom: isMobile ? '1rem' : '0rem'
              }}
              onClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
            >
              <Checkbox
                myOnClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
                myChecked={contractStatus.privacy}
                style={{ marginBottom: '0rem' }}
              />
              <div
                className={styles.infoText}
                style={{ marginBottom: 0, marginLeft: '1rem', cursor: 'pointer' }}
              >
                Coensio&apos;nun&nbsp;
                <a
                  href="https://coens.io/privacy-policy/"
                  target="__BLANK"
                  style={{ color: '#7458D3' }}
                  onClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
                >
                  Gizlilik Politikası&apos;nı
                </a>
                &nbsp;kabul ediyorum.
              </div>
            </div>
            <div
              className={styles.checkBoxItem}
              style={{ marginBottom: '2.4rem', cursor: 'pointer' }}
              onClick={() => setContractStatus(prev => ({ ...prev, posted: !prev.posted }))}
            >
              <Checkbox
                myOnClick={() => setContractStatus(prev => ({ ...prev, posted: !prev.posted }))}
                myChecked={contractStatus.posted}
                style={{ marginBottom: '0rem' }}
              />
              <div
                className={styles.infoText}
                style={{ marginBottom: 0, marginLeft: '1rem', cursor: 'pointer' }}
              >
                {/* Keep me posted */}
                Coensio&apos;nun verilerimi işlemesine izin veriyorum.
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ paddingBottom: '30px' }}>
          <CustomButton
            iconStyle={{ fill: 'none' }}
            Icon={Start}
            type={1}
            buttonOnClick={async () => {
              onSubmitHandler();
            }}
            isDisabled={showContract && !(contractStatus.privacy && contractStatus.posted)}
            loading={buttonLoading}
            style={{ width: '100%' }}
            textField="Teste Başla"
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div>
        {width > 576 && <div className={styles.companyName}>{reduxCompanyName}</div>}
        <div className={styles.assessmentTitle}>{reduxAssessmentTitle}</div>
        <InfoCard>
          <div className={styles.questionWrapper}>
            <div className={styles.questionTitle}>Question</div>
            <div className={styles.infoPart}>
              {width > 576 ? (
                <>
                  <div className={styles.infoText}>{reduxQuestionCount}</div>
                  <Tooltip
                    direction="right"
                    text={
                      <ul className={styles.skillList}>
                        <li className={styles.codingElement}>
                          <span className={styles.infoText} style={{ marginBottom: '1.8rem' }}>
                            {`Coding ${codingCount}`}
                          </span>
                        </li>
                        <li className={styles.multiElement}>
                          <span className={styles.infoText} style={{ marginBottom: '1.8rem' }}>
                            {` Multiple Choice ${multiCount}`}
                          </span>
                        </li>
                        <li className={styles.FreeTextElement}>
                          <span className={styles.infoText}>{`Free-Text: ${freeCount}`}</span>
                        </li>
                      </ul>
                    }
                  >
                    <Info className={styles.infoIcon} />
                  </Tooltip>
                </>
              ) : (
                <div className={styles.questionPart}>
                  <div className={styles.iconWrapper}>
                    <Coding />
                    <div className={styles.questionNumber}>{codingCount}</div>
                  </div>
                  <div className={styles.iconWrapper}>
                    <MultipleChoice />
                    <div className={styles.questionNumber}>{multiCount}</div>
                  </div>
                  <div className={styles.iconWrapper}>
                    <FreeText />
                    <div className={styles.questionNumber}>{freeCount}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {width <= 576 && <div className={styles.divider} />}
          <div className={`${styles.questionWrapper}`}>
            <div className={styles.questionTitle}>Duration</div>
            <div className={styles.infoText}>{SecToMin(parseInt(reduxTotalTime, 10))}</div>
          </div>
          <div className={styles.divider} />
          <div
            style={{ border: 'none', paddingBottom: 0 }}
            className={`${styles.questionWrapper} ${styles.inner}`}
          >
            <div className={styles.questionTitle}>Time To Complete</div>
            <div className={styles.infoText}>7 days</div>
          </div>
        </InfoCard>

        {isMobile && codingCount > 0 && (
          <>
            <div className={`${styles.infoText} ${styles.desc}`}>
              Wait! This assessment includes coding question. We recommend using a computer, but
              your choice. We support all devices 👀
            </div>
          </>
        )}

        <div className={`${styles.infoTitle}`}>👇🏼 Keep these in mind before you start,</div>

        <div className={`${styles.infoText} ${styles.bullet}`}>
          This assessment contains {languagesText} questions.
        </div>
        {codingCount > 0 && (
          <div className={`${styles.infoText} ${styles.bullet}`}>
            You can solve coding questions by using allowed coding languages.
          </div>
        )}
        <div className={`${styles.infoText} ${styles.bullet}`}>
          You can see the questions once in the given time.
        </div>
        <div className={`${styles.infoText} ${styles.bullet}`}>
          Do not refresh the page or use back/forward buttons.
        </div>

        <div className={styles.infoText} style={{ marginTop: '0.6rem', marginBottom: '2.4rem' }}>
          Good luck!
        </div>
      </div>
      <div>
        {showContract ? (
          <div className={styles.checkboxGroup}>
            <div
              className={styles.checkBoxItem}
              style={{
                marginBottom: '1.5rem',
                cursor: 'pointer',
                paddingBottom: isMobile ? '1rem' : '0rem'
              }}
              onClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
            >
              <Checkbox
                myOnClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
                myChecked={contractStatus.privacy}
                style={{ marginBottom: '0rem' }}
              />
              <div
                className={styles.infoText}
                style={{ marginBottom: 0, marginLeft: '1rem', cursor: 'pointer' }}
              >
                I Agree to Coensio&apos;s&nbsp;
                <a
                  href="https://coens.io/privacy-policy/"
                  target="__BLANK"
                  style={{ color: '#7458D3' }}
                  onClick={() => setContractStatus(prev => ({ ...prev, privacy: !prev.privacy }))}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <div
              className={styles.checkBoxItem}
              style={{ marginBottom: '2.4rem', cursor: 'pointer' }}
              onClick={() => setContractStatus(prev => ({ ...prev, posted: !prev.posted }))}
            >
              <Checkbox
                myOnClick={() => setContractStatus(prev => ({ ...prev, posted: !prev.posted }))}
                myChecked={contractStatus.posted}
                style={{ marginBottom: '0rem' }}
              />
              <div
                className={styles.infoText}
                style={{ marginBottom: 0, marginLeft: '1rem', cursor: 'pointer' }}
              >
                {/* Keep me posted */}
                Allow Coensio to process my data
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ paddingBottom: '30px' }}>
          <CustomButton
            iconStyle={{ fill: 'none' }}
            Icon={Start}
            type={1}
            buttonOnClick={async () => {
              onSubmitHandler();
            }}
            isDisabled={showContract && !(contractStatus.privacy && contractStatus.posted)}
            loading={buttonLoading}
            style={{ width: '100%' }}
            textField="Start Assessment"
          />
        </div>
      </div>
    </>
  );
};

export default StartPage;
