import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../../Common/Components/Header';
import RemoveQuesionModal from '../Modals/RemoveQuestionModal';
import QuestionBasedFilterBody from '../Components/QuestionBasedFilterBody';
import AddCodingModal from '../Modals/AddCodingModal';
import {
  removeQuestionShow,
  questionBeingAdded,
  premiumQuestionModal
} from '../../../redux/assessmentsVol2/selectors';
import {
  setRemoveQuestionShow,
  setQuestionBeingAdded,
  setPublishedAssessment,
  setPremiumQuestionModal
} from '../../../redux/assessmentsVol2/slice';
import LeavingPageModal from '../Modals/LeavingPageModal';
import ConfimationPopup from '../../../Common/Components/ConfimationPopup';
import DeleteAssessmentModal from '../Modals/DeleteAssessmentModal';
import PremiumQuestionModal from '../Modals/PremiumQuestionModal';

export default function QuestionBasedAssessmentPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(null);
  const showRemoveQModal = useSelector(removeQuestionShow);
  const toBeAddedQ = useSelector(questionBeingAdded);
  const showPremium = useSelector(premiumQuestionModal);
  return (
    <div>
      <Header />
      <QuestionBasedFilterBody />
      <LeavingPageModal />
      <RemoveQuesionModal />
      <PremiumQuestionModal
        show={false} // Make it show Premium
        onHide={() => {
          dispatch(setPremiumQuestionModal(false));
        }}
      />
      <DeleteAssessmentModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        setConfirmedNavigation={setConfirmedNavigation}
      />
      <ConfimationPopup
        when
        pathname={history.location.pathname}
        deleteModal={setShowDeleteModal}
        confirmedNavigation={confirmedNavigation}
        setConfirmedNavigation={setConfirmedNavigation}
        navigate={path => history.push(path)}
        shouldBlockNavigation={clocation => {
          if (
            clocation.pathname === location.pathname ||
            clocation.pathname.startsWith('/assessment-customization/')
          ) {
            dispatch(setPublishedAssessment(false));
            return false;
          }
          return true;
        }}
      />
      <RemoveQuesionModal
        show={showRemoveQModal}
        onHide={() => dispatch(setRemoveQuestionShow(false))}
      />
      <AddCodingModal
        show={toBeAddedQ && toBeAddedQ.type === 'Coding'}
        onHide={() => {
          dispatch(setQuestionBeingAdded(null));
        }}
      />
    </div>
  );
}
