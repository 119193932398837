import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { freeTextAnswersRedux } from '../../../redux/addQuestion/selectors';
import { setFreeTextAnswersRedux } from '../../../redux/addQuestion/slice';
// import QuestionHeader from '../header/QuestionHeader';
// import TextEditor from './textEditor';
import styles from '../Styles/FreeText.module.css';

function FreeTextAnswers() {
  const dispatch = useDispatch();
  const reduxFreeTextAnswers = useSelector(freeTextAnswersRedux);

  const [arrayElementChange, setArrayElementChange] = useState(0);

  /// ///////////////////////////////////////////////////////////////////////
  // Will be used when switching to TextEditor
  /// ///////////////////////////////////////////////////////////////////////
  /*
  const [htmlContent, setHtmlContent] = useState(['', '', '', '', '']);

  const getContent = htmlContentProp => {
    // Needs further modifying
    setHtmlContent(htmlContentProp);
    console.log(htmlContentProp);
  };
  */

  /// ///////////////////////////////////////////////////////////////////////

  const updateAnswer = (val, index) => {
    const temp = reduxFreeTextAnswers.slice();
    temp[index] = val;
    dispatch(setFreeTextAnswersRedux(temp));
  };

  useEffect(async () => {
    setArrayElementChange(0);
  }, [arrayElementChange]);

  return (

    <div className={styles.FreeTextRightPane}>
      <div>
        <p>
          <b>Answers</b><br />
          Enter at least 5 answers
        </p>
      </div>

      <div className={styles.answerBlock} style={{ height: '85%', overflowY: 'scroll' }}>
        {reduxFreeTextAnswers.map((e, i) => (
          <div className={`d-block w-100 p-2  ${styles['FormInput-custom']}`}> Answer #{i+1}
            <br />
            <input 
              className='input' 
              placeholder='Enter an answer' 
              type='text' 
              name='input_name' 
              onChange={val => {updateAnswer(val.target.value, i); setArrayElementChange(1);}}
              value={e}
              style={{ "height": "80%", "width": "100%" }} 
            />
          </div>
          // <TextEditor getContent={getContent} currentBody="" />
        ))}
      </div>
    </div>

  );
}
export default FreeTextAnswers;