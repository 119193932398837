import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../Common/Components/CustomButton';
import {
  customAssessmentId,
  rootAssessmentQuestionList,
  sessionQuestion
} from '../../../redux/assessmentsVol2/selectors';
import styles from '../Styles/QuestionBasedRightColumn.module.css';
import AssessmentWeightSelectorItem from './AssessmentWeightSelectorItem';
import { ReactComponent as Icon } from '../../../images/purple_info.svg';
import { setSessionQuestion } from '../../../redux/assessmentsVol2/slice';

export default function QuestionBasedRightColumn() {
  const array = useSelector(rootAssessmentQuestionList);
  const id = useSelector(customAssessmentId);
  const history = useHistory();
  const questionAdded = useSelector(sessionQuestion);
  const dispatch = useDispatch();
  const calculateMin = questionList => {
    let timeInSeconds = 0;

    questionList?.forEach(item => {
      timeInSeconds += parseInt(item.questionDuration, 10);
    });

    const time = {
      min: Math.floor(timeInSeconds / 60),
      sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
    };
    if (time.sec === 0) {
      return `${time.min} min`;
    }
    if (time.min === 0) {
      return `${time.sec} sec`;
    }
    return `${time.min} min ${time.sec} sec`;
  };

  useEffect(() => {
    return () => {
      dispatch(setSessionQuestion(false));
    };
  }, []);

  return (
    <div className={styles.rightColumn}>
      {!questionAdded ? (
        <CustomButton
          buttonOnClick={() => {
            // history.push(`/assessment-customization/${id}`);
            history.goBack();
          }}
          style={{
            width: '90%',
            marginTop: 12,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 24
          }}
          textField="Back to Assessment"
          type={5}
          size="small"
        />
      ) : (
        <CustomButton
          buttonOnClick={() => {
            history.goBack();
            // history.push(`/assessment-customization/${id}`);
          }}
          style={{
            width: '90%',
            marginTop: 12,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 24
          }}
          textField="Confirm"
          type={1}
          size="small"
        />
      )}
      <p>Assessment Details</p>
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '90%',
          minWidth: 'fit-content',
          display: 'flex'
        }}
      >
        <div className={styles.detailsBar}>
          <div className={styles.detailsColumn}>
            <div className={styles.questionInfo}>Questions:</div>
            <div className={styles.questionNumber}>{array && array.length}</div>
          </div>
          <div className={styles.detailsColumn}>
            <div className={styles.questionInfo}>Duration:</div>
            <div className={styles.questionNumber}>{calculateMin(array)}</div>
          </div>
        </div>
      </div>
      <p type="last">Questions</p>
      <div className={styles.listContainer}>
        {array && array.length === 0 ? (
          <>
            <div className={styles.noQText}>There is no question yet.</div>
            <div className={styles.noQuestion}>
              <Icon />
              <div className={styles.noQuestionText}>Click the plus icon to add a question.</div>
            </div>
          </>
        ) : (
          array?.map((item, index) => (
            <AssessmentWeightSelectorItem
              key={`${item}`}
              id={id}
              item={item}
              index={index}
              type="question"
            />
          ))
        )}
      </div>
    </div>
  );
}
