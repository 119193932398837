import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import UserIcon from '../../../../Common/Components/UserIcon';
import styles from './AddOnCard.module.css';
import {
  setUnsubscribeAddOnModalShow,
  setToBeCancelledAddOnType,
  setUnsubscribeAddOnInfoCount,
  setUnsubscribeAddOnItemCode,
  setUnsubscribeSuccessShow
} from '../../../../redux/plan/slice';
import { ReactComponent as DownPoly } from '../../../Images/downPoly.svg';
import { ReactComponent as RightPoly } from '../../../Images/rightPoly.svg';
import { ReactComponent as Close } from '../../../Images/closeButton.svg';
import { userSelector } from '../../../../redux/auth/selectors';
import { currentPlanRedux, unsubscribeAddOnModalShow, unsubscribeSuccessShow } from '../../../../redux/plan/selectors';
import UnsubscribeModal from '../../../Modals/UnsubscribeModal';
import UnsubscribeSuccessModal from '../../../Modals/UnsubscribeSuccessModal';

function AddOnCard({ items, addOnName }) {
  const dispatch = useDispatch();
  const [addOnOpen, setAddOnOpen] = useState(false);
  const user = useSelector(userSelector);
  const [name, setName] = useState();
  const [surname, setSurname] = useState();

  useEffect(() => {
    // to set the name and surname of unsubscribed users for nested userIcon
    if (items) {
      const nameArray = [];
      const surnameArray = [];
      items.forEach(item => {
        if (item.unsubscribeUser !== '') {
          const nameSurname = item?.unsubscribeUser?.split(' ');
          const len = nameSurname.length;
          nameArray.push(nameSurname[0]);
          surnameArray.push(nameSurname[len - 1]);
        }
      });
      setName(nameArray);
      setSurname(surnameArray);
    }
  }, [items]);

  // Tüm addon iptal edilecekse removeOne=0
  // Sadece tek bir assessment addon'u iptal edilecekse removeOne=1
  async function handleUnsubscribeAddOn(addOn, removeOne) {
    if (removeOne) {
      // Published assessmentları tek tek remove etmek için
      await dispatch(setUnsubscribeAddOnInfoCount(1));
      await dispatch(setToBeCancelledAddOnType('Published Assessments'));
      await dispatch(setUnsubscribeAddOnItemCode(addOn.addOnCode));
      await dispatch(setUnsubscribeAddOnModalShow(true));
    } else {
      /* eslint-disable no-lonely-if */
      if (addOn?.addOnPlanName === 'Published Assessments') {
        // await dispatch(setToBeCancelledAddOn(idList));
        await dispatch(setUnsubscribeAddOnInfoCount(addOn.usedValues));
        await dispatch(setToBeCancelledAddOnType('Published Assessments'));
        await dispatch(setUnsubscribeAddOnItemCode(addOn.addOnCode));
        await dispatch(setUnsubscribeAddOnModalShow(true));
      } else if (addOn?.addOnPlanName === 'Candidates') {
        // await dispatch(setToBeCancelledAddOn(idList));
        await dispatch(setUnsubscribeAddOnInfoCount(addOn.allowedValues));
        await dispatch(setToBeCancelledAddOnType('Candidates'));
        await dispatch(setUnsubscribeAddOnItemCode(addOn.addOnCode));
        await dispatch(setUnsubscribeAddOnModalShow(true));
      }
    }
  }
  const currentPlan = useSelector(currentPlanRedux);
  const unsubscribeAddOnShow = useSelector(unsubscribeAddOnModalShow);
  const unsubscribeSuccess = useSelector(unsubscribeSuccessShow);


  return (
    <div className={`${styles.planWrapper} ${styles.addOnContainer} ${addOnOpen && styles.open}`}>
      {/* Unsubscribe AddOn confirm modal */}
      <UnsubscribeModal
        title="Are you sure?"
        description={`You are about to unsubscribe from the additional ${addOnName} package.`}
        show={unsubscribeAddOnShow}
        onHide={() => dispatch(setUnsubscribeAddOnModalShow(false))}
        addOn
      />
      {/* Unsubscribe AddOn success modal */}
      <UnsubscribeSuccessModal
        show={unsubscribeSuccess}
        onHide={() => dispatch(setUnsubscribeSuccessShow(false))}
        title="Successfully Unsubscribed!"
        footer=" *You will be able to use them till the next billing period."
        icon="tick"
      />
      <div className={`${styles.row} ${styles.headerRow}`}>
        <div className={styles.header}>
          Additional{' '}
          {items[0]?.addOnPlanName?.split(' ')?.length > 1
            ? items[0]?.addOnPlanName?.split(' ')[1]
            : items[0]?.addOnPlanName?.split(' ')[0]}
        </div>
        {isMobile ? null : <span className={styles.icon} />}
      </div>
      <div className={styles.outerContainer}>
        <div className={styles.outerRow}>
          {items[0]?.addOnPlanName === 'Published Assessments' &&
            items?.length >= 1 &&
            items?.filter(a => a.subscriptionStatus === 1)?.length > 0 && (
              <div className={styles.iconContainer}>
                {!addOnOpen ? (
                  <RightPoly
                    onClick={() => setAddOnOpen(true)}
                    className={styles.arrowIcon}
                    style={{ marginRight: '9px', cursor: 'pointer' }}
                  />
                ) : (
                  <DownPoly
                    onClick={() => setAddOnOpen(false)}
                    style={{ marginRight: '9px', cursor: 'pointer' }}
                  />
                )}
              </div>
            )}
          <div className={styles.row}>
            <div className={styles.addOnTitle}>
              {items[0].addOnCode === 10 ? items?.length : items[0]?.allowedValues}{' '}
              {items[0]?.addOnPlanName?.split(' ')?.length > 1
                ? items[0]?.addOnPlanName?.split(' ')[1]?.toLocaleLowerCase()
                : items[0]?.addOnPlanName?.split(' ')[0]?.toLocaleLowerCase()}
              /{currentPlan?.name === 'Premium' ? 'year' : 'month'}
            </div>
            {items[0]?.addOnCode === 20 && (
              <div className={styles.usagePercent}>
                {items[0]?.usedValues} / {items[0]?.allowedValues}
              </div>
            )}
          </div>
        </div>
        {addOnOpen && (
          <div className={styles.listWrapper}>
            {items?.map(
              (a, index) =>
                a?.subscriptionStatus === 1 && (
                  <div className={styles.row} style={{ marginBottom: '12px' }}>
                    <span className={`${styles.addOnTitleOpen} ${styles.addOnTitle}`}>
                      1 assessment/{currentPlan?.name === 'Premium' ? 'year' : 'month'}
                    </span>
                    <Close
                      className={styles.closeIcon}
                      onClick={() => {
                        handleUnsubscribeAddOn(a, 1);
                      }}
                    />
                  </div>
                )
            )}
          </div>
        )}
      </div>
      {items[0].unsubscribeUser !== '' && items[0].addOnCode === 20 ? (
        <div className={styles.unsubscribedRow}>
          <div className={styles.unsubscribedInfo}>
            <div>
              <span className={styles.boldText}>Unsubscribed </span>
            </div>
            {isMobile ? null : (
              <UserIcon
                textClassName={styles.userIconText}
                className={styles.userIcon}
                comment
                name={name && name[0]}
                surname={surname && surname[0]}
              />
            )}
          </div>
          <span className={styles.validInfoText}>*Available until next billing period</span>
        </div>
      ) : items[0].addOnCode === 10 && items?.filter(x => x.subscriptionStatus === 0).length > 0 ? (
        <div className={styles.unsubscribedRow}>
          <div className={styles.unsubscribedInfo}>
            <div>
              <span className={styles.boldText}>Unsubscribed </span>
              {items?.length > 1 && (
                <span className={styles.lightText}>{`(${
                  items?.filter(i => i.subscriptionStatus === 0)?.length
                }/${items?.length})`}</span>
              )}
            </div>
            <div>
              {items
                ?.slice(0, 4)
                ?.map(
                  (a, i) =>
                    a?.subscriptionStatus === 0 && (
                      <UserIcon
                        nested={items?.filter(i => i.subscriptionStatus === 0)?.length > 1}
                        textClassName={styles.userIconText}
                        className={styles.userIcon}
                        comment
                        name={name && name[i]}
                        surname={surname && surname[i]}
                      />
                    )
                )}
            </div>
          </div>
          <span className={styles.validInfoText}>*Available until next billing period</span>
        </div>
      ) : (
        <div
          className={styles.unsubscribeRow}
          onClick={() => {
            handleUnsubscribeAddOn(items[0], 0);
          }}
        >
          Unsubscribe
        </div>
      )}
    </div>
  );
}
export default AddOnCard;
