import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAssessments,
  selectActiveItemCount,
  selectArchiveItemCount,
  filterData,
  archivedCount,
  unpublishedCount,
  publishedCount,
  activeTab,
  getWidth
} from '../../../redux/assessments/selectors';
import {
  filterAssesmentList,
  filterStatusAssessment,
  setActiveTab
} from '../../../redux/assessments/slice';
import { getCompanyAssessmentListAction } from '../../../redux/assessments/actions';
import styles from '../Styles/TabButtonList.module.css';
import { fetchCompanyAssessmentList, useWindowSize } from '../../../utils/helpers';

export default function TabButtonList({ companyId, setLoading }) {
  const dispatch = useDispatch();
  const items = useSelector(selectAssessments);

  const activeItemCount = useSelector(selectActiveItemCount);
  const archiveItemCount = useSelector(selectArchiveItemCount);

  const filterDataType = useSelector(filterData);

  const countArchive = useSelector(archivedCount);

  const countUnpublished = useSelector(unpublishedCount);
  const countPublished = useSelector(publishedCount);
  const [childState, setChildState] = useState(null);
  const [width, setWidth] = useState();
  const [left, setLeft] = useState(null);
  const selectedTab = useSelector(activeTab);
  const getWidthOfButton = useSelector(getWidth);
  const [screenWidth, screenHeight] = useWindowSize();

  const getType = () => {
    let type = '';
    if (filterDataType === 'Time To Hire') {
      type = 'success';
    } else if (filterDataType === 'Average Rating') {
      type = 'warning';
    } else {
      type = 'danger';
    }

    return type;
  };

  useEffect(() => {
    setChildState(document.getElementById(selectedTab)?.getBoundingClientRect());
  }, [getWidthOfButton, selectedTab, countPublished, countArchive, screenWidth]);

  const ref = useRef();

  useEffect(() => {
    if (childState) {
      setWidth(childState.width);
      if (selectedTab === 'active') {
        setLeft(childState.width + 30);
      } else {
        setLeft(document.getElementById('active')?.getBoundingClientRect().width + 30);
      }
    }
  }, [childState]);

  return (
    <div className={styles.headerTabContainer}>
      <div
        style={{ width: width + 30 || 'fit-content', left: selectedTab === 'active' ? 0 : left }}
        className={`${styles.selectedTab} ${selectedTab === 'active' && styles.published}  ${
          selectedTab === 'archived' && styles.archived
        }`}
      />
      <div
        id="active"
        style={{ width: 'fit-content' }}
        onClick={async () => {
          if (selectedTab !== 'active') {
            setLoading(true);
            dispatch(setActiveTab('active'));
            const data = {
              CompanyId: companyId,
              search: '',
              IsActive: true
            };
            dispatch(filterStatusAssessment('primary'));
            const lastType = getType();
            dispatch(filterAssesmentList(lastType));
            dispatch(filterAssesmentList(lastType));
            fetchCompanyAssessmentList(data, setLoading, ref, dispatch);
          }
          
        }}
        className={`${styles.headerTab} ${selectedTab === 'active' && styles.selected}`}
      >
        Active ({countPublished})
      </div>
      <div
        id="archived"
        onClick={async () => {
          if (selectedTab !== 'archived') {
            setLoading(true);
            dispatch(setActiveTab('archived'));
            const data = {
              CompanyId: companyId,
              search: '',
              IsActive: false
            };
            dispatch(filterStatusAssessment('secondary'));
            const lastType = getType();
            dispatch(filterAssesmentList(lastType));
            dispatch(filterAssesmentList(lastType));
            fetchCompanyAssessmentList(data, setLoading, ref, dispatch);
          }
        }}
        className={`${styles.headerTab} ${selectedTab === 'archived' && styles.selected}`}
      >
        Archived ({countArchive})
      </div>
    </div>
  );
}
