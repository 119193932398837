import { React, useEffect, useState } from 'react';
import LanguageDropdownItem from './LanguageDropdownItem';
import styles from '../Styles/LanguageDropdown.module.css';

const LanguageDropdown = ({
  list = [{ id: '', title: '', key: '' }],
  visibleItemNo,
  wrapperStyle = {},
  headerStyle = {},
  listStyle = {},
  itemStyle = {},
  setSelectedOption,
  defaultSelectedId,
  myOnClick = () => {},
  ...props
}) => {
  const [selected, setSelected] = useState(null);
  const [myList, setMyList] = useState(list);
  const lastIndex = list.length;
  //   let wrapperElem = null;
  const [allowedHeight, setAllowedHeight] = useState(visibleItemNo*28);

  const [isListOpen, setListOpen] = useState(false);

  useEffect(() => {
    list.forEach(element => {
      if (defaultSelectedId && element.id === defaultSelectedId) {
        setSelected(element);

        setSelectedOption(element);
      }
    });
  }, [JSON.stringify(list)]);

  useEffect(async () => {
    myList.forEach(element => {
      if (element.id !== selected?.id) {
        element.selected = false;
      } else {
        element.selected = true;
      }
    });
  }, [selected]);

  return (
    <div
      className={styles.wrapper}
      style={wrapperStyle}
      id="wrapper"
      onClick={() => setListOpen(!isListOpen)}
    >
      <div className={styles.innerWrapper} onClick={() => setListOpen(!isListOpen)}>
        <div className={styles.placeholder} style={headerStyle}>
          {selected && selected.title}
        </div>
        <span alt="" className={isListOpen ? styles.arrowUp : styles.arrowDown} />
      </div>

      {isListOpen && (
        <div role="list" className={styles.list} style={{ height: `${allowedHeight}px` }}>
          {myList.map(item => (
            <LanguageDropdownItem
              itemStyle={itemStyle}
              href=""
              key={item.key}
              id={item.id}
              title={item.title}
              first={item.key === 1}
              last={item.key === lastIndex}
              selected={selected && item.key === selected.id}
              onSelect={() => {
                setListOpen(!isListOpen);
                setSelectedOption(item);
                setSelected(item);
                myOnClick();
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default LanguageDropdown;
