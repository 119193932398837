/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import route from '../../../constant/route';
import { useWindowSize } from '../../../utils/helpers';

import styles from '../Styles/AssessmentCardComponent2.module.css';
import { ReactComponent as CandidateIcon } from './candidates.svg';
import { ReactComponent as CalendarIcon } from './calendar.svg';
import ShortText from '../../../Common/Components/ShortText';
import TextOverflow from '../../../Common/TextOverflow/TextOverflow';

function AssessmentCardComponent({ item, ...props }) {

  function publishedStatusPicker(publishedStatus) {
    switch (publishedStatus) {
      // 10: TimeToHire 20:"AverageRating" 30:"WaitForMore"
      case 10:
        return ['#4AC389', 'Published'];
      case 20:
        return ['rgba(57, 57, 57, 0.5)', 'Unpublished'];
      case 30:
        return ['#FF7E62', 'Archived'];
      default:
        return ['#FF7E62', 'Archived'];
    }
  }

  const [color, text] = publishedStatusPicker(item.publishedStatus);

  function backgroundPicker(hiringStatus) {
    switch (hiringStatus) {
      // 10: TimeToHire 20:"AverageRating" 30:"WaitForMore" -1: Not enough data
      case 10:
        return '#4AC389';
      case 20:
        return '#FFBB01';
      case 30:
        return '#FF7E62';
      case -1:
        return '#FF7E62';
      default:
        return '#FF7E62';
    }
  }
  function hiringStatusTextPicker(hiringStatus) {
    switch (hiringStatus) {
      // 10: TimeToHire 20:"AverageRating" 30:"WaitForMore" -1:Not enough data
      case 10:
        return 'TIME TO HIRE';
      case 20:
        return 'AVERAGE';
      case 30:
        return 'WAIT FOR MORE';
      case -1:
        return 'WAIT FOR MORE';
      default:
        return 'WAIT FOR MORE';
    }
  }
  function senorityTextPicker(seniorityLevel) {
    switch (seniorityLevel) {
      case 10:
        return 'Junior - ';
      case 20:
        return 'Mid-Senior - ';
      case 30:
        return 'Senior - ';
      default:
        return '';
    }
  }
  const hiringText = hiringStatusTextPicker(item.hiringStatus);
  const seniorityText = senorityTextPicker(item.seniorityLevel);
  const HireStatusColor = backgroundPicker(item.hiringStatus);

  const onClickEnableAssessmentDetailPageviewEvent = () => {
    localStorage.setItem('Mixpanel_AssessmentDetailPageviewFlag', 1);
  };

  const [width, height] = useWindowSize();
  // const [charLimit, setCharLimit] = useState(20);

  const [textWidth, setTextWidth] = useState();

  useEffect(() => {
    // const charWidth = document.getElementById('charFlag')?.getBoundingClientRect()?.width;
    const textFieldWidth = document.getElementById(item.id)?.getBoundingClientRect()?.width;
    setTextWidth(textFieldWidth)
    // setCharLimit(Math.ceil(textFieldWidth/charWidth) + 3);
  }, [width])

  return (
    <div key={item.id} className={styles.cardWrapper}>
      <Link
        className={styles.linkStyle}
        // style={{ width: '100%' }}
        to={route.assessmentDetail(item.id)}
        onClick={() => onClickEnableAssessmentDetailPageviewEvent()}
      >
        <div className={styles.card}>
          <div className={styles.upperCard}>
            <div
              style={{
                color: color
              }}
              className={styles.status}
            >
              {text}
            </div>

            <div>
              {/* <div className={styles.assessmentName} id="charFlag" style={{visibility: 'hidden', position: 'absolute'}}>A</div> */}
              <div className={styles.assessmentName} id={item.id}>
                {/* <ShortText name={item.name} staticLimit={charLimit} /> */}
                <TextOverflow 
                  text={item.name}
                  itemId={`assessmentItem-${item.id}`}
                  position="top"
                  width={textWidth}
                />
              </div>
              <div className={styles.textWrapper}>
                <div
                  className={styles.position}
                >{`${seniorityText}${item.rootAssessmentName}`}</div>
              </div>
            </div>

            {/* <div>
              {item?.assessmentUserList?.slice(0, 3)?.map((relatedPerson, index) => (
                <UserIcon
                  name={index !== 2 ? relatedPerson.name : '+'}
                  surname={
                    index !== 2 ? relatedPerson.surname : item?.assessmentUserList?.length - 2
                  }
                  nested={index > 0}
                />
              ))}
            </div> */}

            <div className={styles.candidateFrame}>
              <div className={styles.IconTextWrapper} style={{borderRight: "1px solid rgba(57, 57, 57, 0.15)"}}>
                <CandidateIcon className={styles.CandidateIcon} />
                <div className={styles.text}>
                  {item.candidateCount}&nbsp;candidate{item.candidateCount > 1 && `s`}
                </div>
              </div>
              {/* <div className={styles.border} /> */}
              <div className={styles.IconTextWrapper}>
                <CalendarIcon />
                <div className={styles.text}>
                  {/* {item.dateDiff}&nbsp;day{item.dateDiff > 1 && `s`} ago */}
                  {item.dateDiff === 0 ? "Today" : item.dateDiff === 1 ? "Yesterday" : `${item.dateDiff} days ago`}
                </div>
              </div>
              
              
            </div>
          </div>
          <div className={styles.lowerCard}>
            {/* 10: TimeToHire 20:"AverageRating" 30:"WaitForMore" -1: Not enough data */}
            {item.hiringStatus === 20 && (
              <div className={styles.industryAverageWrapper}>
                <div style={{ color: '#FFBB01' }}>Approximately&nbsp;</div>
                <div>industry average</div>
              </div>
            )}
            {(item.hiringStatus === 10 || item.hiringStatus === 30) && item.percentage !== -200 && (
              <div className={styles.industryAverageWrapper}>
                <div style={{ color: item.percentage >= 0 ? '#4AC389' : '#FF7E62' }}>
                  {Math.abs(Math.floor(item.percentage))}%&nbsp;
                  {item.percentage >= 0 ? 'better' : 'worse'}&nbsp;
                </div>
                <div>than industry average</div>
              </div>
            )}
            {item.percentage === -200 && (
              <div className={styles.industryAverageWrapper}>
                <div style={{ color: '#FF7E62' }}>Not enough&nbsp;</div>
                <div>candidates to compare</div>
              </div>
            )}
            <div
              className={styles.hiringStatusWrapper}
              style={{ background: HireStatusColor }}
            >
              {hiringText}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default AssessmentCardComponent;
