import React, { Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CookiesProvider } from 'react-cookie';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import AssessmentsPage from './Assessment/AssessmentList/Pages/assessmentsPage';
import AssessmentDetail from './Assessment/NewAssessmentDetail/Pages/AssessmentDetailPage';
import LoginPage from './Auth/Pages/Login/LoginPage';
import AddAssessment from './Assessment/AddAssessment/Pages/addAssessment';
import route from './constant/route';
import {
  authSelector,
  settingsPageSuccessMessage,
  settingsPageSuccessShow,
  userSelector
} from './redux/auth/selectors';

// import AddLogo from './Common/Pages/addLogo';
import Question from './Assessment/Test/Pages/addAssessmentShowQuestion';
import CodingQuestionPage from './Assessment/Question/Pages/codingQuestionPage';
import MultiSelectQuestionPage from './Assessment/Question/Pages/multiSelectQuestionPage';
import FreeTextQuestionPage from './Assessment/Question/Pages/freeTextQuestionPage';
import CandidatePage from './Candidate/Pages/CandidatePage';
import InvitedUserActivatePage from './Auth/Pages/Signup/InvitedUserActivatePage';

import './style/globalStyle.css';

import AssessmentCustomizationPage from './Assessment/AddAssessment/Pages/AssessmentCustomizationPage';
import QuestionBasedAssessmentPage from './Assessment/AddAssessment/Pages/QuestionBasedAssessmentPage';

import CandidateResultPublicPage from './Candidate/Pages/CandidateResultPublicPage';
import { getDomainProtocol, getOnlyDomain } from './utils/helpers';
import { setLoading, setSettingsPageSuccessShow } from './redux/auth/slice';
import WelcomeCandidatePage from './Candidate/Pages/WelcomeCandidatePage';
import QuestionPage from './Assessment/Test/Pages/QuestionPage/QuestionPage';
import CandidateResultPage from './Assessment/Test/Pages/CandidateResultPage';
import AssessmentSessionInformation from './Assessment/Test/Pages/AssessmentSessionInformation';
import ApplicationPage from './Candidate/Pages/ApplicationPage';
import SqlQuestionPage from './Assessment/Question/Pages/sqlQuestionPage';
import SalesPage from './Auth/Pages/SalesPage/SalesPage';

import TryAssessmentPage from './Assessment/AddAssessment/Pages/TryAssessmentPage';
import CreateAssessmentPage from './Assessment/AddAssessment/Pages/CreateAssessmentPage';
import SettingsPage from './Auth/Pages/Settings/SettingsPage';
import { setTab } from './redux/addAssessment/slice';
import {
  currentPlanRedux,
  onboardingStepRedux,
  paymentFailedShow,
  paymentMethodModalShow,
  ShowPackageDetail
} from './redux/plan/selectors';
import {
  getCurrentPlanAction,
  getCurrentUsageAction,
  getOnboardingStepsAction
} from './redux/plan/actions';
import { useInterval } from './utils/useInterval';
import { getCurrentUserDetailAction } from './redux/auth/actions';
import UnpaidContactSalesModal from './Auth/Modals/UnpaidContactSales/UnpaidContactSalesModal';
import ContactSalesSuccess from './Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import {
  setContactSalesModalShow,
  setOnboardingStep,
  setPaymentMethodModalShow,
  setShowPackageDetail,
  setWelcomeOnboardShow
} from './redux/plan/slice';
import PaymentMethod from './Auth/Modals/PaymentMethod';
import InfoBanner from './Common/Components/InfoBanner';
import NotFound from './Common/Pages/NotFound/NotFound';
import SignupPage from './Auth/Pages/Signup/SignupPage';
import ForgotPasswordPage from './Auth/Pages/ForgotPassword/ForgotPasswordPage';
import NewPasswordPage from './Auth/Pages/NewPassword/NewPasswordPage';
import UserInfoPage from './Auth/Pages/Signup/UserInfoPage';

import mixpanel, { EXCLUDED_COMPANY_LIST } from './utils/mixpanel';
import Loading from './Common/Components/Loading';
import Maintenance from './Common/Pages/UnderConstruction/Construction';
import CandidateDetailPage from './Candidate/Pages/CandidateDetail/CandidateDetailPage';
import PackageDetailsModal from './Common/Modals/PackageDetailsModal';

const CvReminderPage = React.lazy(() => import('./Candidate/Pages/CvReminder/CvReminderPage'));
const CandidateLanding = React.lazy(() =>
  import('./Candidate/Pages/CandidateLanding/CandidateLanding')
);
// import CvReminderPage from './Candidate/Pages/CvReminder/CvReminderPage';

function App() {
  const UNDER_MAINTENANCE_FLAG = false;

  const currentStep = useSelector(onboardingStepRedux);
  const CURRENT_PLAN_REQUEST_INTERVAL = 600000; // 10min
  // const CURRENT_USER_REQUEST_INTERVAL = 600000  // 10min

  // Eğer link eskiyse, i.e. "coensio.coens.io" formatı, ya da direkt subdomain yoksa
  // subdomain'i app olarak ata
  function checkSubdomain() {
    if (window.location.host === "18.184.207.179") {
      return;
    }
    const arr = window.location.href.split('://');
    const subdom = arr[1].split(getOnlyDomain());
    if (subdom[0] !== 'app.') {
      window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}${subdom[1]}`;
    }
  }
  useEffect(() => {
    checkSubdomain();
  }, []);

  const dispatch = useDispatch();
  const currentPlan = useSelector(currentPlanRedux);
  const loginState = useSelector(authSelector);
  const user = useSelector(userSelector);

  // 2 endpoint için de token var mı yok mu check'i
  const isLoggedIn = () => {
    if (loginState.token && loginState.token2) {
      dispatch(setLoading(false));
      return true;
    }
    return false;
  };

  // public sayfalar - login olmadan da erişilebiliyor olmalı
  const reachablePages = [
    route.activate,
    route.refreshPassword,
    route.welcomeCandidate,
    route.candidateResult,
    route.register,
    route.forgotPassword,
    route.userActivate,
    route.salesScreen().split('/:index')[0],
    route.companyUserActivate,
    route.assessmentPublic().split('/:id')[0],
    '/candidate-public',
    route.assessmentSessionInformation,
    route.assessmentSession,
    route.notFound,
    route.cvReminder,
    route.candidateLanding
  ];

  const fetchUserInfo = async () => {
    const resp = await dispatch(getCurrentUserDetailAction());

    /* --------------- Mixpanel identify  --------------- */
    // After login or change company

    if (resp.meta.requestStatus === 'fulfilled') {
      if (resp.payload.isFirstAdmin) {
        await dispatch(getOnboardingStepsAction()); // initial call due to fill QS progress bar accordingly + not show QS
      }

      const isExcluded = EXCLUDED_COMPANY_LIST?.includes(resp?.payload?.companyName);
      if (!isExcluded) {
        mixpanel.identify(resp.payload.userId);

        if (resp.payload.userGroupType !== 'Super Admin') {
          mixpanel.people.set({
            $name: resp.payload.userNameSurname,
            $email: resp.payload.email,
            'Company Name': resp.payload.companyName,
            'Admin Flag': resp.payload.isAdmin,
            'User Role': resp.payload.userGroupType
          });

          mixpanel.set_group('company_name', resp.payload.companyName);
          mixpanel
            .get_group('company_name', resp.payload.companyName)
            .set_once('$name', resp.payload.companyName);
        } else {
          mixpanel.people.set({
            $name: resp.payload.userNameSurname,
            $email: resp.payload.email,
            'Company Name': 'Coensio'
          });
          mixpanel.set_group('company_name', 'Coensio');
        }
      }
    }
    /* -------------------------------------------------- */
  };

  useEffect(() => {
    dispatch(setTab(user?.userGroupType));
  }, [user]);

  const [isUnpaidUser, setIsUnpaidUser] = useState(false);
  const [contactSalesSucess, setContactSalesSuccess] = useState(false);
  const paymentFailed = useSelector(paymentFailedShow);
  const showPackageModal = useSelector(ShowPackageDetail);

  useEffect(() => {
    // Giriş yapılıysa ya da yeni giriş yaptıysa
    if (isLoggedIn()) {
      // url /login'se ve giriş yapılıysa -> assessments'a yönlendir
      if (
        window.location.pathname.toLowerCase() === '/login' ||
        window.location.pathname.toLowerCase() === '/login/'
      ) {
        setTimeout(() => {
          const redirectLink = localStorage.getItem('redirect');
          if (redirectLink) {
            localStorage.removeItem('redirect');
            window.location.href = redirectLink;
          } else
            window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/assessments`;
        }, 100);
      }

      if (!user || user.version !== 3) {
        if (!loginState.loading && !window.location.pathname.toLowerCase().startsWith('/login')) {
          fetchUserInfo();
        }
      }
      // önceden giriş yapılıysa herhangi bir check yok, direkt var olan sayfaya devam
    }
    // Giriş yapılmadıysa -> public page mi değil mi kontrol et
    else {
      let isPublic = false;
      reachablePages.forEach(e => {
        if (e === window.location.pathname) {
          isPublic = true;
        } else if (window.location.pathname.toLowerCase().startsWith(e.toLowerCase())) {
          isPublic = true;
        }
      });
      if (!isPublic && window.location.pathname !== '/login') {
        localStorage.setItem('redirect', window.location.href);
        window.location.pathname = '/login';
      }
    }
  }, [loginState]);

  // User değiştiğinde + sayfa yenilendiğinde getCurrentPlan'ı çek
  useEffect(() => {
    if (user) {
      const data = {
        CompanyId: user?.companyId
      };

      let isPublic = false;
      reachablePages.forEach(e => {
        if (e === window.location.pathname) {
          isPublic = true;
        } else if (window.location.pathname.toLowerCase().startsWith(e.toLowerCase())) {
          isPublic = true;
        }
      });

      if (!isPublic) {
        dispatch(getCurrentPlanAction(data));
        dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
      }
    }
  }, [user]);

  // 10 dakikada bir current planı çek
  useInterval(() => {
    if (user) {
      const data = {
        CompanyId: user?.companyId
      };
      dispatch(getCurrentPlanAction(data));
    }
  }, CURRENT_PLAN_REQUEST_INTERVAL);

  // Unpaid check
  useEffect(() => {
    if (currentPlan) {
      let isPublic = false;
      reachablePages.forEach(e => {
        if (e === window.location.pathname) {
          isPublic = true;
        } else if (window.location.pathname.toLowerCase().startsWith(e.toLowerCase())) {
          isPublic = true;
        }
      });

      if (currentPlan?.payStatus === 20 && !isPublic) {
        setIsUnpaidUser(true);
      } else {
        setIsUnpaidUser(false);
      }
    }
  }, [currentPlan]);

  const paymentMethodShow = useSelector(paymentMethodModalShow);
  const seePlansShow = useSelector(ShowPackageDetail);
  const successText = useSelector(settingsPageSuccessMessage);
  const successShow = useSelector(settingsPageSuccessShow);

  return (
    <CookiesProvider>
      <UnpaidContactSalesModal
        freeTrialEnded={currentPlan?.name === 'Free Trial'}
        addOnCode={10}
        show={
          isUnpaidUser &&
          !contactSalesSucess &&
          !paymentMethodShow &&
          !seePlansShow &&
          user?.userGroupType !== 'Super Admin'
        }
        onHide={() => setIsUnpaidUser(false)}
        title={
          currentPlan?.name === 'Free Trial'
            ? 'Thank you for trying Coensio!'
            : 'Account Suspended!'
        }
        description={
          currentPlan?.name === 'Free Trial'
            ? 'Your free trial has ended. Purchase a paid plan to continue your journey.'
            : `Your subscription hasn’t been renewed.
        Please ${
          currentPlan?.isCustom ? 'contact our sales team' : 'change your payment method'
        } to renew your subscription.`
        }
        isCustom={currentPlan?.isCustom}
        setSuccess={setContactSalesSuccess}
      />
      {showPackageModal ? (
        <PackageDetailsModal
          show={showPackageModal}
          onHide={() => {
            dispatch(setShowPackageDetail(false));
          }}
        />
      ) : null}
      <PaymentMethod
        show={paymentMethodShow !== ''}
        onHide={() => dispatch(setPaymentMethodModalShow(''))}
      />
      <InfoBanner
        text={successText}
        show={successShow}
        setStatus={e => dispatch(setSettingsPageSuccessShow(e))}
      />
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSucess}
        onHide={() => {
          dispatch(setContactSalesModalShow(false));
        }}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />
      <Router basename="/">
        {UNDER_MAINTENANCE_FLAG ? (
          <Switch>
            <Route path="*">
              <Maintenance />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path={route.notFound}>
              <NotFound />
            </Route>
            <Route path={route.refreshPassword}>
              <NewPasswordPage />
            </Route>
            <Route path={route.activate}>
              <UserInfoPage />
            </Route>
            <Route path={route.welcomeCandidate}>
              <WelcomeCandidatePage />
            </Route>
            <Route path={route.candidateResult}>
              <CandidateResultPage />
            </Route>
            <Route path={route.register}>
              <SignupPage />
            </Route>
            <Route path={route.forgotPassword}>
              <ForgotPasswordPage />
            </Route>
            <Route path={[route.userActivate, route.companyUserActivate]}>
              <InvitedUserActivatePage />
            </Route>
            <Route path={route.assessmentPublic()}>
              <ApplicationPage />
            </Route>
            <Route path={route.assessments}>
              <AssessmentsPage />
            </Route>
            <Route path="/assessment-detail/:id">
              <AssessmentDetail />
            </Route>

            <Route path={route.addAssessment}>
              <AddAssessment />
            </Route>

            <Route path="/question/:id">
              <Question />
            </Route>
            {/* <Route path={route.addLogoScreen}>
              <AddLogo />
            </Route> */}

            <Route path={route.codingQuestion}>
              <CodingQuestionPage />
            </Route>
            <Route path={route.multiSelectQuestion}>
              <MultiSelectQuestionPage />
            </Route>
            <Route path={route.freeTextQuestion}>
              <FreeTextQuestionPage />
            </Route>
            <Route path={route.sqlQuestion}>
              <SqlQuestionPage />
            </Route>

            {/* <Route path="/candidate-detail/:id">
              <CandidatePage />
            </Route> */}
            <Route path="/candidate-detail/:id">
              <CandidateDetailPage />
            </Route>

            <Route path="/assessment-customization/:id">
              <AssessmentCustomizationPage />
            </Route>
            <Route path="/question-based-assessment/:id">
              <QuestionBasedAssessmentPage />
            </Route>
            <Route path="/try-assessment/:id">
              <TryAssessmentPage />
            </Route>
            <Route path="/create-assessment/:id/:index">
              <CreateAssessmentPage />
            </Route>

            <Route path="/candidate-public">
              <CandidateResultPublicPage />
            </Route>
            <Route path={route.login}>
              <LoginPage />
            </Route>

            <Route path="/settings/:place">
              <SettingsPage />
            </Route>

            <Route path={route.assessmentSessionInformation}>
              <AssessmentSessionInformation />
            </Route>
            <Route path={route.assessmentSession}>
              <QuestionPage />
            </Route>
            <Route path={route.salesScreen()}>
              <SalesPage />
            </Route>
            <Route path={route.cvReminder}>
              <Suspense
                fallback={
                  <LoadingWrapper>
                    <Loading />
                  </LoadingWrapper>
                }
              >
                <CvReminderPage />
              </Suspense>
            </Route>

            <Route path={route.candidateLanding}>
              <Suspense
                fallback={
                  <LoadingWrapper>
                    <Loading />
                  </LoadingWrapper>
                }
              >
                <CandidateLanding />
              </Suspense>
            </Route>
            <Route
              exact
              path="/"
              render={() =>
                loginState.isLogged ? (
                  <Redirect to={route.assessments} />
                ) : (
                  <Redirect to={route.login} />
                )
              }
            />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        )}
      </Router>
    </CookiesProvider>
  );
}

export default App;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
