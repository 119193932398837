import { React } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as Plus } from '../../../images/addAssessment/new_AddIcon.svg';
import QuestionBasedQuestion from './QuestionBasedQuestion';
import styles from '../Styles/QuestionBasedLeftColumn.module.css';
import route from '../../../constant/route';
import { rootAssessmentQuestionList } from '../../../redux/assessmentsVol2/selectors';
import CustomButton from '../../../Common/Components/CustomButton';

export default function QuestionBasedLeftColumn() {
  // const { id } = useParams();
  const url = window.location.href;
  const lastSegment = url.split('/').pop();

  const questions = useSelector(rootAssessmentQuestionList);

  const scrollHeight = questions && JSON.stringify(97 * questions.length + 120);

  return (
    <div className={styles.wrapper} style={{ height: `${scrollHeight}px` }}>
      <div className={styles.questionCount}>
        <span className={styles.countText}>({questions && questions.length}) Questions</span>
        <NavLink className={styles.icon} to={route.questionBasedAssessment(lastSegment)}>
          <span>
            <Plus className={styles.plus} />
          </span>
        </NavLink>
      </div>
      {!questions || (questions && questions?.length === 0) ? (
        <div className={styles.noQuestion}>
          <div className={styles.noQuestionTextContainer}>
            <div className={styles.noQuestionText}>There is no question yet.</div>
            <NavLink
              className={styles.noQuestionButton}
              to={route.questionBasedAssessment(lastSegment)}
            >
              <CustomButton
                type={1}
                textField="Add First Question"
                size="small"
              />
            </NavLink>
          </div>
        </div>
      ) : (
        <div className={styles.listWrapper}>
          {questions?.map((question, index) => (
            <QuestionBasedQuestion
              qType={question.questionType}
              difficulty={question.difficulty}
              skills={question.label}
              title={question.title}
              duration={question.questionDuration}
              index={index + 1}
              id={question.questionId}
              status={question.purchaseType}
              language={question.questionLanguage}
            />
          ))}
        </div>
      )}
    </div>
  );
}
