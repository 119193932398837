import { React, useEffect, useState, useRef } from 'react';
import DropdownItem from './CustomDropdownItem';
import TextInput from '../TextInput';
import styles from './Dropdown.module.css';
import ShortText from '../ShortText';
import { useWindowSize } from '../../../utils/helpers';

const Dropdown = ({
  title = 'Title',
  list = [{ id: '', title: '', key: '' }],
  placeholder = 'Select one',
  visibleItemNo,
  wrapperStyle,
  headerStyle = {},
  headerTitleStyle = {},
  listStyle = {},
  itemStyle = {},
  setSelectedOption,
  defaultSelectedId,
  changeInList,
  myOnClick = () => {},
  searchBar = false,
  searchBarPlaceholder,
  containerStyle,
  resetSelected,
  ...props
}) => {
  const [selected, setSelected] = useState(null);
  const [myList, setMyList] = useState(list);
  const lastIndex = list.length;
  let wrapperElem = null;
  const [itemElem, setItemElem] = useState();
  const [allowedHeight, setAllowedHeight] = useState();

  const [isListOpen, setListOpen] = useState(false);

  const [searchParam, setSearchParam] = useState();
  const [searchActive, setSearchActive] = useState(false);

  useEffect(() => {
    if (resetSelected) {
      setSelected(null);
    }
  }, [resetSelected]);

  useEffect(() => {
    setSelected(list[defaultSelectedId]);
  }, [defaultSelectedId]);

  useEffect(() => {
    if (defaultSelectedId && list) {
      list.forEach(element => {
        if (defaultSelectedId && element.id === defaultSelectedId) {
          setSelected(element);
        }
      });
    }
  }, [defaultSelectedId, list]);

  useEffect(() => {
    wrapperElem = document.getElementById('wrapper').getBoundingClientRect().height;
    setItemElem(document.getElementById('listItem')?.getBoundingClientRect().height);
  }, [myList, defaultSelectedId]);

  useEffect(() => {
    wrapperElem = document.getElementById('wrapper').getBoundingClientRect().height;
    setItemElem(document.getElementById('listItem')?.getBoundingClientRect().height);
  }, [isListOpen]);

  useEffect(async () => {
    if (searchParam) {
      const temp = [];
      list.forEach(e => {
        if (e.title.toLowerCase().includes(searchParam.toLowerCase())) {
          temp.push(e);
        }
      });

      setMyList(temp);
    } else {
      setMyList(list);
    }
  }, [changeInList, searchParam]);

  useEffect(async () => {
    if (itemElem) {
      if (myList?.length > visibleItemNo) {
        setAllowedHeight(visibleItemNo * itemElem);
      } else {
        setAllowedHeight(myList.length * itemElem + 2);
      }
    }
  }, [changeInList, myList, itemElem]);

  useEffect(async () => {
    myList?.forEach(element => {
      if (element.id !== selected?.id) {
        element.selected = false;
      } else {
        element.selected = true;
      }
    });
  }, [selected]);

  useEffect(() => {
    if (!isListOpen) {
      setSearchParam(null);
    }
  }, [isListOpen]);

  const [screenWidth, screenHeight] = useWindowSize();
  const [wrapperHeight, setWrapperHeight] = useState();

  useEffect(() => {
    const h = document.getElementById('wrapper')?.getBoundingClientRect().height;
    setWrapperHeight(h);
  }, [screenWidth]);

  const [ddContainerHeight, setddContainerHeight] = useState();
  const [ddContainerWidth, setddContainerWidth] = useState();

  useEffect(() => {
    if (!isListOpen) {
      setTimeout(() => {
        const h = document.getElementById('phonecontainer')?.getBoundingClientRect().height;
        setddContainerHeight(h);
        const w = document.getElementById('phonecontainer')?.getBoundingClientRect().width;
        setddContainerWidth(w);
      }, 100);
    }
  }, [isListOpen, screenWidth]);

  const mainWrapperElement = document.getElementById('wrapper');
  document.onmouseup = e => {
    if (!mainWrapperElement.contains(e.target)) {
      setListOpen(false);
      setSearchActive(false);
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={
        wrapperStyle || {
          height: ddContainerHeight,
          width: isListOpen ? ddContainerWidth : '100%'
        }
      }
      id="wrapper"
      onClick={() => {
        if (searchBar) {
          setSearchActive(true);
        }
        setListOpen(true);
      }}
    >
      {searchBar && isListOpen ? (
        <TextInput
          autofocus
          // mref={inputRef}
          placeholder={searchBarPlaceholder || 'Select Job Role'}
          value={searchParam && searchParam}
          onClick={() => {
            // setSearchActive(!searchActive);
            setSearchActive(true);
          }}
          // style={{ height: wrapperHeight }}
          inputstyle={{ height: '100%' }}
          onChange={e => {
            const value = e.target.value || '';
            setSearchParam(value);
          }}
        />
      ) : (
        <div
          id="phonecontainer"
          onClick={() => !searchActive && setListOpen(!isListOpen)}
          className={`${styles['dd-container']}
      ${!isListOpen && selected === null && styles.closeNoSelect}
      ${!isListOpen && selected !== null && styles.closeSelect}
      ${isListOpen && selected === null && styles.openNoSelect}
      ${isListOpen && selected !== null && styles.openSelect}
      `}
          style={containerStyle}
        >
          {(isListOpen || selected !== null) && (
            <div className={styles.placeholder} style={headerStyle}>
              <ShortText staticLimit={25} name={selected ? selected.title : placeholder} />
              {/* {selected ? selected.title : placeholder} */}
            </div>
          )}
          <label style={headerTitleStyle} className={styles.title}>
            {title}
          </label>
          <span alt="" className={isListOpen ? styles.arrowUp : styles.arrowDown} />
        </div>
      )}

      {isListOpen && (
        <div
          role="list"
          id="dropdownList"
          className={styles.list}
          style={{
            height: `${allowedHeight}px`,
            display: myList.length === 0 ? 'none' : 'inherit'
          }}
          // ref={boxRef}
          onClick={e => e.stopPropagation()}
        >
          {myList.map(item => (
            <div id="listItem">
              <DropdownItem
                itemStyle={itemStyle}
                href=""
                key={item.key}
                id={item.id}
                title={item.title}
                first={item.key === 1}
                last={item.key === lastIndex}
                selected={selected && item.key === selected.id}
                onSelect={() => {
                  setListOpen(false);
                  setSelectedOption(item);
                  setSelected(item);
                  myOnClick();
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Dropdown;
