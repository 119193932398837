import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../Styles/Tooltip.module.css';

export default function ToolTip({ position, text, children, color = '', maxSize = null, myStyle }) {
  const buildStyle = () => {
    let style = {};
    if (color !== '') {
      style = { color, ...style };
    }
    if (maxSize) {
      style = { maxWidth: maxSize, ...style };
    }
    return style;
  };

  return (
    <OverlayTrigger
      placement={position}
      delay={{ show: 250, hide: 400 }}
      style={{ backgroundColor: '#fff' }}
      overlay={
        <Tooltip
          className={styles['tooltip-c']}
          style={{
            backgroundColor: 'fff',
            opacity: '1',
            ...myStyle
          }}
        >
          <div style={buildStyle()}>{text}</div>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
}
