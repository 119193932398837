import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/TryAssessmentPage.module.css';
import Header from '../../../Common/Components/Header';
import AssessmentQuestion from '../Components/AssessmentQuestion';
import { tryAssessmentQuestions } from '../../../redux/assessmentsVol2/selectors';
import { tryAssessmentAction } from '../../../redux/assessmentsVol2/actions';
import { viewHeight } from '../../../redux/user/selectors';

const TryAssessmentPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const qList = useSelector(tryAssessmentQuestions);
  const height = useSelector(viewHeight);
  useEffect(async () => {
    await dispatch(tryAssessmentAction({ RootAssessmentId: id }));
  }, []);

  return (
    <div>
      <Header />
      <div className={styles.pageWrapper}>
        <div className={styles.container}>
        {/* <div className={styles.container} style={{ height: `calc(100vh - ${height}px` }}> */}
          <div className={styles.heading}>Assessment Preview</div>
          {qList?.map((question, index) => {
            return (
              <div className={styles.question}>
                <AssessmentQuestion
                  key={index}
                  index={index + 1}
                  time={question.time}
                  category={question.category}
                  title={question.title}
                  type={question.questionTypeId}
                  options={question.option}
                  question={question.questionDescription}
                  difficulty={question.difficulty}
                  imageOption={question.imageOption}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TryAssessmentPage;
