import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Oval, Preloader } from 'react-preloader-icon';
import coding from '../../../images/coding_assessment.svg';
import freetext from '../../../images/freetext_assessment.svg';
import multiselect from '../../../images/multiple_assessment.svg';
import clock from '../Images/clockDuration.svg';
import outSource from '../Images/outSource.svg';
import styles from '../Styles/FilteredQuestion.module.css';
import questionTick from '../../../images/question_tick.svg';
import questionPlus from '../../../images/question_plus.svg';
import {
  customAssessmentId,
  filterState,
  rootAssessmentQuestionList,
  showPopupRedux
} from '../../../redux/assessmentsVol2/selectors';
import {
  addQuestionBasedQuestionAction,
  getQuestionByFilterAction
} from '../../../redux/assessmentsVol2/actions';
import {
  setQuestionBeingAdded,
  setShow
} from '../../../redux/assessmentsVol2/slice';
import ShortText from '../../../Common/Components/ShortText';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as Premium } from '../../../images/premium_icon.svg';
import { ReactComponent as Purchased } from '../../../images/purchased_icon.svg';
import { questionAvailabilityControlAction } from '../../../redux/plan/actions';
import { userSelector } from '../../../redux/auth/selectors';

import HeadsUpModal from '../../../Auth/Modals/HeadsUpModal';
import ContactSalesSuccess from '../../../Auth/Modals/UnsubscribeSuccessModal';
import { currentPlanRedux, premiumQuestionCheckRedux } from '../../../redux/plan/selectors';
import { useWindowSize } from '../../../utils/helpers';
import { ReactComponent as WorldIcon } from '../../../images/world.svg';
import FreeTrialModal from '../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import { setToBePurchasedAddOn } from '../../../redux/plan/slice';
import PurchaseAddOnSuccessModal from '../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';

export default function FilteredQuestion({
  questionTypeId,
  language,
  skills,
  questionDuration,
  title,
  onClick,
  isIncluded,
  questionId,
  item,
  status = 'Premium',
  difficulty = 'Easy',
  setScrollPos,
  disableScrollTemp,
  isScrollAllowed
}) {
  const calculateMin = timeInit => {
    const timeInSeconds = parseInt(timeInit, 10);
    const time = {
      min: Math.floor(timeInSeconds / 60),
      sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
    };
    if (time.sec === 0) {
      return `${time.min} min`;
    }
    if (time.min === 0) {
      return `${time.sec} sec`;
    }
    return `${time.min} min ${time.sec} sec`;
  };
  const dispatch = useDispatch();
  const assID = useSelector(customAssessmentId);
  const showPopup = useSelector(showPopupRedux);
  const rootQuestions = useSelector(rootAssessmentQuestionList);
  const user = useSelector(userSelector);

  const [continuePublish, setContinuePublish] = useState(false);
  const [addQuestionHeadsUp, setAddQuestionHeadsUp] = useState(false);
  const [previewQuestionHeadsUp, setPreviewQuestionHeadsUp] = useState(false);
  const [freeTrialModalShow, setFreeTrialModalShow] = useState(false);
  const [freeTrialPreviewModalShow, setFreeTrialPreviewModalShow] = useState(false);

  const myPlan = useSelector(currentPlanRedux);
  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [purchaseAddOnsuccess, setPurchaseAddOnsuccess] = useState(false);
  const [purchaseAddOnPreviewsuccess, setPurchaseAddOnPreviewSuccess] = useState(false);

  const [screenWidth, screenHeight] = useWindowSize();
  const [staticLimit, setStaticLimit] = useState();
  const [selectedLangs, setSelectedLangs] = useState();
  const isPremiumObject = useSelector(premiumQuestionCheckRedux);
  const filter = useSelector(filterState);

  useEffect(() => {
    const charWidth = document.getElementById('char').getBoundingClientRect().width;

    const allowedWidth =
      document.getElementById('questionBasedMiddleColumn').getBoundingClientRect().width - 120;

    setStaticLimit(Math.floor(allowedWidth / charWidth));
  }, [screenWidth]);

  const [plusButtonLoading, setPlusButtonLoading] = useState(false);
  useEffect(() => {
    if (isScrollAllowed) {
      setPlusButtonLoading(false);
    }
  }, [isScrollAllowed]);

  useEffect(() => {
    const a = rootQuestions?.filter(q => q?.questionId === item?.questionId)[0]?.label;
    setSelectedLangs(a);
  }, [rootQuestions, item]);

  async function addQuestion() {
    const data = {
      rootAssessmentId: assID,
      questionId: item.questionId,
      category: item.category,
      languageList: item.codingLanguages
    };
    // CHANGE THIS: setQuestionBeingAdded should take "data" as the input (input of the add question action)
    await dispatch(setQuestionBeingAdded(data));
    if (item.questionTypeId !== 2 || (item.codingLanguages && item.codingLanguages[0] === 'SQL')) {
      dispatch(addQuestionBasedQuestionAction(data));
      dispatch(setShow({ type: 1, appear: true }));
    } else if (item.questionTypeId === 2) {
      const codingData = {
        type: 'Coding',
        rootAssessmentId: assID,
        questionId: item.questionId,
        category: item.category,
        languageList: item.codingLanguages
      };
      dispatch(setQuestionBeingAdded(codingData));
      disableScrollTemp(true);
      setPlusButtonLoading(false);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div
        id="char"
        style={{
          fontSize: '16px',
          padding: 0,
          visibility: 'hidden',
          position: 'absolute'
        }}
      >
        0
      </div>

      {/* START OF PURCHASE PQ in Question Library (add Q) */}

      {/* Generic to every contact sales action on this page */}
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />

      <HeadsUpModal
        addOnCode={60}
        show={addQuestionHeadsUp}
        onHide={() => setAddQuestionHeadsUp(false)}
        title="This one is a premium question"
        description={
          myPlan?.isCustom
            ? ` Your plan does not include this question.
          Please contact our sales team to upgrade your plan.`
            : `Get this premium question for only $${isPremiumObject?.price} to take your assessment to the next level! `
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchaseAddOnsuccess}
        tryAgain={() => console.log('heyyo purchase to add Q')}
      />

      {/* When the plan is not custom */}
      <FreeTrialModal
        show={freeTrialModalShow}
        onHide={() => setFreeTrialModalShow(false)}
        addOnCode={60}
      />

      {/* Purchase AddOn Success */}
      <PurchaseAddOnSuccessModal
        show={purchaseAddOnsuccess}
        onHide={async () => {
          setPurchaseAddOnsuccess(false);
          addQuestion();
          dispatch(getQuestionByFilterAction(filter)); // to update the diamond icons
        }}
        addOnCode={60}
      />
      {/* END OF PURCHASE PQ in Question Library (add Q) */}

      {/* START OF PURCHASE PQ in Question Library (preview Q) */}
      <HeadsUpModal
        addOnCode={61}
        show={previewQuestionHeadsUp}
        onHide={() => setPreviewQuestionHeadsUp(false)}
        title="This one is a premium question"
        description={
          myPlan?.isCustom
            ? ` Your plan does not include this question.
          Please contact our sales team to upgrade your plan.`
            : `Get this premium question for only $${isPremiumObject?.price} to take your assessment to the next level! `
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchaseAddOnPreviewSuccess}
        tryAgain={() => console.log('heyyo purchase to preview')}
      />

      <FreeTrialModal
        show={freeTrialPreviewModalShow}
        onHide={() => setFreeTrialPreviewModalShow(false)}
        addOnCode={61}
      />

      <PurchaseAddOnSuccessModal
        show={purchaseAddOnPreviewsuccess}
        onHide={() => {
          setPurchaseAddOnPreviewSuccess(false);
          window.open(`/question/${item?.questionId}`, '_blank').focus(); // opens up a new tab to preview Q
          dispatch(getQuestionByFilterAction(filter)); // to update the diamond icons
        }}
        addOnCode={61}
      />
      {/* END OF PURCHASE PQ in Question Library (preview Q) */}

      <div className={styles.titleWrapper}>
        {/* <div className={styles.questionNo}>{index}</div> */}
        <div className={styles.title}>
          <ShortText name={title} staticLimit={staticLimit} />
        </div>
        {language !== 'EN' && (
          <div className={styles.languageLabel}>
            <WorldIcon className={styles.worldIcon} />
            <div className={styles.questionLanguage}>{`${language}`}</div>
          </div>
        )}
        <div className={styles.questionDifficulty}>{difficulty}</div>
      </div>
      <div className={styles.rowWrapper}>
        <span style={{ marginRight: '4px' }}>
          <img
            alt=""
            width={36}
            height={24}
            src={questionTypeId === 2 ? coding : questionTypeId === 3 ? freetext : multiselect}
          />
        </span>
        {!item?.isIncluded && item?.questionTypeId === 2 && skills && skills[0] === 'SQL' && (
          <div className={styles.skillWrapper}>SQL</div>
        )}

        {!item?.isIncluded && item?.questionTypeId === 2 && skills && skills[0] !== 'SQL' && (
          <div className={styles.skillWrapper}>General Coding</div>
        )}

        {item.isIncluded &&
          item?.questionTypeId === 2 &&
          selectedLangs
            ?.slice(0, 3)
            ?.map(lang => <div className={styles.skillWrapper}>{lang}</div>)}
        {item.isIncluded && selectedLangs?.length >= 4 && item?.questionTypeId === 2 && (
          <Tooltip
            direction="right"
            text={
              <ul className={styles.skillList}>
                {selectedLangs?.slice(3, selectedLangs?.length).map(x => (
                  <li className={styles.skillElement}>
                    <span>{x}</span>
                  </li>
                ))}
              </ul>
            }
          >
            <div className={`${styles.skillWrapper} ${styles.more}`}>{`+${
              selectedLangs?.length - 3
            }`}</div>
          </Tooltip>
        )}
        <div className={styles.durationWrapper}>
          <span className={styles.imgWrapper}>
            <img alt="" src={clock} />
          </span>
          <div className={styles.duration}>{calculateMin(questionDuration)}</div>
        </div>
        {status === 30 ? (
          <Tooltip direction="top" text="This one is a premium question. Curious?">
            <Premium className={styles.statusIcon} />
          </Tooltip>
        ) : status === 10 ? (
          <Tooltip direction="top" text="This one is a purchased premium question.">
            <Purchased className={styles.purchasedIcon} />
          </Tooltip>
        ) : null}
        <div className={styles.iconWrapper}>
          <span
            className={styles.imgWrapper1}
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={async () => {
              const data2 = {
                CompanyId: user?.companyId,
                QuestionId: item?.questionId
              };
              const res = await dispatch(questionAvailabilityControlAction(data2));
              if (res?.payload?.isAvailable) {
                window.open(`/question/${item?.questionId}`, '_blank').focus();
              } else if (myPlan?.name === 'Free Trial') {
                setFreeTrialPreviewModalShow(true);
              } else {
                const data = {
                  questionId: item?.questionId,
                  bundleItemCode: 60
                };
                await dispatch(setToBePurchasedAddOn(data));
                await setPreviewQuestionHeadsUp(true);
              }
            }}
          >
            <img alt="" src={outSource} />
          </span>
          <span className={isIncluded ? styles.imgWrapper : styles.imgWrapper2}>
            {!plusButtonLoading ? (
              <img
                style={{ cursor: 'pointer' }}
                alt=""
                onClick={async () => {
                  // add question here
                  if (isScrollAllowed) {
                    if (!isIncluded) {
                      setPlusButtonLoading(true);
                      disableScrollTemp(false);
                      const data2 = {
                        CompanyId: user?.companyId,
                        QuestionId: item?.questionId
                      };
                      const res = await dispatch(questionAvailabilityControlAction(data2));
                      if (res?.payload?.isAvailable) {
                        addQuestion();
                      } else if (myPlan?.name === 'Free Trial') {
                        setFreeTrialModalShow(true);
                        disableScrollTemp(true);
                      } else {
                        disableScrollTemp(true);
                        const data = {
                          questionId: item?.questionId,
                          bundleItemCode: 60
                        };
                        await dispatch(setToBePurchasedAddOn(data));
                        setAddQuestionHeadsUp(true);
                      }
                    }
                    setScrollPos();
                  }
                }}
                src={isIncluded ? questionTick : questionPlus}
              />
            ) : (
              <Preloader
                use={Oval}
                size={24}
                strokeWidth={17}
                strokeColor="#7458D3"
                duration={1200}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  marginBottom: '1px'
                }}
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
