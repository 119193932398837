import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import CustomButton from '../../Common/Components/CustomButton';
import CloseButton from '../../Common/Components/CloseButton';
import styles from '../Styles/EditBillingModal.module.css';
import TextInput from '../../Common/Components/TextInput';
import {
  getBillingInfoAction,
  editBillingInfoAction,
  getCountriesAction
} from '../../redux/plan/actions';
import Dropdown from '../../Common/Components/CustomDropdown/CustomDropdown';

import { billingInfoRedux, countriesRedux } from '../../redux/plan/selectors';
import { userSelector } from '../../redux/auth/selectors';
import {
  setSettingsPageErrorMessage,
  setSettingsPageErrorShow,
  setSettingsPageSuccessMessage,
  setSettingsPageSuccessShow
} from '../../redux/auth/slice';
import MobileModal from '../../Common/Components/MobileModal/MobileModal';
import Modal from '../../Common/CustomModal/Modal';

export default function EditBillingModal({ show, onHide, ...props }) {
  const dispatch = useDispatch();
  const billingInfo = useSelector(billingInfoRedux);
  const user = useSelector(userSelector);
  const countryList = useSelector(countriesRedux);

  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [email, setEmail] = useState();
  const [myAddress, setAddress] = useState();
  const [myCity, setCity] = useState();
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [vat, setVat] = useState();
  const [dropdownList, setDropdownList] = useState();
  const [defaultSelected, setDefaultSelected] = useState();
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show && billingInfo && countryList) {
      const nameSurname = billingInfo?.nameSurname?.split(' ');
      setName(nameSurname && nameSurname[0]);
      setEmail(billingInfo?.mail);
      setSurname(nameSurname && nameSurname[1]);
      setAddress(billingInfo?.address);
      setCity(billingInfo?.city);
      setPostalCode(billingInfo?.zipCode);
      const temp = countryList?.indexOf(
        countryList?.filter(c => c.id === billingInfo?.countryId)[0]
      );
      setCountry({
        id: temp,
        key: temp,
        title: billingInfo?.country
      });
      setVat(billingInfo?.taxNo);
    }
    return () => {
      setName();
      setSurname();
      setEmail();
      setAddress();
      setCity();
      setPostalCode();
      setCountry();
      setVat();
    };
  }, [billingInfo, show, countryList]);

  useEffect(() => {
    if (show) {
      const rawData = {
        CompanyId: user.companyId
      };
      dispatch(getBillingInfoAction(rawData));
      dispatch(getCountriesAction());
    }
  }, [show]);

  useEffect(() => {
    if (countryList) {
      const temp = [];
      countryList.forEach((item, index) => {
        temp.push({
          id: index,
          key: index,
          title: item.name
        });
      });
      setDropdownList(temp);
      const temp2 = countryList?.indexOf(
        countryList?.filter(c => c.id === billingInfo?.countryId)[0]
      );
      setDefaultSelected(temp2);
    }
  }, [countryList]);

  async function handleEditBilling() {
    setLoading(true);
    const data = {
      id: billingInfo?.id,
      nameSurname: `${name} ${surname}`,
      mail: email,
      address: myAddress,
      city: myCity,
      countryId: countryList[country.id].id,
      zipCode: postalCode,
      taxNo: vat
    };
    const a = await dispatch(editBillingInfoAction(data));
    if (a?.meta?.requestStatus === 'fulfilled') {
      await dispatch(setSettingsPageSuccessMessage('Changes saved successfully'));
      await setLoading(false);
      await dispatch(
        getBillingInfoAction({
          CompanyId: user.companyId
        })
      );
      await onHide();
      await dispatch(setSettingsPageSuccessShow(true));
    } else {
      await dispatch(setSettingsPageErrorMessage(a?.payload?.message));
      await setLoading(false);
      await dispatch(setSettingsPageErrorShow(true));
    }
  }

  const WebBilling = (
    <Modal
      centerHorizontally
      centerVertically
      dialogClassName={styles.container}
      contentStyle={{width: '720px', minWidth: '576px', padding: '20px 32px 24px', borderRadius: '24px'}}
      show={show}
      onClose={onHide}
      outsideClickClose
    >
      <div className={styles.wrapper}>
        <div className={styles.headerContainer}>
          <span className={styles.header}>Edit Billing Information</span>
          <CloseButton width={24} height={24} onClick={onHide} />
        </div>

        <div className={styles.partWrapper} style={{ paddingTop: '20px' }}>
          <span className={styles.partHeader}>Billing Contact</span>
          <div className={styles.doubleInputContainer}>
            <TextInput
              className={styles.inputWrapper}
              placeholder="Name*"
              value={name}
              onChange={e => setName(e.target.value)}
              style={{ width: '50%', marginRight: '8px' }}
            />
            <TextInput
              placeholder="Surname*"
              value={surname}
              onChange={e => setSurname(e.target.value)}
              style={{ width: '50%', marginLeft: '8px' }}
            />
          </div>
          <TextInput placeholder="Mail*" value={email} onChange={e => setEmail(e.target.value)} />
        </div>

        <div className={styles.partWrapper}>
          <span className={styles.partHeader}>Billing Address</span>
          <TextInput
            placeholder="Address*"
            value={myAddress}
            onChange={e => setAddress(e.target.value)}
            style={{ marginBottom: '1.6rem' }}
          />
          <div className={styles.doubleInputContainer}>
            <TextInput
              className={styles.inputWrapper}
              placeholder="City*"
              value={myCity}
              onChange={e => setCity(e.target.value)}
              style={{ width: '50%', marginRight: '8px' }}
            />
            <TextInput
              placeholder="Postal Code*"
              value={postalCode}
              onChange={e => setPostalCode(e.target.value)}
              style={{ width: '50%', marginLeft: '8px' }}
            />
          </div>
          {dropdownList && countryList && defaultSelected && (
            <Dropdown
              wrapperStyle={{ height: '50px' }}
              listStyle={{ height: '150px' }}
              itemStyle={{ height: '50px' }}
              headerStyle={{ fontSize: '1.6rem' }}
              defaultSelectedId={defaultSelected}
              setSelectedOption={setCountry}
              placeholder="Select a country"
              list={dropdownList}
              title="Country*"
              visibleItemNo={3}
              headerTitleStyle={{ paddingLeft: 0, fontSize: '1.6rem' }}
              resetDropdown
              searchBar
              searchBarPlaceholder="Select a country"
            />
          )}
        </div>

        <div className={styles.partWrapper}>
          <span className={styles.partHeader}>VAT Number</span>
          <TextInput
            placeholder="VAT Number*"
            value={vat}
            onChange={e => setVat(e.target.value)}
            style={{ marginBottom: '2.8rem' }}
          />
        </div>

        <div className={styles.buttonContainer}>
          <CustomButton
            type={2}
            textField="Cancel"
            style={{ marginRight: '4px' }}
            buttonOnClick={onHide}
          />

          <CustomButton
            loading={loading}
            type={1}
            textField="Save"
            buttonOnClick={() => handleEditBilling()}
            isDisabled={
              !name ||
              !surname ||
              !email ||
              !myAddress ||
              !myCity ||
              !postalCode ||
              !country ||
              !vat ||
              (`${name} ${surname}` === billingInfo?.nameSurname &&
                email === billingInfo?.mail &&
                myAddress === billingInfo?.address &&
                myCity === billingInfo?.city &&
                postalCode === billingInfo?.zipCode &&
                vat === billingInfo?.taxNo &&
                country.title === billingInfo?.country)
            }
          />
        </div>
      </div>
    </Modal>
  );

  const MobileBilling = (
    <MobileModal
      backdropStyle={{ overflow: 'hidden' }}
      show={show}
      onClose={onHide}
      id="mobile-edit-billing-modal"
      contentStyle={{ padding: '18px', maxHeight: '90dvh' }}
      outsideClickClose
    >
      <div className={styles.wrapper}>
        <div className={styles.headerContainer}>
          <span className={styles.header}>Edit Billing Information</span>
          <CloseButton width={24} height={24} onClick={onHide} />
        </div>

        <div className={styles.partWrapper} style={{ paddingTop: '18px' }}>
          <span className={styles.partHeader}>Billing Contact</span>
          <TextInput
            className={styles.inputWrapper}
            placeholder="Name*"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <TextInput
            placeholder="Surname*"
            value={surname}
            onChange={e => setSurname(e.target.value)}
          />
          <TextInput placeholder="Mail*" value={email} onChange={e => setEmail(e.target.value)} />
        </div>

        <div className={styles.partWrapper}>
          <span className={styles.partHeader}>Billing Address</span>
          <TextInput
            placeholder="Address*"
            value={myAddress}
            onChange={e => setAddress(e.target.value)}
          />
          <TextInput
            className={styles.inputWrapper}
            placeholder="City*"
            value={myCity}
            onChange={e => setCity(e.target.value)}
          />
          <TextInput
            placeholder="Postal Code*"
            value={postalCode}
            onChange={e => setPostalCode(e.target.value)}
          />
          {dropdownList && countryList && defaultSelected && (
            <Dropdown
              wrapperStyle={{ height: '50px' }}
              listStyle={{ height: '150px' }}
              itemStyle={{ height: '50px' }}
              headerStyle={{ fontSize: '1.6rem' }}
              defaultSelectedId={defaultSelected}
              setSelectedOption={setCountry}
              placeholder="Select a country"
              list={dropdownList}
              title="Country*"
              visibleItemNo={3}
              headerTitleStyle={{ paddingLeft: 0, fontSize: '1.6rem' }}
              resetDropdown
              searchBar
              searchBarPlaceholder="Select a country"
            />
          )}
        </div>

        <div className={styles.partWrapper}>
          <span className={styles.partHeader}>VAT Number</span>
          <TextInput
            placeholder="VAT Number*"
            value={vat}
            onChange={e => setVat(e.target.value)}
            style={{ marginBottom: '2.8rem' }}
          />
        </div>

        <div className={styles.buttonContainer}>
          <CustomButton
            type={8}
            textField="Cancel"
            style={{ marginRight: '4px' }}
            buttonOnClick={onHide}
            size="mobile"
          />

          <CustomButton
            size="mobile"
            loading={loading}
            type={1}
            textField="Save"
            buttonOnClick={() => handleEditBilling()}
            isDisabled={
              !name ||
              !surname ||
              !email ||
              !myAddress ||
              !myCity ||
              !postalCode ||
              !country ||
              !vat ||
              (`${name} ${surname}` === billingInfo?.nameSurname &&
                email === billingInfo?.mail &&
                myAddress === billingInfo?.address &&
                myCity === billingInfo?.city &&
                postalCode === billingInfo?.zipCode &&
                vat === billingInfo?.taxNo &&
                country.title === billingInfo?.country)
            }
          />
        </div>
      </div>
    </MobileModal>
  );

  return isMobile ? MobileBilling : WebBilling;
}
