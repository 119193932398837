/* eslint-disable no-return-assign */
import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../Styles/PlanUnsubscribedSuccess.module.css';
import CloseButton from '../../Common/Components/CloseButton';

export default function UnsubscribeSuccessModal({ show, onHide, ...props }) {
  return (
    <Modal
      {...props}
      centered
      onHide={onHide}
      show={show}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
      dialogClassName={styles.container}
    >
      <>
        <div className={styles.successContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.successTitle}>We&apos;re sad to see you go...</div>
            <CloseButton width={24} height={24} onClick={onHide} />
          </div>
          <div className={styles.descText}>
            Thousands of diamonds are waiting for you to discover them. You might be missing the
            right one for your company.
          </div>
          <div className={styles.successFootNote}>
            *You can access Coensio until the end of the billing period.
          </div>
        </div>
      </>
    </Modal>
  );
}
