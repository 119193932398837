import React from 'react';
import goBack2 from '../Images/goBack2.svg';
import styles from '../Styles/GoBackButton.module.css'

function GoBackButton({ onClick, size, ...props }) {
  return (
    <div className={styles.GoBackButtonContainer} style={props.style}>
      <img
        src={goBack2}
        alt="goBack"
        onClick={onClick}
        centered
        className={styles.GoBackButtonImage}
      />
    </div>
  );
} export default GoBackButton;
