import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { encryptedQuestionIdRedux, isSuccessRedux } from '../../../redux/addQuestion/selectors';
import styles from '../Styles/InformationPopup.module.css';

export default function InformationPopup({ show, onHide }) {

  const reduxEncryptedQuestionId = useSelector(encryptedQuestionIdRedux);
  const reduxIsSuccess = useSelector(isSuccessRedux);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <div className={styles.ModalBody}>
        {reduxIsSuccess === true && (
          <>
            <div>Success</div>
            {reduxEncryptedQuestionId && reduxEncryptedQuestionId !== '' && (
              <button className={styles.Button} type='button' onClick={() => window.open(`/question/${reduxEncryptedQuestionId}`, '_blank').focus()}>Go to Question Detail</button>
            )}
          </>
        )}
        {!reduxIsSuccess && 'Failed'}
        {reduxIsSuccess && reduxIsSuccess !== true && 'Loading'}
      </div>
    </Modal>
  );
}
