import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { assessmentView } from '../../../redux/addAssessment/selectors';
import { setTab } from '../../../redux/addAssessment/slice';
import GoBackButton from '../../../Common/Components/GoBackButton';
import styles from '../Styles/ViewSwitch.module.css';
import { setAssessmentActiveTab } from '../../../redux/assessmentsVol2/slice';

const LeftText = React.memo(({ text, unselectedViewName, onClick, style, rightStyle }) => {
  return (
    <div
      className={`${unselectedViewName && styles.animate} ${
        text === unselectedViewName ? styles.rightAnimate : ''
      }`}
      onClick={onClick}
      style={text === unselectedViewName ? style : {}}
    >
      <span
        style={text === unselectedViewName ? { fontSize: '16px' } : {}}
        className={styles.selectedText}
      >
        Recruiter View
      </span>
      <span
        style={text === unselectedViewName ? {} : rightStyle.based}
        className={`${styles.selectedBaseText} ${
          text === unselectedViewName ? styles.displayNone : ''
        }`}
      >
        / Skill-based
      </span>
    </div>
  );
});
const RightText = React.memo(({ text, unselectedViewName, onClick, style, rightStyle }) => (
  <div
    className={`${unselectedViewName && styles.animateHm} ${
      text === unselectedViewName ? styles.rightAnimateHm : ''
    }`}
    onClick={onClick}
    style={text === unselectedViewName ? style : {}}
  >
    <span
      className={styles.selectedText}
      style={text === unselectedViewName ? { fontSize: '16px' } : {}}
    >
      Hiring Manager View
    </span>
    <span
      style={text === unselectedViewName ? {} : rightStyle.based}
      className={`${styles.selectedBaseText} ${
        text === unselectedViewName ? styles.displayNone : rightStyle.based
      }`}
    >
      / Question-based
    </span>
  </div>
));

export default function ViewSwitch() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [viewName, setViewName] = useState();
  const [viewBase, setViewBase] = useState();
  const [id, setId] = useState();
  const [style, setStyle] = useState({ width: '70%' });
  const [textStyle, setTextStyle] = useState({ display: 'inline-block' });
  const [rightTextStyle, setRightStyle] = useState({ based: { opacity: 1 } });
  const activeTab = useSelector(assessmentView);
  const [unselectedViewName, setUnselectedViewName] = useState('');

  function onTabSwitch(tab) {
    if (tab) {
      if (tab === 'Recruiter View') {
        setViewName('Recruiter View');
        setViewBase('/ Skill-based');
        setUnselectedViewName('Hiring Manager View');
        dispatch(setTab('Recruiter'));
      } else {
        setViewName('Hiring Manager View');
        setViewBase('/ Question-based');
        setUnselectedViewName('Recruiter View');
        dispatch(setTab('Hiring Manager'));
      }
    }
  }

  useEffect(async () => {
    if (activeTab) {
      onTabSwitch(`${activeTab} View`);
    }
  }, [activeTab]);

  const onClick = () => {
    setTextStyle({ opacity: 0 });
    setRightStyle({ based: { opacity: 0 } });
    if (style.width === '100%') {
      setStyle({ width: '70%' });
    } else {
      setStyle({ width: '100%' });
    }
    setTimeout(() => {
      setStyle(style => ({ width: '70%' }));
    }, 450);
    setTimeout(() => {
      setTextStyle({ opacity: 1 });
    }, [750]);
    setTimeout(() => {
      setRightStyle({ based: { opacity: 1 } });
    }, [650]);
    onTabSwitch(unselectedViewName);
  };

  return (
    <div className={styles.wrapper}>
      <GoBackButton
        style={{ display: 'flex', marginBottom: 'auto', marginTop: '2px' }}
        onClick={async () => {
          await dispatch(setAssessmentActiveTab(1))
          if (history.location.key) { 
            history.goBack();
          } else {
            history.replace('/add-assessment');
          }
        }}
      />
      <div className={styles.tabWrapper}>
        <div className={styles.whiteTab}>
          <div className={styles.activeTab} style={style} />
          <LeftText
            text="Recruiter View"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={onClick}
            style={textStyle}
            rightStyle={rightTextStyle}
            unselectedViewName={unselectedViewName}
          />
          <RightText
            // eslint-disable-next-line react/jsx-no-bind
            onClick={onClick}
            rightStyle={rightTextStyle}
            style={textStyle}
            unselectedViewName={unselectedViewName}
            text="Hiring Manager View"
          />
        </div>
      </div>
    </div>
  );
}
