import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  arriveTypeSelector,
  endDateSelector,
  endScoreSelector,
  funnelIdSelector,
  orderTypeSelector,
  pageNumberSelector,
  searchParameterSelector,
  startDateSelector,
  startScoreSelector,
  funnelInfoSelector,
  funnelOpenSelector,
  oldestInitialDateSelector,
  companyAssessmentNameSelector,
  rootAssessmentNameSelector,
  industryAvgSelector,
  assessmentObjStatusSelector,
  industryListSelector,
  schoolListSelector,
  industryIncludeTypeSelector,
  screeningExperiencesSelector,
  screeningWorkTypesSelector,
  screeningNationalitiesSelector,
  screeningSalaryCurrencySelector,
  screeningSalaryMinValueSelector,
  screeningSalaryMaxValueSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  setArriveType,
  setEndDate,
  setEndScore,
  setFunnelId,
  setOrderType,
  setSearchParam,
  setStartDate,
  setStartScore,
  setPageNumber,
  setFunnelOpen,
  setActiveFilterCount,
  setIndustryList,
  setIndustryIncludeType,
  setScreeningExperiences,
  setScreeningWorkTypes,
  setScreeningNationalities,
  setScreeningSalaryCurrency,
  setScreeningSalaryMinValue,
  setScreeningSalaryMaxValue,
  setSchoolList
} from '../../../redux/assessmentDetailVol2/slice';
import {
  currencyEnumSelector,
  getParam,
  urlDynamicQueryUpdateLite,
  useWindowSize
} from '../../../utils/helpers';
import AssessmentDetailHeader from '../AssessmentDetailHeader/AssessmentDetailHeader';
import FilterRow from '../FilterRow/FilterRow';
import Funnel from '../Funnel/Funnel';
import styles from './AssessmentDetailContent.module.css';
import ProgressBarRow from '../AssessmentDetailHeader/ProgressBarRow';
import {
  getCandidatesByFilterAction,
  getCandidatesByFilterAfterMoveToFunnelAction,
  getRequestCandidatesAction
} from '../../../redux/assessmentDetailVol2/actions';
import CandidateWrapper from '../CandidateWrapper/CandidateWrapper';
import FilterModal from '../FilterModal/FilterModal';
import { userSelector } from '../../../redux/auth/selectors';

import mixpanel from '../../../utils/mixpanel';
import { useIp } from '../../../hooks/useIp';
import filterDataModel from '../utils/filterDataModel';

export default function AssessmentDetailContent() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loc, currency] = useIp();

  /* Filter Modal Variables */
  const [filterOpen, setFilterOpen] = useState(false);

  /* Selectors for filter parameters */
  const searchParameterRedux = useSelector(searchParameterSelector);
  const orderTypeRedux = useSelector(orderTypeSelector);
  const funnelIdRedux = useSelector(funnelIdSelector);
  const startDateRedux = useSelector(startDateSelector);
  const endDateRedux = useSelector(endDateSelector);
  const startScoreRedux = useSelector(startScoreSelector);
  const endScoreRedux = useSelector(endScoreSelector);
  const pageNumberRedux = useSelector(pageNumberSelector);
  const arriveTypeRedux = useSelector(arriveTypeSelector);
  const oldestDateInt = useSelector(oldestInitialDateSelector);

  const industryListRedux = useSelector(industryListSelector);
  const industryIncludeTypeRedux = useSelector(industryIncludeTypeSelector);
  const schoolListRedux = useSelector(schoolListSelector);
  const screeningExperiencesRedux = useSelector(screeningExperiencesSelector);
  const screeningWorkTypesRedux = useSelector(screeningWorkTypesSelector);
  const screeningNationalitiesRedux = useSelector(screeningNationalitiesSelector);
  const screeningSalaryCurrencyRedux = useSelector(screeningSalaryCurrencySelector);
  const screeningSalaryMinValueRedux = useSelector(screeningSalaryMinValueSelector);
  const screeningSalaryMaxValueRedux = useSelector(screeningSalaryMaxValueSelector);

  const funnels = useSelector(funnelInfoSelector);
  const { filterData, filterDataAfterMove } = filterDataModel();

  const [activateFilter, setActivateFilter] = useState(false);
  const [activateFilterForMove, setActivateFilterForMove] = useState(false);

  const [filterCountReady, setFilterCountReady] = useState(false);

  const filterCountCheck = () => {
    /* Filter count check */
    let filterCount = 0;

    // Date related checks
    if (
      (startDateRedux !== null && startDateRedux !== oldestDateInt) ||
      (endDateRedux !== null && endDateRedux !== 0)
    ) {
      filterCount += 1;
    }

    // Score related checks
    if (startScoreRedux !== 0 || endScoreRedux !== 100) {
      filterCount += 1;
    }

    // Industry related checks
    // TODO: Test below
    if (industryListRedux) {
      filterCount += 1;
    }
    if (schoolListRedux) {
      filterCount += 1;
    }
    // Screening related checks
    // TODO: Test below
    if (screeningExperiencesRedux) {
      filterCount += 1;
    }
    if (screeningWorkTypesRedux) {
      filterCount += 1;
    }
    if (screeningNationalitiesRedux) {
      filterCount += 1;
    }
    if (screeningSalaryMinValueRedux || screeningSalaryMaxValueRedux) {
      filterCount += 1;
    }

    // Arrive type related checks
    if (arriveTypeRedux?.length !== 0) {
      filterCount += 1;
    }
    dispatch(setActiveFilterCount(filterCount));
  };

  useEffect(() => {
    if (filterCountReady) {
      filterCountCheck();
      setFilterCountReady(false);
    }
  }, [filterCountReady]);

  const fetchCandidates = async () => {
    setActivateFilter(false);

    // filter api call
    await dispatch(getCandidatesByFilterAction(filterData)).then(resp => {
      setFilterCountReady(true);
    });
  };

  const fetchRequestCandidates = async () => {
    setActivateFilter(false);

    // filter api call
    await dispatch(getRequestCandidatesAction(filterData)).then(resp => {
      setFilterCountReady(true);
    });
  };

  const fetchCandidatesAfterMoveFunnel = async () => {
    // If page is loaded right after candidateDetail (i.e. via go back button), we keep pageNumber
    // and send request with pageNumber as 0 and items as (pageNumber+1)*50
    const candidateFlag = getParam(location.search, 'candidateFlag');

    // Reset flag if exists
    if (candidateFlag === 'true') {
      urlDynamicQueryUpdateLite('candidateFlag', 'false', false);
      // Send both requests first, then wait for response
      const requestReq = dispatch(getRequestCandidatesAction(filterDataAfterMove));
      const filterReq = dispatch(getCandidatesByFilterAction(filterDataAfterMove));
      await requestReq;
      await filterReq;
    } else {
      const requestReq = dispatch(getRequestCandidatesAction(filterDataAfterMove));
      const filterReq = dispatch(getCandidatesByFilterAfterMoveToFunnelAction(filterDataAfterMove));
      await requestReq;
      await filterReq;
    }

    setActivateFilterForMove(false);
    window.dispatchEvent(new Event('disableSelectAll'));
    setFilterCountReady(true);
  };

  function updateFiltersOnLocationChange(moveFlag = false) {
    /* 
      Triggered whenever there is a change in url
      Gets filter values from url and sets corresponding redux states
      To avoid duplicate api calls on redux state changes, activateFilter is set at the end of method
    */
    const { location } = window;
    const searchParamUrl = getParam(location.search, 'searchParam');
    const orderTypeUrl = getParam(location.search, 'orderType');
    const funnelIdUrl = getParam(location.search, 'funnelId');
    const startDateUrl = getParam(location.search, 'startDate');
    const endDateUrl = getParam(location.search, 'endDate');
    const startScoreUrl = getParam(location.search, 'startScore');
    const endScoreUrl = getParam(location.search, 'endScore');
    const pageNumberUrl = getParam(location.search, 'pageNumber');
    const arriveTypeUrl = getParam(location.search, 'arriveType');

    const industryListUrl = getParam(location.search, 'industryList');
    const industryIncludeTypeUrl = getParam(location.search, 'industryIncludeType');
    const schoolListUrl = getParam(location.search, 'schoolList');
    const experiencesUrl = getParam(location.search, 'experiences');
    const workTypesUrl = getParam(location.search, 'workTypes');
    const nationalitiesUrl = getParam(location.search, 'workPermit');
    const currencyUrl = getParam(location.search, 'currency');
    const minSalaryUrl = getParam(location.search, 'minSalary');
    const maxSalaryUrl = getParam(location.search, 'maxSalary');

    // TODO: For some parameters add a list of allowed parameters
    // i.e., allowedOrderTypes = ["1", "4"]
    // if parameter is not allowed -> set to default

    if (searchParamUrl === null) {
      urlDynamicQueryUpdateLite('searchParam', '', false);
      dispatch(setSearchParam(''));
    } else {
      dispatch(setSearchParam(decodeURIComponent(searchParamUrl)));
    }

    if (orderTypeUrl === null) {
      urlDynamicQueryUpdateLite('orderType', '3', false);
      dispatch(setOrderType(3));
    } else {
      dispatch(setOrderType(parseInt(orderTypeUrl, 10) || 3));
    }

    if (funnelIdUrl === null) {
      urlDynamicQueryUpdateLite('funnelId', '', false);
      dispatch(setFunnelId(''));
    } else {
      dispatch(setFunnelId(funnelIdUrl));
    }

    if (startDateUrl === null) {
      urlDynamicQueryUpdateLite('startDate', '', false);
      dispatch(setStartDate(null));
    } else {
      dispatch(
        setStartDate(parseInt(startDateUrl, 10) || (parseInt(startDateUrl, 10) === 0 ? 0 : null))
      );
    }
    if (endDateUrl === null) {
      urlDynamicQueryUpdateLite('endDate', '0', false);
      dispatch(setEndDate(0));
    } else {
      dispatch(setEndDate(parseInt(endDateUrl, 10) || 0));
    }
    if (startScoreUrl === null) {
      urlDynamicQueryUpdateLite('startScore', '0', false);
      dispatch(setStartScore(0));
    } else {
      dispatch(setStartScore(parseInt(startScoreUrl, 10) || 0));
    }
    if (endScoreUrl === null) {
      urlDynamicQueryUpdateLite('endScore', '100', false);
      dispatch(setEndScore(100));
    } else {
      dispatch(setEndScore(parseInt(endScoreUrl, 10) || 100));
    }
    if (pageNumberUrl === null) {
      urlDynamicQueryUpdateLite('pageNumber', '0', false);
      dispatch(setPageNumber(0));
    } else {
      dispatch(setPageNumber(parseInt(pageNumberUrl, 10) || 0));
    }
    if (arriveTypeUrl === null) {
      urlDynamicQueryUpdateLite('arriveType', '', false);
      dispatch(setArriveType([]));
    } else {
      dispatch(setArriveType(parseInt(arriveTypeUrl, 10) ? [parseInt(arriveTypeUrl, 10)] : []));
    }

    /* --------------- New Filter Parameters Below ----------------- */
    if (industryListUrl === null) {
      urlDynamicQueryUpdateLite('industryList', '', false);
      dispatch(setIndustryList(null));
    } else {
      /* Industry list on url should be set as 
       urlDynamicQueryUpdateLite('industryList', [10, 20, 30, ...], false); */
      let resp;
      if (industryListUrl) {
        resp = [];
        industryListUrl.split(',')?.forEach(x => resp.push(parseInt(x, 10)));
      }
      dispatch(setIndustryList(resp));
    }

    if (industryIncludeTypeUrl === null) {
      urlDynamicQueryUpdateLite('industryIncludeType', '20', false);
      dispatch(setIndustryIncludeType(20));
    } else {
      dispatch(setIndustryIncludeType(parseInt(industryIncludeTypeUrl, 10)));
    }

    if (schoolListUrl === null) {
      urlDynamicQueryUpdateLite('schoolList', '', false);
      dispatch(setSchoolList(null));
    } else {
      let resp;
      if (schoolListUrl) {
        resp = [];
        schoolListUrl.split(',')?.forEach(x => resp.push(parseInt(x, 10)));
      }
      dispatch(setSchoolList(resp));
    }

    if (experiencesUrl === null) {
      urlDynamicQueryUpdateLite('experiences', '', false);
      dispatch(setScreeningExperiences(null));
    } else {
      /* Experiences list on url should be set as 
       urlDynamicQueryUpdateLite('experiences', [10, 20, 30, ...], false); */
      let resp;
      if (experiencesUrl) {
        resp = [];
        experiencesUrl.split(',')?.forEach(x => resp.push(parseInt(x, 10)));
      }
      dispatch(setScreeningExperiences(resp || null));
    }

    if (workTypesUrl === null) {
      urlDynamicQueryUpdateLite('workTypes', '', false);
      dispatch(setScreeningWorkTypes(null));
    } else {
      let resp;
      if (workTypesUrl) {
        resp = [];
        workTypesUrl.split(',')?.forEach(x => resp.push(parseInt(x, 10)));
      }
      dispatch(setScreeningWorkTypes(resp));
    }

    if (nationalitiesUrl === null) {
      urlDynamicQueryUpdateLite('workPermit', '', false);
      dispatch(setScreeningNationalities(null));
    } else {
      let resp;
      if (nationalitiesUrl) {
        resp = [];
        nationalitiesUrl.split(',')?.forEach(x => resp.push(parseInt(x, 10)));
      }
      dispatch(setScreeningNationalities(resp));
    }

    if (currencyUrl === null) {
      const enumVal = currencyEnumSelector(currency);
      urlDynamicQueryUpdateLite('currency', enumVal, false);
      dispatch(setScreeningSalaryCurrency(enumVal));
    } else {
      dispatch(setScreeningSalaryCurrency(parseInt(currencyUrl, 10)));
    }

    if (minSalaryUrl === null) {
      urlDynamicQueryUpdateLite('minSalary', '', false);
      dispatch(setScreeningSalaryMinValue(null));
    } else {
      dispatch(setScreeningSalaryMinValue(parseInt(minSalaryUrl, 10) || null));
    }

    if (maxSalaryUrl === null) {
      urlDynamicQueryUpdateLite('maxSalary', '', false);
      dispatch(setScreeningSalaryMaxValue(null));
    } else {
      dispatch(setScreeningSalaryMaxValue(parseInt(maxSalaryUrl, 10) || null));
    }

    if (moveFlag) {
      setActivateFilterForMove(true);
    } else {
      setActivateFilter(true);
    }
  }

  useEffect(() => {
    if (loc) {
      const candidateFlag = getParam(location.search, 'candidateFlag');

      updateFiltersOnLocationChange(candidateFlag === 'true');
      window.addEventListener('locationchange', () => updateFiltersOnLocationChange());
      window.addEventListener('moveCandidateToFunnel', () => updateFiltersOnLocationChange(true));
    }

    return () => {
      window.removeEventListener('locationchange', () => updateFiltersOnLocationChange());
      window.removeEventListener('moveCandidateToFunnel', () =>
        updateFiltersOnLocationChange(true)
      );
    };
  }, [loc]);

  useEffect(async () => {
    if (activateFilter) {
      fetchCandidates();
      if (pageNumberRedux === 0) {
        fetchRequestCandidates();
      }
    }
  }, [activateFilter]);

  useEffect(async () => {
    if (activateFilterForMove) {
      fetchCandidatesAfterMoveFunnel();
    }
  }, [activateFilterForMove]);

  const [allowedHeightForCandidateWrapper, setAllowedHeightForCandidateWrapper] = useState();
  const [screenWidth, screenHeight] = useWindowSize();
  const [allowedFunnelHeight, setAllowedFunnelHeight] = useState();

  const [mobileHeaderHeight, setMobileHeaderHeight] = useState();

  const url = window.location.href;

  const calculateCandidateWrapperHeight = () => {
    const headerHeight = document.getElementById('header')?.getBoundingClientRect()?.height;
    const assessDetailHeaderHeight = document
      .getElementById('assessmentDetailHeader')
      ?.getBoundingClientRect()?.height;
    const assessDetailFilterRowHeight = document
      .getElementById('assessmentDetailFilterRow')
      ?.getBoundingClientRect()?.height;
    const assessmentDetailProgressBarRowHeight = document
      .getElementById('assessmentDetailProgressBarRow')
      ?.getBoundingClientRect()?.height;

    setMobileHeaderHeight(assessDetailHeaderHeight);

    if (headerHeight && assessDetailHeaderHeight && assessDetailFilterRowHeight) {
      setAllowedHeightForCandidateWrapper(
        headerHeight +
          assessDetailHeaderHeight +
          assessDetailFilterRowHeight +
          (assessmentDetailProgressBarRowHeight || 0)
      );
    } else if (assessDetailHeaderHeight && assessDetailFilterRowHeight) {
      setAllowedHeightForCandidateWrapper(
        assessDetailHeaderHeight +
          assessDetailFilterRowHeight +
          (assessmentDetailProgressBarRowHeight || 0)
      );
    } else {
      setAllowedHeightForCandidateWrapper();
    }

    if (assessDetailHeaderHeight && headerHeight) {
      setAllowedFunnelHeight(assessDetailHeaderHeight + headerHeight);
    }
  };

  useEffect(() => {
    calculateCandidateWrapperHeight();
  }, [screenWidth]);

  const funnelOpen = useSelector(funnelOpenSelector);

  useEffect(() => {
    if (screenWidth > 960) {
      dispatch(setFunnelOpen(true));
    } else {
      dispatch(setFunnelOpen(false));
    }
  }, [screenWidth]);

  /* Mixpanel below */

  const reduxUserDetail = useSelector(userSelector);
  const companyAssessmentNameRedux = useSelector(companyAssessmentNameSelector);
  const rootAssessmentNameRedux = useSelector(rootAssessmentNameSelector);
  const indAvgRedux = useSelector(industryAvgSelector);
  const assessmentObjStatusRedux = useSelector(assessmentObjStatusSelector);

  useEffect(() => {
    if (
      localStorage.getItem('Mixpanel_AssessmentDetailPageviewFlag') &&
      funnels &&
      funnels?.length > 0 &&
      reduxUserDetail
    ) {
      mixpanel.track('Assessment Detail - Pageview', {
        'User Id': reduxUserDetail?.userId,
        'Company Name': reduxUserDetail?.companyName,
        'Name Surname': reduxUserDetail?.userNameSurname,
        Email: reduxUserDetail?.email,
        'User Type': reduxUserDetail?.userGroupType,
        'Assessment Name': companyAssessmentNameRedux,
        'Assessment Category': rootAssessmentNameRedux,
        'Assessment Industry Average': indAvgRedux,
        'Assessment Status':
          assessmentObjStatusRedux === 10
            ? 'Published'
            : assessmentObjStatusRedux === 20
            ? 'Unpublished'
            : 'Archived',
        'Assessment Id': id,
        'All Candidates Count': funnels?.filter(x => x?.funnelName === 'All Candidates')[0]
          ?.userCount,
        'Scored Candidates Count': funnels?.filter(x => x?.funnelName === 'Scored')[0]?.userCount,
        'Not Scored Candidates Count': funnels?.filter(x => x?.funnelName === 'Not Scored')[0]
          ?.userCount
      });
      localStorage.removeItem('Mixpanel_AssessmentDetailPageviewFlag');
    }
  }, [funnels, reduxUserDetail]);

  /* ------------------------------------------------- */

  const [filterOpen2, setfilterOpen2] = useState();

  useEffect(() => {
    if (filterOpen) {
      setfilterOpen2(true);
    } else {
      setTimeout(() => {
        setfilterOpen2(false);
      }, 300);
    }
  }, [filterOpen]);

  return (
    <div className={styles.container}>
      {filterOpen2 && (
        <FilterModal filterShow={filterOpen} filterOnClose={() => setFilterOpen(false)} />
      )}

      <AssessmentDetailHeader />
      <div className={styles.wrapper}>
        <div className={styles.col1} style={{ display: !funnelOpen && 'none' }}>
          <Funnel
            caID={id}
            webHeight={allowedFunnelHeight}
            mobileHeight={screenHeight - mobileHeaderHeight}
          />
        </div>
        <div className={styles.CandidateMainWrapper}>
          <ProgressBarRow />
          <FilterRow setFilterOpen={setFilterOpen} />
          <div className={styles.allCandidatesWrapper}>
            <CandidateWrapper allowedHeightForCandidateWrapper={allowedHeightForCandidateWrapper} />
          </div>
        </div>
      </div>
    </div>
  );
}
