import React, { useEffect, useState } from 'react';
import Input, { getCountries, getCountryCallingCode, isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input/input';
import Dropdown from '../../Dropdown/Dropdown';
import styles from './PhoneInput.module.css';

const CustomPhoneInput = ({ country, phoneNum, setPhoneNum, reset }) => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountry, setCountryId] = useState();

  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    setErrorState(false)
    const countryList = getCountries();
    const temp = [];
    countryList.forEach((e, i) => {
      const code = getCountryCallingCode(e);
      const dropdownObj = { id: i, name: `${e} (+${code})`, key: `${e} (+${code})` }
      temp.push(dropdownObj);
      if (phoneNum && parsePhoneNumber(phoneNum)?.country) {
        if (parsePhoneNumber(phoneNum)?.country === e) {
          setCountryId(dropdownObj);
        }
      } else if (e === country) {
        setCountryId(dropdownObj);
      }        
    });
    setCountryCodes(temp);
  }, [reset]);

  const [timeoutState, setTimeoutState] = useState(-1);

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.DropdownWrapper}>
        {countryCodes && selectedCountry && (
          <Dropdown
            placeholder=""
            title="Country"
            // openItemCount={3}
            list={countryCodes}
            setSelectedOption={setCountryId}
            selectedOption={selectedCountry}
            selectOnClick={() => {setPhoneNum(); setErrorState(false)}}
            isSearchable
            searchBarPlaceholder="Country Code"
            wrapperStyle={{width: '12rem'}}
          />
        )}
      </div>

      {countryCodes && selectedCountry && (
        <div className={styles.PhoneWrapper}>
          <Input
            className={`${styles.PhoneInputField} ${errorState && styles.Invalid}`}
            value={phoneNum}
            country={countryCodes[selectedCountry?.id]?.name.split(' ')[0]}
            onChange={e => {
              setPhoneNum(e);
              if (timeoutState !== -1) {
                clearTimeout(timeoutState);
                setTimeoutState(-1);
              }
              if (!e) {
                setErrorState(false);
              } else if(isPossiblePhoneNumber(e)) {
                setErrorState(false);
              } else {
                setTimeoutState(
                  setTimeout(() => {
                    setErrorState(true)
                  }, 500)
                );
              }
              
            }}
            placeholder=" " // Olması gerekiyor
            name="PhoneInput"
          />
          <span className={`${styles.basePlaceholder} ${styles.placeholder}`}>Phone Number*</span>
        </div>
      )}
    </div>
  );
};

export default CustomPhoneInput;
