import API from '../../../constant/apiVol2';
import client from '../../../constant/indexVol2';

export const loginVol2 = data =>
  new Promise((resolve, reject) => {
    client.post(API.loginVol2, data).then(resolve).catch(reject);
  });

export const getRootAssessmentListServiceVol2 = data =>
  new Promise((resolve, reject) => {
    client.get(API.getRootAssessmentList, { params: data }).then(resolve).catch(reject);
  });

export const getCategoryLabelsListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCategoryLabelsList, { params: data }).then(resolve).catch(reject);
  });

// Add skill-based question
export const addSkillBasedQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addSkillBasedQuestion, data).then(resolve).catch(reject);
  });

export const getCodingLanguagesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCodingLanguages, { params: data }).then(resolve).catch(reject);
  });

export const addSkillBasedCodingQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addSkillBasedCodingQuestion, data).then(resolve).catch(reject);
  });

export const createCustomAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createCustomAssessment, data).then(resolve).catch(reject);
  });

// Update or call in the initial render
export const updateCustomAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateCustomAssessment, data).then(resolve).catch(reject);
  });

export const removeSkillBasedQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.removeSkillBasedQuestion, data).then(resolve).catch(reject);
  });

// Update the Question Weight
export const updateQuestionWeightService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateQuestionWeight, data).then(resolve).catch(reject);
  });

export const getJobDescriptionListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getJobDescriptionList, { params: data }).then(resolve).catch(reject);
  });

export const getQuestionByFilterService = data =>
  new Promise((resolve, reject) => {
    client.post(API.getQuestionByFilter, data).then(resolve).catch(reject);
  });

export const addQuestionBasedQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addQuestionBasedQuestion, data).then(resolve).catch(reject);
  });
export const tryAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.get(API.tryAssessment, { params: data }).then(resolve).catch(reject);
  });

export const getUserListByCompanyService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getUserListByCompany, { params: data }).then(resolve).catch(reject);
  });

export const sendEmailForReviewService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendEmailForReview, data).then(resolve).catch(reject);
  });

export const sendNotificationForRecruiterService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendNotificationForRecruiter, data).then(resolve).catch(reject);
  });

export const getSkillsOfJobRoleService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getSkillsOfJobRole(data)).then(resolve).catch(reject);
  });

export const getQuestionDetailService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getQuestionDetail, { params: data }).then(resolve).catch(reject);
  });
export const inviteUserService = data =>
  new Promise((resolve, reject) => {
    client.post(API.inviteUser, data).then(resolve).catch(reject);
  });
export const getAtsListService = data =>
  new Promise((resolve, reject) => {
    // const cancelToken = axios.CancelToken.source(); How to create the token
    // cancelToken.cancel() how to cancel
    // client.get(API.getAtsList, { params: data,cancelToken:cancelToken }).then(resolve).catch(reject); How to use it
    client.get(API.getAtsList, { params: data }).then(resolve).catch(reject);
  });

export const addAssessmentServiceVol2 = data =>
  new Promise((resolve, reject) => {
    client.post(API.addAssessment, data).then(resolve).catch(reject);
  });

export const getAssessmentDetailAfterCreateService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getAssessmentDetailAfterCreate, { params: data }).then(resolve).catch(reject);
  });
export const applicantAssessmentSendEmailService = data =>
  new Promise((resolve, reject) => {
    client.post(API.applicantSendEmail, data).then(resolve).catch(reject);
  });

export const publishAssessmentControlService = data =>
  new Promise((resolve, reject) => {
    client.get(API.publishAssessmentControl, { params: data }).then(resolve).catch(reject);
  });

export const getRootAssessmentDropdownListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getRootAssessmentDropdownList, { params: data }).then(resolve).catch(reject);
  });
