import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/CustomAssessmentModal.module.css';
import CloseButton from '../../../Common/Components/CloseButton';
import { setTab } from '../../../redux/addAssessment/slice';
import CustomPopover from '../../../Common/Components/CustomPopover';

import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';

// next stage s for opening the name modal
export default function CustomAssessmentModal({ show, onHide, nextStage }) {
  const dispatch = useDispatch();

  // Mixpanel Variable
  const user = useSelector(userSelector);

  const [AIButtonClicked, setAIButtonClicked] = useState(false);
  const delay = 4000;

  const [leftMargin, setLeftMargin] = useState(0);
  const [topMargin, setTopMargin] = useState(0);
  const [timer, setTimer] = useState(0);


  useEffect(() => {
    const left = document.getElementById('AIButton')?.getBoundingClientRect()?.left;
    const width = document.getElementById('AIButton')?.getBoundingClientRect()?.width;
    const top = document.getElementById('AIButton')?.getBoundingClientRect()?.top;


    setLeftMargin(left + width / 2 - 70);
    setTopMargin(top + 24);
  });

  function closePopover() {
    setAIButtonClicked(false);
  }


  useEffect(() => {
    if(!show){
      setAIButtonClicked(false);
      clearTimeout(timer);
    }
  }, [show])

  const ModalButton = ({ id, color, title, subtitle, user, onClick, isnextStage = true }) => (
    <div
      id={id}
      className={`${styles.buttonContainer} ${
        color === 'purple' ? styles.purpleButton : styles.greyButton
      }`}
      onClick={() => {
        // history.push(`/assessment-customization/${'createID'}user?type=${user.replace(/\s/g, '')}`);
        onClick();
        if (isnextStage) {
          nextStage();
        }
      }}
    >
      <div className={styles.buttonTitle}>{title}</div>
      <div className={styles.buttonSubtitle}>{subtitle}</div>
      <div
        className={`${styles.userTitle} ${
          color === 'purple' ? styles.purpleUser : styles.greyUser
        }`}
      >
        {user}
      </div>
    </div>
  );

  return (
    <Modal
      style={{ inset: '0' }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <div className={styles.title}>
        Create Custom Assessment
        <div className={styles.closeButton}>
          <CloseButton size={34} onClick={onHide} />
        </div>
      </div>
      <div className={styles.subtitle}>
        Please select the best option to create your custom assessment.
      </div>
      <ModalButton
        color="grey"
        title="Create by Choosing Skills"
        subtitle="Best option for"
        user="Recruiters"
        onClick={async () => {
          /* Mixpanel Button Click Event */
          mixpanel.track("Create Custom Assessment Popup Selection", {
            "User Id": user.userId,
            "Company Name": user.companyName,
            "Name Surname": user.userNameSurname,
            "Selected Option": "Recruiter",
          });

          await dispatch(setTab('Recruiter'));
        }}
      />
      <ModalButton
        color="grey"
        title="Create by Choosing Questions from Library"
        subtitle="Best option for"
        user="Hiring Managers"
        onClick={async () => {
          /* Mixpanel Button Click Event */
          mixpanel.track("Create Custom Assessment Popup Selection", {
            "User Id": user.userId,
            "Company Name": user.companyName,
            "Name Surname": user.userNameSurname,
            "Selected Option": "Hiring Manager",
          });

          await dispatch(setTab('Hiring Manager'));
        }}
      />
      <CustomPopover
        style={{ marginTop: topMargin, marginLeft: leftMargin }}
        show={AIButtonClicked && show}
        className={styles.popover}
        placement="top"
      >
        <ModalButton
          id="AIButton"
          isnextStage={false}
          color="purple"
          title="Let the Magic Happen! "
          subtitle="Just paste your job description to create a perfect fit assessment by"
          user="Coensio AI"
          onClick={() => {
           /* Mixpanel Button Click Event */
            mixpanel.track("Create Custom Assessment Popup Selection", {
              "User Id": user.userId,
              "Company Name": user.companyName,
              "Name Surname": user.userNameSurname,
              "Selected Option": "Coensio AI",
            });

            setAIButtonClicked(true);
             const timer = setTimeout(() => {closePopover()
             }, delay);
             setTimer(timer);

           }}
        />
      </CustomPopover>
    </Modal>
  );
}
