import { React, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SkillBasedQuestion from './SkillBasedQuestion';
import styles from '../Styles/QuestionList.module.css';
import codingQ from '../../../images/coding_assessment.svg';
import freetextQ from '../../../images/freetext_assessment.svg';
import multiselectQ from '../../../images/multiple_assessment.svg';
import { setQuestionBeingAdded } from '../../../redux/assessmentsVol2/slice';

export default function QuestionList({ qType, qList, listChange }) {
  const [alteredList, setAlteredList] = useState([]);
  const dispatch = useDispatch();

  const handleTypeConversion = () => {
    let typeInt = 0;
    if (qType === 'Coding') {
      typeInt = 2;
    } else if (qType === 'Multiple Select') {
      typeInt = 1;
    } else if (qType === 'Free-Text') {
      typeInt = 3;
    }
    return typeInt;
  };

  const memoizedCallback = () => {
    const myList = [];
    if (qList) {
      qList.forEach(x => {
        let number = 0;
        let flag = false;

        myList.forEach(z => {
          if (JSON.stringify(z.skills) === JSON.stringify(x.label) && 
              JSON.stringify(z.language) === JSON.stringify(x.questionLanguage) &&
              JSON.stringify(z.difficulty) === JSON.stringify(x.difficulty)
          ) {
            flag = true;
          }
        });

        if (!flag) {
          number = 1;
          const idArr = [x.questionId];
          qList.forEach(y => {
            if (
              JSON.stringify(x.questionId) !== JSON.stringify(y.questionId) &&
              JSON.stringify(x.label) === JSON.stringify(y.label) &&
              JSON.stringify(x.difficulty) === JSON.stringify(y.difficulty) &&
              JSON.stringify(x.questionLanguage) === JSON.stringify(y.questionLanguage)
            ) {
              number += 1;
              idArr.push(y.questionId);
            }
          });
          myList.push({
            skills: x.label,
            copy: number,
            id: idArr,
            difficulty: x.difficulty,
            type: x.questionType,
            language: x.questionLanguage,
            category: x.category,
            maxQ: x.maxQuestionCount
          });
        }
      });
    }
    setAlteredList(myList);
  };

  useEffect(() => {
    memoizedCallback();
    handleTypeConversion();
  }, [listChange]);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.qTitleRow}>
        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 9, flexBasis: '90%' }}>
          <span>
            <img
              width={36}
              height={24}
              src={
                qType === 'Coding'
                  ? codingQ
                  : qType === 'Multiple Select'
                  ? multiselectQ
                  : freetextQ
              }
              alt=""
            />
          </span>
          <div className={styles.header}>{`${qType} Questions`}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100px' }}>
          <div className={styles.count}>{qList && qList.length}</div>
          <button
            className={styles.addButton}
            type="button"
            onClick={() => {
              dispatch(setQuestionBeingAdded(qType));
            }}
          >
            Add
          </button>
        </div>
      </div>
      {alteredList.map((question, index) => (
        <SkillBasedQuestion
          copy={question.copy}
          qType={question.type}
          language={question.language}
          difficulty={question.difficulty}
          isLast={alteredList.length === index + 1}
          id={question.id}
          skills={question.skills && question.skills}
          qCategory={question.category}
          maxQuestionCount={question.maxQ}
          isEmpty={false}
        />
      ))}
      {alteredList.length === 0 && (
        <SkillBasedQuestion isEmpty isLast qType={handleTypeConversion()} />
      )}
    </div>
  );
}
