import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIndex, setToInitialState } from '../../../redux/addAssessment/slice';
import {
  setToInitialState2,
  setContinueToAssessmentDetail
} from '../../../redux/assessmentsVol2/slice';
import Header from '../../../Common/Components/Header';
import CreateAssessmentBody from '../Components/CreateAssessmentBody';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { continueToAssessmentDetailRedux } from '../../../redux/assessmentsVol2/selectors';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';

export default function CreateAssessmentPage() {
  const dispatch = useDispatch();
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setIndex(0)); // Return the initial index when user leaves the page
      dispatch(setToInitialState());
      dispatch(setToInitialState2());
    };
  }, []);

  const continueToAssessmentDetailFlag = useSelector(continueToAssessmentDetailRedux);

  return (
    <>
      <InfoBanner
        text="Copied!"
        show={showCopied}
        setStatus={type => {
          setShowCopied(type);
        }}
      />

      <InfoBanner
        text="An error occurred. Please try again."
        show={continueToAssessmentDetailFlag === false}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(setContinueToAssessmentDetail(null));
        }}
      />

      <Header />
      <CreateAssessmentBody setShowCopy={setShowCopied} />
    </>
  );
}
