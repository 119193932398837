import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'react-input-range/lib/css/index.css';
import { format, startOfWeek, subtractDays, isSameDay } from 'date-fns';
import styles from './FilterModal.module.css';
import downArrow from './down-arrow.svg';
import upArrow from './up-arrow.svg';
import RadioButton from '../../../Common/Components/RadioButton';
import MyCalendar from '../../../Common/Components/MyCalendar';
import { oldestInitialDateSelector } from '../../../redux/assessmentDetailVol2/selectors';

export default function FilterItemDate({
  filterName,
  openItem,
  setOpenItem = () => {},
  // dates,
  // setDates = () => {}
  initialDate,
  setInitialDate = () => {},
  finalDate,
  setFinalDate = () => {}
}) {
  // Date Filter Variables
  const oldestDateInt = useSelector(oldestInitialDateSelector);

  const today = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const lastSunday = startOfWeek(today);
  const lastSaturday = new Date(lastSunday - 86400000);
  const sevenDaysAgo = new Date(today - 86400000 * 7);
  const thirtyDaysAgo = new Date(today - 86400000 * 30);

  const [dateTab, setDateTab] = useState(1); // 1: radiobuttons 2: calendar
  const [selectedDateRange, setSelectedDateRange] = useState(); // selected option from radiobuttons
  const [calendarChange, setCalendarChange] = useState(null);
  const [dateUnselected, setDateUnselected] = useState(null);

  // FUNCTIONS
  function resetFields() {
    setSelectedDateRange(-1);
    setCalendarChange(null);
    setInitialDate(
      oldestDateInt ? new Date(new Date().setDate(new Date().getDate() + oldestDateInt)) : today
    );
    setFinalDate(today);
  }

  // USE EFFECTS
  useEffect(() => {
    if (dateUnselected === 'true' && selectedDateRange === undefined) {
      setCalendarChange(null);
      setInitialDate(
        oldestDateInt ? new Date(new Date().setDate(new Date().getDate() + oldestDateInt)) : today
      );
      setFinalDate(today);
    } else {
      switch (selectedDateRange) {
        case 'Today':
          setInitialDate(today);
          setFinalDate(today);
          break;
        case 'Yesterday':
          setInitialDate(yesterday);
          setFinalDate(yesterday);

          break;
        case 'Last Weekend':
          setInitialDate(lastSaturday);
          setFinalDate(lastSunday);

          break;
        case 'Last 7 days':
          setInitialDate(sevenDaysAgo);
          setFinalDate(today);

          break;
        case 'Last 30 days':
          setInitialDate(thirtyDaysAgo);
          setFinalDate(today);
          break;

        default:
          break;
      }
    }
  }, [selectedDateRange]);

  useEffect(() => {
    if (calendarChange === 'change') {
      setSelectedDateRange(null);
    }
  }, [calendarChange]);

  useEffect(() => {
    if (initialDate && finalDate) {
      if (initialDate === -1 || finalDate === -1) {
        resetFields();
      }

      if (isSameDay(today, initialDate) && isSameDay(today, finalDate)) {
        setSelectedDateRange('Today');
      } else if (isSameDay(yesterday, initialDate) && isSameDay(yesterday, finalDate)) {
        setSelectedDateRange('Yesterday');
      } else if (isSameDay(lastSaturday, initialDate) && isSameDay(lastSunday, finalDate)) {
        setSelectedDateRange('Last Weekend');
      } else if (isSameDay(sevenDaysAgo, initialDate) && isSameDay(today, finalDate)) {
        setSelectedDateRange('Last 7 days');
      } else if (isSameDay(thirtyDaysAgo, initialDate) && isSameDay(today, finalDate)) {
        setSelectedDateRange('Last 30 days');
      }
    }
  }, [initialDate, finalDate]);

  return (
    <div className={styles.itemContainer}>
      <div
        className={styles.innerHeaderWrapper}
        onClick={() => {
          if (openItem === filterName) {
            setOpenItem('');
          } else {
            setOpenItem(filterName);
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span className={styles.itemHeader}>Filter Candidates by&nbsp;</span>
          <span className={styles.itemHeaderBold}>{filterName}</span>
        </div>

        <div className={styles.rightHeaderWrapper}>
          {calendarChange === 'change' || selectedDateRange ? (
            <div className={styles.yellowIcon} />
          ) : null}
          <span className={styles.icon}>
            <img alt="" src={openItem === filterName ? upArrow : downArrow} />
          </span>
        </div>
      </div>

      {openItem === filterName ? (
        <div className={styles.dateWrapper}>
          <div className={styles.dateContainer}>
            <div
              className={`${styles.dateRange} ${dateTab === 1 && styles.dateRangeActive}`}
              onClick={() => {
                setDateTab(1);
              }}
            >
              Pick a Date Range
            </div>
            <div
              className={`${styles.dateRange} ${dateTab === 2 && styles.dateRangeActive}`}
              onClick={() => {
                setDateTab(2);
              }}
            >
              Custom Date
            </div>
          </div>
          {dateTab === 1 ? (
            <div className={styles.radioWrapper}>
              <RadioButton
                radioItemTextStyle={{ fontFamily: 'Jost-400' }}
                radioItemStyle={{ marginBottom: '2rem' }}
                data={['Today', 'Yesterday', 'Last Weekend', 'Last 7 days', 'Last 30 days']}
                setSelectedOption={setSelectedDateRange}
                selectedOption={selectedDateRange}
                setUnselectedButtons={setDateUnselected}
              />
            </div>
          ) : (
            <>
              <div className={styles.startEndDateWrapper}>
                <div className={styles.dateStartEndContainer}>
                  <span className={`${styles.dateStartEndTitle} ${initialDate && styles.filled}`}>
                    From
                  </span>
                  <span className={styles.dateStartEnd}>
                    {initialDate ? format(initialDate, 'MMM dd, yyyy (EEEE)') : null}
                  </span>
                </div>
                <div className={styles.dateStartEndContainer}>
                  <span className={`${styles.dateStartEndTitle} ${finalDate && styles.filled}`}>
                    To
                  </span>
                  <span className={styles.dateStartEnd}>
                    {finalDate ? format(finalDate, 'MMM dd, yyyy (EEEE)') : null}
                  </span>
                </div>
              </div>
              <div className={styles.calendarContainer}>
                <MyCalendar
                  dateStart={initialDate}
                  dateEnd={finalDate}
                  setDateStart={setInitialDate}
                  setDateEnd={setFinalDate}
                  setChange={setCalendarChange}
                />
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
}
