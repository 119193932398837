import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/LeavingPageModal.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import { ReactComponent as Delete } from '../../../images/leavePageModalDelete.svg';
import { sendNotificationForRecruiterAction } from '../../../redux/assessmentsVol2/actions';
import { customAssessmentId } from '../../../redux/assessmentsVol2/selectors';
import CloseButton from '../../../Common/Components/CloseButton';

export default function LeavingPageModal({
  show = false,
  onHide = () => {},
  cancelClick,
  confirmClick
}) {
  const dispatch = useDispatch();
  const assessmentId = useSelector(customAssessmentId);
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          // marginRight: 0,
          // marginLeft: 'auto',
          justifyContent:"space-between",
          marginBottom:"24px"
        }}
      >
        <div className={styles.title}>Your progress has been saved as a draft!</div>
        <CloseButton onClick={() => onHide()} />
      </div>

      <div className={styles.subtitle}>
        You can easily continue to edit or delete this assessment from your company assessment
        library.{' '}
      </div>
      <div className={styles.row}>
        <div
          style={{
            marginLeft: 0,
            marginRight: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Delete className={styles.deleteIcon} />
          <button type="button" className={styles.delete} onClick={cancelClick}>
            Delete Assessment
          </button>
        </div>
        <CustomButton
          size="small"
          style={{ width: 70, height: 37 }}
          textField="OK"
          buttonOnClick={e => {
            dispatch(
              sendNotificationForRecruiterAction({ type: 2, rootAssessmentId: assessmentId })
            );
            confirmClick(e);
          }}
          type={1}
        />
      </div>
    </Modal>
  );
}
