import React from 'react';
import Modal from '../../../Common/CustomModal/Modal';
import CloseButton from '../../../Common/Components/CloseButton';
import CustomButton from '../../../Common/Components/CustomButton';
import Hourglass from './hourglass.svg';
import styles from './RequestLimitedModal.module.css';
import { useWindowSize } from '../../../utils/helpers';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';

const RequestLimitedModal = ({ show = true, onHide }) => {
  const [screenW] = useWindowSize();

  const ModalContent = (
    <>
      <div className={styles.closeButton}>
        <CloseButton size={screenW > 576 ? "small" : "medium"} onClick={onHide} />
      </div>
      <div className={styles.infoBox}>
        <img alt="" className={styles.hourglass} src={Hourglass} />
        <span className={styles.headText}>Hourly request limit reached!</span>
        <span className={styles.belowInfoText}>
          Once the timeout period has expired, you can continue sending requests to candidates.
        </span>
        <CustomButton
          textField="OK"
          customStyle={{ width: '100%', marginTop: '32px', fontSize: '18px' }}
          size="medium"
          isResponsive
          buttonOnClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onHide();
          }}
        />
      </div>
    </>
  );

  const WebView = (
    <Modal
      outsideClickClose
      centerHorizontally
      centerVertically
      show={show}
      onClose={onHide}
      size={{ width: '572px' }}
      contentStyle={{
        background: '#FFFFFF',
        borderRadius: '16px',
        padding: '47px 32px 32px 32px'
      }}
      backdropStyle={{ overflow: 'hidden' }}
    >
      {ModalContent}
    </Modal>
  );

  const MobileView = (
    <MobileModal
      id="mobile-request-limited"
      contentStyle={{
        padding: '3.2rem 2rem 2.4rem',
        minWidth: '375px',
        overflow: 'hidden'
      }}
      onClose={onHide}
      backdropStyle={{ overflow: 'hidden' }}
      show={show}
      outsideClickClose
    >
      {ModalContent}
    </MobileModal>
  );

  return screenW > 576 ? WebView : MobileView;
};

export default RequestLimitedModal;
