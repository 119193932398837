/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import 'react-circular-progressbar/dist/styles.css';
import { Navbar, Nav, BImg } from 'bootstrap-4-react';
import { userSelector } from '../../redux/auth/selectors';
import logo from '../../images/Coensio_newLogo.svg';
import route from '../../constant/route';
import styles from '../Styles/Header.module.css';
import CompanySelectModal from '../Modals/companySelectModal';
import { getLogoByCompanyIdAction } from '../../redux/logo/actions';
import UserIcon from './UserIcon';
import { LogoPath } from '../../redux/logo/selectors';
import { setLogo } from '../../redux/logo/slice';
import Banner from './Banner';
import RequestFullBanner from './RequestFullBanner/RequestFullBanner';
import { setAvailableSpace, setViewHeight } from '../../redux/user/slice';
import ProfileBanner from '../ProfileBanner/ProfileBanner';
import {
  currentPlanRedux,
  currentUsageRedux,
  errorInSeePlans,
  errorTextInSeePlans,
  purchaseModalShowRedux,
  onboardingStepRedux,
  teamHighlight,
  addLogoBoxHighlight,
  addNewUserHighlight
} from '../../redux/plan/selectors';
import {
  setErrorInSeePlans,
  setPurchaseModalShow,
  setQuickStartOpen
} from '../../redux/plan/slice';
import CustomButton from './CustomButton';
import { ReactComponent as EditLogo } from '../../Auth/Images/logoUploadIcon.svg';
import { ReactComponent as Error } from '../../images/errorSubmit.svg';
import InfoBanner from './InfoBanner';
import PurchaseModal from '../../Auth/Modals/PurchaseModal';
import ContactSalesSuccess from '../../Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import Highlight from './Highlight';
import MobileHeaderComponent from './MobileHeaderComponent';
import { getHeaderHeight, useWindowSize } from '../../utils/helpers';

const Header = () => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const headerH = getHeaderHeight();

  useEffect(() => {
    dispatch(setAvailableSpace(`calc(100dvh - ${headerH}px)`));
  }, [headerH]);

  const [showBanner, setShowBanner] = useState(false);
  const [showRequestBanner, setShowRequestBanner] = useState(false);

  const { search } = useLocation();
  const refresh = new URLSearchParams(search).get('refresh');
  const url = window.location.href;
  // const [showBanner, setShowBanner] = useState(!localStorage.getItem('Banner'));
  const userLogo = useSelector(LogoPath); // Böyle düzeltmek lazım
  // const { search } = useLocation();
  // const refresh = new URLSearchParams(search).get('changeCompany');

  const [userModal, setUserModal] = useState(false);
  // const [companyModal, setCompanyModal] = useState(false);
  const [companySelect, setCompanySelect] = useState(false);
  // const [currentUser, setCurrentUser] = useState(null);
  const [planStatus, setPlanStatus] = useState(false);

  const currentPlan = useSelector(currentPlanRedux);

  const currentPackageUsage = useSelector(currentUsageRedux);

  // const [userLogo, setUserLogo] = useState(null);
  const getLogo = async currentUser => {
    // const formData = new FormData();
    // formData.append('companyId', currentUser.companyId);
    // await dispatch(getCompanyLogoAction(formData));
    const data = {
      CompanyId: currentUser.companyId
    };
    const resp = await dispatch(getLogoByCompanyIdAction(data));
  };

  /* eslint-disable */
  useEffect(async () => {
    if (localStorage.getItem('user') && JSON.stringify(user) !== localStorage.getItem('user')) {
      dispatch(setLogo(null));
    }
  }, [user]);

  useEffect(() => {
    if (
      currentPackageUsage?.usedCandidates >= currentPackageUsage?.allowedCandidates &&
      !sessionStorage.getItem('isBannerClosed')
    ) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }

    if (
      currentPackageUsage?.usedRequestCandidate > currentPackageUsage?.allowedRequestCandidate
      // && !(currentPackageUsage?.usedRequestCandidate === 0 && currentPackageUsage?.allowedRequestCandidate === 0)
    ) {
      setShowRequestBanner(true);
    } else {
      setShowRequestBanner(false);
    }
  }, [currentPackageUsage]);

  const [boundingBox, setBoundingBox] = useState();
  const [img, setImg] = useState(false);
  const [width, height] = useWindowSize();
  useEffect(() => {
    setBoundingBox(document.getElementById('profileIcon')?.getBoundingClientRect());
  }, [width, userLogo, user, img]);

  useEffect(() => {
    if (user) {
      if (!userLogo) {
        getLogo(user);
      }
      const activeCompany = localStorage.getItem('activeCompany');
      if (user && user.userGroupType === 'Super Admin' && !activeCompany) {
        setCompanySelect(true);
      }
      if (!user.packageId || user.packageId.length === 0) {
        if (user && user.userGroupType !== 'Super Admin') {
          setPlanStatus(false);
        }
      }
    }
  }, [user]);

  useEffect(async () => {
    const height = document.getElementById('header').getBoundingClientRect().height; // Hold the header information to refresh the screen
    const result = dispatch(setViewHeight(height));
  }, [showBanner]);

  // Show conditions of the banners change -> headerUpdate event is trigerred
  useEffect(() => {
    if (width <= 576) {
      setTimeout(() => {
        window.dispatchEvent(new Event('headerUpdate'));
      }, 50);
    } else {
      window.dispatchEvent(new Event('headerUpdate'));
    }
  }, [showBanner, showRequestBanner, width]);

  const ref = useRef();

  const error = useSelector(errorInSeePlans);
  const errorText = useSelector(errorTextInSeePlans);
  const purchaseShow = useSelector(purchaseModalShowRedux);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const onboardingStep = useSelector(onboardingStepRedux);
  const [userIconHighlight, setUserIconHighlight] = useState(false);
  const teamHighlighted = useSelector(teamHighlight);
  const addLogoBoxHighlighted = useSelector(addLogoBoxHighlight);
  const addNewUserHighlighted = useSelector(addNewUserHighlight);

  return (
    <div id={'header'} className={styles.headerParent}>
      {user && (
        <>
          <Navbar
            className={styles.CustomNavbar}
            style={{ height: width <= 576 ? '' : '64px' }}
            id="CoensioNavBar"
          >
            {width <= 576 ? (
              <MobileHeaderComponent />
            ) : (
              <>
                <Navbar.Brand onClick={() => {}} id="navbarBrand">
                  <NavLink to={route.assessments}>
                    <BImg src={logo} mr="1" ml="5" maxWidth="126" maxHeight="50" />
                  </NavLink>
                  {user && user.userGroupType === 'Super Admin' && (
                    <span className={styles.selectCompany} onClick={() => setCompanySelect(true)}>
                      Change Corporate
                    </span>
                  )}
                </Navbar.Brand>{' '}
              </>
            )}
            <ProfileBanner
              boundingBox={boundingBox}
              show={userModal}
              width={width}
              parentRef={ref}
              setShow={type => setUserModal(type)}
            />
            <CompanySelectModal
              show={companySelect}
              currentUser={user}
              onHide={() => setCompanySelect(false)}
              onHideSuccess={company => {
                setCompanySelect(false);
              }}
            />

            <InfoBanner
              text={errorText}
              show={error}
              CustomIcon={Error}
              setStatus={type => {
                dispatch(setErrorInSeePlans(type));
              }}
            />
            <PurchaseModal
              show={purchaseShow}
              onHide={() => dispatch(setPurchaseModalShow(false))}
              setSuccess={setPurchaseSuccess}
            />
            <ContactSalesSuccess
              icon="tick"
              show={purchaseSuccess}
              onHide={() => {
                setPurchaseSuccess(false);
              }}
              title="Done and dusted!"
              description="Thank you for your purchase! Time to find a rockstar."
            />
            <Highlight
              style={{ marginRight: '10px' }}
              isHighlighted={
                (onboardingStep === 2 || onboardingStep == 3) &&
                !userModal &&
                !teamHighlighted &&
                !addLogoBoxHighlighted &&
                !addNewUserHighlighted
              }
              borderRadius={12}
              paddingPixel={4}
              child={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '10px'
                  }}
                  id="OnboardingAddLogoId"
                >
                  <div style={{ marginLeft: 'auto' }}>
                    <Nav
                      mr="0"
                      onClick={() => {
                        setUserModal(prev => !prev);
                        dispatch(setQuickStartOpen(false));
                      }}
                    >
                      <Nav.ItemLink href="#" style={{ padding: '.5rem .1rem', color: '#4a4546' }}>
                        <span id={'profileIcon'} ref={ref}>
                          <UserIcon name={user?.userNameSurname} surname=" " />
                        </span>
                      </Nav.ItemLink>
                    </Nav>
                  </div>
                  <div
                    style={{
                      height: '32px',
                      border: '1px solid #58585833',
                      marginLeft: '5px',
                      marginRight: '5px'
                    }}
                  />
                  {userLogo ? (
                    <img
                      onLoad={() => {
                        setImg(true);
                      }}
                      className="mt-1"
                      src={userLogo}
                      style={{
                        marginLeft: '5px',
                        maxHeight: '50px',
                        maxWidth: '100px',
                        marginRight: '10px'
                      }}
                      alt="logo"
                    />
                  ) : (
                    <div className={styles.HeaderAddLogo}>
                      <CustomButton
                        textField="Add Logo"
                        Icon={EditLogo}
                        type={4}
                        size="small"
                        buttonOnClick={() => {
                          history.push('/settings/general');
                        }}
                      />
                    </div>
                  )}
                </div>
              }
            />
            {/* </> */}
          </Navbar>
        </>
      )}
      {user && showBanner && !showRequestBanner && <Banner setShow={setShowBanner} />}
      {user && showRequestBanner && <RequestFullBanner setShow={setShowRequestBanner} />}
    </div>
  );
};

export default React.memo(Header, (prev, next) => {
  return true;
});
