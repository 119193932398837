import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './InviteCandSuccess.module.css';
import { ReactComponent as Close } from './cross.svg';
import { ReactComponent as Success } from '../../../images/IBE_success.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Fail } from './alertRejectedIcon.svg';
import Modal from '../../../Common/CustomModal/Modal';

import {
  invitedEmail,
  scoredEmails,
  sentMailSuccess,
  mailRejected
} from '../../../redux/assessmentsVol2/selectors';
import { setSentMailRejected, setSentMailSuccess } from '../../../redux/assessmentsVol2/slice';
import Loading from '../../../Common/Components/Loading';
import { resetCandidateListRelatedStuffForNewFilters } from '../../../redux/assessmentDetailVol2/slice';
import { resetFiltersForFunnelClick, urlDynamicQueryUpdateLite, useWindowSize } from '../../../utils/helpers';
import {
  funnelIdSelector,
  funnelInfoSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import { getFunnelInformationAction } from '../../../redux/assessmentDetailVol2/actions';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';

const InviteCandSuccessModal = ({ show = false }) => {
  const invitedCount = useSelector(invitedEmail);
  const scoredCount = useSelector(scoredEmails);
  const dispatch = useDispatch();

  const { id } = useParams();

  const mailSuccess = useSelector(sentMailSuccess);
  const rejected = useSelector(mailRejected);

  const funnels = useSelector(funnelInfoSelector);
  const selectedFunnelIdRedux = useSelector(funnelIdSelector);

  const handleOnClose = (avoidDuplicate = false) => {
    dispatch(setSentMailSuccess(false));
    dispatch(setSentMailRejected(false));

    const funnelData = {
      CompanyAssessmentId: id
    };
    dispatch(getFunnelInformationAction(funnelData));

    if (!avoidDuplicate) {
      const scoredFunnel = funnels.filter(
        e => e.funnelCategory === 20 && e.funnelName === 'Scored'
      )[0];
      if (selectedFunnelIdRedux === scoredFunnel?.funnelId || selectedFunnelIdRedux === "") {
        dispatch(resetCandidateListRelatedStuffForNewFilters());
        urlDynamicQueryUpdateLite('funnelId', scoredFunnel?.funnelId, true);
      }
    }
  };

  function redirectToScored() {
    dispatch(resetCandidateListRelatedStuffForNewFilters());
    const scoredFunnel = funnels.filter(
      e => e.funnelCategory === 20 && e.funnelName === 'Scored'
    )[0];
    if (scoredFunnel) {
      urlDynamicQueryUpdateLite('funnelId', scoredFunnel?.funnelId, true);
    }
    handleOnClose(true);
  }

  const [screenW, screenH] = useWindowSize();

  const WebIbeSuccessModal = (
    <Modal
      backdropStyle={{ backdropFilter: 'blur(2px)', zIndex: '10001' }}
      contentStyle={{ borderRadius: '12px' }}
      show={show}
      onClose={() => handleOnClose(false)}
      size={{ width: '572px' }}
      centerHorizontally
      centerVertically
      outsideClickClose
    >
      {mailSuccess ? (
        <div className={styles.modalContent}>
          <div className={styles.close}>
            <Close onClick={() => handleOnClose(false)} />
          </div>

          <Success className={`${styles.icon} ${styles.success}`} />
          <div className={styles.title}>
            {`${invitedCount}/${scoredCount + invitedCount} have been successfully invited!`}
          </div>
          <div className={styles.text}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            These candidates can be seen as 'invited by e-mail' in the not scored stage.
          </div>
          <div className={styles.divider} />
          <Info className={`${styles.icon} ${styles.info}`} />
          <div className={styles.title}>{`${scoredCount}/${
            scoredCount + invitedCount
          } have already been scored!`}</div>
          <div className={styles.text}>These candidates can be seen in the scored stage.</div>
          {scoredCount !== 0 && (
            <div
              className={styles.purple}
              // to={route.assessmentDetail(assessmentId)}
              onClick={() => {
                redirectToScored();
              }}
            >
              See the ‘scored’ candidates
            </div>
          )}
          {/* <div onClick={() => } className={styles.purple}>See the ‘scored’ candidates</div> */}
        </div>
      ) : rejected ? (
        <div>
          <div className={styles.failWrapper}>
            <div className={styles.close}>
              <Close onClick={() => handleOnClose(false)} />
            </div>
            <Fail className={`${styles.icon} ${styles.success}`} />
            <div className={styles.title} style={{ marginBottom: '0' }}>
              Invitation failed
            </div>
            <div className={styles.failText}>An error has occured, please try again.</div>
          </div>
        </div>
      ) : (
        // <div className={`${styles.modalContent} ${styles.loadingWrapper}`}>
        //   <Loading />
        //   <div className={styles.subText}>We&apos;re sending your invitations. Please wait...</div>
        // </div>
        null
      )}
    </Modal>
  );

  const MobileIbeSuccessModal = (
    <MobileModal
      id="mobile-ibeSuccess"
      onClose={() => handleOnClose(false)}
      show={show}
      outsideClickClose
      backdropStyle={{ backdropFilter: 'blur(2px)', zIndex: '10001', overflowY: 'hidden' }}
      contentStyle={{
        padding: '20px',
        minWidth: '375px'
      }}
    >
      {mailSuccess ? (
        <div className={styles.modalContent}>
          <div className={styles.mobileHeader}>
            <Success className={`${styles.icon} ${styles.success}`} />
            <div className={styles.title}>
              {`${invitedCount}/${scoredCount + invitedCount} have been successfully invited!`}
            </div>
            <div className={styles.close}>
              <Close onClick={() => handleOnClose(false)} />
            </div>
          </div>

          <div className={styles.text}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            These candidates can be seen as 'invited by e-mail' in the not scored stage.
          </div>
          <div className={styles.divider} />

          <div className={styles.mobileHeader}>
            <Info className={`${styles.icon} ${styles.info}`} />
            <div className={styles.title}>
              {`${scoredCount}/${scoredCount + invitedCount} have already been scored!`}
            </div>
          </div>

          <div className={styles.text}>
            These candidates can be seen in the&nbsp;
            {scoredCount !== 0 ? (
              <text
                className={`${styles.purple}`}
                onClick={() => {
                  redirectToScored();
                }}
              >
                scored stage.
              </text>
            ) : (
              <text>scored stage.</text>
            )}
          </div>
        </div>
      ) : rejected ? (
        <div>
          <div className={styles.failWrapper}>
            <div className={styles.close}>
              <Close onClick={() => handleOnClose(false)} />
            </div>
            <Fail className={`${styles.icon} ${styles.success}`} />
            <div className={styles.title} style={{ marginBottom: '0' }}>
              Invitation failed
            </div>
            <div className={styles.failText}>An error has occured, please try again.</div>
          </div>
        </div>
      ) : (
        // <div className={`${styles.modalContent} ${styles.loadingWrapper}`}>
        //   <Loading />
        //   <div className={styles.subText}>We&apos;re sending your invitations. Please wait...</div>
        // </div>
        null
      )}
    </MobileModal>
  );

  return <>{screenW > 576 ? WebIbeSuccessModal : MobileIbeSuccessModal}</>;
};

export default InviteCandSuccessModal;
