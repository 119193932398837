import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Tooltip from '../../../Common/Components/Tooltip';
import {
  assessmentDetailObjSelector,
  funnelInfoSelector,
  funnelOpenSelector,
  industryAvgSelector,
  selectedAcceptedCandidateListSelector,
  selectedCandidateListSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  setIsMoveEnabledFlag,
  setSelectedAcceptedCandidateList,
  setSelectedCandidateList
} from '../../../redux/assessmentDetailVol2/slice';
import {
  candidateFindIndex,
  dateFormatter,
  getParam,
  urlDynamicQueryUpdateLite,
  useWindowSize
} from '../../../utils/helpers';
import styles from './CandidateListComponent.module.css';
import error from './shield.svg';
import emailIcon from './ibe.svg';
import Checkbox from '../../../Common/RawCheckbox/Checkbox';
import TextOverflow from '../../../Common/TextOverflow/TextOverflow';
import dateIcon from '../FilterRow/Images/date.svg';
import lockIcon from '../CandidateWrapper/Images/lockedCand.svg';
import { ReactComponent as Unlock } from '../CandidateWrapper/Images/unlockCandidate.svg';
import CustomButton from '../../../Common/Components/CustomButton';
import HeadsUpModal from '../../../Auth/Modals/HeadsUpModal';
import { currentPlanRedux } from '../../../redux/plan/selectors';
import ContactSalesSuccess from '../../../Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import PurchaseAddOnSuccessModal from '../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import {
  newOpsCandidateCheckAction,
  unlockNewOpsCandidateAction
} from '../../../redux/candidateDetail/actions';
import { isCandidateAuthorizedSelector } from '../../../redux/candidateDetail/selectors';
import FreeTrialModal from '../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import mixpanel from '../../../utils/mixpanel';
import {
  mixpanelCandidateRelatedProperties,
  mixpanelReturnAssessmentDetailRelatedProperties,
  mixpanelReturnUserRelatedProperties
} from '../../../utils/mixpanelHelpers';
import { userSelector } from '../../../redux/auth/selectors';

const CandidateListComponent = ({
  item,
  index,
  enableSelectAllFlagState,
  disableSelectAllFlagState,
  isAcceptedCandidate = false,
  isRequestCandidate = false
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [checkBoxState, setCheckBoxState] = useState(false);
  const indAvgRedux = useSelector(industryAvgSelector);

  const selectedCandidateListRedux = useSelector(selectedCandidateListSelector);
  const selectedAcceptedCandidateListRedux = useSelector(selectedAcceptedCandidateListSelector);
  /**
   * If candidate is request candidate (hence accepted as well), perform operations based on selectedAcceptedCandidateListRedux
   * Else, perform based on selectedCandidateListRedux
   */
  const selectedList = isAcceptedCandidate
    ? selectedAcceptedCandidateListRedux
    : selectedCandidateListRedux;
  const setSelectedList = isAcceptedCandidate
    ? setSelectedAcceptedCandidateList
    : setSelectedCandidateList;

  const [screenWidth] = useWindowSize();
  const [isHovered, setHovered] = useState(false);
  const isFunnelOpen = useSelector(funnelOpenSelector);
  const [indAverageDiff, setIndAvgDiff] = useState(0);

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [purchasedSuccess, setPurchasedSuccess] = useState(false);
  const myPlan = useSelector(currentPlanRedux);
  const newOpsCandidateCheck = useSelector(isCandidateAuthorizedSelector);
  const [unlockLoading, setUnlockLoading] = useState(false);
  const [freeTrialLockedCandidate, setFreeTrialLockedCandidate] = useState(false);

  // Mixpanel Assessment Detail Candidate Click
  // #region
  const currentUser = useSelector(userSelector);
  const assessmentDetailRedux = useSelector(assessmentDetailObjSelector);
  const funnelsRedux = useSelector(funnelInfoSelector);

  function mixpanelCandidateClickEvent() {
    const userProps = mixpanelReturnUserRelatedProperties(currentUser);
    const assessmentProps = mixpanelReturnAssessmentDetailRelatedProperties(assessmentDetailRedux);
    const candidateProps = mixpanelCandidateRelatedProperties(item);
    const candidateFunnelName = funnelsRedux?.filter(e => e?.funnelId === item?.hiringStage)[0]
      ?.funnelName;
    const currentFunnelName =
      funnelsRedux?.filter(e => e?.funnelId === getParam(location.search, 'funnelId'))[0]
        ?.funnelName || 'Scored';

    mixpanel.track('Assessment Detail Candidate Click', {
      ...userProps,
      ...assessmentProps,
      ...candidateProps,
      'Candidate Funnel Name': candidateFunnelName,
      'Current Funnel Name': currentFunnelName
    });
  }

  // #endregion

  const fetchParametersFromUrl = () => {
    mixpanelCandidateClickEvent();

    urlDynamicQueryUpdateLite('candidateFlag', 'true', false);
    let params = '';
    // If candidate is an accepted request candidate an additional parameter "requestStatus" is added to URL
    if (item?.acceptStatus) {
      params = `?defaultAssessmentId=${id}&currentAssessmentId=${id}&${
        window.location.search.split('?')[1]
      }&requestStatus=${item?.acceptStatus}`;
    } else {
      params = `?defaultAssessmentId=${id}&currentAssessmentId=${id}&${
        window.location.search.split('?')[1]
      }`;
    }
    return `/candidate-detail/${item.userId}${params}`;
  };

  async function handleUnlockNewOpsCandidate() {
    // Covers free trial
    if (myPlan?.name === 'Free Trial') {
      setFreeTrialLockedCandidate(true);
    } else {
      // Checks if user is allowed to unlock this candidate
      await setUnlockLoading(true);
      await dispatch(
        newOpsCandidateCheckAction({
          UserId: item?.userId,
          CompanyAssessmentId: id
        })
      ).then(async res => {
        if (!res.payload.result) {
          // Not allowed: Payment modal or contact sales modal shown to the user
          await setPaymentModal(true);
          setTimeout(() => {
            setUnlockLoading(false);
          }, 400);
        } else {
          // Allowed: unlocks candidate
          await dispatch(
            unlockNewOpsCandidateAction({ userId: item?.userId, companyAssessmentId: id })
          ).then(() => {
            setUnlockLoading(false);

            history.push(fetchParametersFromUrl());
            window.dispatchEvent(new Event('moveCandidateToFunnel'));
          });
          // success
          // TO DO: refresh page
        }
      });
    }
  }

  useEffect(() => {
    if (purchasedSuccess) {
      history.push(fetchParametersFromUrl());
      window.dispatchEvent(new Event('moveCandidateToFunnel'));
    }
  }, [purchasedSuccess]);

  useEffect(() => {
    if (indAvgRedux && item.points !== -1) {
      const diff = Math.round((Math.abs(indAvgRedux - item.points) / indAvgRedux) * 100);
      if (indAvgRedux > item.points) {
        setIndAvgDiff(diff * -1);
      } else {
        setIndAvgDiff(diff);
      }
    }
  }, [indAvgRedux, item]);

  function handleChecked() {
    if (checkBoxState) {
      const index = candidateFindIndex(selectedList, item);
      if (index !== -1) {
        const innerArray = [...selectedList];
        innerArray.splice(index, 1);
        dispatch(setSelectedList(innerArray));
      }
    } else {
      const innerArray = [...selectedList];
      innerArray.push({ userId: item.userId, objStatusId: item.objStatusId });
      dispatch(setSelectedList(innerArray));
    }
  }

  useEffect(() => {
    /* 
      Both states are in following format: [state, setState]
    */
    if (enableSelectAllFlagState[0]) {
      setCheckBoxState(true);
    }
    if (disableSelectAllFlagState[0]) {
      setCheckBoxState(false);
    }
    enableSelectAllFlagState[1](null);
    disableSelectAllFlagState[1](null);
  }, [enableSelectAllFlagState[0], disableSelectAllFlagState[0]]);

  /* Candidate drag related methods/states */
  const [isDragStart, setIsDragStart] = useState(false);
  const onDragStart = () => {
    const index = candidateFindIndex(selectedList, item);
    if (index === -1) {
      const innerArray = [...selectedList];
      innerArray.push({ userId: item.userId, objStatusId: item.objStatusId });
      dispatch(setSelectedList(innerArray));
    }
    setIsDragStart(true);
  };

  useEffect(() => {
    if (isDragStart) {
      dispatch(setIsMoveEnabledFlag(true));
    }
  }, [isDragStart]);

  const onDragEnd = () => {
    setIsDragStart(false);
    dispatch(setIsMoveEnabledFlag(false));
  };

  const [elemWidth, setElemWidth] = useState(); // yazının sığması için max
  const [wrapperMax, setWrapperMax] = useState(); // row'un alabileceği max

  useEffect(() => {
    setWrapperMax(
      document.getElementById('candidateListWrapper')?.getBoundingClientRect()?.width - 70
    );
    if (
      (item?.name && item?.name?.trim() !== ''
        ? item?.name?.length > 25
        : item?.email?.length > 25) ||
      screenWidth <= 720
    ) {
      if (document.getElementById(`lockWrapper-${index}`)) {
        setElemWidth(
          document.getElementById(`candidateItemWrapper-${index}`)?.getBoundingClientRect()?.width -
            document.getElementById(`candidateItemRightPart-${index}`)?.getBoundingClientRect()
              ?.width -
            document.getElementById(`lockWrapper-${index}`)?.getBoundingClientRect()?.width -
            130
        );
      } else {
        setElemWidth(
          document.getElementById(`candidateItemWrapper-${index}`)?.getBoundingClientRect()?.width -
            document.getElementById(`candidateItemRightPart-${index}`)?.getBoundingClientRect()
              ?.width -
            130
        );
      }
    }
  }, [screenWidth, isFunnelOpen, isHovered, index, unlockLoading]);

  const Modals = (
    <>
      {/* START: Unlock candidate add on payment modals */}
      <HeadsUpModal
        addOnCode={40}
        show={paymentModal}
        onHide={() => setPaymentModal(false)}
        title={myPlan?.isCustom ? 'Hello there!' : 'Want to unlock?'}
        description={
          !myPlan?.isCustom
            ? `Unlock this candidate's profile for only ${
                newOpsCandidateCheck?.paymentCurrencyType === 10 ? '₺' : '$'
              } ${newOpsCandidateCheck?.totalPrice}.`
            : `Please contact our sales team to reach the candidate.`
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchasedSuccess}
        newOpData={{ userId: item?.userId, companyAssessmentId: id }}
      />

      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />

      {/* TO DO: refresh page */}
      <PurchaseAddOnSuccessModal
        show={purchasedSuccess}
        onHide={() => setPurchasedSuccess(false)}
        addOnCode={40}
      />
      <FreeTrialModal
        show={freeTrialLockedCandidate}
        onHide={() => setFreeTrialLockedCandidate(false)}
        addOnCode={40}
      />
      {/* END: Unlock candidate add on payment modals */}
    </>
  );

  const CheckboxConst = (
    <Checkbox
      isChecked={checkBoxState}
      setIsChecked={setCheckBoxState}
      size={16}
      parentUpdate={() => handleChecked()}
    />
  );

  const ScoreWrapper = (
    <div className={styles.scoreWrapper}>
      <div
        className={`${
          item?.points === -1
            ? styles.grayCircle
            : !indAvgRedux
            ? styles.yellowCircle
            : item?.points >= indAvgRedux
            ? styles.greenCircle
            : item?.points >= indAvgRedux * 0.8
            ? styles.yellowCircle
            : styles.redCircle
        } ${styles.circle}`}
      />
      <div className={styles.candScore}>{item?.points !== -1 ? item?.points : 'NA'}</div>
    </div>
  );

  const hoverableFlag = (screenWidth >= 960 || !isFunnelOpen) && screenWidth > 576;

  function industryAverageTextSelection() {
    if (
      item.points !== -1 &&
      indAvgRedux &&
      item.points >= indAvgRedux * 0.8 &&
      item.points < indAvgRedux
    ) {
      return (
        <>
          <div className={styles.indAverageText} style={{ color: '#FFD600' }}>
            Approximately&nbsp;
          </div>
          <div className={styles.indAverageText}>industry average</div>
        </>
      );
    }
    if (
      item.points !== -1 &&
      indAvgRedux &&
      (item.points < indAvgRedux * 0.8 || item.points >= indAvgRedux)
    ) {
      return (
        <>
          <div
            className={styles.indAverageText}
            style={{ color: indAverageDiff >= 0 ? '#4AC389' : '#FF7E62' }}
          >
            {Math.abs(indAverageDiff)}%&nbsp;{indAverageDiff >= 0 ? 'better' : 'worse'}
            &nbsp;
          </div>
          <div className={styles.indAverageText}>than industry average</div>
        </>
      );
    }
    if (item.points === -1 && indAvgRedux) {
      return (
        <>
          <div className={styles.indAverageText} style={{ color: '#FF7E62' }}>
            Not take
          </div>
          <div className={styles.indAverageText}>&nbsp;the assessment yet.</div>
        </>
      );
    }
    if (!indAvgRedux) {
      return <div className={styles.indAverageText}>Industry average not available.</div>;
    }

    return null;
  }

  const NameField = (
    <>
      {item?.objStatusId === 4 ? (
        <div className={styles.unlockCandidate} id={`lockWrapper-${index}`}>
          <div className={styles.lockWrapper}>
            <span className={styles.coensioCandidate}>Locked Candidate</span>
          </div>
        </div>
      ) : item?.objStatusId === 4 ? (
        <div
          onClick={e => {
            e.stopPropagation();
            handleUnlockNewOpsCandidate();
          }}
          className={styles.unlockCandidateMobile}
        >
          <img alt="" src={lockIcon} className={styles.lockIcon} />
          <Unlock className={styles.unlockIcon} />
        </div>
      ) : (
        <TextOverflow
          textStyle={{ filter: item.objStatusId === 4 && 'blur(10px)' }}
          width={elemWidth}
          textClassName={styles.name}
          text={item?.name && item?.name?.trim() !== '' ? item?.name : item?.email}
          itemId={`cand-${item.email}`}
        />
      )}
    </>
  );

  const AcceptedTag = (
    <>
      {isAcceptedCandidate && (
        <div className={styles.acceptedTag}>
          <span className={styles.acceptedText}>ACCEPTED</span>
        </div>
      )}
    </>
  );

  const IBETag = (
    <>
      {item?.arriveType === 10 && (
        <Tooltip position="top" text=" This candidate has been invited by e-mail.">
          <div className={styles.hoverStyle}>
            <img alt="" src={emailIcon} className={styles.error} />
          </div>
        </Tooltip>
      )}
    </>
  );

  const IsSuspectTag = (
    <>
      {item?.isSuspect &&
        item?.arriveType !== 10 &&
        (isHovered ? (
          <Tooltip
            position="top"
            text=" The candidate solved the assessment more than once with different email addresses. You can also take this into account when reviewing the candidate’s score."
            padding="8px"
            fontFamilyForText1="Jost-300"
          >
            <div className={styles.hoverStyle}>
              <img alt="" src={error} className={styles.error} />
            </div>
          </Tooltip>
        ) : (
          <div className={styles.hoverStyle}>
            <img alt="" src={error} className={styles.error} />
          </div>
        ))}
    </>
  );

  return (
    <div className={styles.wrapper}>
      {Modals}
      <div className={styles.itemWrapper}>
        {CheckboxConst}

        <div
          className={styles.candidateWrapper}
          style={{
            zIndex: isDragStart && '10000',
            background: isDragStart && '#f4f4f4'
          }}
          id={`candidateItemWrapper-${index}`}
          onClick={() => {
            history.push(fetchParametersFromUrl());
          }}
          onAuxClick={e => {
            e.preventDefault();
            window.open(fetchParametersFromUrl(), '_blank');
          }}
          draggable={!isMobile && 'true'}
          onDragStart={() => {
            if (!isMobile) {
              onDragStart();
              setCheckBoxState(true);
            }
          }}
          onDragEnd={() => {
            if (!isMobile) {
              onDragEnd();
            }
          }}
          key={index}
          onMouseEnter={() => {
            if (!isMobile) {
              setHovered(true);
            }
          }}
          onMouseLeave={() => {
            if (!isMobile) {
              setHovered(false);
            }
          }}
        >
          <div className={styles.leftPart}>
            <text className={styles.indexLabel}>
              {item?.objStatusId === 4 ? (
                <img alt="" src={lockIcon} className={styles.lock} />
              ) : (
                index + 1
              )}
            </text>
            <div className={styles.border} />
            {NameField}

            {AcceptedTag}

            {IBETag}

            {IsSuspectTag}
          </div>

          <div className={styles.rightPart} id={`candidateItemRightPart-${index}`}>
            <div style={{ display: 'flex' }}>
              {hoverableFlag ? (
                isHovered ? (
                  industryAverageTextSelection()
                ) : item.points !== -1 ? (
                  <div className={styles.dayText}>{dateFormatter(item?.addDate, true)}</div>
                ) : (
                  <>&nbsp;</>
                )
              ) : null}
            </div>
            {hoverableFlag && <div className={styles.border} />}
            {ScoreWrapper}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateListComponent;
