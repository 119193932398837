import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './RequestCandidate.module.css';
import RequestCandidateListComponent from './RequestCandidateListComponent';
import { useWindowSize } from '../../../utils/helpers';
import {
  requestAcceptedCandidateListSelector,
  requestDeclinedCandidateListSelector,
  requestListedCandidateListSelector,
  requestPendingCandidateListSelector
} from '../../../redux/assessmentDetailVol2/selectors';

const Tabs = ({ setSelectedTab, selectedTab }) => {
  const listedCandidatesRedux = useSelector(requestListedCandidateListSelector);
  const pendingCandidatesRedux = useSelector(requestPendingCandidateListSelector);
  const declinedCandidatesRedux = useSelector(requestDeclinedCandidateListSelector);

  return (
    <div className={styles.tabWrapper}>
      <div
        className={`${styles.tabContainer} ${selectedTab === 0 && styles.selectedTab}`}
        onClick={() => {
          setSelectedTab(0);
        }}
      >
        {`New (${listedCandidatesRedux?.length})`}
      </div>
      <div
        className={`${styles.tabContainer} ${selectedTab === 1 && styles.selectedTab}`}
        onClick={() => {
          setSelectedTab(1);
        }}
      >
        {`Pending (${pendingCandidatesRedux?.length})`}
      </div>
      <div
        className={`${styles.tabContainer} ${selectedTab === 2 && styles.selectedTab}`}
        onClick={() => {
          setSelectedTab(2);
        }}
      >
        {`Declined (${declinedCandidatesRedux?.length})`}
      </div>
    </div>
  );
};

export default function RequestCandidateWrapper() {
  const [hideShowClicked, setHideShowClicked] = useState(false);
  const [screenW, screenH] = useWindowSize();
  const assmId = JSON.stringify(useParams().id);

  const listedCandidatesRedux = useSelector(requestListedCandidateListSelector);
  const pendingCandidatesRedux = useSelector(requestPendingCandidateListSelector);
  const declinedCandidatesRedux = useSelector(requestDeclinedCandidateListSelector);

  const isHideClicked = sessionStorage.getItem(assmId) === 'closed';

  const [hidden, setHidden] = useState(isHideClicked);
  const [showCandidates, setShowCandidates] = useState(isHideClicked);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedList, setSelectedList] = useState(listedCandidatesRedux);
  const [selectedListForShow, setSelectedListForShow] = useState(true);
  const [selectedStatusForText, setSelectedStatusForText] = useState(0);

  function handleRenderedList() {
    if (selectedStatus === 0) {
      setSelectedList(listedCandidatesRedux);
    } else if (selectedStatus === 1) {
      setSelectedList(pendingCandidatesRedux);
    } else if (selectedStatus === 2) {
      setSelectedList(declinedCandidatesRedux);
    } else {
      setSelectedList([]);
    }
  }

  function handleCandidateShow() {
    setTimeout(() => {
      if (hidden) {
        setShowCandidates(false);
      } else {
        setShowCandidates(true);
      }
    }, 400);
  }

  useEffect(() => {
    setShowCandidates(false);
    setSelectedList([]);
    setSelectedStatusForText(selectedStatus);
    setTimeout(() => {
      handleRenderedList();
      handleCandidateShow();
    }, 150);
  }, [selectedStatus, hidden]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedList?.length > 0) {
        setSelectedListForShow(true);
      } else {
        setSelectedListForShow(false);
      }
    }, 50);
  }, [selectedList]);

  return (
    <>
      {/* TO-DO: redux state of Coensio Candidate length must be considered */}
      {/* CHANGE after new endpoint integration */}
      {listedCandidatesRedux?.length > 0 ||
      pendingCandidatesRedux?.length > 0 ||
      declinedCandidatesRedux?.length > 0 ? (
        <div className={styles.wrapper}>
          <div
            className={styles.container}
            style={{
              height:
                screenW >= 576
                  ? hidden
                    ? '54px'
                    : selectedList?.length === 0
                    ? `172px`
                    : `calc(46px + ${selectedList?.length * 57}px + 69px)`
                  : hidden
                  ? '52px'
                  : selectedList?.length === 0
                  ? `172px`
                  : `calc(42px + ${selectedList?.length * 50}px + 69px)`,
              borderBottom: hidden && '2px solid #7458D3'
            }}
          >
            <div className={styles.headerWrapper}>
              <div className={styles.headerTextWrapper}>
                <div className={styles.header}>
                  {screenW > 365 ? 'Recommended candidates by Coensio:' : 'Candidates by Coensio:'}
                </div>
              </div>
              <div
                className={styles.hideShowText}
                onClick={async () => {
                  if (!isHideClicked) {
                    sessionStorage.setItem(assmId, 'closed');
                  }
                  setHidden(x => !x);

                  await setHideShowClicked(true);
                  setTimeout(() => {
                    setHideShowClicked(false);
                  }, 300);
                }}
              >
                {hidden ? 'Show' : 'Hide'}
              </div>
            </div>

            <div
              style={{
                padding: '16px 0px',
                opacity: hidden ? '0' : '1',
                transition: 'opacity 0.1s linear'
              }}
            >
              <Tabs selectedTab={selectedStatus} setSelectedTab={setSelectedStatus} />
            </div>

            {selectedListForShow ? (
              <div
                className={styles.listWrapper}
                style={{
                  opacity: showCandidates ? '1' : '0',
                  transition: 'opacity 0.1s linear'
                }}
              >
                {selectedList?.map((cand, index) => (
                  <RequestCandidateListComponent
                    status={selectedStatusForText}
                    candidate={cand}
                    myKey={index}
                    keyPressed={hideShowClicked}
                    isHidden={hidden}
                    listLength={selectedList?.length}
                  />
                ))}
              </div>
            ) : null}

            {showCandidates && selectedList?.length === 0 ? (
              <div
                className={styles.listWrapper}
                style={{
                  opacity: hidden ? '0' : '1',
                  transition: 'opacity 0.1s linear'
                }}
              >
                <text className={styles.noCandText}>
                  {selectedStatusForText === 0
                    ? 'Stay tuned for new potential candidates! 🙌'
                    : selectedStatusForText === 1
                    ? 'The requested candidates will be seen here.'
                    : 'Candidates who declined the requests will be seen here.'}
                </text>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}
