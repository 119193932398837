import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../../../../Common/CustomModal/Modal';
import CloseButton from '../../../../Common/Components/CloseButton';
import CustomButton from '../../../../Common/Components/CustomButton';
import SearchBar from '../../../../Common/Components/SearchBar';
import Checkbox from '../../../../Common/Components/Checkbox';
import copyLink from '../../../../images/copyLink.svg';
import linkCopiedImg from '../../../../images/linkCopied.svg';
import invited from '../../../../images/invited.svg';
import styles from './ShareProfile.module.css';
import { ReactComponent as Add } from './addUser.svg';
import {
  userList,
  HMUserList,
  invitedHMList,
  inviteUserShow,
  newlyInvitedIDList,
  allUserListSelector,
  invitedAllUserListSelector
} from '../../../../redux/assessmentsVol2/selectors';
import { setInviteUserShow } from '../../../../redux/assessmentsVol2/slice';
import {
  getUserListByCompanyAction,
  sendEmailForReviewAction
} from '../../../../redux/assessmentsVol2/actions';
import { teamRedux, userSelector } from '../../../../redux/auth/selectors';
import { ReactComponent as Success } from '../../../../images/SFR_success.svg';
import InviteUserModal from './InviteUserModal';
import { shareProfileLinkAction } from '../../../../redux/candidateDetail/actions';
import { getTeamAction } from '../../../../redux/auth/actions';
import ShortText from '../../../../Common/Components/ShortText';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import { ReactComponent as ShareIcon } from './exportLink.svg';
import { genericMixpanelCandidateDetailEventGenerator, mixpanelReturnCandidateDetailProperties } from '../helpers';
import {
  candidatePositionsSelector,
  candidateProfileSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../../redux/candidateDetail/selectors';
import mixpanel from '../../../../utils/mixpanel';

export default function ShareProfileModal({ show, onCloseModal, ...props }) {
  const [selectedHRlist, setSelectedHRlist] = useState([]);
  const newlyInvitedList = useSelector(newlyInvitedIDList);
  const newUserList = useSelector(userList);
  const inviteShow = useSelector(inviteUserShow);
  const [searchParam, setSearchParam] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [emailsReady, setEmailsReady] = useState(false);
  const [windowW, windowH] = useWindowSize();

  // Team
  const allUsers = useSelector(allUserListSelector);
  const invitedAllUsers = useSelector(invitedAllUserListSelector);

  // Mixpanel Share Profile - Send Button Click
  // #region
  const positionsList = useSelector(candidatePositionsSelector);
  const candidateProfile = useSelector(candidateProfileSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  function mixpanelSendButtonEvent() {
    const candDetProps = mixpanelReturnCandidateDetailProperties(
      user,
      positionsList,
      candidateProfile,
      loadingForPersonalInfoRedux,
      personalInfoExperienceRedux,
      personalInfoNationalityRedux,
      personalInfoSalaryRedux,
      personalInfoWorkTypeRedux
    );
    let hmCount = 0;
    let recCount = 0;

    selectedHRlist?.forEach(selected => {
      allUsers
        ?.filter(x => x?.email === selected)
        ?.forEach(x => {
          if (x?.userGroupType === 'Recruiter') {
            recCount += 1;
          } else if (x?.userGroupType === 'Hiring Manager') {
            hmCount += 1;
          }
        });
    });


    mixpanel.track('Share Profile - Send Button Click', {
      ...candDetProps,
      'Shared Hiring Manager Count': hmCount,
      'Shared Recruiter Count': recCount,
    })
  }
  // #endregion

  // Mixpanel Share Profile - Copy Link Click
  // #region
  function mixpanelCopyLinkEvent() {
    genericMixpanelCandidateDetailEventGenerator('Share Profile - Copy Link Click', user, positionsList, candidateProfile, loadingForPersonalInfoRedux, personalInfoExperienceRedux, personalInfoNationalityRedux, personalInfoSalaryRedux, personalInfoWorkTypeRedux)
  }
  // #endregion


  function copyToClipBoard(copyMe) {
    navigator.clipboard.writeText(copyMe);

    mixpanelCopyLinkEvent();
    setCopySuccess(true);
  }

  async function handleShareProfile() {
    const link = window.location.href;
    const data = {
      profileLink: link,
      emails: selectedHRlist,
      userId: id,
      companyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };
    mixpanelSendButtonEvent();

    const response = await dispatch(shareProfileLinkAction(data));
    if (response.payload) {
      setSuccess(true);
      window.dispatchEvent(new Event('fetchActivites'));
    }
    setEmailsReady(false);
    setButtonLoading(false);
  }

  useEffect(() => {
    if (user && show) {
      dispatch(getUserListByCompanyAction({ CompanyId: user.companyId }));
    }
    return () => {
      setSelectedHRlist([]);
      setSuccess(false);
    };
  }, [user, show]);

  function handleCheckbox(hr) {
    let temp = [...selectedHRlist];
    if (selectedHRlist.includes(hr)) {
      temp = temp.filter(e => e !== hr);
      setSelectedHRlist(temp);
    } else {
      setSelectedHRlist(prev => [...prev, hr]);
    }
  }

  useEffect(() => {
    setButtonLoading(false);
  }, []);

  async function handleList() {
    setButtonLoading(true);
    if (newlyInvitedList?.length > 0) {
      await newlyInvitedList?.forEach(id => {
        const email = newUserList?.filter(user => user?.id === id)[0]?.email;
        setSelectedHRlist(prev => [...prev, email]);
      });
    }
    await setEmailsReady(true);
  }

  useEffect(() => {
    if (emailsReady) {
      handleShareProfile();
    }
  }, [emailsReady]);

  const WebModal = (
    <Modal
      modalId="share-profile-modal"
      outsideClickClose
      centerHorizontally
      centerVertically
      show={show}
      onClose={onCloseModal}
      size={{ width: '572px' }}
      contentStyle={{
        background: '#FFFFFF',
        borderRadius: '16px',
        padding: '23px 28px'
      }}
      backdropStyle={{ overflow: 'hidden' }}
    >
      <InviteUserModal show={inviteShow} onHide={() => dispatch(setInviteUserShow(false))} />
      <div className={`${styles.ModalWrapper} ${inviteShow && styles.blurred}`}>
        <div className={styles.header}>
          {!success && <div className={styles.headerTitle}>Share Profile</div>}
          <CloseButton onClick={onCloseModal} size="small" />
        </div>

        {success ? (
          <>
            <div className={styles.successContainer}>
              <Success className={styles.successIcon} />
              <div className={styles.successTitle}>Successfully Sent!</div>
              <div className={styles.successSubtitle}>
                Invitation for the assessment review has been sent to the selected users.
              </div>
              <div className={styles.successFootNote}>
                *You will be notified by e-mail about the changes made in the assessment.
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.lineWrapper}>
              <SearchBar
                size="line"
                isExtended={1}
                color="gray"
                inputFieldPlaceholder="Search"
                inputFieldOnChange={event => {
                  setSearchParam(event.target.value);
                }}
                inputFieldValue={searchParam}
                extendedStyle={{
                  width: '100%',
                  fontSize: '16px',
                  color: 'rgba(57, 57, 57, 0.5)',
                  height: '36px'
                }}
              />
              <CustomButton
                customStyle={{ height: '36px', fontSize: '16px' }}
                Icon={Add}
                textField="Add new user"
                type={5}
                buttonOnClick={() => dispatch(setInviteUserShow(true))}
              />
            </div>

            <div className={styles.hrList}>
              {allUsers?.map(
                (user, key) =>
                  user.nameSurname
                    .toString()
                    .toLowerCase()
                    .includes(searchParam.toLocaleLowerCase()) && (
                    <div className={styles.checkboxRow}>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox
                          myOnClick={() => handleCheckbox(user.email)}
                          myChecked={
                            selectedHRlist.includes(user.email) ||
                            newlyInvitedList.includes(user.id)
                          }
                          myDisabled={newlyInvitedList.includes(user.id)}
                        />
                      </div>
                      <div
                        className={`${styles.hrText} ${
                          newlyInvitedList.includes(user.id) && styles.disabledHR
                        }`}
                        key={key}
                      >
                        <ShortText name={user?.nameSurname} staticLimit={32} />
                      </div>
                      {newlyInvitedList.includes(user.id) && styles.disabledHR && (
                        <div className={styles.invitedWrapper}>
                          <span className={styles.imgWrapper}>
                            <img alt="" src={invited} />
                          </span>
                          <span className={styles.invitedText}>New user invitation sent!</span>
                        </div>
                      )}
                      {JSON.stringify(invitedAllUsers).includes(user.id) &&
                        !newlyInvitedList.includes(user.id) && (
                          <div className={styles.invitedWrapper}>
                            <span className={styles.imgWrapper}>
                              <img alt="" src={invited} />
                            </span>
                            <span className={styles.invitedText}>Invited!</span>
                          </div>
                        )}
                    </div>
                  )
              )}
            </div>
            <div className={styles.linkRow}>
              <div
                className={styles.copyLink}
                onClick={() => copyToClipBoard(window.location.href)}
              >
                <span className={styles.imgWrapper}>
                  <img
                    style={{ height: '100%', width: '100%' }}
                    alt=""
                    src={copySuccess ? linkCopiedImg : copyLink}
                  />
                </span>
                <span>{copySuccess ? 'Link Copied!' : 'Copy Link'}</span>
              </div>
              <div className={styles.sendButton}>
                <CustomButton
                  textField="Send"
                  buttonOnClick={async () => {
                    // eslint-disable-next-line no-restricted-syntax
                    handleList();
                  }}
                  size="small"
                  isDisabled={selectedHRlist.length === 0 && newlyInvitedList.length === 0}
                  loading={buttonLoading}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );

  const MobilModal = (
    <MobileModal
      id="mobile-shareProfile-modal"
      onClose={onCloseModal}
      show={show}
      outsideClickClose
      contentStyle={{
        padding: '25px',
        height: `413px`,
        minWidth: '375px',
        overflow: 'hidden'
      }}
    >
      <InviteUserModal show={inviteShow} onHide={() => dispatch(setInviteUserShow(false))} />
      <div className={`${styles.ModalWrapper} ${inviteShow && styles.blurred}`}>
        <div className={styles.header}>
          {!success && (
            <div className={styles.headerWrapper}>
              <ShareIcon /> <div className={styles.headerTitle}>Share Profile</div>
            </div>
          )}
          <CloseButton onClick={onCloseModal} size="small" />
        </div>

        {success ? (
          <>
            <div className={styles.successContainer}>
              <Success className={styles.successIcon} />
              <div className={styles.successTitle}>Successfully Sent!</div>
              <div className={styles.successSubtitle}>
                Invitation for the assessment review has been sent to the selected users.
              </div>
              <div className={styles.successFootNote}>
                *You will be notified by e-mail about the changes made in the assessment.
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.lineWrapper}>
              <SearchBar
                size="line"
                isExtended={1}
                color="white"
                inputFieldPlaceholder="Search"
                inputFieldOnChange={event => {
                  setSearchParam(event.target.value);
                }}
                inputFieldValue={searchParam}
                extendedStyle={{
                  width: '100%',
                  fontSize: '16px',
                  border: '1px solid rgba(57, 57, 57, 0.15)',
                  borderRadius: '8px',
                  color: 'rgba(57, 57, 57, 0.5)',
                  height: '36px'
                }}
              />
              <CustomButton
                customStyle={{ height: '36px', fontSize: '16px' }}
                Icon={Add}
                textField="Add"
                type={5}
                buttonOnClick={() => dispatch(setInviteUserShow(true))}
              />
            </div>

            <div className={styles.hrList}>
              {allUsers?.map(
                (user, key) =>
                  user.nameSurname
                    .toString()
                    .toLowerCase()
                    .includes(searchParam.toLocaleLowerCase()) && (
                    <div className={styles.checkboxRow}>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox
                          myOnClick={() => handleCheckbox(user.email)}
                          myChecked={
                            selectedHRlist.includes(user.email) ||
                            newlyInvitedList.includes(user.id)
                          }
                          myDisabled={newlyInvitedList.includes(user.id)}
                        />
                      </div>
                      <div
                        className={`${styles.hrText} ${
                          newlyInvitedList.includes(user.id) && styles.disabledHR
                        }`}
                        key={key}
                      >
                        <ShortText
                          name={user?.nameSurname}
                          staticLimit={
                            windowW < 420
                              ? 15
                              : windowW < 440
                              ? 20
                              : windowW < 480
                              ? 27
                              : windowW < 510
                              ? 33
                              : 38
                          }
                        />
                      </div>
                      {newlyInvitedList.includes(user.id) && styles.disabledHR && (
                        <div className={styles.invitedWrapper}>
                          <span className={styles.imgWrapper}>
                            <img alt="" src={invited} />
                          </span>
                          <span className={styles.invitedText}>Invitation sent!</span>
                        </div>
                      )}
                      {JSON.stringify(invitedAllUsers).includes(user.id) &&
                        !newlyInvitedList.includes(user.id) && (
                          <div className={styles.invitedWrapper}>
                            <span className={styles.imgWrapper}>
                              <img alt="" src={invited} />
                            </span>
                            <span className={styles.invitedText}>Invited!</span>
                          </div>
                        )}
                    </div>
                  )
              )}
            </div>
            <div className={styles.linkRow}>
              <CustomButton
                textField={copySuccess ? 'Link Copied!' : 'Copy Link'}
                type={8}
                buttonOnClick={() => {
                  copyToClipBoard(window.location.href);
                }}
                size="mobile"
              />
              <CustomButton
                textField="Send"
                buttonOnClick={async () => {
                  // eslint-disable-next-line no-restricted-syntax
                  handleList();
                }}
                size="mobile"
                isDisabled={selectedHRlist.length === 0 && newlyInvitedList.length === 0}
                loading={buttonLoading}
              />
            </div>
          </>
        )}
      </div>
    </MobileModal>
  );

  return <>{windowW > 576 ? <>{WebModal}</> : <>{MobilModal}</>}</>;
}
