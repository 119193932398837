import React, { useEffect, useState } from 'react';
// import Multiselect from 'multiselect-react-dropdown';
import { isMobile } from 'react-device-detect';
import Dropdown from 'react-dropdown';
import styles from './UnlabeledSingleSelectDropdown.module.css';

const UnlabeledSingleSelectDropdown = ({
  list,
  placeholder,
  selectedValue,
  setSelectedValue,
  wrapperStyle,
  isResponsive = false,
  disableDefaultOnClick = false,
  optionalOnClick = () => {},
}) => {
  return (
    <div style={wrapperStyle} onClick={!isMobile && optionalOnClick} onTouchStart={isMobile && optionalOnClick}>
      <Dropdown
        className={isResponsive ? styles.DropdownClassName : styles.DropdownClassNamePx}
        controlClassName={isResponsive ? styles.ControlClassName : styles.ControlClassNamePx}
        placeholderClassName={
          isResponsive ? styles.PlaceholderClassName : styles.PlaceholderClassNamePx
        }
        menuClassName={isResponsive ? styles.MenuClassName : styles.MenuClassNamePx}
        arrowClassName={isResponsive ? styles.ArrowClassName : styles.ArrowClassNamePx}
        //   arrowClosed={<span className="arrow-closed" />}
        //   arrowOpen={<span className="arrow-open" />}
        options={list}
        onChange={setSelectedValue}
        value={selectedValue}
        placeholder={placeholder}
        disabled={disableDefaultOnClick}
      />
    </div>
  );
};
export default UnlabeledSingleSelectDropdown;
