import React, { useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import styles from './FilterModal.module.css';
import downArrow from './down-arrow.svg';
import upArrow from './up-arrow.svg';

export default function FilterItemScore({
  filterName,
  openItem,
  setOpenItem = () => {},
  scores,
  setScores = () => {}
}) {
  // Score Filter Variables
  const [scoreMin, setScoreMin] = useState(scores[0]);
  const [scoreMax, setScoreMax] = useState(scores[1]);
  const [scoreRange, setScoreRange] = useState({
    min: 0,
    max: 100
  });

  useEffect(() => {
    if (scores) {
      setScoreMin(scores[0]);
      setScoreMax(scores[1]);
      setScoreRange({ min: scores[0], max: scores[1] });
    }
  }, [scores]);


  function setMinVal() {
    if (scoreMin <= scoreMax) {
      setScores([scoreMin, scoreMax]);
      setScoreRange({ ...scoreRange, min: scoreMin });
    } else {
      setScoreMin(scoreMax);
      setScores([scoreMax, scoreMax])
      setScoreRange({ ...scoreRange, min: scoreMax });
    }
  }

  function setMaxVal() {
    if (scoreMax >= scoreMin) {
      setScores([scoreMin, scoreMax]);
      setScoreRange({ ...scoreRange, max: scoreMax});
    } else {
      setScoreMax(scoreMin)
      setScores([scoreMin, scoreMin]);
      setScoreRange({ ...scoreRange, max: scoreMin});
    }
  }


  return (
    <div className={styles.itemContainer}>
      <div
        className={styles.innerHeaderWrapper}
        onClick={() => {
          if (openItem === filterName) {
            setOpenItem('');
          } else {
            setOpenItem(filterName);
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span className={styles.itemHeader}>Filter Candidates by&nbsp;</span>
          <span className={styles.itemHeaderBold}>{filterName}</span>
        </div>
        <div className={styles.rightHeaderWrapper}>
          {scoreMin !== 0 || scoreMax !== 100 ? <div className={styles.yellowIcon} /> : null}
          <span className={styles.icon}>
            <img alt="" src={openItem === filterName ? upArrow : downArrow} />
          </span>
        </div>
      </div>
      {openItem === filterName ? (
        <div className={styles.scoreContainer}>
          <input
            style={{ marginRight: '1.6rem' }}
            id="scoreMinInput"
            className={styles.inputBox}
            type="number"
            value={scoreMin}
            min={0}
            max={100}
            onBlur={setMinVal}
            onKeyDown={e => {
              if (e.keyCode === 13) { // Enter
                setMinVal();
              }
            }}
            onChange={e => {
              if (parseInt(e.target.value, 10) > 100) {
                setScoreMin(100);
              } else {
                setScoreMin(parseInt(e.target.value, 10));
              }
            }}
          />
          <div className={styles['range-input']}>
            <InputRange
              step={1}
              maxValue={100}
              minValue={0}
              value={scoreRange}
              onChangeStart={value => {
                if (isNaN(value.min)) {
                  setScores([0, scoreMax]);
                  setScoreMin(0);
                  setScoreRange({ min: 0, max: scoreMax });
                }
                if (isNaN(value.max)) {
                  setScores([scoreMin, 0]);
                  setScoreMax(0);
                  setScoreRange({ min: scoreMin, max: 0 });
                }
              }}
              onChange={value => {
                setScores([value.min, value.max]);
                setScoreMin(value.min);
                setScoreMax(value.max);
                setScoreRange(value);
              }}
            />
          </div>
          <input
            style={{ marginLeft: '1.6rem' }}
            id="scoreMaxInput"
            className={styles.inputBox}
            type="number"
            value={scoreMax}
            min={0}
            max={100}
            onBlur={setMaxVal}
            onKeyDown={e => {
              if (e.keyCode === 13) { // Enter
                setMaxVal();
              }
            }}
            onChange={e => {
              if (parseInt(e.target.value, 10) > 100) {
                setScoreMax(100);
              } else {
                setScoreMax(parseInt(e.target.value, 10));
              }
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
