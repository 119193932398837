import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { loginPageErrorFlagRedux, loginPageErrorMessageRedux } from '../../../redux/auth/selectors';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD, useWindowSize } from '../../../utils/helpers';
import LoginComponent from '../../Components/Login/LoginComponent';
import LoginMobile from '../../Components/Login/LoginMobile';
import styles from './LoginPage.module.css';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';
import { resetLoginPageErrorFlag, resetLoginPageErrorMessage } from '../../../redux/auth/slice';
import { resetToInitial } from '../../../redux/signup/slice';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [width, height] = useWindowSize();
  const errorFlag = useSelector(loginPageErrorFlagRedux);
  const errorMessage = useSelector(loginPageErrorMessageRedux);

  useEffect(() => {
    dispatch(resetLoginPageErrorFlag());
    dispatch(resetLoginPageErrorMessage());
    dispatch(resetToInitial());
  }, []);

  return (
    <div className={styles.ContentBody}>
      {/* {width > ASSESSMENT_FLOW_MOBILE_THRESHOLD ? <LoginComponent /> : <LoginMobile />} */}
      <LoginComponent />
      <InfoBanner
        text={errorMessage}
        show={errorFlag}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(resetLoginPageErrorFlag());
          dispatch(resetLoginPageErrorMessage());
        }}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
    </div>
  );
};

export default LoginPage;
