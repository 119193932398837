import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addCandidatesToFunnelService,
  createNoteService,
  deleteNoteService,
  getActivitiesService,
  getCandidateBackgroundService,
  getCandidateCVService,
  getCandidatePositionsService,
  getCandidateProfileService,
  getCandidateSolutionsService,
  getFilteredUserIdService,
  getNotebyIdService,
  getNotesService,
  getPersonalInformationService,
  newOpsCandidateCheckService,
  sendRequestService,
  shareProfileLinkService,
  unlockNewOpsCandidateService,
  updateNoteService
} from './service';

export const getCandidateProfileAction = createAsyncThunk(
  'candidateDetail/getCandidateProfile',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateProfileService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidatePositionsAction = createAsyncThunk(
  'candidateDetail/getCandidatePositions',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidatePositionsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPositionsForCurrentFunnelListAction = createAsyncThunk(
  'candidateDetail/getPositionsForCurrentFunnelList',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidatePositionsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const refreshCandidatePositionsAction = createAsyncThunk(
  'candidateDetail/refreshCandidatePositions',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidatePositionsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addCandidatesToFunnelInCandidateDetailAction = createAsyncThunk(
  'candidateDetail/addCandidatesToFunnelInCandidateDetail',
  async (data, thunkAPI) => {
    try {
      const response = await addCandidatesToFunnelService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unlockNewOpsCandidateAction = createAsyncThunk(
  'candidateDetail/unlockNewOpsCandidate',
  async (data, thunkAPI) => {
    try {
      const response = await unlockNewOpsCandidateService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const newOpsCandidateCheckAction = createAsyncThunk(
  'candidateDetail/unlockNewOpsCandidate',
  async (data, thunkAPI) => {
    try {
      const response = await newOpsCandidateCheckService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNotesAction = createAsyncThunk(
  'candidateDetail/getNotes',
  async (data, thunkAPI) => {
    try {
      const response = await getNotesService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createNoteAction = createAsyncThunk(
  'candidateDetail/createNote',
  async (data, thunkAPI) => {
    try {
      const response = await createNoteService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateNoteAction = createAsyncThunk(
  'candidateDetail/updateNote',
  async (data, thunkAPI) => {
    try {
      const response = await updateNoteService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteNoteAction = createAsyncThunk(
  'candidateDetail/deleteNote',
  async (data, thunkAPI) => {
    try {
      const response = await deleteNoteService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNotebyIdAction = createAsyncThunk(
  'candidateDetail/getNotebyId',
  async (data, thunkAPI) => {
    try {
      const response = await getNotebyIdService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateSolutionsAction = createAsyncThunk(
  'candidateDetail/getCandidateSolutions',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateSolutionsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const shareProfileLinkAction = createAsyncThunk(
  'candidateDetail/shareProfileLink',
  async (data, thunkAPI) => {
    try {
      const response = await shareProfileLinkService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateBackgroundAction = createAsyncThunk(
  'candidateDetail/getCandidateBackground',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateBackgroundService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateCVAction = createAsyncThunk(
  'candidateDetail/getCandidateCV',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateCVService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFilteredUserIdAction = createAsyncThunk(
  'candidateDetail/getFilteredUserId',
  async (data, thunkAPI) => {
    try {
      const response = await getFilteredUserIdService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPersonalInformationAction = createAsyncThunk(
  'candidateDetail/getPersonalInformation',
  async (data, thunkAPI) => {
    try {
      const response = await getPersonalInformationService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getActivitiesAction = createAsyncThunk(
  'candidateDetail/getActivities',
  async (data, thunkAPI) => {
    try {
      const response = await getActivitiesService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendRequestAction = createAsyncThunk(
  'sendRequest',
  async (data, thunkAPI) => {
    try {
      const resp = await sendRequestService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);