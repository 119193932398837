import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
  snapCenterToCursor
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  setClickedFunnel,
  setFunnelOpen,
  setPopupShowState,
  setPopupTypeState
} from '../../../redux/assessmentDetailVol2/slice';
import styles from './Funnel.module.css';
import FunnelItem from './FunnelItem';
import FunnelPopup from './FunnelPopup';
import funnelClose from './headerClose.svg';
import CloseButton from '../../../Common/Components/CloseButton';
import { useWindowSize } from '../../../utils/helpers';
import { updateFunnelPriorityAction } from '../../../redux/assessmentDetailVol2/actions';
import { isMoveEnabledFlagSelector } from '../../../redux/assessmentDetailVol2/selectors';

export default function FunnelCategory({
  categoryOrder,
  funnels,
  sortableFunnels,
  draggable = false,
  isMobile = false,
  mobileCloseFunnel = () => {}
}) {
  const dispatch = useDispatch();
  const itemIds = useMemo(() => sortableFunnels?.map(item => item.funnelId), [sortableFunnels]);

  const [sortables, setSortables] = useState(sortableFunnels);

  useEffect(() => {
    if (sortableFunnels) {
      setSortables(sortableFunnels);
    }
  }, [sortableFunnels]);

  const name =
    categoryOrder === 10
      ? 'Hiring Funnel'
      : categoryOrder === 20
      ? 'New'
      : categoryOrder === 30
      ? 'In Review'
      : 'Concluded';

  const [screenWidth, screenHeight] = useWindowSize();
  const [dragStarted, setDragStarted] = useState(false);

  function handleDragEnd(event) {
    const { active, over } = event;
    const temp = [];
    const detailedTemp = [];

    if (active.id !== over.id) {
      const activeIndex = sortableFunnels.findIndex(a => a.funnelId === active.id);
      const overIndex = sortableFunnels.findIndex(a => a.funnelId === over.id);
      const sortedArray = arrayMove(sortableFunnels, activeIndex, overIndex);

      sortedArray.forEach((sFunnel, index) => {
        temp.push({
          priority: index + 1,
          id: sFunnel.funnelId,
          name: sFunnel.funnelName
        });
        detailedTemp.push({
          priority: index + 1,
          funnelId: sFunnel.funnelId,
          funnelName: sFunnel.funnelName,
          funnelCategory: 30,
          userCount: sFunnel.userCount
        });
      });

      setSortables(detailedTemp);
      dispatch(updateFunnelPriorityAction({ funnelPriorities: temp }));
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // delay: 120,
        distance: 5
        // tolerance: 5
      }
    })
  );

  const SortableItem = ({ id, item }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: id
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition
    };

    return (
      <div
        id={`${item.funnelId}-mySortedFunnel`}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <FunnelItem updateInAction={dragStarted} funnel={item} />
      </div>
    );
  };

  const handleNewStageClicked = () => {
    dispatch(setPopupTypeState('Add'));
    dispatch(setClickedFunnel(null));
    dispatch(setPopupShowState(true));
  };

  const isMoveEnabledRedux = useSelector(isMoveEnabledFlagSelector);

  return (
    <>
      <div className={styles['category-container']}>
        <div className={styles.closeWrapper}>
          <div className={styles['category-name']}>{name}</div>
          {categoryOrder === 10 && screenWidth <= 960 && !isMobile ? (
            <CloseButton width={28} height={28} onClick={() => dispatch(setFunnelOpen(false))} />
          ) : null}
        </div>
        {categoryOrder === 30 && !isMoveEnabledRedux && draggable ? (
          <DndContext
            onDragStart={() => setDragStarted(true)}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={e => {
              handleDragEnd(e);
              setDragStarted(false);
            }}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext strategy={verticalListSortingStrategy} items={itemIds}>
              {sortables
                ?.sort((a, b) => a.priority - b.priority)
                ?.map((sFunnel, sIndex) => (
                  <SortableItem key={sIndex} id={sFunnel.funnelId} item={sFunnel} />
                ))}
            </SortableContext>
          </DndContext>
        ) : (
          <div className={styles.itemWrapper}>
            {funnels
              ?.filter(element => element.funnelCategory === categoryOrder)
              .sort((a, b) => a.priority - b.priority)
              .map(item => (
                <FunnelItem
                  funnel={item}
                  isMobile={isMobile}
                  mobileCloseFunnel={mobileCloseFunnel}
                />
              ))}
          </div>
        )}
        {categoryOrder === 30 ? (
          <div onClick={handleNewStageClicked} className={styles.newStageButton}>
            Add a New Stage
          </div>
        ) : null}
      </div>
    </>
  );
}
