export default {
  detail: {},
  createDetail: {},
  candidateDetail: {},
  isLoading: false,
  reason: '',
  disableButton: true,
  candidateFunnelInfo: { funnelId: '', funnelName: '', isScored: false },
  inviteCandidatesModalShow: false,
  assessmentSettingInfo: {},
  copiedState: false, // Assessment Settings Popup
  settingsSuccess: false,
  settingsError: false,
  settingsBannerMessage: '',
  funnelCounts: null,
};
