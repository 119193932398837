import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import SkillBasedLeftColumn from './SkillBasedLeftColumn';
import styles from '../Styles/CustomAssessmentBody.module.css';
import SkillBasedMCandFTModals from '../Modals/SkillBasedMCandFTModals';
import AssessmentAddedPopup from './AssessmentAddedPopup';
import AddCodingModal from '../Modals/AddCodingModal';
import { addSkillBasedErrorMessageRedux, addSkillBasedErrorRedux, questionBeingAdded } from '../../../redux/assessmentsVol2/selectors';
import { setAddSkillBasedError, setAddSkillBasedErrorMessage, setQuestionBeingAdded } from '../../../redux/assessmentsVol2/slice';
import { viewHeight } from '../../../redux/user/selectors';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';

export default function SkillBasedAssessmentBody(props) {
  const { search } = useLocation();
  const [show, setShow] = useState(false);
  // eslint-disable-next-line react/destructuring-assignment

  const skillBasedMCandFTPopup = useSelector(questionBeingAdded);
  const dispatch = useDispatch();
  const height = useSelector(viewHeight);
  const name = new URLSearchParams(search).get('type');
  const { id } = useParams(); // Candidate Id'yi çekiyor
  const history = useHistory();

  const addSkillBasedErrorState = useSelector(addSkillBasedErrorRedux);
  const addSkillBasedErrorMessage = useSelector(addSkillBasedErrorMessageRedux);

  return (
    <section className={styles.contentBody} style={{ height: `calc(100vh - ${height}px)` }}>
      <div className={styles.pageWrapper}>
        <SkillBasedMCandFTModals
          show={
            skillBasedMCandFTPopup &&
            (skillBasedMCandFTPopup === 'Multiple Select' || skillBasedMCandFTPopup === 'Free-Text')
          }
          onHide={() => {
            dispatch(setQuestionBeingAdded(null));
          }}
          type={skillBasedMCandFTPopup}
        />
        <AddCodingModal
          show={skillBasedMCandFTPopup && skillBasedMCandFTPopup === 'Coding'}
          onHide={() => {
            dispatch(setQuestionBeingAdded(null));
          }}
        />
        <SkillBasedLeftColumn />
        {show ? <AssessmentAddedPopup /> : null}
        
        <InfoBanner 
          text={addSkillBasedErrorMessage}
          show={addSkillBasedErrorState}
          CustomIcon={Error}
          setStatus={type => {
            dispatch(setAddSkillBasedError(null));
            dispatch(setAddSkillBasedErrorMessage(null));
          }}
        />
      </div>
    </section>
  );
}
