import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-dropdown/style.css';
import QuestionDetails from '../Components/questionDetails';
import CloseButton from '../../../Common/Components/CloseButton';
import { setInitial } from '../../../redux/addQuestion/slice';
import InformationPopup from '../Modals/InformationPopup';
import styles from '../Styles/Sql.module.css';
import {
  assessmentLabelRedux,
  categoryRedux,
  difficultyRedux,
  isPassedVerificationRedux,
  isSuccessRedux,
  languageRedux,
  limitRedux,
  questionDescriptionRedux,
  sqlCreateTableCodeRedux,
  sqlQueryTypeRedux,
  sqlTestCasesRedux,
  sqlValidCasesRedux,
  sqlVerificationCodeRedux,
  statusRedux,
  subcategoryRedux,
  titleRedux
} from '../../../redux/addQuestion/selectors';
import SqlSignature from '../Components/sqlSignature';
import SqlTestCases from '../Components/sqlTestCases';
import SqlVerificationCode from '../Components/sqlVerificationCode';
import QuestionDescTextEditor from '../Components/QuestionDescTextEditor';
import { createSqlQuestionAction } from '../../../redux/addQuestion/actions';
import Header from '../../../Common/Components/Header';

function SqlQuestionPage() {
  const dispatch = useDispatch();

  // Selectors
  const reduxCategory = useSelector(categoryRedux);
  const reduxAssessmentLabel = useSelector(assessmentLabelRedux);
  const reduxSubcategory = useSelector(subcategoryRedux);
  const reduxTitle = useSelector(titleRedux);
  const reduxDifficulty = useSelector(difficultyRedux);
  const reduxLanguage = useSelector(languageRedux);
  const reduxLimit = useSelector(limitRedux);
  const reduxStatus = useSelector(statusRedux);

  const reduxQuestionDescription = useSelector(questionDescriptionRedux);

  const reduxIsSuccess = useSelector(isSuccessRedux);

  const reduxIsPassedVerification = useSelector(isPassedVerificationRedux);

  const reduxSqlQueryType = useSelector(sqlQueryTypeRedux);
  const reduxSqlCreateTableCode = useSelector(sqlCreateTableCodeRedux);
  const reduxSqlTestCases = useSelector(sqlTestCasesRedux);
  const reduxSqlValidCases = useSelector(sqlValidCasesRedux);
  const reduxSqlVerificationCode = useSelector(sqlVerificationCodeRedux);

  /// ///////////////////////////////////////////////////////////////////
  const [questionStage, setQuestionStage] = useState(1);
  // const [htmlContent, setHtmlContent] = useState('');
  const [isInQuestionDetail, setInQuestionDetail] = useState(false);

  const [saveStatus, setSaveStatus] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  /* eslint-disable */

  function checkSaveStatus() {

    if (reduxSqlQueryType === '') {
      return false;
    }
    if (reduxSqlTestCases.length === 0) {
      return false;
    } else {
      reduxSqlTestCases.forEach(e => {
        if (e.setup === '' || e.result === '' || e.weight === '') {
          return false;
        }
        if (reduxSqlQueryType !== 'SELECT' && e.checkingQuery === ''){
          return false;
        }
      })
    }

    if (reduxSqlValidCases.length === 0) {
      return false;
    } else {
      reduxSqlValidCases.forEach(e => {
        if (e.setup === '' || e.result === '' || e.weight === '') {
          return false;
        }
        if (reduxSqlQueryType !== 'SELECT' && e.checkingQuery === ''){
          return false;
        }
      })
    }

    if (
      !reduxCategory ||
      reduxCategory === '' ||
      // !reduxAssessmentLabel ||
      // reduxAssessmentLabel === '' ||
      // !reduxSubcategory ||
      // reduxSubcategory === '' ||
      !reduxTitle ||
      reduxTitle === '' ||
      !reduxDifficulty ||
      reduxDifficulty === '' ||
      !reduxLanguage ||
      reduxLanguage === '' ||
      !reduxIsPassedVerification ||
      !reduxQuestionDescription ||
      reduxQuestionDescription === '' ||
      reduxQuestionDescription.trim() === '<p></p>'
    ) {
      return false;
    }
    return true;
  }

  useEffect(async () => {
    checkSaveStatus();
    setSaveStatus(0);
  }, [saveStatus]);

  const createQuestion = async () => {
    let questionBody = reduxQuestionDescription;
    questionBody = questionBody.replaceAll('"', '\\"');
    const questionBodyArray = questionBody.split('<p>');
    let updatedQuestionBody = '"';
    questionBodyArray.forEach((element, index) => {
      if (index === questionBodyArray.length - 1) {
        updatedQuestionBody = updatedQuestionBody.concat(element.trimEnd(' '));
      } else {
        updatedQuestionBody = updatedQuestionBody.concat(element);
        updatedQuestionBody = updatedQuestionBody.concat(
          '<p class=\\"text-dark-75 font-size-xlg font-weight-normal pt-5 mb-1\\">'
        );
      }
    });
    updatedQuestionBody = updatedQuestionBody.concat('"');
    updatedQuestionBody = updatedQuestionBody.replaceAll('>\n<', '><');



    const testCaseSetupsTemp = [];
    const checkingQueriesTemp = [];
    const resultsTemp = [];
    const weightsTemp = [];
    reduxSqlTestCases.forEach(e => {
      testCaseSetupsTemp.push(e.setup.replaceAll(`\r\n`, `\n`));
      resultsTemp.push(e.result.replaceAll(`\r\n`, `\n`));
      weightsTemp.push(e.weight.replaceAll(`\r\n`, `\n`));
      if (reduxSqlQueryType !== "SELECT" && reduxSqlQueryType !== "") {
        checkingQueriesTemp.push(e.checkingQuery.replaceAll(`\r\n`, `\n`));
      }
    })

    const testMainSqlCode = {
      queryType: reduxSqlQueryType,
      testCount: reduxSqlTestCases.length,
      createTablesQuery: reduxSqlCreateTableCode,
      testCaseSetups: testCaseSetupsTemp,
      checkingQueries: checkingQueriesTemp,
      results: resultsTemp,
      weights: weightsTemp
    };


    const validCaseSetupsTemp = [];
    const validCheckingQueriesTemp = [];
    const validResultsTemp = [];
    const validWeightsTemp = [];
    reduxSqlValidCases.forEach(e => {
      validCaseSetupsTemp.push(e.setup.replaceAll(`\r\n`, `\n`));
      validResultsTemp.push(e.result.replaceAll(`\r\n`, `\n`));
      validWeightsTemp.push(e.weight.replaceAll(`\r\n`, `\n`));
      if (reduxSqlQueryType !== "SELECT" && reduxSqlQueryType !== "") {
        validCheckingQueriesTemp.push(e.checkingQuery.replaceAll(`\r\n`, `\n`));
      }
    })

    const validationMainSqlCode = {
      queryType: reduxSqlQueryType,
      testCount: reduxSqlValidCases.length,
      createTablesQuery: reduxSqlCreateTableCode,
      testCaseSetups: validCaseSetupsTemp,
      checkingQueries: validCheckingQueriesTemp,
      results: validResultsTemp,
      weights: validWeightsTemp
    };

    const rawData = {
      questionTitle: reduxTitle,
      time: reduxLimit,
      category: reduxCategory,
      subCategory: reduxTitle,
      assessmentLabel: "General SQL Coding",
      questionDifficulty: reduxDifficulty,
      questionLanguage: reduxLanguage,
      questionDescHtml: updatedQuestionBody,
      isActive: reduxStatus,
      answerQuery: reduxSqlVerificationCode,
      testMainSqlCodeCreate: testMainSqlCode,
      validationMainSqlCodeCreate: validationMainSqlCode,
    };

    const a = await dispatch(createSqlQuestionAction(rawData));
  };

  useEffect(async () => {
    if (questionStage === 4) {
      setInQuestionDetail(true);
    } else {
      setInQuestionDetail(false);
    }
  }, [questionStage]);

  function nextClicked() {
    setQuestionStage(questionStage + 1);
  }
  function backClicked() {
    if (questionStage !== 1) {
      setQuestionStage(questionStage - 1);
    }
  }
  function closeQuestion(e) {
    e.stopPropagation();
    alert("You're about to leave the page. Your work will be lost !");
    dispatch(setInitial());
    setQuestionStage(1);
  }

  function saveClicked() {
    setShowPopup(true);
    createQuestion();
  }

  // const getContent = htmlContentProp => {
  //   setHtmlContent(htmlContentProp);
  // };

  const popupOnHide = () => {
    switch (reduxIsSuccess) {
      case true:
        dispatch(setInitial());
        setQuestionStage(1);
        setShowPopup(false);
        break;
      case false:
        setShowPopup(false);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {/* <QuestionHeader /> */}
      <Header />
      <InformationPopup show={showPopup} onHide={popupOnHide} />
      <div className={styles.QuestionWrapperPane}>
        <div className={styles.QuestionHeaderRow}>
          <div className={styles.QuestionHeader}>Add SQL Question</div>
          <CloseButton
            onClick={e => {
              closeQuestion(e);
            }}
            size="large"
          />
        </div>
        <div className={styles.QuestionPane}>
          <div className={styles.LeftPane}>
            <div className={styles.height30px}>Question Text:</div>
            <div className={styles.TextEditorQuestionText}>
              {/* <TextEditor getContent={getContent} currentBody="" /> */}
              <QuestionDescTextEditor />
            </div>
          </div>
          <div className={styles.toolbarBorder} />
          {questionStage !== 4 && (
            <div className={styles.RightPane}>
              {questionStage === 1 && <SqlSignature />}
              {questionStage === 2 && <SqlTestCases />}
              {questionStage === 3 && <SqlVerificationCode />}
            </div>
          )}
          {questionStage === 4 && <QuestionDetails detailChange={setSaveStatus} />}
        </div>
        <div className={styles['nav-question']}>
          <button
            className={styles.questionNavButton}
            type="button"
            onClick={backClicked}
            style={{ gridColumnStart: 2 }}
          >
            BACK
          </button>
          {isInQuestionDetail ? (
            <button
              disabled={!checkSaveStatus()}
              className={styles.questionNavButton}
              type="button"
              onClick={saveClicked}
              style={{ gridColumnStart: 3 }}
            >
              SAVE
            </button>
          ) : (
            <button
              className={styles.questionNavButton}
              type="button"
              onClick={nextClicked}
              style={{ gridColumnStart: 3 }}
              disabled={questionStage === 1 && reduxSqlQueryType === ''}
            >
              NEXT
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default SqlQuestionPage;
