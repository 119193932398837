import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../Common/Components/Checkbox';
import styles from './NewOpCompanyItem.module.css';

const NewOpCompanyItem = ({ item, selectedList, setSelectedList }) => {
  const [newOpCompanyLogoPath, setNewOpCompanyLogoPath] = useState();
  const [isChecked, setIsChecked] = useState(false);


	// Add logo path domain
	const setLogoPath = () => {
		if (!item.companyLogo) {
			setNewOpCompanyLogoPath();
		} else if (item.companyLogo.startsWith('CompanyLogo/')) {
			setNewOpCompanyLogoPath(`${process.env.REACT_APP_IMAGE_API}/${item.companyLogo}`);
		} else {
			setNewOpCompanyLogoPath(item.companyLogo);
		}
	}

	const setCheckedStatus = () => {
		let isChecked = false;
    selectedList.forEach(e => {
        if (e === item.companyName) {
            isChecked = true;
        }
    })
		setIsChecked(isChecked);
	}

  const checkCompany = () => {
    if (selectedList.includes(item.companyName)) {
			const temp = selectedList.slice();
			const index = temp.indexOf(item.companyName);
			temp.splice(index, 1)
			setSelectedList(temp);
		} else {
			setSelectedList([...selectedList, item.companyName])
		}
  }

	useEffect(() => {
    if (item) {
      setLogoPath();
    }
  }, [item]);

	useEffect(() => {
		setCheckedStatus();
	}, [selectedList])

  return (
    <div className={styles.CompanyItemWrapper}>
      <Checkbox myOnClick={checkCompany} myChecked={isChecked} />
      <div className={styles.CompanyInfoWrapper} onClick={checkCompany}>
        {newOpCompanyLogoPath && (
          <div className={styles.CompanyLogoWrapper}>
            <img src={newOpCompanyLogoPath} alt="newOpCompanyLogo" />
          </div>
        )}
        <div className={styles.CompanyNameWrapper}>{item.companyName}</div>
      </div>
    </div>
  );
};

export default NewOpCompanyItem;
