import React, { useState, useEffect } from 'react';
import Tooltip from '../Components/Tooltip';
import styles from './TextOverflow.module.css';

export default function TextOverflow({
  text,
  textClassName,
  textStyle,
  itemId,
  position = 'top',
  width,
  disabled,
  children
}) {
  const [clipped, setClipped] = useState(true);

  function isEllipsisActive() {
    const element = document.getElementById(itemId);
    setClipped(element?.scrollWidth > element?.offsetWidth);
  }

  return (
    <div
      onMouseEnter={() => {
        if (disabled !== true) {
          isEllipsisActive();
        }
      }}
    >
      {clipped && !disabled ? (
        <Tooltip direction={position} text={text}>
          <span
            id={itemId}
            style={{ maxWidth: `${width}px`, ...textStyle }}
            className={`${textClassName} ${styles.textOverflowed}`}
            onCopy={() => {
              navigator.clipboard.writeText(text);
            }}
          >
            {!children ? text : children}
          </span>
        </Tooltip>
      ) : (
        <div
          id={itemId}
          style={{ maxWidth: `${width}px`, ...textStyle }}
          className={`${textClassName} ${styles.textOverflowed}`}
        >
          {!children ? text : children}
        </div>
      )}
    </div>
  );
}
