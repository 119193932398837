import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from '../Styles/PublishBody.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import { atsState, checkBoxState } from '../../../redux/addAssessment/selectors';
import { ReactComponent as CopyIcon } from '../Images/purpleCopyIcon.svg';
import linkedin from '../Images/Connections/linkedin.svg';
import breezy from '../Images/Connections/breezy.svg';
import workable from '../Images/Connections/workable.svg';
import recruitee from '../Images/Connections/recruitee.svg';
import jazzhr from '../Images/Connections/jazzhr.svg';
import kariyer from '../Images/Connections/kariyer.svg';
import CreateAssessmentPopup from '../../AssessmentList/Components/CreateAssessmentPopup';
import imageSrc from '../Images/videoPopup.svg';

import {
  assessmentDetailAfterCreate,
  AtsList,
  CompanyAssessmentId,
  connectionListRedux
} from '../../../redux/assessmentsVol2/selectors';
import { getAssessmentDetailAfterCreateAction } from '../../../redux/assessmentsVol2/actions';
import { isFirstAssessmentCheckAction } from '../../../redux/plan/actions';

import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';
import { useInterval } from '../../../utils/useInterval';
import { useWindowSize } from '../../../utils/helpers';
import ConnectionRow from '../../NewAssessmentDetail/Modals/ConnectionRow';

export default function PublishBody({ id, setShowCopy, setOnboardingOpen }) {
  const dispatch = useDispatch();
  const [windowW, windowH] = useWindowSize();
  const history = useHistory();

  const selectedConnections = useSelector(checkBoxState);
  const selectedATS = useSelector(atsState);
  const [connectionIndex, setConnectionIndex] = useState(0);
  const [hasNextConnection, setHasNextConnection] = useState(
    !selectedATS && selectedConnections.length > 1
  );
  const [hasPrevConnection, setHasPrevConnection] = useState(
    !selectedATS && selectedConnections.length > 1 && connectionIndex !== 0
  );
  const [video, set] = useState('');
  const [logo, setLogo] = useState();

  const [switchValue, setSwitchValue] = useState(1);
  const videoItem = useRef();
  const [load, setLoad] = useState(false);
  const asssessmentID = useSelector(CompanyAssessmentId);

  const atsList = useSelector(AtsList);
  const connectionList = useSelector(connectionListRedux);
  const [onboardingPopupShow, setOnboardingPopupShow] = useState(false);

  const user = useSelector(userSelector);

  const buildYoutubeUrl = url => {
    // setLoad(!load);
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return match && match[7].length === 11 ? `https://www.youtube.com/embed/${match[7]}` : false;
  };

  const assessmentDetail = useSelector(assessmentDetailAfterCreate);

  useEffect(() => {
    if (onboardingPopupShow) {
      setOnboardingOpen(true);
    } else {
      setOnboardingOpen(false);
    }
  }, [onboardingPopupShow]);

  const handlePlayClicked = () => {
    setOnboardingPopupShow(false);
  };

  useEffect(async () => {
    if (asssessmentID) {
      const data = { CompanyAssessmentId: asssessmentID };
      const a = dispatch(getAssessmentDetailAfterCreateAction(data));
    }
  }, [asssessmentID]);

  function get(option) {
    const a = buildYoutubeUrl(option);
    set(a);
  }

  function getLogo(option) {
    switch (option) {
      case 'LinkedIn':
        setLogo(linkedin);
        break;
      case 'Linkedin Recruiter':
        setLogo(linkedin);
        break;
      case 'Breezy':
        setLogo(breezy);
        break;
      case 'Recruitee':
        setLogo(recruitee);
        break;
      case 'Workable':
        setLogo(workable);
        break;
      case 'JazzHR':
        setLogo(jazzhr);
        break;
      case 'Other':
        break;
      case 'Kariyer.net':
        setLogo(kariyer);
        break;
      default:
        setLogo(linkedin);
        break;
    }
  }

  useEffect(() => {
    if (selectedATS) {
      get(atsList?.filter(ats => ats.name === selectedATS)[0]?.link);
      getLogo(selectedATS);
    } else if (selectedConnections.length > 0) {
      get(
        connectionList?.filter(ats => ats.name === selectedConnections[connectionIndex])[0]?.link
      );
      getLogo(selectedConnections[connectionIndex]);
    }
  }, [selectedATS, selectedConnections]);

  function goToNextConnection() {
    setLoad(!load);
    if (selectedConnections.length >= connectionIndex + 1) {
      /* Mixpanel Skip Button Event */
      mixpanel.track('Create Assessment Page - Publish - Skip Button Click', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Root Assessment Id': id,
        'Root Assessment Name': localStorage.getItem(
          'Mixpanel_AssessmentCustomizationRootAssessmentName'
        ),
        'Selected Connection on Skip Click': selectedATS || selectedConnections[connectionIndex]
      });

      setConnectionIndex(connectionIndex + 1);
    }
  }

  function goToPrevConnection() {
    if (connectionIndex > 0) {
      setConnectionIndex(connectionIndex - 1);
    }
  }

  useEffect(() => {
    setLoad(!load);
    if (selectedConnections.length > 0) {
      get(
        connectionList?.filter(ats => ats.name === selectedConnections[connectionIndex])[0]?.link
      );
      getLogo(selectedConnections[connectionIndex]);
      setHasNextConnection(connectionIndex + 1 < selectedConnections.length);
      setHasPrevConnection(connectionIndex > 0);
    }
  }, [connectionIndex]);

  function handleBackButton() {
    if (hasPrevConnection) {
      goToPrevConnection();
    }
  }

  function handleNextClicked() {
    setLoad(!load);
    if (hasNextConnection) {
      goToNextConnection();
    } else {
      /* Mixpanel Finish Button Event */
      mixpanel.track('Create Assessment Page - Publish - Finish Button Click', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Root Assessment Id': id,
        'Root Assessment Name': localStorage.getItem(
          'Mixpanel_AssessmentCustomizationRootAssessmentName'
        ),
        'Connection List': selectedConnections,
        'Selected ATS': selectedATS
      });
      history.replace(`/create-assessment/${id}/${5}`);
    }
  }

  function handleDelay() {
    setShowCopy(true);

    /* Mixpanel Copy Button Event */
    mixpanel.track('Create Assessment Page - Publish - Copy Button Click', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      ),
      'Selected Connection on Copy Click': selectedATS || selectedConnections[connectionIndex]
    });
  }

  /* Mixpanel pageview */
  useEffect(() => {
    mixpanel.track('Create Assessment Page - Publish Pageview', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      ),
      'Connection List': selectedConnections,
      'Selected ATS': selectedATS
    });
  }, []);

  /* Detect click on video - for mixpanel */
  const [isVideoClicked, setIsVideoClicked] = useState(false);
  useEffect(() => {
    setIsVideoClicked(false);
  }, [connectionIndex]);

  useInterval(() => {
    const elem = document.activeElement;
    if (elem && elem.tagName === 'IFRAME' && !isVideoClicked) {
      /* Mixpanel Copy Button Event */
      mixpanel.track('Create Assessment Page - Publish - Video Click', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Root Assessment Id': id,
        'Root Assessment Name': localStorage.getItem(
          'Mixpanel_AssessmentCustomizationRootAssessmentName'
        ),
        'Selected Connection on Video Click': selectedATS || selectedConnections[connectionIndex]
      });
      setIsVideoClicked(true);
      handlePlayClicked();
    }
  }, 1000);

  useEffect(async () => {
    if (assessmentDetail?.id) {
      const data = {
        AssessmentId: assessmentDetail?.id
      };
      const resp = await dispatch(isFirstAssessmentCheckAction(data));
      if (resp.payload) {
        setOnboardingPopupShow(true);
      }
    }
  }, [assessmentDetail?.id]);

  /* ------------------------------------- */

  const [width, height] = useWindowSize();
  const [yConstant, setYConstant] = useState(191);

  useEffect(() => {
    if (width >= 1600) {
      setYConstant(207);
    } else {
      setYConstant(191);
    }
  }, [width]);

  return (
    <div>
      <CreateAssessmentPopup
        show={onboardingPopupShow}
        setShow={setOnboardingPopupShow}
        relativeElementId="Video"
        title="Learn how to share"
        text="Watch the video to correctly share your assessment and reach out new talents"
        imageSrc={imageSrc}
        xCenter
        yConstant={yConstant}
      />
      {windowW > 700 && <div className={styles.title}>Publish</div>}
      <div className={styles.subtitle}>
        Please follow the steps in the video below to publish the assessment.
      </div>
      {windowW > 700 ? (
        <div
          id="Video"
          style={{
            position: onboardingPopupShow && 'relative',
            zIndex: onboardingPopupShow && '10015',
            pointerEvents: onboardingPopupShow && 'visible'
          }}
          className={styles.videoWrapper}
        >
          <div className={logo && styles.videoTitleContainer}>
            {logo && (
              <span className={styles.imgWrapper}>
                <img alt="" src={logo} key={load} />
              </span>
            )}
            {selectedATS ? (
              <span className={styles.connectionName}>{selectedATS}</span>
            ) : (
              <span className={styles.connectionName}>{selectedConnections[connectionIndex]}</span>
            )}
          </div>
          <div className={styles.videoContainer}>
            <div className={styles['video-area']}>
              <iframe
                frameBorder="0"
                id="videoPlayer"
                src={video}
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
                className={styles['video-iframe']}
                ref={videoItem}
                key={load}
              />
            </div>
          </div>
        </div>
      ) : (
        <ConnectionRow
          copied={() => handleDelay()}
          isOpen
          updateConnection={load}
          createAssessment
          mobile
          connection={
            selectedATS
              ? atsList?.filter(ats => ats.name === selectedATS)[0]?.code
              : connectionList?.filter(ats => ats.name === selectedConnections[connectionIndex])[0]
                  ?.code
          }
          assessmentID={asssessmentID}
          video={
            selectedATS
              ? atsList?.filter(ats => ats.name === selectedATS)[0]?.link
              : connectionList?.filter(ats => ats.name === selectedConnections[connectionIndex])[0]
                  ?.link
          }
        />
      )}

      <div className={styles.footerWrapper}>
        {windowW > 700 && (
          <div className={styles.footer}>
            <div className={styles.footerTitleWrapper}>
              <span className={styles.footerTitle}>
                {selectedATS || selectedConnections[connectionIndex] === 'Kariyer.net'
                  ? 'Template Mail'
                  : 'Application Page Link'}
              </span>
              {(selectedATS || selectedConnections[connectionIndex] === 'Kariyer.net') && (
                <div className={styles.switchWrapper}>
                  <span
                    className={styles.htmlText}
                    style={{ color: switchValue === 0 ? '#7458d3' : '#5858584d' }}
                  >
                    HTML
                  </span>
                  <div
                    className={`${styles.switchContainer} ${
                      switchValue === 1 ? styles.active : ''
                    }`}
                    onClick={() => setSwitchValue(switchValue === 1 ? 0 : 1)}
                  >
                    <div
                      style={switchValue === 0 ? { marginLeft: 2 } : { marginLeft: 21 }}
                      className={styles.switchButton}
                    />
                  </div>
                  <span
                    className={styles.textText}
                    style={{ color: switchValue === 1 ? '#7458d3' : '#5858584d' }}
                  >
                    Text
                  </span>
                  <span className={styles.arrowImg} />
                </div>
              )}
              <CustomButton
                type={3}
                textField={
                  selectedATS || selectedConnections[connectionIndex] === 'Kariyer.net'
                    ? switchValue === 0
                      ? 'Copy HTML'
                      : 'Copy Text'
                    : 'Copy Link'
                }
                style={{
                  marginRight: 0,
                  marginLeft: !(
                    selectedATS || selectedConnections[connectionIndex] === 'Kariyer.net'
                  )
                    ? 'auto'
                    : '12px',
                  width: '150px'
                }}
                Icon={
                  !(selectedATS || selectedConnections[connectionIndex] === 'Kariyer.net')
                    ? CopyIcon
                    : null
                }
                iconStyle={{ fill: 'none' }}
                buttonOnClick={() =>
                  selectedATS || selectedConnections[connectionIndex] === 'Kariyer.net'
                    ? switchValue === 0
                      ? navigator.clipboard.writeText(assessmentDetail.mailTemplateHtml) &&
                        handleDelay()
                      : navigator.clipboard.writeText(
                          document?.getElementById('htmlToTextDiv')?.innerText
                        ) && handleDelay()
                    : navigator.clipboard.writeText(assessmentDetail.link) && handleDelay()
                }
              />
              <div
                id="htmlToTextDiv"
                dangerouslySetInnerHTML={{ __html: assessmentDetail.mailTemplateText }}
                style={{ display: 'none' }}
              />
            </div>
            {windowW > 700 && (
              <span className={styles.footerText}>
                {selectedATS || selectedConnections[connectionIndex] === 'Kariyer.net'
                  ? 'This template creates an auto email in Lever that will send all the applicants in related job post.'
                  : 'This is an external link to collect applications and send assessments to candidates automatically by Coensio.'}
              </span>
            )}
          </div>
        )}
      </div>
      {windowW > 700 ? (
        <div className={styles.buttonsWrapper}>
          {selectedConnections.length > 1 && (
            <CustomButton
              type={5}
              textField="Back"
              isDisabled={!hasPrevConnection}
              buttonOnClick={() => handleBackButton()}
            />
          )}
          <div className={styles.rightButtons}>
            {hasNextConnection && (
              <CustomButton
                type={2}
                textField="Skip"
                buttonOnClick={() => goToNextConnection()}
              />
            )}
            <CustomButton
              textField={hasNextConnection ? 'Next' : 'Finish'}
              style={{ marginRight: 0, marginLeft: 'auto', gridColumnStart: 2 }}
              buttonOnClick={() => {
                handleNextClicked();
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.buttonsWrapper}>
          <CustomButton
            size="mobile"
            type={8}
            textField="Skip"
            buttonOnClick={() => {
              if (hasNextConnection) {
                goToNextConnection();
              } else {
                handleNextClicked();
              }
            }}
          />

          <CustomButton
            size="mobile"
            type={1}
            textField={hasNextConnection ? 'Next' : 'Finish'}
            buttonOnClick={() => {
              handleNextClicked();
            }}
          />
        </div>
      )}
    </div>
  );
}
