import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloseButton from '../../../Common/Components/CloseButton';
import CustomButton from '../../../Common/Components/CustomButton';
import SearchBar from '../../../Common/Components/SearchBar';
import styles from '../Styles/SkillBasedModal.module.css';
import tick from '../../../images/addAssessment/tickGreyBackground.svg';
import {
  addSkillBasedQuestionAction,
  getCategoryLabelsListAction
} from '../../../redux/assessmentsVol2/actions';
import {
  addSkillBasedErrorRedux,
  categoryLabelListSelector
} from '../../../redux/assessmentsVol2/selectors';
import { setAddSkillBasedError, setQuestionBeingAdded } from '../../../redux/assessmentsVol2/slice';
import RadioButton from '../../../Common/Components/RadioButton';
import LanguageDropdown from '../../../Common/Components/LanguageDropdown';
import { ReactComponent as Arrow } from '../../../images/skillBased_animation.svg';

import mixpanel from '../../../utils/mixpanel';
import { userSelector } from '../../../redux/auth/selectors';

export default function SkillBasedMCandFTModals({ show, onHide, type }) {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const ref = useRef(); // Reference for programmatic scroll
  const [scrollPosition, setScrollPosition] = useState(0);
  // Selected radiobutton
  const [difficulty, setDifficulty] = useState('Medium');
  const [language, setLanguage] = useState();
  const [searchVal, setSearchVal] = useState('');
  const categoryLabelList = useSelector(categoryLabelListSelector);

  // Seçili olan kategorinin categoryLabelList içerisindeki indexi
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // Seçilmiş labelların tutulduğu dictionary. Format: {category1: [Seçili label arrayi], category: [Seçili label arrayi], ...}
  const [selectedLabelDict, setSelectedLabelDict] = useState();

  // Difficulty göndermeden aldığımız liste -> tüm distinct category'lere ulaşmak için
  const [wholeList, setWholeList] = useState();
  const [selectedTab, setSelectedTab] = useState({ category: '', labelList: '' });

  const [leftBlur, setLeftBlur] = useState(false);
  const [rightBlur, setRightBlur] = useState(true);
  const [firstCategory, set1] = useState(
    document?.getElementById(`category0`)?.getBoundingClientRect()
  );
  const [lastCategory, set2] = useState(
    document?.getElementById(`category${wholeList?.length - 1}`)?.getBoundingClientRect()
  );
  const [categoryDiv, setDiv] = useState(
    document?.getElementById(`categories`)?.getBoundingClientRect()
  );
  const [changeInComponents, setChange] = useState(false);

  // Mixpanel variable
  const user = useSelector(userSelector);

  // apiden gelen response'u categoryLabelList'e eşitle
  useEffect(async () => {
    if (difficulty !== 'Medium') {
      setDifficulty('Medium');
    }
    setLoading(true);
    setSearchVal('');
    setScrollPosition(0);
    setSelectedTabIndex(0);
    setSelectedLabelDict();
    let initialData = {};
    if (show) {
      setRightBlur(true);
      setLeftBlur(false);
      if (type === 'Multiple Select') {
        /* Mixpanel pageview */
        mixpanel.track('Skill Based Popup - Pageview', {
          'User Id': user.userId,
          'Company Name': user.companyName,
          'Name Surname': user.userNameSurname,
          'Root Assessment Id': id,
          'Root Assessment Name': localStorage.getItem(
            'Mixpanel_AssessmentCustomizationRootAssessmentName'
          ),
          'Question Type': 'Multiple Choice'
        });
        /* ----------------- */

        const rawData = {
          QuestionTypeId: 1,
          QuestionLanguage: 'EN',
          Difficulty: 'Medium'
        };
        initialData = {
          QuestionTypeId: 1,
          QuestionLanguage: 'EN',
          Difficulty: ''
        };
        const initial = await dispatch(getCategoryLabelsListAction(initialData));
        if (initial.meta.requestStatus === 'fulfilled') {
          setWholeList(initial.payload);
          setSelectedTab(initial.payload[0]);
        }
        const a = await dispatch(getCategoryLabelsListAction(rawData));
        if (a.meta.requestStatus === 'fulfilled') {
          setLoading(false);
        }
      } else if (type === 'Free-Text') {
        /* Mixpanel pageview */
        mixpanel.track('Skill Based Popup - Pageview', {
          'User Id': user.userId,
          'Company Name': user.companyName,
          'Name Surname': user.userNameSurname,
          'Root Assessment Id': id,
          'Root Assessment Name': localStorage.getItem(
            'Mixpanel_AssessmentCustomizationRootAssessmentName'
          ),
          'Question Type': 'Free Text'
        });
        /* ----------------- */

        const rawData = {
          QuestionTypeId: 3,
          QuestionLanguage: 'EN',
          Difficulty: 'Medium'
        };
        initialData = {
          QuestionTypeId: 3,
          QuestionLanguage: 'EN',
          Difficulty: ''
        };
        const initial = await dispatch(getCategoryLabelsListAction(initialData));
        if (initial.meta.requestStatus === 'fulfilled') {
          setWholeList(initial.payload);
          setSelectedTab(initial.payload[0]);
        }
        const a = await dispatch(getCategoryLabelsListAction(rawData));
        if (a.meta.requestStatus === 'fulfilled') {
          setLoading(false);
        }
      }
    }
    setDiv(document?.getElementById(`categories`)?.getBoundingClientRect());
    return () => {
      setRightBlur(true);
      setLeftBlur(false);
    };
  }, [show]);

  // categoryLabelList updatelendiğinde selectedLabelDict oluştur
  useEffect(() => {
    if (categoryLabelList) {
      const temp = {};
      categoryLabelList.forEach(element => {
        temp[element.category] = [];
      });
      setSelectedLabelDict(temp);
    }
  }, [categoryLabelList]);

  // Labellara clicklendiğinde tetikleniyor. Seçili değilse selectedLabelDict içerisine ekliyor, seçiliyse çıkartıyor
  const selectLabel = label => {
    const categoryName = categoryLabelList.filter(cat => cat.category === selectedTab.category)[0]
      .category;
    let tempLabelList = selectedLabelDict[categoryName];
    if (tempLabelList.includes(label)) {
      tempLabelList = tempLabelList.filter(e => e !== label);
    } else {
      tempLabelList.push(label);
    }
    setSelectedLabelDict(prevState => ({ ...prevState, [categoryName]: tempLabelList }));
  };

  // // searchVal değiştiğinde ona uygun filtreleme yapıyor.
  // // Eğer seçili kategori içerisinde o filtreye uyan label varsa o kategori seçili kalmaya devam ediyor
  // // Eğer seçili kategori içerisinde o filtreye uyan label yoksa, o labeli içeren ilk kategoriyi seçiyor
  // // Eğer direkt uygun bir label yoksa selectedTabIndex'i -1'e eşitliyor
  useEffect(() => {
    if (searchVal && show) {
      const temp1 = [];

      wholeList.forEach(a => {
        if (a.category.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase())) {
          temp1.push({ category: a.category, list: a.labelList });
        }
      });

      if (temp1.length > 0) {
        setSelectedTab(temp1[0]);
      } else {
        const temp = [];
        categoryLabelList?.forEach(e => {
          if (
            e.category.toLowerCase().includes(searchVal) ||
            e.labelList.toLowerCase().includes(searchVal)
          ) {
            temp.push({ category: e.category, list: e.labelList });
          }
          temp.sort((a, b) => {
            const length1 = a.list
              .split(', ')
              .filter(e => e.toLowerCase().includes(searchVal.toLocaleLowerCase())).length;
            const length2 = b.list
              .split(', ')
              .filter(e => e.toLowerCase().includes(searchVal.toLocaleLowerCase())).length;

            return length2 - length1;
          });
          setSelectedTab(temp[0]);
        });
      }
    } else if (show && wholeList && searchVal !== '') {
      setSelectedTab(wholeList[0]);
    }
  }, [searchVal]);

  const addQuestion = async () => {
    setLoading(true);
    const temp = [];
    Object.entries(selectedLabelDict).forEach((element, index) => {
      if (element[1].length > 0) {
        temp.push({ category: element[0], labels: element[1] });
      }
    });
    if (type === 'Multiple Select') {
      /* Mixpanel Confirm Click */
      mixpanel.track('Skill Based Popup - Confirm Button Click', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Root Assessment Id': id,
        'Root Assessment Name': localStorage.getItem(
          'Mixpanel_AssessmentCustomizationRootAssessmentName'
        ),
        'Question Type': 'Multiple Choice',
        'Category Labels': temp,
        Difficulty: difficulty,
        'Question Language': language.title,
        'Coding Language List': null
      });
      /* ----------------- */

      const rawData = {
        rootAssessmentId: id,
        questionTypeId: 1,
        categoryLabels: temp,
        difficulty,
        QuestionLanguage: language.title
      };
      const a = await dispatch(addSkillBasedQuestionAction(rawData));
      // if (a.meta.requestStatus === 'fulfilled') {
      // setLoading(false);
      // onHide();
      // }
    } else if (type === 'Free-Text') {
      /* Mixpanel Confirm Click */
      mixpanel.track('Skill Based Popup - Confirm Button Click', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Root Assessment Id': id,
        'Root Assessment Name': localStorage.getItem(
          'Mixpanel_AssessmentCustomizationRootAssessmentName'
        ),
        'Question Type': 'Free Text',
        'Category Labels': temp,
        Difficulty: difficulty,
        'Question Language': language.title,
        'Coding Language List': null
      });
      /* ----------------- */

      const rawData = {
        rootAssessmentId: id,
        questionTypeId: 3,
        categoryLabels: temp,
        difficulty,
        QuestionLanguage: language.title
      };
      const a = await dispatch(addSkillBasedQuestionAction(rawData));
      // if (a.meta.requestStatus === 'fulfilled') {
      //   setLoading(false);
      //   onHide();
      // }
    }
    setLoading(false);
    onHide();
    dispatch(setQuestionBeingAdded(null));
  };

  const addSkillBasedErrorState = useSelector(addSkillBasedErrorRedux);

  useEffect(() => {
    if (addSkillBasedErrorState === false && show) {
      dispatch(setAddSkillBasedError(null));
    }
  }, [addSkillBasedErrorState]);

  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    if (selectedLabelDict) {
      let count = 0;
      Object.values(selectedLabelDict).forEach(e => {
        count += e.length;
      });
      setSelectedCount(count);
    }
  }, [selectedLabelDict]);

  useEffect(async () => {
    if (type === 'Multiple Select') {
      const rawData = {
        QuestionTypeId: 1,
        QuestionLanguage: language?.title,
        Difficulty: difficulty
      };
      const a = await dispatch(getCategoryLabelsListAction(rawData));
      if (a.meta.requestStatus === 'fulfilled') {
        setLoading(false);
      }
    } else if (type === 'Free-Text') {
      const rawData = {
        QuestionTypeId: 3,
        QuestionLanguage: language.title,
        Difficulty: difficulty
      };
      const a = await dispatch(getCategoryLabelsListAction(rawData));
      if (a.meta.requestStatus === 'fulfilled') {
        setLoading(false);
      }
    }
  }, [difficulty, language]);

  useEffect(() => {
    setTimeout(() => {
      set1(document?.getElementById(`category0`)?.getBoundingClientRect());
      set2(document?.getElementById(`category${wholeList?.length - 1}`)?.getBoundingClientRect());
      setDiv(document?.getElementById(`categories`)?.getBoundingClientRect());
    }, 500);
  }, [changeInComponents]);

  const horizontalScroll = amount => {
    if (ref?.current) {
      ref.current.scrollLeft -= amount;
    }
  };

  useEffect(async () => {
    const lastCategoryX = lastCategory?.x + lastCategory?.width;
    const firstCategoryX = firstCategory?.x;
    const divPos = categoryDiv?.x + categoryDiv?.width;
    const divX = categoryDiv?.x;

    if ((lastCategoryX, firstCategoryX, divPos, divX)) {
      if (Math.abs(lastCategoryX - divPos) < 25) {
        setRightBlur(false);
      } else {
        setRightBlur(true);
      }

      if (Math.abs(firstCategoryX - divX) < 10) {
        setLeftBlur(false);
      } else {
        setLeftBlur(true);
      }
    }
  }, [JSON.stringify(lastCategory), JSON.stringify(firstCategory), JSON.stringify(selectedTab)]);

  useEffect(async () => {
    const divX = await categoryDiv?.x;
    const index = await wholeList?.map(a => a?.category)?.indexOf(selectedTab?.category);
    const pos = await document?.getElementById(`category${index}`)?.getBoundingClientRect();
    await horizontalScroll(divX - pos?.x + 250);
    setChange(!changeInComponents);
  }, [JSON.stringify(selectedTab)]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <div
        className={styles.Header}
        style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
      >
        {type === 'Multiple Select' && 'Add Multiple Select Question'}
        {type === 'Free-Text' && 'Add Free Text Question'}
        <CloseButton onClick={onHide} style={{ marginRight: 13 }} />
      </div>
      <div
        className={styles.Description}
        style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
      >
        {type === 'Multiple Select' &&
          'Please select skill(s) to assess by multiple select questions.'}
        {type === 'Free-Text' && 'Please select skill(s) to assess by free text questions.'}
      </div>
      <SearchBar
        isExtended={1}
        size="line"
        color="gray"
        inputFieldPlaceholder="Search"
        inputFieldValue={searchVal}
        inputFieldOnChange={e => setSearchVal(e.target.value)}
        style={{
          opacity: loading ? '0.4' : '1',
          pointerEvents: loading ? 'none' : 'initial',
          marginLeft: 23,
          marginRight: 13
        }}
      />
      <div className={styles.categoryArrowWrapper} id="wrapper">
        {leftBlur && (
          <div>
            <div className={styles.blurIconLeft} />
            <Arrow
              className={`${styles.icon} ${styles.left}`}
              onClick={() => {
                ref.current.scrollLeft -= 500;
                setChange(!changeInComponents);
              }}
            />
          </div>
        )}

        <div
          onLoad={() => setDiv(document?.getElementById(`categories`)?.getBoundingClientRect())}
          id="categories"
          className={styles.CategoryWrapper}
          ref={ref}
          style={{
            opacity: loading ? '0.4' : '1',
            pointerEvents: loading ? 'none' : 'initial',
            marginLeft: !leftBlur && 23
          }}
        >
          {wholeList &&
            wholeList.map((e, i) => (
              <div
                id={`category${i}`}
                className={`${styles.Category} ${
                  selectedTab?.category === e.category && styles.SelectedCategory
                }`}
                onClick={() => {
                  // setSelectedTabIndex(i);
                  setSelectedTab(e);
                }}
                // Eğer kategori, search'e uyan label bulundurmuyorsa gösterme
                style={{
                  display: !searchVal
                    ? 'initial'
                    : e.labelList.toLowerCase().includes(searchVal.toLowerCase())
                    ? 'initial'
                    : e.category.toLowerCase().includes(searchVal.toLowerCase())
                    ? 'initial'
                    : 'none'
                }}
              >
                {e.category}
              </div>
            ))}
        </div>
        {rightBlur && (
          <div>
            <div className={styles.blurIconRight} />
            <Arrow
              className={styles.icon}
              onClick={() => {
                ref.current.scrollLeft += 500;
                setChange(!changeInComponents);
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.difficultyWrapper}>
        <RadioButton
          containerStyle={{ display: 'flex', flexDirection: 'row' }}
          data={['Easy', 'Medium', 'Hard']}
          selectedOption={difficulty}
          setSelectedOption={setDifficulty}
          disableUnselect
        />
        <div style={{ marginRight: 0, marginLeft: 'auto' }}>
          <LanguageDropdown
            visibleItemNo={6}
            list={[
              { id: '1', title: 'EN', key: '1' },
              { id: '2', title: 'TR', key: '2' },
              { id: '3', title: 'DE', key: '3' },
              { id: '4', title: 'RU', key: '4' },
              { id: '5', title: 'IT', key: '5' },
              { id: '6', title: 'AR', key: '6' }
            ]}
            defaultSelectedId="1"
            setSelectedOption={setLanguage}
          />
        </div>
      </div>

      <div
        className={styles.LabelWrapper}
        style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
      >
        {/* START */}
        {selectedTabIndex !== -1 &&
        (categoryLabelList
          ?.filter(cat => cat.category === selectedTab?.category)[0]
          ?.labelList?.toLocaleLowerCase()
          ?.includes(searchVal?.toLocaleLowerCase()) ||
          categoryLabelList
            ?.filter(cat => cat.category === selectedTab?.category)[0]
            ?.category.toLowerCase()
            ?.includes(searchVal?.toLowerCase())) ? (
          categoryLabelList
            ?.filter(cat => cat.category === selectedTab?.category)[0]
            ?.labelList.split(', ')
            ?.map(
              e =>
                (e.toLowerCase().includes(searchVal?.toLocaleLowerCase()) ||
                  categoryLabelList
                    ?.filter(cat => cat.category === selectedTab?.category)[0]
                    .category.toLowerCase()
                    .includes(searchVal.toLowerCase())) && (
                  <div
                    className={`${styles.Label} ${
                      selectedLabelDict &&
                      selectedLabelDict[
                        categoryLabelList?.filter(cat => cat.category === selectedTab?.category)[0]
                          ?.category
                      ]?.includes(e) &&
                      styles.selectedLabel
                    }`}
                    onClick={() => selectLabel(e)}
                  >
                    <div className={styles.LabelText}>{e}</div>
                    {selectedLabelDict &&
                    selectedLabelDict[
                      categoryLabelList?.filter(cat => cat.category === selectedTab?.category)[0]
                        .category
                    ]?.includes(e) ? (
                      <img className={styles.TickIcon} src={tick} alt="" />
                    ) : (
                      <div className={styles.LabelIcon}>+</div>
                    )}
                  </div>
                )
            )
        ) : (
          <div className={styles.notAvailable}>No such question is available.</div>
        )}
        {selectedTabIndex === -1 && <div>Yok böyle label</div>}
        {/* END */}
      </div>

      <div
        className={styles.ButtonWrapper}
        style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
      >
        <CustomButton
          type={2}
          buttonOnClick={onHide}
          textField="Cancel"
          size="small"
          style={{ marginRight: '8px' }}
        />
        <CustomButton
          type={1}
          buttonOnClick={() => {
            addQuestion();
          }}
          textField="Confirm"
          size="small"
          isDisabled={selectedCount === 0}
        />
      </div>
    </Modal>
  );
}
