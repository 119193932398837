export default {
  getCategoryLabelsList: `api/Question/GetCategoryLabelsList`,

  loginVol2: `api/User/Login`,
  getCurrentUserDetail: `api/User/GetCurrentUserDetail`,

  getRootAssessmentList: `api/assessment/GetRootAssessmentList`,

  // New api assessment list call
  getCompanyAssessmentList: `api/company/GetAssessmentListByCompanyId`,
  // getCompanyAssessmentCandidateCounts: `/api/Company/GetCompanyAssessmentCounts`,
  getCompanyAssessmentFilterCount: `/api/company/GetAssessmentCountsByCompanyId`,

  // Add coding question popup
  getCodingLanguages: `api/Question/GetCodingLanguages`,
  addSkillBasedCodingQuestion: `api/assessment/AddSkillBasedCodingQuestion`,
  // Custom api assessment list call
  createCustomAssessment: `/api/assessment/CreateCustomAssessment`,

  // update Custom Assessment -> also call when page is reloaded ? (creates a new assessment based on custom list)
  updateCustomAssessment: `api/assessment/UpdateCustomAssessment`,

  // Add&Delete free-text and multiple choice question
  addSkillBasedQuestion: `api/assessment/AddSkillBasedQuestion`,
  removeSkillBasedQuestion: `api/assessment/RemoveQuestionFromAssessment`,
  updateQuestionWeight: `api/assessment/UpdateQuestionWeight`,

  // Api for the filter column
  getJobDescriptionList: `api/assessment/GetJobDescriptionList`,

  getQuestionByFilter: `api/Question/GetQuestionByFilter`,

  // add question based question
  addQuestionBasedQuestion: `api/assessment/AddQuestionBasedQuestion`,

  // Try assessment
  tryAssessment: `api/assessment/TryAssessment`,

  // Send for Review
  getUserListByCompany: `/api/user/GetUserListByCompany`,

  sendEmailForReview: `/api/Assessment/ShareLinkForReview`,

  sendNotificationForRecruiter: `/api/assessment/SendNotificationForRecruiter`,

  // get skills based on job role
  getSkillsOfJobRole: (id = ':id') => `api/Question/GetQuestionSkillsFilter?RootAssessmentId=${id}`,

  getCandidatePublicInfo: '/api/Candidate/GetCandidatePublicInfo',

  getCandidatePublicSolutions: '/api/Candidate/GetCandidatePublicSolutions',

  getCandidateAssessmentPublicLabels: '/api/Candidate/GetCandidateAssessmentPublicLabels',

  getCandidateIsScored: '/api/Candidate/GetCandidateIsScored',

  postRunCode: `api/AnswerQuestion/AnswerCodeControl`,
  getCreateQuestionDropdown: `api/Question/GetCreateQuestionDropdown`,
  getCreateQuestionSubcategoryDropdown: `api/Question/GetCreateQuestionSubcategoryDropdown`,
  getCreateQuestionAssessmentLabelDropdown: `api/Question/GetCreateQuestionAssessmentLabelDropdown`,
  createMultipleChoiceQuestion: `api/Question/CreateMultipleChoiceQuestion`,
  createFreeTextQuestion: `api/Question/CreateFreeTextQuestion`,
  createCodingQuestion: `api/AnswerQuestion/TestMainCodeCreate`,
  getQuestionDetail: `api/Question/GetQuestionDetail`,
  getCandidateSolutions: `/api/Candidate/getCandidateSolutions`,
  getCandidateAssessmentLabels: '/api/Candidate/GetCandidateAssessmentLabels',
  inviteUser: `/api/Assessment/UserInvite`,

  addAssessment: `api/Assessment/AddAssessment`, // For new assessment creation,
  getAtsList: `api/Assessment/GetAssessmentDistributorListForEnableAts`,

  getAssessmentDetailAfterCreate: `api/Assessment/GetCompanyAssessmentAfterCreate`,

  getCurrentPlan: `api/Bundle/GetCurrentPlan`,
  getAllPlans: `api/Bundle/GetBundles`,
  getCurrentUsage: `api/Company/GetCurrentUsage`,
  getAddOns: `api/Company/GetAddOns`,
  getPackageDetail: `api/Bundle/GetBundleDetail`,
  getBillingInfo: `api/Company/GetBillingInformation`,
  editBillingInfo: `api/Company/EditBillingInformation`,
  getCountries: `api/Country/GetCountries`,
  applicantSendEmail: `api/Applicant/ApplicantAssessmentSendMail`,
  addCard: `api/Payment/CreateCard`,
  getMyCards: `api/Payment/MyCards`, // Bu tam olarak ne döndürüyor farklı anlamadım
  contactSales: `api/BundleItemMap/ContactSalesMail`,
  createPackageOrder: `api/Order/PurchaseOrder`,
  comparePlansAndFeatures: `api/Bundle/GetBundleDetails`,
  postSqlRunCode: `api/AnswerQuestion/AnswerSqlCodeControl`,
  createSqlQuestion: `api/AnswerQuestion/TestMainSqlCodeCreate`,
  // getAssessmentCandidateList: `api/Company/GetAssessmentCandidateFilterList`,
  // getCandidateCountByFunnelId: `api/Funnel/GetCandidateCountByFunnelId`,
  getCandidateInformationByFunnelId: `api/Funnel/GetCandidateInformationByFunnelId `,
  addUserToFunnel: `api/Funnel/AddUsersToFunnel`,
  getAssessmentListForCandidate: `api/Candidate/GetAssessmentCandidateList`,
  // getAssessmentListCounts: `api/Company/GetCompanyAssessmentCounts`,
  getCandidateFunnelInfo: `api/Candidate/GetCandidateFunnelInfo`,

  // Assessment flow
  applicantAssessmentApplyEncrypted: `api/AssessmentSession/ApplicantAssessmentApplyEncrypted`,
  getCandidateApplicationDetail: `api/AssessmentSession/CandidateApplicationDetail`,
  candidateHasData: `api/Candidate/CandidateHasDataOrExistScore`,
  candidateContractCheck: `api/Candidate/CandidateContractCheck`,
  candidateContractConfirm: `api/Candidate/CandidateContractConfirm`,
  startAssessmentSession: `api/AssessmentSession/StartAssessmentSession`,
  getNextQuestion: `api/AssessmentSession/GetNextQuestionFromAssessment`,
  getCurrentQuestion: `api/AssessmentSession/GetCurrentQuestionFromAssessment`,
  submitMultipleChoiceQuestionAnswer: `api/AssessmentSession/SubmitMultipleChoiceQuestionAnswer`,
  submitFreeTextQuestionAnswer: `api/AssessmentSession/SubmitFreeTextQuestionAnswer`,
  submitCodingQuestionAnswer: `api/AssessmentSession/SubmitCodingQuestionAnswer`,
  runCodeTestPage: `api/AssessmentSession/RunCode`,
  getAssessmentSessionDetail: `api/AssessmentSession/GetAssessmentSessionDetail`,
  ChangeOrAddApplicantDetail: `api/Candidate/ChangeOrAddApplicantDetail`,
  incrementTabSwitch: `/api/AssessmentSession/IncrementTabSwitch`,
  applyCandidateToAssessment: `/api/Candidate/ApplyCandidateToAssessment`,
  getCandidateInfoFromUserId: '/api/Candidate/GetCvUploadInformation',
  // getCandidateSolutions: `api/Candidate/GetCandidateSolutions`,

  unsubscribeAddOn: `api/Order/UnsubscribeAddOn`,
  unsubscribePlan: `api/Company/CancelPackage`,
  downgradePackage: `api/Company/CompanyBundleFuturePlanCreate`,
  upgradePackage: `api/Company/CompanyBundleUpgradePlan`,

  getCompanyProfile: `api/Company/GetCompanyProfile`,
  updateCompanyProfile: `api/Company/UpdateCompanyProfile`,
  getProfile: `api/User/MyProfile`,
  updateProfile: `api/User/UpdateMyProfile`,
  // Sales screen
  getCustomerSuccessTeam: `api/Company/GetAccountManagerList`,
  getCompanyList: `api/Company/GetCompanyList`,
  getCompanyBundleInfo: `api/Company/GetCurrentCustomBundle`,
  getTemplates: `api/Bundle/GetTemplateDropdownList`,
  getTemplateInfo: `api/Bundle/GetTemplateById`,
  createTemplate: `api/Bundle/CreateTemplate`,
  updateCompany: `api/Company/UpdateCustomBundleItem`,
  connectCompanyWithBundle: `api/Bundle/ConnectTemplateWithCompany`,
  // Create company apisi
  createCompany: `api/Company/CreateCompany`,
  cancelCustomBundle: `api/Company/CancelCustomBundle`,
  publishAssessmentControl: `api/Company/IsCompanyNeededPublishAssessment`,

  // new tokenden gelenler
  changeCompany: `api/Company/ChangeCompany`,
  // getUserList: `api/User/GetUserListByCompany`, // Duplicate api - birini kaldıralım
  addUserToCompany: `api/User/AddUserToCompany`,
  refreshPassword: `api/User/RefreshPassword`,
  resendInvitation: `api/User/SendInvitation`,
  questionAvailabilityControl: `api/Company/IsQuestionAvailable`,
  premiumQAvailableInAssessmentControl: `api/Assessment/GetCustomAssessmentPremiumQuestionCheck`,

  getTeam: `api/Company/GetTeam`,
  makeAdmin: `api/User/MakeAdmin`,
  removeFromAdmins: `api/User/RemoveFromAdmins`,
  editTeamMember: `api/Company/EditCompanyUser`,
  deleteTeamMember: `api/Company/DeleteUserFromCompany`,
  signupRequest: 'api/User/SignUpRequest',

  getUserGroupTypeList: 'api/user/GetUserGroupTypeList',

  // Assessment Detail api
  getAssessmentDetail: 'api/Assessment/GetCompanyAssessmentDetail',

  // Assessment Settings Popup API
  addConnectionToAssessment: 'api/Assessment/AddConnectionToAssessment',
  getAssessmentInfo: 'api/Assessment/GetAssessmentSettingsPopupInfo',
  changeAuthorizedUsersOfAssessment: 'api/Assessment/ChangeAssessmentuserList',
  updateAssessmentInfo: 'api/Assessment/UpdateCompanyAssessmentInfo',
  getAssessmentStatus: 'api/Assessment/GetCompanyAssessmentStatus',
  getAssessmentConnections: 'api/Assessment/GetAssessmentConnections',

  // NewOp
  getNewOpsCompanyList: 'api/NewOps/GetNewOps',
  applyWithNewOps: 'api/NewOps/ApplyWithNewOps',
  getCandidateInfoFromEmail: 'api/Candidate/GetCandidateInfoFromEmail',

  // Cancel the cancel
  abortCancelInProgress: 'api/Company/AbortPackageCancellation',

  purchaseAddOn: 'api/Order/PurchaseAddOn',
  isAuthorizedForCandidateReport: 'api/User/IsUserAuthorizeCandidateReport',
  unlockCandidateReport: 'api/Candidate/UnlockCandidateReport',
  getInvoiceList: 'api/Company/GetInvoiceList',
  downloadInvoice: 'api/CompanyBundle/DownloadInvoice',

  // Signup api
  signupByEmail: 'api/User/SignUpByEmail',
  signupStep2: 'api/User/SignUp',
  sendVerificationEmail: 'api/User/SendVerificationMail',
  forgotPassword: 'api/User/ForgotPassword',
  createSignupInfo: 'api/User/CreateSignupInfo',
  activateUser: 'api/User/UserActivate',
  quickEmailVerification: 'api/User/QuickEmailVerification',
  invitedUserActivate: 'api/User/InvitedUserActivate',
  getCompanyLogoByUserId: 'api/Company/GetCompanyLogoByUser',
  forgotPasswordCheck: 'api/User/ForgotPasswordCheck',
  getUpgradePrices: 'api/Payment/GetPaymentTotalPrice',

  // Onboarding
  getOnboardingSteps: 'api/Company/GetCompayQuickStartInfo',
  isFirstAssessmentCheck: 'api/Assessment/CheckIsFirstAssessment',

  // Root assessment filtering
  getRootAssessmentDropdownList: 'api/Assessment/AssessmentDropdownList',
  // Rejection Mail New
  getCompanySubjectAndEmailContent: 'api/Company/GetCompanySubjectAndEmailContent',
  sendRejectedEmailContent: 'api/Company/SendRejectedEmailContent',
  createRejectedEmailContent: 'api/company/CreateRejectedEmailContent',

  // new Assessment Detail
  getCandidatesByFilter: 'api/Candidate/GetCandidatesFromAssessmentByFilter',
  getFunnelInformation: 'api/Funnel/GetFunnelInfo',
  addNewFunnel: 'api/Funnel/AddFunnel',
  updateFunnelName: 'api/Funnel/UpdateFunnelName',
  deleteFunnel: 'api/Funnel/DeleteFunnel',
  updateFunnelPriority: 'api/Funnel/UpdateFunnelPriority',
  getScreeningFilters: 'api/Screening/GetAllScreeningQuestions',
  getIndustries: 'api/Company/GetIndustries',
  getSchools: 'api/Company/GetSchools',

  fetchShortUrl: 'api/create-short-url',

  // Candidate Report //
  getCandidateInfo: 'api/Candidate/GetCandidateInfo',
  // Comment API's
  createComment: 'api/CommentCandidate/CreateComment',
  updateComment: 'api/CommentCandidate/UpdateComment',
  deleteComment: 'api/CommentCandidate/DeleteComment',
  getComments: 'api/CommentCandidate/GetComments',
  getCommentCount: 'api/CommentCandidate/GetCommentCount',

  getLogoByCompanyId: 'api/Company/GetCompanyLogo',

  // Screening Questions
  getScreeningQuestions: 'api/Screening/GetScreeningQuestions',
  saveScreeningAnswer: 'api/Screening/ScreeningCandidateAnswer',

  getWelcomeCandidateLink: 'api/assessment/GetWelcomeCandidateLink',
  // Candiate Detail
  getCandidateProfile: 'api/Candidate/GetCandidateProfile',
  getCandidatePositions: 'api/candidate/getpositions',
  unlockNewOpsCandidate: 'api/Candidate/UnlockNewOpsCandidate',
  newOpsCandidateCheck: 'api/Candidate/GetNewOpsCandidateCheck',
  getNotes: 'api/Note/GetAllByUserId',
  createNote: 'api/Note/Create',
  updateNote: 'api/Note/Update',
  deleteNote: 'api/Note/Delete',
  getNotebyId: 'api/Note/GetById',
  shareProfileLink: 'api/Candidate/ShareProfileLink',
  getCandidateBackground: 'api/Candidate/GetCandidateBackground',
  getCandidateCV: 'api/Candidate/GetCandidateCv',
  getFilteredUserId: 'api/Candidate/GetFilteredUserId',
  getPersonalInformation: 'api/Candidate/GetPersonalInformation',
  getActivities: 'api/Candidate/GetActivities',

  // Candidate Landing
  unsubscribeFromRequestMails: 'api/RequestCandidate/UnsubscribeFromEmails',
  timeoutCheckForRequest: 'api/RequestCandidate/RequestCandidateTimeoutCheck',
  requestResponseForCandidate: 'api/RequestCandidate/RequestCandidateResponse',
  // Request Candidate Filter
  getRequestCandidates: 'api/Candidate/GetRequestCandidates',
  sendRequest: 'api/RequestCandidate/SendRequest',
};
