import React, { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Common/Components/Header';
import CandidateContentBody from '../Components/CandidateContentBody';
import {
  candidateEmails,
  candidateId,
  reduxDefaultAssessmentId
} from '../../redux/FilterDetail/selectors';
import { userSelector } from '../../redux/auth/selectors';
import { getCurrentPlanAction, getCurrentUsageAction } from '../../redux/plan/actions';

import mixpanel from '../../utils/mixpanel';
import RejectCandidatesModal from '../../Assessment/NewAssessmentDetail/Modals/RejectionMail/RejectCandidatesModal';
import RejectSuccessModal from '../../Assessment/NewAssessmentDetail/Modals/RejectionMail/RejectSuccessModal';
import {
  showRejectPopupSelector,
  showRejectSuccessSelector
} from '../../redux/candidate/selectors';
import { setShowRejectPopup, setShowRejectSuccess } from '../../redux/candidate/slice';
import { candidateReportMoveCandidateToFunnel } from '../../utils/helpers';
import { funnelInfoSelector } from '../../redux/assessmentDetailVol2/selectors';
import { setFunnelInfo } from '../../redux/assessmentDetailVol2/slice';

export const UserContext = createContext();

function CandidatePage() {
  // const { id } = useParams(); // Candidate Id'yi çekiyor
  const defaultAssessmentId =
    useSelector(reduxDefaultAssessmentId) || localStorage.getItem('defaultAssessmentId');
  const candidateEmail = useSelector(candidateEmails) || localStorage.getItem('candidateEmail');
  const candID = useSelector(candidateId) || localStorage.getItem('candId');

  const currentUser = useSelector(userSelector);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(defaultAssessmentId);
  const [selectedAssessmentName, setSelectedAssessmentName] = useState(null); // Aday sayfası header kısmına paslanıyor
  const dispatch = useDispatch();

  // Mixpanel variable
  const [candidateInfos, setCandidateInfos] = useState();
  const [candidateAssessmentsInfo, setcandidateAssessmentsInfo] = useState();

  useEffect(() => {
    const data = {
      CompanyId: currentUser?.companyId
    };
    dispatch(getCurrentPlanAction(data));
    dispatch(getCurrentUsageAction({ companyId: currentUser?.companyId }));
  }, []);

  useEffect(() => {
    if (candidateInfos && candidateAssessmentsInfo && currentUser) {
      mixpanel.track('Candidate Detail - Pageview', {
        'User Id': currentUser?.userId,
        'Company Name': currentUser?.companyName,
        'Name Surname': currentUser?.userNameSurname,
        Email: currentUser?.email,
        'User Type': currentUser?.userGroupType,

        'Assessment Name': candidateAssessmentsInfo?.reservedName,
        'Assessment Industry Average': candidateAssessmentsInfo?.allScoreAverage,
        'Assessment Id': candidateAssessmentsInfo?.companyAssessmentId,

        'Invited by Email Flag': candidateAssessmentsInfo?.arriveType === 10,
        'Is Suspect Flag': candidateAssessmentsInfo?.isSuspect,
        'Candidate Name': candidateInfos?.name,
        'Candidate Email': candidateInfos?.email,
        'Candidate Score': candidateAssessmentsInfo?.score,

        'Candidate Color':
          Math.round(candidateAssessmentsInfo?.score) >= candidateAssessmentsInfo?.allScoreAverage
            ? 'green'
            : Math.round(candidateAssessmentsInfo?.score) >=
              candidateAssessmentsInfo?.allScoreAverage * 0.8
            ? 'yellow'
            : 'red'
      });
    }
  }, [candidateInfos, candidateAssessmentsInfo, currentUser]);

  const showRejectionPopup = useSelector(showRejectPopupSelector);
  const [showRejectionPopup2, setShowRejectionPopup2] = useState(false);
  const showRejectionSuccess = useSelector(showRejectSuccessSelector);
  const [showRejectionSuccess2, setShowRejectionSuccess2] = useState(false);
  const [isMailSentFlag, setIsMailSentFlag] = useState(false);
  const funnels = useSelector(funnelInfoSelector);

  useEffect(() => {
    if (showRejectionPopup) {
      setShowRejectionPopup2(true);
    } else {
      setTimeout(() => {
        setShowRejectionPopup2(false);
      }, 300);
    }
  }, [showRejectionPopup]);

  useEffect(() => {
    if (showRejectionSuccess) {
      setShowRejectionSuccess2(true);
    } else {
      setTimeout(() => {
        setShowRejectionSuccess2(false);
      }, 300);
    }
  }, [showRejectionSuccess]);

  return (
    <>
      <UserContext.Provider
        value={[
          candidateEmail,
          currentUser?.userId,
          [selectedAssessmentId, setSelectedAssessmentId],
          [selectedAssessmentName, setSelectedAssessmentName],
          candID,
          currentUser,
          // Mixpanel
          setCandidateInfos,
          setcandidateAssessmentsInfo
        ]}
      >
        <Header />
        <CandidateContentBody />
      </UserContext.Provider>

      {showRejectionPopup2 ? (
        <RejectCandidatesModal
          show={showRejectionPopup}
          onClose={() => dispatch(setShowRejectPopup(false))}
          showSuccess={() => dispatch(setShowRejectSuccess(true))}
          setMailSentFlag={setIsMailSentFlag}
          moveMethod={async () => {
            await candidateReportMoveCandidateToFunnel(
              funnels?.filter(funnel => funnel.funnelName === 'Rejected')[0],
              selectedAssessmentId,
              candID,
              dispatch
            );
            await dispatch(setFunnelInfo([]));
          }}
          candDetailAssessmentId={selectedAssessmentId}
        />
      ) : null}

      {showRejectionSuccess2 ? (
        <RejectSuccessModal
          show={showRejectionSuccess}
          onClose={() => dispatch(setShowRejectSuccess(false))}
          isMailSent={isMailSentFlag}
          selectedCandCount={1}
        />
      ) : null}
    </>
  );
}

export default CandidatePage;
