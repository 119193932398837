import React from 'react';
import styles from './QuestionReport.module.css';
import QuestionReportBody from './QuestionReportBody';

const QuestionReport = ({ qInfo, index }) => {
  return (
    <div className={styles.QuestionWrapper}>
      <div className={styles.InfoRow}>
        <text className={`${styles.noWrapper}`}>{index + 1}</text>
        <text className={`${styles.headerTextFlexGrow}`}>{qInfo.questionTitle}</text>
        <text
          className={`${styles.tabSwitch} ${styles.label}`}
          style={qInfo.tabSwitch > 0 ? {marginRight: '28px', width: '30px', minWidth: '30px'} : { color: '#393939', marginRight: '28px', width: '30px', minWidth: '30px'}}
        >
          {qInfo.tabSwitch > 0 ? qInfo.tabSwitch : '-'}
        </text>
        <text className={`${styles.label}`}>
          {qInfo.time % 60 === 0
            ? `${qInfo.time / 60}m`
            : qInfo.time > 60
            ? `${Math.floor(qInfo.time / 60)}m ${qInfo.time % 60}s`
            : `${qInfo.time}s`}
        </text>
        <text className={`${styles.label}`}>
          {qInfo.point > 0 ? qInfo.point : 0}/{qInfo.maxPoints}
        </text>
      </div>
      <div className={styles.QuestionRow}>
        <QuestionReportBody
          type={qInfo.questionTypeId}
          qBody={qInfo.questionDescription}
          mcCorrectAnswer={qInfo.questionAnswerOption}
          mcSelectedAnswer={qInfo.candidateAnswerOption}
          mcOptions={qInfo.options}
          ftCandidateAnswer={qInfo.candidateAnswerFreetext}
          cdCandidateAnswer={qInfo.candidateAnswerCode}
          cdCandidateCodeLang={qInfo.questionCategory}
          imageOpt={qInfo.imageOption}
        />
      </div>
      <div className={styles.QuestionLabelRow}>
        <text>
          {qInfo.questionTypeId === 1
            ? 'Multiple Choice'
            : qInfo.questionTypeId === 2
            ? 'Coding'
            : 'Free Text'}
        </text>
        <text>&#x2022; &nbsp; {qInfo.difficulty}</text>

        {qInfo.point >= qInfo.maxPoints ? ( // Max point
          <div className={`${styles.QuestionCorrectness} ${styles.GreenBackground} `}>CORRECT</div>
        ) : qInfo.point === 0 ? ( // 0 point
          qInfo.questionTypeId === 1 && qInfo.candidateAnswerOption === -1 ? ( // Multiple choice ise ve candidateAnswerOption alanı -1 ise aday soruyu skiplemiş demek, bu durumda da Not Answered basmak istiyoruz
            <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
              NOT ANSWERED
            </div>
          ) : qInfo.questionTypeId === 3 &&
            (qInfo.candidateAnswerFreetext === '' ||
              qInfo.candidateAnswerFreetext === 'null' ||
              qInfo.candidateAnswerFreetext === 'Null' ||
              qInfo.candidateAnswerFreetext === null) ? ( // Free text ise ve candidateAnswerFreetext alanı boşsa ("", "null", "Null", null), Not Answered basmak istiyoruz
            <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
              NOT ANSWERED
            </div>
          ) : (
            <div className={`${styles.QuestionCorrectness} ${styles.RedBackground} `}>WRONG</div>
          )
        ) : qInfo.point === -1 ? ( // -1 point
          <div className={`${styles.QuestionCorrectness} ${styles.GreyBackground} `}>
            NOT ANSWERED
          </div>
        ) : (
          // Partial point
          <div className={`${styles.QuestionCorrectness} ${styles.YellowBackground} `}>
            PARTIALLY CORRECT
          </div>
        )}
      </div>
    </div>
  );
};
export default QuestionReport;
