import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  settingsPageErrorMessage,
  settingsPageErrorShow,
  userSelector
} from '../../../redux/auth/selectors';
import { setSettingsPageErrorShow } from '../../../redux/auth/slice';
import { currentPlanRedux } from '../../../redux/plan/selectors';
import SettingsBar from '../../Components/SettingsBar';
import TeamTab from './Team/TeamTab';
import GeneralTab from './GeneralTab/GeneralTab';
import BillingPage from './Billing/BillingPage';
import PlanTab from './PlanTab/PlanTab';
import ProfileTab from './ProfileTab/ProfileTab';
import styles from './Settings.module.css';
import Header from '../../../Common/Components/Header';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { availableSpaceSelector } from '../../../redux/user/selectors';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';

const SettingsPage = () => {
  const { place } = useParams();
  const dispatch = useDispatch();
  const availableWindowH = useSelector(availableSpaceSelector);

  const errorText = useSelector(settingsPageErrorMessage);
  const errorShow = useSelector(settingsPageErrorShow);
  const currentPlan = useSelector(currentPlanRedux);
  const currentUser = useSelector(userSelector);
  const disabledToSeeBillingTab =
    (!currentUser?.isAdmin && currentUser?.userGroupType !== 'Super Admin') ||
    currentPlan?.name === 'Free Trial' ||
    currentPlan?.isCustom;
  // const unsubscribeShow = useSelector(unsubscribeModalShow);
  // const unsubscribeSuccess = useSelector(unsubscribeSuccessShow);
  // const unsubscribePlanSuccess = useSelector(planUnsubscribedModalShow);
  // const abortDowngradeSuccess = useSelector(abortDowngradeSuccessShow);
  // const contactSalesSuccessModal = useSelector(contactSalesSuccess);
  // const currentPlan = useSelector(currentPlanRedux);

  const getSettingsTab = place => {
    if (place.startsWith('billing')) {
      return <BillingPage disabled={disabledToSeeBillingTab} />;
    }
    if (place.startsWith('plan')) {
      return <PlanTab />;
    }
    if (place.startsWith('general')) {
      return <GeneralTab />;
    }
    if (place.startsWith('myprofile')) {
      return <ProfileTab />;
    }
    if (place.startsWith('team')) {
      return <TeamTab />;
    }
    return null;
  };

  return (
    <div className={styles.pageContainer}>
      <Header />
      <div className={styles.layoutContainer} style={{ minHeight: availableWindowH }}>
        <div className={styles.barContainer}>
          <SettingsBar />
        </div>
        {currentUser && <div className={styles.tabContainer}>{getSettingsTab(place)}</div>}
      </div>

      {/* Modals and Banners */}
      <InfoBanner
        CustomIcon={Error}
        text={errorText}
        show={errorShow}
        setStatus={e => dispatch(setSettingsPageErrorShow(e))}
      />
    </div>
  );
};

export default SettingsPage;
