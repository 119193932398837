import { createSlice } from '@reduxjs/toolkit';
import { getCandidateFunnelInfoActionVol2 } from '../assessmentDetail/actions';
import initialState from './initialState';

const CandidateReportSlice = createSlice({
    name: 'candidateReport',
    initialState,
    reducers: {
        setIsScoredRedux: (state, action) => {
            state.isScored = action.payload;
        },
    },

    extraReducers: builder => {
        builder.addCase(getCandidateFunnelInfoActionVol2.fulfilled, (state, action) => {
            state.isScored = action.payload.data.isScored;
        })
    }
});

export const { setIsScoredRedux } = CandidateReportSlice.actions;

export default CandidateReportSlice.reducer;