import { createSlice } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';

import { stateToHTML } from 'draft-js-export-html';
import {
  comparePlansAndFeaturesAction,
  contactSalesAction,
  getAddOnsAction,
  getBillingInfoAction,
  getCountriesAction,
  getCurrentPlanAction,
  getCurrentUsageAction,
  getInvoiceListAction,
  getMyCardsAction,
  getOnboardingStepsAction,
  getPackageDetailAction,
  getProfileAction,
  isAuthorizedForCandidateReportAction,
  isFirstAssessmentCheckAction,
  premiumQAvailableInAssessmentControlAction,
  purchaseAddOnAction,
  questionAvailabilityControlAction,
  upgradePackageAction
} from './actions';
import initialState from './initialState';

const PlanSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setUnsubscribeAddOnInfoCount: (state, action) => {
      state.unsubscribeAddOnInfoCount = action.payload;
    },
    setUnsubscribeAddOnItemCode: (state, action) => {
      state.unsubscribeAddOnItemCode = action.payload;
    },
    setUnsubscribeModalShow: (state, action) => {
      state.unsubscribeModalShow = action.payload;
    },
    setUnsubscribeAddOnModalShow: (state, action) => {
      state.unsubscribeAddOnModalShow = action.payload;
    },
    setAbortDowngradeSuccessShow: (state, action) => {
      state.abortDowngradeSuccessShow = action.payload;
    },
    setUnsubscribeSuccessShow: (state, action) => {
      state.unsubscribeSuccessShow = action.payload;
    },
    setPurchaseModalShow: (state, action) => {
      state.purchaseModalShow = action.payload;
    },
    setPurchaseModalPackageId: (state, action) => {
      state.purchaseModalPackageId = action.payload;
    },
    setPlanUnsubscribedModalShow: (state, action) => {
      state.planUnsubscribedModalShow = action.payload;
    },
    setEditBillingShow: (state, action) => {
      state.editBillingModalShow = action.payload;
    },
    setPaymentMethodModalShow: (state, action) => {
      state.paymentMethodModalShow = action.payload;
    },
    setContactSalesModalShow: (state, action) => {
      state.contactSalesSuccess = action.payload;
    },
    setToBeCancelledAddOnType: (state, action) => {
      state.toBeCancelledAddOnType = action.payload;
    },
    setShowPackageDetail: (state, action) => {
      state.showPackageDetail = action.payload;
    },
    setDowngradeModalShow: (state, action) => {
      state.downgradeModalShow = action.payload;
    },
    setUpgradeModalShow: (state, action) => {
      state.upgradeModalShow = action.payload;
    },
    setDowngradeSuccess: (state, action) => {
      state.downgradeSuccess = action.payload;
    },
    setUpgradeSuccess: (state, action) => {
      state.upgradeSuccess = action.payload;
    },
    setPaymentFailedShow: (state, action) => {
      state.paymentFailedShow = action.payload;
    },
    setEditCompanyInfoShow: (state, action) => {
      state.editCompanyInfoShow = action.payload;
    },
    setErrorInPlan: (state, action) => {
      state.errorInPlan = action.payload;
    },
    setErrorTextInPlan: (state, action) => {
      state.errorTextInPlan = action.payload;
    },
    setErrorInSeePlans: (state, action) => {
      state.errorInSeePlans = action.payload;
    },
    setErrorTextInSeePlans: (state, action) => {
      state.errorTextInSeePlans = action.payload;
    },
    setToBePurchasedAddOn: (state, action) => {
      state.toBePurchasedAddOn = action.payload;
    },
    setPurchaseSuccessShow: (state, action) => {
      state.purchaseSuccessShow = action.payload;
    },
    setPurchasedAddOnCode: (state, action) => {
      state.purchasedAddOnCode = action.payload;
    },
    setPaymentFailedMessage: (state, action) => {
      state.paymentFailedErrorMessage = action.payload;
    },
    setWelcomeOnboardShow: (state, action) => {
      state.welcomeOnboardShow = action.payload;
    },
    setOnboardingStep: (state, action) => {
      state.onboardingStep = action.payload;
    },
    setLogoHighlight: (state, action) => {
      state.logoHighlight = action.payload;
    },
    setCompanyHighlight: (state, action) => {
      state.companyHighlight = action.payload;
    },
    setAddAssessmentHighlight: (state, action) => {
      state.addAssessmentHighlight = action.payload;
    },
    setTeamHighlight: (state, action) => {
      state.teamHighlight = action.payload;
    },
    setAddLogoBoxHighlight: (state, action) => {
      state.addLogoBoxHighlight = action.payload;
    },
    setAddNewUserHighlight: (state, action) => {
      state.addNewUserHighlight = action.payload;
    },
    setSearchBarHighlight: (state, action) => {
      state.searchBarHighlight = action.payload;
    },
    setCoensioLibHighlight: (state, action) => {
      state.coensioLibHighlight = action.payload;
    },
    setCustomAssessmentHighlight: (state, action) => {
      state.customAssessmentHighlight = action.payload;
    },
    setQuickStartOpen: (state, action) => {
      state.quickStartOpen = action.payload;
    },
    setPopupFlowEnded: (state, action) => {
      state.popupFlowEnded = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getCurrentPlanAction.fulfilled, (state, action) => {
      state.currentPlan = action.payload;
      state.loading = false;
      // state.purchaseModalPackageId = action.payload;
    });
    builder.addCase(getCurrentPlanAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCurrentPlanAction.rejected, (state, action) => {
      state.loading = false;
      // state.purchaseModalPackageId = null;
      state.currentPlan = null;
    });
    builder.addCase(getCurrentUsageAction.fulfilled, (state, action) => {
      state.currentUsage = action.payload;
    });
    builder.addCase(getCurrentUsageAction.pending, (state, action) => {
      state.currentUsage = null;
    });
    builder.addCase(getAddOnsAction.fulfilled, (state, action) => {
      state.addOns = action.payload;
      state.caAddOns = action.payload?.filter(x => x.addOnCode === 20); // Candidate AddOn list
      state.asAddOns = action.payload?.filter(x => x.addOnCode === 10); // Assessment AddOn list
    });
    builder.addCase(getPackageDetailAction.fulfilled, (state, action) => {
      state.packageDetail = action.payload;
    });
    builder.addCase(getPackageDetailAction.pending, (state, action) => {
      state.packageDetail = null;
    });
    builder.addCase(getBillingInfoAction.fulfilled, (state, action) => {
      state.billingInfo = action.payload;
    });
    builder.addCase(getMyCardsAction.fulfilled, (state, action) => {
      state.cardInfo = action.payload;
    });
    builder.addCase(getCountriesAction.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(comparePlansAndFeaturesAction.fulfilled, (state, action) => {
      state.plansDetailJSON = action.payload;
    });
    builder.addCase(contactSalesAction.fulfilled, (state, action) => {
      state.contactSalesSuccess = true;
    });
    builder.addCase(contactSalesAction.rejected, (state, action) => {
      state.contactSalesSuccess = true;
    });
    builder.addCase(getProfileAction.fulfilled, (state, action) => {
      state.profileObj = action.payload;
    });
    builder.addCase(premiumQAvailableInAssessmentControlAction.fulfilled, (state, action) => {
      state.premiumQControlResult = action.payload;
    });
    builder.addCase(questionAvailabilityControlAction.fulfilled, (state, action) => {
      state.premiumQuestionCheck = action.payload;
    });
    builder.addCase(purchaseAddOnAction.rejected, (state, action) => {
      state.paymentFailedErrorMessage = action.payload?.message;
    });
    builder.addCase(upgradePackageAction.rejected, (state, action) => {
      state.paymentFailedErrorMessage = action.payload?.message;
    });
    builder.addCase(isAuthorizedForCandidateReportAction.fulfilled, (state, action) => {
      state.isAuthorizedCR = action.payload;
    });
    builder.addCase(getInvoiceListAction.fulfilled, (state, action) => {
      state.invoiceList = action.payload;
    });
    builder.addCase(getOnboardingStepsAction.fulfilled, (state, action) => {
      if (!isMobile) {
        if (
          state.onboardingSteps.createAssessment === false ||
          state.onboardingSteps.inviteTeammates === false ||
          state.onboardingSteps.addLogo === false ||
          state.onboardingSteps.customAssessment === false
        ) {
          localStorage.setItem('onboarding', true);
          // document.cookie = `onboarding=DONE;max-age=${1 * 24 * 60 * 60 * 1000}; path=/`;
        }

        state.stepsReady = true;
        state.onboardingSteps = action.payload;
        const a = Object.values(action.payload);
        const b = Object.entries(action.payload);
        const temp = a.filter(bool => bool === true).length;
        state.completedOnboardingNo = temp;
        if (action.payload.createAssessment === false) {
          state.onboardingStep = 1;
        } else if (action.payload.inviteTeammates === false) {
          state.onboardingStep = 2;
        } else if (action.payload.addLogo === false) {
          state.onboardingStep = 3;
        } else if (action.payload.customAssessment === false) {
          state.onboardingStep = 4;
        } else {
          state.onboardingStep = 10;
        }
      } else {
        state.completedOnboardingNo = 4;
      }
    });

    builder.addCase(getOnboardingStepsAction.pending, (state, action) => {
      if (
        state.onboardingSteps.createAssessment === false ||
        state.onboardingSteps.inviteTeammates === false ||
        state.onboardingSteps.addLogo === false ||
        state.onboardingSteps.customAssessment === false
      ) {
        state.stepsReady = true;
      } else {
        state.stepsReady = false;
      }
    });
    builder.addCase(getOnboardingStepsAction.rejected, (state, action) => {
      state.stepsReady = true;
    });
    builder.addCase(isFirstAssessmentCheckAction.rejected, (state, action) => {
      state.isFirstAssm = action.payload;
    });
  }
});

export const {
  setUnsubscribeModalShow,
  setUnsubscribeAddOnModalShow,
  setUnsubscribeSuccessShow,
  setPurchaseModalShow,
  setPurchaseModalPackageId,
  setPlanUnsubscribedModalShow,
  setEditBillingShow,
  setPaymentMethodModalShow,
  setContactSalesModalShow,
  setToBeCancelledAddOnType,
  setShowPackageDetail,
  setDowngradeModalShow,
  setUpgradeModalShow,
  setDowngradeSuccess,
  setUpgradeSuccess,
  setPaymentFailedShow,
  setEditCompanyInfoShow,
  setUnsubscribeAddOnInfoCount,
  setUnsubscribeAddOnItemCode,
  setErrorInPlan,
  setErrorTextInPlan,
  setErrorInSeePlans,
  setErrorTextInSeePlans,
  setAbortDowngradeSuccessShow,
  setToBePurchasedAddOn,
  setPurchaseSuccessShow,
  setPurchasedAddOnCode,
  setPaymentFailedMessage,
  setWelcomeOnboardShow,
  setOnboardingStep,
  setLogoHighlight,
  setCompanyHighlight,
  setAddAssessmentHighlight,
  setTeamHighlight,
  setAddLogoBoxHighlight,
  setAddNewUserHighlight,
  setSearchBarHighlight,
  setCoensioLibHighlight,
  setCustomAssessmentHighlight,
  setQuickStartOpen,
  setPopupFlowEnded
} = PlanSlice.actions;

export default PlanSlice.reducer;
