import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTeamAction,
  makeAdminAction,
  removeFromAdminsAction
} from '../../../../redux/auth/actions';
import { resendInvitationAction } from '../../../../redux/user/actions';
import {
  setErrorInTeam,
  setErrorMessageInTeam,
  setSuccessInTeam,
  setSuccessMessageInTeam
} from '../../../../redux/auth/slice';
import { userSelector } from '../../../../redux/auth/selectors';
import TextOverflow from '../../../../Common/TextOverflow/TextOverflow';
import DeleteUserModal from '../../../Modals/DeleteUserModal';
import EditUserModal from '../Modals/EditUserModal';
import UserIcon from '../../../../Common/Components/UserIcon';
import UnsubscribeSuccessModal from '../../../Modals/UnsubscribeSuccessModal';
import { ReactComponent as Invited } from '../../../Images/invitedTick.svg';
import { ReactComponent as AdminStar } from '../../../Images/adminStar.svg';
import { ReactComponent as Dots } from '../../../Images/threeDots.svg';
import styles from './TeamTab.module.css';
import { useWindowSize } from '../../../../utils/helpers';

const TeamMember = ({
  name,
  surname,
  role,
  invited,
  isAdmin,
  userId,
  companyId,
  key,
  teamUser
}) => {
  const dispatch = useDispatch();
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth <= 576;

  const [hover, setHover] = useState(false);
  const [modalHover, setModalHover] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [position, setPosition] = useState(0);
  const [positionTop, setPositionTop] = useState(false);
  const [memberTextWidth, setMemberTextWidth] = useState(screenWidth / 15);

  const myUser = useSelector(userSelector);

  async function handleAdminFunctionality() {
    const userData = {
      userId: userId,
      companyId: companyId
    };
    if (hover && !isAdmin && myUser?.isAdmin && myUser?.userId !== userId) {
      const a = await dispatch(makeAdminAction(userData));
      if (a?.meta?.requestStatus === 'rejected') {
        await dispatch(setErrorInTeam(true));
        await dispatch(setErrorMessageInTeam(a?.payload?.message));
      } else {
        await dispatch(setSuccessInTeam(true));
        await dispatch(setSuccessMessageInTeam('Changes saved successfully'));
      }
      await dispatch(getTeamAction({ CompanyId: companyId }));
    } else if (hover && isAdmin && myUser?.isAdmin && myUser?.userId !== userId) {
      const b = await dispatch(removeFromAdminsAction(userData));
      if (b?.meta?.requestStatus === 'rejected') {
        await dispatch(setErrorInTeam(true));
        await dispatch(setErrorMessageInTeam(b?.payload?.message));
      } else {
        await dispatch(setSuccessInTeam(true));
        await dispatch(setSuccessMessageInTeam('Changes saved successfully'));
      }
      await dispatch(getTeamAction({ CompanyId: companyId }));
    }
  }

  async function resendInvitation() {
    const data = {
      userId: userId
    };
    const a = await dispatch(resendInvitationAction(data));
    if (a?.meta?.requestStatus === 'rejected') {
      await dispatch(setErrorInTeam(true));
      await dispatch(setErrorMessageInTeam(a?.payload?.message));
    } else {
      await dispatch(setSuccessInTeam(true));
      await dispatch(setSuccessMessageInTeam('Invitation mail has been sent'));
    }
  }

  useEffect(() => {
    if (!hover && !modalHover) {
      setEditOpen(false);
    }
  }, [hover]);

  useEffect(() => {
    if (modalHover) {
      setHover(true);
    }
    if (!modalHover) {
      setHover(false);
    }
  }, [modalHover]);

  useEffect(() => {
    const { top } = document.getElementById(userId).getBoundingClientRect();
    const { height } = document.getElementById(userId).getBoundingClientRect();
    const body = document.body.offsetHeight;
    const wrapperList = document?.getElementById('usersWrapper');

    wrapperList.addEventListener('scroll', e => {
      setHover(false);
    });

    if (body - 120 < top + height) {
      setPositionTop(true);
    } else {
      setPositionTop(false);
    }
    setPosition(top + height);
  });

  useEffect(() => {
    const teamWrapper = document?.getElementById('team-page')?.getBoundingClientRect()?.width;
    if (teamWrapper) {
      if (isMobile) {
        setMemberTextWidth(teamWrapper - 50 - 30 - 30 - 30 - 30);
      } else if (screenWidth < 720) {
        setMemberTextWidth(teamWrapper - 50 - 230);
      } else if (isAdmin) {
        // 250: right column, 50: padding, 90: admin, 30: user icon etc.
        setMemberTextWidth(teamWrapper - 230 - 50 - 90);
      } else {
        setMemberTextWidth(teamWrapper - 230 - 50 - 30 - 30);
      }
    }
  }, [screenWidth, isAdmin, invited]);

  const ref = useRef();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // setEditOpen(false)
          setHover(false);
        }
      }
      // Bind the event listener
      if (isMobile && editOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, isMobile, editOpen]);
  }

  useOutsideAlerter(ref);

  const [editModalShowState2, setEditModalShowState2] = useState();

  useEffect(() => {
    if (editModalShow) {
      setEditModalShowState2(true);
    } else {
      setTimeout(() => {
        setEditModalShowState2(false);
      }, 300);
    }
  }, [editModalShow]);

  return (
    <>
      {editModalShowState2 && (
        <EditUserModal
          show={editModalShow}
          onClose={() => setEditModalShow(false)}
          user={teamUser}
          type="editTeamUser"
        />
      )}

      <DeleteUserModal
        setDeleteSuccess={setSuccessShow}
        userId={userId}
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        title="Are you sure?"
        description={`Deleted users will no longer have access to Coensio. Do you want to delete ${name} ${surname}?
        `}
      />
      <UnsubscribeSuccessModal
        show={successShow}
        onHide={() => setSuccessShow(false)}
        title="Successfully Deleted!"
        footer="*The user is deleted and cannot reach Coensio anymore."
        icon="tick"
      />
      <div
        id={userId}
        className={hover ? styles.memberWrapperHover : styles.memberWrapper}
        onMouseEnter={() => {
          if (!isMobile) setHover(true);
        }}
        onMouseLeave={() => {
          if (!isMobile) setHover(false);
        }}
      >
        <div className={styles.nameWrapper}>
          <div className={styles.iconWrapper}>
            <UserIcon
              textClassName={styles.userIconText}
              comment
              name={name.split(' ')[0]}
              surname={surname}
              className={!invited ? styles.activeUserIcon : styles.passiveUserIcon}
            />
          </div>
          {/* <span className={styles.nameText} id={`teamMember-${key}`}>
            {name.concat(` ${surname}`)}{' '}
          </span> */}
          <TextOverflow
            text={name.concat(` ${surname}`)}
            itemId={`teamMember-${key}`}
            textClassName={styles.nameText}
            width={memberTextWidth}
          />

          {isAdmin && screenWidth > 720 ? (
            <div className={styles.adminBox}>
              <AdminStar className={styles.starIcon} />
              <span className={styles.adminText}>Admin</span>
            </div>
          ) : screenWidth <= 720 ? (
            <div className={styles.adminStar}>
              <AdminStar className={styles.starIcon} />
            </div>
          ) : null}
        </div>
        <div className={styles.roleWrapper} isMobile={JSON.stringify(isMobile)}>
          {!isMobile && (
            <div>
              {invited ? (
                <div className={styles.invitedWrapper}>
                  {!hover && <Invited />}
                  <span
                    className={hover ? styles.inviteAgainText : styles.invitedText}
                    onClick={() => {
                      if (hover) {
                        resendInvitation();
                      }
                    }}
                  >
                    {hover ? 'Send Invitation Again' : 'Invited!'}
                  </span>
                </div>
              ) : (
                <span
                  onClick={() => {
                    handleAdminFunctionality();
                  }}
                  className={
                    hover && !isAdmin && myUser?.isAdmin && myUser?.userId !== userId
                      ? styles.adminTextHover
                      : hover && isAdmin && myUser?.isAdmin && myUser?.userId !== userId
                      ? styles.adminTextHover2
                      : styles.roleText
                  }
                >
                  {hover && !isAdmin && myUser.isAdmin && myUser?.userId !== userId
                    ? `Make Admin`
                    : hover && isAdmin && myUser.isAdmin && myUser?.userId !== userId
                    ? `Dismiss as Admin`
                    : role}
                </span>
              )}
            </div>
          )}
          <div
            className={styles.roleDots}
            onClick={() => {
              setEditOpen(!editOpen);
              setHover(true);
            }}
            isMobile={JSON.stringify(isMobile)}
          >
            <Dots />
          </div>
        </div>
      </div>
      {editOpen && (
        <div
          style={{ top: positionTop ? position - 140 : position }}
          className={styles.editModal}
          id={`editOpen-${teamUser?.userId}`}
          ref={ref}
          onMouseEnter={() => {
            if (!isMobile) {
              setModalHover(true);
            }
          }}
          onMouseLeave={() => {
            if (!isMobile) {
              setModalHover(false);
            }
          }}
        >
          <div
            className={styles.editWrapper}
            onClick={() => {
              setEditModalShow(true);
              setHover(false);
              setModalHover(false);
            }}
          >
            <span>Edit</span>
          </div>
          <div
            className={styles.deleteWrapper}
            onClick={() => {
              setDeleteModalShow(true);
              setHover(false);
              setModalHover(false);
            }}
          >
            <span>Delete</span>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamMember;
