/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TabSwitch from '../../../../Common/Components/TabSwitch';
import { setAssessmentActiveTab } from '../../../../redux/assessmentsVol2/slice';
import {
  candidateCvPathSelector,
  candidateProfileSelector
} from '../../../../redux/candidateDetail/selectors';
import { useWindowSize } from '../../../../utils/helpers';
import OverviewTab from './OverviewTab';
import styles from './ProfileTab.module.css';
import ResumeTab from './ResumeTab';
import { ReactComponent as LockedCV } from './lockedCV.svg';
import constants from '../../../../utils/constants';

const ProfileTab = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const candidateCV = useSelector(candidateCvPathSelector);
  const candidateProfile = useSelector(candidateProfileSelector);

  const isRequestCandidateFlag =
    candidateProfile?.status === constants.REQUEST_CANDIDATE_OBJECT_STATUS;
  const isNewopCandidateFlag =
    candidateProfile?.status === constants.NEWOP_LOCKED_CANDIDATE_OBJECT_STATUS;
  const isRequestLockedFlag =
    isRequestCandidateFlag &&
    (candidateProfile?.requestStatus !== constants.REQUEST_STATUS_ACCEPTED ||
      (candidateProfile?.requestStatus === constants.REQUEST_STATUS_ACCEPTED &&
        candidateProfile?.requestPayStatus === false));

  const isLockedFlag = isRequestLockedFlag || isNewopCandidateFlag;

  useEffect(() => {
    dispatch(setAssessmentActiveTab(0));
    return () => {
      dispatch(setAssessmentActiveTab(0));
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <TabWrapper>
        <TabSwitch
          tabIconList={[null, isLockedFlag ? <LockedCV /> : null]}
          isDisabledList={[false, !candidateCV?.path || isLockedFlag]}
          tabNameList={['Overview', 'Resume']}
          tooltipList={[
            null,
            isLockedFlag
              ? 'You are unable to see locked candidates resumes.'
              : !candidateCV?.path
              ? 'The reminder email has been sent to the candidate. Please wait for the candidate to upload a CV.'
              : null
          ]}
          onClickFuncList={[
            () => {
              setActiveTab(0);
            },
            () => {
              setActiveTab(1);
            }
          ]}
          height="37px"
          myStyle={{ fontSize: '16px', lineHeight: '23px' }}
          backgroundColor="#7458D3"
        />
      </TabWrapper>

      <>
        <div
          style={{
            visibility: activeTab === 1 ? 'hidden' : 'visible',
            maxHeight: activeTab === 1 && 0
          }}
        >
          <OverviewTab />
        </div>

        <div style={{ visibility: activeTab === 0 ? 'hidden' : 'visible' }}>
          <ResumeTab isHidden={activeTab === 0} />
        </div>
      </>
    </div>
  );
};

export default ProfileTab;

const TabWrapper = ({ children }) => {
  const [screenW, screenH] = useWindowSize();
  return screenW > 576 ? <>{children}</> : <div style={{ marginLeft: '20px' }}>{children}</div>;
};
