import { React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import QuestionList from './QuestionList';
import styles from '../Styles/QuestionList.module.css';
import { rootAssessmentQuestionList } from '../../../redux/assessmentsVol2/selectors';

export default function SkillBasedLeftColumn() {
  const questions = useSelector(rootAssessmentQuestionList);
  const [change, setChange] = useState(false);

  useEffect(() => {
    setChange(!change);
  }, [questions]);

  return (
    <div className={styles.wrapper}>
      <QuestionList
        qType="Coding"
        qList={questions?.filter(q => q.questionType === 2)}
        listChange={change}
      />
      <QuestionList
        qType="Multiple Select"
        qList={questions?.filter(q => q.questionType === 1)}
        listChange={change}
      />
      <QuestionList
        qType="Free-Text"
        qList={questions?.filter(q => q.questionType === 3)}
        listChange={change}
      />
    </div>
  );
}
