import React from 'react';
import styles from './QuestionBar.module.css';

const QuestionBar = () => {
  return (
    <div className={styles.BarWrapper}>
      <text className={`${styles.noWrapper}`}>No</text>
      <text className={`${styles.headerTextFlexGrow}`}>Question Title</text>
      <text className={`${styles.label}`} style={{marginRight: '28px', width: '70px'}}>Tab Switch</text>
      <text className={`${styles.label}`}>
        Time Taken
      </text>
      <text className={`${styles.label}`}>Score</text>
    </div>
  );
};
export default QuestionBar;
