/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../../../utils/helpers';
import styles from './Experience.module.css';
import { ReactComponent as Lock } from './lockIcon.svg';
import { ReactComponent as EducationIcon } from './educationIcon.svg';
import { candidateBackgroundSelector } from '../../../../redux/candidateDetail/selectors';
import CustomButton from '../../../../Common/Components/CustomButton';
import constants from '../../../../utils/constants';

const Education = () => {
  const [windowW, windowH] = useWindowSize();
  const education = useSelector(candidateBackgroundSelector);
  const [eduNoAvailable, setEduNoAvailable] = useState(2);

  const lockedFlag =
    education?.isLocked ||
    (education?.requestStatus &&
      (education?.requestStatus !== constants.REQUEST_STATUS_ACCEPTED ||
        (education?.requestStatus === constants.REQUEST_STATUS_ACCEPTED && education?.requestPayStatus === false)));

  return (
    <>
      {education?.educations ? (
        <div className={styles.wrapper}>
          <span className={styles.header}>Education</span>

          {education?.educations?.slice(0, eduNoAvailable)?.map((edu, i) => (
            <div className={styles.rowWrapper}>
              <div className={styles.companyLogo}>
                {/* TO-DO: logo present case */}
                {edu?.schoolLogo && edu?.schoolLogo !== 'Coensio' ? (
                  <img className={styles.logoImg} src={edu?.schoolLogo} alt={`schoolLogo-${i}`} />
                ) : (
                  <EducationIcon />
                )}
              </div>

              <div className={styles.textWrapper}>
                <span className={styles.subHeader}>{edu?.degree}</span>
                {lockedFlag ? <Lock /> : null}
                {!lockedFlag ? <span className={styles.companyText}>{edu?.schoolName}</span> : null}
                <span className={styles.experienceDate}>{edu?.date}</span>
              </div>
            </div>
          ))}
          {education?.educations?.length > 2 && eduNoAvailable !== education?.educations?.length ? (
            <CustomButton
              type={5}
              textField={`Show All Education (${education?.educations?.length})`}
              customStyle={{ width: '100%' }}
              size="small"
              buttonOnClick={() => setEduNoAvailable(education?.educations?.length)}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Education;
