import React, { useState } from 'react';
import styles from '../Styles/AssessmentQuestion.module.css';
import coding from '../../../images/coding_assessment.svg';
import multiple from '../../../images/multiple_assessment.svg';
import freeText from '../../../images/freetext_assessment.svg';

const calculateMin = timeInit => {
  const timeInSeconds = parseInt(timeInit, 10);
  const time = {
    min: Math.floor(timeInSeconds / 60),
    sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
  };
  if (time.sec === 0) {
    return `${time.min} min`;
  }
  if (time.min === 0) {
    return `${time.sec} sec`;
  }
  return `${time.min} min ${time.sec} sec`;
};
export const FreeTextQuestions = ({ qDesc, category, time, index, title, difficulty }) => {
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.freeTextWrapper}>
        <div className={styles.headerRow}>
          <div>{index ? `${index}. ${title}` : `${title}`}</div>
          {difficulty && <div className={styles.difficulty}>{difficulty}</div>}
        </div>
        <div className={styles.innerBody}>
          <div
            className={styles.freeTextQuestionText}
            dangerouslySetInnerHTML={{ __html: qDesc }}
          />
        </div>
        <div className={styles.infoRow}>
          <img width={33} height={24} alt="" src={freeText} />
          <div className={styles.category}>{category}</div>
          <div className={styles.time}>{calculateMin(parseInt(time, 10))}</div>
        </div>
      </div>
    </div>
  );
};

export const CodingQuestion = ({ qDesc, category, time, index, title, difficulty }) => {
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.codingWrapper}>
        <div className={styles.headerRow}>
          <div>{index ? `${index}. ${title}` : `${title}`}</div>
          {difficulty && <div className={styles.difficulty}>{difficulty}</div>}
        </div>
        <div className={styles.innerBody}>
          <div className={styles.codingQuestionText} dangerouslySetInnerHTML={{ __html: qDesc }} />
        </div>
        <div className={styles.infoRow}>
          <img width={33} height={24} alt="" src={coding} />
          <div className={styles.category}>{category}</div>
          <div className={styles.time}>{calculateMin(parseInt(time, 10))}</div>
        </div>
      </div>
    </div>
  );
};

function parseAnswer(item) {
  let text = item;
  if (text.includes(`<img src="questions/`)) {
    text = text.replaceAll(`<img src="`, `<img src="${process.env.REACT_APP_IMAGE_API}/`);
    return text;
  }
  return '';
}

const alignedText = item => {
  let temp = item;
  temp = temp.replaceAll('text-align:right;', 'margin-left:auto;');
  temp = temp.replaceAll('text-align:left;', 'margin-right:auto;');
  temp = temp.replaceAll('text-align:none;', 'margin-right:auto;margin-left:auto;');
  return temp;
};
export const MultipleChoiceQuestion = ({ qDesc, mcOptions, title, index, category, time, difficulty, imageOption }) => {
  let opt = [];
  try {
    opt = JSON.parse(mcOptions);
  } catch (error) {
    console.log(error, 'error on parsing multiple choice options');
    console.log(mcOptions);
  }
  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.multipleChoiceWrapper}>
        <div className={styles.headerRow}>
          <div>{index ? `${index}. ${title}` : `${title}`}</div>
          {difficulty && <div className={styles.difficulty}>{difficulty}</div>}
        </div>
        <div className={styles.innerBody}>
          <div
            className={styles.multipleChoiceQuestionText}
            dangerouslySetInnerHTML={{ __html: qDesc }}
          />
          {opt?.map((item, index) => (
            <div key={index} className={styles.Option}>
              <div className={styles.optionWrapper}>
                <div className={`${styles.optionCheckbox}`} />
                {imageOption ? (
                  <div
                    className={styles.optionText}
                    dangerouslySetInnerHTML={{ __html: alignedText(item) }}
                  />
                ) : // <div className={styles.StringOptionWrapper}>{item}</div>
                item.includes(`<img src="questions`) ? (
                  <div dangerouslySetInnerHTML={{ __html: parseAnswer(item) }} />
                ) : (
                    <div className={`${styles.optionText} `}>{item}</div>
                )}
              </div>

            </div>
          ))}
        </div>
        <div className={styles.infoRow}>
          <img width={33} height={24} alt="" src={multiple} />
          <div className={styles.category}>{category}</div>
          <div className={styles.time}>{calculateMin(parseInt(time, 10))}</div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line consistent-return
const AssessmentQuestion = ({ question, time, type, options, title, index, category, difficulty = null, imageOption }) => {
  const [state, setState] = useState();

  let qDesc = [];
  try {
    qDesc = JSON.parse(question);
    // qDesc = qDesc.replaceAll("https://api.coens.io", process.env.REACT_APP_IMAGE_API);
    qDesc = qDesc.replaceAll('https://api.coens.io/', '');
    qDesc = qDesc.replaceAll('https://file.coens.io/', '');
    qDesc = qDesc.replaceAll('<img src="', `<img src="${process.env.REACT_APP_IMAGE_API}/`);
  } catch (error) {
    console.log(error, 'error on parsing question body');
    console.log(question);
  }

  switch (type) {
    case 1:
      return (
        <MultipleChoiceQuestion
          category={category}
          index={index}
          title={title}
          qDesc={qDesc}
          time={time}
          mcOptions={options}
          difficulty={difficulty}
          imageOption={imageOption}
        />
      );
    case 2:
      return (
        <CodingQuestion index={index} title={title} qDesc={qDesc} time={time} category={category} difficulty={difficulty} />
      );
    case 3:
      return (
        <FreeTextQuestions
          index={index}
          title={title}
          qDesc={qDesc}
          time={time}
          category={category}
          difficulty={difficulty}
        />
      );
    default:
      break;
  }
};

export default AssessmentQuestion;
