import React from 'react';
import styles from '../Styles/ConnectionItem.module.css';
import Checkbox from '../../../Common/Components/Checkbox';
import linkIcon from '../../../images/LinkedinCand.svg';
import kariyerIcon from '../../../images/kariyer.svg';
import breezyIcon from '../../../images/breezy.svg';
import recruiteeIcon from '../../../images/recruitee.svg';
import successFactors from '../../../images/successFactors.svg';
import lever from '../../../images/lever.svg';
import smartRecruiter from '../../../images/smartRecruiter.svg';
import avature from '../../../images/avature.svg';
import workable from '../../../images/workable.svg';
import jazzhr from '../Images/Connections/jazzhr.svg';

const RadioButton = ({ active }) => {
  return (
    <div className={`${styles.radioContainer} ${active && styles.active}`}>
      {active && <div className={styles.radioButton} style={active ? { opacity: 1 } : {}} />}
    </div>
  );
};

export default function ConnectionItem({
  active,
  text,
  ats,
  myonClick,
  index = null,
  type,
  mobile
}) {
  const getLogo = text => {
    switch (text) {
      case 'Kariyer.net':
        return kariyerIcon;
      case 'Breezy':
        return breezyIcon;
      case 'Recruitee':
        return recruiteeIcon;
      case 'SuccessFactors':
        return successFactors;
      case 'Lever':
        return lever;
      case 'SmartRecruiters':
        return smartRecruiter;
      case 'Avature':
        return avature;
      case 'Workable':
        return workable;
      case 'JazzHR':
        return jazzhr;
      case 'LinkedIn':
      case 'Linkedin Recruiter':
        return linkIcon;
      default:
        return null;
    }
  };

  return (
    <div
      className={
        mobile ? styles.mobileContainer : type === 2 ? styles.mainContainer2 : styles.mainContainer
      }
      style={
        index && (index + 1) % 3 === 0 && type !== 2
          ? { marginRight: 0 }
          : {}
      }
    >
      <div
        style={{ marginBottom: mobile && '14px' }}
        className={styles.container}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          myonClick(text);
        }}
      >
        <div className={styles.leftColumn}>
          {ats ? (
            <RadioButton active={active} />
          ) : (
            <Checkbox
              labelStyle={{
                cursor: 'pointer',
                marginBottom: 0,
                marginRight: 12,
                width: 16,
                height: 16,
                display: 'flex'
              }}
              myChecked={active}
              myOnClick={e => {}}
            />
          )}
          <div className={styles.text}>{text}</div>
        </div>
        {getLogo(text) && <img alt="" src={getLogo(text)} />}
      </div>
    </div>
  );
}
