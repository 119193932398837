import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../../Common/Components/Checkbox';
import CustomButton from '../../../Common/Components/CustomButton';
import UserIcon from '../../../Common/Components/UserIcon';
import {
  changeAuthorizedUsersOfAssessmentAction,
  getAssessmentInfoAction
} from '../../../redux/assessmentDetail/actions';
import { assessmentSettingInfoRedux } from '../../../redux/assessmentDetail/selectors';
import {
  setSettingsBannerMessage,
  setSettingsError,
  setSettingsSuccess
} from '../../../redux/assessmentDetail/slice';
import { getTeamAction } from '../../../redux/auth/actions';
import { teamRedux, userSelector } from '../../../redux/auth/selectors';
import styles from './AssessmentSettingsModal.module.css';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import CloseButton from '../../../Common/Components/CloseButton';

export default function AuthorizeUserModal({ show, onHide, id, mobile }) {
  const dispatch = useDispatch();

  const team = useSelector(teamRedux);
  const user = useSelector(userSelector);
  const detail = useSelector(assessmentSettingInfoRedux);
  const [authorizedUsers, setAuthorizedUsers] = useState([]);

  async function handleCheckbox(userID) {
    let temp = [...authorizedUsers];
    if (authorizedUsers.includes(userID)) {
      temp = temp.filter(e => e !== userID);
      setAuthorizedUsers(temp);
    } else {
      setAuthorizedUsers(prev => [...prev, userID]);
    }
  }

  useEffect(() => {
    dispatch(getTeamAction({ CompanyId: user?.companyId }));
    const temp = [];
    detail?.assessmentUsers?.forEach(user => {
      temp.push(user.id);
      setAuthorizedUsers(temp);
    });

    return () => {
      const temp = [];
      detail?.assessmentUsers?.forEach(user => {
        temp.push(user.id);
        setAuthorizedUsers(temp);
      });
    };
  }, [show]);

  async function handleAuthorizeUser() {
    let temp = '-';
    authorizedUsers?.forEach(e => {
      temp = temp + e + '-';
    });
    const data = {
      userIds: temp,
      companyAssessmentId: id
    };
    const resp = await dispatch(changeAuthorizedUsersOfAssessmentAction(data));
    const resp2 = await dispatch(getAssessmentInfoAction({ CompanyAssessmentId: id }));

    if (resp?.payload?.data === true) {
      // success banner
      await dispatch(setSettingsBannerMessage('Changes saved successfully'));
      await dispatch(setSettingsSuccess(true));
    } else {
      // error banner
      await dispatch(setSettingsBannerMessage(resp?.payload?.message));
      await dispatch(setSettingsError(true));
    }
    onHide();
  }

  const Web = (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        onHide();
      }}
      contentClassName={`${styles.connectionContent}`}
      dialogClassName={styles.dialogCustomModal}
    >
      <div className={styles.nameHeader}>Edit Authorized User</div>
      <div className={styles.userListWrapper}>
        {team?.companyUsers?.map((member, index) => (
          <div className={styles.userRow}>
            <div style={{ marginRight: 10 }}>
              <Checkbox
                myOnClick={() => handleCheckbox(member.userId)}
                myChecked={authorizedUsers.includes(member?.userId)}
                myDisabled={user.userId === member.userId}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <UserIcon name={member.name} surname={member.surname} />
            </div>
            <span className={styles.userNameLabel}>{`${member?.name} ${member?.surname}`}</span>
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonWrapper}>
          <CustomButton
            size="small"
            type={2}
            textField="Cancel"
            style={{ marginRight: '12px' }}
            buttonOnClick={() => {
              onHide();
            }}
          />
          <CustomButton
            size="small"
            type={1}
            textField="Save"
            isDisabled={authorizedUsers.length === 0}
            buttonOnClick={() => handleAuthorizeUser()}
          />
        </div>
      </div>
    </Modal>
  );

  const Mobile = (
    <MobileModal
      outsideClickClose
      backdropStyle={{ overflowY: 'hidden' }}
      contentStyle={{ padding: '20px', overflowY: 'hidden', minWidth: '375px' }}
      show={show}
      onClose={() => {
        onHide();
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '24px'
        }}
      >
        <div className={styles.nameHeaderMobile}>Edit Authorized User</div>
        <CloseButton onClick={() => onHide()} />
      </div>
      <div id="user-list-wrapper-mobile" className={styles.userListWrapperMobile}>
        {team?.companyUsers?.map((member, index) => (
          <div className={styles.userRow}>
            <div style={{ marginRight: 10 }}>
              <Checkbox
                myOnClick={() => handleCheckbox(member.userId)}
                myChecked={authorizedUsers.includes(member?.userId)}
                myDisabled={user.userId === member.userId}
              />
            </div>
            <div style={{ marginRight: 10 }} onClick={() => handleCheckbox(member.userId)}>
              <UserIcon name={member.name} surname={member.surname} />
            </div>
            <span
              onClick={() => handleCheckbox(member.userId)}
              className={styles.userNameLabel}
            >{`${member?.name} ${member?.surname}`}</span>
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: '10px',
          marginTop: '24px'
        }}
      >
        <CustomButton
          size="mobile"
          type={8}
          textField="Cancel"
          buttonOnClick={() => {
            onHide();
          }}
        />
        <CustomButton
          size="mobile"
          type={1}
          textField="Save"
          isDisabled={authorizedUsers.length === 0}
          buttonOnClick={() => handleAuthorizeUser()}
        />
      </div>
    </MobileModal>
  );

  return <>{!mobile ? <>{Web}</> : <>{Mobile}</>}</>;
}
