import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgressBar from '../../Common/Components/CircularProgressBar';
import styles from '../Styles/ProgressBarWrapper.module.css';
import { UserContext } from '../Pages/CandidatePage';
import { getCandidateAssessmentLabelsAction } from '../Api/action';
import CandidateInformationTable from './CandidateInformationTable';
import CandidateLabel from './CandidateLabel';
import arrow from '../Images/arrow.svg';
import Skeleton from '../../Common/Components/Skeleton';
import { getCandidatePublicLabels } from '../../redux/CandidatePublic/selector';
import { isScoredRedux } from '../../redux/CandidateReport/selector';

function ProgressBarWrapper({ isScored, purchaseSuccess }) {
  const dispatch = useDispatch();
  // Success Factors
  const publicLabels = useSelector(getCandidatePublicLabels);
  const reduxIsScored = useSelector(isScoredRedux);
  const ref = useRef();
  const [labels, setLabels] = useState();
  const [totalScore, setTotalScore] = useState(0);
  const [indAvg, setIndAvg] = useState();
  const [loading, setLoading] = useState(true);
  const [scrollIndex, setIndex] = useState(0);
  const timer = useRef();
  const [lastScrollClick, setLastScrollClick] = useState();
  // const [candidateEmail, setCandidateEmail] = useState(useContext(UserContext) ? useContext(UserContext)[0] : null);
  const candidateEmail = useContext(UserContext) ? useContext(UserContext)[0] : null;
  // const [selectedAssessmentId, setSelectedAsses] = useState(useContext(UserContext) ? useContext(UserContext)[2][0] : null);
  const selectedAssessmentId = useContext(UserContext) ? useContext(UserContext)[2][0] : null;
  const [isAuthorized, setIsAuthorized] = useState(false);

  const publicPageCheck = () => {
    if (publicLabels) {
      setLabels(publicLabels);
      let total = 0;
      publicLabels.forEach(element => {
        total += element.candidatePoint;
      });
      setTotalScore(total);
      if (publicLabels.length > 0) {
        setIndAvg(publicLabels[0].allScoreAverage);
      }
      setLoading(false);
      setIndex(0);
      setLastScrollClick('up');
      setIsAuthorized(true);
    }
  };

  useEffect(() => {
    publicPageCheck();
  }, []);

  // const hasRight = true;

  useEffect(async () => {
    if ((candidateEmail && selectedAssessmentId) || purchaseSuccess) {
      setLoading(true);
      timer.current = Date.now();
      const local = timer.current;
      const jsonData = {
        CompanyAssessmentId: selectedAssessmentId,
        Email: candidateEmail
      };
      const result = await dispatch(getCandidateAssessmentLabelsAction(jsonData));
      if (local === timer.current) {
        setLabels(
          result?.payload?.candidateAssessmentLabels?.filter(
            e => !e.labelName.startsWith('notSelected')
          )
        );
        setIsAuthorized(result?.payload?.isAuthorize);
        setTotalScore(result?.payload?.totalCandidatePoint);
        setIndAvg(result?.payload?.industryAvgScore);

        setLoading(false);
      }
      setIndex(0);
      setLastScrollClick('up');
    }
  }, [selectedAssessmentId, candidateEmail, purchaseSuccess]);

  return (
    <div className={styles.mainWrapper}>
      {loading && <Skeleton type="progress" />}
      {!loading && (reduxIsScored || isScored) && labels.length > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.LeftInnerWrapper}>
            <div className={styles.CircularWrapper}>
              <CircularProgressBar score={totalScore} avg={indAvg} />
            </div>
            <div className={styles.TableWrapper}>
              <CandidateInformationTable
                score={reduxIsScored || isScored ? totalScore : 'N/A'}
                industry={indAvg}
              />
            </div>
          </div>
          {/* eslint-disable */}
          <div
            className={`${styles.rightWrapper} ${!isAuthorized && styles.noSelect}`}
            style={!isAuthorized ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}
          >
            <div className={`${styles.scrollButton} ${styles.borderBottom}`}>
              <div
                className={styles.button}
                style={
                  lastScrollClick === 'up'
                    ? scrollIndex === 0
                      ? { visibility: 'hidden' }
                      : {}
                    : scrollIndex >= 0 && scrollIndex < 4
                    ? { visibility: 'hidden' }
                    : {}
                }
              >
                <img
                  src={arrow}
                  alt=""
                  style={{ transform: 'rotate(-90deg)' }}
                  onClick={() => {
                    if (lastScrollClick === 'up') {
                      if (scrollIndex - 4 <= 0) {
                        setIndex(0);
                        setLastScrollClick('up');
                      } else {
                        setIndex(scrollIndex - 4);
                        setLastScrollClick('up');
                      }
                    } else {
                      if (scrollIndex - 7 <= 0) {
                        setIndex(0);
                        setLastScrollClick('up');
                      } else {
                        setIndex(scrollIndex - 7);
                        setLastScrollClick('up');
                      }
                    }
                  }}
                />
              </div>
            </div>
            <div
              className={`${styles.right}`}
              ref={ref}
              style={labels.length < 4 ? { justifyContent: 'center' } : {}}
            >
              {labels.map((x, myindex) => (
                <CandidateLabel
                  x={x}
                  index={scrollIndex}
                  myindex={myindex}
                  color={
                    Math.round(totalScore) >= indAvg
                      ? 'rgba(110, 207, 161, 0.8)'
                      : Math.round(totalScore) >= indAvg * 0.8
                      ? 'rgba(255, 214, 0, 0.6)'
                      : 'rgba(255, 126, 98, 0.8)'
                  }
                />
              ))}
            </div>
            <div className={`${styles.scrollButton} ${styles.borderTop}`}>
              <div
                className={styles.button}
                style={
                  lastScrollClick === 'up'
                    ? scrollIndex <= labels.length - 1 && scrollIndex > labels.length - 5
                      ? { visibility: 'hidden' }
                      : {}
                    : scrollIndex === labels.length - 1
                    ? { visibility: 'hidden' }
                    : {}
                }
              >
                <img
                  src={arrow}
                  alt=""
                  style={{ transform: 'rotate(90deg)' }}
                  onClick={() => {
                    if (lastScrollClick === 'up') {
                      if (scrollIndex + 7 >= labels.length) {
                        setIndex(labels.length - 1);
                        setLastScrollClick('down');
                      } else {
                        setIndex(scrollIndex + 7);
                        setLastScrollClick('down');
                      }
                    } else {
                      if (scrollIndex + 4 >= labels.length) {
                        setIndex(labels.length - 1);
                        setLastScrollClick('down');
                      } else {
                        setIndex(scrollIndex + 4);
                        setLastScrollClick('down');
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProgressBarWrapper;
