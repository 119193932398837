import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/DeleteAssessmentModal.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import { deleteCandidate } from '../../../redux/assessmentsVol2/selectors';
import {
  sendNotificationForRecruiterAction,
  updateCustomAssessmentAction
} from '../../../redux/assessmentsVol2/actions';
import { useWindowSize } from '../../../utils/helpers';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import CloseButton from '../../../Common/Components/CloseButton';

export default function DeleteAssessmentModal({
  show = false,
  onHide = () => { },
  setConfirmedNavigation = () => { },
  onDelete = () => { }
}) {
  const assessmentId = useSelector(deleteCandidate);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [mobile, setMobile] = useState(false);
  const [screenW, screenH] = useWindowSize();
  const MOBILE_THRESDHOLD = 576;

  useEffect(() => {
    if (screenW < MOBILE_THRESDHOLD) {
      setMobile(true);
    } else {
      setMobile(false);
      onHide();
    }
  }, [screenW]);

  useEffect(() => {
    if (!show) {
      setLoading(false);
    }
  }, [show]);

  return (
    <>
      {mobile && (
        <MobileModal
          id="mobile-deleteassessment"
          onClose={onHide}
          show={show}
          outsideClickClose
          backdropStyle={{ overflowY: 'hidden'}}
          contentStyle={{
            padding: '2rem',
            height: `207px`,
            minWidth: '375px',
            overflow: 'hidden'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.title}>Delete Assessment</div>
            <div>
              <CloseButton onClick={onHide} />
            </div>
          </div>

          <div className={styles.subtitle}>
            Are you sure you want to delete this asssessment permanently?
          </div>

          <div className={styles.buttonWrapper}>
            <CustomButton
              customStyle={{ marginRight: '0.5rem' }}
              textField="Cancel"
              type={8}
              size="mobile"
              buttonOnClick={onHide}
            />
            <CustomButton
              customStyle={{ marginLeft: '0.5rem' }}
              textField="Delete"
              loading={loading}
              size="mobile"
              type={6}
              buttonOnClick={async () => {
                setLoading(true);
                if (assessmentId) {
                  const data = {
                    rootAssessmentId: assessmentId,
                    objStatus: 3
                  };
                  await dispatch(
                    sendNotificationForRecruiterAction({
                      type: 0,
                      rootAssessmentId: assessmentId
                    })
                  );
                  await dispatch(updateCustomAssessmentAction(data));
                  await onHide();
                  setLoading(false);
                  setConfirmedNavigation(true);
                  await onDelete(); // for assessment list to update it after deletion
                } else {
                  onHide();
                  setLoading(false);
                  setConfirmedNavigation(true);
                }

              }}
            />
          </div>
        </MobileModal>
      )}
      {!mobile && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName={styles.container}
          show={show}
          onHide={onHide}
          contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
        >
          <div className={styles.title}>Delete Assessment</div>
          <div className={styles.subtitle}>
            Are you sure you want to delete this asssessment permanently?
          </div>
          <div className={styles.row}>
            <button type="button" className={styles.cancel} onClick={onHide}>
              Cancel
            </button>
            {/* <button
          type="button"
          className={styles.delete}
          onClick={async () => {
            if (assessmentId) {
              const data = {
                rootAssessmentId: assessmentId,
                objStatus: 3
              };
              await dispatch(
                sendNotificationForRecruiterAction({ type: 0, rootAssessmentId: assessmentId })
              );
              await dispatch(updateCustomAssessmentAction(data));
              await onDelete(); // for assessment list to update it after deletion
            }
            onHide();
            setConfirmedNavigation(true);
          }}
        >
          Delete
        </button> */}
            <CustomButton
              textField="Delete"
              loading={loading}
              type={6}
              buttonOnClick={async () => {
                setLoading(true);
                if (assessmentId) {
                  const data = {
                    rootAssessmentId: assessmentId,
                    objStatus: 3
                  };
                  await dispatch(
                    sendNotificationForRecruiterAction({ type: 0, rootAssessmentId: assessmentId })
                  );
                  await dispatch(updateCustomAssessmentAction(data));
                  await onDelete(); // for assessment list to update it after deletion
                }
                onHide();
                setLoading(false);
                setConfirmedNavigation(true);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
