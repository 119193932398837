import React, { useState, useEffect, useRef } from 'react';
import styles from './Banner.module.css';

export default function Banner({
  // IMPORTANT NOTES:
  // scrollInside CANNOT be used without a size.height prop
  // center prop centers modal horizontally
  // outsideClickClose boolean controls close preference on outside click
  // position prop is mandatory since banner position is strict (if center required: bodyStyle can be filled with justifyCenter etc.)
  // initPosition specifies the initial position of the banner for better animation
  // parentRef is the reference of parent element which calls banner to show; therefore, on parent click should not be counted as outside click
  bannerId,
  show,
  onClose = () => {},
  parentRef,
  children,
  contentStyle = {},
  position = { x: 0, y: 0 },
  initPosition = { x: 0, y: 0 },
  size = {},
  scrollInside,
  outsideClickClose,
  bannerOnClick = () => {}
}) {
  const ref = useRef();

  function useOutsideAlerter(ref, outerRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          outerRef.current &&
          !outerRef.current.contains(event.target) &&
          !ref.current.contains(event.target) &&
          outsideClickClose
        ) {
          onClose();
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, parentRef]);
  }

  useOutsideAlerter(ref, parentRef);
  return (
    <div
      ref={ref}
      className={`${styles.mybanner} ${show && styles.myshow}`}
      style={{
        height: size.height ? size.height : 'fit-content',
        overflow: scrollInside && 'auto',
        width: size.width ? size.width : 'fit-content',
        transform: show
          ? `translate(${position.x}, ${position.y})`
          : initPosition
          ? `translate(${initPosition.x}, ${initPosition.y})` // if initial position is given
          : `translate(45%, -1200px)`, // default initial position
        ...contentStyle
      }}
      onClick={e => {
        e.stopPropagation();
        bannerOnClick();
      }}
      id={bannerId}
    >
      {children}
    </div>
  );
}
