/* eslint-disable no-unused-vars */
import React from 'react';
import AssessmentList from './AssessmentList';
import styles from '../Styles/AssessmentBody.module.css';

export default function AssessmentBody() {
  return (
    <div className={styles.ContentBody}>
      <AssessmentList />
    </div>
  );
}
