import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const getCandidateSolutionsServiceVol2 = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateSolutions, { params: data }).then(resolve).catch(reject);
  });
export const getCommentsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getComments, { params: data }).then(resolve).catch(reject);
  });
export const getCommentCountService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCommentCount, { params: data }).then(resolve).catch(reject);
  });
export const createCommentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createComment, data).then(resolve).catch(reject);
  });
export const updateCommentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateComment, data).then(resolve).catch(reject);
  });
export const deleteCommentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deleteComment, data).then(resolve).catch(reject);
  });

  export const getCandidateInfoService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCandidateInfo, { params: data }).then(resolve).catch(reject);
  });
  