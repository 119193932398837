import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBarComponent from './ProgressBarComponent';
import { useWindowSize } from '../../../utils/helpers';
import {
  fiveCandidatesExistFlagSelector,
  funnelIdSelector,
  funnelInfoSelector,
  funnelOpenSelector,
  industryAvgSelector,
  isCustomFlagSelector,
  top5AvgSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import styles from './ProgressBarRow.module.css';
import funnelIcon from './funnelIcon.svg';
import { setFunnelOpen } from '../../../redux/assessmentDetailVol2/slice';
import TextOverflow from '../../../Common/TextOverflow/TextOverflow';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import CloseButton from '../../../Common/Components/CloseButton';
import info from './info.svg';
import arrowDown from './arrowDown.svg';

const ProgressBarRow = () => {
  const dispatch = useDispatch();
  const funnelId = useSelector(funnelIdSelector);
  const funnels = useSelector(funnelInfoSelector);
  const [screenW, screenH] = useWindowSize();
  const [funnel, setFunnel] = useState();

  const indAvgRedux = useSelector(industryAvgSelector);
  const top5AvgRedux = useSelector(top5AvgSelector);

  const funnelOpen = useSelector(funnelOpenSelector);

  const [wrapperElement, setWrapperElement] = useState();
  const [progressElement, setProgressElement] = useState();
  const [nameElement, setNameElement] = useState();
  const [allowedWidth, setAllowedWidth] = useState();

  const fiveCandidatesExistFlagRedux = useSelector(fiveCandidatesExistFlagSelector);

  const MOBILE_THRESHOLD = 576;
  const [progressModalShow, setProgressModalShow] = useState(false);

  useEffect(() => {
    if (funnels && funnelId) {
      const temp = funnels.filter(f => f.funnelId === funnelId);
      setFunnel(temp[0]);
    } else if (funnels) {
      const temp = funnels.filter(f => f.funnelCategory === 20 && f.funnelName === 'Scored');
      setFunnel(temp[0]);
    }
  }, [funnels, funnelId]);

  useEffect(() => {
    if (funnels) {
      // set elements initially
      const w = document.getElementById('assessmentDetailProgressBarRow');
      setWrapperElement(w);
      const p = document.getElementById('progressInHeader');
      setProgressElement(p);
      const n = document.getElementById('funnelHeaderId');
      setNameElement(n);
    }
  }, [funnels, funnelOpen]);

  useEffect(() => {
    if (nameElement) {
      setAllowedWidth(
        wrapperElement?.getBoundingClientRect()?.width -
          nameElement?.getBoundingClientRect()?.width -
          progressElement?.getBoundingClientRect()?.width -
          100
      );
    } else {
      setAllowedWidth(
        wrapperElement?.getBoundingClientRect()?.width -
          progressElement?.getBoundingClientRect()?.width -
          100
      );
    }
  }, [nameElement, wrapperElement, progressElement, screenW]);

  useEffect(() => {
    if (screenW > 576) {
      setProgressModalShow(false);
    }
  }, [screenW]);

  const [progressModalShow2, setProgressModalShow2] = useState();
  useEffect(() => {
    if (progressModalShow) {
      setProgressModalShow2(true);
    } else {
      setTimeout(() => {
        setProgressModalShow2(false);
      }, 300);
    }
  }, [progressModalShow]);


  const isCustomFlagRedux = useSelector(isCustomFlagSelector)

  const MobilIndAvgModal = (
    <MobileModal
      id="mobile-progressBar-modal"
      outsideClickClose
      backdropStyle={{ overflowY: 'hidden' }}
      onClose={() => setProgressModalShow(false)}
      show={progressModalShow}
      contentStyle={{
        height: '167px',
        width: screenW,
        overflow: 'hidden'
      }}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.upperRow}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ProgressBarComponent
              industryAverage={indAvgRedux}
              assessmentAverage={top5AvgRedux}
              enoughScores={fiveCandidatesExistFlagRedux}
              industryAverageTooltipDirection="top"
              assessmentAverageTooltipDirection="bottom"
            />
            <img
              style={{
                width: '12px',
                height: '17px',
                marginTop: '3px',
                marginLeft: '8px'
              }}
              src={info}
              alt=""
            />
          </div>
          <CloseButton onClick={() => setProgressModalShow(false)} />
        </div>
        <div className={styles.modalBorder} />
        <div className={styles.lowerRow}>
          <div className={styles.scoreWrapper}>
            <img src={info} alt="" />
            <div style={{ marginLeft: '10px' }}>
              {fiveCandidatesExistFlagRedux && top5AvgRedux
                ? 'Average score of your top 10 candidates:'
                : 'There are not enough scores yet to calculate the average score of your candidates.'}
              &nbsp;
            </div>
            <div>{fiveCandidatesExistFlagRedux && top5AvgRedux?.toFixed(1)}</div>
          </div>
          <div className={styles.scoreWrapper}>
            <img
              style={{ width: '10px', height: '10px', marginTop: '5px' }}
              src={arrowDown}
              alt=""
            />
            <div style={{ display: 'inline', flexDirection: 'row' }}>
              <div style={{ marginLeft: '10px' }}>
                {indAvgRedux
                  ? `${isCustomFlagRedux ? "Average score of top 10 candidates who completed this assessment: " : 'Average score for each top 10 candidates of all companies using this assessment: '} ${indAvgRedux}`
                  : 'Industry Average: NaN'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MobileModal>
  );

  return (
    <>
      {screenW <= 960 && (
        <div className={styles.wrapper} id="assessmentDetailProgressBarRow">
          <div className={styles.funnelWrapper}>
            {!funnelOpen ? (
              <div id="funnelHeaderId" style={{ display: 'flex', flexDirection: 'row' }}>
                {screenW > 576 && <span className={styles.funnelName}>Hiring Funnel</span>}
                <span className={styles.funnelIcon} onClick={() => dispatch(setFunnelOpen(true))}>
                  <img alt="" src={funnelIcon} />
                </span>
              </div>
            ) : null}
            {funnel ? (
              <label
                myCategory={funnel?.funnelCategory}
                myId={`headerFunnel-${funnel?.funnelName}`}
                className={styles.text}
              >
                <TextOverflow
                  itemId="funnelNameLowRes"
                  width={allowedWidth}
                  text={`${funnel?.funnelName} (${funnel?.userCount})`}
                />
              </label>
            ) : <label className={styles.text}>&nbsp;</label>}
          </div>
          <div id="progressInHeader">
            <ProgressBarComponent
              mobileModal={screenW <= 576}
              setModalShow={setProgressModalShow}
              isBarVisible={screenW > MOBILE_THRESHOLD}
              infoPresent
              industryAverage={indAvgRedux}
              assessmentAverage={top5AvgRedux}
              enoughScores={fiveCandidatesExistFlagRedux}
              industryAverageTooltipDirection="top"
              assessmentAverageTooltipDirection="bottom"
            />
          </div>
        </div>
      )}
      {screenW <= MOBILE_THRESHOLD && progressModalShow2 && MobilIndAvgModal}
    </>
  );
};

export default ProgressBarRow;
