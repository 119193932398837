import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-dropdown';
import Switch from 'react-switch';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SalesScreen.module.css';
import CancelSubscriptionModal from '../../Modals/CancelSubscriptionModal/CancelSubscriptionModal';
import {
  AssessmentNumber,
  BillingPeriod,
  CandidateNumber,
  CandidateReport,
  CustomBundleId,
  PackageName,
  PremiumQuestion,
  Price,
  SelectedCompany,
  TemplateList,
  createdCompanyId,
  createTempAndConnectTempActionSuccessCheckRedux,
  NewOpsCandidate,
  maxNumberOfRequests
} from '../../../redux/salesScreen/selectors';
import {
  setAssessmentNumber,
  setBillingPeriod,
  setCancelBundleSuccessCheck,
  setCandidateNumber,
  setCandidateReport,
  setCreateTempAndConnectTempActionSuccessCheck,
  setInfoModal,
  setInfoText,
  setInitial,
  setMaxNumberRequests,
  setNewOpsCandidate,
  setPackageName,
  setPremiumQuestion,
  setPrice,
  setSelectedCompany
} from '../../../redux/salesScreen/slice';
import {
  createTemplateAction,
  getCompanyBundleInfoAction,
  getTemplateInfoAction,
  getTemplatesAction,
  updateCompanyAction,
  cancelCustomBundleAction,
  connectCompanyWithBundleAction
} from '../../../redux/salesScreen/actions';

const ContentBody = styled.div`
  width: 100vw;
  padding: 2rem;
  height: 100vh;
  background-color: white;
  position: relative;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type='number'] {
    -moz-appearance: textfield;
    font-family: 'Jost-400';
  }

  label {
    font-family: 'Jost-400';
  }
`;

const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
`;

const SalesScreen = () => {
  const { search } = useLocation();
  const state = new URLSearchParams(search).get('state');

  const selectedBundleId = useSelector(CustomBundleId);
  const assessmentNumber = useSelector(AssessmentNumber);
  const candidateNumber = useSelector(CandidateNumber);
  const candidateReport = useSelector(CandidateReport);
  const premiumQuestion = useSelector(PremiumQuestion);
  const billingPeriod = useSelector(BillingPeriod);
  const newOpsCandidate = useSelector(NewOpsCandidate);
  const numberOfRequests = useSelector(maxNumberOfRequests);
  const name = useSelector(PackageName);
  const price = useSelector(Price);
  const templateList = useSelector(TemplateList);
  const dispatch = useDispatch();
  const selectedCompany = useSelector(SelectedCompany);
  const [showModal, setModal] = useState(false);
  const [localTemplates, setLocalTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const newlyCreatedCompanyID = useSelector(createdCompanyId);
  const history = useHistory();

  const createTempAndConnectTempActionSuccessCheck = useSelector(
    createTempAndConnectTempActionSuccessCheckRedux
  );

  const [isEditable, setIsEdatible] = useState(false);

  async function handleSavePackage() {
    const createData = {
      period: billingPeriod === 'annual' ? 365 : 30,
      templateName: name,
      assessmentNumbers: assessmentNumber ? parseInt(assessmentNumber, 10) : 0,
      candidateNumbers: candidateNumber ? parseInt(candidateNumber, 10) : 0,
      candidateReport: candidateReport ? 30 : 0,
      premiumQuestion: premiumQuestion ? 30 : 0,
      newOpsCandidate: newOpsCandidate ? parseInt(newOpsCandidate, 10) : 0,
      candidateRequest : numberOfRequests ? parseInt(numberOfRequests, 10) : 0,
      price: parseInt(price, 10),
      insight: 0
    };
    const connectData = {
      templateId: selectedTemplate?.value,
      companyId: newlyCreatedCompanyID !== null ? newlyCreatedCompanyID : selectedCompany?.value
    };

    if (state === 'new') {
      await dispatch(setInfoText('New company successfully created and package is defined!'));
      await dispatch(connectCompanyWithBundleAction(connectData));
    } else if (state === 'update') {
      await dispatch(setInfoText(`${selectedCompany.label}'s package is successfully updated!`));
      await dispatch(connectCompanyWithBundleAction(connectData));
    } else {
      // create template
      await dispatch(setInfoText(`${name} is saved in templates`));
      await dispatch(createTemplateAction(createData));
    }
  }

  useEffect(() => {
    if (
      (state === 'new' || state === 'update') &&
      !newlyCreatedCompanyID &&
      selectedCompany.value === ''
    ) {
      history.push('/nRdDOsdIFdwEdfdsaSd/0');
    }

    // Sadece create new template'da doldurulabilir olmalı - diğerlerinde disabled
    if (!state) {
      setIsEdatible(true);
    } else {
      setIsEdatible(false);
    }

    // Redux'ı temizle - yoksa önceden kalanları basıyor
    dispatch(setBillingPeriod('monthly'));
    dispatch(setAssessmentNumber(1));
    dispatch(setCandidateNumber(1));
    dispatch(setCandidateReport(false));
    dispatch(setNewOpsCandidate(null));
    dispatch(setPremiumQuestion(false));
    dispatch(setPrice(0));
    dispatch(setPackageName(''));
    dispatch(setCreateTempAndConnectTempActionSuccessCheck(null));
    dispatch(setCancelBundleSuccessCheck(null));
    setLocalTemplates(false);
    setSelectedTemplate(null);

    // New template oluşturma aşamasındaysa istek attırma
    if ((selectedCompany || newlyCreatedCompanyID) && state) {
      const rawData = {
        CompanyId: newlyCreatedCompanyID || selectedCompany.value
      };
      // Yeni oluştuysa -> bundle'ı yok - istek attırma
      if (state !== 'new') {
        dispatch(getCompanyBundleInfoAction(rawData));
      }
      dispatch(getTemplatesAction());
    }
  }, []);

  useEffect(() => {
    // template list çekiliyor
    if (templateList) {
      setLocalTemplates(templateList.map(x => ({ label: x.name, value: x.id })));
    }
  }, [templateList]);

  useEffect(() => {
    // Firmaya tanımlı olan paketi template list içerisinde çekiyor
    if (selectedBundleId) {
      const filteredBundle = templateList?.filter(template => template.id === selectedBundleId);
      if (filteredBundle.length !== 0) {
        setSelectedTemplate({ label: filteredBundle[0].name, value: filteredBundle[0].id });
      } else {
        setSelectedTemplate(null);
      }
    }
  }, [selectedBundleId]);

  useEffect(() => {
    // Seçili template'ın bilgilerini getiriyor
    if (selectedTemplate) {
      const rawData = { TemplateId: selectedTemplate.value };
      dispatch(getTemplateInfoAction(rawData));
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (createTempAndConnectTempActionSuccessCheck) {
      dispatch(setInfoModal(true));
    } else if (createTempAndConnectTempActionSuccessCheck === false) {
      // Cleanup
      dispatch(setInfoText(''));
      alert('Error');
    }
  }, [createTempAndConnectTempActionSuccessCheck]);

  return (
    <ContentBody>
      <PageWrapper>
        <Column>
          <div className={styles.row}>
            <h1 className={styles.title}>Package Details</h1>
            {(state === 'new' || state === 'update') && (
              <Dropdown
                value={selectedTemplate}
                className={styles.dropDownClass}
                options={localTemplates && localTemplates}
                placeholder="select from template"
                onChange={val => {
                  setSelectedTemplate(val);
                }}
              />
            )}
          </div>

          <div
            className={styles.packageInfos}
            style={{
              pointerEvents: isEditable ? 'initial' : 'none',
              opacity: isEditable ? '1' : '0.6'
            }}
          >
            <div className={styles.row}>
              <label htmlFor="assessmentCount">Billing Period</label>
              <div className={styles.selectGroup}>
                <div
                  onClick={() => {
                    dispatch(setBillingPeriod('monthly'));
                  }}
                  className={`${styles.select} ${billingPeriod === 'monthly' && styles.selected}`}
                >
                  Monthly
                </div>
                <div
                  onClick={() => {
                    dispatch(setBillingPeriod('annual'));
                  }}
                  className={`${styles.select} ${billingPeriod === 'annual' && styles.selected}`}
                >
                  Annual
                </div>
              </div>
            </div>

            <div className={styles.row}>
              <label htmlFor="assessmentCount">Number of Assessments</label>
              <input
                value={assessmentNumber}
                onChange={event => {
                  dispatch(setAssessmentNumber(event.target.value));
                }}
                className={styles.assessmentInput}
                name="assessmentCount"
                type="number"
              />
            </div>

            <div className={styles.row}>
              <label htmlFor="candidateCount">Number of Candidates</label>
              <input
                onChange={event => {
                  dispatch(setCandidateNumber(event.target.value));
                }}
                value={candidateNumber}
                className={styles.assessmentInput}
                name="  "
                type="number"
              />
            </div>
            <div className={styles.row}>
              <label htmlFor="candidateCount">Candidate Report</label>
              <Switch
                checked={candidateReport}
                checkedIcon={false}
                uncheckedIcon={false}
                className={styles.assessmentInput}
                onChange={() => dispatch(setCandidateReport(!candidateReport))}
              />
            </div>
            <div className={styles.row}>
              <label htmlFor="candidateCount">Premium Questions</label>
              <Switch
                checked={premiumQuestion}
                checkedIcon={false}
                uncheckedIcon={false}
                className={styles.assessmentInput}
                onChange={() => dispatch(setPremiumQuestion(!premiumQuestion))}
              />
            </div>
            <div className={styles.row}>
              <label htmlFor="newOpsCandidate">NewOps Candidate</label>
              <input
                onChange={event => {
                  dispatch(setNewOpsCandidate(event.target.value));
                }}
                value={newOpsCandidate}
                className={styles.assessmentInput}
                name="  "
                type="number"
              />
            </div>
            <div className={styles.row}>
              <label htmlFor="requestNumber">Max Number of Requests</label>
              <input
                onChange={event => {
                  dispatch(setMaxNumberRequests(event.target.value));
                }}
                value={numberOfRequests}
                className={styles.assessmentInput}
                name="  "
                type="number"
              />
            </div>
            <div className={`${styles.row} ${styles.price}`}>
              <label htmlFor="price">Price</label>
              <input
                onChange={event => {
                  dispatch(setPrice(event.target.value));
                }}
                value={price}
                className={styles.assessmentInput}
                name="price"
                type="number"
              />
            </div>
          </div>

          <div className={`${styles.price}`}>
            <button
              onClick={() => {
                history.goBack();
              }}
              className={styles.saveButton}
              type="button"
            >
              Cancel
            </button>
            <button
              disabled={(state && !selectedTemplate) || (!state && name === '')}
              type="button"
              onClick={() => {
                handleSavePackage();
              }}
            >
              {state === 'new' ? 'Save' : state === 'update' ? 'Update' : 'Save as Template'}
            </button>
          </div>
        </Column>
        <Column>
          {state !== 'new' && state !== 'update' ? (
            <div className={styles.column}>
              <label htmlFor="packageName">Name of the package</label>
              <input
                value={name}
                onChange={event => {
                  dispatch(setPackageName(event.target.value));
                }}
                name="packageName"
                className={styles.packageName}
                type="text"
              />
            </div>
          ) : (
            state === 'update' && (
              <div className={styles.column}>
                <button
                  onClick={() => {
                    // handleCancelSubscription();
                    setModal(true);
                  }}
                  className={styles.cancelButton}
                  type="button"
                >
                  {`Cancel subscription of ${selectedCompany.label}`}
                </button>
              </div>
            )
          )}
          <CancelSubscriptionModal
            show={showModal}
            onHide={() => {
              setModal(false);
            }}
          />
        </Column>
      </PageWrapper>
    </ContentBody>
  );
};

export default SalesScreen;
