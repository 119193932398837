import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import CloseButton from '../../../Common/Components/CloseButton';
import { upgradePricesRedux, userSelector } from '../../../redux/auth/selectors';
import CustomButton from '../../../Common/Components/CustomButton';
import styles from './AllPricesModal.module.css';
import stylesP from '../../Styles/PaymentFailedModal.module.css';
import hands from './headsup.svg';
import {
  getAddOnsAction,
  getBillingInfoAction,
  getCurrentPlanAction,
  getCurrentUsageAction,
  upgradePackageAction
} from '../../../redux/plan/actions';

import { ReactComponent as DownArrow } from '../../Images/arrow-down.svg';
import Mastercard from '../../Images/mastercardIcon.svg';
import Visa from '../../Images/Visa.svg';
import Amex from '../../Images/Americanexpress.svg';
import redCircle from '../../Images/redCircle.svg';
import {
  paymentFailedErrorMessageRedux,
  billingInfoRedux,
  currentPlanRedux,
  purchaseModalPackageIdRedux
} from '../../../redux/plan/selectors';
import { setUpgradeSuccess } from '../../../redux/plan/slice';
import { useWindowSize } from '../../../utils/helpers';

export default function AllPricesModal({ show, onHide, showPaymentMethod = () => {} }) {
  const dispatch = useDispatch();
  const currentPlan = useSelector(currentPlanRedux);
  const newPackageObject = useSelector(purchaseModalPackageIdRedux);
  const upgradePrices = useSelector(upgradePricesRedux);
  const user = useSelector(userSelector);
  const [loading, setLoading] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [messageShow, setMessageShow] = useState(true);
  const [screenW, screenH] = useWindowSize();

  const handleUpgrade = async () => {
    setLoading(true);
    const data = {
      newBundleId: newPackageObject?.bundleId
    };
    const a = await dispatch(upgradePackageAction(data));
    if (a?.meta?.requestStatus === 'fulfilled') {
      setLoading(false);
      await onHide();
      await dispatch(setUpgradeSuccess(true));
      const data = {
        CompanyId: user?.companyId
      };
      dispatch(getCurrentPlanAction(data));
      dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
      dispatch(getAddOnsAction(data));
    } else {
      setLoading(false);
      setPaymentFailed(true);
    }
    setMessageShow(true);
  };

  // Payment Failed Variables:
  const billingInfo = useSelector(billingInfoRedux);
  const [association, setAssociation] = useState('');
  const error = useSelector(paymentFailedErrorMessageRedux);

  useEffect(() => {
    if (show) {
      const rawData = {
        CompanyId: user?.companyId
      };
      dispatch(getBillingInfoAction(rawData));
      setPaymentFailed(false);
    } else {
      setPaymentFailed(false);
    }
  }, [show]);

  useEffect(() => {
    if (billingInfo) {
      if (billingInfo?.cardAssociation === 'MASTER_CARD') {
        setAssociation('Mastercard');
      } else if (billingInfo?.cardAssociation === 'AMERICAN_EXPRESS') {
        setAssociation('Amex');
      } else if (billingInfo?.cardAssociation === 'VISA') {
        setAssociation('Visa');
      }
    }
  }, [billingInfo]);

  const StyledBox = styled.div`
    height: 86px;
    background: #fff;
    width: ${isMobile ? '100%' : '520px'};
    padding-top: 12px;
    padding-left: 22px;
    margin-bottom: 10px;
    filter: drop-shadow(0px 1px 24px rgba(131, 131, 131, 0.1));

    .header {
      font-family: 'Jost-400';
      font-size: 1.2rem;
      line-height: 17px;
      color: rgba(57, 57, 57, 0.49);
      text-transform: uppercase;
      margin-bottom: 14px;
    }

    .text {
      font-family: 'Jost-400';
      font-size: 1.8rem;
      line-height: 26px;
      color: rgba(57, 57, 57, 0.84);
    }
  `;
  const Box = ({ type }) => {
    return (
      <StyledBox>
        <div className="header">
          {type === 'leftover'
            ? `leftover from ${currentPlan?.name} plan `
            : type === 'addon'
            ? 'add-ons'
            : `price for ${newPackageObject?.name} plan`}
        </div>
        <div className="text">{` ${upgradePrices?.paymentCurrencyType === 10 ? '₺' : '$'} 
        ${
          type === 'leftover'
            ? upgradePrices?.leftOverPrice
            : type === 'addon'
            ? upgradePrices?.addOnPrice
            : upgradePrices?.newBundlePrice
        }
        `}</div>
      </StyledBox>
    );
  };
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={
        !paymentFailed
          ? `${styles.modalContent} ${styles['modal-content']}`
          : `${stylesP.modalContent} ${stylesP['modal-content']}`
      }
    >
      <CloseButton onClick={onHide} width={24} height={24} />
      {!paymentFailed ? (
        <>
          <span
            style={{
              marginBottom: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <img alt="" src={hands} />
          </span>
          <div className={styles.headsup}>Heads up!</div>
          <div className={styles.header}>{`${newPackageObject.name} will be only ${
            upgradePrices?.paymentCurrencyType === 10 ? '₺' : '$'
          }${
            upgradePrices?.addOnPrice + upgradePrices?.leftOverPrice + upgradePrices?.newBundlePrice
          } for you. This includes: `}</div>

          {upgradePrices?.newBundlePrice !== 0 && <Box type="newplan" />}
          {upgradePrices?.addOnPrice !== 0 && <Box type="addon" />}
          {upgradePrices?.leftOverPrice !== 0 && <Box type="leftover" />}

          <CustomButton
            style={{ width: '100%', marginBottom: '8px', marginTop: '14px' }}
            type={1}
            textField="Confirm"
            buttonOnClick={() => {
              handleUpgrade();
            }}
            loading={loading}
          />
          <CustomButton
            style={{ width: '100%' }}
            textField="Cancel"
            buttonOnClick={() => {
              onHide();
            }}
            type={2}
          />
        </>
      ) : (
        <>
          <span className={stylesP.redCircle}>
            <img src={redCircle} alt="" />
          </span>
          <div className={stylesP.title}>Payment failed!</div>
          <div className={stylesP.subtitle}>
            We couldn’t receive your payment. Please check your payment method and try again.
          </div>
          <DownArrow className={stylesP.downArrow} />
          <div className={stylesP.cardWrapper}>
            <div className={stylesP.cardContainer}>
              <span className={stylesP.cardTitle}>{`${
                billingInfo?.cardAssociation ? association : '*****'
              }****${billingInfo?.cardLastFourDigits}`}</span>
              <span
                className={stylesP.cardInfo}
              >{`Expires on ${billingInfo?.cardExpireDate}`}</span>
            </div>
            {billingInfo?.cardAssociation === 'MASTER_CARD' ? (
              <span className={stylesP.mastercard}>
                <img src={Mastercard} alt="" />
              </span>
            ) : billingInfo?.cardAssociation === 'AMERICAN_EXPRESS' ? (
              <span className={stylesP.mastercard}>
                <img src={Amex} alt="" />
              </span>
            ) : billingInfo?.cardAssociation === 'VISA' ? (
              <span className={stylesP.mastercard}>
                <img src={Visa} alt="" />
              </span>
            ) : (
              <div />
            )}
          </div>
          <div className={stylesP.errorMessage}>{messageShow ? error : ''}</div>
          <div className={stylesP.row}>
            <CustomButton
              style={{ width: screenW > 576 && '508px', marginBottom: '8px' }}
              type={1}
              textField="Change Payment Method"
              buttonOnClick={() => {
                onHide();
                showPaymentMethod('change');
                // dispatch(setPaymentMethodModalShow('change'));
              }}
            />
            <CustomButton
              style={{ width: screenW > 576 && '508px' }}
              textField="Try Again"
              buttonOnClick={async () => {
                await setMessageShow(false);
                await handleUpgrade();
              }}
              type={2}
              loading={loading}
            />
          </div>
        </>
      )}
    </Modal>
  );
}
