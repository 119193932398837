import React from 'react';
import styles from './LabeledTextInput.module.css';

const LabeledTextInput = ({
  label = '',
  placeholder = '',
  leftText = '',
  name = '',
  type = '',
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  value = null,
  mref = null,
  style = {},
  inputstyle,
  autofocus = false,
  onKeyDown = null,
  inputRef,
  ...props
}) => {
  return (
    <div className={styles.inputContainer} style={style} ref={mref}>
      <label className={value && styles.filled} htmlFor={name}>
        {label}
      </label>
      <div className={styles.inputWrapper}>
        {leftText && <text>{leftText}</text>}
        <input
          placeholder={placeholder}
          ref={inputRef}
          autoFocus={autofocus}
          onKeyDown={onKeyDown || null}
          name={name}
          id="field"
          type={type}
          autoComplete="off"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={value && styles.filledInput}
          style={inputstyle}
          {...props}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
export default LabeledTextInput;
