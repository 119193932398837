import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShortText from '../../../../Common/Components/ShortText';
import styles from './RootAssessmentCard.module.css';

import draftIcon from './Images/draft.svg';
import kebabMenuIcon from './Images/kebabMenu.svg';
import seniorityIcon from './Images/seniority.svg';
import questionCountIcon from './Images/questionCount.svg';
import durationIcon from './Images/duration.svg';
import verifiedIcon from './Images/verified.svg';
import duplicateIcon from './Images/duplicate.svg';
import deleteIcon from './Images/delete.svg';
import { userSelector } from '../../../../redux/auth/selectors';
import Tooltip from '../../../../Common/Components/Tooltip';
import { setAssessmentId, setDeletedId } from '../../../../redux/assessmentsVol2/slice';
import { useWindowSize } from '../../../../utils/helpers';

const RootAssessmentCard = ({
  rootAssessmentDetails,
  setSelectedRootAssessmentDetails,
  setSelectedRootAssessmentQuestionList,
  setDetailsModalState,
  isCustom,
  setDeleteModalState,
  setDuplicateModalState,
  wrapperStyle = {},
  cardId,
  cardRef,
  scrollPos,   // Scroll position update for custom tab
}) => {
  const dispatch = useDispatch();
  const [kebabState, setKebabState] = useState(false);
  const [kebabPosition, setKebabPosition] = useState({ top: 0, left: 0 });
  const [staticLabelList, setStaticLabelList] = useState([]);
  const user = useSelector(userSelector);

  const [kebabStateBackgroundStyle, setKebabStateBackgroundStyle] = useState();

  useEffect(() => {
    if (rootAssessmentDetails?.skills) {
      setStaticLabelList(rootAssessmentDetails.skills);
    }
  }, [rootAssessmentDetails]);

  useEffect(() => {
    document.addEventListener('click', e => {
      if (!document.getElementById(`${cardId}-kebabDropdown`)?.contains(e.target)) {
        setKebabState(false);
      }
    });

    return () => {
      document.removeEventListener('click', e => {
        if (!document.getElementById(`${cardId}-kebabDropdown`)?.contains(e.target)) {
          setKebabState(false);
        }
      });
    };
  }, []);

  const [width, height] = useWindowSize();

  useEffect(() => {
    if (kebabState) {
      setKebabStateBackgroundStyle({ opacity: '0.5', cursor: 'default' });
      const x = document.getElementById(`${cardId}-kebabIcon`)?.getBoundingClientRect().left;
      const y = document.getElementById(`${cardId}-kebabIcon`)?.getBoundingClientRect().top;

      setKebabPosition({ top: y, left: x });
    } else {
      setKebabStateBackgroundStyle();
    }
  }, [kebabState, width, height, scrollPos]);

  return (
    <div
      id={cardId}
      ref={cardRef}
      className={styles.MainWrapper}
      style={{ ...wrapperStyle, ...kebabStateBackgroundStyle, opacity: '1' }}
      onClick={() => {
        if (!kebabState) {
          setSelectedRootAssessmentDetails(rootAssessmentDetails);
          setSelectedRootAssessmentQuestionList(rootAssessmentDetails);
          setDetailsModalState(true);
        }
      }}
    >
      <div className={styles.Title} style={{ ...kebabStateBackgroundStyle }}>
        <ShortText
          name={rootAssessmentDetails?.rootAssessmentName}
          staticLimit={isCustom ? (rootAssessmentDetails?.objStatusId !== 1 ? 30 : 34) : 38}
        />
        {rootAssessmentDetails?.objStatusId !== 1 ? (
          <img style={{ marginLeft: 'auto' }} src={draftIcon} alt="draft" />
        ) : null}
        {isCustom ? (
          <img
            className={styles.kebabMenu}
            style={{
              cursor: 'pointer',
              marginLeft: rootAssessmentDetails?.objStatusId !== 1 ? '4px' : 'auto'
            }}
            onClick={e => {
              e.stopPropagation();
              setKebabState(prev => !prev);
            }}
            src={kebabMenuIcon}
            alt="kebabMenu"
            id={`${cardId}-kebabIcon`}
          />
        ) : null}
      </div>
      <div className={styles.SkillsRow} style={{ ...kebabStateBackgroundStyle }}>
        {staticLabelList?.slice(0, 2).map(e => (
          <div className={styles.SkillBox}>
            <ShortText
              name={e}
              staticLimit={
                staticLabelList.length === 1 ? 24 : staticLabelList.length === 2 ? 18 : 10
              }
            />
          </div>
        ))}
        {staticLabelList && staticLabelList.length === 3 ? (
          staticLabelList?.slice(2, 3).map(e => (
            <div className={styles.SkillBox}>
              <ShortText name={e} staticLimit={10} />
            </div>
          ))
        ) : staticLabelList && staticLabelList.length > 3 ? (
          <div className={styles.SkillBox}>{`${staticLabelList.length - 2}+`}</div>
        ) : null}
      </div>
      <div className={styles.DetailsRow} style={{ ...kebabStateBackgroundStyle }}>
        {!isCustom ? (
          <>
            <div className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}>
              <img className={styles.icons} src={seniorityIcon} alt="seniority" />
              {rootAssessmentDetails?.seniorityLevel === 10
                ? 'Junior'
                : rootAssessmentDetails?.seniorityLevel === 20
                ? 'Mid-Senior'
                : rootAssessmentDetails?.seniorityLevel === 30
                ? 'Senior'
                : 'Junior'}
            </div>
            <div className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}>
              <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
              {rootAssessmentDetails?.questionList?.length} questions
            </div>
            <div className={`${styles.DetailBox} ${styles.JustifyCenter}`}>
              <img className={styles.icons} src={durationIcon} alt="duration" />
              {rootAssessmentDetails?.rootAssessmentTotalTime} min
            </div>
          </>
        ) : (
          <>
            <div
              className={`${styles.DetailBox} ${styles.RightBorder}`}
              style={{ paddingLeft: '22px' }}
            >
              <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
              {rootAssessmentDetails?.questionList?.length} questions
            </div>
            <div className={`${styles.DetailBox}`} style={{ paddingLeft: '22px' }}>
              <img className={styles.icons} src={durationIcon} alt="duration" />
              {rootAssessmentDetails?.rootAssessmentTotalTime} minutes
            </div>
          </>
        )}
      </div>
      <div className={styles.PreparedByWrapper} style={{ ...kebabStateBackgroundStyle }}>
        <div className={styles.PreparedByHeader}>
          <div className={styles.PreparedByText}>Prepared by</div>
          <div className={styles.PreparedByLine} />
        </div>
        <div className={styles.PreparedByContent}>
          <div className={styles.PreparedByContentHeader}>
            {!isCustom ? (
              <>
                Coensio
                <img style={{ marginLeft: '8px' }} src={verifiedIcon} alt="verified" />
              </>
            ) : (
              <>
                {/* Draft statüsü ve eski assessmentlar için davranış */}
                {!rootAssessmentDetails?.creator
                  ? rootAssessmentDetails?.objStatusId === 1
                    ? 'Hiring Team'
                    : 'Save and use!'
                  : `${rootAssessmentDetails?.creator?.name} ${rootAssessmentDetails?.creator?.surname}`}
                {rootAssessmentDetails?.creator ||
                (!rootAssessmentDetails?.creator && rootAssessmentDetails?.objStatusId === 1) ? (
                  <img style={{ marginLeft: '8px' }} src={verifiedIcon} alt="verified" />
                ) : null}
              </>
            )}
          </div>
          <div className={styles.PreparedByContentSubtext}>
            {!isCustom ? (
              <>Prepared by one of the experts in this field.</>
            ) : (
              <>
                {/* Draft statüsü ve eski assessmentlar için davranış */}
                {!rootAssessmentDetails?.creator
                  ? rootAssessmentDetails?.objStatusId === 1
                    ? `Recruiter at ${user?.companyName}`
                    : `It's in the draft status.`
                  : `${rootAssessmentDetails?.creator?.userType} at ${user?.companyName}`}
              </>
            )}
          </div>
        </div>
      </div>

      {isCustom && kebabState ? (
        <div
          id={`${cardId}-kebabDropdown`}
          className={styles.kebabMenuDropdown}
          style={{ top: kebabPosition.top + 32, left: kebabPosition.left - 122 + 23 }}
        >
          <div
            className={styles.kebabMenuElement}
            style={{ color: '#7458D3' }}
            onClick={() => {
              dispatch(setAssessmentId(rootAssessmentDetails?.rootAssessmentId));
              setDuplicateModalState(true);
              setKebabState(false);
            }}
          >
            <img src={duplicateIcon} alt="duplicate" style={{ marginRight: '10px' }} />
            Duplicate
          </div>
          <div style={{ height: '0px', borderBottom: '1px solid rgba(57, 57, 57, 0.1)' }} />
          <div
            className={`${styles.kebabMenuElement} ${
              rootAssessmentDetails?.hasCompanyAssessment && styles.disabledMenuElement
            }`}
            style={{ color: '#DE4747' }}
            onClick={() => {
              if (!rootAssessmentDetails?.hasCompanyAssessment) {
                dispatch(setDeletedId(rootAssessmentDetails?.rootAssessmentId));
                setDeleteModalState(true);
                setKebabState(false);
              }
            }}
          >
            {rootAssessmentDetails?.hasCompanyAssessment ? (
              <Tooltip
                position="top"
                text="This assessment is actively used, therefore it cannot be deleted from the library."
              >
                <>
                  <img src={deleteIcon} alt="delete" style={{ marginRight: '10px' }} />
                  Delete
                </>
              </Tooltip>
            ) : (
              <>
                <img src={deleteIcon} alt="delete" style={{ marginRight: '10px' }} />
                Delete
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RootAssessmentCard;
