import React from 'react';
import Modal from '../../CustomModal/Modal';

export default function MobileModal({
  // scrollInside CANNOT be used without a maxHeight prop
  // Layer 1: backdrop, style is mostly only for background color
  // Layer 2: wrapper, style is for position alignment mosty. Give margin styling to this layer
  // Layer 3: content, style is for the box area of the modal (border, padding etc. should be given here)

  children,
  show,
  onClose,
  id,
  maxHeight,
  outsideClickClose,
  backdropStyle = {},
  contentStyle = {},
  bodyStyle = {},
  outsideTouchClose,
  // scrollOnTouch
}) {
  return (
    <Modal
      startFromBottom
      modalId={id}
      show={show}
      onClose={onClose}
      size={{ width: '100vw', height: 'fit-content' }}
      maxHeight={maxHeight}
      contentStyle={{ borderRadius: '10px 10px 0px 0px', ...contentStyle }}
      outsideClickClose={outsideClickClose}
      scrollInside
      backdropStyle={backdropStyle}
      bodyStyle={bodyStyle}
      outsideTouchClose={outsideTouchClose}
      // scrollOnTouch={scrollOnTouch}
    >
      {children}
    </Modal>
  );
}
