import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Checkbox from '../../../Common/Components/Checkbox';
import CloseButton from '../../../Common/Components/CloseButton';
import CustomButton from '../../../Common/Components/CustomButton';
import SearchBar from '../../../Common/Components/SearchBar';
import {
  addSkillBasedCodingQuestionAction,
  getCodingLanguagesAction,
  addQuestionBasedQuestionAction
} from '../../../redux/assessmentsVol2/actions';
import {
  addSkillBasedErrorRedux,
  codingLanguageListSelector,
  questionBeingAdded,
  showPopupRedux
} from '../../../redux/assessmentsVol2/selectors';
import styles from '../Styles/AddCodingModal.module.css';
import { setAddSkillBasedError, setShow } from '../../../redux/assessmentsVol2/slice';
import RadioButton from '../../../Common/Components/RadioButton';
import LanguageDropdown from '../../../Common/Components/LanguageDropdown';

import mixpanel from '../../../utils/mixpanel';
import { userSelector } from '../../../redux/auth/selectors';

export default function AddCodingModal({ show, onHide, view }) {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  const languageList = useSelector(codingLanguageListSelector);
  const toBeAddedQ = useSelector(questionBeingAdded);
  const showPopup = useSelector(showPopupRedux);
  const [searchVal, setSearchVal] = useState('');

  // Selected radiobutton
  const [difficulty, setDifficulty] = useState('Medium');
  const [language, setLanguage] = useState();

  // languageList istediğimiz formatta değil, dolayısıyla onu istediğimiz formata
  // sokup list'e eşitliyoruz
  const [list, setList] = useState();
  const [selectedLanguageList, setSelectedLanguageList] = useState([]);

  // Diğer kategorileri disable edebilmek için
  const [selectedCategory, setSelectedCategory] = useState('');
  const [minheight, setMinheight] = useState();

  useEffect(() => {
    if (list) {
      const h = document?.getElementById('mymodal')?.getBoundingClientRect()?.height;
      setMinheight(h);
    }
  }, [list]);

  // Mixpanel variable
  const user = useSelector(userSelector);

  useEffect(async () => {
    setDifficulty('Medium');
    setSearchVal('');
    setSelectedLanguageList([]);
    setSelectedCategory('');
    if (show) {
      setLoading(true);
      if (!toBeAddedQ?.category) {
        /* Mixpanel pageview */
        mixpanel.track('Skill Based Popup - Pageview', {
          'User Id': user.userId,
          'Company Name': user.companyName,
          'Name Surname': user.userNameSurname,
          'Root Assessment Id': id,
          'Root Assessment Name': localStorage.getItem(
            'Mixpanel_AssessmentCustomizationRootAssessmentName'
          ),
          'Question Type': 'Coding'
        });
        /* ----------------- */
      }
      await dispatch(getCodingLanguagesAction());
    }
  }, [show]);

  useEffect(() => {
    if (languageList) {
      const temp = [];
      languageList.forEach(e => {
        if (!temp.includes(e.languageType)) {
          temp.push(e.languageType);
        }
      });
      temp.sort();
      temp.reverse();
      const tempDict = [];
      temp.forEach(cat => {
        const innerTemp = [];
        languageList.forEach(element => {
          if (element.languageType === cat) {
            innerTemp.push(element.language);
          }
        });
        tempDict.push({ category: cat, lang: innerTemp });
      });
      if (toBeAddedQ && toBeAddedQ.codingLanguages && toBeAddedQ.codingLanguages[0] !== 'SQL') {
        tempDict.shift();
      }
      setList(tempDict);
      setLoading(false);
    }
  }, [languageList]);

  const checkBoxOnClick = (lang, category) => {
    let temp = [...selectedLanguageList];
    if (selectedLanguageList.includes(lang)) {
      temp = temp.filter(e => e !== lang);
      if (temp.length === 0) {
        setSelectedCategory('');
      }
      setSelectedLanguageList(temp);
    } else {
      temp.push(lang);
      setSelectedCategory(category);
      setSelectedLanguageList(temp);
    }
  };

  const addQuestion = async () => {
    setLoading(true);
    const rawData = {
      rootAssessmentId: id,
      languageList: selectedLanguageList
    };
    let a = null;
    if (toBeAddedQ?.category) {
      const temp = {
        ...rawData,
        category: toBeAddedQ?.category,
        questionId: toBeAddedQ?.questionId
      };
      a = await dispatch(addQuestionBasedQuestionAction(temp));
      if (!showPopup.appear) {
        dispatch(setShow({ type: 1, appear: true }));
      }
    } else {
      /* Mixpanel Confirm Click */
      mixpanel.track('Skill Based Popup - Confirm Button Click', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Root Assessment Id': id,
        'Root Assessment Name': localStorage.getItem(
          'Mixpanel_AssessmentCustomizationRootAssessmentName'
        ),
        'Question Type': 'Coding',
        'Category Labels': 'General Coding',
        Difficulty: difficulty,
        'Question Language': language.title,
        'Coding Language List': selectedLanguageList
      });
      /* ----------------- */
      const skillData = {
        rootAssessmentId: id,
        languageList: selectedLanguageList,
        difficulty,
        questionLanguage: language.title === 'EN' ? 'EN' : 'TR'
      };
      a = await dispatch(addSkillBasedCodingQuestionAction(skillData));
    }
    if (a.meta.requestStatus === 'fulfilled') {
      setLoading(false);
      onHide();
    }
  };

  const addSkillBasedErrorState = useSelector(addSkillBasedErrorRedux);

  useEffect(() => {
    if (addSkillBasedErrorState === false && show) {
      dispatch(setAddSkillBasedError(null));
    }
  }, [addSkillBasedErrorState]);

  // dispatch(addQuestionBasedQuestionAction(data));

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']} ${'mymodal'}`}
    >
      <div id="mymodal" style={{ minHeight: minheight }}>
        <div
          className={styles.Header}
          style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
        >
          Add Coding Question
          <CloseButton onClick={onHide} />
        </div>
        <div
          className={styles.Description}
          style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
        >
          Select the language(s) that candidates will be
          <span style={{ fontFamily: 'Jost-400' }}> allowed to use in this coding question.</span>
        </div>
        <SearchBar
          isExtended={1}
          size="large"
          color="gray"
          inputFieldPlaceholder="Search"
          inputFieldValue={searchVal}
          inputFieldOnChange={e => setSearchVal(e.target.value)}
          style={{
            opacity: loading ? '0.4' : '1',
            pointerEvents: loading ? 'none' : 'initial'
          }}
        />
        {!toBeAddedQ?.type && (
          <div className={styles.difficultyWrapper}>
            <RadioButton
              containerStyle={{ display: 'flex', flexDirection: 'row' }}
              data={['Easy', 'Medium', 'Hard']}
              selectedOption={difficulty}
              setSelectedOption={setDifficulty}
            />
            <div style={{ marginRight: 0, marginLeft: 'auto' }}>
              <LanguageDropdown
                visibleItemNo={2}
                list={[
                  { id: '1', title: 'EN', key: '1' },
                  { id: '2', title: 'TR', key: '2' }
                ]}
                defaultSelectedId="1"
                setSelectedOption={setLanguage}
              />
            </div>
          </div>
        )}
        <div
          className={styles.BodyWrapper}
          style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
        >
          <div className={styles.ListWrapper}>
            {JSON.stringify(list)?.toLocaleLowerCase()?.includes(searchVal?.toLocaleLowerCase()) ? (
              list?.map(
                (e, i) =>
                  e.lang.toString().toLowerCase().includes(searchVal.toLocaleLowerCase()) && (
                    <div
                      className={`${styles.LangAndCategoryWrapper}`}
                      style={{
                        display:
                          toBeAddedQ?.type === 'Coding' && e.category === 'Query Languages'
                            ? // toBeAddedQ?.type === 'Coding' && e.category === 'Queryable Language'
                              'none'
                            : 'initial'
                      }}
                    >
                      <div className={styles.Category}>{e.category.toUpperCase()}</div>
                      <div
                        className={`${styles.LanguagesWrapper}  ${
                          selectedCategory !== '' &&
                          selectedCategory !== e.category &&
                          styles.Disabled
                        }`}
                      >
                        {e.lang.map(
                          lang =>
                            lang.toLowerCase().includes(searchVal.toLocaleLowerCase()) && (
                              <div className={styles.Language}>
                                <div style={{ marginRight: '17px' }}>
                                  <Checkbox
                                    myChecked={
                                      selectedLanguageList && selectedLanguageList.includes(lang)
                                    }
                                    myOnClick={() => checkBoxOnClick(lang, e.category)}
                                  />
                                </div>
                                <span style={{ marginBottom: '0.3rem' }}>{lang}</span>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  )
              )
            ) : (
              <div className={styles.notAvailable}>No such question is available.</div>
            )}
          </div>

          <div
            className={`${styles.InformationWrapper} ${
              !selectedLanguageList || (selectedLanguageList.length === 0 && styles.Hidden)
            }`}
          >
            <div className={styles.InformationText}>
              Language(s) that candidates could be use to solve:
            </div>
            <div className={styles.InformationBox}>
              {selectedLanguageList && selectedLanguageList.join(', ')}
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.ButtonWrapper}
        style={{ opacity: loading ? '0.4' : '1', pointerEvents: loading ? 'none' : 'initial' }}
      >
        <CustomButton
          type={2}
          buttonOnClick={onHide}
          textField="Cancel"
          size="small"
          style={{ marginRight: '8px' }}
        />
        <CustomButton
          type={1}
          buttonOnClick={() => {
            addQuestion();
          }}
          textField="Confirm"
          size="small"
          isDisabled={selectedLanguageList.length === 0}
        />
      </div>
    </Modal>
  );
}
