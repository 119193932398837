import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  contactSalesService,
  createCardService,
  getAddOnsService,
  getAllPlansService,
  getBillingInfoService,
  getCountriesService,
  getCurrentPlanService,
  getCurrentUsageService,
  getMyCardsService,
  getPackageDetailService,
  createPackageOrderService,
  comparePlansAndFeaturesService,
  editBillingInformationService,
  unsubscribePlanService,
  unsubscribeAddOnService,
  downgradePackageService,
  getProfileService,
  updateMyProfileService,
  questionAvailabilityControlService,
  premiumQAvailableInAssessmentControlService,
  signupRequestService,
  upgradePackageService,
  abortCancelInProgressService,
  purchaseAddOnService,
  getInvoiceListService,
  unlockCandidateReportService,
  downloadInvoiceService,
  getOnboardingStepsService,
  isFirstAssessmentCheckService
} from './service';

export const getCurrentPlanAction = createAsyncThunk('GetCurrentPlan', async (data, thunkAPI) => {
  try {
    const response = await getCurrentPlanService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllPlansAction = createAsyncThunk('GetAllPlans', async (data, thunkAPI) => {
  try {
    const response = await getAllPlansService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCurrentUsageAction = createAsyncThunk('GetCurrentUsage', async (data, thunkAPI) => {
  try {
    const response = await getCurrentUsageService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAddOnsAction = createAsyncThunk('GetAddOns', async (data, thunkAPI) => {
  try {
    const response = await getAddOnsService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getPackageDetailAction = createAsyncThunk(
  'GetPackageDetail',
  async (data, thunkAPI) => {
    try {
      const response = await getPackageDetailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBillingInfoAction = createAsyncThunk('GetBillingInfo', async (data, thunkAPI) => {
  try {
    const response = await getBillingInfoService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const createCardAction = createAsyncThunk('createCard', async (data, thunkAPI) => {
  try {
    const response = await createCardService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const getMyCardsAction = createAsyncThunk('getCards', async (data, thunkAPI) => {
  try {
    const response = await getMyCardsService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getCountriesAction = createAsyncThunk('GetCountries', async (data, thunkAPI) => {
  try {
    const response = await getCountriesService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const contactSalesAction = createAsyncThunk('ContactSales', async (data, thunkAPI) => {
  try {
    const response = await contactSalesService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createPackageOrderAction = createAsyncThunk(
  'CreatePackageOrder',
  async (data, thunkAPI) => {
    try {
      const response = await createPackageOrderService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const comparePlansAndFeaturesAction = createAsyncThunk(
  'comparePlansAndFeatures',
  async (data, thunkAPI) => {
    try {
      const response = await comparePlansAndFeaturesService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editBillingInfoAction = createAsyncThunk(
  'editBillingInfoAction',
  async (data, thunkAPI) => {
    try {
      const response = await editBillingInformationService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unsubscribeAddOnAction = createAsyncThunk(
  'unsubscribeAddOn',
  async (data, thunkAPI) => {
    try {
      const response = await unsubscribeAddOnService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const unsubscribePlanAction = createAsyncThunk('unsubscribePlan', async (data, thunkAPI) => {
  try {
    const response = await unsubscribePlanService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const downgradePackageAction = createAsyncThunk(
  'downgradePackageAction',
  async (data, thunkAPI) => {
    try {
      const response = await downgradePackageService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const upgradePackageAction = createAsyncThunk(
  'upgradePackageAction',
  async (data, thunkAPI) => {
    try {
      const response = await upgradePackageService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getProfileAction = createAsyncThunk('getProfile', async (data, thunkAPI) => {
  try {
    const response = await getProfileService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateMyProfileAction = createAsyncThunk('updateMyProfile', async (data, thunkAPI) => {
  try {
    const response = await updateMyProfileService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const questionAvailabilityControlAction = createAsyncThunk(
  'questionAvailabilityControl',
  async (data, thunkAPI) => {
    try {
      const response = await questionAvailabilityControlService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const premiumQAvailableInAssessmentControlAction = createAsyncThunk(
  'premiumQAvailableInAssessmentControl',
  async (data, thunkAPI) => {
    try {
      const response = await premiumQAvailableInAssessmentControlService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signupRequestAction = createAsyncThunk('signupRequest', async (data, thunkAPI) => {
  try {
    const response = await signupRequestService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const abortCancelInProgressAction = createAsyncThunk(
  'abortCancelInProgress',
  async (data, thunkAPI) => {
    try {
      const response = await abortCancelInProgressService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const purchaseAddOnAction = createAsyncThunk('purchaseAddOn', async (data, thunkAPI) => {
  try {
    const response = await purchaseAddOnService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const isAuthorizedForCandidateReportAction = createAsyncThunk(
  'isAuthorizedForCandidateReport',
  async (data, thunkAPI) => {
    try {
      const response = await purchaseAddOnService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unlockCandidateReportAction = createAsyncThunk(
  'unlockCandidateReport',
  async (data, thunkAPI) => {
    try {
      const response = await unlockCandidateReportService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getInvoiceListAction = createAsyncThunk('getInvoiceList', async (data, thunkAPI) => {
  try {
    const response = await getInvoiceListService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const downloadInvoiceAction = createAsyncThunk('downloadInvoice', async (data, thunkAPI) => {
  try {
    const response = await downloadInvoiceService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

// Onboarding

export const getOnboardingStepsAction = createAsyncThunk(
  'getOnboardingSteps',
  async (data, thunkAPI) => {
    try {
      const response = await getOnboardingStepsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const isFirstAssessmentCheckAction = createAsyncThunk(
  'isFirstAssessmentCheck',
  async (data, thunkAPI) => {
    try {
      const response = await isFirstAssessmentCheckService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
