import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './DomainSignupComponent.module.css';
import { ReactComponent as CoensioLogo } from '../../../images/Coensio_newLogo.svg';
import { signUpStep1ErrorMessageRedux } from '../../../redux/signup/selectors';
import GoBackButton from '../../../Common/Components/GoBackButton';

const DomainSignupComponent = ({ title, invitation = false, companyNameFromParent = null }) => {
  const errorMessage = useSelector(signUpStep1ErrorMessageRedux);
  const [companyName, setCompanyName] = useState(companyNameFromParent);
  const history = useHistory();

  useEffect(() => {
    if (!companyNameFromParent && errorMessage) {
      setCompanyName(errorMessage?.split('. ')[1]);
    }
  }, [errorMessage]);

  // const Back = () => {
  //   return (
  //     <div className={styles.BackWrapper}>
  //       <Link to={route.login} className={styles.Back}>
  //         <BackIcon className={styles.Arrow} />
  //         <div className={styles.BackText}>Back to Coensio</div>
  //       </Link>
  //     </div>
  //   );
  // };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.Card}>
          <div>
            <CoensioLogo className={styles.Logo} />
          </div>

          <div className={styles.CardTextBox}>
            <div className={styles.backWrapper}>
              <GoBackButton
                style={{ marginLeft: '-10px' }}
                onClick={() => {
                  history.push(`/login`);
                }}
              />
              <div className={!invitation ? styles.CardTitle : styles.CardInvitationTitle}>
                {title}
              </div>
            </div>

            {!invitation ? (
              <div className={styles.CardText}>
                We informed the admin of
                <div style={{ fontFamily: 'Jost-500' }}>&nbsp;{companyName}.&nbsp;</div>Please ask
                for an invitation to join the team.
              </div>
            ) : (
              <div className={styles.CardText}>
                <div style={{ fontFamily: 'Jost-500' }}>{companyName}</div>&nbsp;has sent you an
                invitation mail. Please Check your mail box.
              </div>
            )}

            {/* <Back /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainSignupComponent;
