import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styles from '../Styles/ProgressBarWrapper.module.css';

function AnimatedText({ score, ...props }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
  });

  return (
    <animated.text className={styles.percantage} style={props.style}>
      {useSpring({
        transition: '2s',
        stroke: active ? score : 0
      }).stroke.interpolate(x => Math.round(x))}
    </animated.text>
  );
}
export default AnimatedText;
