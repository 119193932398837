import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../Common/Components/CustomButton';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import TextInput from '../../../Common/Components/TextInput';
import {
  getAssessmentInfoAction,
  updateAssessmentInfoAction
} from '../../../redux/assessmentDetail/actions';
import {
  setSettingsBannerMessage,
  setSettingsError,
  setSettingsSuccess
} from '../../../redux/assessmentDetail/slice';
import { setCompanyAssessmentName } from '../../../redux/assessmentDetailVol2/slice';
import { useWindowSize } from '../../../utils/helpers';
import styles from './AssessmentSettingsModal.module.css';
import CloseButton from '../../../Common/Components/CloseButton';

export default function EditAssessmentNameModal({ show, onHide, name, id, status, mobile }) {
  const dispatch = useDispatch();
  const [position, setPosition] = useState(name);
  const [windowW, windowH] = useWindowSize();

  async function handleChangeName() {
    const data = {
      companyAssessmentId: id,
      companyAssessmentName: position,
      assessmentStatus: status
    };
    const a = await dispatch(updateAssessmentInfoAction(data));
    const resp = await dispatch(getAssessmentInfoAction({ CompanyAssessmentId: id }));

    if (a?.payload?.status === 200 && resp?.meta?.requestStatus === 'fulfilled') {
      await dispatch(setSettingsBannerMessage('Changes saved successfully'));
      await dispatch(setSettingsSuccess(true));
      dispatch(setCompanyAssessmentName(resp?.payload?.data?.name));
    } else {
      await dispatch(setSettingsBannerMessage(a?.payload?.message || resp?.payload?.message));
      await dispatch(setSettingsError(true));
    }
    await onHide();
  }

  useEffect(() => {
    if (show) {
      setPosition(name);
    }
    return () => {
      setPosition(name);
    };
  }, [show]);

  const WebModal = (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        onHide();
      }}
      contentClassName={`${styles.nameContent}`}
      dialogClassName={styles.dialogCustomModal}
    >
      <div className={styles.nameHeader}>Edit Position Name</div>
      <TextInput
        placeholder="Position Name"
        onChange={event => {
          setPosition(event.target.value);
        }}
        value={position}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginRight: 0,
          marginLeft: 'auto',
          marginTop: 24
        }}
      >
        <CustomButton
          size="small"
          type={2}
          textField="Cancel"
          style={{ marginRight: '12px' }}
          buttonOnClick={() => onHide()}
        />
        <CustomButton
          isDisabled={!position}
          size="small"
          type={1}
          textField="Save"
          buttonOnClick={() => handleChangeName()}
        />
      </div>
    </Modal>
  );

  const Mobile = (
    <MobileModal
      outsideClickClose
      id="edit-assm-name-mobile"
      show={show}
      onClose={onHide}
      contentStyle={{ padding: '20px', minWidth: '375px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '24px'
        }}
      >
        <div className={styles.nameHeaderMobile}>Edit Position Name</div>
        <CloseButton size="small" onClick={() => onHide()} />
      </div>
      <TextInput
        placeholder="Position Name"
        onChange={event => {
          setPosition(event.target.value);
        }}
        value={position}
        inputstyle={{ height: '50px' }}
      />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: '10px',
          marginTop: '24px'
        }}
      >
        <CustomButton
          size="mobile"
          type={8}
          textField="Cancel"
          buttonOnClick={() => onHide()}
        />
        <CustomButton
          isDisabled={!position}
          size="mobile"
          type={1}
          textField="Save"
          buttonOnClick={() => handleChangeName()}
        />
      </div>
    </MobileModal>
  );

  return <>{mobile ? <>{Mobile}</> : <>{WebModal}</>}</>;
}
