import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopover from '../../../Common/Components/CustomPopover';
import styles from '../Styles/QuestionBasedMiddleColumn.module.css';
import SearchBar from '../../../Common/Components/SearchBar';
import FilteredQuestion from './FilteredQuestion';
import comingSoon from '../Images/coming-soon.svg';
import {
  candidateQuestionList,
  customAssessmentId,
  filterState,
  loading,
  maxPageCount,
  showPopupRedux,
  pageIndex
} from '../../../redux/assessmentsVol2/selectors';
import AssessmentAddedPopup from './AssessmentAddedPopup';
import { getQuestionByFilterAction } from '../../../redux/assessmentsVol2/actions';
import { setFilterState, setPageIndex } from '../../../redux/assessmentsVol2/slice';
import TabSwitch from '../../../Common/Components/TabSwitch';
import Tooltip from '../../../Common/Components/Tooltip';
import plus from '../../../images/plusIcon2.svg';
import Loading from '../../../Common/Components/Loading';

export default function QuestionBasedMiddleColumn({
  resetCurrentListFlag,
  setResetCurrentFlagList,
  resetScrollPositionFlag,
  setResetScrollPositionFlag,
  resetFilterFlag
}) {
  const newcomingQList = useSelector(candidateQuestionList);
  const [qList, setQList] = useState(newcomingQList);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const filter = useSelector(filterState);
  const id = useSelector(customAssessmentId);
  const maxPageNo = useSelector(maxPageCount);
  const isLoading = useSelector(loading);
  const showPopup = useSelector(showPopupRedux);
  const filteredQListRef = useRef();
  const qPerPAge = 50;
  const url = window.location.href;
  const lastSegment = url.split('/').pop();
  const myPageIndex = useSelector(pageIndex);
  const [change, setChange] = useState(false);

  const [scrollableContent, setScrollableContent] = useState(false);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [isScrollAllowed, setIsScrollAllowed] = useState(true);
  const [leftMargin, setLeftMargin] = useState(0);

  useEffect(() => {
    const { left } = document.getElementById('createQButton').getBoundingClientRect();
    setLeftMargin(left - 180);
  });

  useEffect(() => {
    if (resetFilterFlag) {
      setSearch('');
    }
  }, [resetFilterFlag]);

  const handleScroll = async () => {
    if (resetScrollPositionFlag) {
      setLastScrollPosition(false);
      setResetScrollPositionFlag(false);
    } else if (!isScrollAllowed) {
      filteredQListRef?.current.scrollTo({ top: lastScrollPosition });
    } else {
      const { scrollTop, scrollHeight, clientHeight } = filteredQListRef?.current;
      if (filteredQListRef?.current) {
        if (
          scrollTop + clientHeight < scrollHeight + 5 &&
          scrollTop + clientHeight > scrollHeight - 5 &&
          qList.length / qPerPAge === myPageIndex &&
          qList.length >= 50 &&
          maxPageNo > qList.length % 50
        ) {
          const myIndex = myPageIndex + 1;
          dispatch(setPageIndex(myIndex));
          setLastScrollPosition(scrollTop);
          await dispatch(
            getQuestionByFilterAction({
              ...filter,
              pageSize: qPerPAge,
              currentPage: myIndex,
              rootAssessmentId: lastSegment
            })
          );
        }
      }
    }
  };

  const saveLastScrollPos = () => {
    const { scrollTop, scrollHeight, clientHeight } = filteredQListRef?.current;
    setLastScrollPosition(scrollTop);
  };

  const [createQClicked, setCreateQClicked] = useState(false);
  const delay = 4000;

  function closePopover() {
    setCreateQClicked(false);
  }

  useEffect(() => {
    // if (newcomingQList) {
    if (resetCurrentListFlag) {
      setQList(newcomingQList);
      // setIsScrollAllowed(true)
    } else {
      if (newcomingQList && myPageIndex === 1) {
        setQList(newcomingQList);
        filteredQListRef?.current.scrollTo({ top: 0 });
      }
      if (qList && myPageIndex !== 1) {
        newcomingQList?.forEach(item => {
          setQList(current => [...current, item]);
        });
      }
    }

    filteredQListRef?.current.scrollTo({ top: lastScrollPosition });
    setIsScrollAllowed(true);
    setTimeout(() => {
      const { scrollHeight, clientHeight } = filteredQListRef?.current;
      if (scrollHeight > clientHeight) {
        setScrollableContent(true);
        setChange(!change);
      } else {
        setScrollableContent(false);
        setChange(!change);
      }
      setResetCurrentFlagList(false);
    }, 50);
  }, [JSON.stringify(newcomingQList)]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.firstRow}>
        <TabSwitch tabNameList={['Coensio Library']} height={32} myStyle={{ fontSize: '14px' }} />
        <Tooltip text="Stay tuned!">
          <div className={styles.companyLib}>
            <span className={styles.mycompanyLib}>My Company Library</span>
            <span className={styles.icon}>
              <img alt="" src={comingSoon} />
            </span>
          </div>
        </Tooltip>
        <div className={styles.createQWrapper}>
          <span>
            <img alt="" src={plus} />
          </span>
          <CustomPopover
            style={{ marginLeft: leftMargin, marginTop: '100px' }}
            show={createQClicked}
          >
            <button
              id="createQButton"
              className={styles.createQ}
              type="button"
              onClick={() => {
                setCreateQClicked(true);
                const timer = setTimeout(() => {
                  closePopover();
                }, delay);
                clearTimeout();
              }}
            >
              Create Question
            </button>
          </CustomPopover>
        </div>
      </div>
      <div className={styles.secondRow}>
        <span className={styles.questionsText}>Questions</span>
        <div className={styles.searchBar}>
          <SearchBar
            isExtended={1}
            inputFieldValue={search}
            inputFieldOnChange={event => {
              setSearch(event.target.value);
              if (event.target.value === '') {
                dispatch(setPageIndex(1));
                dispatch(
                  setFilterState({
                    ...filter,
                    rootAssessmentId: id,
                    searchParameter: event.target.value
                  })
                );
                dispatch(
                  getQuestionByFilterAction({
                    ...filter,
                    rootAssessmentId: id,
                    searchParameter: event.target.value
                  })
                );
              }
            }}
            inputFieldOnKeyDown={event => {
              if (event.key === 'Enter') {
                dispatch(
                  setFilterState({
                    ...filter,
                    rootAssessmentId: id,
                    searchParameter: event.target.value
                  })
                );
                dispatch(
                  getQuestionByFilterAction({
                    ...filter,
                    rootAssessmentId: id,
                    searchParameter: search
                  })
                );
                dispatch(setPageIndex(1));
              }
            }}
            color="white"
            size="xsmall"
            extendedIconOnClick={() => {
              dispatch(
                getQuestionByFilterAction({
                  ...filter,
                  rootAssessmentId: id,
                  searchParameter: search
                })
              );
              dispatch(setPageIndex(1));
            }}
            inputFieldPlaceholder="Search"
          />
        </div>
      </div>
      <div
        className={styles.listWrapper}
        onScroll={() => handleScroll()}
        ref={filteredQListRef}
        changeProp={change}
        style={{
          paddingRight: !scrollableContent ? '0px' : '12px',
          pointerEvents: isScrollAllowed ? 'initial' : 'none',
          opacity: isScrollAllowed ? '1' : '0.4'
        }}
      >
        {!isLoading &&
          qList?.map((question, index) => (
            <FilteredQuestion
              item={question}
              status={question.purchaseType}
              language={question.questionLanguage}
              difficulty={question.difficulty}
              isIncluded={question.isIncluded}
              questionDuration={question.questionDuration}
              questionTypeId={question.questionTypeId}
              title={question.title}
              questionId={question.questionId}
              skills={question.codingLanguages}
              index={index + 1}
              setScrollPos={saveLastScrollPos}
              disableScrollTemp={setIsScrollAllowed}
              isScrollAllowed={isScrollAllowed}
            />
          ))}
        {!isLoading && qList?.length === 0 && (
          <div className={styles.loading}>No such question is available.</div>
        )}
        {isLoading && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
      {showPopup.appear && <AssessmentAddedPopup show={showPopup} type={1} />}
    </div>
  );
}
