import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from '../Styles/AddConnection.module.css';
import {
  AssessmentName,
  atsState,
  checkBoxState,
  switchState
} from '../../../redux/addAssessment/selectors';
import { setAtsState, setCheckBoxState, setSwitchState } from '../../../redux/addAssessment/slice';
import ConnectionItem from './ConnectionItem';
import CustomButton from '../../../Common/Components/CustomButton';
import {
  AtsList,
  authorizedUsers,
  connectionListRedux,
  continueToAssessmentDetailRedux
} from '../../../redux/assessmentsVol2/selectors';
import { addAssessmentActionVol2, getAtsListAction } from '../../../redux/assessmentsVol2/actions';
import { publishAssessmentControlAction } from '../../../redux/addAssessment/actions';

import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';
import { useWindowSize } from '../../../utils/helpers';

export default function AddConnection({ id }) {
  const dispatch = useDispatch();
  const [windowW, windowH] = useWindowSize();
  const switchValue = useSelector(switchState);
  const history = useHistory();
  const ats = useSelector(atsState);
  const radioState = useSelector(checkBoxState);
  const assessmentName = useSelector(AssessmentName); // Name of the assessment taken at first step
  const atsList = useSelector(AtsList);
  const connectionList = useSelector(connectionListRedux);
  // Creates the assessment for the next step, to have a working link
  const invited = useSelector(authorizedUsers);

  const getEnumOfConnectionList = () => {
    const temp = [];
    radioState.forEach(e => {
      connectionList.forEach(c => {
        if (c.name === e) temp.push(c.code);
      });
    });
    return temp;
  };

  const createAssessment = async () => {
    const data = {
      rootAssessmentId: id,
      assessmentUserIds: `${invited}`,
      name: assessmentName,
      isCvNeeded: true,
      isATSEnabled: switchValue === 1 && atsList.filter(x => x.name === ats).length > 0,
      atsType:
        switchValue === 0
          ? ''
          : atsList.filter(x => x.name === ats).length > 0
          ? atsList.filter(x => x.name === ats)[0].id
          : '', // FIXME: bad code
      connectionList: getEnumOfConnectionList()
    };
    await dispatch(addAssessmentActionVol2(data));
  };

  const continueToAssessmentDetailFlag = useSelector(continueToAssessmentDetailRedux);

  useEffect(() => {
    if (continueToAssessmentDetailFlag === true) {
      // Eğer connection seçilmezse direkt assessment detail sayfasına yönlendir
      if (
        (switchValue === 0 && radioState.length === 0) ||
        (switchValue === 1 && atsList.filter(x => x.name === ats).length === 0)
      ) {
        history.replace(`/create-assessment/${id}/${5}`);
      } else {
        history.replace(`/create-assessment/${id}/${4}`);
      }
    }
  }, [continueToAssessmentDetailFlag]);

  const user = useSelector(userSelector);

  useEffect(() => {
    dispatch(getAtsListAction());

    /* Mixpanel pageview */
    mixpanel.track('Create Assessment Page - Add Connection Pageview', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Id': id,
      'Root Assessment Name': localStorage.getItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName'
      )
    });
  }, []);

  const [nextButtonLoading, setNextButtonLoading] = useState(false);

  return (
    <div className={styles.container}>
      <div>
        {windowW > 700 && <div className={styles.title}>Add Connection</div>}
        <div className={styles.subtitle}>
          Please select the platforms you will use to connect the assessment.
        </div>
        <div className={styles.atsRow}>
          <div
            className={`${styles.switchContainer} ${switchValue === 1 ? styles.active : ''}`}
            onClick={() => {
              dispatch(setAtsState(''));
              dispatch(setCheckBoxState([]));
              dispatch(setSwitchState(switchValue === 1 ? 0 : 1));
            }}
          >
            <div
              style={switchValue === 0 ? { marginLeft: 2 } : { marginLeft: 21 }}
              className={styles.switchButton}
            />
          </div>
          <div className={styles.switchText}>Applicant Tracking System (ATS)</div>
        </div>
        <div className={styles.listWrapper}>
          {atsList &&
            (switchValue === 0 ? connectionList : atsList).map((x, index) => {
              if (switchValue === 1) {
                return (
                  <ConnectionItem
                    mobile={windowW <= 700}
                    text={x.name}
                    index={index}
                    active={ats === x.name}
                    ats={switchValue === 1}
                    myonClick={text => {
                      dispatch(setAtsState(text));
                    }}
                  />
                );
              }
              return (
                <ConnectionItem
                  mobile={windowW <= 700}
                  text={x.name}
                  active={radioState.includes(x.name)}
                  ats={radioState === 1}
                  index={index}
                  myonClick={text => {
                    if (radioState.includes(x.name)) {
                      let temp = [];
                      temp = [...radioState];
                      temp.splice(temp.indexOf(x.name), 1);
                      dispatch(setCheckBoxState(temp));
                    } else {
                      dispatch(setCheckBoxState([...radioState, text]));
                    }
                  }}
                />
              );
            })}
        </div>
      </div>
      {windowW > 700 ? (
        <div className={styles.buttonRow}>
          <CustomButton
            type={5}
            textField="Back"
            buttonOnClick={() => {
              history.replace(`/create-assessment/${id}/${2}`);
            }}
          />
          <CustomButton
            type={1}
            loading={nextButtonLoading}
            textField="Next"
            buttonOnClick={async () => {
              setNextButtonLoading(true);

              /* Mixpanel button click event */
              mixpanel.track('Create Assessment Page - Add Connection - Next Button Click', {
                'User Id': user.userId,
                'Company Name': user.companyName,
                'Name Surname': user.userNameSurname,
                'Root Assessment Id': id,
                'Root Assessment Name': localStorage.getItem(
                  'Mixpanel_AssessmentCustomizationRootAssessmentName'
                ),
                'ATS Selected Flag':
                  switchValue === 1 && atsList.filter(x => x.name === ats).length > 0
              });

              const resp = await dispatch(publishAssessmentControlAction());
              if (resp?.payload?.result) {
                await createAssessment();
              } else {
                history.replace('/assessments');
              }
              setNextButtonLoading(false);
            }}
          />
        </div>
      ) : (
        <div className={styles.buttonsWrapper}>
          <CustomButton
            size="mobile"
            type={windowW > 700 ? 5 : 8}
            textField="Back"
            buttonOnClick={() => {
              history.replace(`/create-assessment/${id}/${2}`);
            }}
          />
          <CustomButton
            size="mobile"
            textField="Next"
            style={{ marginRight: 0, marginLeft: 'auto' }}
            buttonOnClick={async () => {
              setNextButtonLoading(true);

              /* Mixpanel button click event */
              mixpanel.track('Create Assessment Page - Add Connection - Next Button Click', {
                'User Id': user.userId,
                'Company Name': user.companyName,
                'Name Surname': user.userNameSurname,
                'Root Assessment Id': id,
                'Root Assessment Name': localStorage.getItem(
                  'Mixpanel_AssessmentCustomizationRootAssessmentName'
                ),
                'ATS Selected Flag':
                  switchValue === 1 && atsList.filter(x => x.name === ats).length > 0
              });

              const resp = await dispatch(publishAssessmentControlAction());
              if (resp?.payload?.result) {
                await createAssessment();
              } else {
                history.replace('/assessments');
              }
              setNextButtonLoading(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
