import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../Common/Components/CustomButton';
import { removeSkillBasedQuestionAction } from '../../../redux/assessmentsVol2/actions';
import { questionBeingRemoved } from '../../../redux/assessmentsVol2/selectors';
import {
  setRemoveQuestionShow,
  setQuestionBeingRemoved,
  setShow
} from '../../../redux/assessmentsVol2/slice';
import styles from '../Styles/RemoveQuestionModal.module.css';

export default function RemoveQuesionModal({
  show = false,
  onHide = () => {},
  onDelete = () => {}
}) {
  const dispatch = useDispatch();
  const questionToBeRemoved = useSelector(questionBeingRemoved);
  const [loading, setLoading] = useState(false);
  const handleRemoveQuestion = async () => {
    setLoading(true);
    const a = await dispatch(removeSkillBasedQuestionAction(questionToBeRemoved));
    if (a) {
      dispatch(setRemoveQuestionShow(false));
      dispatch(setQuestionBeingRemoved(null));
      dispatch(setShow({ type: 2, appear: true }));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!show) {
      setLoading(false);
    }
  }, [show]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <div className={styles.title}>Remove Question</div>
      <div className={styles.subtitle}>Are you sure you want to remove this question?</div>
      <div className={styles.row}>
        <CustomButton
          type={2}
          textField="Cancel"
          buttonOnClick={onHide}
          style={{ marginRight: '4px' }}
        />
        <CustomButton
          textField="Remove"
          buttonOnClick={() => {
            handleRemoveQuestion();
            onHide();
          }}
          type={6}
          loading={loading}
        />
      </div>
    </Modal>
  );
}
