import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../Common/Styles/Funnel.module.css';
import CandidateFunnelStage from './CandidateFunnelStage';
import { UserContext } from '../Pages/CandidatePage';
import { funnelInfoSelector } from '../../redux/assessmentDetailVol2/selectors';
import { candidateReportMoveCandidateToFunnel, moveCandidateToFunnel } from '../../utils/helpers';
import { getFunnelInformationAction } from '../../redux/assessmentDetailVol2/actions';

function CandidateFunnel({
  funnelList,
  activeFunnelName,
  getInfo,
  setClose,
  isScored,
  moveBack,
  assessmentID,
  moveToRejected,
}) {
  const dispatch = useDispatch();
  const candidateId = useContext(UserContext)[4];
  const [customFunnelList, setCustomList] = useState();

  const funnels = useSelector(funnelInfoSelector);


  async function moveCandidate(funnel, userId) {
    candidateReportMoveCandidateToFunnel(funnel, assessmentID, userId, dispatch);
  }

  useEffect(async () => {
    const temp = [];
    if (funnels?.length > 0) {
      funnels.forEach(item => {
        if (
          item.funnelName !== 'Not Scored' &&
          item.funnelName !== 'Scored' &&
          item.funnelName !== 'Hired' &&
          item.funnelName !== 'Rejected' && 
          item.funnelName !== 'All Candidates'
        ) {
          temp.push(item);
        } 
        setCustomList(temp);
      });
    } else {
      const funnelData = {
        CompanyAssessmentId: assessmentID
      };
      dispatch(getFunnelInformationAction(funnelData));
      getInfo();
    }
  }, [funnels]);

  return (
    <div
      className={`${styles.wrapper1} ${styles.noFlickr} `}
      style={{ paddingLeft: 0, paddingBottom: '0px !important', borderRadius: 10 }}
    >
      <div className={`${styles.innerWrapper}  `}>
        <div className={styles.rowWrapper}>
          <div className={styles.header}>NEW</div>
          <div>
            {!isScored && (
              <CandidateFunnelStage
                section={2}
                stageName="Not Scored"
                item={funnels?.filter(funnel => funnel.funnelName === 'Not Scored')[0]}
                hide={activeFunnelName === 'Scored'}
                onFunnelClick={async () => {
                  if (activeFunnelName !== 'Not Scored') {
                    await moveCandidate(funnels?.filter(funnel => funnel.funnelName === 'Not Scored')[0], candidateId);
                    setTimeout(async () => {
                      await getInfo();
                      setClose(false);
                    }, 300);
                  }
                  
                }}
                selected={activeFunnelName === 'Not Scored'}
                userCount={
                  funnels.filter(funnel => funnel.funnelName === 'Not Scored')[0]?.userCount
                }
              />
            )}
            {isScored && (
              <CandidateFunnelStage
                section={2}
                stageName="Scored"
                onFunnelClick={async () => {
                  if (activeFunnelName !== 'Scored') {
                    await moveCandidate(funnels?.filter(funnel => funnel.funnelName === 'Scored')[0], candidateId);
                    setTimeout(async () => {
                      await getInfo();
                      setClose(false);
                    }, 300);
                  }
                  
                }}
                item={funnels?.filter(funnel => funnel.funnelName === 'Scored')[0]}
                hide={activeFunnelName === 'Not Scored'}
                selected={activeFunnelName === 'Scored'}
                userCount={funnels?.filter(funnel => funnel.funnelName === 'Scored')[0]?.userCount}
              />
            )}
          </div>
        </div>

        <div className={styles.rowWrapper}>
          <div className={styles.header}>IN REVIEW</div>

          {customFunnelList?.map((value, index) => (
            <CandidateFunnelStage
              section={3}
              stageName={value.funnelName}
              item={value}
              myselected={activeFunnelName === value.funnelName ? 'selected' : 'notSelected'}
              hide={false}
              onFunnelClick={async () => {
                if (activeFunnelName !== value.funnelName) {
                  await moveCandidate(value, candidateId);
                  setTimeout(async () => {
                    await getInfo();
                    setClose(false);
                  }, 300);
                }
              }}
              selected={activeFunnelName === value.funnelName}
              userCount={value.userCount}
            />
          ))}
        </div>

        <div className={styles.rowWrapper}>
          <div className={styles.header}>CONCLUDED</div>
          <div>
            <CandidateFunnelStage
              section={4}
              stageName="Hired"
              item={funnels?.filter(funnel => funnel.funnelName === 'Hired')[0]}
              onFunnelClick={async () => {
                if (activeFunnelName !== 'Hired') {
                  await moveCandidate(
                    funnels?.filter(funnel => funnel.funnelName === 'Hired')[0],
                    candidateId
                  );
                  setTimeout(async () => {
                    await getInfo();
                    setClose(false);
                  }, 300);
                }
              }}
              selected={activeFunnelName === 'Hired'}
              userCount={funnels?.filter(funnel => funnel.funnelName === 'Hired')[0]?.userCount}
            />
            <CandidateFunnelStage
              section={5}
              stageName="Rejected"
              item={funnels?.filter(funnel => funnel.funnelName === 'Rejected')[0]}
              onFunnelClick={async () => {
                if (activeFunnelName !== 'Rejected') {
                  await moveToRejected();
                }
              }}
              selected={activeFunnelName === 'Rejected'}
              userCount={funnels?.filter(funnel => funnel.funnelName === 'Rejected')[0]?.userCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CandidateFunnel;
