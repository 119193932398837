import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getParam, useWindowSize } from '../../utils/helpers';
import Modal from '../CustomModal/Modal';
import constants from '../../utils/constants';
import styles from './RequestMail.module.css';
import CustomButton from '../Components/CustomButton';
import {
  setRequestInfoBannerErrorFlag,
  setRequestInfoBannerState,
  setRequestInfoBannerText,
  setRequestNumberExceed
} from '../../redux/candidateDetail/slice';
import MobileModal from '../Components/MobileModal/MobileModal';
import CloseButton from '../Components/CloseButton';
import { sendRequestAction } from '../../redux/candidateDetail/actions';

// import constants from '../../utils/constants';

const RequestMail = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [screenW] = useWindowSize();
  //   const [emailSubject, setEmailSubject] = useState();
  //   const [emailContent, setEmailContent] = useState();

  //   useEffect(() => {
  //     if (show) {
  //         setEmailSubject(constants.REQUEST_MAIL_SUBJECT)
  //         setEmailContent(constants.REQUEST_MAIL_CONTENT);
  //     } else {
  //         setEmailSubject()
  //         setEmailContent()
  //     }
  //   }, [show])

  const sendRequest = async () => {
    setButtonLoading(true);
    const data = {
      userId: id,
      companyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };

    const response = await dispatch(sendRequestAction(data));
    if (
      response.payload.typeCode &&
      response.payload.typeCode === constants.HTTP_RESPONSE_CODE_MAX_REQUEST
    ) {
      onClose();
      setButtonLoading(false);
      dispatch(setRequestNumberExceed(true));
    } else if (response?.meta?.requestStatus === 'fulfilled') {
      dispatch(setRequestInfoBannerErrorFlag(false));
      dispatch(setRequestInfoBannerText('A request mail is sent to the candidate.'));
      dispatch(setRequestInfoBannerState(true));
      // onClose();
      setTimeout(() => {
        location.reload();
      }, 1000);
      // setButtonLoading(false);
    } else {
      // fail
      dispatch(setRequestInfoBannerErrorFlag(true));
      dispatch(setRequestInfoBannerText('An error occurred!'));
      dispatch(setRequestInfoBannerState(true));
    }
  };

  const ModalContent = (
    <div className={styles.contentWrapper}>
      <text className={styles.contentHeader}>Send Request to Candidate</text>
      <div className={styles.descriptionWrapper}>
        <text>Do you want to send connection request to this candidate? </text>
        <text className={styles.coensioPurple}>
          *Only candidates who accepted the connection request will be charged.
        </text>
      </div>
      {/* <div className={styles.mailWrapper}>
          <text className={styles.mailSubject}>
            {emailSubject}
          </text>
          <div className={styles.mailContent}  dangerouslySetInnerHTML={{__html: emailContent}} />
        </div> */}
      <div className={styles.buttonWrapper}>
        <CustomButton
          textField="Cancel"
          type={2}
          isResponsive
          buttonOnClick={onClose}
          customStyle={screenW <= 576 ? { width: '100%' } : {}}
        />
        <CustomButton
          textField="Send Request"
          type={1}
          isResponsive
          buttonOnClick={sendRequest}
          loading={buttonLoading}
          customStyle={screenW <= 576 ? { width: '100%' } : {}}
        />
      </div>
    </div>
  );

  const WebRequestModal = (
    <Modal
      contentStyle={{
        border: '1px solid rgba(88, 88, 88, 0.2)',
        borderRadius: '24px',
        padding: '2rem 2.4rem'
      }}
      onClose={onClose}
      size={{ width: 'min(76.8rem, 768px)', height: 'fit-content' }}
      centerHorizontally
      centerVertically
      show={show}
      outsideClickClose
    >
      {ModalContent}
    </Modal>
  );
  const MobileRequestModal = (
    <MobileModal
      id="mobile-request-modal"
      contentStyle={{
        padding: '2rem 2rem 2.4rem',
        minWidth: '375px',
        overflow: 'hidden'
      }}
      onClose={onClose}
      backdropStyle={{ overflow: 'hidden' }}
      show={show}
      outsideClickClose
    >
      {ModalContent}
      <div className={styles.mobileCloseButtonWrapper}>
        <CloseButton onClick={onClose} />
      </div>
    </MobileModal>
  );

  return screenW > 576 ? WebRequestModal : MobileRequestModal;
};

export default RequestMail;
