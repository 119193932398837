import { React, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import grey from '../../../images/greyEllipse.svg';
import green from '../../../images/greenEllipse.svg';
import greenNext from '../../../images/assessmentDetail/next-green.svg';
import greyNext from '../../../images/assessmentDetail/next-grey.svg';
import redNext from '../../../images/assessmentDetail/next-red.svg';
import red from '../../../images/redEllipse.svg';
import ShortText from '../../../Common/Components/ShortText';
import editMobile from './editMobile.svg';
import {
  getAssessmentDetailAction,
  getAssessmentInfoAction,
  updateAssessmentInfoAction
} from '../../../redux/assessmentDetail/actions';

import CloseButton from '../../../Common/Components/CloseButton';
import styles from './AssessmentSettingsModal.module.css';
import UserIcon from '../../../Common/Components/UserIcon';
import { publishAssessmentControlAction } from '../../../redux/addAssessment/actions';
import { ReactComponent as EditIcon } from '../Images/editIcon.svg';
import EditAssessmentNameModal from './EditAssessmentNameModal';
import AddConnectionModal from './AddConnectionModal';
import AuthorizeUserModal from './AuthorizeUserModal';
import { assessmentSettingInfoRedux } from '../../../redux/assessmentDetail/selectors';
import ConnectionRow from './ConnectionRow';
import { getAtsListAction } from '../../../redux/assessmentsVol2/actions';
import { AtsList, connectionListRedux } from '../../../redux/assessmentsVol2/selectors';
import { setToBePurchasedAddOn } from '../../../redux/plan/slice';
import { getCurrentPlanAction, getCurrentUsageAction } from '../../../redux/plan/actions';
import { userSelector } from '../../../redux/auth/selectors';
import { setAssessmentObjStatus } from '../../../redux/assessmentDetailVol2/slice';
import {
  setSettingsBannerMessage,
  setSettingsError,
  setSettingsSuccess
} from '../../../redux/assessmentDetail/slice';
import { useWindowSize } from '../../../utils/helpers';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';

export default function AssessmentSettingsModal({
  onModalHide,
  AssessmentName,
  AssesmentTitle,
  editUsers,
  setAssessmentUnavailable = () => {},
  purchaseSuccess,
  show,
  allowedSettingsModalHeight,
  ...props
}) {
  const popupData = useSelector(assessmentSettingInfoRedux);
  const [shake, setShake] = useState(false);
  const [modalBlur, setModalBlur] = useState(false);
  const [addConnectionShow, setAddConnectionShow] = useState(false);
  const [authorizeUserShow, setAuthorizeUserShow] = useState(false);

  const [addConnectionMobileShow, setAddConnectionMobileShow] = useState(false);
  const [authorizeUserMobileShow, setAuthorizeUserMobileShow] = useState(false);
  const [nameMobileShow, setNameMobileShow] = useState(false);

  const [nameShow, setNameShow] = useState(false);
  const [windowW, windowH] = useWindowSize();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const atsList = useSelector(AtsList);
  const connectionList = useSelector(connectionListRedux);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAtsListAction({}));
  }, [popupData]);

  const assessmentId = id;

  function getAssessmentInfo() {
    dispatch(getAssessmentInfoAction({ CompanyAssessmentId: assessmentId }));
  }

  useEffect(async () => {
    if (assessmentId) {
      getAssessmentInfo();
    }
  }, [assessmentId]);

  const changeAssessmentStatus = async e => {
    let flag = 0;
    if (e === 10) {
      const data = {};
      const assessmentAvailability = await dispatch(publishAssessmentControlAction(data));
      if (assessmentAvailability?.payload?.result === true) {
        flag = 1;
      } else if (assessmentAvailability?.payload?.result === false) {
        flag = 2;
        await dispatch(getAssessmentDetailAction({ CompanyAssessmentId: assessmentId }));
        await onModalHide();
        const data = {
          bundleItemCode: 10
        };
        await dispatch(setToBePurchasedAddOn(data));
        await setAssessmentUnavailable(true);
      }
    }
    if (flag === 1 || flag === 0) {
      const data = {
        companyAssessmentId: assessmentId,
        companyAssessmentName: popupData?.name,
        assessmentStatus: e
      };
      const resp = await dispatch(updateAssessmentInfoAction(data));
      await setTimeout(() => {
        getAssessmentInfo();
      }, 400);

      if (resp?.meta?.requestStatus === 'fulfilled') {
        dispatch(setAssessmentObjStatus(e));
        await dispatch(setSettingsBannerMessage('Changes saved successfully'));
        dispatch(setSettingsSuccess(true));
      } else {
        await dispatch(setSettingsBannerMessage('An error occurred'));
        await dispatch(setSettingsError(true));
      }
    }
  };

  const Published = () => (
    <>
      <div
        className={`d-flex align-items-center  ${styles.statusList}  ${styles.statusListUser2}`}
        style={shake ? { backgroundColor: 'white', border: 'none' } : {}}
      >
        <img src={green} alt="button" style={{ marginLeft: '13px' }} />
        <span style={{ color: '#4AC389', marginLeft: '10px', marginRight: 'auto' }}>Published</span>
        <img
          alt="next"
          style={{
            color: '#4AC389',
            float: 'right',
            marginLeft: 'auto',
            marginRight: '10px'
          }}
          src={greenNext}
          className={shake ? styles.animate : ''}
        />
        <span />
      </div>
    </>
  );

  const Unpublished = (it = false) => (
    <>
      <img src={grey} alt="button" style={{ marginLeft: '13px' }} />
      <span style={{ color: '#585858CC', marginLeft: '10px', marginRight: 'auto' }}>
        Unpublished
      </span>
      {it && (
        <img
          alt="next"
          style={{
            color: '#585858CC',
            float: 'right',
            marginLeft: 'auto',
            marginRight: '10px'
          }}
          src={greyNext}
          className={shake ? styles.animate : ''}
        />
      )}
      <span />
    </>
  );
  const Unpublishedit = (it = false) => (
    <>
      <img src={grey} alt="button" style={{ marginLeft: '13px' }} />
      <span style={{ color: '#585858CC', marginLeft: '10px' }}>Unpublished</span>
      <span />
    </>
  );

  const Archived = (it = false) => (
    <>
      <img src={red} alt="button" style={{ marginLeft: '13px' }} />
      <span style={{ color: 'rgba(222, 71, 71, 0.8)', marginLeft: '10px', marginRight: 'auto' }}>
        Archived
      </span>
      {it && (
        <img
          alt="next"
          style={{
            color: '#DE4747CC',
            float: 'right',
            marginLeft: 'auto',
            marginRight: '10px'
          }}
          src={redNext}
          className={shake ? styles.animate : ''}
        />
      )}
      <span />
    </>
  );
  const Archivedit = (it = false) => (
    <>
      <img src={red} alt="button" style={{ marginLeft: '13px' }} />
      <span style={{ color: 'rgba(222, 71, 71, 0.8)', marginLeft: '10px' }}>Archived</span>
      <span />
    </>
  );

  const Publishedit = () => (
    <div className="d-flex">
      <img src={green} alt="button" style={{ marginLeft: '13px' }} />
      <span style={{ color: '#4AC389', paddingLeft: '10px' }}>Published</span>
      <span />
    </div>
  );

  function handleSelect(evtKey) {
    switch (evtKey) {
      case 'Unpublished':
        changeAssessmentStatus(20);
        break;
      case 'Archived':
        changeAssessmentStatus(30);
        break;
      case 'Published':
        changeAssessmentStatus(10);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (purchaseSuccess) {
      changeAssessmentStatus(10);
      const data = {
        CompanyId: user?.companyId
      };
      dispatch(getCurrentPlanAction(data));
      dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
    }
  }, [purchaseSuccess]);


  const mobileAssmStatusDropdown = useRef();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {

        if (ref.current && !ref.current.contains(event.target)) {
          const wrapperElem = document.getElementById('mobile-assm-status-dropdown');
          const menuElem = document.getElementById('mobile-assm-status-dropdown-menu');
          const toggleElem = document.getElementById('dropdown-basic');
          wrapperElem?.classList?.remove('show')
          menuElem?.classList?.remove('show')
          toggleElem?.setAttribute("aria-expanded", false);
          setShake(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(mobileAssmStatusDropdown);

  const Mobile = (
    <MobileModal
      id="mobile-assm-settings-modal"
      onClose={onModalHide}
      outsideClickClose={!(nameMobileShow || addConnectionMobileShow || authorizeUserMobileShow)}
      show={show}
      backdropStyle={{
        backgroundColor:
          !(nameMobileShow || addConnectionMobileShow || authorizeUserMobileShow) && 'transparent',
        overflowY: 'hidden',
        transition:
          !(nameMobileShow || addConnectionMobileShow || authorizeUserMobileShow) && show &&
          'background-color 0.3s linear 0.125s'
      }}
      contentStyle={{
        height: `${allowedSettingsModalHeight}px`,
        padding: '20px',
        minWidth: '375px',
        borderRadius: '0px'
      }}
      // scrollOnTouch
    >
      <EditAssessmentNameModal
        mobile
        show={nameMobileShow}
        onHide={() => {
          setNameMobileShow(false);
        }}
        name={popupData?.name}
        id={assessmentId}
        status={popupData?.assessmentStatus}
      />
      <AddConnectionModal
        mobile
        show={addConnectionMobileShow}
        onHide={() => {
          setAddConnectionMobileShow(false);
        }}
        id={assessmentId}
      />
      <AuthorizeUserModal
        mobile
        show={authorizeUserMobileShow}
        onHide={() => {
          setAuthorizeUserMobileShow(false);
        }}
        id={assessmentId}
      />
      <div className={styles.mobileWrapper}>
        <div className={styles.headerRow}>
          <span className={styles.assmSettingsText}>Assessment Settings</span>
          <CloseButton size="medium" onClick={onModalHide} />
        </div>
        <span className={`${styles.subheaderName} ${styles.headerRow}`}>{AssessmentName}</span>

        <Dropdown
          id="mobile-assm-status-dropdown"
          className={`${styles.headerRow} ${styles['user-role-dropdown1']} ${styles['funnel-dropdown']} `}
          // eslint-disable-next-line react/jsx-no-bind
          onSelect={handleSelect}
          onClick={() => {
            if (!shake) {
              // Dont know why but timeout is required
              setTimeout(() => {
                const wrapperElem = document.getElementById('mobile-assm-status-dropdown');
                const menuElem = document.getElementById('mobile-assm-status-dropdown-menu');
                const toggleElem = document.getElementById('dropdown-basic');
                wrapperElem?.classList?.add('show')
                menuElem?.classList?.add('show')
                toggleElem?.setAttribute("aria-expanded", true);
              }, 10);
              setShake(true)
            } else {
              // Dont know why but timeout is required
              setTimeout(() => {
                const wrapperElem = document.getElementById('mobile-assm-status-dropdown');
                const menuElem = document.getElementById('mobile-assm-status-dropdown-menu');
                const toggleElem = document.getElementById('dropdown-basic');
                wrapperElem?.classList?.remove('show')
                menuElem?.classList?.remove('show')
                toggleElem?.setAttribute("aria-expanded", false);
              }, 10);
              setShake(false);
            }
          }}

          ref={mobileAssmStatusDropdown}
        >
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className=""
          >
            {popupData?.assessmentStatus === 20 && (
              <div
                className={`d-flex align-items-center ${styles.statusList}  ${styles.statusListUser2}`}
                style={
                  shake
                    ? {
                        border: 'none',
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)'
                      }
                    : {
                        backgroundColor: 'white'
                      }
                }
              >
                <Unpublished />
              </div>
            )}
            {popupData?.assessmentStatus === 10 && (
              <div
                className={`d-flex align-items-center ${styles.statusList} `}
                style={
                  shake
                    ? {
                        border: 'none',
                        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)',
                        borderRadius: '8px',
                        backgroundColor: 'white'
                      }
                    : { borderRadius: '8px' }
                }
              >
                <Published />
              </div>
            )}
            {popupData?.assessmentStatus === 30 && (
              <div
                className={`d-flex align-items-center    ${styles.statusList}  ${styles.statusListUser2}`}
                style={
                  shake
                    ? {
                        border: 'none',
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)'
                      }
                    : {
                        backgroundColor: 'white',
                        border: '1px solid rgba(221, 58, 58, 0.1)'
                      }
                }
              >
                <Archived />
              </div>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ border: 'none' }} className={styles.EditMenu} id="mobile-assm-status-dropdown-menu">
            {popupData?.assessmentStatus !== 20 && (
              <Dropdown.Item eventKey="Unpublished" className={styles.editModalItem}>
                <div className="d-flex">
                  <Unpublishedit />
                </div>
              </Dropdown.Item>
            )}
            {popupData?.assessmentStatus !== 30 && (
              <Dropdown.Item eventKey="Archived" className={styles.editModalItem}>
                <div className="d-flex">
                  <Archivedit />
                </div>
              </Dropdown.Item>
            )}
            {popupData?.assessmentStatus !== 10 && (
              <Dropdown.Item eventKey="Published" className={styles.editModalItem}>
                <div className="d-flex">
                  <Publishedit />
                </div>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <div className={styles.headerRowMobile}>
          <span className={styles.subheaderName}>Position name</span>
          <img
            className={styles.editIcons}
            alt=""
            src={editMobile}
            onClick={() => {
              setNameMobileShow(true);
            }}
          />
        </div>
        <ShortText
          id="positionName"
          staticLimit={40}
          name={popupData?.name}
          textClassName={`${styles.assmSettingsText} ${styles.headerRow}`}
        />

        <div className={styles.headerRowMobile}>
          <span className={styles.subheaderName}>Authorized User</span>
          <img
            className={styles.editIcons}
            alt=""
            src={editMobile}
            onClick={() => setAuthorizeUserMobileShow(true)}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          {popupData?.assessmentUsers?.slice(0, 4).map((user, index) => (
            <UserIcon
              nested={index > 0}
              name={index !== 3 ? user.name : '+'}
              surname={index !== 3 ? user.surname : popupData?.assessmentUsers?.length - 3}
            />
          ))}
        </div>

        <div className={styles.headerRow}>
          <span className={styles.subheaderName}>Connection</span>
        </div>
        <div
          className={
            !popupData?.isAtsEnabled &&
            popupData?.connectionList?.length === 0 &&
            styles.addConnection
          }
        >
          <div
            className={
              !popupData?.isAtsEnabled &&
              popupData?.connectionList?.length === 0 &&
              styles.addConnectionText
            }
            onClick={() => {
              if (!popupData?.isAtsEnabled && popupData?.connectionList?.length === 0) {
                setAddConnectionMobileShow(true);
              }
            }}
          >
            {!popupData?.isAtsEnabled && popupData?.connectionList?.length === 0 ? (
              '+ Add connection'
            ) : popupData?.isAtsEnabled ? (
              <ConnectionRow
                mobile
                connection={atsList?.filter(ats => ats.id === popupData?.atsType)[0]?.code}
                assessmentID={assessmentId}
                video={atsList?.filter(ats => ats.id === popupData?.atsType)[0]?.link}
              />
            ) : (
              popupData?.connectionList?.map((connection, i) => (
                <ConnectionRow
                  mobile
                  connection={connection}
                  assessmentID={assessmentId}
                  video={connectionList?.filter(c => c.code === connection)[0]?.link}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </MobileModal>
  );
  const ModalNormal = (
    <Modal
      {...props}
      contentClassName={`${styles.customModal} ${styles['modal-content']} ${
        modalBlur && styles.blurredModal
      } ${(addConnectionShow || authorizeUserShow) && styles.customModalBig} `}
      dialogClassName={styles.dialogCustomModal}
      centered
      onHide={onModalHide}
      show={show}
    >
      <EditAssessmentNameModal
        show={nameShow}
        onHide={() => {
          setNameShow(false);
          setModalBlur(false);
        }}
        name={popupData?.name}
        id={assessmentId}
        status={popupData?.assessmentStatus}
      />
      <AddConnectionModal
        show={addConnectionShow}
        onHide={() => {
          setAddConnectionShow(false);
          setModalBlur(false);
        }}
        id={assessmentId}
      />
      <AuthorizeUserModal
        show={authorizeUserShow}
        onHide={() => {
          setAuthorizeUserShow(false);
          setModalBlur(false);
        }}
        id={assessmentId}
      />
      {popupData?.assessmentStatus ? (
        <div>
          <div className={styles.headerRow}>
            <span className={styles.header}>Assessment Settings</span>
            <CloseButton size="small" onClick={() => onModalHide()} />
          </div>
          <div className={`${styles.headerRow} ${styles.borderedRow}`}>
            <span className={styles.name}>{AssessmentName}</span>
            <Dropdown
              className={`${styles['user-role-dropdown1']} ${styles['funnel-dropdown']}`}
              style={{ alignSelf: 'center' }}
              // eslint-disable-next-line react/jsx-no-bind
              onSelect={handleSelect}
              onClick={() => {
                setShake(!shake);
              }}
              autoClose
              onToggle={() => {
                setShake(false);
              }}
            >
              <Dropdown.Toggle variant="" id="dropdown-basic" className="">
                {popupData?.assessmentStatus === 20 && (
                  <div
                    className={`d-flex align-items-center ${styles.statusList}  ${styles.statusListUser2}`}
                    style={
                      shake
                        ? {
                            border: 'none',
                            backgroundColor: 'white',
                            boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)'
                          }
                        : { backgroundColor: 'rgba(88, 88, 88, 0.1)' }
                    }
                  >
                    <Unpublished />
                  </div>
                )}
                {popupData?.assessmentStatus === 10 && (
                  <div
                    className={`d-flex align-items-center ${styles.statusList} `}
                    style={
                      shake
                        ? {
                            border: 'none',
                            boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)',
                            borderRadius: '8px',
                            backgroundColor: 'white'
                          }
                        : { borderRadius: '8px' }
                    }
                  >
                    <Published />
                  </div>
                )}
                {popupData?.assessmentStatus === 30 && (
                  <div
                    className={`d-flex align-items-center    ${styles.statusList}  ${styles.statusListUser2}`}
                    style={
                      shake
                        ? {
                            border: 'none',
                            backgroundColor: 'white',
                            boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.15)'
                          }
                        : {
                            backgroundColor: 'rgba(221, 58, 58, 0.1)',
                            border: '1px solid rgba(221, 58, 58, 0.1)'
                          }
                    }
                  >
                    <Archived />
                  </div>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.EditMenu} style={{ border: 'none' }}>
                {popupData?.assessmentStatus !== 20 && (
                  <Dropdown.Item eventKey="Unpublished" className={styles.editModalItem}>
                    <div className="d-flex">
                      <Unpublishedit />
                    </div>
                  </Dropdown.Item>
                )}
                {popupData?.assessmentStatus !== 30 && (
                  <Dropdown.Item eventKey="Archived" className={styles.editModalItem}>
                    <div className="d-flex">
                      <Archivedit />
                    </div>
                  </Dropdown.Item>
                )}
                {popupData?.assessmentStatus !== 10 && (
                  <Dropdown.Item eventKey="Published" className={styles.editModalItem}>
                    <Publishedit />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={styles.positionLine}>
            <div className={`${styles.headerRow} ${styles.hoverHeight}`}>
              <span className={styles.name}>Position Name</span>
              <div className={styles.iconWrapper}>
                <EditIcon
                  style={{ marginRight: '18px' }}
                  className={styles.editIcon1}
                  onClick={() => {
                    setModalBlur(true);
                    setNameShow(true);
                  }}
                />
              </div>
            </div>
            <ShortText
              id="positionName"
              staticLimit={40}
              name={popupData?.name}
              textClassName={`${styles.headerRow} ${styles.text} ${styles.borderedRow}`}
            />
          </div>
          <div className={styles.authorizedLine}>
            <div className={`${styles.headerRow}  ${styles.hoverHeight}`}>
              <div className={styles.name}>Authorized User</div>
              <div className={styles.iconWrapper}>
                <EditIcon
                  className={styles.editIcon2}
                  style={{ marginRight: '18px' }}
                  onClick={() => {
                    setModalBlur(true);
                    setAuthorizeUserShow(true);
                  }}
                />
              </div>
            </div>
            {popupData?.assessmentUsers?.slice(0, 4).map((user, index) => (
              <UserIcon
                nested={index > 0}
                name={index !== 3 ? user.name : '+'}
                surname={index !== 3 ? user.surname : popupData?.assessmentUsers?.length - 3}
              />
            ))}
          </div>

          <div className={`${styles.name} ${styles.headerRow}`}>Connection</div>
          <div
            className={
              !popupData?.isAtsEnabled &&
              popupData?.connectionList?.length === 0 &&
              styles.addConnection
            }
          >
            <div
              className={
                !popupData?.isAtsEnabled &&
                popupData?.connectionList?.length === 0 &&
                styles.addConnectionText
              }
              onClick={() => {
                if (!popupData?.isAtsEnabled && popupData?.connectionList?.length === 0) {
                  setModalBlur(true);
                  setAddConnectionShow(true);
                }
              }}
            >
              {!popupData?.isAtsEnabled && popupData?.connectionList?.length === 0 ? (
                '+ Add connection'
              ) : popupData?.isAtsEnabled ? (
                <ConnectionRow
                  connection={atsList?.filter(ats => ats.id === popupData?.atsType)[0]?.code}
                  assessmentID={assessmentId}
                  video={atsList?.filter(ats => ats.id === popupData?.atsType)[0]?.link}
                />
              ) : (
                popupData?.connectionList?.map((connection, i) => (
                  <ConnectionRow
                    connection={connection}
                    assessmentID={assessmentId}
                    video={connectionList?.filter(c => c.code === connection)[0]?.link}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );

  return <div>{windowW > 576 ? <>{ModalNormal}</> : <>{Mobile}</>}</div>;
}
