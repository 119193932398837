import React from 'react';
import CloseButton from '../../../../Common/Components/CloseButton';
import MobileModal from '../../../../Common/Components/MobileModal/MobileModal';
import Modal from '../../../../Common/CustomModal/Modal';
import { useWindowSize } from '../../../../utils/helpers';

import success from './rejectSuccess.svg';

const RejectSuccessModal = ({ show, onClose, isMailSent, selectedCandCount }) => {
  const [screenW, screenH] = useWindowSize();

  const WebSuccessModal = (
    <Modal
      contentStyle={{
        border: '1px solid rgba(88, 88, 88, 0.2)',
        borderRadius: '24px',
        padding: '4.6rem 8rem'
      }}
      //   size={{ width: '57.2rem', height: '21.8rem' }}
      centerHorizontally
      centerVertically
      onClose={onClose}
      show={show}
      outsideClickClose
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Jost-300',
          fontSize: '1.8rem',
          color: '#585858',
          textAlign: 'center'
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: '2rem',
            top: '1.7rem',
            width: '3.4rem',
            height: '3.4rem'
          }}
        >
          <CloseButton onClick={onClose} />
        </div>
        <img
          src={success}
          alt="successIcon"
          style={{ width: '4.2rem', height: '4.2rem', marginBottom: '3.2rem' }}
        />
        {isMailSent ? ( // Remove if check when new candidate detail releases
        <text>
          Rejection mail have been sent to&nbsp;
          <text style={{ fontFamily: 'Jost-400' }}>
            {selectedCandCount} candidate
            {selectedCandCount > 1 ? 's' : ''}
          </text>
          &nbsp;you selected.
          <br />
          The candidate{selectedCandCount > 1 ? 's are' : ' is'} in the ‘Rejected’ stage
        </text>
        ) : (     // Remove if check when new candidate detail releases
          <text>
            Selected candidate{selectedCandCount > 1 ? 's have' : ' has'} been moved to&nbsp;
            <text style={{ fontFamily: 'Jost-400' }}>‘Rejected’</text>
            &nbsp;stage from now.
          </text>
        )}
      </div>
    </Modal>
  );

  const MobileSuccessModal = (
    <MobileModal
      backdropStyle={{ overflow: 'hidden' }}
      contentStyle={{
        padding: '20px 20px 24px'
      }}
      onClose={onClose}
      show={show}
      outsideClickClose
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Jost-300',
          fontSize: '16px',
          color: '#585858',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontFamily: 'Jost-400',
            fontSize: '16px',
            lineHeight: '23px',
            color: '#585858',
            textAlign: 'center',
            marginBottom: '24px' 
          }}
        >
          <img
            src={success}
            alt="successIcon"
            style={{ width: '25px', height: '25px', marginRight: '12px'}}
          />
          <text>
            Mail Successfully Sent
          </text>
          <div
            style={{
              width: '24px',
              height: '24px',
              marginLeft: 'auto'
            }}
          >
            <CloseButton onClick={onClose} />
          </div>
          
        </div>

        <text>
          Rejection mail have been sent to&nbsp;
          <text style={{ fontFamily: 'Jost-400' }}>
            {selectedCandCount} candidate
            {selectedCandCount > 1 ? 's' : ''}
          </text>
          &nbsp;you selected.
          <br />
          The candidate{selectedCandCount > 1 ? 's are' : ' is'} in the&nbsp;<text style={{ fontFamily: 'Jost-400' }}>‘Rejected’</text>&nbsp;stage
        </text>
      </div>
    </MobileModal>
  );

  return screenW > 576 ? WebSuccessModal : MobileSuccessModal;
};

export default RejectSuccessModal;
