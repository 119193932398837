import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  getAssessmentDetailAction,
  getCandidateFunnelInfoActionVol2,
  getAssessmentInfoAction,
} from './actions';

const assessmentsSlice = createSlice({
  name: 'assessmentDetail',
  initialState,
  reducers: {
    setDisableButton: (state, action) => {
      state.disableButton = action.payload;
    },
    setInviteCandidatesModalShow: (state, action) => {
      state.inviteCandidatesModalShow = action.payload;
    },
    setDetailOldApi: (state, action) => {
      state.detail = action.payload;
    },
    setCopiedState: (state, action) => {
      state.copiedState = action.payload;
    },
    setSettingsSuccess: (state, action) => {
      state.settingsSuccess = action.payload;
    },
    setSettingsError: (state, action) => {
      state.settingsError = action.payload;
    },
    setSettingsBannerMessage: (state, action) => {
      state.settingsBannerMessage = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getAssessmentDetailAction.pending, state => {
      state.detail = {};
      state.loading = true;
    });
    builder.addCase(getAssessmentDetailAction.fulfilled, (state, action) => {
      state.detail = action?.payload;
      state.loading = false;
    });
    builder.addCase(getAssessmentDetailAction.rejected, (state, action) => {
      state.detail = {};
      state.reason = action.payload;
      state.loading = false;
    });

    builder.addCase(getCandidateFunnelInfoActionVol2.fulfilled, (state, action) => {
      state.candidateFunnelInfo = action.payload.data;
    });
    builder.addCase(getAssessmentInfoAction.fulfilled, (state, action) => {
      state.assessmentSettingInfo = action.payload.data;
    });
  }
});

export const {
  setDisableButton,
  setInviteCandidatesModalShow,
  setDetailOldApi,
  setSettingsSuccess,
  setSettingsError,
  setSettingsBannerMessage,
  setCopiedState
} = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
