import React, { useLayoutEffect, useState } from 'react';
import styles from './UserInfoComponent.module.css';
import UserInfoQuestionComponent from './UserInfoQuestionComponent';
import { ReactComponent as CoensioLogo } from '../../../images/coensio_l.svg';

const UserInfoComponent = ({
  userId,
  questionNumber,
  isFirstQuestion = false,
  isLastQuestion = false,
  question,
  answers
}) => {
  const MOBILE_THRESHOLD = 576;

  function useWindowSize() {
    // Use this hook to update the bounding box in the parent!
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  const NavigationBar = () => {
    return (
      <>
        <div className={styles.navigationBar}>
          <CoensioLogo className={styles.logo} />
        </div>
      </>
    );
  };

  const Circles = () => {
    return (
      <div className={styles.circleWrapper}>
        <div className={questionNumber === 1 ? styles.currentCircle : styles.filledCircle} />
        <div
          className={
            questionNumber === 2
              ? styles.currentCircle
              : questionNumber < 2
              ? styles.emptyCircle
              : styles.filledCircle
          }
        />
        <div
          className={
            questionNumber === 3
              ? styles.currentCircle
              : questionNumber < 3
              ? styles.emptyCircle
              : styles.filledCircle
          }
        />
        <div className={questionNumber === 4 ? styles.currentCircle : styles.emptyCircle} />
      </div>
    );
  };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.OuterCard}>
          {width <= MOBILE_THRESHOLD && <NavigationBar />}
          <div className={styles.Card}>
            <Circles />
            <UserInfoQuestionComponent
              userId={userId}
              isFirstQuestion={isFirstQuestion}
              isLastQuestion={isLastQuestion}
              question={question}
              answers={answers}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoComponent;
