import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './NewOpComponent.module.css';
import NewOpCompanyItem from './NewOpCompanyItem';
import CustomButton from '../../../../Common/Components/CustomButton';
import Checkbox from '../../../../Common/Components/Checkbox';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  rootAssessmentNameRedux
} from '../../../../redux/AssessmentSession/selectors';
import { applyWithNewOpsAction } from '../../../../redux/AssessmentSession/actions';
import { ReactComponent as Saved } from '../../../../images/savedCV.svg';
import mixpanel from '../../../../utils/mixpanel';
import { isJsonString } from '../../../../utils/helpers';

const NewOpComponent = ({
  id,
  newOpCompanyList,
  collapseNewOp = () => {},
  application,
  setParentSelectedList = () => {},

  // Mixpanel Variable
  path = null,
  scoredFlagForUnpublishedAssessmentCandidate = true
}) => {
  const dispatch = useDispatch();
  const email = useSelector(CandidateEmail);
  const [newOpCardState, setNewOpCardState] = useState(true);

  const [selectedNewOpCompanyList, setSelectedNewOpCompanyList] = useState([]);
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);

  /* Mixpanel Variables */
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const companyName = useSelector(CompanyName);
  const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);

  const selectAllBehavior = () => {
    const temp = [];
    if (selectedNewOpCompanyList?.length !== newOpCompanyList?.length) {
      newOpCompanyList?.forEach(e => {
        temp.push(e.companyName);
      });
      setIsSelectAllClicked(true);
    } else {
      setIsSelectAllClicked(false);
    }
    setSelectedNewOpCompanyList(temp);
  };

  useEffect(() => {
    if (selectedNewOpCompanyList?.length === newOpCompanyList?.length) {
      setIsSelectAllSelected(true);
    } else {
      setIsSelectAllSelected(false);
    }

    setParentSelectedList(selectedNewOpCompanyList);
  }, [selectedNewOpCompanyList]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const shareScore = async () => {
    setButtonLoading(true);

    mixpanel.track('New Op Apply Button Click', {
      'Application Flag': !!application,
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': email,
      'Already Scored Flag':
        isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
        JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
      'CV Flag': !!path,
      'NewOpp Flag': true,
      'Number of NewOpp': newOpCompanyList?.length,
      'Number of Selected NewOpp': selectedNewOpCompanyList?.length,
      'Select All Flag': isSelectAllClicked
    });

    const pairs = [];
    selectedNewOpCompanyList?.forEach(e => {
      newOpCompanyList?.forEach(innerE => {
        if (e === innerE.companyName) {
          pairs.push({
            newOpType: innerE.newOpType,
            companyAssessmentIds: innerE.companyAssessmentIds
          });
        }
      });
    });

    const data = {
      candidateEmail: email,
      companyAssessmentKey: id,
      companyAssessments: pairs
    };
    const resp = await dispatch(applyWithNewOpsAction(data));
    setButtonLoading(false);
    if (resp.meta.requestStatus === 'fulfilled') {
      collapseNewOp(true);
      setNewOpCardState(false);

      mixpanel.track('New Op Apply Button Success', {
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag':
          isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
          JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
        'CV Flag': !!path,
        'NewOpp Flag': true,
        'Number of NewOpp': newOpCompanyList?.length,
        'Number of Selected NewOpp': selectedNewOpCompanyList?.length,
        'Select All Flag': isSelectAllClicked
      });
    } else {
      // TODO: fail check?
      mixpanel.track('New Op Apply Button Fail', {
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag':
          isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
          JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
        'CV Flag': !!path,
        'NewOpp Flag': true,
        'Number of NewOpp': newOpCompanyList?.length,
        'Number of Selected NewOpp': selectedNewOpCompanyList?.length,
        'Select All Flag': isSelectAllClicked,
        'Error message': resp?.payload.message
      });
    }
  };

  return (
    <>
      <div className={styles.ExtendableCard}>
        <div className={styles.NewOpHeader}>
          <div
            style={{
              visibility: newOpCardState ? 'hidden' : 'visible',
              opacity: newOpCardState ? '0' : '1',
              transition: 'all 0.5s linear'
            }}
          >
            <div style={{ display: 'flex' }}>
              <Saved style={{ marginRight: '1.6rem' }} />
              <span className={styles.ClosedHeaderText}>
                {newOpCardState
                  ? ''
                  : 'Your score has also been shared with the selected companies!'}
              </span>
            </div>
          </div>
        </div>

        <div
          className={`${styles.baseInputBody} ${
            newOpCardState ? styles.openInputBody : styles.closedInputBody
          } `}
        >
          <>
            <div className={styles.BodyHead}>
              <Checkbox
                myOnClick={selectAllBehavior}
                myChecked={isSelectAllSelected}
                borderColor="#7458D3"
              />
              <div className={styles.SelectAll} onClick={selectAllBehavior}>
                Select All
              </div>
            </div>
            <div className={styles.CompanyListWrapper}>
              {newOpCompanyList?.map(e => (
                <NewOpCompanyItem
                  item={e}
                  selectedList={selectedNewOpCompanyList}
                  setSelectedList={setSelectedNewOpCompanyList}
                />
              ))}
            </div>
            <div className={styles.ButtonWrapper}>
              <CustomButton
                buttonOnClick={() => {
                  shareScore();
                }}
                size="small"
                textField={
                  application
                    ? 'Apply'
                    : !scoredFlagForUnpublishedAssessmentCandidate
                    ? 'Apply'
                    : 'Share my score'
                }
                type={1}
                isDisabled={selectedNewOpCompanyList?.length === 0}
                loading={buttonLoading}
              />
            </div>
          </>
        </div>
      </div>
    </>
  );
};
export default NewOpComponent;
