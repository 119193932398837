import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './QuickStart.module.css';
import outSource from './quick-start-icon.svg';
import up from './up-icon.svg';
import down from './down-icon.svg';
import close from './close.svg';
import tick from './tick.svg';
import createAssessment from './create-assessment.svg';
import inviteTeammates from './inviteTeammates.svg';
import companyLogo from './companyLogo.svg';
import customAssessment from './customAssessment.svg';
import Banner from '../../../Common/Components/CustomBanner/Banner';
import { getOnboardingStepsAction } from '../../../redux/plan/actions';
import {
  completedOnboardingNoRedux,
  onboardingStepRedux,
  onboardingStepsRedux,
  quickStartOpen
} from '../../../redux/plan/selectors';
import {
  setAddLogoBoxHighlight,
  setAddNewUserHighlight,
  setOnboardingStep,
  setQuickStartOpen,
  setTeamHighlight
} from '../../../redux/plan/slice';
import { useWindowSize } from '../../../utils/helpers';
import purple from './purpleIcon.svg';

export default function QuickStart({
  parentRef,
  show,
  open,
  setOpen,
  quickStartId,
  relativeItemId,
  innerBannerStyle = {}
}) {
  const onboardingSteps = useSelector(onboardingStepsRedux);
  const completedNo = useSelector(completedOnboardingNoRedux);
  const step = useSelector(onboardingStepRedux);
  const [activeNo, setActiveNo] = useState(step);
  const dispatch = useDispatch();
  const [relativeElement, setRelativeElement] = useState(document?.getElementById(relativeItemId));
  const [bottom, setBottom] = useState();

  const quickStartOpenRedux = useSelector(quickStartOpen);

  useEffect(() => {
    if (show && open && step === 0) {
      dispatch(getOnboardingStepsAction());
    }
  }, [show, open, step]);

  useEffect(() => {
    setActiveNo(step);
  }, [step]);

  useEffect(() => {
    if (!open) {
      dispatch(setTeamHighlight(false));
      dispatch(setAddLogoBoxHighlight(false));
      dispatch(setAddNewUserHighlight(false));
    }
  }, [open]);

  useEffect(() => {
    if (!quickStartOpenRedux) {
      setOpen(false);
      dispatch(setQuickStartOpen(true));
    }
  }, [quickStartOpenRedux]);

  const [Yposition, setYposition] = useState('15rem');
  const [width, height] = useWindowSize();

  function handlePosition() {
    const temp = relativeElement?.getBoundingClientRect()?.bottom;
    setBottom(temp);
    setYposition(`${temp + 16}px`);
  }

  useEffect(() => {
    if (show) {
      handlePosition();
    }
  }, [width, relativeElement]);

  /* eslint-disable */
  useEffect(() => {
    if (show) {
      window.addEventListener('scroll', () => {
        handlePosition();
      });

      return () => {
        window.removeEventListener('scroll', () => {
          handlePosition();
        });
      };
    }
  }, [show, relativeElement]);

  useEffect(() => {
    setRelativeElement(document?.getElementById(relativeItemId));
  }, [relativeItemId]);

  const OnboardingItem = ({ no, title, img, itemOnClick, done }) => {
    function getDescription() {
      switch (no) {
        case 1:
          return (
            <div className={styles.descWrapper}>
              <span className={styles.boldDesc}>Choose one of the assessments&nbsp;</span>
              <span className={styles.desc}>from library to create a job posting.</span>
            </div>
          );
        case 2:
          return (
            <div className={styles.descWrapper}>
              <span className={styles.desc}>Collaborate with your teammates. You can&nbsp;</span>
              <span className={styles.boldDesc}>manage your team&nbsp;</span>
              <span className={styles.desc}>with&nbsp;</span>
              <span className={styles.boldDesc}>company settings.</span>
            </div>
          );
        case 3:
          return (
            <div className={styles.descWrapper}>
              <span className={styles.desc}>Highlight your company for candidates. Open&nbsp;</span>
              <span className={styles.boldDesc}>company settings&nbsp;</span>
              <span className={styles.desc}>and add logo from&nbsp;</span>
              <span className={styles.boldDesc}>general section.</span>
            </div>
          );
        case 4:
          return (
            <div className={styles.descWrapper}>
              <span className={styles.boldDesc}>Customize assessments&nbsp;</span>
              <span className={styles.desc}>
                according to your needs and pre-assess candidates your own way.
              </span>
            </div>
          );
        default:
          return 0;
      }
    }
    return (
      <div className={`${styles.itemWrapper} `}>
        <div
          className={styles.headerWrapper}
          onClick={() => {
            itemOnClick();
          }}
        >
          <div className={styles.headerContainer}>
            <div className={done ? styles.doneBox : no !== activeNo && styles.grayBox}>
              {done ? (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <img alt="" src={tick} />
                </span>
              ) : (
                no === activeNo && (
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '8px'
                    }}
                  >
                    <img
                      style={{
                        height: '2.4rem',
                        width: '2.4rem'
                      }}
                      alt=""
                      src={purple}
                    />
                  </span>
                )
              )}
            </div>
            <div
              className={styles.purpleText}
              style={{ color: done ? 'rgba(116, 88, 211, 0.3)' : no !== activeNo && '#C1C1C1' }}
            >
              {title}
            </div>
          </div>

          <div>
            <img alt="" src={no === activeNo ? up : down} />
          </div>
        </div>

        {no === activeNo && (
          <div className={` ${styles.container} ${no === activeNo && styles.opened}`}>
            <div className={styles.imgWrapper}>
              <img alt="" src={img} />
            </div>
            {getDescription()}
          </div>
        )}
      </div>
    );
  };

  const openStyle = {
    background: '#FFFF',
    outline: '1.5px solid #7458D3',
    boxShadow: '0px 2px 12px rgba(138, 138, 138, 0.4)',
    borderRadius: '16px 0px 0px 16px',
    userSelect: 'none',
    transition: 'transform 0.3s linear',
    top: 0
  };
  const closedStyle = {
    background: '#FFFF',
    border: '1.5px solid #7458D3',
    boxShadow: '8px 2px 12px rgba(138, 138, 138, 0.4)',
    borderRadius: '16px 0px 0px 16px',
    padding: '8px 12px 8px 16px',
    display: 'flex',
    userSelect: 'none',
    justifyContent: 'space-between',
    transition: 'transform 0.2s ease-out',
    top: 0,
    cursor: 'pointer'
  };

  const [profBannerExistStyle, setProfBannerExistStyle] = useState({});

  const detectProfileBanner = () => {
    const profBanner = document.getElementById('profile_Banner');
    const underQSelement = document.getElementById('underQS');

    if (profBanner || underQSelement) {
      setProfBannerExistStyle({ zIndex: '10' });
    } else {
      setProfBannerExistStyle({});
    }
  };

  useEffect(() => {
    document.addEventListener('click', detectProfileBanner);

    return () => {
      document.removeEventListener('click', detectProfileBanner);
    };
  }, []);

  return (
    <>
      {open ? (
        <Banner
          bannerId={quickStartId}
          position={{ x: 'calc(100vw - 370px)', y: Yposition || '15rem' }}
          initPosition={{ x: '150vw', y: '10rem' }}
          size={{ width: '332px' }}
          parentRef={parentRef}
          contentStyle={{ ...openStyle, ...profBannerExistStyle }}
          show={show}
          scrollInside
        >
          <div className={`${styles.longHeaderWrapper}`} id="QSid">
            <div className={styles.textWrapper}>
              <div className={styles.text}>Quick Start</div>
              <div className={styles.barWrapper}>
                <div
                  className={styles.bar}
                  style={{
                    width: completedNo === 0 ? '6px' : `calc(${(completedNo / 4) * 100}% + 1px)`
                  }}
                />
              </div>
            </div>
            <span
              className={styles.actionImg}
              onClick={() => {
                setOpen(false);
                // dispatch(setOnboardingStep(0));
              }}
            >
              <img alt="" src={close} />
            </span>
          </div>
          <div className={styles.onboardingItemWrapper}>
            <OnboardingItem
              title="Create Assessment"
              img={createAssessment}
              no={1}
              itemOnClick={() => dispatch(setOnboardingStep(1))}
              done={onboardingSteps?.createAssessment}
            />
            <OnboardingItem
              title="Invite Teammates"
              img={inviteTeammates}
              no={2}
              itemOnClick={() => dispatch(setOnboardingStep(2))}
              done={onboardingSteps?.inviteTeammates}
            />
            <OnboardingItem
              title="Add Company Logo"
              img={companyLogo}
              no={3}
              itemOnClick={() => dispatch(setOnboardingStep(3))}
              done={onboardingSteps?.addLogo}
            />
            <OnboardingItem
              title="Try Custom Assessment"
              img={customAssessment}
              no={4}
              itemOnClick={() => dispatch(setOnboardingStep(4))}
              done={onboardingSteps?.customAssessment}
            />
          </div>
        </Banner>
      ) : (
        <Banner
          bannerId={quickStartId}
          position={{ x: 'calc(100vw - 215px)', y: Yposition || '15rem' }}
          initPosition={{ x: '200vw', y: '10rem' }}
          size={{ width: '180px' }}
          parentRef={parentRef}
          contentStyle={{ ...closedStyle, ...innerBannerStyle, ...profBannerExistStyle }}
          show={show}
          bannerOnClick={() => {
            setOpen(true);
          }}
        >
          <div className={styles.textWrapper}>
            <div className={styles.text}>Quick Start</div>
            <div className={styles.barWrapper}>
              <div
                className={styles.bar}
                style={{
                  width: completedNo === 0 ? '6px' : `calc(${(completedNo / 4) * 100}% + 1px)`
                }}
              />
            </div>
          </div>
          <span className={styles.actionImg}>
            <img alt="" src={outSource} />
          </span>
        </Banner>
      )}
    </>
  );
}
