import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setScreeningNationalityIds } from '../../../../redux/AssessmentSession/slice';
import styles from './ScreeningCardComponent.module.css';

// Input Type: 50  -- multi select
const MultiSelectDropdown = ({ nationalityList, selectedList, setSelectedList }) => {
  const [innerSelectedList, setInnerSelectedList] = useState();
  useEffect(() => {
    const temp = [];
    selectedList.forEach(e => {
      nationalityList.forEach(innerE => {
        if (e === innerE.id) {
          temp.push(innerE);
        }
      });
    });
    setInnerSelectedList(temp);
  }, [selectedList]);

  const onSelect = (selectedListParam, selectedItemParam) => {
    const temp = [...selectedList];
    temp.push(selectedItemParam.id);
    setSelectedList(temp);
  };

  const onRemove = (selectedListParam, selectedItemParam) => {
    const temp = [...selectedList];
    const ind = selectedList.indexOf(selectedItemParam.id);
    if (ind > -1) {
      const arr = temp.splice(ind, 1);
      setSelectedList(temp);
    }
  };

  return (
    <Multiselect
      className={styles.MultiSelectDropdown}
      options={nationalityList}
      displayValue="name"
      placeholder="Select Country"
      selectedValues={innerSelectedList}
      showCheckbox
      onSelect={onSelect}
      onRemove={onRemove}
      avoidHighlightFirstOption
    //   closeIcon='cancel'
    />
  );
};

const WorkPermitQuestion = ({ content, index }) => {
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState(content?.currentNationality || []);

  useEffect(() => {
    dispatch(setScreeningNationalityIds(selectedList));
  }, [selectedList]);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>{content?.nationalityQuestion}</div>
      <div className={styles.answer}>
        <MultiSelectDropdown
          nationalityList={content?.nationalities}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
        />
      </div>
    </div>
  );
};

export default WorkPermitQuestion;
