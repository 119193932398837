import { createSlice } from '@reduxjs/toolkit';
import {
//   createCodingQuestion,
  createCodingQuestionAction2,
//   createFreetextQuestion,
  createFreeTextQuestionAction2,
//   createMultipleChoiceQuestion,
  createMultipleChoiceQuestionAction2,
  createSqlQuestionAction,
  postRunCodeAction,
  postSqlRunCodeAction
} from './actions';
import initialState from './initialState';

const AddQuestionSlice = createSlice({
  name: 'addQuestion',
  initialState,
  reducers: {
    setInitial: () => initialState,

    setCategoryRedux: (state, action) => {
      state.category = action.payload;
    },
    setAssessmentLabelRedux: (state, action) => {
      state.assessmentLabel = action.payload;
    },
    setSubcategoryRedux: (state, action) => {
      state.subcategory = action.payload;
    },
    setTitleRedux: (state, action) => {
      state.title = action.payload;
    },
    setDifficultyRedux: (state, action) => {
      state.difficulty = action.payload;
    },
    setLanguageRedux: (state, action) => {
      state.language = action.payload;
    },
    setLimitRedux: (state, action) => {
      state.limit = action.payload;
    },
    setStatusRedux: (state, action) => {
      state.status = action.payload;
    },
    setQuestionDescriptionRedux: (state, action) => {
      state.questionDescription = action.payload;
    },

    setMultiSelectAnswersRedux: (state, action) => {
      state.multiSelectAnswers = action.payload;
    },
    setSelectedOptionIndexRedux: (state, action) => {
      state.selectedOptionIndex = action.payload;
    },

    setFreeTextAnswersRedux: (state, action) => {
      state.freeTextAnswers = action.payload;
    },

    setTestCasesRedux: (state, action) => {
      state.testCases = action.payload;
    },
    setValidCasesRedux: (state, action) => {
      state.validCases = action.payload;
    },
    setOutputVariableRedux: (state, action) => {
      state.outputVariable = action.payload;
    },
    setParametersRedux: (state, action) => {
      state.parameters = action.payload;
    },
    setInitialSelectedLangRedux: (state, action) => {
      state.initialSelectedLang = action.payload;
    },
    setInitialCodeDictionaryRedux: (state, action) => {
      state.initialCodeDictionary = action.payload;
    },
    setIsPassedVerificationRedux: (state, action) => {
      state.isPassedVerification = action.payload;
    },
    setVerificationCodeRedux: (state, action) => {
      state.verificationCode = action.payload;
    },
    setVerificationSelectedLangRedux: (state, action) => {
      state.verificationSelectedLang = action.payload;
    },

    setSqlQueryTypeRedux: (state, action) => {
      state.sqlQueryType = action.payload;
    },
    setSqlCreateTableCode: (state, action) => {
      state.sqlCreateTableCode = action.payload;
    },
    setSqlTestCases: (state, action) => {
      state.sqlTestCases = action.payload;
    },
    setSqlValidCases: (state, action) => {
      state.sqlValidCases = action.payload;
    },
    setSqlVerificationCode: (state, action) => {
      state.sqlVerificationCode = action.payload;
    },

    setIsSuccessRedux: (state, action) => {
      state.isSuccess = action.payload;
    },
    setEncryptedQuestionIdRedux: (state, action) => {
      state.encryptedQuestionId = action.payload;
    },

    setVerificationCodeResponseRedux: (state, action) => {
      state.verificationCodeResponse = action.payload;
    }
  },
  extraReducers: builder => {
    // builder.addCase(createMultipleChoiceQuestion.fulfilled, (state, action) => {
    //     state.isSuccess = true;
    // });
    // builder.addCase(createCodingQuestion.fulfilled, (state, action) => {
    //     state.isSuccess = true;
    // });
    // builder.addCase(createFreetextQuestion.fulfilled, (state, action) => {
    //     state.isSuccess = true;
    // });

    builder.addCase(postRunCodeAction.pending, (state, action) => {
      state.verificationCodeResponse = 'Running...';
      state.isPassedVerification = false;
    });
    builder.addCase(postRunCodeAction.fulfilled, (state, action) => {
      state.verificationCodeResponse = action.payload;
      if (action.payload.point === action.payload.weight.toString()) {
        state.isPassedVerification = true;
      }
    });
    builder.addCase(postRunCodeAction.rejected, (state, action) => {
      state.verificationCodeResponse = '';
      state.isPassedVerification = false;
    });

    builder.addCase(postSqlRunCodeAction.pending, (state, action) => {
      state.verificationCodeResponse = 'Running...';
      state.isPassedVerification = false;
    });
    builder.addCase(postSqlRunCodeAction.fulfilled, (state, action) => {
      state.verificationCodeResponse = action.payload;
      if (action.payload.point === action.payload.weight.toString()) {
        state.isPassedVerification = true;
      }
    });
    builder.addCase(postSqlRunCodeAction.rejected, (state, action) => {
      state.verificationCodeResponse = '';
      state.isPassedVerification = false;
    });

    builder.addCase(createMultipleChoiceQuestionAction2.pending, (state, action) => {
      state.isSuccess = 'loading';
      state.encryptedQuestionId = '';
    });
    builder.addCase(createMultipleChoiceQuestionAction2.rejected, (state, action) => {
      state.isSuccess = false;
    });
    builder.addCase(createMultipleChoiceQuestionAction2.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.isSuccess = true;
      } else {
        state.isSuccess = false;
      }
      state.encryptedQuestionId = action.payload.questionIdEncrypted;
    });
    builder.addCase(createFreeTextQuestionAction2.pending, (state, action) => {
      state.isSuccess = 'loading';
      state.encryptedQuestionId = '';
    });
    builder.addCase(createFreeTextQuestionAction2.rejected, (state, action) => {
      state.isSuccess = false;
    });
    builder.addCase(createFreeTextQuestionAction2.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.isSuccess = true;
      } else {
        state.isSuccess = false;
      }
      state.encryptedQuestionId = action.payload.questionIdEncrypted;
    });
    builder.addCase(createCodingQuestionAction2.pending, (state, action) => {
      state.isSuccess = 'loading';
      state.encryptedQuestionId = '';
    });
    builder.addCase(createCodingQuestionAction2.rejected, (state, action) => {
      state.isSuccess = false;
    });
    builder.addCase(createCodingQuestionAction2.fulfilled, (state, action) => {
      if (action.payload.isSuccess) {
        state.isSuccess = true;
      } else {
        state.isSuccess = false;
      }
      state.encryptedQuestionId = action.payload.questionIdEncrypted;
    });

    builder.addCase(createSqlQuestionAction.pending, (state, action) => {
      state.isSuccess = 'loading';
      state.encryptedQuestionId = '';
    });
    builder.addCase(createSqlQuestionAction.rejected, (state, action) => {
      state.isSuccess = false;
    });
    builder.addCase(createSqlQuestionAction.fulfilled, (state, action) => {
      state.isSuccess = true;
      state.encryptedQuestionId = action.payload.questionIdEncrypted;
    });
  }
});

export const {
  setInitial,
  setCategoryRedux,
  setAssessmentLabelRedux,
  setSubcategoryRedux,
  setTitleRedux,
  setDifficultyRedux,
  setLanguageRedux,
  setLimitRedux,
  setStatusRedux,
  setQuestionDescriptionRedux,
  setIsSuccessRedux,
  setMultiSelectAnswersRedux,
  setSelectedOptionIndexRedux,
  setFreeTextAnswersRedux,
  setTestCasesRedux,
  setValidCasesRedux,
  setOutputVariableRedux,
  setParametersRedux,
  setInitialCodeDictionaryRedux,
  setInitialSelectedLangRedux,
  setIsPassedVerificationRedux,
  setVerificationCodeRedux,
  setVerificationSelectedLangRedux,
  setEncryptedQuestionIdRedux,
  setVerificationCodeResponseRedux,
  setSqlQueryTypeRedux,
  setSqlCreateTableCode,
  setSqlTestCases,
  setSqlValidCases,
  setSqlVerificationCode
} = AddQuestionSlice.actions;

export default AddQuestionSlice.reducer;
