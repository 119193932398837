import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  getCompanyAssessmentListAction,
  getCompanyAssessmentFilterCountAction
} from './actions';

const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  activeFilter: 'active',
  reducers: {
    addAssessment: {
      reducer: (state, action) => {
        state.items.push(action.payload);
      }
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    selectAssessment: {
      reducer: (state, action) => {
        state.selectedAssess = state.items.filter(item => item.id === action.payload);
      }
    },
    addToHiringFunnel: {
      reducer: (state, action) => {
        state.selectedHiringFunnel = [...state.selectedHiringFunnel, action.payload];
      }
    },
    filterAssesmentList: {
      reducer: (state, action) => {
        let filterData = 'Active';
        switch (action.payload) {
          case 'success':
            filterData = 'Time To Hire';
            break;
          case 'warning':
            filterData = 'Average Rating';
            break;
          case 'danger':
            filterData = 'Wait For More';
            break;

          default:
            break;
        }

        if (state.filterData !== filterData) {
          state.filterData = filterData;
          state.filteredItems = state.items.filter(
            item => item.companyAssessmentType === filterData
          );
        } else {
          state.filterData = null;
          state.filteredItems = state.items;
        }
      }
    },
    filterStatusAssessment: {
      reducer: (state, action) => {
        let fStatus = 'Active';
        switch (action.payload) {
          case 'primary':
            fStatus = 'Active';
            break;
          case 'secondary':
            fStatus = 'Archived';
            break;
          default:
            break;
        }

        state.filterDataStatus = fStatus;
      }
    },
    resetAssessmentList: (state, action) => {
      state.items = [];
    },

    setCompanyAssessmentList: (state, action) => {
      state.assessmentList2 = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCompanyAssessmentListAction.pending, (state, action) => {
      state.assessmentList2 = [];
    });

    builder.addCase(getCompanyAssessmentFilterCountAction.fulfilled, (state, action) => {
      const temp = action.payload.data;
      state.getWidth = true;
      state.publishedCount = temp.filter(item => item.name === 'Active')[0].assessmentCount;
      state.archivedCount = temp.filter(item => item.name === 'Archived')[0].assessmentCount;
    });
  }
});

export const {
  addAssessment,
  filterAssesmentList,
  filterStatusAssessment,
  selectAssessment,
  setActiveTab,
  addToHiringFunnel,
  resetAssessmentList,
  setCompanyAssessmentList
} = assessmentsSlice.actions;
export default assessmentsSlice.reducer;
