import { React, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton from '../../../Common/Components/CloseButton';
import CustomButton from '../../../Common/Components/CustomButton';
import SearchBar from '../../../Common/Components/SearchBar';
import Checkbox from '../../../Common/Components/Checkbox';
import copyLink from '../../../images/copyLink.svg';
import linkCopiedImg from '../../../images/linkCopied.svg';
import invited from '../../../images/invited.svg';
import styles from '../Styles/SendForReview.module.css';
import {
  userList,
  customAssessmentId,
  HMUserList,
  invitedHMList,
  inviteUserShow,
  newlyInvitedID,
  newlyInvitedIDList
} from '../../../redux/assessmentsVol2/selectors';
import { setInviteUserShow } from '../../../redux/assessmentsVol2/slice';
import {
  getUserListByCompanyAction,
  sendEmailForReviewAction
} from '../../../redux/assessmentsVol2/actions';
import { userSelector } from '../../../redux/auth/selectors';
import { ReactComponent as Success } from '../../../images/SFR_success.svg';

export default function SendForReviewModal({ show, onHide, shareProfile = false, ...props }) {
  const [selectedHRlist, setSelectedHRlist] = useState([]);
  const invitedHRlist = useSelector(invitedHMList);

  const newlyInvited = useSelector(newlyInvitedID);
  const newlyInvitedList = useSelector(newlyInvitedIDList);

  const [searchParam, setSearchParam] = useState('');
  // const [hrList, setHrlist] = useState();
  const [copySuccess, setCopySuccess] = useState(false);
  const inviteShow = useSelector(inviteUserShow);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const id = useSelector(customAssessmentId);
  const hrList = useSelector(HMUserList);
  const newUserList = useSelector(userList);
  const [success, setSuccess] = useState(false);
  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  useEffect(() => {
    if (copySuccess) {
      const a = navigator.clipboard.read();
    }
  }, [copySuccess]);

  useEffect(() => {
    if (user && show) {
      dispatch(getUserListByCompanyAction({ CompanyId: user.companyId }));
    }
    return () => {
      setSelectedHRlist([]);
      setSuccess(false);
    };
  }, [user, show]);

  function handleCheckbox(hr) {
    let temp = [...selectedHRlist];
    if (selectedHRlist.includes(hr)) {
      temp = temp.filter(e => e !== hr);
      setSelectedHRlist(temp);
    } else {
      setSelectedHRlist(prev => [...prev, hr]);
    }
  }

  const [emailsReady, setEmailsReady] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  async function sendMails() {
    const link = window.location.href;
    const response = await dispatch(
      sendEmailForReviewAction({
        emails: selectedHRlist,
        link,
        rootAssessmentId: id
      })
    );

    // onHide();
    if (response.payload) {
      setSuccess(true);
    }
    setEmailsReady(false);

    setButtonLoading(false);
  }

  useEffect(() => {
    setButtonLoading(false);
  }, []);

  async function handleInviteHRForReview() {
    setButtonLoading(true);
    if (newlyInvitedList?.length > 0) {
      await newlyInvitedList?.forEach(id => {
        const email = newUserList?.filter(user => user?.id === id)[0]?.email;
        setSelectedHRlist(prev => [...prev, email]);
      });
    }
    await setEmailsReady(true);
  }

  useEffect(() => {
    if (emailsReady) {
      sendMails();
    }
  }, [emailsReady]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${styles['modal-content']}`}
      dialogClassName={styles.container}
      onHide={onHide}
      show={show}
      onExit={() => setCopySuccess(false)}
    >
      <div className={`${styles.ModalWrapper} ${inviteShow && styles.blurred}`}>
        <div className={styles.header}>
          {!success && <div className={styles.headerTitle}>Send for Review</div>}
          <CloseButton onClick={onHide} size="small" />
        </div>

        {success ? (
          <>
            <div className={styles.successContainer}>
              <Success className={styles.successIcon} />
              <div className={styles.successTitle}>Successfully Sent!</div>
              <div className={styles.successSubtitle}>
                Invitation for the assessment review has been sent to the selected users.
              </div>
              <div className={styles.successFootNote}>
                *You will be notified by e-mail about the changes made in the assessment.
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.lineWrapper}>
              <span className={styles.textNormal}>Please select a&nbsp;</span>
              <span className={styles.textBold}>hiring manager.</span>
              <button
                className={styles.inviteButton}
                type="button"
                onClick={() => dispatch(setInviteUserShow(true))}
              >
                Add new user
              </button>
            </div>
            <div className={styles.searchWrapper}>
              <SearchBar
                size="xsmall"
                isExtended={1}
                color="gray"
                inputFieldPlaceholder="Search"
                inputFieldOnChange={event => {
                  setSearchParam(event.target.value);
                }}
                inputFieldValue={searchParam}
                extendedStyle={{ width: '100%', fontSize: '14px' }}
              />
            </div>
            <div className={styles.hrList}>
              {hrList?.map(
                (hr, key) =>
                  hr.nameSurname
                    .toString()
                    .toLowerCase()
                    .includes(searchParam.toLocaleLowerCase()) && (
                    <div className={styles.checkboxRow}>
                      <div className={styles.checkboxWrapper}>
                        <Checkbox
                          myOnClick={() => handleCheckbox(hr.email)}
                          myChecked={
                            selectedHRlist.includes(hr.email) || newlyInvitedList.includes(hr.id)
                          }
                          myDisabled={newlyInvitedList.includes(hr.id)}
                        />
                      </div>
                      <div
                        className={`${styles.hrText} ${
                          newlyInvitedList.includes(hr.id) && styles.disabledHR
                        }`}
                        key={key}
                      >
                        {hr.nameSurname}
                      </div>
                      {newlyInvitedList.includes(hr.id) && styles.disabledHR && (
                        <div className={styles.invitedWrapper}>
                          <span className={styles.imgWrapper}>
                            <img alt="" src={invited} />
                          </span>
                          <span className={styles.invitedText}>New user invitation sent!</span>
                        </div>
                      )}
                      {JSON.stringify(invitedHRlist).includes(hr.id) &&
                        !newlyInvitedList.includes(hr.id) && (
                          <div className={styles.invitedWrapper}>
                            <span className={styles.imgWrapper}>
                              <img alt="" src={invited} />
                            </span>
                            <span className={styles.invitedText}>Invited!</span>
                          </div>
                        )}
                    </div>
                  )
              )}
            </div>
            <div className={styles.linkRow}>
              <div
                className={styles.copyLink}
                onClick={() => copyToClipBoard(window.location.href)}
              >
                <span className={styles.imgWrapper}>
                  <img
                    style={{ height: '100%', width: '100%' }}
                    alt=""
                    src={copySuccess ? linkCopiedImg : copyLink}
                  />
                </span>
                <span>{copySuccess ? 'Link Copied!' : 'Copy Link'}</span>
              </div>
              <div className={styles.sendButton}>
                <CustomButton
                  textField="Send"
                  buttonOnClick={async () => {
                    // eslint-disable-next-line no-restricted-syntax
                    handleInviteHRForReview();
                  }}
                  size="small"
                  isDisabled={selectedHRlist.length === 0 && newlyInvitedList.length === 0}
                  loading={buttonLoading}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
