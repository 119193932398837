import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton from '../../Common/Components/CloseButton';
import CustomButton from '../../Common/Components/CustomButton';
import {
  setUnsubscribeSuccessShow,
  setPlanUnsubscribedModalShow,
  setDowngradeSuccess,
  setErrorTextInPlan,
  setErrorInPlan,
  setErrorTextInSeePlans,
  setErrorInSeePlans
} from '../../redux/plan/slice';
import { ReactComponent as PurpleMinus } from '../Images/purpleMinus.svg';
import { ReactComponent as PurplePlus } from '../Images/purplePlus.svg';
import styles from '../Styles/UnsubscribeModal.module.css';
import {
  unsubscribePlanAction,
  unsubscribeAddOnAction,
  downgradePackageAction,
  getCurrentPlanAction,
  getCurrentUsageAction,
  getAddOnsAction
} from '../../redux/plan/actions';
import { userSelector } from '../../redux/auth/selectors';

import {
  toBeCancelledAddOnType,
  addOnsRedux,
  purchaseModalPackageIdRedux,
  unsubscribeAddOnInfoCountRedux,
  unsubscribeAddOnItemCodeRedux
} from '../../redux/plan/selectors';
import { useWindowSize } from '../../utils/helpers';

export default function UnsubscribeModal({
  show,
  onHide,
  title,
  description,
  footer,
  plan,
  downgrade,
  addOn,
  ...props
}) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  // const cancelAddOnID = useSelector(toBeCancelledAddOn);
  const addOnType = useSelector(toBeCancelledAddOnType);
  const [addOnTitle, setAddOnTitle] = useState('');
  const [addOnSubTitle, setAddOnSubTitle] = useState('');
  const addOns = useSelector(addOnsRedux);

  const downgradePackageId = useSelector(purchaseModalPackageIdRedux);
  const addOnInfoCount = useSelector(unsubscribeAddOnInfoCountRedux);
  const addOnItemCode = useSelector(unsubscribeAddOnItemCodeRedux);
  const [screenW, screenH] = useWindowSize();

  // Popup içerisindeki count'ı değiştirebilmemiz için
  const [addOnInnerCount, setAddOnInnerCount] = useState(addOnInfoCount);

  useEffect(() => {
    setAddOnInnerCount(addOnInfoCount);
  }, [addOnInfoCount]);

  const handleUnsubscribe = async () => {
    if (addOnType && addOn) {
      const addOnData = {
        bundleItemCode: addOnItemCode,
        count: addOnType === 'Published Assessments' ? addOnInnerCount : null
      };
      onHide();
      const a = await dispatch(unsubscribeAddOnAction(addOnData));

      if (a?.payload) {
        await dispatch(setUnsubscribeSuccessShow(true));
      } else {
        // error message
        await dispatch(setErrorTextInPlan(a?.payload?.message));
        await dispatch(setErrorInPlan(true));
      }
    } else {
      const planData = {
        companyId: user.companyId
      };
      onHide();
      const a = await dispatch(unsubscribePlanAction(planData));
      if (a?.meta?.requestStatus === 'fulfilled') {
        await dispatch(setPlanUnsubscribedModalShow(true));
      } else {
        // error message
        await dispatch(setErrorTextInPlan(a?.payload?.message));
        await dispatch(setErrorInPlan(true));
      }
    }
    const data = {
      CompanyId: user.companyId
    };
    await dispatch(getCurrentPlanAction(data));
    await dispatch(getCurrentUsageAction(data));
    await dispatch(getAddOnsAction(data));
  };

  const handleDowngrade = async () => {
    const data = {
      newBundleId: downgradePackageId.bundleId
    };
    const a = await dispatch(downgradePackageAction(data));
    if (a?.meta?.requestStatus === 'fulfilled') {
      await onHide();
      await dispatch(setDowngradeSuccess(true));
      await dispatch(getCurrentPlanAction({ CompanyId: user?.companyId }));
      await dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
      await dispatch(getAddOnsAction({ companyId: user?.companyId }));
    } else {
      await dispatch(setErrorTextInSeePlans(a?.payload?.message));
      await onHide();
      await dispatch(setErrorInSeePlans(true));
    }
  };

  useEffect(() => {
    if (addOnType) {
      if (addOnType === 'Published Assessments') {
        if (addOnInfoCount === 1) {
          setAddOnSubTitle('assessment');
        } else {
          setAddOnSubTitle('assessments');
        }
        setAddOnTitle('assessment');
      } else if (addOnType === 'Candidates') {
        if (addOnInfoCount === 1) {
          setAddOnSubTitle('candidate');
        } else {
          setAddOnSubTitle('candidates');
        }
        setAddOnTitle('candidate');
      }
    }
  }, [addOnType, show]);

  return (
    <Modal
      size="lg"
      className={styles.backdrop}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      // backdropClassName={styles.backdrop}
      contentClassName={`${styles.modalContent} ${
        addOnType && addOn && styles['addOnmodal-content']
      } ${!addOnType && !addOn && styles['planModal-content']}`}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <CloseButton onClick={onHide} width={24} height={24} />
      </div>
      <div className={styles.subtitle}>{description}</div>
      {addOnType && addOn && (
        <div className={styles.addOnWrapper}>
          <div className={styles.addOnTitle}>Additional {addOnTitle}</div>
          <div className={styles.rowWrapper}>
            <div className={styles.addOnInfo}>
              {/* eslint-disable */}
              {addOnInnerCount} {addOnSubTitle}/month
            </div>
            {/* {addOnType === 'Published Assessments' && ( */}
            {addOnType === 'Published Assessments' && addOnInfoCount > 1 && (
              <div className={styles.iconsWrapper}>
                <PurpleMinus
                  className={styles.icon}
                  onClick={() => {
                    if (addOnInnerCount > 1) {
                      setAddOnInnerCount(addOnInnerCount - 1);
                    }
                  }}
                  style={addOnInnerCount <= 1 ? { pointerEvents: 'none', opacity: '0.4' } : {}}
                />
                <PurplePlus
                  className={styles.icon}
                  onClick={() => {
                    if (addOnInfoCount > addOnInnerCount) {
                      setAddOnInnerCount(addOnInnerCount + 1);
                    }
                  }}
                  style={
                    addOnInnerCount >= addOnInfoCount
                      ? { pointerEvents: 'none', opacity: '0.4' }
                      : {}
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div style={{ marginTop: !addOn && '25px' }} className={styles.row}>
        <CustomButton
          type={2}
          textField={downgrade ? 'Downgrade' : 'Unsubscribe'}
          buttonOnClick={downgrade ? () => handleDowngrade() : () => handleUnsubscribe()}
          style={{ marginRight: '4px', marginBottom: screenW <= 576 && '16px' }}
        />

        <CustomButton
          textField={addOnType ? 'Keep it?' : 'Keep my subscription'}
          buttonOnClick={() => {
            onHide();
          }}
          type={1}
        />
      </div>
    </Modal>
  );
}
