import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { getRootAssessmentDropdownListAction, publishAssessmentControlAction } from './actions';

const addAssessmentSlice = createSlice({
  name: 'custom-assessment',
  initialState,
  reducers: {
    setToInitialState: (state, action) => initialState,
    addWeight: (state, action) => {
      state.weightSum += action.payload;
    },
    setTab: (state, action) => {
      state.assessmentView = action.payload;
    },
    setTotalWeightToZero: (state, action) => {
      state.weightSum = 0;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setName: (state, action) => {
      state.AssessmentName = action.payload;
    },
    setSwitchState: (state, action) => {
      state.switchState = action.payload;
    },
    setAtsState: (state, action) => {
      state.atsState = action.payload;
    },
    setCheckBoxState: (state, action) => {
      state.checkBoxState = action.payload;
    },
    setUserTypeOfAuthComponent: (state, action) => {
      state.userTypeOfAuthComponent = action.payload;
    },
    setRootAssmCategories: (state, action) => {
      state.rootAssmCategories = action.payload;
    },
    setRootAssmJobRoles: (state, action) => {
      state.rootAssmJobRoles = action.payload;
    },
    setRootAssmSeniorities: (state, action) => {
      state.rootAssmSeniorities = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(publishAssessmentControlAction.fulfilled, (state, action) => {
      state.publishEnabled = action.payload;
    });
    builder.addCase(publishAssessmentControlAction.pending, (state, action) => {
      state.publishEnabled = false;
    });
    builder.addCase(publishAssessmentControlAction.rejected, (state, action) => {
      state.publishEnabled = false;
    });
    builder.addCase(getRootAssessmentDropdownListAction.fulfilled, (state, action) => {
      const tempCat = [];
      const tempRole = [];
      const tempSen = [];

      action.payload.categories?.forEach(a => {
        tempCat.push({ name: a.categoryName, catId: a.categoryId });
      });

      action.payload.jobRoles?.forEach(a => {
        tempRole.push({ name: a.jobRoleName, roleId: a.jobRoleId });
      });
      action.payload.seniorityLevels?.forEach(a => {
        tempSen.push({ name: a.seniority, seniorityId: a.seniorityId });
      });
      state.rootAssmCategories = tempCat;
      state.rootAssmJobRoles = tempRole;
      state.rootAssmSeniorities = tempSen;
    });
  }
});

export const {
  addWeight,
  setTab,
  setTotalWeightToZero,
  setIndex,
  setName,
  setSwitchState,
  setAtsState,
  setCheckBoxState,
  setToInitialState,
  setUserTypeOfAuthComponent,
  setRootAssmCategories,
  setRootAssmJobRoles,
  setRootAssmSeniorities
} = addAssessmentSlice.actions;

export default addAssessmentSlice.reducer;
