import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentSessionDetailAction } from '../../../../redux/AssessmentSession/actions';
import {
  assessmentSessionTokenRedux,
  AssessmentTitle,
  LogoPath
} from '../../../../redux/AssessmentSession/selectors';
import styles from './QuestionHeader.module.css';
import mockImage2 from '../../../../images/Coensio_newLogo.svg';
import ShortText from '../../../../Common/Components/ShortText';
import { useWindowSize } from '../../../../utils/helpers';

const QuestionHeader = () => {
  const dispatch = useDispatch();
  const [innerPath, setInnerPath] = useState();
  const assessmentToken = useSelector(assessmentSessionTokenRedux);
  const logoPath = useSelector(LogoPath);
  const assessmentTitle = useSelector(AssessmentTitle);
  const [width, height] = useWindowSize();
  const [oldWidth, setOldWidth] = useState(width);
  const [left, setLeft] = useState();
  const [limit, setLimit] = useState(10000);
  useEffect(() => {
    if (!logoPath || !assessmentTitle) {
      const data = {
        Token: assessmentToken
      };
      dispatch(getAssessmentSessionDetailAction(data));
    }
  }, [logoPath, assessmentTitle]);

  useEffect(() => {
    if (logoPath && logoPath.startsWith('CompanyLogo')) {
      let temp = '';
      temp = temp.concat(`${process.env.REACT_APP_IMAGE_API}/`);
      temp = temp.concat(logoPath);
      setInnerPath(temp);
    }
  }, [logoPath]);
  useEffect(() => {
    setLeft(document.getElementById('text')?.getBoundingClientRect().left);
  }, [width, document.getElementById('text')?.getBoundingClientRect()]);

  useEffect(() => {
    if (oldWidth - width > 0 && left && left <= width / 2) {
      setLimit(10);
    } else if (oldWidth - width < 0) {
      setLimit(10000);
    } else if (oldWidth - width === 0 && left && left <= width / 2) {
      setLimit(10);
    }

    setOldWidth(width);
  }, [left, width]);

  const [staticLimit, setStaticLimit] = useState();

  useEffect(() => {
    const charWidth = document.getElementById('char').getBoundingClientRect().width;

    const allowedWidth =
      (document.getElementById('qHeader').getBoundingClientRect().width - 70) / 2;

    setStaticLimit(Math.floor(allowedWidth / charWidth));
  });

  return (
    <>
      <div id="qHeader" className={styles.QuestionHeader}>
        <img className={styles.UpperLogo} src={innerPath || mockImage2} alt="companyLogo" />
        <ShortText
          position="left"
          id="text"
          staticLimit={staticLimit}
          name={assessmentTitle || ''}
        />
      </div>
      <div
        id="char"
        className={styles.QuestionHeader}
        style={{ padding: 0, visibility: 'hidden', position: 'absolute' }}
      >
        0
      </div>
    </>
  );
};

export default QuestionHeader;
