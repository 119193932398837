import React, { useEffect, useState } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { isMobile } from 'react-device-detect';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import styles from './RejectionMailTextEditor.module.css';

const RejectionMailTextEditor = ({ setStyle = {}, sampleMail, passMailToParent }) => {
  const [latestMailContent, setLatestMailContent] = useState();

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    if (sampleMail) {
      const blocksFromHTML = htmlToDraft(sampleMail?.emailContent);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
      setLatestMailContent(EditorState.createWithContent(state));
    }
  }, [sampleMail]);

  const handleEditorChange = state => {
    setEditorState(state);
    if (latestMailContent.getCurrentContent() !== state.getCurrentContent()) {
      passMailToParent(draftToHtml(convertToRaw(state.getCurrentContent())));
    }
  };

  function uploadImage(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${process.env.REACT_APP_PRODUCT_API_URL}/api/common/ImageSave`);
      const data = new FormData();
      data.append('file', file);
      data.append('directoryName', 'rejectionMail');
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);
        const path = `${process.env.REACT_APP_IMAGE_API}/${response.model.path}`;
        resolve({ data: { link: path } });
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  return (
    <div className={styles.RejectionMailTextEditorWrapper} style={setStyle}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          image: {
            urlEnabled: false,
            uploadEnabled: true,
            previewImage: true,
            uploadCallback: uploadImage
          }
        }}
        toolbarClassName={styles.Toolbar}
        wrapperClassName={styles.Wrapper}
        editorClassName={styles.Editor}
        readOnly={isMobile}
      />
    </div>
  );
};

export default RejectionMailTextEditor;
