import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import styles from '../Styles/PremiumQuestionModal.module.css';
import { ReactComponent as PremiumIcon } from '../../../images/premium_icon.svg';
import CustomButton from '../../../Common/Components/CustomButton';
import CloseButton from '../../../Common/Components/CloseButton';

const Title = styled.div`
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 12px;
  text-align: center;
  color: #393939;
`;
const Subtitle = styled.div`
  font-family: 'Jost-300';
  font-style: normal;
  font-weight: 300;
  margin-bottom: 38px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #393939;
`;

function PremiumQuestionModal({ show, onHide }) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      <CloseButton size="small" onClick={onHide} style={{ marginRight: -9 }} />
      <PremiumIcon className={styles.icon} />
      <Title>This one is a premium question</Title>
      <Subtitle>
        Get this premium question for only $X to take your assessment to the next level!{' '}
      </Subtitle>
      <CustomButton type={1} textField="Confirm" style={{ width: '100%' }} />
      <CustomButton
        buttonOnClick={() => {
          onHide();
        }}
        type={2}
        textField="Cancel"
        style={{ width: '100%' }}
      />
    </Modal>
  );
}

export default PremiumQuestionModal;
