/* eslint-disable no-return-assign */
import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../Styles/UnsubscribeSuccessModal.module.css';
import { ReactComponent as Success } from '../../images/SFR_success.svg';
import { ReactComponent as PurpleSuccess } from '../Images/purpleSuccess.svg';
import confetti from '../Images/confetti.svg';
import CloseButton from '../../Common/Components/CloseButton';

export default function UnsubscribeSuccessModal({
  show,
  onHide,
  title,
  description,
  footer,
  icon,
  ...props
}) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      show={show}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
      dialogClassName={styles.container}
    >
      <Modal.Body>
        <>
          <div className={styles.successContainer}>
            <CloseButton width={24} height={24} onClick={onHide} />
            {icon === 'tick' ? (
              <Success className={styles.successIcon} />
            ) : icon === 'confetti' ? (
              <span className={styles.successIcon}>
                <img alt="" src={confetti} />
              </span>
            ) : (
              <PurpleSuccess className={styles.successIcon} />
            )}
            {title && <div className={styles.successTitle}>{title}</div>}
            {description && <div className={styles.descText}>{description}</div>}
            {footer && <div className={styles.successFootNote}>{footer}</div>}
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}
