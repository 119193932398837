import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../Common/Components/CustomButton';
import CloseButton from '../../Common/Components/CloseButton';
import styles from '../Styles/PaymentMethod.module.css';
import TextInput from '../../Common/Components/TextInput';
import { billingInfoRedux, paymentMethodModalShow } from '../../redux/plan/selectors';
import CardInput from '../../Common/Components/CardInput';
import {
  createCardAction,
  getBillingInfoAction,
  getCurrentPlanAction,
  getCurrentUsageAction
} from '../../redux/plan/actions';
import cards from '../Images/cardsFrame.svg';
import { userSelector } from '../../redux/auth/selectors';
import { setSettingsPageSuccessMessage, setSettingsPageSuccessShow } from '../../redux/auth/slice';

export default function PaymentMethod({ show, onHide, ...props }) {
  const dispatch = useDispatch();
  const modalType = useSelector(paymentMethodModalShow);
  const [cardNo, setCardNo] = useState();
  const [cardCvc, setCardCvc] = useState();
  const [cardMmyy, setCardMmyy] = useState();
  const [name, setName] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const user = useSelector(userSelector);
  const billingInfo = useSelector(billingInfoRedux);
  const [association, setAssociation] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleCreateCard() {
    setLoading(true);
    const data = {
      cardAlias: name,
      cardNumber: cardNo.replace(/ /g, ''),
      expireYear: cardMmyy.split('/')[1].replace(/ /g, ''),
      expireMonth: cardMmyy.split('/')[0].replace(/ /g, ''),
      cardHolderName: name,
      cvv: cardCvc
    };
    const a = await dispatch(createCardAction(data));
    const rawData = {
      CompanyId: user.companyId
    };

    if (a?.meta?.requestStatus === 'fulfilled') {
      await dispatch(setSettingsPageSuccessMessage('Payment method successfully updated'));
      await setLoading(false);
      await onHide();
      await dispatch(getCurrentPlanAction({ CompanyId: user?.companyId }));
      await dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
      await dispatch(setSettingsPageSuccessShow(true));
    } else {
      await setErrorMessage(a?.payload?.message);
      await setLoading(false);
      await setError(true);
      await setTimeout(() => {
        setError(false);
        setErrorMessage('');
      }, 10000);
    }

    await dispatch(getBillingInfoAction(rawData));
    await setLoading(false);
  }

  useEffect(() => {
    if (!show) {
      setCardNo();
      setCardCvc();
      setCardMmyy();
      setName();
      setError(false);
      setErrorMessage('');
    } else {
      const rawData = {
        CompanyId: user.companyId
      };

      dispatch(getBillingInfoAction(rawData));
    }
  }, [show]);

  useEffect(() => {
    if (billingInfo) {
      if (billingInfo?.cardAssociation === 'MASTER_CARD') {
        setAssociation('Mastercard');
      } else if (billingInfo?.cardAssociation === 'AMERICAN_EXPRESS') {
        setAssociation('Amex');
      } else if (billingInfo?.cardAssociation === 'VISA') {
        setAssociation('Visa');
      }
    }
  }, [billingInfo]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      {error && <div className={styles.errorMessage}>{errorMessage}</div>}
      <div className={styles.wrapper}>
        <div className={styles.headerContainer}>
          <span className={styles.header}>{modalType} payment method</span>
          <CloseButton width={24} height={24} onClick={onHide} />
        </div>
        <div className={styles.infoText}>
          {`Choose a new payment method before removing ${
            billingInfo?.cardAssociation ? association : '*****'
          }****${billingInfo?.cardLastFourDigits}`}
        </div>

        <div className={styles.subHeaderContainer}>
          <div className={styles.subHeader}>Add a credit or debit card</div>
          <span>
            <img src={cards} alt="" />
          </span>
        </div>
        <div className={styles.inputContainer}>
          <CardInput
            no={cardNo}
            cvc={cardCvc}
            mmyy={cardMmyy}
            setNo={setCardNo}
            setMmyy={setCardMmyy}
            setCvc={setCardCvc}
          />
          <TextInput
            inputstyle={{ height: '50px' }}
            placeholder="Cardholder name"
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </div>

        <div className={styles.buttonContainer}>
          <CustomButton
            buttonOnClick={() => onHide()}
            type={2}
            textField="Cancel"
            style={{ marginRight: '4px' }}
          />
          <CustomButton
            loading={loading}
            buttonOnClick={() => handleCreateCard()}
            isDisabled={!cardNo || !name}
            type={1}
            textField={
              modalType === 'add'
                ? 'Save'
                : modalType === 'remove'
                ? 'Save and Remove'
                : 'Save and Change'
            }
          />
        </div>
      </div>
    </Modal>
  );
}
