import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import GlobalStyle from './style/globalStyle';

// Sentry.init({
//   dsn: "https://91527c17de284f7eba4ed6135abf4aad@o4504331863851008.ingest.sentry.io/4504331873878016", // default
//   integrations: [new BrowserTracing()],
//   environment: process.env.REACT_APP_SENTRY_ENV,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <GoogleReCaptchaProvider reCaptchaKey="6LeHfCQhAAAAAPvO_bNDeL1lKyxLzjw1iz1GJtdV">
      <App />
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
