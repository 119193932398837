import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from '../Styles/AssessmentCreationPage.module.css';
import Loading from '../../../Common/Components/Loading';
import {
  assessmentDetailAfterCreate,
  CompanyAssessmentId
} from '../../../redux/assessmentsVol2/selectors';
import { getAssessmentDetailAfterCreateAction } from '../../../redux/assessmentsVol2/actions';
import { useWindowSize } from '../../../utils/helpers';

const AssessmentCreationPage = () => {
  const dispatch = useDispatch();
  const [redirectNow, setRedirectNow] = useState(false);
  const [windowW, windowH] = useWindowSize();

  const history = useHistory();
  const detail = useSelector(assessmentDetailAfterCreate);
  const assessmentId = detail.id;
  const asssessmentID = useSelector(CompanyAssessmentId);

  // No ats - no connection durumunda 3. stepten 5. stepe atlıyoruz
  // O durum için useEffect
  useEffect(() => {
    if (detail.length === 0 || Object.keys(detail).length === 0) {
      const data = {
        CompanyAssessmentId: asssessmentID || localStorage.getItem('addAssessmentCompanyId')
      };
      dispatch(getAssessmentDetailAfterCreateAction(data));
    }
  }, []);

  setTimeout(() => setRedirectNow(true), 3000);

  useEffect(() => {
    if (redirectNow) {
      localStorage.removeItem('addAssessmentCompanyId');
      history.replace(`/assessment-detail/${assessmentId}?welcome=true`);
    }
  }, [redirectNow]);

  return (
    <div className={styles.container}>
      <Loading />
      <div className={styles.text}>{windowW > 700 ? "..we are creating your assessment.." : "Please wait.."}</div>
    </div>
  );
};

export default AssessmentCreationPage;
