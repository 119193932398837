export default {
  REQUEST_MAIL_SUBJECT: 'Job Opportunity — @@PositionName@@ Position at Coensio',
  REQUEST_MAIL_CONTENT: `<p>Hi @@CandidateName@@,</p>
    <p></p>
    <p>I am @@UserName@@. I came across your profile on Coensio and wanted to reach out regarding a unique opportunity.</p>
    <p>I work for @@CompanyName@@, and we’re looking to hire a @@PositionName@@. I think your experience and Coensio Score is a great fit for this role.</p>
    <p></p>
    <p>If you’re interested in learning more, I’d love to connect.&nbsp;</p>
    <p>I look forward to hearing from you soon.</p>
    <p></p>
    <p>Best,</p>
    <p>@@UserName@@</p>
    <p></p>
    `,

  REQUEST_CANDIDATE_OBJECT_STATUS: 9,
  NEWOP_LOCKED_CANDIDATE_OBJECT_STATUS: 4,
  REQUEST_STATUS_NEW: 10,
  REQUEST_STATUS_PENDING: 20,
  REQUEST_STATUS_ACCEPTED: 30,
  REQUEST_STATUS_DECLINED: 40,
  HTTP_RESPONSE_CODE_MAX_REQUEST: 418
};
