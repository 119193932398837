import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CustomButton from '../../Common/Components/CustomButton';
import CloseButton from '../../Common/Components/CloseButton';
import styles from '../Styles/PurchaseModal.module.css';
import { ReactComponent as BackArrow } from '../Images/leftArrow.svg';
import Visa from '../Images/Visa.svg';
import MasterCard from '../Images/Mastercard.svg';
import AmericanExpress from '../Images/Americanexpress.svg';
import TextInput from '../../Common/Components/TextInput';
import Dropdown from '../../Common/Components/CustomDropdown/CustomDropdown';
import { ReactComponent as Gradient } from '../Images/gradientSmall.svg';
import alert from '../Images/alert.svg';
import {
  getBillingInfoAction,
  getCountriesAction,
  getPackageDetailAction,
  createPackageOrderAction,
  createCardAction,
  editBillingInfoAction,
  getProfileAction,
  getCurrentPlanAction,
  getCurrentUsageAction
} from '../../redux/plan/actions';
import {
  billingInfoRedux,
  countriesRedux,
  packageDetailRedux,
  purchaseModalPackageIdRedux
} from '../../redux/plan/selectors';
import { upgradePricesRedux, userSelector } from '../../redux/auth/selectors';
import CardInput from '../../Common/Components/CardInput';
import ShortText from '../../Common/Components/ShortText';
import { setShowPackageDetail } from '../../redux/plan/slice';

export default function PurchaseModal({ show, onHide, setSuccess = () => {}, ...props }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const packageDetail = useSelector(packageDetailRedux);
  const billingInfo = useSelector(billingInfoRedux);
  const countryList = useSelector(countriesRedux);
  const purchaseModalPackageId = useSelector(purchaseModalPackageIdRedux);
  const upgradePrices = useSelector(upgradePricesRedux);

  const [error, setErrorShow] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [cardNo, setCardNo] = useState();
  const [cardCvc, setCardCvc] = useState();
  const [cardMmyy, setCardMmyy] = useState();

  const [nameSurname, setNameSurname] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [postalCode, setPostalCode] = useState();
  const [country, setCountry] = useState();
  const [vat, setVat] = useState();
  const [dropdownList, setDropdownList] = useState([]);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');


  const resetFields = () => {
    setNameSurname();
    setAddress();
    setCity();
    setPostalCode();
    setCountry();
    setVat();
    setCardCvc();
    setCardNo();
    setCardMmyy();
  };

  useEffect(() => {
    if (show) {
      const data = {
        BundleId: purchaseModalPackageId?.bundleId
      };
      dispatch(getPackageDetailAction(data));
      const rawData = {
        CompanyId: user.companyId
      };
      dispatch(getBillingInfoAction(rawData));
      dispatch(getCountriesAction());
      const data2 = {
        UserId: user.userId
      };
      dispatch(getProfileAction(data2));
    }
    if (!show) {
      resetFields();
      setErrorShow(false);
      setErrorText('');
    }
  }, [show]);

  useEffect(() => {
    if (loading) {
      setErrorShow(false);
      setErrorText('');
    }
  }, [loading]);

  const createOrder = async () => {
    await setLoadingText('Purchase in progress...');
    await setLoading(true);
    const billingData = {
      nameSurname: nameSurname,
      mail: user?.email,
      address: address,
      city: city,
      countryId: country?.code,
      zipCode: postalCode,
      taxNo: vat
    };

    const timer1 = setTimeout(async () => {
      await setLoadingText('Trying to receive payment...');
    }, 3000);

    const timer2 = setTimeout(async () => {
      await setLoadingText('Hold on! You are almost there...');
    }, 3000);

    const a = await dispatch(editBillingInfoAction(billingData));
    if (a?.meta?.requestStatus !== 'fulfilled') {
      // error messages are shown considering their importance level
      await setLoading(false);
      await setErrorText(a?.payload?.message);
      await setErrorShow(true);
    } else {
      const cardData = {
        cardAlias: nameSurname,
        cardNumber: cardNo.replace(/ /g, ''),
        expireYear: cardMmyy.split('/')[1].replace(/ /g, ''),
        expireMonth: cardMmyy.split('/')[0].replace(/ /g, ''),
        cardHolderName: nameSurname,
        cvv: cardCvc
      };
      const b = await dispatch(createCardAction(cardData));

      if (b?.meta?.requestStatus !== 'fulfilled') {
        await setLoading(false);
        await setErrorText(b?.payload?.message);
        await setErrorShow(true);
      } else {
        const bundleData = {
          bundleId: purchaseModalPackageId?.bundleId
        };

        const c = await dispatch(createPackageOrderAction(bundleData));

        if (c?.meta?.requestStatus !== 'fulfilled') {
          await setLoading(false);
          await setErrorText(c?.payload?.message);
          await setErrorShow(true);
        } else {
          await setLoading(false);
          await onHide();
          await setSuccess(true);
          const planData = {
            CompanyId: user?.companyId
          };
          await dispatch(getCurrentPlanAction(planData));
          await dispatch(getCurrentUsageAction(planData));
          await dispatch(getBillingInfoAction(planData));
        }
      }
    }
    clearTimeout(timer1);
    clearTimeout(timer2);
  };

  useEffect(() => {
    if (countryList) {
      const temp = [];
      countryList.forEach((item, index) => {
        temp.push({
          id: index,
          key: index,
          title: item.name,
          code: item.id
        });
      });
      setDropdownList(temp);
      setChange(!change);
    }
  }, [countryList]);

  const countries = useSelector(countriesRedux);

  const [vWidth, setVwidth] = useState(document?.documentElement.clientWidth);

  useEffect(() => {
    setVwidth(document?.documentElement.clientWidth);
  }, [document?.documentElement.clientWidth]);

  const StyledGradient = styled.div`
    margin-right: auto;
    margin-left: auto;
    height: 10px;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: -10px;
    justify-content: center;
    align-items: center;

    path {
      transform: scaleX(calc(${vWidth} / 1500)) translateX(1.5vw);
    }
  `;

  return (
    <div className={styles.generalWrapper} id="underQS">
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={`${styles.container} ${styles.myModalContainer}`}
        show={show}
        onHide={onHide}
        contentClassName={styles['modal-content']}
      >
        {/* <div className={styles.gradientContainer}> */}
        <StyledGradient>
          <Gradient />
        </StyledGradient>
        {/* </div> */}
        <div className={styles.modalBody}>
          <div className={styles.titleContainer}>
            <BackArrow
              className={styles.backIcon}
              onClick={() => {
                onHide();
                dispatch(setShowPackageDetail(true));
              }}
            />
            <span className={styles.purchaseTitle}>
              Purchase {purchaseModalPackageId?.name} Plan
            </span>
            <div className={styles.closeButton}>
              <CloseButton onClick={onHide} />
            </div>
          </div>

          <div className={styles.colContainer}>
            {billingInfo && (
              <div className={styles.leftCol}>
                {error && (
                  <div className={styles.errorMessageDiv}>
                    <span className={styles.alertImg}>
                      <img alt="" src={alert} />
                    </span>
                    <ShortText name={errorText} staticLimit={65} />
                  </div>
                )}
                <div className={styles.paymentCardContainer}>
                  <span className={styles.cardTitle}>Payment</span>
                  <div className={styles.descRow}>
                    <span className={styles.descText}>Add a credit or debit card</span>
                    <div className={styles.cardsContainer}>
                      <img src={Visa} alt="" className={styles.cardIcon} />
                      <img className={styles.cardIcon} alt="" src={AmericanExpress} />
                      <img alt="" src={MasterCard} className={styles.cardIcon} />
                    </div>
                  </div>
                  <div style={{ marginBottom: '1.6rem' }}>
                    <CardInput
                      no={cardNo}
                      cvc={cardCvc}
                      mmyy={cardMmyy}
                      setNo={setCardNo}
                      setMmyy={setCardMmyy}
                      setCvc={setCardCvc}
                    />
                  </div>
                  <TextInput
                    placeholder="Name Surname*"
                    value={nameSurname}
                    onChange={e => setNameSurname(e.target.value)}
                    inputstyle={{ height: '5rem' }}
                  />
                </div>

                <div className={styles.paymentCardContainer}>
                  <span className={styles.cardTitle}>Billing Address</span>
                  <div style={{ marginBottom: '1.6rem' }}>
                    <TextInput
                      placeholder="Address*"
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                      inputstyle={{ height: '5rem' }}
                    />
                  </div>
                  <div className={styles.inputGrid} style={{ marginBottom: '1.6rem' }}>
                    <div>
                      <TextInput
                        placeholder="City*"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        inputstyle={{ height: '5rem' }}
                      />
                    </div>
                    <div>
                      <TextInput
                        placeholder="Postal Code*"
                        value={postalCode}
                        onChange={e => setPostalCode(e.target.value)}
                        inputstyle={{ height: '5rem' }}
                      />
                    </div>
                  </div>
                  <Dropdown
                    setSelectedOption={setCountry}
                    placeholder="Select a country"
                    list={dropdownList}
                    title="Country*"
                    visibleItemNo={3}
                    headerTitleStyle={{ paddingLeft: 0 }}
                    changeInList={change}
                    resetDropdown
                    wrapperStyle={{ height: '5rem' }}
                    headerStyle={{ paddingTop: '1rem' }}
                    itemStyle={{ height: '5rem' }}
                    searchBar
                    searchBarPlaceholder="Find your country"
                  />
                </div>
                <div className={styles.paymentCardContainer}>
                  <span className={styles.cardTitle}>VAT Number</span>
                  <TextInput
                    placeholder="VAT Number*"
                    value={vat}
                    onChange={e => setVat(e.target.value)}
                    inputstyle={{ height: '50px' }}
                    style={{ height: '5rem', marginBottom: '1rem' }}
                  />
                </div>
              </div>
            )}

            <div className={styles.rightCol}>
              <div className={styles.planContainer}>
                <div style={{ marginBottom: '16px' }}>
                  <span className={styles.cardTitle}>Summary</span>
                </div>
                <div className={styles.rightColRows}>
                  <span className={styles.subtitleText}>Your Plan</span>
                  <span className={styles.infoText}>{packageDetail?.bundleName} Plan</span>
                </div>

                <div
                  className={styles.rightColRows}
                  style={{ paddingBottom: '2rem', borderBottom: '1px solid rgba(88,88,88,0.1)' }}
                >
                  <span className={styles.subtitleText}>Billing</span>
                  <span className={styles.infoText}>{packageDetail?.billingType}</span>
                </div>

                <div
                  className={styles.rightColRows}
                  style={{
                    paddingBottom: '0.3rem',
                    paddingTop: '0.3rem',
                    marginBottom: '16px'
                  }}
                >
                  <span className={styles.subtitleText}>Total</span>
                  <span className={styles.totalText}>{`${
                    upgradePrices?.paymentCurrencyType === 10 ? '₺' : '$'
                  }${
                    upgradePrices?.addOnPrice +
                    upgradePrices?.leftOverPrice +
                    upgradePrices?.newBundlePrice
                  }`}</span>
                </div>

                <CustomButton
                  isDisabled={
                    !cardNo ||
                    !cardCvc ||
                    !cardMmyy ||
                    !nameSurname ||
                    !address ||
                    !city ||
                    !postalCode ||
                    !country ||
                    !vat
                  }
                  type={1}
                  textField="Confirm"
                  style={{
                    height: '42px',
                    fontSize: '1.8rem',
                    marginRight: '0px',
                    marginLeft: 'auto',
                    marginTop: '16px'
                  }}
                  buttonOnClick={() => createOrder()}
                  loading={loading}
                />
              </div>
              {loading && <div className={styles.loadingDiv}>{loadingText}</div>}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
