import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getAssessmentListServiceVol2,
  getCompanyAssessmentFilterCountService
} from './serviceV2';


export const getCompanyAssessmentListAction = createAsyncThunk(
  'company/getAssessmentList',
  async (data, thunkAPI) => {
    try {
      return await getAssessmentListServiceVol2(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyAssessmentFilterCountAction = createAsyncThunk(
  'company/getFilterCounts',
  async (data, thunkAPI) => {
    try {
      return await getCompanyAssessmentFilterCountService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
