import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onboardingStepRedux } from '../../../redux/plan/selectors';

import { ReactComponent as PersonalIcon } from '../../../images/bannerPersonal.svg';
import { ReactComponent as CompanyIcon } from '../../../images/bannerCompany.svg';
import { ReactComponent as Logout } from '../../../images/logout.svg';

import styles from './SettingsContent.module.css';
import Highlight from '../../Components/Highlight';
import { logoutAndRedirect } from '../../../utils/helpers';

const SettingsContent = () => {
  const onboardingStep = useSelector(onboardingStepRedux);
  const history = useHistory();

  return (
    <>
      <div className={styles.setting}>Settings</div>
      <div className={styles.settingBlock}>
        <div
          className={styles.settingRow}
          onClick={() => {
            history.push('/settings/myprofile');
          }}
        >
          <PersonalIcon className={styles.icon} />
          <div className={styles.settingText}>Personal</div>
        </div>
        <div
          className={`${styles.settingRow}`}
          onClick={() => {
            history.push('/settings/general');
          }}
        >
          <Highlight
            isHighlighted={onboardingStep === 2 || onboardingStep === 3}
            borderRadius={12}
            paddingPixel={4}
            child={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: onboardingStep === 2 || (onboardingStep === 3 && '4px 6px 4px 6px')
                }}
                id="companyDiv"
              >
                <CompanyIcon className={styles.icon} />
                <div className={styles.settingText}>Company</div>
              </div>
            }
          />
        </div>
      </div>
      <div className={styles.borderBottom} />
      <div
        className={styles.logoutRow}
        onClick={() => {
          logoutAndRedirect();
        }}
      >
        <Logout />
        <div className={styles.logoutText}>Logout</div>
      </div>
    </>
  );
};

export default SettingsContent;
