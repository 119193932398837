import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from '../Styles/AssessmentDetailsModal.module.css';
import { ReactComponent as Tick } from '../../../images/purple_tick.svg';
import { ReactComponent as NewTab } from '../../../images/exportIcon.svg';
import Tooltip from '../../../Common/Components/Tooltip';
import CloseButton from '../../../Common/Components/CloseButton';
import ShortText from '../../../Common/Components/ShortText';
import TextOverflow from '../../../Common/TextOverflow/TextOverflow';
import { ReactComponent as Coding } from '../../../images/coding_assessment.svg';
import { ReactComponent as MultipleChoice } from '../../../images/multiple_assessment.svg';
import { ReactComponent as FreeText } from '../../../images/freetext_assessment.svg';
import { assessmentView } from '../../../redux/addAssessment/selectors';
import { userSelector } from '../../../redux/auth/selectors';
import { updateCustomAssessmentAction } from '../../../redux/assessmentsVol2/actions';
import {
  assessmentActiveTab,
  updateCustomAssessmentCheckRedux
} from '../../../redux/assessmentsVol2/selectors';
import {
  setAssessmentId,
  setCustomAssessment,
  setUpdateCustomAssessmentCheck
} from '../../../redux/assessmentsVol2/slice';
import CustomButton from '../../../Common/Components/CustomButton';
import { questionAvailabilityControlAction } from '../../../redux/plan/actions';
import { currentPlanRedux } from '../../../redux/plan/selectors';
import { setToBePurchasedAddOn } from '../../../redux/plan/slice';

import seniorityIcon from '../Components/RootAssessmentCard/Images/seniority.svg';
import questionCountIcon from '../Components/RootAssessmentCard/Images/questionCount.svg';
import durationIcon from '../Components/RootAssessmentCard/Images/duration.svg';

import CustomModal from '../../../Common/CustomModal/Modal';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';

import mixpanel from '../../../utils/mixpanel';
import { useWindowSize } from '../../../utils/helpers';
import backToLibImg from '../Images/backToLibArrowIcon.svg';

export default function AssessmentDetailsModal({
  show,
  onDetailHide,
  questions,
  category,
  onCreate,
  nextStage,
  setSinglePremiumQuestion,
  custom
}) {
  const history = useHistory();
  const activeTab = useSelector(assessmentView);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const currentPlan = useSelector(currentPlanRedux);
  const assessmentTab = useSelector(assessmentActiveTab);

  const updateCustomAssessmentCheckFlag = useSelector(updateCustomAssessmentCheckRedux);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);

  /* --------------- Mixpanel pageview --------------- */
  useEffect(() => {
    if (show) {
      mixpanel.track('Root Assessment Info Popup - Pageview', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Root Assessment Name': category.rootAssessmentName
      });
    }
  }, [show]);
  /* ------------------------------------------------- */

  useEffect(() => {
    if (updateCustomAssessmentCheckFlag === true && show) {
      dispatch(setUpdateCustomAssessmentCheck('pending'));
      dispatch(setCustomAssessment(questions?.rootAssessmentId));
      history.push(`/assessment-customization/${questions?.rootAssessmentId}`);
    }
  }, [updateCustomAssessmentCheckFlag]);

  const calculateMin = questionList => {
    let timeInSeconds = 0;

    questionList?.forEach(item => {
      timeInSeconds += parseInt(item.time, 10);
    });

    const time = {
      min: Math.floor(timeInSeconds / 60),
      sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
    };
    if (time.sec === 0) {
      return `${time.min} min`;
    }
    if (time.min === 0) {
      return `${time.sec} sec`;
    }
    return `${time.min} min ${time.sec} sec`;
  };

  const [screenW, screenH] = useWindowSize();

  const QuestionItem = ({
    item,
    index,
    difficulty,
    mobile = false,
    largeWeb = false,
    smallWeb = false
  }) => {
    const [textWidth, setTextWidth] = useState(245);

    useEffect(() => {
      if (mobile) {
        const maxWidth =
          screenW -
          40 - // paddings
          33 - // left img
          // - 26 // right img
          15; // bonus
        setTextWidth(maxWidth);
      } else if (smallWeb) {
        const maxWidth =
          520 -
          42 - // paddings
          24 - // inner padding
          33 - // left img
          24 - // right img
          58 -
          8 - // difficulty
          16; // bonus
        setTextWidth(maxWidth);
      } else if (largeWeb) {
        const maxWidth =
          412 -
          33 - // left img
          24 - // right img
          58 -
          8 - // difficulty
          8; // bonus
        setTextWidth(maxWidth);
      }
    }, [screenW]);

    return (
      <div className={styles.questionItem}>
        <div className={styles.NumberContainer}>
          <Tooltip text={item.questionTypeName}>
            {item.questionTypeName === 'Free Text' ? (
              <FreeText className={styles.icon} />
            ) : item.questionTypeName === 'Coding' ? (
              <Coding className={styles.icon} />
            ) : (
              <MultipleChoice className={styles.icon} />
            )}
          </Tooltip>
          <div
            className={styles.questionName}
            onClick={async () => {
              if (screenW >= 576) {
                const data2 = {
                  CompanyId: user?.companyId,
                  QuestionId: item.questionId
                };
                const res = await dispatch(questionAvailabilityControlAction(data2));
                if (res?.payload?.isAvailable) {
                  window.open(`/question/${item.questionId}`, '_blank').focus();
                } else {
                  await onDetailHide();
                  const data = {
                    questionId: item?.questionId,
                    bundleItemCode: 60
                  };
                  await dispatch(setToBePurchasedAddOn(data));
                  await setSinglePremiumQuestion(true);
                }
              }
            }}
          >
            <TextOverflow
              text={item.questionTitle}
              itemId={`AssessmentDetailsModal-question-${item.questionId}`}
              width={textWidth}
            />
          </div>
          {!mobile ? <div className={styles.questionDifficulty}>{difficulty}</div> : null}
        </div>
        {screenW >= 576 ? (
          <span className={styles.imgWrapper}>
            <NewTab
              onClick={async () => {
                const data2 = {
                  CompanyId: user?.companyId,
                  QuestionId: item.questionId
                };
                const res = await dispatch(questionAvailabilityControlAction(data2));
                if (res?.payload?.isAvailable) {
                  window.open(`/question/${item.questionId}`, '_blank').focus();
                } else {
                  await onDetailHide();

                  const data = {
                    questionId: item?.questionId,
                    bundleItemCode: 60
                  };
                  await dispatch(setToBePurchasedAddOn(data));
                  await setSinglePremiumQuestion(true);
                }
              }}
              className={styles.newTabIcon}
            />
          </span>
        ) : null}
      </div>
    );
  };

  const mixpanelContinueButtonEvent = () => {
    /* Mixpanel Edit Button Click Event */
    localStorage.setItem(
      'Mixpanel_AssessmentCustomizationRootAssessmentName',
      category.rootAssessmentName
    );

    const qListForMixpanel = [];
    questions?.questionList.forEach(e => {
      qListForMixpanel.push({
        'Question Id': e.questionId,
        Category: e.category,
        Subcategory: e.subcategory,
        'Question Type': e.questionTypeName,
        Difficulty: e.difficulty,
        'Question Language': e.questionLanguage,
        'Question Title': e.questionTitle
      });
    });

    mixpanel.track('Root Assessment Info Popup - Continue Button Click', {
      'User Id': user.userId,
      'Company Name': user.companyName,
      'Name Surname': user.userNameSurname,
      'Root Assessment Name': category.rootAssessmentName,
      'Is Custom Assessment Flag': assessmentTab === 1,
      'Question List': qListForMixpanel
    });
  };

  const WebLargeModal = (
    <Modal
      dialogClassName={styles.container}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onDetailHide}
      contentClassName={styles.modalContent}
    >
      <div style={{ position: 'absolute', top: 16, left: 864 }}>
        <CloseButton size={40} onClick={onDetailHide} />
      </div>
      <div className={styles.innerContainer}>
        <div className={styles.leftPane}>
          <div className={styles.assessmentName}>Assessment Name</div>
          <TextOverflow
            text={category && category.rootAssessmentName}
            textClassName={styles.assessmentTitle}
            itemId="AssessmentDetailsModal-assessmentName"
            width={370}
          />
          <div className={styles.assessmentDetails}>Assesment Details</div>

          <div className={styles.DetailsRow}>
            {assessmentTab !== 1 ? (
              <>
                <div
                  className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}
                >
                  <img className={styles.icons} src={seniorityIcon} alt="seniority" />
                  {category?.seniorityLevel === 10
                    ? 'Junior'
                    : category?.seniorityLevel === 20
                    ? 'Mid-Senior'
                    : category?.seniorityLevel === 30
                    ? 'Senior'
                    : 'Junior'}
                </div>
                <div
                  className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}
                >
                  <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
                  {category?.questionList?.length} questions
                </div>
                <div className={`${styles.DetailBox} ${styles.JustifyCenter}`}>
                  <img className={styles.icons} src={durationIcon} alt="duration" />
                  {category?.rootAssessmentTotalTime} min
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles.DetailBox} ${styles.RightBorder}`}
                  style={{ paddingLeft: '22px' }}
                >
                  <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
                  {category?.questionList?.length} questions
                </div>
                <div className={`${styles.DetailBox}`} style={{ paddingLeft: '22px' }}>
                  <img className={styles.icons} src={durationIcon} alt="duration" />
                  {category?.rootAssessmentTotalTime} minutes
                </div>
              </>
            )}
          </div>

          <div className={styles.Skills}>Skills</div>
          <div className={styles.skillsWrapper}>
            {category?.skills &&
              category.skills.map(x => (
                <div className={styles.skillItemContainer}>
                  <Tick className={styles.tick} />
                  <div className={styles.skillItem}>{x}</div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.rightPane}>
          <div className={styles.questions}>Questions</div>
          <div className={styles.questionsContainer}>
            {category &&
              questions &&
              questions?.questionList &&
              questions?.questionList?.map((x, index) => (
                <QuestionItem item={x} index={index} difficulty={x.difficulty} largeWeb />
              ))}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {assessmentTab === 1 && category.hasCompanyAssessment ? (
          <Tooltip
            text="This assessment is actively used; therefore, it cannot be edited."
            direction="top"
          >
            <CustomButton
              textField="Edit"
              isDisabled={assessmentTab === 1 && category.hasCompanyAssessment}
              loading={editButtonLoading}
              size="small"
              type={5}
              buttonOnClick={async () => {
                // dispatch(setTab(activeTab));
                setEditButtonLoading(true);
                dispatch(setAssessmentId(questions?.rootAssessmentId));
                if (assessmentTab === 1) {
                  const data = {
                    rootAssessmentId: questions?.rootAssessmentId,
                    objStatus: 4 // Draft olduğu için published değil ?
                  };
                  await dispatch(updateCustomAssessmentAction(data));
                } else {
                  nextStage();
                }
                setEditButtonLoading(false);
              }}
            />{' '}
          </Tooltip>
        ) : (
          <CustomButton
            textField="Edit"
            isDisabled={assessmentTab === 1 && category.hasCompanyAssessment}
            loading={editButtonLoading}
            size="small"
            type={5}
            buttonOnClick={async () => {
              // dispatch(setTab(activeTab));
              setEditButtonLoading(true);
              dispatch(setAssessmentId(questions?.rootAssessmentId));
              if (assessmentTab === 1) {
                // Custom Assess
                const data = {
                  rootAssessmentId: questions?.rootAssessmentId,
                  objStatus: 4 // Draft olduğu için published değil ?
                };

                /* Mixpanel Edit Button Click Event */
                mixpanel.track('Root Assessment Info Popup - Edit Button Click', {
                  'User Id': user.userId,
                  'Company Name': user.companyName,
                  'Name Surname': user.userNameSurname,
                  'Root Assessment Name': category.rootAssessmentName,
                  'Is Custom Assessment Flag': true
                });

                await dispatch(updateCustomAssessmentAction(data));
              } else {
                // Coensio Library
                nextStage();

                /* Mixpanel Edit Button Click Event */
                mixpanel.track('Root Assessment Info Popup - Edit Button Click', {
                  'User Id': user.userId,
                  'Company Name': user.companyName,
                  'Name Surname': user.userNameSurname,
                  'Root Assessment Name': category.rootAssessmentName,
                  'Is Custom Assessment Flag': false
                });
              }
              setEditButtonLoading(false);
            }}
          />
        )}
        <CustomButton
          style={{ marginLeft: 10 }}
          textField={category?.objStatusId === 1 ? 'Continue' : 'Save & Continue'}
          isDisabled={questions?.questionList?.length === 0}
          loading={continueButtonLoading}
          type={1}
          size="small"
          buttonOnClick={async () => {
            setContinueButtonLoading(true);

            /* Mixpanel Edit Button Click Event */
            mixpanelContinueButtonEvent();

            await onCreate();
            setContinueButtonLoading(false);
          }}
          tooltipText="There is no question in this assessment"
        />
      </div>
    </Modal>
  );

  const WebSmallModal = (
    <CustomModal
      onClose={onDetailHide}
      show={show}
      outsideClickClose
      centerVertically
      centerHorizontally
      contentStyle={{
        maxHeight: '90vh',
        borderRadius: '16px',
        padding: '28px 14px 28px 28px'
      }}
      size={{ width: '520px' }}
    >
      <div style={{ position: 'absolute', top: 20, right: 14 }}>
        <CloseButton width={32} height={32} onClick={onDetailHide} />
      </div>

      <div className={styles.WebSmallHeader}>
        <div className={styles.assessmentName}>Assessment Name</div>
        <TextOverflow
          text={category && category.rootAssessmentName}
          textClassName={styles.assessmentTitle}
          itemId="AssessmentDetailsModal-assessmentName"
          width={450}
        />
      </div>
      <div
        className={styles.WebSmallScrollableContent}
        style={{ maxHeight: 'calc(90vh - 56px - 62px - 16px - 36px - 16px)', paddingRight: '14px' }}
      >
        <div className={styles.WebSmallUpperContent}>
          <div className={styles.assessmentDetails}>Assesment Details</div>

          <div className={styles.DetailsRow}>
            {assessmentTab !== 1 ? (
              <>
                <div
                  className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}
                >
                  <img className={styles.icons} src={seniorityIcon} alt="seniority" />
                  {category?.seniorityLevel === 10
                    ? 'Junior'
                    : category?.seniorityLevel === 20
                    ? 'Mid-Senior'
                    : category?.seniorityLevel === 30
                    ? 'Senior'
                    : 'Junior'}
                </div>
                <div
                  className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}
                >
                  <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
                  {category?.questionList?.length} questions
                </div>
                <div className={`${styles.DetailBox} ${styles.JustifyCenter}`}>
                  <img className={styles.icons} src={durationIcon} alt="duration" />
                  {category?.rootAssessmentTotalTime} min
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles.DetailBox} ${styles.RightBorder}`}
                  style={{ paddingLeft: '22px' }}
                >
                  <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
                  {category?.questionList?.length} questions
                </div>
                <div className={`${styles.DetailBox}`} style={{ paddingLeft: '22px' }}>
                  <img className={styles.icons} src={durationIcon} alt="duration" />
                  {category?.rootAssessmentTotalTime} minutes
                </div>
              </>
            )}
          </div>

          <div className={styles.Skills}>Skills</div>
          <div className={styles.skillsWrapper}>
            {category?.skills &&
              category.skills.map(x => (
                <div className={styles.skillItemContainer}>
                  <Tick className={styles.tick} />
                  <div className={styles.skillItem}>{x}</div>
                </div>
              ))}
          </div>
        </div>

        <div className={styles.WebSmallLowerContent}>
          <div className={styles.questions}>Questions</div>
          <div className={styles.WebSmallQuestionsContainer}>
            {category &&
              questions &&
              questions?.questionList &&
              questions?.questionList?.map((x, index) => (
                <QuestionItem item={x} index={index} difficulty={x.difficulty} smallWeb />
              ))}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer} style={{ marginTop: '16px', paddingRight: '24px' }}>
        {assessmentTab === 1 && category.hasCompanyAssessment ? (
          <Tooltip
            text="This assessment is actively used; therefore, it cannot be edited."
            direction="top"
          >
            <CustomButton
              textField="Edit"
              isDisabled={assessmentTab === 1 && category.hasCompanyAssessment}
              loading={editButtonLoading}
              size="small"
              type={5}
              buttonOnClick={async () => {
                // dispatch(setTab(activeTab));
                setEditButtonLoading(true);
                dispatch(setAssessmentId(questions?.rootAssessmentId));
                if (assessmentTab === 1) {
                  const data = {
                    rootAssessmentId: questions?.rootAssessmentId,
                    objStatus: 4 // Draft olduğu için published değil ?
                  };
                  await dispatch(updateCustomAssessmentAction(data));
                } else {
                  nextStage();
                }
                setEditButtonLoading(false);
              }}
            />{' '}
          </Tooltip>
        ) : (
          <CustomButton
            textField="Edit"
            isDisabled={assessmentTab === 1 && category.hasCompanyAssessment}
            loading={editButtonLoading}
            size="small"
            type={5}
            buttonOnClick={async () => {
              // dispatch(setTab(activeTab));
              setEditButtonLoading(true);
              dispatch(setAssessmentId(questions?.rootAssessmentId));
              if (assessmentTab === 1) {
                // Custom Assess
                const data = {
                  rootAssessmentId: questions?.rootAssessmentId,
                  objStatus: 4 // Draft olduğu için published değil ?
                };

                /* Mixpanel Edit Button Click Event */
                mixpanel.track('Root Assessment Info Popup - Edit Button Click', {
                  'User Id': user.userId,
                  'Company Name': user.companyName,
                  'Name Surname': user.userNameSurname,
                  'Root Assessment Name': category.rootAssessmentName,
                  'Is Custom Assessment Flag': true
                });

                await dispatch(updateCustomAssessmentAction(data));
              } else {
                // Coensio Library
                nextStage();

                /* Mixpanel Edit Button Click Event */
                mixpanel.track('Root Assessment Info Popup - Edit Button Click', {
                  'User Id': user.userId,
                  'Company Name': user.companyName,
                  'Name Surname': user.userNameSurname,
                  'Root Assessment Name': category.rootAssessmentName,
                  'Is Custom Assessment Flag': false
                });
              }
              setEditButtonLoading(false);
            }}
          />
        )}
        <CustomButton
          style={{ marginLeft: 10 }}
          textField={category?.objStatusId === 1 ? 'Continue' : 'Save & Continue'}
          isDisabled={questions?.questionList?.length === 0}
          loading={continueButtonLoading}
          type={1}
          size="small"
          buttonOnClick={async () => {
            setContinueButtonLoading(true);

            /* Mixpanel Edit Button Click Event */
            mixpanelContinueButtonEvent();

            await onCreate();
            setContinueButtonLoading(false);
          }}
          tooltipText="There is no question in this assessment"
        />
      </div>
    </CustomModal>
  );

  const [mobileHeaderHeight, setMobileHeaderHeight] = useState();

  useEffect(() => {
    if (screenW <= 576) {
      const headerH = document.getElementById('header')?.getBoundingClientRect()?.height;
      setMobileHeaderHeight(headerH);
    }
  }, [screenW]);

  const MobileDetailModal = (
    <MobileModal
      id="mobile-rootAssessmentDetail"
      onClose={onDetailHide}
      show={show}
      outsideClickClose
      outsideTouchClose
      backdropStyle={{
        overflowY: 'hidden',
        backgroundColor: 'transparent'
      }}
      contentStyle={{
        height: `calc(100dvh - ${mobileHeaderHeight}px)`,
        padding: '20px 0px 0px 0px',
        boxShadow: '0px -2px 24px rgba(134, 134, 134, 0.15)',
        overflowY: 'hidden'
      }}
    >
      {/* {console.log(
        screenH,
        document.body.maxHeight,
        document.body.clientHeight,
        document.body.offsetHeight,
        'window HHH'
      )} */}

      <div className={styles.BackToWrapper} style={{ paddingLeft: '20px' }}>
        <img alt="backToLib" src={backToLibImg} onClick={onDetailHide} />
        <text onClick={onDetailHide}>Back to Assessment Library</text>
      </div>
      <div
        className={styles.MobileContentWrapper}
        style={{
          paddingLeft: '20px',
          overflowY: 'auto',
          height: `calc(100dvh - ${mobileHeaderHeight}px - 20px - 74px - 41px)`,
          maxHeight: `calc(100dvh - ${mobileHeaderHeight}px - 20px - 74px)`
        }}
      >
        <div className={styles.MobileUpperContent}>
          <div className={styles.assessmentName}>Assessment Name</div>
          <TextOverflow
            text={category && category.rootAssessmentName}
            textClassName={styles.assessmentTitle}
            itemId="AssessmentDetailsModal-assessmentName"
            width={330}
          />
          <div className={styles.assessmentDetails}>Assesment Details</div>

          <div className={styles.DetailsRow}>
            {assessmentTab !== 1 ? (
              <>
                <div
                  className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}
                >
                  <img className={styles.icons} src={seniorityIcon} alt="seniority" />
                  {category?.seniorityLevel === 10
                    ? 'Junior'
                    : category?.seniorityLevel === 20
                    ? 'Mid-Senior'
                    : category?.seniorityLevel === 30
                    ? 'Senior'
                    : 'Junior'}
                </div>
                <div
                  className={`${styles.DetailBox} ${styles.JustifyCenter} ${styles.RightBorder}`}
                >
                  <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
                  {category?.questionList?.length} questions
                </div>
                <div className={`${styles.DetailBox} ${styles.JustifyCenter}`}>
                  <img className={styles.icons} src={durationIcon} alt="duration" />
                  {category?.rootAssessmentTotalTime} min
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${styles.DetailBox} ${styles.RightBorder}`}
                  style={{ paddingLeft: '22px' }}
                >
                  <img className={styles.icons} src={questionCountIcon} alt="questionCount" />
                  {category?.questionList?.length} questions
                </div>
                <div className={`${styles.DetailBox}`} style={{ paddingLeft: '22px' }}>
                  <img className={styles.icons} src={durationIcon} alt="duration" />
                  {category?.rootAssessmentTotalTime} minutes
                </div>
              </>
            )}
          </div>

          <div className={styles.Skills}>Skills</div>
          <div className={styles.skillsWrapper}>
            {category?.skills &&
              category.skills.map(x => (
                <div className={styles.skillItemContainer}>
                  <Tick className={styles.tick} />
                  <div className={styles.skillItem}>{x}</div>
                </div>
              ))}
          </div>
        </div>

        <div className={styles.MobileLowerContent}>
          <div className={styles.questions}>Questions</div>
          <div className={styles.MobileQuestionsContainer}>
            {category &&
              questions &&
              questions?.questionList &&
              questions?.questionList?.map((x, index) => (
                <QuestionItem item={x} index={index} difficulty={x.difficulty} mobile />
              ))}
          </div>
        </div>
      </div>
      <div className={styles.MobileButtonWrapper} style={{ height: '74px' }}>
        <CustomButton
          textField={category?.objStatusId === 1 ? 'Create' : 'Save & Create'}
          isDisabled={questions?.questionList?.length === 0}
          loading={continueButtonLoading}
          type={1}
          size="mobile"
          buttonOnClick={async () => {
            setContinueButtonLoading(true);

            /* Mixpanel Edit Button Click Event */
            mixpanelContinueButtonEvent();

            await onCreate();
            setContinueButtonLoading(false);
          }}
        />
      </div>
    </MobileModal>
  );

  return screenW < 914 ? (screenW < 576 ? MobileDetailModal : WebSmallModal) : WebLargeModal;
}
