const C = 'c';
const CPP = 'cpp';
const CSHARP = 'csharp';
const JAVA = 'java';
const JAVASCRIPT = 'javascript';
const KOTLIN = 'kotlin';
const PYTHON = 'python';
const SWIFT = 'swift';
const PHP = 'php';
const GO = 'go';
const SCALA = 'scala'
const TYPESCRIPT = 'typescript'

const SQL = 'sql';

export const languages = [
  // TODO: Extend this list
  // Whenever you add a language, add its variable types and put it into variableTypeDict
  // And generate its initial code
  { value: C, label: 'C' },
  { value: CPP, label: 'C++' },
  { value: CSHARP, label: 'C#' },
  { value: JAVA, label: 'Java' },
  { value: JAVASCRIPT, label: 'Javascript' },
  { value: KOTLIN, label: 'Kotlin' },
  { value: PYTHON, label: 'Python3' },
  { value: SWIFT, label: 'Swift' },
  { value: PHP, label: 'PHP' },
  { value: GO, label: 'Go' },
  { value: SCALA, label: 'Scala' },
  { value: TYPESCRIPT,label: 'Typescript' },
];

// Python, Java, C++, C#, Kotlin, Swift, PHP
export const defaultVariableTypes = [
  { value: 'String', label: 'String' },
  { value: 'Integer', label: 'Integer' },
  { value: 'Float', label: 'Float' },
  { value: 'Boolean', label: 'Boolean' },
  { value: 'Array<String>', label: 'Array<String>' },
  { value: 'Array<Integer>', label: 'Array<Integer>' },
  { value: 'Array<Float>', label: 'Array<Float>' },
  { value: 'Array<Boolean>', label: 'Array<Boolean>' }
];

// Javascript
export const javascriptVariableTypes = [
  { value: 'String', label: 'String' },
  { value: 'Integer', label: 'Integer' }, // In JS there is Number type instead of Integer, but it does not affect anything at the moment
  { value: 'Boolean', label: 'Boolean' },
  { value: 'Array<String>', label: 'Array<String>' },
  { value: 'Array<Integer>', label: 'Array<Integer>' },
  { value: 'Array<Float>', label: 'Array<Float>' },
  { value: 'Array<Boolean>', label: 'Array<Boolean>' }
];

// Java, C++, C#, Kotlin, Swift
// export const javaCppCsharpVariableTypes = [
//     { value: 'String', label: 'String' },
//     { value: 'Integer', label: 'Integer' },
//     { value: 'Float', label: 'Float' },
//     { value: 'Boolean', label: 'Boolean' },
//     { value: 'Array<String>', label: 'Array<String>' },
//     { value: 'Array<Integer>', label: 'Array<Integer>' },
//     { value: 'Array<Float>', label: 'Array<Float>' },
//     { value: 'Array<Boolean>', label: 'Array<Boolean>' }
// ];

// C
export const cVariableTypes = [
  { value: 'String', label: 'String' },
  { value: 'Integer', label: 'Integer' },
  { value: 'Float', label: 'Float' },
  { value: 'Boolean', label: 'Boolean' },
  { value: 'Array<String>', label: 'Array<String>' },
  { value: 'Array<Integer>', label: 'Array<Integer>' },
  { value: 'Array<Float>', label: 'Array<Float>' },
  { value: 'Array<Boolean>', label: 'Array<Boolean>' }
];

export const variableTypeDict = {
  [PYTHON]: defaultVariableTypes,
  [C]: cVariableTypes,
  [CPP]: defaultVariableTypes,
  [CSHARP]: defaultVariableTypes,
  [JAVA]: defaultVariableTypes,
  [JAVASCRIPT]: defaultVariableTypes,
  [KOTLIN]: defaultVariableTypes,
  [SWIFT]: defaultVariableTypes,
  [PHP]: defaultVariableTypes,
  [GO]: defaultVariableTypes,
  [SCALA]: defaultVariableTypes,
  [TYPESCRIPT]: defaultVariableTypes,
};

export const initialCodeGeneration = (outputVariable, parameters) => {
  let baseStr = '';
  const initialCodeDict = {};

  // Python
  baseStr = 'class Solution:\n\t# For helper functions, call them as Solution.helperMethod\n\t';
  baseStr = baseStr.concat('def ');
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(self, ');
  for (let index = 0; index < parameters.length; index += 1) {
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat('):\n\n\t\t');
  baseStr = baseStr.concat('# Insert your code here');

  initialCodeDict[PYTHON] = baseStr;

  // Java
  baseStr = 'class Solution {\n';
  baseStr = baseStr.concat('\tpublic ');
  switch (outputVariable.type) {
    case 'Array<String>':
      baseStr = baseStr.concat('String[]');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat('int[]');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat('double[]');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat('boolean[]');
      break;
    case 'Integer':
      baseStr = baseStr.concat('int');
      break;
    case 'Float':
      baseStr = baseStr.concat('double');
      break;
    case 'Boolean':
      baseStr = baseStr.concat('boolean');
      break;
    default:
      baseStr = baseStr.concat(outputVariable.type);
      break;
  }
  baseStr = baseStr.concat(' ');
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');
  for (let index = 0; index < parameters.length; index += 1) {
    switch (parameters[index].type) {
      case 'Array<String>':
        baseStr = baseStr.concat('String[]');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat('int[]');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat('double[]');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat('boolean[]');
        break;
      case 'Integer':
        baseStr = baseStr.concat('int');
        break;
      case 'Float':
        baseStr = baseStr.concat('double');
        break;
      case 'Boolean':
        baseStr = baseStr.concat('boolean');
        break;
      case '':
        baseStr = baseStr.concat('undefined');
        break;
      default:
        baseStr = baseStr.concat(parameters[index].type);
        break;
    }
    baseStr = baseStr.concat(' ');
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') {\n\n\t\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n\t}');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[JAVA] = baseStr;

  // Javascript
  baseStr = 'var ';
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat(' = function(');
  for (let index = 0; index < parameters.length; index += 1) {
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') {\n\n\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n};');

  initialCodeDict[JAVASCRIPT] = baseStr;

  // C
	baseStr = `/**
	* Note: The returned array must be malloced, assume caller calls free().
	*/`;
  baseStr = baseStr.concat('\n#include <stdio.h>\n#include <stdbool.h>\n#include <string.h>\n#include <stdlib.h>\n\n');
  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat('char * ');
      break;
    case 'Integer':
      baseStr = baseStr.concat('int ');
      break;
    case 'Float':
      baseStr = baseStr.concat('double ');
      break;
    case 'Boolean':
      baseStr = baseStr.concat('bool ');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat('char ** '); // TODO: fix this
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat('int * ');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat('double * ');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat('bool * ');
      break;
    default:
      break;
  }
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');

  // Count of the number of array parameters
  let arrCount = 0;
  for (let index = 0; index < parameters.length; index += 1) {
    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat('char * ');
        break;
      case 'Integer':
        baseStr = baseStr.concat('int ');
        break;
      case 'Float':
        baseStr = baseStr.concat('double ');
        break;
      case 'Boolean':
        baseStr = baseStr.concat('bool ');
        break;
      case 'Array<String>':
        arrCount += 1;
        baseStr = baseStr.concat('char ** '); // TODO: fix this
        break;
      case 'Array<Integer>':
        arrCount += 1;
        baseStr = baseStr.concat('int * ');
        break;
      case 'Array<Float>':
        arrCount += 1;
        baseStr = baseStr.concat('double * ');
        break;
      case 'Array<Boolean>':
        arrCount += 1;
        baseStr = baseStr.concat('bool * ');
        break;
      default:
        baseStr = baseStr.concat('undefined ');
        break;
    }
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }

    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }

  // For each array, add a parameter that represents its size
  for (let index = 0; index < arrCount; index += 1) {
    baseStr = baseStr.concat(', int sizeOfArray');
    baseStr = baseStr.concat(index + 1);
  }

  baseStr = baseStr.concat(') {\n\n\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[C] = baseStr;

  // C++
  baseStr = '';
  // baseStr = baseStr.concat('using namespace std;\n\n');
  baseStr = baseStr.concat('class Solution {\n');
  baseStr = baseStr.concat('public:\n\t');
  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat('string ');
      break;
    case 'Integer':
      baseStr = baseStr.concat('int ');
      break;
    case 'Float':
      baseStr = baseStr.concat('double ');
      break;
    case 'Boolean':
      baseStr = baseStr.concat('bool ');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat('vector<string> ');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat('vector<int> ');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat('vector<double> ');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat('vector<bool> ');
      break;
    default:
      break;
  }
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');
  for (let index = 0; index < parameters.length; index += 1) {
    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat('string ');
        break;
      case 'Integer':
        baseStr = baseStr.concat('int ');
        break;
      case 'Float':
        baseStr = baseStr.concat('double ');
        break;
      case 'Boolean':
        baseStr = baseStr.concat('bool ');
        break;
      case 'Array<String>':
        baseStr = baseStr.concat('vector<string> ');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat('vector<int> ');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat('vector<double> ');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat('vector<bool> ');
        break;
      default:
        baseStr = baseStr.concat('undefined ');
        break;
    }
    // baseStr = baseStr.concat(parameters[index].type);
    // baseStr = baseStr.concat(' ');
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') {\n\n\t\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n\t}');
  baseStr = baseStr.concat('\n};');

  initialCodeDict[CPP] = baseStr;

  // C#
  baseStr = 'using System;\n';
  baseStr = baseStr.concat('using System.Linq;\n\n');
  // baseStr = baseStr.concat('namespace TestTaker\n{\n\t');
  baseStr = baseStr.concat('public class Solution\n{\n\t');
  baseStr = baseStr.concat('public ');

  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat('string ');
      break;
    case 'Integer':
      baseStr = baseStr.concat('int ');
      break;
    case 'Float':
      baseStr = baseStr.concat('double ');
      break;
    case 'Boolean':
      baseStr = baseStr.concat('bool ');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat('string[] ');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat('int[] ');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat('double[] ');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat('bool[] ');
      break;
    default:
      break;
  }
  baseStr = baseStr.concat(outputVariable.name);
  baseStr = baseStr.concat('(');
  for (let index = 0; index < parameters.length; index += 1) {
    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat('string ');
        break;
      case 'Integer':
        baseStr = baseStr.concat('int ');
        break;
      case 'Float':
        baseStr = baseStr.concat('double ');
        break;
      case 'Boolean':
        baseStr = baseStr.concat('bool ');
        break;
      case 'Array<String>':
        baseStr = baseStr.concat('string[] ');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat('int[] ');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat('double[] ');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat('bool[] ');
        break;
      default:
        baseStr = baseStr.concat('undefined ');
        break;
    }
    // baseStr = baseStr.concat(parameters[index].type);
    // baseStr = baseStr.concat(' ');
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') {\n\n\t\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n\t}');
  // baseStr = baseStr.concat('\n}');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[CSHARP] = baseStr;

  // Kotlin
  baseStr = 'class Solution {\n';
  baseStr = baseStr.concat('\tconstructor(){} \n');
  baseStr = baseStr.concat('\t// We currently only support Kotlin 1.3.70, please keep that in mind! \n');
  baseStr = baseStr.concat('\tfun ');
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');

  for (let index = 0; index < parameters.length; index += 1) {
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    baseStr = baseStr.concat(': ');

    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat('String');
        break;
      case 'Integer':
        baseStr = baseStr.concat('Int');
        break;
      case 'Float':
        baseStr = baseStr.concat('Double');
        break;
      case 'Boolean':
        baseStr = baseStr.concat('Boolean');
        break;
      case 'Array<String>':
        baseStr = baseStr.concat('Array<String>');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat('Array<Int>');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat('Array<Double>');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat('Array<Boolean>');
        break;
      default:
        baseStr = baseStr.concat('undefined');
        break;
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat('): ');

  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat('String');
      break;
    case 'Integer':
      baseStr = baseStr.concat('Int');
      break;
    case 'Float':
      baseStr = baseStr.concat('Double');
      break;
    case 'Boolean':
      baseStr = baseStr.concat('Boolean');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat('Array<String>');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat('Array<Int>');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat('Array<Double>');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat('Array<Boolean>');
      break;
    default:
      break;
  }
  baseStr = baseStr.concat('{\n\n\t ');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n\t}');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[KOTLIN] = baseStr;

  // Swift
  baseStr = 'func ';
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');

  for (let index = 0; index < parameters.length; index += 1) {
    baseStr = baseStr.concat('_ ');
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    baseStr = baseStr.concat(':');

    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat('String');
        break;
      case 'Integer':
        baseStr = baseStr.concat('Int');
        break;
      case 'Float':
        baseStr = baseStr.concat('Double');
        break;
      case 'Boolean':
        baseStr = baseStr.concat('Bool');
        break;
      case 'Array<String>':
        baseStr = baseStr.concat('[String]');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat('[Int]');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat('[Double]');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat('[Bool]');
        break;
      default:
        baseStr = baseStr.concat('undefined');
        break;
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(',');
    }
  }
  baseStr = baseStr.concat(') -> ');

  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat('String');
      break;
    case 'Integer':
      baseStr = baseStr.concat('Int');
      break;
    case 'Float':
      baseStr = baseStr.concat('Double');
      break;
    case 'Boolean':
      baseStr = baseStr.concat('Bool');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat('[String]');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat('[Int]');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat('[Double]');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat('[Bool]');
      break;
    default:
      baseStr = baseStr.concat('undefined');
      break;
  }
  baseStr = baseStr.concat(' {\n\n\t ');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[SWIFT] = baseStr;

  // PHP
  baseStr = '<?php ';
  baseStr = baseStr.concat('class Solution {\n');
  baseStr = baseStr.concat('\tfunction ');

  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');
  for (let index = 0; index < parameters.length; index += 1) {
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat('$');
      baseStr = baseStr.concat(parameters[index].name);
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') {\n\n\t\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n\t}');
  baseStr = baseStr.concat('\n}');
  baseStr = baseStr.concat('\n?>');

  initialCodeDict[PHP] = baseStr;

  // Go
  baseStr = '';
  baseStr = baseStr.concat('func ');
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');

  for (let index = 0; index < parameters.length; index += 1) {
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    baseStr = baseStr.concat(' ');

    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat('string');
        break;
      case 'Integer':
        baseStr = baseStr.concat('int');
        break;
      case 'Float':
        baseStr = baseStr.concat('float64');
        break;
      case 'Boolean':
        baseStr = baseStr.concat('bool');
        break;
      case 'Array<String>':
        baseStr = baseStr.concat('[]string');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat('[]int');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat('[]float64');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat('[]bool');
        break;
      default:
        baseStr = baseStr.concat('undefined');
        break;
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') ');

  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat('string');
      break;
    case 'Integer':
      baseStr = baseStr.concat('int');
      break;
    case 'Float':
      baseStr = baseStr.concat('float64');
      break;
    case 'Boolean':
      baseStr = baseStr.concat('bool');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat('[]string');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat('[]int');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat('[]float64');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat('[]bool');
      break;
    default:
      break;
  }
  baseStr = baseStr.concat(' {\n\n\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[GO] = baseStr;


  // Scala
  baseStr = '';
  baseStr = baseStr.concat('class Solution {\n\t');
  baseStr = baseStr.concat('def ');
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');

  for (let index = 0; index < parameters.length; index += 1) {
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }
    baseStr = baseStr.concat(' ');

    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat(': String');
        break;
      case 'Integer':
        baseStr = baseStr.concat(': Int');
        break;
      case 'Float':
        baseStr = baseStr.concat(': Double');
        break;
      case 'Boolean':
        baseStr = baseStr.concat(': Boolean');
        break;
      case 'Array<String>':
        baseStr = baseStr.concat(': Array[String]');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat(': Array[Int]');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat(': Array[Double]');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat(': Array[Boolean]');
        break;
      default:
        baseStr = baseStr.concat(': undefined');
        break;
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') ');

  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat(': String');
      break;
    case 'Integer':
      baseStr = baseStr.concat(': Int');
      break;
    case 'Float':
      baseStr = baseStr.concat(': Double');
      break;
    case 'Boolean':
      baseStr = baseStr.concat(': Boolean');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat(': Array[String]');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat(': Array[Int]');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat(': Array[Double]');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat(': Array[Boolean]');
      break;
    default:
      break;
  }
  baseStr = baseStr.concat(' = {\n\n\t\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n\t}');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[SCALA] = baseStr;


  // Typescript
  baseStr = 'function ';
  if (outputVariable.name === '') {
    baseStr = baseStr.concat('undefined');
  } else {
    baseStr = baseStr.concat(outputVariable.name);
  }
  baseStr = baseStr.concat('(');

  for (let index = 0; index < parameters.length; index += 1) {
    if (parameters[index].name === '') {
      baseStr = baseStr.concat('undefined');
    } else {
      baseStr = baseStr.concat(parameters[index].name);
    }

    switch (parameters[index].type) {
      case 'String':
        baseStr = baseStr.concat(':string');
        break;
      case 'Integer':
        baseStr = baseStr.concat(':number');
        break;
      case 'Float':
        baseStr = baseStr.concat(':number');
        break;
      case 'Boolean':
        baseStr = baseStr.concat(':boolean');
        break;
      case 'Array<String>':
        baseStr = baseStr.concat(':string[]');
        break;
      case 'Array<Integer>':
        baseStr = baseStr.concat(':number[]');
        break;
      case 'Array<Float>':
        baseStr = baseStr.concat(':number[]');
        break;
      case 'Array<Boolean>':
        baseStr = baseStr.concat(':boolean[]');
        break;
      default:
        baseStr = baseStr.concat(':undefined');
        break;
    }
    if (index !== parameters.length - 1) {
      baseStr = baseStr.concat(', ');
    }
  }
  baseStr = baseStr.concat(') ');

  switch (outputVariable.type) {
    case 'String':
      baseStr = baseStr.concat(': string');
      break;
    case 'Integer':
      baseStr = baseStr.concat(': number');
      break;
    case 'Float':
      baseStr = baseStr.concat(': number');
      break;
    case 'Boolean':
      baseStr = baseStr.concat(': boolean');
      break;
    case 'Array<String>':
      baseStr = baseStr.concat(': string[]');
      break;
    case 'Array<Integer>':
      baseStr = baseStr.concat(': number[]');
      break;
    case 'Array<Float>':
      baseStr = baseStr.concat(': number[]');
      break;
    case 'Array<Boolean>':
      baseStr = baseStr.concat(': boolean[]');
      break;
    default:
      break;
  }
  baseStr = baseStr.concat(' {\n\n\t');
  baseStr = baseStr.concat('// Insert your code here');
  baseStr = baseStr.concat('\n}');

  initialCodeDict[TYPESCRIPT] = baseStr;

  return initialCodeDict;
};

export const apiFormatterForOutput = (casesList, selectedLanguage, outputVariable) => {
  const weightsArray = [];
  const inputsArrayRaw = [];
  const outputsArray = [];

  casesList.forEach(e => {
    weightsArray.push(e.weight);
    inputsArrayRaw.push(e.input);
    let innerResult = '';

    switch (selectedLanguage) {
      case CSHARP:
        if (outputVariable.type.includes('Array')) {
          innerResult = innerResult.concat('new ');
          if (outputVariable.type.includes('Integer')) {
            innerResult = innerResult.concat('int');
          }
          if (outputVariable.type.includes('String')) {
            innerResult = innerResult.concat('string');
          }
          if (outputVariable.type.includes('Float')) {
            innerResult = innerResult.concat('double');
          }
          if (outputVariable.type.includes('Boolean')) {
            innerResult = innerResult.concat('bool');
          }
          innerResult = innerResult.concat('[]{');
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            innerInp = innerInp.replace('[', '');
            innerInp = innerInp.replace(']', '');
            innerResult = innerResult.concat(innerInp);
          } else {
            innerResult = innerResult.concat(e.result);
          }
          innerResult = innerResult.concat('}');
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case JAVA:
        if (outputVariable.type.includes('Array')) {
          innerResult = innerResult.concat('new ');
          if (outputVariable.type.includes('Integer')) {
            innerResult = innerResult.concat('int');
          }
          if (outputVariable.type.includes('String')) {
            innerResult = innerResult.concat('String');
          }
          if (outputVariable.type.includes('Float')) {
            innerResult = innerResult.concat('double');
          }
          if (outputVariable.type.includes('Boolean')) {
            innerResult = innerResult.concat('boolean');
          }
          innerResult = innerResult.concat('[]{');
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            innerInp = innerInp.replace('[', '');
            innerInp = innerInp.replace(']', '');
            innerResult = innerResult.concat(innerInp);
          } else {
            innerResult = innerResult.concat(e.result);
          }
          innerResult = innerResult.concat('}');
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case JAVASCRIPT:
        if (outputVariable.type.includes('Array')) {
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            const innerInp = e.result;
            innerResult = innerResult.concat(innerInp);
          } else {
            let innerInp = '[';
            innerInp = innerInp.concat(e.result);
            innerInp = innerInp.concat(']');
            innerResult = innerResult.concat(innerInp);
          }
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case SWIFT:
        if (outputVariable.type.includes('Array')) {
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            const innerInp = e.result;
            innerResult = innerResult.concat(innerInp);
          } else {
            let innerInp = '[';
            innerInp = innerInp.concat(e.result);
            innerInp = innerInp.concat(']');
            innerResult = innerResult.concat(innerInp);
          }
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case PYTHON:
        if (outputVariable.type === 'Boolean') {
          let innerInp = e.result;
          innerInp = innerInp.replaceAll('true', 'True');
          innerInp = innerInp.replaceAll('false', 'False');
          innerResult = innerResult.concat(innerInp);
        } else if (outputVariable.type.includes('Array')) {
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            if (outputVariable.type.includes('Boolean')) {
              innerInp = innerInp.replaceAll('true', 'True');
              innerInp = innerInp.replaceAll('false', 'False');
            }
            innerResult = innerResult.concat(innerInp);
          } else {
            let innerInp = '[';
            let modifiedBool = e.result;
            if (outputVariable.type.includes('Boolean')) {
              modifiedBool = modifiedBool.replaceAll('true', 'True');
              modifiedBool = modifiedBool.replaceAll('false', 'False');
            }
            innerInp = innerInp.concat(modifiedBool);
            innerInp = innerInp.concat(']');
            innerResult = innerResult.concat(innerInp);
          }
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case KOTLIN:
        if (outputVariable.type.includes('Array')) {
          innerResult = innerResult.concat('arrayOf(');
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            innerInp = innerInp.replace('[', '');
            innerInp = innerInp.replace(']', '');
            innerResult = innerResult.concat(innerInp);
          } else {
            innerResult = innerResult.concat(e.result);
          }
          innerResult = innerResult.concat(')');
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case PHP:
        if (outputVariable.type.includes('Array')) {
          innerResult = innerResult.concat('array(');
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            innerInp = innerInp.replace('[', '');
            innerInp = innerInp.replace(']', '');
            innerResult = innerResult.concat(innerInp);
          } else {
            innerResult = innerResult.concat(e.result);
          }
          innerResult = innerResult.concat(')');
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case GO:
        if (outputVariable.type.includes('Array')) {
          innerResult = innerResult.concat('[]');
          if (outputVariable.type.includes('Integer')) {
            innerResult = innerResult.concat('int');
          }
          if (outputVariable.type.includes('String')) {
            innerResult = innerResult.concat('string');
          }
          if (outputVariable.type.includes('Float')) {
            innerResult = innerResult.concat('float64');
          }
          if (outputVariable.type.includes('Boolean')) {
            innerResult = innerResult.concat('bool');
          }
          innerResult = innerResult.concat('{');
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            innerInp = innerInp.replace('[', '');
            innerInp = innerInp.replace(']', '');
            innerResult = innerResult.concat(innerInp);
          } else {
            innerResult = innerResult.concat(e.result);
          }
          innerResult = innerResult.concat('}');
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case CPP:
        if (outputVariable.type.includes('Array')) {
          innerResult = innerResult.concat('vector<');
          if (outputVariable.type.includes('Integer')) {
            innerResult = innerResult.concat('int');
          }
          if (outputVariable.type.includes('String')) {
            innerResult = innerResult.concat('string');
          }
          if (outputVariable.type.includes('Float')) {
            innerResult = innerResult.concat('double');
          }
          if (outputVariable.type.includes('Boolean')) {
            innerResult = innerResult.concat('bool');
          }
          innerResult = innerResult.concat('>{');
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            innerInp = innerInp.replace('[', '');
            innerInp = innerInp.replace(']', '');
            innerResult = innerResult.concat(innerInp);
          } else {
            innerResult = innerResult.concat(e.result);
          }
          innerResult = innerResult.concat('}');
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case C:
        // if (outputVariable.type.includes('Array')) {
        //   // innerResult = innerResult.concat("")
        //   if (outputVariable.type.includes('Integer')) {
        //     innerResult = innerResult.concat('int ');
        //   }
        //   if (outputVariable.type.includes('String')) {
        //     innerResult = innerResult.concat('char* ');
        //   }
        //   if (outputVariable.type.includes('Float')) {
        //     innerResult = innerResult.concat('float ');
        //   }
        //   if (outputVariable.type.includes('Boolean')) {
        //     innerResult = innerResult.concat('bool ');
        //   }
        //   innerResult = innerResult.concat(outputVariable.name);
        //   innerResult = innerResult.concat('[] = {');
        //   if (e.result.startsWith('[') && e.result.endsWith(']')) {
        //     let innerInp = e.result;
        //     innerInp = innerInp.replace('[', '');
        //     innerInp = innerInp.replace(']', '');
        //     innerResult = innerResult.concat(innerInp);
        //   } else {
        //     innerResult = innerResult.concat(e.result);
        //   }
        //   innerResult = innerResult.concat('}');
        // } else {
        //   innerResult = innerResult.concat(e.result);
        // }
				if (outputVariable.type.includes('Array')) {
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            const innerInp = e.result;
            innerResult = innerResult.concat(innerInp);
          } else {
            let innerInp = '[';
            innerInp = innerInp.concat(e.result);
            innerInp = innerInp.concat(']');
            innerResult = innerResult.concat(innerInp);
          }
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case SCALA:
        if (outputVariable.type.includes('Array')) {
          innerResult = innerResult.concat('Array');
          if (outputVariable.type.includes('Integer')) {
            innerResult = innerResult.concat('[Int]');
          }
          if (outputVariable.type.includes('String')) {
            innerResult = innerResult.concat('[String]');
          }
          if (outputVariable.type.includes('Float')) {
            innerResult = innerResult.concat('[Double]');
          }
          if (outputVariable.type.includes('Boolean')) {
            innerResult = innerResult.concat('[Boolean]');
          }
          innerResult = innerResult.concat('(');
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            let innerInp = e.result;
            innerInp = innerInp.replace('[', '');
            innerInp = innerInp.replace(']', '');
            innerResult = innerResult.concat(innerInp);
          } else {
            innerResult = innerResult.concat(e.result);
          }
          innerResult = innerResult.concat(')');
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      case TYPESCRIPT:
        if (outputVariable.type.includes('Array')) {
          if (e.result.startsWith('[') && e.result.endsWith(']')) {
            const innerInp = e.result;
            innerResult = innerResult.concat(innerInp);
          } else {
            let innerInp = '[';
            innerInp = innerInp.concat(e.result);
            innerInp = innerInp.concat(']');
            innerResult = innerResult.concat(innerInp);
          }
        } else {
          innerResult = innerResult.concat(e.result);
        }
        break;

      default:
        break;
    }
    outputsArray.push(innerResult);
  });

  return { weightsArray, inputsArrayRaw, outputsArray };
};

export const apiFormatterForInputs = (
  inputsArrayRaw,
  selectedLanguage,
  outputVariable,
  parameters
) => {
  const inputsArray = [];

  inputsArrayRaw.forEach((e, index) => {
    let inputs = outputVariable.name;
    inputs = inputs.concat('(');

    // Array for array sizes in C
    const arrSizesArray = [];

    e.forEach((inp, inputIndex) => {
      switch (selectedLanguage) {
        case CSHARP:
          if (parameters[inputIndex].type.includes('Array')) {
            inputs = inputs.concat('new ');
            if (parameters[inputIndex].type.includes('Integer')) {
              inputs = inputs.concat('int');
            }
            if (parameters[inputIndex].type.includes('String')) {
              inputs = inputs.concat('string');
            }
            if (parameters[inputIndex].type.includes('Float')) {
              inputs = inputs.concat('double');
            }
            if (parameters[inputIndex].type.includes('Boolean')) {
              inputs = inputs.concat('bool');
            }
            inputs = inputs.concat('[]{');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);
            } else {
              inputs = inputs.concat(inp);
            }
            inputs = inputs.concat('}');
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
          break;

        case JAVA:
					if (parameters[inputIndex].type.includes('Array')) {
            inputs = inputs.concat('new ');
            if (parameters[inputIndex].type.includes('Integer')) {
              inputs = inputs.concat('int');
            }
            if (parameters[inputIndex].type.includes('String')) {
              inputs = inputs.concat('String');
            }
            if (parameters[inputIndex].type.includes('Float')) {
              inputs = inputs.concat('double');
            }
            if (parameters[inputIndex].type.includes('Boolean')) {
              inputs = inputs.concat('boolean');
            }
            inputs = inputs.concat('[]{');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);
            } else {
              inputs = inputs.concat(inp);
            }
            inputs = inputs.concat('}');
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
          break;

				case JAVASCRIPT:
					if (parameters[inputIndex].type.includes('Array')) {
            if (inp.startsWith('[') && inp.endsWith(']')) {
              const innerInp = inp;
              inputs = inputs.concat(innerInp);
            } else {
              let innerInp = '[';
              innerInp = innerInp.concat(inp);
              innerInp = innerInp.concat(']');
              inputs = inputs.concat(innerInp);
            }
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
				break;

				case SWIFT:
					if (parameters[inputIndex].type.includes('Array')) {
            if (inp.startsWith('[') && inp.endsWith(']')) {
              const innerInp = inp;
              inputs = inputs.concat(innerInp);
            } else {
              let innerInp = '[';
              innerInp = innerInp.concat(inp);
              innerInp = innerInp.concat(']');
              inputs = inputs.concat(innerInp);
            }
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
				break;
				
				case PYTHON:
					if (parameters[inputIndex].type === 'Boolean') {
            let innerInp = inp;
            innerInp = innerInp.replaceAll('true', 'True');
            innerInp = innerInp.replaceAll('false', 'False');
            inputs = inputs.concat(innerInp);
          } else if (parameters[inputIndex].type.includes('Array')) {
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              if (parameters[inputIndex].type.includes('Boolean')) {
                innerInp = innerInp.replaceAll('true', 'True');
                innerInp = innerInp.replaceAll('false', 'False');
              }
              inputs = inputs.concat(innerInp);
            } else {
              let innerInp = '[';
              let modifiedBool = inp;
              if (parameters[inputIndex].type.includes('Boolean')) {
                modifiedBool = modifiedBool.replaceAll('true', 'True');
                modifiedBool = modifiedBool.replaceAll('false', 'False');
              }
              innerInp = innerInp.concat(modifiedBool);
              innerInp = innerInp.concat(']');
              inputs = inputs.concat(innerInp);
            }
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
					break;

				case KOTLIN:
					if (parameters[inputIndex].type.includes('Array')) {
            inputs = inputs.concat('arrayOf(');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);
            } else {
              const innerInp = inp;
              inputs = inputs.concat(innerInp);
            }
            inputs = inputs.concat(')');
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
					break;

				case PHP:
					if (parameters[inputIndex].type.includes('Array')) {
            inputs = inputs.concat('array(');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);
            } else {
              const innerInp = inp;
              inputs = inputs.concat(innerInp);
            }
            inputs = inputs.concat(')');
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
					break;

				case GO:
					if (parameters[inputIndex].type.includes('Array')) {
            inputs = inputs.concat('[]');
            if (parameters[inputIndex].type.includes('Integer')) {
              inputs = inputs.concat('int');
            }
            if (parameters[inputIndex].type.includes('String')) {
              inputs = inputs.concat('string');
            }
            if (parameters[inputIndex].type.includes('Float')) {
              inputs = inputs.concat('float64');
            }
            if (parameters[inputIndex].type.includes('Boolean')) {
              inputs = inputs.concat('bool');
            }
            inputs = inputs.concat('{');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);
            } else {
              inputs = inputs.concat(inp);
            }
            inputs = inputs.concat('}');
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
					break;
				
				case CPP:
					if (inputIndex === 0 && JSON.stringify(parameters).includes('Array')) {
            inputs = inputs.concat(',, ');
          }

          if (parameters[inputIndex].type.includes('Array')) {
            inputs = inputs.concat('vector<');
            if (parameters[inputIndex].type.includes('Integer')) {
              inputs = inputs.concat('int');
            }
            if (parameters[inputIndex].type.includes('String')) {
              inputs = inputs.concat('string');
            }
            if (parameters[inputIndex].type.includes('Float')) {
              inputs = inputs.concat('double');
            }
            if (parameters[inputIndex].type.includes('Boolean')) {
              inputs = inputs.concat('bool');
            }
            inputs = inputs.concat('>{');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);
            } else {
              inputs = inputs.concat(inp);
            }
            inputs = inputs.concat('}');
          } else {
            inputs = inputs.concat(inp);
          }

          if (JSON.stringify(parameters).includes('Array')) {
            if (inputIndex !== e.length) {
              inputs = inputs.concat(',, ');
            }
          } else if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
					break;

				case C:
					if (inputIndex === 0 && JSON.stringify(parameters).includes('Array')) {
            inputs = inputs.concat(',,');
          }

          if (parameters[inputIndex].type.includes('Array')) {
            if (parameters[inputIndex].type.includes('Integer')) {
              inputs = inputs.concat('int ');
            }
            if (parameters[inputIndex].type.includes('String')) {
              inputs = inputs.concat('char* ');
            }
            if (parameters[inputIndex].type.includes('Float')) {
              inputs = inputs.concat('double ');
            }
            if (parameters[inputIndex].type.includes('Boolean')) {
              inputs = inputs.concat('bool ');
            }
            inputs = inputs.concat(parameters[inputIndex].name);
            inputs = inputs.concat(index);
            inputs = inputs.concat('[] = {');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);

              // Store array size
              if (innerInp === '') {
                arrSizesArray.push(0);
              } else {
                const innerArraySize = innerInp.split(",").length;
                arrSizesArray.push(innerArraySize);
              }

            } else {
              inputs = inputs.concat(inp);

              // Store array size
              if (inp === '') {
                arrSizesArray.push(0);
              } else {
                const innerArraySize = inp.split(",").length;
                arrSizesArray.push(innerArraySize);
              }
              
            }

            
            inputs = inputs.concat('}');
          } else {
            inputs = inputs.concat(inp);
          }

          if (JSON.stringify(parameters).includes('Array')) {
            if (inputIndex !== e.length) {
              inputs = inputs.concat(',,');
            }
          } else if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
					break;
					
        case SCALA:
          if (parameters[inputIndex].type.includes('Array')) {
            inputs = inputs.concat('Array');
            if (parameters[inputIndex].type.includes('Integer')) {
              inputs = inputs.concat('[Int]');
            }
            if (parameters[inputIndex].type.includes('String')) {
              inputs = inputs.concat('[String]');
            }
            if (parameters[inputIndex].type.includes('Float')) {
              inputs = inputs.concat('[Double]');
            }
            if (parameters[inputIndex].type.includes('Boolean')) {
              inputs = inputs.concat('[Boolean]');
            }
            inputs = inputs.concat('(');
            if (inp.startsWith('[') && inp.endsWith(']')) {
              let innerInp = inp;
              innerInp = innerInp.replace('[', '');
              innerInp = innerInp.replace(']', '');
              inputs = inputs.concat(innerInp);
            } else {
              inputs = inputs.concat(inp);
            }
            inputs = inputs.concat(')');
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
          break;

        case TYPESCRIPT:
          if (parameters[inputIndex].type.includes('Array')) {
            if (inp.startsWith('[') && inp.endsWith(']')) {
              const innerInp = inp;
              inputs = inputs.concat(innerInp);
            } else {
              let innerInp = '[';
              innerInp = innerInp.concat(inp);
              innerInp = innerInp.concat(']');
              inputs = inputs.concat(innerInp);
            }
          } else {
            inputs = inputs.concat(inp);
          }

          if (inputIndex !== e.length - 1) {
            inputs = inputs.concat(', ');
          }
          break;

					default:
          break;
      }
    });

    // Append array sizes at the end of function
    if (selectedLanguage === C) {
      arrSizesArray.forEach(e => {
        inputs = inputs.concat(e)
        inputs = inputs.concat(",,")
      })
    }

    inputs = inputs.concat(')');
    inputsArray.push(inputs);
  });

	return inputsArray;
};

