import { setInfoBannerText, setInfoBannerShow } from '../../../redux/candidateDetail/slice';
import { addCandidatesToFunnelInCandidateDetailAction } from '../../../redux/candidateDetail/actions';
import { getParam } from '../../../utils/helpers';
import mixpanel from '../../../utils/mixpanel';

// Only valid for funnel objects
export function sortOnPriority(obj1, obj2) {
  if (obj1.funnelCategory < obj2.funnelCategory) {
    return -1;
  }
  if (obj1.funnelCategory > obj2.funnelCategory) {
    return 1;
  }
  if (obj1.priority <= obj2.priority) {
    return -1;
  }
  return 1;
}

export function orderPositions(positionList) {
  const temp = [];
  positionList?.forEach(e => {
    if (e.companyAssessmentId === getParam(location.search, 'defaultAssessmentId')) {
      temp.push(e);
    }
  });
  positionList?.forEach(e => {
    if (e.companyAssessmentId !== getParam(location.search, 'defaultAssessmentId')) {
      temp.push(e);
    }
  });

  return temp;
}

export async function moveCandidateToFunnel(dispatch, id, selectedFunnel, onlyForDefault = false) {
  const isRequestCandidate = getParam(location.search, 'requestStatus');
  const data = {
    funnelId: selectedFunnel.value,
    companyAssessmentId: getParam(
      location.search,
      !onlyForDefault ? 'currentAssessmentId' : 'defaultAssessmentId'
    ),
    userIds: isRequestCandidate ? [] : [id],
    requestedUserIds: isRequestCandidate ? [id] : [],
  };
  await dispatch(addCandidatesToFunnelInCandidateDetailAction(data));
  dispatch(setInfoBannerText(`Candidate is moved to '${selectedFunnel.label}' stage`));
  dispatch(setInfoBannerShow(true));
  window.dispatchEvent(new Event('candidateFunnelUpdateOnCandidateDetail'));
}

export async function moveCandidateToRejected(
  innerBool,
  dispatch,
  id,
  defaultAssessmentFunnelListRedux
) {
  const rejectedFunnel = defaultAssessmentFunnelListRedux.filter(
    e => e.funnelName === 'Rejected'
  )[0];
  const isRequestCandidate = getParam(location.search, 'requestStatus');
  const data = {
    funnelId: rejectedFunnel.funnelId,
    companyAssessmentId: getParam(location.search, 'currentAssessmentId'),
    userIds: isRequestCandidate ? [] : [id],
    requestedUserIds: isRequestCandidate ? [id] : [],
  };
  await dispatch(addCandidatesToFunnelInCandidateDetailAction(data));
  if (!innerBool) {
    dispatch(setInfoBannerText(`Candidate is moved to 'Rejected' stage`));
    dispatch(setInfoBannerShow(true));
  }
  window.dispatchEvent(new Event('candidateFunnelUpdateOnCandidateDetail'));
  window.dispatchEvent(new Event('setRejectedOnDropdownEvent'));
  // setSelectedValue({value: rejectedFunnel.funnelId, label: rejectedFunnel.funnelName})
}

export function setRejectedOnDropdown(setMethod, rejectedFunnelObj) {
  setMethod({ value: rejectedFunnelObj.funnelId, label: rejectedFunnelObj.funnelName });
}

export function mixpanelReturnCandidateDetailProperties(
  currentUser,
  positionsList,
  candidateProfile,
  loadingForPersonalInfoRedux,
  personalInfoExperienceRedux,
  personalInfoNationalityRedux,
  personalInfoSalaryRedux,
  personalInfoWorkTypeRedux
) {
  const defAssess = positionsList?.filter(
    x => x?.companyAssessmentId === getParam(location.search, 'defaultAssessmentId')
  )[0];
  const curAssess = positionsList?.filter(
    x => x?.companyAssessmentId === getParam(location.search, 'currentAssessmentId')
  )[0];

  // Screening related enum handler below
  // #region
  let expText;
  const workTypeList = [];
  const workPermitList = [];
  let salaryTRYtext;
  let salaryUSDtext;
  let salaryEURtext;
  let salaryGBPtext;

  if (personalInfoExperienceRedux && !personalInfoExperienceRedux?.isLocked) {
    // Experience enum
    // [Display(Name = "0 - 1")]
    // ZeroToOne = 10,
    // [Display(Name = "1 - 3")]
    // OneToFive = 20,
    // [Display(Name = "3 - 5")]
    // FiveToTen = 30,
    // [Display(Name = "5+")]
    // TenPlus = 40
    switch (personalInfoExperienceRedux.experiencePeriod) {
      case 10:
        expText = '0 - 1 year';
        break;

      case 20:
        expText = '1 - 3 years';
        break;

      case 30:
        expText = '3 - 5 years';
        break;

      case 40:
        expText = '5+ years';
        break;

      default:
        break;
    }
  }

  if (
    personalInfoWorkTypeRedux &&
    !personalInfoWorkTypeRedux?.isLocked &&
    personalInfoWorkTypeRedux?.workTypes?.length >= 1
  ) {
    personalInfoWorkTypeRedux?.workTypes?.forEach(x =>
      workTypeList.push(
        x === 10 ? 'Remote' : x === 20 ? 'Office' : x === 30 ? 'Hybrid' : 'Invalid enum'
      )
    );
    // Work type enum
    // [Display(Name = "Remote")]
    // Remote = 10,
    // [Display(Name = "Office")]
    // Hybrid = 20,
    // [Display(Name = "Hybrid")]
    // Office = 30
  }

  if (
    personalInfoNationalityRedux &&
    !personalInfoNationalityRedux?.isLocked &&
    personalInfoNationalityRedux?.nationalities?.length >= 1
  ) {
    personalInfoNationalityRedux?.nationalities?.forEach(x => workPermitList.push(x));
  }

  if (
    personalInfoSalaryRedux &&
    !personalInfoSalaryRedux?.isLocked &&
    personalInfoSalaryRedux?.currencies
  ) {
    salaryTRYtext = personalInfoSalaryRedux?.currencies.TRY;
    salaryUSDtext = personalInfoSalaryRedux?.currencies.USD;
    salaryEURtext = personalInfoSalaryRedux?.currencies.EUR;
    salaryGBPtext = personalInfoSalaryRedux?.currencies.GBP;
  }
  // #endregion

  return {
    // User related
    'User Id': currentUser?.userId,
    'Company Name': currentUser?.companyName,
    'Name Surname': currentUser?.userNameSurname,
    Email: currentUser?.email,
    'User Type': currentUser?.userGroupType,

    // Assessment related
    'Default Assessment Id': getParam(location.search, 'defaultAssessmentId'),
    'Default Assessment Name': defAssess?.reservedName,
    'Default Assessment Industry Average': defAssess?.allScoreAverage,
    'Assessment Id': getParam(location.search, 'currentAssessmentId'),
    'Assessment Name': curAssess?.reservedName,
    'Assessment Industry Average': curAssess?.allScoreAverage,

    // Candidate related
    'Is Locked Flag': candidateProfile?.status === 4,
    'Candidate Score': curAssess?.score,
    'Candidate Color':
      curAssess?.score >= curAssess?.allScoreAverage
        ? 'green'
        : curAssess?.score >= curAssess?.allScoreAverage * 0.8
        ? 'yellow'
        : 'red',
    'Invited by Email Flag': curAssess?.arriveType === 10,
    'Is Suspect Flag': curAssess?.isSuspect,

    'Candidate Name': candidateProfile?.fullName,
    'Candidate Email': candidateProfile?.email,

    // Screening related
    'Experience Level': expText || null,
    'Work Type': workTypeList,
    'Work Permit': workPermitList,
    'Salary Expectation - TRY': parseFloat(salaryTRYtext) || null,
    'Salary Expectation - USD': parseFloat(salaryUSDtext) || null,
    'Salary Expectation - EUR': parseFloat(salaryEURtext) || null,
    'Salary Expectation - GBP': parseFloat(salaryGBPtext) || null
  };
}

export function genericMixpanelCandidateDetailEventGenerator(
  eventName,
  currentUser,
  positionsList,
  candidateProfile,
  loadingForPersonalInfoRedux,
  personalInfoExperienceRedux,
  personalInfoNationalityRedux,
  personalInfoSalaryRedux,
  personalInfoWorkTypeRedux
) {
  const candDetProps = mixpanelReturnCandidateDetailProperties(
    currentUser,
    positionsList,
    candidateProfile,
    loadingForPersonalInfoRedux,
    personalInfoExperienceRedux,
    personalInfoNationalityRedux,
    personalInfoSalaryRedux,
    personalInfoWorkTypeRedux
  );
  mixpanel.track(eventName, candDetProps);
}
