import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { schoolsForFilterSelector } from '../../../redux/assessmentDetailVol2/selectors';
import MultiSelectDropdown from '../../../Common/Components/MultiselectDropdown/MultiselectDropdown';
import downArrow from './down-arrow.svg';
import upArrow from './up-arrow.svg';
import styles from './FilterModal.module.css';

export default function FilterItemSchool({
  filterName,
  openItem,
  setOpenItem = () => {},
  setSchoolFilter = () => {},
  schoolFilter,
  setSchoolDropdownScroll
}) {
  const schools = useSelector(schoolsForFilterSelector);
  const dummySchools = [
    {
      id: 120,
      name: 'Sabancı'
    },
    {
      id: 40,
      name: 'Boğaziçi'
    },
    {
      id: 140,
      name: 'Koç'
    },
    {
      id: 150,
      name: 'ITU'
    },
    {
      id: 130,
      name: 'Dokuz Eylül'
    },
    {
      id: 70,
      name: 'Kadir Has'
    }
  ];

  const handleSchoolDropdownScroll = () => {
    setSchoolDropdownScroll(true);
    setTimeout(() => {
      setSchoolDropdownScroll(false);
    }, 1000);
  };

  useEffect(() => {
    if (openItem !== filterName) setSchoolDropdownScroll(false);
  }, [openItem]);

  return (
    <div className={styles.itemContainer}>
      <div
        className={styles.innerHeaderWrapper}
        onClick={() => {
          if (openItem === filterName) {
            setOpenItem('');
          } else {
            setOpenItem(filterName);
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span className={styles.itemHeader}>Filter Candidates by&nbsp;</span>
          <span className={styles.itemHeaderBold}>{filterName}</span>
        </div>

        <div className={styles.rightHeaderWrapper}>
          {schoolFilter?.length > 0 ? <div className={styles.yellowIcon} /> : null}
          <span className={styles.icon}>
            <img alt="" src={openItem === filterName ? upArrow : downArrow} />
          </span>
        </div>
      </div>
      {openItem === filterName ? (
        <div>
          <div className={styles.lastDropdownContainer} onClick={handleSchoolDropdownScroll}>
            <MultiSelectDropdown
              placeholder="Select school"
              list={schools}
              selectedList={schoolFilter}
              setSelectedList={setSchoolFilter}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
