export const PageNumber = state => state.salesScreen.pageNumber;
export const BillingPeriod = state => state.salesScreen.billingPeriod;
export const AssessmentNumber = state => state.salesScreen.assessmentNumber;
export const CandidateNumber = state => state.salesScreen.candidateNumber;
export const CandidateReport = state => state.salesScreen.candidateReport;
export const PackageName = state => state.salesScreen.packageName;
export const Price = state => state.salesScreen.price;
export const Name = state => state.salesScreen.name;
export const Surname = state => state.salesScreen.surname;
export const CompanyName = state => state.salesScreen.companyName;
export const Email = state => state.salesScreen.email;
export const PremiumQuestion = state => state.salesScreen.premiumQuestion;
export const NewOpsCandidate = state => state.salesScreen.newOpsCandidate;
export const CustomQuestion = state => state.salesScreen.customQuestion;
export const InfoModal = state => state.salesScreen.infoModal;
export const InfoText = state => state.salesScreen.infoText;
export const CSList = state => state.salesScreen.CSList;
export const CompanyList = state => state.salesScreen.CompanyList;
export const SelectedCompany = state => state.salesScreen.selectedCompany;
export const TemplateList = state => state.salesScreen.templateList;
export const PhoneNumber = state => state.salesScreen.phoneNumber;
export const Password = state => state.salesScreen.password;
export const CustomBundleId = state => state.salesScreen.customBundleId;
export const createdCompanyId = state => state.salesScreen.createdCompanyId;
export const createCompSuccessRedux = state => state.salesScreen.createCompSuccess;
export const createTempAndConnectTempActionSuccessCheckRedux = state => state.salesScreen.createTempAndConnectTempActionSuccessCheck;
export const cancelBundleSuccessCheckRedux = state => state.salesScreen.cancelBundleSuccessCheck;
export const maxNumberOfRequests = state => state.salesScreen.maxNumberOfRequests;