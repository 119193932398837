import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/AssessmentWeightSelectorItem.module.css';
import { ReactComponent as DropDown } from '../../../images/weight_dropdown.svg';
import { weightSum } from '../../../redux/addAssessment/selectors';
import { addWeight } from '../../../redux/addAssessment/slice';
import { ReactComponent as Delete } from '../../../images/weight_delete.svg';
import codingIcon from '../../../images/coding_assessment.svg';
import freeTextIcon from '../../../images/freetext_assessment.svg';
import multipleChoiceIcon from '../../../images/multiple_assessment.svg';
import ShortText from '../../../Common/Components/ShortText';
import {
  updateQuestionWeightAction
} from '../../../redux/assessmentsVol2/actions';
import {
  setRemoveQuestionShow,
  setQuestionBeingRemoved
} from '../../../redux/assessmentsVol2/slice';
import Tooltip from '../../../Common/Components/Tooltip';
import { rootAssessmentQuestionList } from '../../../redux/assessmentsVol2/selectors';
import { ReactComponent as PremiumIcon } from '../../../images/premium_icon.svg';
import { ReactComponent as PurchasedIcon } from '../../../images/purchased_icon.svg';

const GreyDiv = styled.div`
  background: #f4f4f4;
  border-radius: 8px;
  display: flex;
  margin-right: 15px;
  max-width: 54px;
  width: 54px;
  position: relative;
  flex-direction: row;
  align-items: center;
`;

const PointComponent = React.memo(({ weight, item }) => {
  const TotalWeight = useSelector(weightSum);
  const [points, setPoints] = useState(0);

  useEffect(() => {
    setPoints(Math.floor((weight * 100) / TotalWeight));
  }, [TotalWeight]);

  return (
    <GreyDiv id={styles.points}>
      <div>{item.scoreInt}</div>
    </GreyDiv>
  );
});

export default function AssessmentWeightSelectorItem({
  item,
  index,
  id,
  type = '',
  page = '',
  type1 = 'question'
}) {
  // const TotalWeight = useSelector(weightSum);
  const dispatch = useDispatch();
  const [isExtented, setExtend] = useState(false);
  const [initial, setInitial] = useState(true);
  const array = [1, 2, 3, 4, 5];
  const [weight, setWeight] = useState(item?.weight);

  const Qlist = useSelector(rootAssessmentQuestionList);

  const WeightButton = ({ text, index }) => (
    <div
      onClick={async e => {
        if (isExtented) {
          e.stopPropagation();
          // dispatch(addWeight(-weight));
          setWeight(text);
          const data = {
            rootAssessmentId: id,
            questionId: item.questionId,
            weight: text
          };
          dispatch(updateQuestionWeightAction(data));
          // dispatch(addWeight(item));
          setExtend(!isExtented);
        }
      }}
      type={index === 5 ? '5' : ''}
      className={styles.weightbarInner}
      style={
        text === weight
          ? { color: 'white', backgroundColor: '#585858' }
          : index === 4
          ? { borderRight: 'none' }
          : {}
      }
    >
      {text}
    </div>
  );

  const DeleteIcon = ({ type }) => (
    <span
      className={`${styles.deleteContainer} ${type1 === 'question' ? styles.questionDelete : ''}`}
      onClick={() => {
        const data = {
          rootAssessmentId: id,
          questionId: item.questionId
        };
        dispatch(setRemoveQuestionShow(true));
        dispatch(setQuestionBeingRemoved(data));
      }}
    />
  );

  return (
    <div className={`${styles.container}  ${isExtented ? styles.extented : ''}`} key={item}>
      <div
        className={`${styles.innerContainer} ${
          type1 === 'question' ? styles.questionContainer : ''
        } ${isExtented ? styles.extentedInner : ''} `}
      >
        <Tooltip
          text={
            item.questionType === 1
              ? 'Multiple Choice'
              : item.questionType === 2
              ? 'Coding'
              : 'Free Text'
          }
        >
          <img
            src={
              item.questionType === 1
                ? multipleChoiceIcon
                : item.questionType === 2
                ? codingIcon
                : freeTextIcon
            }
            alt=""
            className={styles.questionIcon}
          />
        </Tooltip>
        <div className={styles.questionLabels}>
          <ShortText name={item.title} type={type !== 'question' ? 1 : 4} />
        </div>
        {/* 10 - Purchased
            20 - Free
            30 - Premium   */}
        {item.purchaseType === 30 ? (
          <Tooltip direction="top" text="This one is a premium question. Curious?">
            <PremiumIcon className={styles.icon} />
          </Tooltip>
        ) : item.purchaseType === 10 ? (
          <Tooltip direction="top" text="This one is a purchased premium question.">
            <PurchasedIcon className={styles.icon} />
          </Tooltip>
        ) : null}
        <div className={styles.weightRowRight}>
          {type !== 'question' ? (
            <>
              <GreyDiv
                className={styles.dropdown}
                onClick={() => {
                  setInitial(false);
                  setExtend(!isExtented);
                }}
              >
                <div
                  className={`${isExtented ? styles.nestedTable : styles.weightbar}`}
                  style={
                    isExtented
                      ? { width: 250, transform: 'translate(-200px)', top: 40, opacity: 1 }
                      : { width: 50, top: 0, opacity: 0 }
                  }
                >
                  <div
                    className={styles.innerAnimation}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 30,
                      position: 'absolute',
                      left: isExtented ? 0 : (weight - 1) * -50,
                      top: 0
                    }}
                  >
                    {array.map((item, index) => (
                      <WeightButton index={index} text={item} />
                    ))}
                  </div>
                </div>
                <div className={styles.weight}>{weight}</div>
                <DropDown />
              </GreyDiv>
              <PointComponent weight={weight} item={item} />
            </>
          ) : null}
          {/* <Delete show={type === 'question' ? 'true' : 'false'} className={styles.deleteIcon} /> */}
          <DeleteIcon type={type} />
        </div>
      </div>
    </div>
  );
}
