export default {
  login: '/login',
  activate: '/Login/UserActivate',
  refreshPassword: '/Login/RefreshPassword',
  userActivate: '/Authorazation/CompanyUserActivate',
  companyUserActivate: '/Authorization',
  register: '/register',
  forgotPassword: '/forgot-password',
  welcomeCandidate: '/welcome-candidate',
  candidateCongratulation: '/candidate-congratulation',
  addAssessment: '/add-assessment',
  assessments: '/assessments',
  candidateResult: '/candidate-result',
  assessmentDetail: (id = ':id') => `/assessment-detail/${id}`,
  assessmentPublic: (id = ':id') => `/application/${id}`,
  addLogoScreen: '/sDjax23pvle8wku',
  codingQuestion: '/upiefEshT782Fhe29Xbo7T',
  multiSelectQuestion: '/hfpexT73Bhj2Vn1Epls5U',
  freeTextQuestion: '/rt57Fh9vJkoln45ErYpZ4F',
  sqlQuestion: '/nxTSuk4IcvhoPYoKQ5doQf',
  candidateDetail: (id = ':id') => `/candidate-detail/${id}`,
  assessmentCustomization: (id = ':id') => `/assessment-customization/${id}`,
  questionBasedAssessment: (id = 'id') => `/question-based-assessment/${id}`,
  tryAssessment: (id = 'id') => `/try-assessment/${id}`,
  createAssessment: (index = 'index') => `/create-assessment/${index}`,
  successFactor: id => `/candidate-public?token=${id}`,
  assessmentSessionInformation: '/assessment-session-information',
  assessmentSession: '/assessment-session',
  settings: id => `/settings/${id}`,
  salesScreen: (index = ':index') => `/nRdDOsdIFdwEdfdsaSd/${index}`,
  notFound: '/not-found',
  cvReminder: '/cv-upload',
  candidateLanding: '/candidate-request',
};
