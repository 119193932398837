import React, { useEffect, useState } from 'react';
import styles from './NotFound.module.css';
import coensioLogo from './coensioWhite.svg';
import mixpanel from '../../../utils/mixpanel';

import blueCircle from './blueCircle.svg';
import purpleCircle from './purpleCircle.svg';
import pinkCircle from './pinkCircle.svg';
import greenCircle from './greenCircle.svg';
import { useWindowSize } from '../../../utils/helpers';

const NotFound = () => {
  const [width, height] = useWindowSize();

  const [blueCircleWidth, setBlueCircleWidth] = useState(566);
  const [blueCircleHeight, setBlueCircleHeight] = useState(566);

  useEffect(() => {
    if (width) {
      setTimeout(() => {
        const bCircleWidth = document?.getElementById('blueCircle')?.getBoundingClientRect()?.width;
        const blueCircleHeight = document
          ?.getElementById('blueCircle')
          ?.getBoundingClientRect()?.height;
        if (bCircleWidth) {
          setBlueCircleWidth(bCircleWidth);
          setBlueCircleHeight(blueCircleHeight);
        }
      }, 100);
    }
  }, [width]);

  useEffect(() => {
    mixpanel.track('404 - Pageview')
  }, [])

  return (
    <div className={styles.PageWrapper}>
      <img className={styles.Logo} src={coensioLogo} alt="coensio" />
      <img
        id="blueCircle"
        className={styles.Circles}
        style={{
          transform: `scale(min(max(${width / 1440}, 0.6), 1))`,
          transformOrigin: '0 0',
          left: `${0 - blueCircleWidth * 0.4}px`,
          top: `${0 - blueCircleHeight * 0.45}px`
        }}
        src={blueCircle}
        alt="blueCircle"
      />
      <img
        className={styles.Circles}
        style={{
          transform: `scale(min(${width / 1440}, 1))`,
          left: '80vw',
          top: '10vh',
          transformOrigin: '0 0'
        }}
        src={purpleCircle}
        alt="purpleCircle"
      />
      <img
        className={styles.Circles}
        style={{
          transform: `scale(min(${width / 1440}, 1))`,
          left: '8vw',
          top: '70vh',
          transformOrigin: '0 0'
        }}
        src={pinkCircle}
        alt="pinkCircle"
      />
      <img
        className={styles.Circles}
        style={{
          transform: `scale(min(${width / 1440}, 1))`,
          left: '75vw',
          top: '80vh',
          transformOrigin: '0 0'
        }}
        src={greenCircle}
        alt="greenCircle"
      />
      <div className={styles.TextWrapper}>
        <div className={styles.TextHeader}>
          It looks like you’re&nbsp;<text style={{ color: '#32CBE0' }}>lost</text>.
        </div>
        <div className={styles.Subtext}>
          Maybe this page used to exist or you just spelled something wrong. <br />
          Let’s double check the URL and try that again!
        </div>
      </div>
    </div>
  );
};
export default NotFound;
