import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CancelSubscriptionModal.module.css';
import CloseButton from '../../../Common/Components/CloseButton';
import { setInfoModal, setInfoText } from '../../../redux/salesScreen/slice';
import { cancelBundleSuccessCheckRedux, SelectedCompany } from '../../../redux/salesScreen/selectors';
import { cancelCustomBundleAction } from '../../../redux/salesScreen/actions';

const CancelSubscriptionModal = ({ onHide, show, ...props }) => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(SelectedCompany);
  const cancelBundleSuccessCheck = useSelector(cancelBundleSuccessCheckRedux);

  async function handleCancelSubscription(flag) {
    const data = {
      companyId: selectedCompany?.value,
      isImmediately: flag
    };
    if (flag) {
      await dispatch(setInfoText(`${selectedCompany.label} will no longer have access to Coensio`));
    } else {
      await dispatch(setInfoText(`${selectedCompany.label} will no longer have access to Coensio after current period`));
    }
    dispatch(cancelCustomBundleAction(data));
    
  }

  useEffect(() => {
    if (show) {
      if (cancelBundleSuccessCheck) {
        onHide();
        dispatch(setInfoModal(true));
      } else if (cancelBundleSuccessCheck === false) {
        dispatch(setInfoText(''));
        alert("Error");
      }
    }
    
  }, [cancelBundleSuccessCheck])

  return (
    <Modal
      size="md"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${styles.customModal} ${styles['modal-content']} `}
      dialogClassName={styles['modal-dialog']}
      onHide={onHide}
    >
      <CloseButton onClick={onHide} />

      <div className={styles.container}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <h3>When do you want to cancel the {selectedCompany.label}'s subscription</h3>
        <div className={styles.row}>
          <button
            onClick={() => {
              handleCancelSubscription(true);
            }}
            className={styles.btn}
            type="button"
          >
            Now
          </button>
          <button
            onClick={() => {
              handleCancelSubscription(false);
            }}
            className={styles.btn}
            type="button"
          >
            End of this period
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
