import React from 'react';
import { useSelector } from 'react-redux';
import styles from './FilterModal.module.css';
import downArrow from './down-arrow.svg';
import upArrow from './up-arrow.svg';
import MultiSelectDropdown from '../../../Common/Components/MultiselectDropdown/MultiselectDropdown';
import RadioButton from '../../../Common/Components/RadioButton';
import { industriesForFilterSelector } from '../../../redux/assessmentDetailVol2/selectors';
import { useWindowSize } from '../../../utils/helpers';

export default function FilterItemIndustry({
  filterName,
  openItem,
  setOpenItem = () => {},
  setIndustryFilter = () => {},
  industryFilter,
  setIncludeFilter = () => {},
  includeFilter,
  includeList
}) {
  // Industry Filter Variables
  const industries = useSelector(industriesForFilterSelector);
  const [screenW] = useWindowSize();

  return (
    <div className={styles.itemContainer}>
      <div
        className={styles.innerHeaderWrapper}
        onClick={() => {
          if (openItem === filterName) {
            setOpenItem('');
          } else {
            setOpenItem(filterName);
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span className={styles.itemHeader}>Filter Candidates by&nbsp;</span>
          <span className={styles.itemHeaderBold}>{filterName}</span>
        </div>

        <div className={styles.rightHeaderWrapper}>
          {industryFilter?.length > 0 ? <div className={styles.yellowIcon} /> : null}
          <span className={styles.icon}>
            <img alt="" src={openItem === filterName ? upArrow : downArrow} />
          </span>
        </div>
      </div>
      {openItem === filterName ? (
        <div>
          <div className={styles.dropdownContainer}>
            <MultiSelectDropdown
              placeholder="Select industry"
              list={industries}
              selectedList={industryFilter}
              setSelectedList={setIndustryFilter}
            />
          </div>
          <div className={styles.includeContainer}>
            <span className={styles.includeText}>Include: </span>
            <RadioButton
              disableUnselect
              data={includeList}
              setSelectedOption={setIncludeFilter}
              selectedOption={includeFilter}
              containerStyle={{ display: 'flex', flexDirection: 'row' }}
              radioItemTextStyle={{
                fontFamily: 'Jost-400',
                fontSize: '1.6rem',
                color: '#585858',
                marginRight: screenW < 576 && '12px'
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
