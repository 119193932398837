import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import styles from './MultiselectDropdown.module.css';

const MultiSelectDropdown = ({ list, selectedList, setSelectedList, placeholder }) => {
  const [innerSelectedList, setInnerSelectedList] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const element = document.getElementById('multiselect-dropdown-revised');
    const listener = event => {
      if ((event.code === 'Backspace' || event.code === 'Delete') && searchTerm === '') {
        event.stopPropagation();
        event.preventDefault();
      }
    };
    element.addEventListener('keydown', listener);
    return () => {
      element.removeEventListener('keydown', listener);
    };
  });

  useEffect(() => {
    const temp = [];
    selectedList?.forEach(e => {
      list.forEach(innerE => {
        if (e === innerE.id) {
          temp.push(innerE);
        }
      });
    });
    setInnerSelectedList(temp);
  }, [selectedList]);

  const onSelect = (selectedListParam, selectedItemParam) => {
    const temp = [...selectedList];
    temp.push(selectedItemParam.id);
    setSelectedList(temp);
  };

  const onRemove = (selectedListParam, selectedItemParam) => {
    const temp = [...selectedList];
    const ind = selectedList?.indexOf(selectedItemParam.id);
    if (ind > -1) {
      const arr = temp.splice(ind, 1);
      setSelectedList(temp);
    }
  };

  return (
    <Multiselect
      id="multiselect-dropdown-revised"
      onSearch={e => setSearchTerm(e)}
      className={styles.MultiSelectDropdown}
      options={list}
      displayValue="name"
      placeholder={placeholder}
      selectedValues={innerSelectedList}
      showCheckbox
      onSelect={onSelect}
      onRemove={onRemove}
      avoidHighlightFirstOption
      emptyRecordMsg='Loading...'
    />
  );
};

export default MultiSelectDropdown;
