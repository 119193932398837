/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../../../utils/helpers';
import styles from './Experience.module.css';
import { ReactComponent as Lock } from './lockIcon.svg';
import { ReactComponent as ExperienceIcon } from './experienceIcon.svg';
import { candidateBackgroundSelector } from '../../../../redux/candidateDetail/selectors';
import CustomButton from '../../../../Common/Components/CustomButton';
import constants from '../../../../utils/constants';

const Description = ({ exp }) => {
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const experience = useSelector(candidateBackgroundSelector);

  const lockedFlag =
    experience?.isLocked ||
    (experience?.requestStatus &&
      (experience?.requestStatus !== constants.REQUEST_STATUS_ACCEPTED ||
        (experience?.requestStatus === constants.REQUEST_STATUS_ACCEPTED && experience?.requestPayStatus === false)));

  return (
    <span className={styles.descriptionWrapper} style={{ filter: lockedFlag && 'blur(7.5px)' }}>
      {exp?.description?.length > 220 && !showMoreClicked
        ? exp?.description.substring(0, 220)
        : exp?.description}
      {!lockedFlag && !showMoreClicked && exp?.description?.length > 220 ? (
        <button type="button" className={styles.seeMore} onClick={() => setShowMoreClicked(true)}>
          ...see more
        </button>
      ) : null}
    </span>
  );
};

const Experience = () => {
  const [windowW, windowH] = useWindowSize();
  const experience = useSelector(candidateBackgroundSelector);
  const [expNoAvailable, setExpNoAvailable] = useState(2);

  const lockedFlag =
    experience?.isLocked ||
    (experience?.requestStatus &&
      (experience?.requestStatus !== constants.REQUEST_STATUS_ACCEPTED ||
        (experience?.requestStatus === constants.REQUEST_STATUS_ACCEPTED && experience?.requestPayStatus === false)));

  return (
    <>
      {experience?.experiences ? (
        <div className={styles.wrapper}>
          <span className={styles.header}>Experience</span>

          {experience?.experiences?.slice(0, expNoAvailable)?.map((exp, i) => (
            <div className={styles.rowWrapper}>
              <div className={styles.companyLogo}>
                {exp?.companyLogo && exp?.companyLogo !== 'Coensio' ? (
                  <img className={styles.logoImg} src={exp?.companyLogo} alt={`companyLogo-${i}`} />
                ) : (
                  <ExperienceIcon />
                )}
              </div>

              <div className={styles.textWrapper}>
                <div className={styles.companyRowWrapper}>
                  <span className={styles.subHeader}>{exp?.position}</span>
                  {exp?.sector ? <div className={styles.labelWrapper}>{exp?.sector}</div> : null}
                </div>
                {lockedFlag ? <Lock /> : null}
                {!lockedFlag ? (
                  <span className={styles.companyText}>{exp?.companyName}</span>
                ) : null}
                <span className={styles.experienceDate}>{exp?.date}</span>
                <Description exp={exp} />
              </div>
            </div>
          ))}
          {experience?.experiences?.length > 2 &&
          expNoAvailable !== experience?.experiences?.length ? (
            <CustomButton
              type={5}
              textField={`Show All Experience (${experience?.experiences?.length})`}
              customStyle={{ width: '100%' }}
              size="small"
              buttonOnClick={() => setExpNoAvailable(experience?.experiences?.length)}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Experience;
