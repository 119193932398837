import { SwipeableDrawer } from '@material-ui/core';
import { padding } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assessmentActiveTab } from '../../redux/assessmentsVol2/selectors';
import { setAssessmentActiveTab } from '../../redux/assessmentsVol2/slice';
import { onboardingStepRedux } from '../../redux/plan/selectors';
import { useWindowSize } from '../../utils/helpers';
import styles from '../Styles/TabSwitch.module.css';
import Highlight from './Highlight';
import Tooltip from './Tooltip3';

const TabSwitch = ({
  tabNameList,
  tabLabelList,
  isDisabledList,
  tabIconList,
  tooltipList,
  height,
  marginBetweenTabs,
  onClickFuncList,
  myStyle,
  switchHighlighted,
  customStyle,
  backgroundColor
}) => {
  // const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const selectedTabIndex = useSelector(assessmentActiveTab);
  const dispatch = useDispatch();
  const [widthArray, setWidthArray] = useState([]);
  const [leftArray, setLeftArray] = useState([]);
  const setSelectedTabIndex = tab => {
    dispatch(setAssessmentActiveTab(tab));
  };
  const onboardingStep = useSelector(onboardingStepRedux);

  useEffect(() => {
    let temp = [];
    tabNameList?.forEach(x => {
      temp = [...temp, document.getElementById(x)?.getBoundingClientRect().width];
    });
    setWidthArray(temp);

    let temp2 = [];
    tabNameList?.forEach(x => {
      temp2 = [...temp2, document.getElementById(x)?.getBoundingClientRect().width + 30];
    });
    setLeftArray(temp2);
  }, [tabNameList, tabLabelList]); // To dynamically change if input changes

  return (
    <Highlight
      isHighlighted={switchHighlighted}
      borderRadius={12}
      paddingPixel={4}
      style={{ width: selectedTabIndex === 0 ? '145.5px' : '135px' }}
      child={
        <div
          className={styles.headerWrapper}
          style={{
            width: widthArray.reduce((a, b) => a + b + 30, 0),
            height,
            border: 'none'
          }}
        >
          <div
            className={styles.selectedTab}
            style={{
              width: widthArray[selectedTabIndex] + 30,
              height,
              left: selectedTabIndex === 0 ? 0 : leftArray[selectedTabIndex - 1],
              background: backgroundColor
            }}
          />

          {tabNameList &&
            tabNameList.map((e, i) =>
              tooltipList?.length > 0 && tooltipList[i] ? (
                <Tooltip
                  maxSize={250}
                  myStyle={{ marginLeft: '10px', fontSize: '14px' }}
                  position="top"
                  text={tooltipList[i]}
                >
                  <div
                    onClick={() => {
                      if (!isDisabledList[i]) {
                        setSelectedTabIndex(i);
                        if (onClickFuncList) {
                          onClickFuncList[i]();
                        }
                      }
                    }}
                    myDisabled={JSON.stringify(isDisabledList[i])}
                    id={e}
                    className={`${styles.headerTab} ${selectedTabIndex === i && styles.selected}`}
                    style={{
                      width: 'fit-content',
                      height,
                      whiteSpace: 'nowrap',
                      marginLeft: '14px',
                      left: i === 0 ? 0 : widthArray[i - 1] + 30,
                      ...myStyle
                    }}
                  >
                    {tabLabelList &&
                    tabLabelList?.length === tabNameList?.length &&
                    tabLabelList[i] !== '' ? (
                      <span style={{ display: 'flex' }}>
                        {e}
                        <span className={styles.TabLabel}>{tabLabelList[i]}</span>
                      </span>
                    ) : tabIconList?.length > 0 && tabIconList[i] ? (
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {e} {tabIconList[i]}
                      </div>
                    ) : (
                      e
                    )}
                  </div>
                </Tooltip>
              ) : (
                <div
                  onClick={() => {
                    if (!isDisabledList || !isDisabledList[i]) {
                      setSelectedTabIndex(i);
                      if (onClickFuncList) {
                        onClickFuncList[i]();
                      }
                    }
                  }}
                  myDisabled={
                    isDisabledList?.length > 0 ? JSON.stringify(isDisabledList[i]) : false
                  }
                  id={e}
                  className={`${styles.headerTab} ${selectedTabIndex === i && styles.selected}`}
                  style={{
                    width: 'fit-content',
                    height,
                    whiteSpace: 'nowrap',
                    marginLeft: '14px',
                    left: i === 0 ? 0 : widthArray[i - 1] + 30,
                    ...myStyle
                  }}
                >
                  {tabLabelList &&
                  tabLabelList?.length === tabNameList?.length &&
                  tabLabelList[i] !== '' ? (
                    <span style={{ display: 'flex' }}>
                      {e}
                      <span className={styles.TabLabel}>{tabLabelList[i]}</span>
                    </span>
                  ) : tabIconList?.length > 0 && tabIconList[i] ? (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      {e} {tabIconList[i]}
                    </div>
                  ) : (
                    e
                  )}
                </div>
              )
            )}
        </div>
      }
    />
  );
};

export default TabSwitch;
