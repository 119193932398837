import { createSlice, current } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  getCodingLanguagesAction,
  addSkillBasedCodingQuestionAction,
  addSkillBasedQuestionAction,
  createCustomAssessmentAction,
  getCategoryLabelsListAction,
  getRootAssessmentListActionVol2,
  updateCustomAssessmentAction,
  removeSkillBasedQuestionAction,
  updateQuestionWeightAction,
  getJobDescriptionListAction,
  getQuestionByFilterAction,
  addQuestionBasedQuestionAction,
  tryAssessmentAction,
  getUserListByCompanyAction,
  sendEmailForReviewAction,
  getSkillsOfJobRoleAction,
  getQuestionDetailAction,
  inviteUser,
  getAtsListAction,
  addAssessmentActionVol2,
  getAssessmentDetailAfterCreateAction,
  applicantAssessmentSendEmailAction
} from './actions';
import initialState from './initialState';

const assessmentsVol2Slice = createSlice({
  name: 'assessmentsVol2',
  initialState,
  reducers: {
    setToInitialState2: (state, action) => initialState,
    setAssessmentId: (state, action) => {
      state.rootAssessmentId = action.payload;
    },
    setAssessmentActiveTab: (state, action) => {
      state.assessmentActiveTab = action.payload;
    },
    setDeletedId: (state, action) => {
      state.assessmentToBeDeleted = action.payload;
    },
    setCustomAssessment: (state, action) => {
      state.customAssessmentId = action.payload;
    },
    setPublishedAssessment: (state, action) => {
      state.publishedAssessment = action.payload;
    },
    setQuestionBeingAdded: (state, action) => {
      state.questionBeingAdded = action.payload;
    },
    setRootQuestionList: (state, action) => {
      state.rootAssessmentQuestionList = action.payload;
    },
    setFilterState: (state, action) => {
      state.filterState = action.payload;
    },
    setRemoveQuestionShow: (state, action) => {
      state.removeQuestionShow = action.payload;
    },
    setQuestionBeingRemoved: (state, action) => {
      state.questionBeingRemoved = action.payload;
    },
    removeFilter: (state, action) => {
      state.filterState = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setSessionQuestion: (state, action) => {
      state.questionAddedInSession = action.payload;
    },
    setSendForReviewShow: (state, action) => {
      state.sendForReviewShow = action.payload;
    },
    setInviteUserShow: (state, action) => {
      state.inviteUserShow = action.payload;
    },
    setAuthorizedHm: (state, action) => {
      state.authorizedHMList = action.payload;
    },
    setAuthorizedRec: (state, action) => {
      state.authorizedRecList = action.payload;
    },
    setFilteredRootAssessmentList: (state, action) => {
      state.filteredRootAssessmentList = action.payload;
    },
    setNewlyInvitedIDList: (state, action) => {
      state.newlyInvitedIDList = action.payload;
    },
    setFilterLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAuthorizedUsers: (state, action) => {
      state.authorizedUsers = action.payload;
    },
    setInvitedArray: (state, action) => {
      state.invitedArray = action.payload;
    },
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    setPremiumQuestionModal: (state, action) => {
      state.premiumQuestionModal = action.payload;
    },
    setIBEScored: (state, action) => {
      state.ibeScoredRedirect = action.payload;
    },

    setSentMailSuccess: (state, action) => {
      state.sentMailSuccess = action.payload;
    },

    setSentMailRejected: (state, action) => {
      state.mailRejected = action.payload;
    },

    setSelectedRootAssessmentQuestionList: (state, action) => {
      state.selectedRootAssessmentQuestionList = action.payload;
    },

    setContinueToAssessmentCustomization: (state, action) => {
      state.continueToAssessmentCustomization = action.payload;
    },
    setUpdateCustomAssessmentCheck: (state, action) => {
      state.updateCustomAssessmentCheck = action.payload;
    },

    setAddSkillBasedError: (state, action) => {
      state.addSkillBasedError = action.payload;
    },
    setAddSkillBasedErrorMessage: (state, action) => {
      state.addSkillBasedErrorMessage = action.payload;
    },
    setContinueToAssessmentDetail: (state, action) => {
      state.continueToAssessmentDetail = action.payload;
    },
    resetCandidateQuestionList: (state, action) => {
      state.candidateQuestionList = null;
    },

    setRemoveSkillBasedError: (state, action) => {
      state.removeSkillBasedError = action.payload;
    },

    setMostPopularRootAssessmentList: (state, action) => {
      state.mostPopularRootAssessmentList = action.payload;
    },

    resetMostPopularRootAssessmentList: (state, action) => {
      state.mostPopularRootAssessmentList = [];
    },

    setSelectedRootAssmFilters: (state, action) => {
      state.selectedRootAssmFilters = action.payload;
    }
  },
  extraReducers: builder => {
    // builder.addCase(getRootAssessmentListActionVol2.fulfilled, (state, action) => {
    //   state.filteredRootAssessmentList = action.payload.rootAssesments;
    //   state.mostPopularRootAssessmentList = action.payload.popularAssesments;
    // });
    // builder.addCase(getRootAssessmentListActionVol2.rejected, (state, action) => {
    //   state.filteredRootAssessmentList = 'rejected';
    //   state.mostPopularRootAssessmentList = [];
    // });
    builder.addCase(getRootAssessmentListActionVol2.pending, (state, action) => {
      state.filteredRootAssessmentList = [];
      state.mostPopularRootAssessmentList = [];
    });
    builder.addCase(getCategoryLabelsListAction.fulfilled, (state, action) => {
      state.categoryLabelList = action.payload;
    });

    builder.addCase(addSkillBasedQuestionAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
      state.addSkillBasedError = action.payload.hasFailure;
      state.addSkillBasedErrorMessage = action.payload.resultMessage;
    });
    builder.addCase(addSkillBasedQuestionAction.pending, (state, action) => {
      state.addSkillBasedError = null;
      state.addSkillBasedErrorMessage = null;
    });
    builder.addCase(addSkillBasedQuestionAction.rejected, (state, action) => {
      state.addSkillBasedError = true;
      state.addSkillBasedErrorMessage = 'An error occurred. Please try again.';
    });

    builder.addCase(addSkillBasedCodingQuestionAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
      state.addSkillBasedError = action.payload.hasFailure;
      state.addSkillBasedErrorMessage = action.payload.resultMessage;
    });
    builder.addCase(addSkillBasedCodingQuestionAction.pending, (state, action) => {
      state.addSkillBasedError = null;
      state.addSkillBasedErrorMessage = null;
    });
    builder.addCase(addSkillBasedCodingQuestionAction.rejected, (state, action) => {
      state.addSkillBasedError = true;
      state.addSkillBasedErrorMessage = 'An error occurred. Please try again.';
    });

    builder.addCase(createCustomAssessmentAction.fulfilled, (state, action) => {
      state.customAssessmentId = action.payload.rootAssessmentId;
      state.rootAssessmentQuestionList = action.payload.questionList;
      state.continueToAssessmentCustomization = true;
    });
    builder.addCase(createCustomAssessmentAction.pending, (state, action) => {
      state.continueToAssessmentCustomization = 'pending';
    });
    builder.addCase(createCustomAssessmentAction.rejected, (state, action) => {
      state.continueToAssessmentCustomization = false;
    });

    builder.addCase(getCodingLanguagesAction.fulfilled, (state, action) => {
      state.codingLanguageList = action.payload;
    });

    builder.addCase(removeSkillBasedQuestionAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
      state.questionAddedInSession = true;
      state.removeSkillBasedError = false;
    });

    builder.addCase(removeSkillBasedQuestionAction.pending, (state, action) => {
      state.removeSkillBasedError = null;
    });

    builder.addCase(removeSkillBasedQuestionAction.rejected, (state, action) => {
      state.removeSkillBasedError = true;
    });

    builder.addCase(updateCustomAssessmentAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
      localStorage.setItem(
        'Mixpanel_AssessmentCustomizationRootAssessmentName',
        action.payload.rootAssessmentName
      );
      state.updateCustomAssessmentCheck = true;
    });
    builder.addCase(updateCustomAssessmentAction.pending, (state, action) => {
      state.updateCustomAssessmentCheck = 'pending';
    });
    builder.addCase(updateCustomAssessmentAction.rejected, (state, action) => {
      state.updateCustomAssessmentCheck = false;
    });

    builder.addCase(updateQuestionWeightAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload.questionList;
    });
    builder.addCase(getJobDescriptionListAction.fulfilled, (state, action) => {
      state.jobDescription = action.payload;
    });
    // addQuestionBasedQuestionAction
    builder.addCase(getQuestionByFilterAction.fulfilled, (state, action) => {
      state.candidateQuestionList = action.payload.questionList;
      state.maxPageCount = action.payload.totalPageCount;
      state.isLoading = false;
    });
    builder.addCase(addQuestionBasedQuestionAction.fulfilled, (state, action) => {
      state.rootAssessmentQuestionList = action.payload;
      state.questionAddedInSession = true;
    });
    builder.addCase(tryAssessmentAction.fulfilled, (state, action) => {
      state.tryAssessmentQuestions = action.payload;
    });

    builder.addCase(getUserListByCompanyAction.fulfilled, (state, action) => {
      state.allUserList = action.payload;
      state.invitedAllUserList = action.payload?.filter(x => x.isActive === 0);
      state.HMUserList = action.payload?.filter(x => x.userGroupType === 'Hiring Manager');
      state.RecUserList = action.payload?.filter(x => x.userGroupType !== 'Hiring Manager');
      state.invitedHMList = action.payload?.filter(
        x => x.isActive === 0 && x.userGroupType === 'Hiring Manager'
      );
      state.invitedRecList = action.payload?.filter(
        x => x.isActive === 0 && x.userGroupType !== 'Hiring Manager'
      );
    });
    builder.addCase(sendEmailForReviewAction.fulfilled, (state, action) => {
      console.log(action.payload);
    });

    builder.addCase(getSkillsOfJobRoleAction.fulfilled, (state, action) => {
      state.skillsOfJobRole = action.payload.categoryLabels;
    });
    builder.addCase(getQuestionDetailAction.fulfilled, (state, action) => {
      state.questionDetail = action.payload;
    });
    builder.addCase(inviteUser.fulfilled, (state, action) => {
      state.userList = action.payload.companyUserList;
      state.newlyInvitedID = action.payload.userId;
    });
    builder.addCase(getAtsListAction.fulfilled, (state, action) => {
      state.AtsList = action.payload.filter(x => x.isAts);
      state.connectionList = action.payload.filter(x => !x.isAts);
    });

    builder.addCase(addAssessmentActionVol2.fulfilled, (state, action) => {
      state.CompanyAssessmentId = action.payload.companyAssessmentId; // pass the assessment id
      localStorage.setItem('addAssessmentCompanyId', action.payload.companyAssessmentId);
      state.continueToAssessmentDetail = true;
    });
    builder.addCase(addAssessmentActionVol2.rejected, (state, action) => {
      state.continueToAssessmentDetail = false;
      state.CompanyAssessmentId = null;
      localStorage.removeItem('addAssessmentCompanyId');
    });
    builder.addCase(addAssessmentActionVol2.pending, (state, action) => {
      state.continueToAssessmentDetail = null;
      state.CompanyAssessmentId = null;
      localStorage.removeItem('addAssessmentCompanyId');
    });

    builder.addCase(getAssessmentDetailAfterCreateAction.fulfilled, (state, action) => {
      state.assessmentDetailAfterCreate = action.payload;
    });
    builder.addCase(applicantAssessmentSendEmailAction.fulfilled, (state, action) => {
      state.scoredEmailCount = action.payload.scoredEmailCount;
      state.invitedCount = action.payload.totalEmailCount - action.payload.scoredEmailCount;
      state.sentMailSuccess = true;
      state.mailRejected = false;
    });
    builder.addCase(applicantAssessmentSendEmailAction.pending, (state, action) => {
      state.scoredEmailCount = 0;
      state.invitedCount = 0;
      state.mailRejected = false;
      state.sentMailSuccess = false;
    });
    builder.addCase(applicantAssessmentSendEmailAction.rejected, (state, action) => {
      state.sentMailSuccess = false;
      state.mailRejected = true;
    });
  }
});

export const {
  setAssessmentId,
  setAssessmentActiveTab,
  setDeletedId,
  setCustomAssessment,
  setPublishedAssessment,
  setQuestionBeingAdded,
  setRootQuestionList,
  setFilterState,
  setRemoveQuestionShow,
  setQuestionBeingRemoved,
  removeFilter,
  setShow,
  setSessionQuestion,
  setSendForReviewShow,
  setInviteUserShow,
  setAuthorizedHm,
  setAuthorizedRec,
  setFilteredRootAssessmentList,
  setNewlyInvitedIDList,
  setAuthorizedUsers,
  setInvitedArray,
  setToInitialState2,
  setPageIndex,
  setPremiumQuestionModal,
  setFilterLoading,
  setIBEScored,
  setSentMailSuccess,
  setSentMailRejected,
  setSelectedRootAssessmentQuestionList,
  setContinueToAssessmentCustomization,
  setUpdateCustomAssessmentCheck,
  setAddSkillBasedError,
  setAddSkillBasedErrorMessage,
  setContinueToAssessmentDetail,
  resetCandidateQuestionList,
  setRemoveSkillBasedError,
  setMostPopularRootAssessmentList,
  resetMostPopularRootAssessmentList,
  setSelectedRootAssmFilters
} = assessmentsVol2Slice.actions;

export default assessmentsVol2Slice.reducer;
