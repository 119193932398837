import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styles from '../Styles/QuestionWeightPanel.module.css';
import { ReactComponent as Info } from '../../../images/weight_info.svg';
import AssessmentWeightSelectorItem from './AssessmentWeightSelectorItem';
import CustomButton from '../../../Common/Components/CustomButton';
import Tooltip from '../../../Common/Components/Tooltip';
import {
  customAssessmentId,
  rootAssessmentQuestionList
} from '../../../redux/assessmentsVol2/selectors';
import {
  sendNotificationForRecruiterAction,
  updateCustomAssessmentAction
} from '../../../redux/assessmentsVol2/actions';
import {
  setAssessmentActiveTab,
  setPublishedAssessment,
  setSendForReviewShow
} from '../../../redux/assessmentsVol2/slice';
import { assessmentView, weightSum } from '../../../redux/addAssessment/selectors';
import { addWeight, setTotalWeightToZero } from '../../../redux/addAssessment/slice';
import { premiumQAvailableInAssessmentControlAction } from '../../../redux/plan/actions';
import HeadsUpModal from '../../../Auth/Modals/HeadsUpModal';
import ContactSalesSuccess from '../../../Auth/Modals/UnsubscribeSuccessModal';
import { currentPlanRedux, premiumQControlResultredux } from '../../../redux/plan/selectors';
import FreeTrialModal from '../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import PurchaseAddOnSuccessModal from '../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';

export default function QuestionWeightPanel() {
  const questions = useSelector(rootAssessmentQuestionList);
  const dispatch = useDispatch();
  const history = useHistory();
  const assessmentId = useSelector(customAssessmentId);
  const prevLocation = useLocation();
  const activeTab = useSelector(assessmentView);
  const [loading, setLoading] = useState(false);
  const calculateMin = questionList => {
    let timeInSeconds = 0;

    questionList?.forEach(item => {
      timeInSeconds += parseInt(item.questionDuration, 10);
    });

    const time = {
      min: Math.floor(timeInSeconds / 60),
      sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
    };
    if (time.sec === 0) {
      return `${time.min} min`;
    }
    if (time.min === 0) {
      return `${time.sec} sec`;
    }
    return `${time.min} min ${time.sec} sec`;
  };

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [premiumQPresent, setPremiumQPresent] = useState(false);
  const [premiumQPresentSFR, setPremiumQPresentSFR] = useState(false);
  const [premiumQPresentPreview, setPremiumQPresentPreview] = useState(false);

  const myPlan = useSelector(currentPlanRedux);
  const premiumQControl = useSelector(premiumQControlResultredux);

  const [continuePurchase, setContinuePurchase] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [continuePurchaseSFR, setContinuePurchaseSFR] = useState(false);
  const [purchaseSuccessSFR, setPurchaseSuccessSFR] = useState(false);
  const [continuePurchasePreview, setContinuePurchasePreview] = useState(false);
  const [purchaseSuccessPreview, setPurchaseSuccessPreview] = useState(false);

  const publishOnClick = async () => {
    setLoading(true);
    const checkData = {
      RootAssessmentId: assessmentId
    };
    const res = await dispatch(premiumQAvailableInAssessmentControlAction(checkData));
    if (res?.payload?.isPremiumQuestionExist) {
      if (myPlan?.name === 'Free Trial') {
        setContinuePurchase(true);
      } else {
        await setPremiumQPresent(true);
      }
    } else {
      const data = {
        rootAssessmentId: assessmentId,
        objStatus: 1
      };
      dispatch(setPublishedAssessment(true));
      await dispatch(updateCustomAssessmentAction(data));
      dispatch(setAssessmentActiveTab(1));
      // send published
      await dispatch(
        sendNotificationForRecruiterAction({ type: 1, rootAssessmentId: assessmentId })
      );
      if (history.location.key) {
        history.goBack();
      } else {
        history.replace('/add-assessment');
      }
    }
    setLoading(false);
  };

  const sendForReview = () => {
    // history.push(`/login?redirectTo=${prevLocation}`);
    dispatch(setSendForReviewShow(true));
  };

  useEffect(() => {
    dispatch(setTotalWeightToZero());
    questions?.forEach(x => {
      dispatch(addWeight(x.weight));
    });
  }, [questions]);


  return (
    <div>
      {/* START OF PURCHASE PQ in Rec View (save assessment) */}
      <HeadsUpModal
        addOnCode={62}
        show={premiumQPresent}
        onHide={() => setPremiumQPresent(false)}
        title="Heads up!"
        description={
          myPlan?.isCustom
            ? `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Please contact our sales team to upgrade your plan.`
            : `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Total price will be only $${premiumQControl?.totalPrice}. Do you want to proceed?`
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchaseSuccess}
      />
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />{' '}
      {/* Purchase AddOn Success */}
      <PurchaseAddOnSuccessModal
        show={purchaseSuccess}
        onHide={() => {
          setPurchaseSuccess(false);
          publishOnClick();
        }}
        addOnCode={62}
      />
      <FreeTrialModal
        show={continuePurchase}
        onHide={() => setContinuePurchase(false)}
        addOnCode={62}
      />
      {/* END OF PURCHASE PQ in Rec View (save assessment) */}
      {/* START OF PURCHASE PQ in Rec View (SFR assessment) */}
      <HeadsUpModal
        addOnCode={66}
        show={premiumQPresentSFR}
        onHide={() => setPremiumQPresentSFR(false)}
        title="Heads up!"
        description={
          myPlan?.isCustom
            ? `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Please contact our sales team to upgrade your plan.`
            : `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Total price will be only $${premiumQControl?.totalPrice}. Do you want to proceed?`
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchaseSuccessSFR}
      />
      <PurchaseAddOnSuccessModal
        show={purchaseSuccessSFR}
        onHide={() => {
          setPurchaseSuccessSFR(false);
          sendForReview();
        }}
        addOnCode={66}
      />
      <FreeTrialModal
        show={continuePurchaseSFR}
        onHide={() => setContinuePurchaseSFR(false)}
        addOnCode={66}
      />
      {/* END OF PURCHASE PQ in Rec View (SFR assessment) */}
      {/* START OF PURCHASE PQ in Rec View (PREVIEW assessment) */}
      <HeadsUpModal
        addOnCode={67}
        show={premiumQPresentPreview}
        onHide={() => setPremiumQPresentPreview(false)}
        title="Heads up!"
        description={
          myPlan?.isCustom
            ? `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Please contact our sales team to upgrade your plan.`
            : `Your assessment includes ${premiumQControl?.premiumQuestionCount} premium questions. Total price will be only $${premiumQControl?.totalPrice}. Do you want to proceed?`
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchaseSuccessPreview}
      />
      <PurchaseAddOnSuccessModal
        show={purchaseSuccessPreview}
        onHide={() => {
          setPurchaseSuccessPreview(false);
          window.open(`/try-assessment/${assessmentId}`, '_blank');
          const data = {
            rootAssessmentId: assessmentId,
            objStatus: 1
          };
          dispatch(updateCustomAssessmentAction(data));
        }}
        addOnCode={67}
      />
      <FreeTrialModal
        show={continuePurchasePreview}
        onHide={() => setContinuePurchasePreview(false)}
        addOnCode={67}
      />
      {/* END OF PURCHASE PQ in Rec View (PREVIEW assessment) */}
      <div className={styles.buttonWrapper}>
        <CustomButton
          type={5}
          size="small"
          textField={activeTab === 'Hiring Manager' ? 'Preview Assessment' : 'Send for Review'}
          style={{ width: '194px' }}
          isDisabled={questions?.length === 0}
          tooltipText="There is no question in this assessment"
          buttonOnClick={async () => {
            const checkData = {
              RootAssessmentId: assessmentId
            };
            const res = await dispatch(premiumQAvailableInAssessmentControlAction(checkData));
            if (!res?.payload?.isPremiumQuestionExist) {
              if (activeTab === 'Hiring Manager') {
                await window.open(`/try-assessment/${assessmentId}`, '_blank');
              } else {
                await sendForReview();
              }
            } else if (myPlan?.name === 'Free Trial') {
              if (activeTab === 'Hiring Manager') {
                await setContinuePurchase(true);
              } else {
                await setContinuePurchaseSFR(true);
              }
            } else if (activeTab === 'Hiring Manager') {
              await setPremiumQPresentPreview(true);
            } else {
              await setPremiumQPresentSFR(true);
            }
          }}
        />
        <CustomButton
          type={1}
          loading={loading}
          size="small"
          buttonOnClick={publishOnClick}
          textField="Save"
          style={{ width: '194px' }}
        />
      </div>
      <div className={styles.weightWrapper}>
        <div>
          <div className={`${styles.weightText} ${styles.title}`}>Assessment Details</div>
          <div className={styles.detailsBar}>
            <div className={styles.detailsColumn}>
              <div className={styles.questionInfo}>Questions:</div>
              <div className={styles.questionNumber}>{questions && questions.length}</div>
            </div>
            <div className={styles.detailsColumn}>
              <div className={styles.questionInfo}>Duration:</div>
              <div className={styles.questionNumber}>{calculateMin(questions)}</div>
            </div>
          </div>
          <div className={styles.questionDetailsRow}>
            <div className={styles.weightText}>Questions</div>
            <div className={styles.detailsRowRight}>
              <div className={styles.weightInfo} style={{ marginRight: '8px' }}>
                <Tooltip
                  direction="left"
                  text="The scores of the questions are calculated according to their weight in the assessment."
                >
                  <Info className={styles.infoIcon} />
                </Tooltip>
                <div className={styles.weightText}>Weight</div>
              </div>
              <div className={styles.weightInfo}>
                <Tooltip
                  direction="left"
                  text="Decimal points are not included here. Total assessment score adds up to 100."
                >
                  <Info className={styles.infoIcon} />
                </Tooltip>
                <div className={styles.weightText}>Points</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.questionListWrapper}>
          {questions && questions.length === 0 ? (
            <div className={styles.noQText}>There is no question yet.</div>
          ) : (
            questions?.map((question, index) => (
              <AssessmentWeightSelectorItem
                index={index}
                item={question}
                key={`${question}`}
                id={assessmentId}
                page={activeTab}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
