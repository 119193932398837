import React from 'react';
import styles from '../Styles/TextInput.module.css';

const TextInput = ({
  placeholder = '',
  name = '',
  type = '',
  hoverIcon = false,
  disabled = false,
  valid = false,
  haserror = false,
  onChange = () => {},
  onBlur = () => {},
  value = null,
  error = false,
  mref = null,
  style = {},
  inputstyle,
  autofocus = false,
  onKeyDown = null,
  showInvisibleError,
  inputRef,
  ...props
}) => {
  function getClassName() {
    if (valid) {
      return styles.validInput;
    }
    if (haserror) {
      return styles.errorInput;
    }
    return styles.filledInput;
  }

  return (
    <div
      className={`${styles['input-container']} ${error && styles.errorContainer}`}
      style={style}
      ref={mref}
    >
      <input
        ref={inputRef}
        autoFocus={autofocus}
        onKeyDown={onKeyDown || null}
        name={name}
        id="field"
        type={type}
        autoComplete="off"
        style={hoverIcon ? inputstyle : { backgroundImage: 'none', ...inputstyle }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
        className={value && getClassName()}
        disabled={disabled}
      />
      <label className={value && styles.filled} htmlFor={name}>
        {placeholder}
      </label>
      {/* {error} */}
      {(!!error || showInvisibleError) && (
        <div
          className={styles.textInputErrorMessageWrapper}
          style={{ visibility: showInvisibleError ? (error ? 'visible' : 'hidden') : 'visible' }}
        >
          {error || 'Error'}
        </div>
      )}
    </div>
  );
};
export default TextInput;
