import { createSlice } from '@reduxjs/toolkit';
import {
  getCandidateAssessmentPublicLabelsAction,
  getCandidateisScoredAction,
  getCandidatePublicInfoAction,
  getCandidatePublicSolutionsAction
} from './actions';
import initialState from './initialState';

const successFactorSlice = createSlice({
  name: 'successFactors',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCandidateAssessmentPublicLabelsAction.fulfilled, (state, action) => {
      state.labels = action.payload;
      state.labelLoading = false;
    });
    builder.addCase(getCandidateAssessmentPublicLabelsAction.pending, (state, action) => {
      state.labelLoading = true;
    });
    builder.addCase(getCandidatePublicSolutionsAction.pending, (state, action) => {
      state.questionLoading = true;
    });
    builder.addCase(getCandidatePublicInfoAction.pending, (state, action) => {
      state.infoLoading = true;
    });
    builder.addCase(getCandidatePublicInfoAction.fulfilled, (state, action) => {
      state.info = action.payload;
      state.infoLoading = false;
    });
    builder.addCase(getCandidatePublicSolutionsAction.fulfilled, (state, action) => {
      state.solutions = action.payload;
      state.questionLoading = false;
    });
    builder.addCase(getCandidateisScoredAction.fulfilled, (state, action) => {
      state.isScored = action.payload;
    });
  }
});

export default successFactorSlice.reducer;
