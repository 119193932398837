import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styles from '../Styles/WelcomeCandidateContent.module.css';
import CustomButton from '../../Common/Components/CustomButton';
import {
  AssessmentTitle,
  CompanyName,
  Expired,
  LogoPath,
  rootAssessmentNameRedux
} from '../../redux/AssessmentSession/selectors';
import {
  applyCandidateToAssessmentAction,
  candidateHasDataAction,
  getNewOpsCompanyListAction
} from '../../redux/AssessmentSession/actions';
import { setApplicationState, setCandidateEmail } from '../../redux/AssessmentSession/slice';
import isEmail from '../../utils/isEmail';
import mockImage from '../../images/Coensio_newLogo.svg';
import EmailText from '../../Common/Components/EmailText';
import coensioCircleLogo from '../Images/coensioCircleLogo.svg';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD } from '../../utils/helpers';
import OopsComponent from './UnpublishedAssessment/OopsComponent';
import UnpublishedNewOpComponent from './UnpublishedAssessment/UnpublishedNewOpComponent';

import mixpanel from '../../utils/mixpanel';

const LeftColumn = ({ logoPath, companyName }) => {
  return (
    <>
      <div className={styles.leftColumn}>
        <img
          className={styles.icon}
          alt=""
          src={logoPath ? `${process.env.REACT_APP_IMAGE_API}/${logoPath}` : mockImage}
        />
        <div className={styles.heading}>Hi there!</div>
        <div className={`${styles.text} ${styles.centerText}`}>
          We are developing and building success. We look forward to your application to develop
          <strong> together</strong> and achieve <strong>greater success!</strong>
        </div>
        <div className={`${styles.text} ${styles.bottomText}`}>
          {` Take a step to find your future in ${companyName}!`}
        </div>
        <div className={styles.text}> Waiting for you...</div>
      </div>
    </>
  );
};

const RightColumn = ({
  assessmentTitle,
  companyName,
  id,
  logoPath,
  application,
  setShowNewOpsForExpiredFlag,
  setIsMailEnteredForExpiredFlag,
  rootAssessmentName
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const expired = useSelector(Expired);
  const { search } = useLocation();
  const emailFromUrl = new URLSearchParams(search).get('email');

  const kariyernetToken = new URLSearchParams(search).get('ats_token');

  function getUtmFromWelcomeCandidate() {
    const url = window.location.href;
    const array = url.split('&');
    const utmArray = [];
    array?.forEach(x => {
      if (x.includes('utm')) {
        utmArray.push(x);
      }
    });
    let utmUrl = '';
    if (utmArray.length > 0) {
      utmUrl = utmArray.join('&');
    }
    return utmUrl;
  }

  // Mixpanel variable
  const [candidateData, setCandidateData] = useState();

  const fetchNewOpsForExpired = async () => {
    if (emailFromUrl) {
      mixpanel.track('Welcome Candidate - Continue Button Click', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': emailFromUrl,
        'Is Mail Encrypted': true
      });
      await dispatch(setCandidateEmail(emailFromUrl));
    } else {
      await dispatch(setCandidateEmail(email));
    }
    const data = {
      email: emailFromUrl || email,
      companyAssessmentKey: id
    };
    const resp = await dispatch(getNewOpsCompanyListAction(data));
    if (resp?.meta?.requestStatus === 'fulfilled') {
      setShowNewOpsForExpiredFlag(true);
    } else {
      setShowNewOpsForExpiredFlag(false);
    }
    if (application) {
      mixpanel.track('Application - Apply Button Success', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag': candidateData?.payload?.isAlreadyPassed,
        'CV Flag': !!candidateData?.payload?.cvPath
      });
    } else {
      mixpanel.track('Welcome Candidate - Continue Button Success', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': emailFromUrl || email,
        'Is Mail Encrypted': !!emailFromUrl,
        'Already Scored Flag': candidateData?.payload?.isAlreadyPassed,
        'CV Flag': !!candidateData?.payload?.cvPath
      });
    }

    setIsMailEnteredForExpiredFlag(true);
  };

  useEffect(() => {
    if (emailFromUrl && expired) {
      fetchNewOpsForExpired();
    }
  }, []);

  const onClick = async () => {
    setLoading(true);

    if (application) {
      mixpanel.track('Application - Apply Button Click', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email
      });
    } else {
      mixpanel.track('Welcome Candidate - Continue Button Click', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Is Mail Encrypted': false
      });
    }

    if (kariyernetToken) {
      localStorage.setItem('kariyernetToken', kariyernetToken);
    }

    if (!expired) {
      await dispatch(setCandidateEmail(email));
      const jsonData = { Key: id, Email: email };
      const firstResp = await dispatch(candidateHasDataAction(jsonData));

      if (firstResp?.meta?.requestStatus === 'fulfilled') {
        setCandidateData(firstResp);
        const passed = firstResp.payload.isAlreadyPassed;
        localStorage.setItem('isCandidateAlreadyPassed', passed);

        let applicationSuccess = true;
        if (application) {
          const appData = { email, companyAssessmentKey: id };
          const resp = await dispatch(applyCandidateToAssessmentAction(appData));
          if (resp.meta.requestStatus === 'fulfilled') {
            applicationSuccess = true;
            mixpanel.track('Application - Apply Button Success', {
              'Assessment Name': assessmentTitle,
              'Root Assessment Name': rootAssessmentName,
              'Company Name': companyName,
              'Candidate Email': email,
              'Already Scored Flag': passed,
              'CV Flag': !!firstResp?.payload?.cvPath
            });
          } else {
            applicationSuccess = false;
            mixpanel.track('Application - Apply Button Fail', {
              'Assessment Name': assessmentTitle,
              'Root Assessment Name': rootAssessmentName,
              'Company Name': companyName,
              'Candidate Email': email,
              'Already Scored Flag': passed,
              'CV Flag': !!firstResp?.payload?.cvPath,
              'Error message': resp?.payload
            });
          }
        }

        if (passed && !application) {
          mixpanel.track('Welcome Candidate - Continue Button Success', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': false,
            'Already Scored Flag': passed,
            'CV Flag': !!firstResp?.payload?.cvPath
          });
          history.push(`/candidate-result?assessment=${id}`);
        } else if (!application) {
          mixpanel.track('Welcome Candidate - Continue Button Success', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': false,
            'Already Scored Flag': passed,
            'CV Flag': !!firstResp?.payload?.cvPath
          });
          const utms = getUtmFromWelcomeCandidate();
          history.push(`/assessment-session-information?assessment=${id}&${utms}`);
        } else if (application && applicationSuccess) {
          dispatch(setApplicationState(`/candidate-result?assessment=${id}`));
        }
      } else {
        /* eslint-disable-next-line */
        if (application) {
          mixpanel.track('Application - Apply Button Fail', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Error message': 'Error on fetching candidate data'
          });
        } else {
          mixpanel.track('Welcome Candidate - Continue Button Fail', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': false,
            'Error message': 'Error on fetching candidate data'
          });
        }
      }
    }

    if (expired) {
      fetchNewOpsForExpired();
    }

    setLoading(false);
  };

  return (
    <>
      <div className={styles.rightColumn}>
        <div className={styles.ImageWrapper}>
          <img
            alt=""
            src={logoPath ? `${process.env.REACT_APP_IMAGE_API}/${logoPath}` : mockImage}
            className={styles.rightIcon}
          />
          <img className={styles.coensioLogo} src={coensioCircleLogo} alt="" />
        </div>

        <div>
          <div className={styles.assessmentTitle}>
            {`${application ? 'Apply' : 'Join'} ${kariyernetToken ? '' : companyName} as`}
          </div>
          <div
            className={styles.assessmentTitle}
            style={{ fontFamily: 'Jost-500', marginBottom: '8.8rem' }}
          >
            {`${assessmentTitle} 🚀`}
          </div>
        </div>
        <div className={styles.lowerColumn}>
          <div>
            <div className={styles.emailTitle}>Enter your e-mail address:</div>
            <EmailText
              inputChange={event => {
                if (isEmail(event.target.value.toLocaleLowerCase('tr'))) {
                  setError(false);
                } else {
                  setError(true);
                }
              }}
              value={email}
              setValue={setEmail}
              placeholder="Email*"
              onEnterDown={
                !error && email
                  ? () => {
                      onClick();
                    }
                  : () => {}
              }
              isDisabled={loading}
              trimWhiteSpaces
            />
          </div>
          <div>
            <CustomButton
              style={{ width: '100%', marginTop: '3.4rem' }}
              buttonOnClick={() => {
                onClick();
              }}
              loading={loading}
              type={1}
              isDisabled={!email || error}
              buttonType="button"
              textField={application ? 'Apply' : 'Continue'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    background-color: white;
    justify-content: space-between;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    justify-content: flex-start;
    padding: 2.4rem;
    padding-top: 28px;
  }
`;

const BigCard = styled.div`
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  background: #ffffff;
  display: flex;
  margin-left: 2rem;
  width: 60%; // To try the continuous scaling
  margin-right: 2rem;
  flex-direction: column;
  padding: 3.2rem;
  justify-content: center;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);

  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    width: 100%;
    max-width: none; // Bunlar nasıl daha iyi yapılır
    max-height: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

const Powered = styled.div`
  display: flex;
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  width: 60%;
  margin-top: 1.6rem;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    display: none;
  }
  align-items: center;
`;

const WelcomeCandidateContent = ({ id, application = false }) => {
  const logoPath = useSelector(LogoPath);
  const companyName = useSelector(CompanyName);
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);

  const [isMailEntered, setIsMailEntered] = useState(false);
  const [showNewOps, setShowNewOps] = useState(true);

  return (
    <>
      {isMailEntered && showNewOps ? (
        <UnpublishedNewOpComponent id={id} application={application} />
      ) : isMailEntered && !showNewOps ? (
        <OopsComponent />
      ) : (
        <PageWrapper>
          <CardWrapper>
            <BigCard>
              <InnerCard>
                <LeftColumn companyName={companyName} logoPath={logoPath} />
                <RightColumn
                  application={application}
                  logoPath={logoPath}
                  id={id}
                  companyName={companyName}
                  assessmentTitle={assessmentTitle}
                  setShowNewOpsForExpiredFlag={setShowNewOps}
                  setIsMailEnteredForExpiredFlag={setIsMailEntered}
                  rootAssessmentName={rootAssessmentName}
                />
              </InnerCard>
            </BigCard>
          </CardWrapper>
          <Powered>
            <div className={styles.powered}>powered by</div>
            <img style={{ maxHeight: '1.4rem' }} alt="" src={mockImage} />
          </Powered>
        </PageWrapper>
      )}
    </>
  );
};

export default WelcomeCandidateContent;
