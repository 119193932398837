import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../Common/Components/CustomButton';
import TextInput from '../../../Common/Components/TextInput';
import Modal from '../../../Common/CustomModal/Modal';
import styles from './FunnelPopup.module.css';
import { ReactComponent as Tick } from './success.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as Tip } from './tipsIcon.svg';
import { ReactComponent as WhiteDelete } from './white-delete.svg';
import { ReactComponent as Trash } from './red_Trash.svg';
import {
  addNewFunnelAction,
  deleteFunnelAction,
  updateFunnelNameAction
} from '../../../redux/assessmentDetailVol2/actions';
import { funnelInfoSelector } from '../../../redux/assessmentDetailVol2/selectors';
import CloseButton from '../../../Common/Components/CloseButton';
import GoBackButton from '../../../Common/Components/GoBackButton';
import {
  moveCandidateToFunnel,
  urlDynamicQueryUpdateLite,
  useWindowSize
} from '../../../utils/helpers';
import Tooltip from '../../../Common/Components/Tooltip';
import {
  setInfoBannerErrorFlag,
  setInfoBannerShowState,
  setInfoBannerText
} from '../../../redux/assessmentDetailVol2/slice';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';

const FunnelPopup = ({
  popupType,
  show,
  setShow,
  setBackClicked,
  setDeleteClicked,
  funnelInfo,
  selectedCandidates,
  selectedAcceptedCandidates,
  selectedFunnelId
}) => {
  const dispatch = useDispatch();
  const [funnelName, setFunnelName] = useState('');
  const [newFunnel, setNewFunnel] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isNewButtonDisabled, setNewButtonDisabled] = useState(true);
  const [isDeleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const funnels = useSelector(funnelInfoSelector);

  const [windowW, windowH] = useWindowSize();

  const { id } = useParams();
  const assessmentId = id;

  const [buttonLoadingState, setButtonLoadingState] = useState(false);


  const allSelectedCandCount = selectedCandidates?.length + selectedAcceptedCandidates?.length;


  useEffect(() => {
    if (!show) {
      setButtonLoadingState(false);
    }
  }, [show]);

  function checkExistingFunnel(name) {
    let exist = false;
    if (funnels) {
      funnels.forEach(element => {
        if (element.funnelName === name.trim()) {
          exist = true;
        }
      });
    }
    return exist;
  }
  useEffect(() => {
    if (popupType === 'Add') {
      setNewButtonDisabled(checkExistingFunnel(newFunnel));
      if (newFunnel.trim() === '') {
        setNewButtonDisabled(true);
      }
    }
  }, [newFunnel]);

  useEffect(() => {
    if (popupType === 'Edit') {
      setDeleteButtonDisabled(funnelInfo.userCount > 0);
      if (funnelName.trim() === '') {
        setDeleteButtonDisabled(true);
      }
    }
  }, [funnelName]);

  useEffect(() => {
    if (popupType === 'Edit') {
      setButtonDisabled(checkExistingFunnel(funnelName));
      if (funnelName.trim() === '') {
        setButtonDisabled(true);
      }
    }
  }, [funnelName]);

  useEffect(() => {
    if (funnelInfo) {
      setFunnelName(funnelInfo.funnelName);
    }
  }, [funnelInfo]);

  const handleOnClose = () => {
    setShow(false);
  };
  const handleBackClick = () => {
    setBackClicked();
  };
  const handleDeleteStageClicked = () => {
    setDeleteClicked();
  };

  const deleteStage = async () => {
    setButtonLoadingState(true);
    const funnelData = {
      funnelId: funnelInfo?.funnelId
    };
    const resp = await dispatch(deleteFunnelAction(funnelData));
    if (resp?.meta?.requestStatus === 'fulfilled') {
      dispatch(setInfoBannerShowState(true));
      dispatch(setInfoBannerText('Stage is deleted'));
      dispatch(setInfoBannerErrorFlag(false));
      setShow(false);

      if (funnelInfo?.funnelId === selectedFunnelId) {
        const scoredFunnel = funnels.filter(
          e => e.funnelCategory === 20 && e.funnelName === 'Scored'
        )[0];
        urlDynamicQueryUpdateLite('funnelId', scoredFunnel.funnelId);
      }
    }
    setButtonLoadingState(false);
  };

  const handleUpdateStageClicked = async () => {
    setButtonLoadingState(true);
    const data = { funnelId: funnelInfo?.funnelId, funnelName: funnelName };
    const resp = await dispatch(updateFunnelNameAction(data));
    if (resp?.meta?.requestStatus === 'fulfilled') {
      dispatch(setInfoBannerShowState(true));
      dispatch(setInfoBannerText('Stage name is updated'));
      dispatch(setInfoBannerErrorFlag(false));
      setShow(false);
    }
    setButtonLoadingState(false);
  };

  const addNewStage = async () => {
    setButtonLoadingState(true);
    const data = { companyAssessmentId: assessmentId, funnelName: newFunnel };
    const resp = await dispatch(addNewFunnelAction(data));
    if (resp?.meta?.requestStatus === 'fulfilled') {
      dispatch(setInfoBannerShowState(true));
      dispatch(setInfoBannerText('New stage is created'));
      dispatch(setInfoBannerErrorFlag(false));
      setShow(false);
    }
    setButtonLoadingState(false);
  };

  const handleMove = async () => {
    setButtonLoadingState(true);
    const isSuccess = await moveCandidateToFunnel(
      funnelInfo,
      assessmentId,
      selectedCandidates?.map(e => e.userId),
      selectedAcceptedCandidates?.map(e => e.userId),
      dispatch,
      selectedFunnelId
    );
    if (isSuccess) {
      setShow(false);
    }
    setButtonLoadingState(false);
  };

  const Mobile = (
    <MobileModal
      id={`funnel-mobile-modal-${popupType}`}
      onClose={handleOnClose}
      outsideClickClose
      show={show}
      // outsideTouchClose
      contentStyle={{ padding: '20px', minWidth: '375px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      {popupType === 'Move-NotNew' && (
        <div className={styles.contentWrapperMobile}>
          <label className={styles.titleMobile}>Move Candidates</label>
          <div className={styles.sentenceWrapper}>
            <text className={styles.textMobile}>These&nbsp;</text>
            <text className={styles.boldTextMobile}>
              {allSelectedCandCount}&nbsp;
              {allSelectedCandCount <= 1 ? 'candidate' : 'candidates'}
            </text>
            <text className={styles.textMobile}>&nbsp;will be moved to the&nbsp;</text>
            <text className={styles.boldText}>&apos;{funnelInfo?.funnelName}&apos;</text>
            <text className={styles.textMobile}>&nbsp;stage.</text>
          </div>
          <div className={styles.endWrapperMobile}>
            <CustomButton
              textField="Cancel"
              type={8}
              buttonOnClick={handleOnClose}
              size="mobile"
            />
            <CustomButton
              size="mobile"
              textField="Confirm"
              buttonOnClick={handleMove}
              style={{ marginLeft: '0.4rem' }}
              loading={buttonLoadingState}
            />
          </div>
        </div>
      )}
      {popupType === 'Move-New' && (
        <div className={styles.contentWrapperMobile}>
          <label className={styles.titleMobile}>Move Candidates</label>
          <div className={styles.sentenceWrapper}>
            <text className={styles.textMobile}>These&nbsp;</text>
            <text className={styles.boldTextMobile}>
              {allSelectedCandCount}&nbsp;
              {allSelectedCandCount <= 1 ? 'candidate' : 'candidates'}
            </text>
            <text className={styles.textMobile}>&nbsp;will be moved to the&nbsp;</text>
            <text className={styles.boldTextMobile}>&apos;New&apos;&nbsp;</text>
            <text className={styles.textMobile}>section of the hiring funnel.</text>
          </div>
          <div className={styles.endWrapperMobile}>
            <CustomButton
              textField="Cancel"
              type={8}
              buttonOnClick={handleOnClose}
              size="mobile"
            />
            <CustomButton
              textField="Confirm"
              buttonOnClick={handleMove}
              size="mobile"
              loading={buttonLoadingState}
            />
          </div>
        </div>
      )}

      {popupType === 'Edit' && (
        <div className={styles.contentWrapper}>
          <div className={styles.headerWrapper}>
            <div style={{ display: 'flex' }}>
              <label className={styles.titleMobile}>Edit Hiring Stage&nbsp;</label>
              <label className={styles.titleMobile} style={{ opacity: '0.4' }}>
                In Review
              </label>
            </div>
            <CloseButton onClick={handleOnClose} />
          </div>
          <TextInput
            value={funnelName}
            onChange={e => setFunnelName(e.target.value)}
            style={{ width: '100%', marginTop: '2.4rem', height: '50px' }}
            placeholder="Name this stage"
          />
          <div
            style={{
              display: 'grid',
              minWidth: '100%',
              gridTemplateColumns: '1fr 1fr',
              gridColumnGap: '10px',
              marginTop: '3.2rem'
            }}
          >
            {isDeleteButtonDisabled ? (
              <Tooltip
                customStyle={{ width: '100%' }}
                text="There are candidates in this stage, so it cannot be deleted."
                direction="top"
              >
                <CustomButton
                  isDisabled
                  Icon={Trash}
                  type={9}
                  buttonOnClick={handleDeleteStageClicked}
                  textField="Delete Stage"
                  size="mobile"
                />
              </Tooltip>
            ) : (
              <CustomButton
                Icon={Trash}
                type={9}
                buttonOnClick={handleDeleteStageClicked}
                textField="Delete Stage"
                size="mobile"
              />
            )}

            {isButtonDisabled && funnelName.trim() !== '' ? (
              <Tooltip
                customStyle={{ width: '100%' }}
                text="This stage name already exists."
                direction="top"
              >
                <CustomButton
                  buttonOnClick={handleUpdateStageClicked}
                  textField="Update"
                  size="mobile"
                  isDisabled={isButtonDisabled}
                  loading={buttonLoadingState}
                />
              </Tooltip>
            ) : (
              <CustomButton
                buttonOnClick={handleUpdateStageClicked}
                textField="Update"
                size="mobile"
                isDisabled={isButtonDisabled}
                loading={buttonLoadingState}
              />
            )}
          </div>
        </div>
      )}
      {popupType === 'Delete' && (
        <div className={styles.contentWrapperMobile}>
          <div className={styles.headerWrapper}>
            <div>
              <GoBackButton
                onClick={handleBackClick}
                style={{
                  width: '2.5rem',
                  height: '2.5rem',
                  minWidth: '2.5rem',
                  minHeight: '2.5rem',
                  // marginLeft: '-3.2rem',
                  marginRight: '0.5rem'
                }}
              />
              <text className={styles.titleMobile} style={{ color: '#D35858' }}>
                Delete&nbsp;
                <text className={styles.titleMobile}>
                  &apos;{funnelInfo?.funnelName}&apos;&nbsp;Stage
                </text>
              </text>
            </div>

            <CloseButton onClick={handleOnClose} style={{ minWidth: '3.4rem' }} />
          </div>
          <div className={styles.sentenceWrapper}>
            <span style={{ marginLeft: '2.5rem' }} className={styles.textMobile}>
              If you complete deletion, this stage will be
            </span>
            <span
              className={styles.textMobile}
              style={{
                fontFamily: 'Jost-500'
              }}
            >
              &nbsp;permanently deleted.
            </span>
          </div>
          <div className={styles.endWrapperMobile}>
            <CustomButton
              size="mobile"
              textField="Cancel"
              type={8}
              buttonOnClick={handleOnClose}
            />
            <CustomButton
              size="mobile"
              Icon={WhiteDelete}
              textField="Delete Stage"
              type={6}
              buttonOnClick={deleteStage}
              loading={buttonLoadingState}
            />
          </div>
        </div>
      )}
      {popupType === 'Add' && (
        <div className={styles.contentWrapper}>
          <div className={styles.headerWrapper}>
            <div style={{ display: 'flex' }}>
              <label className={styles.titleMobile}>Add a New Stage&nbsp;</label>
              <label className={styles.titleMobile} style={{ opacity: '0.4' }}>
                In Review
              </label>
            </div>
            <CloseButton onClick={handleOnClose} />
          </div>
          <TextInput
            value={newFunnel}
            onChange={e => setNewFunnel(e.target.value)}
            style={{ width: '100%', marginTop: '2.4rem', height: '50px' }}
            placeholder="Name this stage"
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '3.2rem'
            }}
          >
            {isNewButtonDisabled && newFunnel.trim() !== '' ? (
              <Tooltip text="This stage name already exists." direction="top">
                <CustomButton
                  buttonOnClick={addNewStage}
                  isDisabled={isNewButtonDisabled}
                  textField="Confirm"
                  size="medium"
                  isResponsive
                  loading={buttonLoadingState}
                />
              </Tooltip>
            ) : (
              <CustomButton
                buttonOnClick={addNewStage}
                isDisabled={isNewButtonDisabled}
                textField="Confirm"
                size="mobile"
                isResponsive
                loading={buttonLoadingState}
              />
            )}
          </div>
        </div>
      )}
      {popupType.split('-')[0] === 'Tips' && (
        <div className={styles.contentWrapper}>
          <div className={styles.headerWrapper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tip style={{ width: '25px', height: '25px' }} />
              <label className={styles.titleMobile} style={{ marginLeft: '12px' }}>
                Tips About&nbsp;
              </label>
              <label className={styles.tipTextMobile}>
                &apos;{popupType.split('-')[1] === 'Scored' ? 'Scored' : 'Not Scored'}&apos;&nbsp;
              </label>
            </div>
            <CloseButton onClick={handleOnClose} />
          </div>
          <div
            className={styles.sentenceWrapper}
            style={{
              textAlign: 'left',
              width: '100%'
            }}
          >
            <span className={styles.textMobile}>
              In this section, candidates who have applied for the relevant position&nbsp;
            </span>
            <span className={styles.textMobile}>
              {popupType.split('-')[1] === 'Scored' ? 'and' : 'but have'}
              &nbsp;
            </span>
            <span
              className={styles.textMobile}
              style={{
                fontFamily: 'Jost-400'
              }}
            >
              {popupType.split('-')[1] === 'NotScored' && 'not yet '}completed the assessment&nbsp;
            </span>
            <span className={styles.textMobile}>are listed.</span>
          </div>
        </div>
      )}
    </MobileModal>
  );

  const Web = (
    <Modal
      backdropStyle={{ backdropFilter: 'blur(2px)' }}
      contentStyle={{ borderRadius: '12px', wordBreak: 'break-word' }}
      onClose={handleOnClose}
      size={{ width: '57.2rem' }}
      centerHorizontally
      centerVertically
      show={show}
      outsideClickClose
    >
      {popupType === 'Move-NotNew' && (
        <div
          className={styles.contentWrapper}
          style={{
            padding: '2.7rem 4rem 3.2rem 4rem',
            alignItems: 'start'
          }}
        >
          <label className={styles.title}>Move Candidates</label>
          <div className={styles.sentenceWrapper}>
            <text className={styles.text}>These&nbsp;</text>
            <text className={styles.boldText}>
              {allSelectedCandCount}&nbsp;
              {allSelectedCandCount <= 1 ? 'candidate' : 'candidates'}
            </text>
            <text className={styles.text}>&nbsp;will be moved to the&nbsp;</text>
            <text className={styles.boldText}>&apos;{funnelInfo?.funnelName}&apos;</text>
            <text className={styles.text}>&nbsp;stage.</text>
          </div>
          <div className={styles.endWrapper}>
            <CustomButton
              textField="Cancel"
              type={2}
              buttonOnClick={handleOnClose}
              isResponsive
            />
            <CustomButton
              size="medium"
              textField="Confirm"
              buttonOnClick={handleMove}
              style={{ marginLeft: '0.4rem' }}
              isResponsive
              loading={buttonLoadingState}
            />
          </div>
        </div>
      )}
      {popupType === 'Move-New' && (
        <div
          className={styles.contentWrapper}
          style={{
            padding: '2.7rem 4rem 3.2rem 4rem',
            alignItems: 'start'
          }}
        >
          <label className={styles.title}>Move Candidates</label>
          <div className={styles.sentenceWrapper}>
            <text className={styles.text}>These&nbsp;</text>
            <text className={styles.boldText}>
              {allSelectedCandCount}&nbsp;
              {allSelectedCandCount <= 1 ? 'candidate' : 'candidates'}
            </text>
            <text className={styles.text}>&nbsp;will be moved to the&nbsp;</text>
            <text className={styles.boldText}>&apos;New&apos;&nbsp;</text>
            <text className={styles.text}>section of the hiring funnel.</text>
          </div>
          <div className={styles.endWrapper}>
            <CustomButton
              textField="Cancel"
              type={2}
              buttonOnClick={handleOnClose}
              isResponsive
            />
            <CustomButton
              size="medium"
              textField="Confirm"
              buttonOnClick={handleMove}
              style={{ marginLeft: '0.4rem' }}
              isResponsive
              loading={buttonLoadingState}
            />
          </div>
        </div>
      )}

      {popupType === 'Edit' && (
        <div
          className={styles.contentWrapper}
          style={{
            padding: '2.7rem 4rem 3.2rem 4rem',
            alignItems: 'start'
          }}
        >
          <div className={styles.headerWrapper}>
            <div style={{ display: 'flex' }}>
              <label className={styles.title}>Edit Hiring Stage&nbsp;</label>
              <label className={styles.title} style={{ opacity: '0.4' }}>
                In Review
              </label>
            </div>
            <CloseButton onClick={handleOnClose} />
          </div>
          <TextInput
            value={funnelName}
            onChange={e => setFunnelName(e.target.value)}
            style={{ width: '100%', marginTop: '2.4rem' }}
            placeholder="Name this stage"
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '3.2rem'
            }}
          >
            {isDeleteButtonDisabled ? (
              <Tooltip
                text="There are candidates in this stage, so it cannot be deleted."
                direction="top"
              >
                <div
                  className={styles.deleteButtonWrapper}
                  style={{
                    pointerEvents: 'none',
                    cursor: 'auto',
                    opacity: '0.4'
                  }}
                >
                  <Trash style={{ width: '1rem', height: '1.3rem' }} />
                  <div onClick={handleDeleteStageClicked} className={styles.deleteButton}>
                    Delete stage
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div className={styles.deleteButtonWrapper}>
                <Trash style={{ width: '1rem', height: '1.3rem' }} />
                <div onClick={handleDeleteStageClicked} className={styles.deleteButton}>
                  Delete stage
                </div>
              </div>
            )}

            {isButtonDisabled && funnelName.trim() !== '' ? (
              <Tooltip text="This stage name already exists." direction="top">
                <CustomButton
                  buttonOnClick={handleUpdateStageClicked}
                  textField="Update"
                  size="medium"
                  isDisabled={isButtonDisabled}
                  loading={buttonLoadingState}
                  isResponsive
                />
              </Tooltip>
            ) : (
              <CustomButton
                buttonOnClick={handleUpdateStageClicked}
                textField="Update"
                size="medium"
                isDisabled={isButtonDisabled}
                loading={buttonLoadingState}
                isResponsive
              />
            )}
          </div>
        </div>
      )}
      {popupType === 'Add' && (
        <div
          className={styles.contentWrapper}
          style={{
            padding: '2.7rem 4rem 3.2rem 4rem',
            alignItems: 'start'
          }}
        >
          <div className={styles.headerWrapper}>
            <div style={{ display: 'flex' }}>
              <label className={styles.title}>Add a New Stage&nbsp;</label>
              <label className={styles.title} style={{ opacity: '0.4' }}>
                In Review
              </label>
            </div>
            <CloseButton onClick={handleOnClose} />
          </div>
          <TextInput
            value={newFunnel}
            onChange={e => setNewFunnel(e.target.value)}
            style={{ width: '100%', marginTop: '2.4rem' }}
            placeholder="Name this stage"
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '3.2rem'
            }}
          >
            {isNewButtonDisabled && newFunnel.trim() !== '' ? (
              <Tooltip text="This stage name already exists." direction="top">
                <CustomButton
                  buttonOnClick={addNewStage}
                  isDisabled={isNewButtonDisabled}
                  textField="Confirm"
                  size="medium"
                  isResponsive
                  loading={buttonLoadingState}
                />
              </Tooltip>
            ) : (
              <CustomButton
                buttonOnClick={addNewStage}
                isDisabled={isNewButtonDisabled}
                textField="Confirm"
                size="medium"
                isResponsive
                loading={buttonLoadingState}
              />
            )}
          </div>
        </div>
      )}
      {popupType === 'Delete' && (
        <div
          className={styles.contentWrapper}
          style={{
            padding: '2.7rem 4rem 3.2rem 4rem',
            alignItems: 'start'
          }}
        >
          <div className={styles.headerWrapper}>
            <GoBackButton
              onClick={handleBackClick}
              style={{
                width: '3rem',
                height: '3rem',
                minWidth: '3rem',
                minHeight: '3rem',
                marginLeft: '-3.2rem',
                marginRight: '0rem'
              }}
            />
            <text className={styles.title} style={{ color: '#D35858' }}>
              Delete&nbsp;
              <text className={styles.title}>&apos;{funnelInfo?.funnelName}&apos;&nbsp;Stage</text>
            </text>

            <CloseButton onClick={handleOnClose} style={{ minWidth: '3.4rem' }} />
          </div>
          <div className={styles.sentenceWrapper}>
            <label className={styles.text}>If you complete deletion, this stage will be</label>
            <label
              className={styles.text}
              style={{
                fontFamily: 'Jost-500'
              }}
            >
              &nbsp;permanently deleted.
            </label>
          </div>
          <div className={styles.endWrapper}>
            <CustomButton
              textField="Cancel"
              type={2}
              buttonOnClick={handleOnClose}
              isResponsive
            />
            <CustomButton
              textField="Delete Stage"
              type={6}
              buttonOnClick={deleteStage}
              style={{ marginLeft: '0.4rem' }}
              isResponsive
              loading={buttonLoadingState}
            />
          </div>
        </div>
      )}

      {popupType.split('-')[0] === 'Tips' && (
        <div
          className={styles.contentWrapper}
          style={{
            padding: '2.9rem 4rem 3.2rem 4rem',
            justifyContent: 'flex-start'
          }}
        >
          <div className={styles.headerWrapper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tip style={{ width: '3rem', height: '3rem' }} />
              <label className={styles.title} style={{ marginLeft: '1.6rem' }}>
                Tips About&nbsp;
              </label>
              <label className={styles.tipText}>
                &apos;{popupType.split('-')[1] === 'Scored' ? 'Scored' : 'Not Scored'}&apos;&nbsp;
              </label>
            </div>
            <CloseButton onClick={handleOnClose} />
          </div>
          <div
            className={styles.sentenceWrapper}
            style={{
              textAlign: 'left',
              width: '100%'
            }}
          >
            <label className={styles.text}>
              In this section, candidates who have applied for the relevant position
            </label>
            <label className={styles.text}>
              {popupType.split('-')[1] === 'Scored' ? 'and' : 'but have'}
              &nbsp;
            </label>
            <label
              className={styles.text}
              style={{
                fontFamily: 'Jost-400'
              }}
            >
              {popupType.split('-')[1] === 'NotScored' && 'not yet '}completed the assessment&nbsp;
            </label>
            <label className={styles.text}>are listed.</label>
          </div>
        </div>
      )}
    </Modal>
  );

  return <>{windowW > 576 ? <>{Web}</> : <>{Mobile}</>}</>;
};

export default FunnelPopup;
