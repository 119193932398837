import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UpdateCompany.module.css';
import { CompanyList, SelectedCompany } from '../../../redux/salesScreen/selectors';
import { setCancelBundleSuccessCheck, setCreateTempAndConnectTempActionSuccessCheck, setCustomBundleId, setSelectedCompany } from '../../../redux/salesScreen/slice';
import { getCompanyListAction } from '../../../redux/salesScreen/actions';

const UpdateCompany = () => {
  const options = ['one', 'two', 'three'];
  const history = useHistory();
  const dispatch = useDispatch();
  const companylist = useSelector(CompanyList);
  const selected = useSelector(SelectedCompany);
  const [localCompany, setLocalCompany] = useState([]);
  
  useEffect(() => {
    dispatch(getCompanyListAction());

    // Redux'ı temizle
    dispatch(setSelectedCompany());
    dispatch(setCreateTempAndConnectTempActionSuccessCheck(null));
    dispatch(setCancelBundleSuccessCheck(null));
  }, [])

  useEffect(() => {
    if (companylist) {
      setLocalCompany(companylist.map(x => ({ label: x.name, value: x.id })));
    }
  }, [companylist]);

  return (
    <div className={styles.contentBody}>
      <div className={styles.dropDownContainer}>
        <Dropdown
          className={styles.dropDownClass}
          options={localCompany && localCompany}
          placeholder="select from companies"
          onChange={val => dispatch(setSelectedCompany(val))}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button
          onClick={() => {
            history.goBack();
          }}
          type="button"
        >
          Back
        </button>
        <button
          onClick={() => {
            dispatch(setCustomBundleId(''));
            history.push('/nRdDOsdIFdwEdfdsaSd/3?state=update');
          }}
          type="button"
          disabled={!selected}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default UpdateCompany;
