import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createCommentService,
  updateCommentService,
  deleteCommentService,
  getCommentsService,
  getCommentCountService,
  getCandidateSolutionsServiceVol2,
  getCandidateInfoService
} from './serviceVol2';

// Actions of all comment services

export const getCommentsAction = createAsyncThunk(
  'CommentCandidate/GetCommentsAction',
  async (data, thunkAPI) => {
    try {
      const response = await getCommentsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCommentAction = createAsyncThunk(
  'CommentCandidate/CreateCommentAction',
  async (data, thunkAPI) => {
    try {
      const response = await createCommentService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCommentAction = createAsyncThunk(
  'CommentCandidate/UpdateCommentAction',
  async (data, thunkAPI) => {
    try {
      const response = await updateCommentService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCommentAction = createAsyncThunk(
  'CommentCandidate/DeleteCommentAction',
  async (data, thunkAPI) => {
    try {
      const response = await deleteCommentService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateSolutionsActionVol2 = createAsyncThunk(
  'candidate/getCandidateSolutionsVol2',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateSolutionsServiceVol2(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCommentCountAction = createAsyncThunk(
  'CommentCandidate/GetCommentCountAction',
  async (data, thunkAPI) => {
    try {
      const response = await getCommentCountService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateInfoAction = createAsyncThunk(
  'candidateReport/getCandidateInfo',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateInfoService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
