import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styles from './SalesInfoModal.module.css';
import CloseButton from '../../../Common/Components/CloseButton';
import { InfoText } from '../../../redux/salesScreen/selectors';

const SalesInfoModal = ({ show = true, onHide, text = 'denenenenenenenennenenenenennenen' }) => {
  const infoText = useSelector(InfoText);
  return (
    <Modal
      size="md"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={`${styles.customModal} ${styles['modal-content']} `}
      dialogClassName={styles['modal-dialog']}
      onHide={onHide}
    >
      <CloseButton onClick={onHide} />
      <div className={styles.text}>{infoText}</div>
    </Modal>
  );
};

export default SalesInfoModal;
