import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Coensio } from '../../../../images/Coensio_newLogo.svg';
import { applicantAssessmentDetailAction } from '../../../../redux/AssessmentSession/actions';
import { AssessmentTitle, LogoPath } from '../../../../redux/AssessmentSession/selectors';
import { useWindowSize } from '../../../../utils/helpers';

const HeaderComponent = styled.div`
  background: ${props => (props.transparent ? 'transparent' : '#e5e5e5')};
  padding-left: 3.6rem;
  padding-top: 2rem;
  padding-right: 4.7rem;
  padding-bottom: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    background-color: ${props => (props.transparent ? 'transparent' : 'white')};
    padding-left: 2.4rem;
  }
`;

const Powered = styled.div`
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #393939;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = ({ transparent, expired }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { id } = useParams();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const reduxLogoPath = useSelector(LogoPath);
  const assessmentTitle = useSelector(AssessmentTitle);
  const [innerPath, setInnerPath] = useState(reduxLogoPath);
  const [width, height] = useWindowSize();
  const history = useHistory();

  useEffect(async () => {
    if (!expired) {
      if (!assessmentTitle) {
        // If there exists assessmentTitle on redux - so is reduxLogoPath
        if (reduxLogoPath && reduxLogoPath.startsWith('CompanyLogo')) {
          let temp = '';
          temp = temp.concat(`${process.env.REACT_APP_IMAGE_API}/`);
          temp = temp.concat(reduxLogoPath);
          setInnerPath(temp);
        } else if (reduxLogoPath) {
          setInnerPath(reduxLogoPath);
        } else if (!reduxLogoPath && reduxLogoPath !== '') {
          const data = {
            Key: assessmentId || id
          };
          const resp = await dispatch(applicantAssessmentDetailAction(data));
          if (resp?.payload?.companyAssessmentObjStatusId === 0) {
            history.push('/not-found');
          }
        }
      } else if (reduxLogoPath && reduxLogoPath.startsWith('CompanyLogo')) {
        let temp = '';
        temp = temp.concat(`${process.env.REACT_APP_IMAGE_API}/`);
        temp = temp.concat(reduxLogoPath);
        setInnerPath(temp);
      } else if (reduxLogoPath) {
        setInnerPath(reduxLogoPath);
      }
    }
  }, [reduxLogoPath]);

  return (
    <HeaderComponent transparent>
      {expired ? (
        <div />
      ) : width <= 576 ? (
        <img alt="" src={innerPath} style={{ maxHeight: 20, maxWidth: 110 }} />
      ) : (
        <img alt="" src={innerPath} style={{ maxHeight: '3.2rem', maxWidth: '13.7rem' }} />
      )}
      <Row>
        <Powered>Powered by</Powered>
        <Coensio style={{ maxWidth: 80, maxHeight: 32, marginLeft: '0.6rem', height: '32px' }} />
      </Row>
    </HeaderComponent>
  );
};

export default Header;
