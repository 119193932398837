import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../../Common/Components/CustomButton';
import Dropdown from '../../../../Common/Components/CustomDropdown/CustomDropdown';
import Tooltip from '../../../../Common/Components/Tooltip';
import UnlabeledSingleSelectDropdown from '../../../../Common/Components/UnlabeledSingleSelectDropdown/UnlabeledSingleSelectDropdown';
import {
  defaultAssessmentFunnelListSelector,
  positionRequestPayStatusSelector,
  positionRequestStatusSelector
} from '../../../../redux/candidateDetail/selectors';
import {
  dateFormatter,
  getParam,
  urlDynamicQueryUpdateLite,
  useWindowSize
} from '../../../../utils/helpers';
import shield from './shield.svg';
import mailIcon from './mailIcon.svg';
import styles from './Positions.module.css';
import TextOverflow from '../../../../Common/TextOverflow/TextOverflow';
import {
  setCurrentAssessmentInfo,
  setInfoBannerShow,
  setInfoBannerText,
  setRequestModalState,
  setShowChangeFunnelPopup,
  setShowRejectionPopupFlag
} from '../../../../redux/candidateDetail/slice';
import { moveCandidateToFunnel, setRejectedOnDropdown, sortOnPriority } from '../helpers';
import constants from '../../../../utils/constants';

const PositionCard = ({ item }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const caID = getParam(location.search, 'defaultAssessmentId');

  // Funnel and position related methods
  // #region
  const defaultAssessmentFunnelListRedux = useSelector(defaultAssessmentFunnelListSelector);
  const [funnelList, setFunnelList] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [initFlag, setInitFlag] = useState(true);

  /* Sets default selected value for dropdown after each time position list is refreshed */
  function setSelectedValueForDropdown() {
    if (item.currentFunnel === 'Rejected') {
      const rejectedFunnel = defaultAssessmentFunnelListRedux.filter(
        e => e.funnelName === item.currentFunnel
      )[0];
      setSelectedValue({ value: rejectedFunnel?.funnelId, label: rejectedFunnel?.funnelName });
      setInitFlag(false);
    } else {
      setSelectedValue(funnelList?.filter(e => e.label === item.currentFunnel)[0]);
      setInitFlag(true);
    }
  }

  /* Sets funnel list for 'Current Position' assessment */
  useEffect(() => {
    if (defaultAssessmentFunnelListRedux && defaultAssessmentFunnelListRedux.length > 0) {
      setInitFlag(true);
      let temp = [...defaultAssessmentFunnelListRedux].sort(sortOnPriority);
      const isScored = item.score !== -1;
      temp = temp.filter(e =>
        isScored
          ? e.funnelName !== 'Not Scored' && e.funnelName !== 'Rejected'
          : e.funnelName !== 'Scored' && e.funnelName !== 'Rejected'
      );
      const funnelListForDropdown = [];
      temp.forEach(e => funnelListForDropdown.push({ value: e.funnelId, label: e.funnelName }));
      setFunnelList(funnelListForDropdown);
    }
  }, [defaultAssessmentFunnelListRedux]);

  useEffect(() => {
    if (funnelList) {
      setSelectedValueForDropdown();
    }
  }, [funnelList]);

  /* Funnel update for default selected assessment on dropdown click */
  function moveCandidateToFunnelInner() {
    const data = {
      UserId: id,
      companyAssessmentId: caID
    };
    moveCandidateToFunnel(dispatch, id, selectedValue, true);
  }

  useEffect(async () => {
    if (selectedValue && selectedValue.label !== 'Rejected') {
      if (!initFlag) {
        // To prevent api call on initial loading
        moveCandidateToFunnelInner();
      } else {
        setInitFlag(false);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedValueForDropdown();
  }, [item.currentFunnel]);

  const [isSelected, setIsSelected] = useState(false);

  function updateStatesOnAssessmentChange() {
    const currentAssessId = getParam(location.search, 'currentAssessmentId');

    if (currentAssessId === item?.companyAssessmentId) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }

  function setRejectedOnDropdownInner() {
    const rejectedFunnel = defaultAssessmentFunnelListRedux.filter(
      e => e.funnelName === 'Rejected'
    )[0];
    setRejectedOnDropdown(setSelectedValue, rejectedFunnel);
  }

  // #endregion

  // Event listeners
  useEffect(() => {
    updateStatesOnAssessmentChange();
    window.addEventListener('locationchange', updateStatesOnAssessmentChange);
    window.addEventListener('setRejectedOnDropdownEvent', setRejectedOnDropdownInner);

    return () => {
      window.removeEventListener('locationchange', updateStatesOnAssessmentChange);
      window.removeEventListener('setRejectedOnDropdownEvent', setRejectedOnDropdownInner);
    };
  }, []);

  /* Width calculations for textoverflow */
  // #region
  const [widthForPositionName, setWidthForPositionName] = useState();
  const [widthForFunnelName, setWidthForFunnelName] = useState();
  const [screenW, screenH] = useWindowSize();
  const [widthForFunnelDropdown, setWidthForFunnelDropdown] = useState();


  const positionRequestStatusRedux = useSelector(positionRequestStatusSelector);
  const positionRequestPayStatusRedux = useSelector(positionRequestPayStatusSelector);
  const isRequestCandidate =
    positionRequestStatusRedux &&
    (positionRequestStatusRedux !== constants.REQUEST_STATUS_ACCEPTED ||
      (positionRequestStatusRedux === constants.REQUEST_STATUS_ACCEPTED &&
        positionRequestPayStatusRedux === false));
  const isNewRequestCandidate =
    positionRequestStatusRedux && positionRequestStatusRedux === constants.REQUEST_STATUS_NEW;


  useEffect(() => {
    /**
     * Right after page is loaded, this useEffect is run, and at that time reject button,
     * current position label or is suspect icon is not rendered yet. That is why, computations
     * are off by a few px. We add a tiny bit of timeout for components to be rendered.
     */
    setTimeout(() => {
      const upperPartWidth = document
        .getElementById(`positionUpperPart-${item.companyAssessmentId}`)
        ?.getBoundingClientRect()?.width;

      let currentPosLabelWidth = 0;
      if (item?.companyAssessmentId === getParam(location.search, 'defaultAssessmentId') && !isRequestCandidate) {
        currentPosLabelWidth =
          document.getElementById('currentPositionLabel')?.getBoundingClientRect()?.width + 15;
      }
      
      let isSuspectLabelWidth = 0;
      if (item?.isSuspect) {
        isSuspectLabelWidth =
          document.getElementById('isSuspectLabel')?.getBoundingClientRect()?.width + 10;
      }
      setWidthForPositionName(upperPartWidth - currentPosLabelWidth - isSuspectLabelWidth);

      if (
        !isSelected ||
        getParam(location.search, 'defaultAssessmentId') !==
          getParam(location.search, 'currentAssessmentId')
      ) {
        const statusWidth = document.getElementById('statusText')?.getBoundingClientRect()?.width;
        setWidthForFunnelName(upperPartWidth - statusWidth);
      }

      if (
        isSelected &&
        getParam(location.search, 'defaultAssessmentId') ===
          getParam(location.search, 'currentAssessmentId')
      ) {
        const rejectButtonWidth = document
          .getElementById('positionRejectButton')
          ?.getBoundingClientRect()?.width;
        setWidthForFunnelDropdown(upperPartWidth - rejectButtonWidth - 18);
      }
    }, 5);
  }, [isSelected, screenW]);
  // #endregion

  /* Color, percentage, date calculations below */
  // #region
  const [color, setColor] = useState();
  const [percentage, setPercentage] = useState();

  useEffect(() => {
    if (item) {
      if (item.allScoreAverage !== null) {
        if (item.score === -1) {
          setColor('#FF7E62');
        } else if (item.score >= item.allScoreAverage) {
          setColor('#4AC389');
        } else if (item.score >= item.allScoreAverage * 0.8) {
          setColor('#FFD600');
        } else {
          setColor('#CA1515');
        }
      } else {
        setColor('#FFD600');
      }

      if (!item.allScoreAverage) {
        setPercentage(100);
      } else {
        setPercentage(
          Math.round(
            (Math.abs(Math.floor(item.allScoreAverage) - item.score) /
              Math.floor(item.allScoreAverage)) *
              100
          )
        );
      }
    }
  }, [item]);
  // #endregion

  return (
    <div
      className={styles.PositionCardWrapper}
      style={!isSelected ? { cursor: 'pointer' } : { border: '1px solid #7458D3' }}
      onClick={() => {
        if (getParam(location.search, 'currentAssessmentId') !== item?.companyAssessmentId) {
          urlDynamicQueryUpdateLite('currentAssessmentId', item?.companyAssessmentId);
        }
      }}
      id={`positionCardWrapper-${item.companyAssessmentId}`}
    >
      <div className={styles.UpperPart} id={`positionUpperPart-${item.companyAssessmentId}`}>
        <div className={styles.PositionNameRow}>
          <TextOverflow
            itemId={`positionName-${item?.companyAssessmentId}`}
            text={item?.reservedName}
            textClassName={styles.PositionName}
            width={widthForPositionName}
          />
          {item?.isSuspect && (
            <div className={styles.isSuspectOverlay} id="isSuspectLabel">
              <Tooltip
                color="#DE4747"
                maxSize={300}
                text="The candidate solved the assessment more than once with different email addresses. You can also take this into account when reviewing the candidate’s score."
              >
                <img id="shield-img" alt="shield" src={shield} className={styles.shield} />
              </Tooltip>
            </div>
          )}
          {item?.companyAssessmentId === getParam(location.search, 'defaultAssessmentId') &&
            !isRequestCandidate && (
              <div className={styles.CurrentPositionDiv} id="currentPositionLabel">
                Current Position
              </div>
            )}
        </div>

        <div className={styles.ScoreWrapper} style={{ borderColor: color }}>
          <div className={styles.ScoreRow}>
            {/* <div className={styles.ScoreCircle} style={{ background: color }} /> */}
            <text>{item?.score !== -1 ? `${item.score}/100` : 'N/A'}</text>
          </div>

          <text style={{ color: 'rgba(57, 57, 57, 0.5)' }}>
            {item.allScoreAverage === null ? (
              <text>Industry average not available</text>
            ) : item.score !== -1 ? (
              item.score >= item.allScoreAverage ? (
                <>
                  <text style={{ color: color }}>{percentage}% better</text>&nbsp;than industry
                  average
                </>
              ) : item.score >= item.allScoreAverage * 0.8 ? (
                <>
                  <text style={{ color: color }}>Average</text>&nbsp;in industry
                </>
              ) : (
                <>
                  <text style={{ color: color }}>{percentage}% worse</text>&nbsp;than industry
                  average
                </>
              )
            ) : (
              <>
                <text style={{ color: color }}>Not take</text>&nbsp;the assessment yet
              </>
            )}
          </text>
        </div>
      </div>

      {!isRequestCandidate ? (
        <div className={styles.MiddlePart}>
          {isSelected &&
          getParam(location.search, 'defaultAssessmentId') ===
            getParam(location.search, 'currentAssessmentId') ? (
            <>
              <UnlabeledSingleSelectDropdown
                list={funnelList}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                placeholder="Stages"
                wrapperStyle={{ width: `${widthForFunnelDropdown}px` }}
                disableDefaultOnClick={screenW <= 960}
                optionalOnClick={() => {
                  if (screenW <= 960) {
                    dispatch(setShowChangeFunnelPopup(true));
                    dispatch(setCurrentAssessmentInfo(item));
                  }
                }}
              />
              <CustomButton
                buttonId="positionRejectButton"
                type={6}
                textField="Reject"
                size="small"
                style={{ marginLeft: '18px', height: '42px' }}
                isDisabled={selectedValue?.label === 'Rejected'}
                buttonOnClick={() => dispatch(setShowRejectionPopupFlag(true))}
              />
            </>
          ) : (
            <text className={styles.FunnelStatusText}>
              <text id="statusText">Status:&nbsp;</text>
              <TextOverflow
                itemId={`positionFunnel-${item?.companyAssessmentId}`}
                text={item?.currentFunnel}
                textStyle={{ color: '#393939' }}
                width={widthForFunnelName}
              />
              {/* <text style={{ color: '#393939' }}>{item.currentFunnel}</text> */}
            </text>
          )}
        </div>
      ) : (
        <>
          <CustomButton
            buttonId="positionsSendRequestButton"
            type={1}
            textField="Click to Send Request"
            size="small"
            style={{ height: '42px', width: '100%' }}
            buttonOnClick={() => dispatch(setRequestModalState(true))}
            isDisabled={!isNewRequestCandidate}
            tooltipText="You've already sent a request to this candidate."
          />
        </>
      )}

      <div className={styles.LowerPart}>
        {item?.arriveType === 10 ? (
          <>
            <img src={mailIcon} alt="mailIcon" style={{ marginRight: '10px' }} />
            Invited on {dateFormatter(item.addDate, true)}
          </>
        ) : (
          `${!isRequestCandidate ? 'Applied' : 'Recommended'} on ${dateFormatter(
            item.addDate,
            true
          )}`
        )}
      </div>
    </div>
  );
};
export default PositionCard;
