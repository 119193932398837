/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { UserContext } from '../Pages/CandidatePage';
import CandidateQuestionBar from './CandidateQuestionBar';
import CandidateQuestionDetail from './CandidateQuestionDetail';
import styles from '../Styles/CandidateQuestionWrapper.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '../../Common/Components/Skeleton';
import { getCandidatePublicSolutions } from '../../redux/CandidatePublic/selector';
import { getCandidateSolutionsActionVol2 } from '../../redux/candidate/actions';
import { ReactComponent as Lock } from '../../images/lock.svg';
import { ReactComponent as UnLock } from '../../images/unlock.svg';
import ContactSalesSuccess from '../../Auth/Modals/UnsubscribeSuccessModal';

import HeadsUpModal from '../../Auth/Modals/HeadsUpModal';
import {
  currentPlanRedux,
  currentUsageRedux,
  toBePurchasedAddOnRedux
} from '../../redux/plan/selectors';
import { isScoredRedux } from '../../redux/CandidateReport/selector';
import PurchaseAddOnSuccessModal from '../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import { setToBePurchasedAddOn } from '../../redux/plan/slice';
import { getCurrentUsageAction, unlockCandidateReportAction } from '../../redux/plan/actions';
import FreeTrialModal from '../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import { setIsScoredRedux } from '../../redux/CandidateReport/slice';
import { userSelector } from '../../redux/auth/selectors';

function CandidateQuestionWrapper({ setPurchaseSuccess }) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const publicQuestion = useSelector(getCandidatePublicSolutions);

  // const [selectedAssessmentId, setAssessId] = useState(useContext(UserContext) ? useContext(UserContext)[2]: null);
  const selectedAssessmentId = useContext(UserContext) ? useContext(UserContext)[2][0] : null;
  // const [candidateEmail, setEmail] = useState(useContext(UserContext) ? useContext(UserContext)[0] : null);
  const candidateEmail = useContext(UserContext) ? useContext(UserContext)[0] : null;
  const [questionArray, setQuestionArray] = useState();
  const [score, setScore] = useState();
  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState('Locked');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const myPlan = useSelector(currentPlanRedux);
  const usage = useSelector(currentUsageRedux);
  const [topPosition, setTopPosition] = useState(0);
  const reduxIsScored = useSelector(isScoredRedux);
  const toBePurchasedAddOn = useSelector(toBePurchasedAddOnRedux);
  const user = useSelector(userSelector);
  const [lockPosition, setLockPosition] = useState(0);

  useEffect(() => {
    setInterval(() => {
      const top = document.getElementById('QuestionsBar')?.getBoundingClientRect().top;
      setLockPosition(top + 150);
    }, 10);
  });

  const publicPageCheck = () => {
    if (publicQuestion) {
      setQuestionArray(publicQuestion.solutionList);
      setScore(publicQuestion.totalScore);
      dispatch(setIsScoredRedux(!!publicQuestion.totalScore));
      setIsAuthorized(true);
      setLoading(false);
    }
  };

  async function unlockFree() {
    const data = {
      email: candidateEmail,
      companyAssessmentId: selectedAssessmentId
    };
    const a = await dispatch(unlockCandidateReportAction(data));
    if (a?.meta?.requestStatus === 'fulfilled') {
      await setPurchaseSuccess(true);
      await setPurchasedSuccess(true);
    } else {
      setContinuePurchase(true);
    }
  }

  useEffect(() => {
    let data = {};
    if (myPlan?.name !== 'Free Trial') {
      data = {
        bundleItemCode: 30,
        email: candidateEmail,
        companyAssessmentId: selectedAssessmentId
      };
    } else {
      data = {
        email: candidateEmail,
        companyAssessmentId: selectedAssessmentId
      };
    }
    dispatch(setToBePurchasedAddOn(data));
    publicPageCheck();
  }, []);

  const Unlock = () => {
    return (
      <div className={styles.unlock} style={{ marginTop: lockPosition }}>
        {state === 'Locked' ? (
          <Lock
            className={styles.icon}
            onMouseEnter={() => {
              if (myPlan?.name !== 'Free Trial') {
                setState('Unlock');
              } else {
                setState(`Free Unlock ${usage?.usedCandidateReports}/3`);
              }
            }}
          />
        ) : (
          <UnLock
            className={styles.icon}
            onMouseLeave={() => {
              setState('Locked');
            }}
            onClick={() => {
              if (myPlan?.name === 'Free Trial') {
                unlockFree();
              } else {
                setPaymentModal(true);
              }
              setState('Locked');
            }}
          />
        )}
        <div
          style={{ width: myPlan?.name === 'Free Trial' && '190px' }}
          onMouseEnter={() => {
            if (myPlan?.name !== 'Free Trial') {
              setState('Unlock');
            } else {
              setState(`Free Unlock ${usage?.usedCandidateReports}/3`);
            }
          }}
          onMouseLeave={() => {
            setState('Locked');
          }}
          className={state === 'Locked' ? styles.textWrapper : styles.textWrapperHover}
          onClick={() => {
            if (myPlan?.name === 'Free Trial') {
              unlockFree();
            } else {
              setPaymentModal(true);
            }
            setState('Locked');
          }}
        >
          <span className={myPlan?.name === 'Free Trial' && styles.freeText}>{state}</span>
        </div>
      </div>
    );
  };
  // TODO: Api call: email + selected ass. id -> question id array
  useEffect(async () => {
    if (!publicQuestion) {
      if (selectedAssessmentId && candidateEmail) {
        ref.current = Date.now(); // Get current time
        const local = ref.current; // store current time in local variable
        setLoading(true);
        const jsonData = {
          CompanyAssessmentId: selectedAssessmentId,
          Email: candidateEmail
        };
        const result = await dispatch(getCandidateSolutionsActionVol2(jsonData));
        if (local === ref.current) {
          if (result.payload.solutionList) {
            setQuestionArray(result.payload.solutionList);
            setScore(result.payload.totalScore);
            setIsAuthorized(result.payload.isAuthorize);
            setPrice(result.payload?.price);
          } else {
            setQuestionArray([]);
            setScore(-1);
            setIsAuthorized(true);
            setPrice('1');
          }
          setLoading(false);
        }
      }
    }
  }, [selectedAssessmentId, candidateEmail]);

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [purchasedSuccess, setPurchasedSuccess] = useState(false);
  const [continuePurchase, setContinuePurchase] = useState(false);

  useEffect(async () => {
    if (purchasedSuccess) {
      const jsonData = {
        CompanyAssessmentId: selectedAssessmentId,
        Email: candidateEmail
      };
      await dispatch(getCandidateSolutionsActionVol2(jsonData)).then(result => {
        setQuestionArray(result.payload.solutionList);
        setScore(result.payload.totalScore);
        setIsAuthorized(result.payload.isAuthorize);
        setPrice(result.payload?.price);
      });
      dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
      setPurchaseSuccess(true);
    }
  }, [purchasedSuccess]);

  return (
    <div className={styles.BodyWrapper}>
      {score !== -1 && reduxIsScored && <CandidateQuestionBar id="QuestionsBar" />}
      {loading && (
        <>
          <Skeleton type={'question'} />
          <Skeleton type={'question'} />
          <Skeleton type={'question'} />
        </>
      )}
      {(!loading && score === -1) ||
        (!reduxIsScored && !loading && (
          <div className={styles.InfoWrapper}>
            This candidate has not started the assessment yet. Therefore there are no test results
            to show.
          </div>
        ))}
      {!loading && score !== -1 && reduxIsScored && (
        <>
          {!isAuthorized && <Unlock />}
          {questionArray.length === 0 && (
            <div className={styles.InfoWrapper}>No question to show</div>
          )}
          {questionArray.length !== 0 && (
            <div
              style={!isAuthorized ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}
              className={styles.noSelect}
            >
              {questionArray.map((e, index) => (
                <CandidateQuestionDetail item={e} index={index} isAuthorized={isAuthorized} />
              ))}
            </div>
          )}
        </>
      )}

      <HeadsUpModal
        addOnCode={30}
        show={paymentModal}
        onHide={() => setPaymentModal(false)}
        title={myPlan?.isCustom ? 'Hello there!' : 'Want to unlock?'}
        description={
          !myPlan?.isCustom
            ? `Unlock this candidate report for only $${price}.`
            : `Please contact our sales team to gain more insight of the candidates by seeing their answers.`
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchasedSuccess}
      />

      <FreeTrialModal
        show={continuePurchase}
        onHide={() => setContinuePurchase(false)}
        addOnCode={30}
      />
      {/* Purchase AddOn Success */}
      <PurchaseAddOnSuccessModal
        show={purchasedSuccess}
        onHide={() => setPurchasedSuccess(false)}
        addOnCode={30}
      />
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />
    </div>
  );
}

export default CandidateQuestionWrapper;
