import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isSafari } from 'react-device-detect';
import CloseButton from '../../../Common/Components/CloseButton';
import styles from './Funnel.module.css';
import Edit from './edit-icon.svg';
import Info from './info-icon.svg';
import {
  assessmentDetailObjSelector,
  candidateListSelector,
  funnelIdSelector,
  funnelInfoSelector,
  funnelOpenSelector,
  isMoveEnabledFlagSelector,
  loadingForFirstFetchSelector,
  selectedAcceptedCandidateListSelector,
  selectedCandidateListSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  getParam,
  moveCandidateToFunnelPopup,
  resetFiltersForFunnelClick,
  urlDynamicQueryUpdateLite,
  useWindowSize
} from '../../../utils/helpers';
import {
  resetCandidateListRelatedStuffForNewFilters,
  setClickedFunnel,
  setInfoBannerShowState,
  setInfoBannerText,
  setIsMoveEnabledFlag,
  setPopupShowState,
  setPopupTypeState,
  setRejectModalSelectedCount,
  setRejectModalState
} from '../../../redux/assessmentDetailVol2/slice';
import TextOverflow from '../../../Common/TextOverflow/TextOverflow';
import { useIp } from '../../../hooks/useIp';
import Tooltip from '../../../Common/Components/Tooltip';
import { ReactComponent as Link } from './link.svg';
import { fetchShortUrlAction } from '../../../redux/assessmentDetailVol2/actions';
import mixpanel from '../../../utils/mixpanel';
import {
  mixpanelReturnAssessmentDetailRelatedProperties,
  mixpanelReturnUserRelatedProperties
} from '../../../utils/mixpanelHelpers';
import { userSelector } from '../../../redux/auth/selectors';
import Modal from '../../../Common/CustomModal/Modal';
import CustomButton from '../../../Common/Components/CustomButton';

export default function FunnelItem({
  funnel,
  key,
  updateInAction,
  isMobile = false,
  mobileCloseFunnel = () => {}
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loc, currency] = useIp();
  const selectedFunnel = useSelector(funnelIdSelector);
  const isMoveEnabledRedux = useSelector(isMoveEnabledFlagSelector);

  const [scrennW, screenH] = useWindowSize();
  const [isHovered, setIsHovered] = useState(false);
  const [copiedState, setCopiedState] = useState('');
  const [copyModal, setCopyModal] = useState(false);

  const handleClick = () => {
    if (funnel.funnelCategory === 20 && funnel.funnelName === 'Scored') {
      dispatch(setPopupTypeState('Tips-Scored'));
      dispatch(setClickedFunnel(funnel));
      dispatch(setPopupShowState(true));
    } else if (funnel.funnelCategory === 20 && funnel.funnelName === 'Not Scored') {
      dispatch(setPopupTypeState('Tips-NotScored'));
      dispatch(setClickedFunnel(funnel));
      dispatch(setPopupShowState(true));
    }
    if (funnel.funnelCategory === 30) {
      dispatch(setPopupTypeState('Edit'));
      dispatch(setClickedFunnel(funnel));
      dispatch(setPopupShowState(true));
    }
  };

  const selectedCandidateListRedux = useSelector(selectedCandidateListSelector);
  const selectedAcceptedCandidateListRedux = useSelector(selectedAcceptedCandidateListSelector);
  const selectedFunnelIdRedux = useSelector(funnelIdSelector);

  // When getCandidates api is pending
  const firstFetchCand = useSelector(loadingForFirstFetchSelector);

  /* -------------------------------*/

  const funnelOpen = useSelector(funnelOpenSelector);
  const [allowedW, setAllowedW] = useState();

  useEffect(() => {
    const f = document
      .getElementById(`funnelItem-${funnel.funnelName}`)
      ?.getBoundingClientRect()?.width;
    setAllowedW(isHovered && !isMoveEnabledRedux ? f - 140 : f - 110);
    // setAllowedW(f - 110);
  }, [scrennW, funnelOpen, isHovered, isMoveEnabledRedux]);

  /* Functions for disabling Not scored on Scored funnel and vice versa */
  const funnels = useSelector(funnelInfoSelector);
  const [newStageSelectedFlag, setNewStageSelectedFlag] = useState(false);
  const isNewStageSelected = () => {
    if (selectedFunnelIdRedux === null || selectedFunnelIdRedux === '') {
      setNewStageSelectedFlag(true);
    } else {
      const temp = funnels.filter(f => f.funnelId === selectedFunnelIdRedux);
      setNewStageSelectedFlag(temp[0]?.funnelCategory === 20);
    }
  };

  useEffect(() => {
    isNewStageSelected();
  }, [selectedFunnelIdRedux]);

  useEffect(() => {
    if (!isMoveEnabledRedux) {
      document
        .getElementById(`funnelName-${funnel.funnelName}`)
        ?.classList?.remove(`${styles.moveHover}`);
    }
  }, [isMoveEnabledRedux]);

  const checkDescendancy = e => {
    const parentElement = document.getElementById(`funnelName-${funnel.funnelName}`);
    if (e.target === parentElement || parentElement?.contains(e.target)) {
      return true;
    }
    return false;
  };

  /* Share stage icon */
  // #region

  const currentUser = useSelector(userSelector);
  const assessDetail = useSelector(assessmentDetailObjSelector);

  function mixpanelShareStageEvent() {
    const userData = mixpanelReturnUserRelatedProperties(currentUser);
    const assesmentData = mixpanelReturnAssessmentDetailRelatedProperties(assessDetail);
    mixpanel.track('Share Stage', {
      ...userData,
      ...assesmentData
    });
  }

  async function fetchShortUrl() {
    let sentUrl;
    if (getParam(window.location.href, 'funnelId')) {
      sentUrl = window.location.href.replace(
        getParam(window.location.href, 'funnelId'),
        funnel.funnelId
      );
    } else {
      sentUrl = window.location.href.replace('funnelId=', `funnelId=${funnel.funnelId}`);
    }
    sentUrl = sentUrl.concat('&utm_source=hr_email&utm_medium=share_stage&utm_name=to_review');
    const data = {
      url: sentUrl
    };
    await dispatch(fetchShortUrlAction(data)).then(resp => {
      if (resp?.meta?.requestStatus === 'fulfilled') {
        const shortUrl = resp?.payload?.shortUrl;
        setCopiedState(shortUrl);
        if (!isSafari) {
          navigator.clipboard.writeText(shortUrl);
          dispatch(setInfoBannerText('Short link is copied!'));
          dispatch(setInfoBannerShowState(true));
        } else {
          setCopyModal(true);
        }
      }
    });
    mixpanelShareStageEvent();
  }

  // #endregion

  let counter = 0;

  return (
    <>
      <div
        className={`${styles.item}`}
        id={`funnelItem-${funnel.funnelName}`}
        categoryNo={funnel.funnelCategory}
        noInteraction={
          (funnel.funnelCategory === 10 ||
            funnel.funnelCategory === 20) &&
          isMoveEnabledRedux &&
          'not-allowed'
        }
      >
        <Modal
          size={{ width: '450px' }}
          modalId="safari-share-stage-copy-modal"
          contentStyle={{ padding: '24px', borderRadius: '20px' }}
          show={copyModal}
          onClose={() => setCopyModal(false)}
          centerHorizontally
          centerVertically
          outsideClickClose
        >
          <div className={styles.copyModalWrapper}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
              }}
            >
              <div className={styles.copiedHeader}>Share what you see with your colleagues!</div>
              <CloseButton
                size="small"
                onClick={() => {
                  setCopyModal(false);
                }}
              />
            </div>
            <div className={styles.copiedSpan}>{copiedState}</div>
            <CustomButton
              customStyle={{ width: '100%' }}
              textField="Copy Link"
              size="medium"
              type={1}
              buttonOnClick={() => {
                navigator.clipboard.writeText(copiedState);
                setCopyModal(false);
                dispatch(setInfoBannerText('Short link is copied!'));
                dispatch(setInfoBannerShowState(true));
              }}
            />
          </div>
        </Modal>
        <div
          id={`funnelName-${funnel.funnelName}`}
          className={`${styles['item-name']} ${
            (selectedFunnel === funnel.funnelId ||
              // Default selected
              (funnel.funnelCategory === 20 &&
                funnel.funnelName === 'Scored' &&
                selectedFunnel === '')) &&
            styles.selected
          }`}
          style={
            isMoveEnabledRedux
              ? funnel.funnelCategory === 10 ||
                (selectedFunnelIdRedux && funnel?.funnelId?.includes(selectedFunnelIdRedux)) ||
                (newStageSelectedFlag && funnel.funnelCategory === 20)
                ? { opacity: '0.4', pointerEvents: 'none' }
                : { boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }
              : {}
          }
          onMouseEnter={() => {
            if (!isMobile) {
              setIsHovered(true);
            }
          }}
          onMouseLeave={() => {
            if (!isMobile) {
              setIsHovered(false);
            }
          }}
          onClick={() => {
            if (
              isMoveEnabledRedux &&
              funnel.funnelCategory === 40 &&
              funnel.funnelName === 'Rejected'
            ) {
              dispatch(setIsMoveEnabledFlag(false));
              dispatch(setRejectModalSelectedCount(selectedCandidateListRedux?.length + selectedAcceptedCandidateListRedux?.length));
              dispatch(setRejectModalState(true));
            } else if (isMoveEnabledRedux) {
              dispatch(setIsMoveEnabledFlag(false));
              moveCandidateToFunnelPopup(
                funnel,
                selectedCandidateListRedux,
                selectedAcceptedCandidateListRedux,
                dispatch,
                id,
                selectedFunnelIdRedux
              );
            } else {
              resetFiltersForFunnelClick(currency);
              if (!firstFetchCand) {
                dispatch(resetCandidateListRelatedStuffForNewFilters());
              }
              urlDynamicQueryUpdateLite('funnelId', funnel.funnelId);
              if (isMobile) {
                setTimeout(() => {
                  mobileCloseFunnel();
                }, 200);
              }
            }
          }}
          onDragEnter={e => {
            if (!isMobile) {
              e.preventDefault();
              counter += 1;
              if (e.target.id === `funnelName-${funnel.funnelName}`) {
                e.target.classList.add(`${styles.moveHover}`);
              } else if (checkDescendancy(e)) {
                const parentElement = document.getElementById(`funnelName-${funnel.funnelName}`);
                parentElement.classList.add(`${styles.moveHover}`);
              }
            }
          }}
          onDragOver={e => {
            if (!isMobile) {
              e.preventDefault();
            }
          }}
          onDragLeave={e => {
            if (!isMobile) {
              e.preventDefault();
              counter -= 1;
              if (counter === 0) {
                if (e.target.id === `funnelName-${funnel.funnelName}`) {
                  e.target.classList.remove(`${styles.moveHover}`);
                } else if (checkDescendancy(e)) {
                  const parentElement = document.getElementById(`funnelName-${funnel.funnelName}`);
                  parentElement.classList.remove(`${styles.moveHover}`);
                }
              }
            }
          }}
          onDrop={() => {
            if (!isMobile) {
              if (funnel.funnelCategory === 40 && funnel.funnelName === 'Rejected') {
                dispatch(setRejectModalSelectedCount(selectedCandidateListRedux?.length + selectedAcceptedCandidateListRedux?.length));
                dispatch(setRejectModalState(true));
              } else {
                moveCandidateToFunnelPopup(
                  funnel,
                  selectedCandidateListRedux,
                  selectedAcceptedCandidateListRedux,
                  dispatch,
                  id,
                  selectedFunnelIdRedux
                );
              }
            }
          }}
        >
          <TextOverflow
            disabled={updateInAction}
            text={funnel.funnelName}
            itemId={`fITEM-${funnel.funnelId}`}
            position="top"
            width={allowedW}
          />

          <div
            className={styles.ShareIconWrapper}
            id={`shareIcon-${funnel.funnelName}`}
            onClick={e => {
              e.stopPropagation();
              fetchShortUrl();
            }}
            style={isMoveEnabledRedux ? { display: 'none' } : {}}
          >
            <Tooltip
              customStyle={{ lineHeight: '23px' }}
              direction="right"
              text="Share what you see with your colleagues!"
              fontFamilyForText1="Jost-300"
              fontSize="14px"
              tooltipId="shareIconTooltip"
            >
              <Link className={styles.link} />
            </Tooltip>
          </div>

          <span>{funnel.userCount} </span>
        </div>
        <span
          className={`${styles['icon-wrapper']} ${
            funnel.funnelCategory === 20
              ? styles.infoIcon
              : funnel.funnelCategory === 30
              ? styles.editIcon
              : null
          } ${
            isMobile &&
            (selectedFunnel === funnel.funnelId ||
              // Default selected
              (funnel.funnelCategory === 20 &&
                funnel.funnelName === 'Scored' &&
                selectedFunnel === ''))
              ? styles['icon-wrapper-mobile']
              : ''
          }`}
          style={isMoveEnabledRedux ? { visibility: 'hidden' } : {}}
          onClick={handleClick}
        >
          <img
            className={styles.icon}
            alt=""
            src={funnel.funnelCategory === 30 ? Edit : funnel.funnelCategory === 20 ? Info : null}
          />
        </span>
      </div>
    </>
  );
}
