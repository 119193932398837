import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoBanner from '../../../Common/Components/InfoBanner';
import {
  forgotPasswordCompanyNameRedux,
  forgotPasswordEdgeCaseStateRedux,
  forgotPasswordErrorRedux,
  forgotPasswordStepRedux
} from '../../../redux/signup/selectors';
import ForgotPasswordComponent from '../../Components/ForgotPassword/ForgotPasswordComponent';
import ForgotPasswordSuccessComponent from '../../Components/ForgotPassword/ForgotPasswordSuccessComponent';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';
import styles from './ForgotPasswordPage.module.css';
import {
  resetForgotPasswordCompanyName,
  resetForgotPasswordEdgeCaseState,
  resetForgotPasswordError,
  setForgotPasswordStep
} from '../../../redux/signup/slice';
import DomainSignupComponent from '../../Components/Signup/DomainSignupComponent';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const forgotPasswordStep = useSelector(forgotPasswordStepRedux);
  const forgotPasswordError = useSelector(forgotPasswordErrorRedux);
  const forgotPasswordEdgeCaseState = useSelector(forgotPasswordEdgeCaseStateRedux);
  const forgotPasswordCompanyName = useSelector(forgotPasswordCompanyNameRedux);

  useEffect(() => {
    dispatch(setForgotPasswordStep(1));
    dispatch(resetForgotPasswordCompanyName());
    dispatch(resetForgotPasswordEdgeCaseState());
    dispatch(resetForgotPasswordError());
  }, []);

  return (
    <div className={styles.ContentBody}>
      {forgotPasswordStep === 1 && <ForgotPasswordComponent />}
      {forgotPasswordStep === 2 && <ForgotPasswordSuccessComponent />}

      {forgotPasswordStep === 3 && (
        <DomainSignupComponent
          title={
            forgotPasswordEdgeCaseState === 1
              ? 'This domain has already signed up'
              : 'Your team has already invited you to Coensio'
          }
          invitation={forgotPasswordEdgeCaseState !== 1}
          companyNameFromParent={forgotPasswordCompanyName}
        />
      )}

      <InfoBanner
        text={forgotPasswordError}
        show={forgotPasswordError}
        CustomIcon={Error}
        setStatus={type => dispatch(resetForgotPasswordError())}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />
    </div>
  );
};

export default ForgotPasswordPage;
