import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './ForgotPasswordSuccessComponent.module.css';
import { ReactComponent as CoensioLogo } from '../../../images/Coensio_newLogo.svg';
import { ReactComponent as Arrow } from '../../Images/backIcon.svg';
import route from '../../../constant/route';

import { forgotPasswordAction } from '../../../redux/signup/actions';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { useInterval } from '../../../utils/useInterval';
import { setForgotPasswordStep } from '../../../redux/signup/slice';
import { forgotPasswordEmailRedux } from '../../../redux/signup/selectors';
import GoBackButton from '../../../Common/Components/GoBackButton';

const ForgotPasswordSuccessComponent = () => {
  const VERIF_MAIL_INTERVAL = 60;
  const [calculateTime, setCalculateTime] = useState(true);
  const [showTime, setShowTime] = useState(true);
  const [timer, setTimer] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [resendClicked, setResendClicked] = useState(false);

  const dispatch = useDispatch();
  const forgotPasswordemail = useSelector(forgotPasswordEmailRedux);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useInterval(() => {
    if (calculateTime) {
      const initDate = localStorage.getItem('forgotPasswordMailSent');
      const msecs = new Date() - new Date(initDate);
      const remaining = VERIF_MAIL_INTERVAL - Math.ceil(msecs / 1000);
      setTimer('0:' + (remaining < 10 ? '0' : '') + String(remaining));
      if (remaining <= 0) {
        setCalculateTime(false);
        setDisabled(false);
        setTimer('');
        setShowTime(false);
      }
    }
  }, 500);

  const handleResendClicked = async () => {
    if (!isDisabled) {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('forgotPassword');

      const data = {
        email: forgotPasswordemail,
        reCaptchaToken: token
      };
      const resp = await dispatch(forgotPasswordAction(data));
      if (resp.meta.requestStatus === 'fulfilled') {
        localStorage.setItem('forgotPasswordMailSent', new Date());
        setResendClicked(true);
        setShowTime(true);
        setDisabled(true);
        setCalculateTime(true);
      } else {
        //   alert('Error');
      }
    }
  };

  const handleBackClicked = async () => {
    await dispatch(setForgotPasswordStep(1));
  };

  const Back = () => {
    return (
      <div className={styles.back}>
        <Link to={route.login} style={{ display: 'flex' }}>
          <Arrow className={styles.arrow} />
          <div className={styles.BackText} onClick={handleBackClicked}>
            Back to Login
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.outerCard}>
          <div className={styles.innerCard}>
            <CoensioLogo className={styles.logo} />
            <div className={styles.backWrapper}>
              <GoBackButton
                style={{ marginLeft: '-10px' }}
                onClick={() => {
                  history.back();
                }}
              />
              <label className={styles.cardTitle}>We have sent you an email!</label>
            </div>
            <label className={styles.cardText}>
              Please check your inbox to reset your password.
            </label>
            <text className={styles.resendWrapper}>
              <text className={styles.cardSubText}>
                Didn’t receive an email? Check your spam box or&nbsp;
                {/* <text style={{ display: 'flex', marginBottom: '8rem' }}> */}
                <text
                  onClick={handleResendClicked}
                  className={styles.link}
                  style={{
                    pointerEvents: isDisabled ? 'none' : 'inherit',
                    opacity: isDisabled ? '0.4' : '1'
                  }}
                >
                  resend email.&nbsp;
                </text>
                {showTime && <label className={styles.remaining}>Time remaining: {timer}</label>}
                {/* </text> */}
              </text>
            </text>
          </div>
          <InfoBanner
            setStatus={setResendClicked}
            show={resendClicked}
            text="Mail Resent"
            textColor="#4AC389"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccessComponent;
