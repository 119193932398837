import React from 'react';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../Common/Components/CustomButton';
import Modal from '../../../Common/CustomModal/Modal';
import { setOnboardingStep } from '../../../redux/plan/slice';
import styles from '../Styles/WelcomePopup.module.css';
import imageSrc from './welcomeOnboardPopup.svg';

function WelcomePopupComponent({ show, onClose, getStartedClicked }) {
  const dispatch = useDispatch();
  return (
    <Modal
      backdropStyle={{ backdropFilter: 'blur(2px)' }}
      contentStyle={{ borderRadius: '12px' }}
      size={{ width: '52.4rem', height: 'fit-content' }}
      show={show}
      onClose={onClose}
      centerHorizontally
      centerVertically
      outsideClickClose
    >
      <div className={styles.popup}>
        <div className={styles.contentWrapper}>
          <img className={styles.image} src={imageSrc} alt="" />
          <div className={styles.right}>
            <label className={styles.title}>Welcome on board!</label>
            <label className={styles.text}>
              Start unlocking the potentials and meet the modern way of hiring.
            </label>
            <div className={styles.buttonWrapper}>
              <CustomButton 
                buttonOnClick={() => {
                  onClose();
                  dispatch(setOnboardingStep(0));
                }}
                textField="Cancel"
                type={2}
                customStyle={{
                  height: '3.6rem',
                  fontSize: '1.6rem',
                  lineHeight: '2.3rem',
                  padding: '0px 2.4rem',
                  marginRight: '4px'
                }}
              />
              <CustomButton
                customStyle={{
                  height: '3.6rem',
                  fontSize: '1.6rem',
                  lineHeight: '2.3rem',
                  padding: '0px 2.4rem'
                }}
                buttonOnClick={getStartedClicked}
                textField="Get Started"
                size="medium"
              />
            </div>
          </div>
        </div>
        {/* <Tick className={styles.tick} onClick={handleOnClose} /> */}
      </div>
    </Modal>
  );
}

export default WelcomePopupComponent;
