import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RadioButton from '../../../../Common/Components/RadioButtonItem';
import { setScreeningExperience } from '../../../../redux/AssessmentSession/slice';
import styles from './ScreeningCardComponent.module.css';

// Input Type: 30
const SingleSelectRadioButton = ({ text, id, selected, setSelected }) => {
  return (
    <div className={styles.checkboxWrapper}>
      <RadioButton checkboxBoxStyle={{border: '0.9px solid #585858'}} isActive={selected} activate={setSelected} item={text} myKey={id} />
    </div>
  );
};

const ExperienceQuestion = ({ content, index }) => {
  const dispatch = useDispatch();
  const [selectedId, setSelected] = useState(content?.currentExperience);

  useEffect(() => {
    dispatch(setScreeningExperience(selectedId));
  }, [selectedId]);

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.question}>{content?.experienceQuestion}</div>
      <div className={styles.wrapper}>
        {content.experiences?.map((e, i) => (
          <SingleSelectRadioButton
            text={e.name}
            id={e.id}
            selected={selectedId === e.id}
            setSelected={setSelected}
          />
        ))}
      </div>
    </div>
  );
};

export default ExperienceQuestion;
