import { createSlice } from '@reduxjs/toolkit';
import {
  applicantAssessmentDetailAction,
  applicantAssessmentDetailWithEmailAction,
  candidateContractCheckAction,
  ChangeOrAddApplicantDetailAction,
  getAssessmentSessionDetailAction,
  getCandidateInfoFromEmailAction,
  getCandidateInfoFromUserIdAction,
  getCurrentQuestionAction,
  getNewOpsCompanyListAction,
  getNextQuestionAction,
  getScreeningQuestionsAction,
  startAssessmentSessionAction,
  submitCodingQuestionAnswerAction,
  submitFreeTextQuestionAnswerAction,
  submitMultipleChoiceQuestionAnswerAction
} from './actions';
import initialState from './initialState';

const assessmentSessionSlice = createSlice({
  name: 'assessmentSession',
  initialState,
  reducers: {
    setSaveButtonLoading: {
      reducer: (state, action) => {
        state.saveLoading = action.payload;
      }
    },
    setCandidateEmail: {
      reducer: (state, action) => {
        state.candidateEmail = action.payload;
        localStorage.setItem('candidateEmail', action.payload);
      }
    },
    setName: {
      reducer: (state, action) => {
        state.candidateName = action.payload;
      }
    },
    setSurname: {
      reducer: (state, action) => {
        state.candidateSurname = action.payload;
      }
    },
    setPhone: {
      reducer: (state, action) => {
        state.candidatePhone = action.payload;
      }
    },
    setLinkedIn: {
      reducer: (state, action) => {
        state.candidateLinkedIn = action.payload;
      }
    },
    setCv: {
      reducer: (state, action) => {
        state.cvPath = action.payload;
      }
    },
    setApplicationState: {
      reducer: (state, action) => {
        state.applicationState = action.payload;
      }
    },
    setBannerStatus: {
      reducer: (state, action) => {
        state.showBanner = action.payload;
      }
    },
    setBannerText: {
      reducer: (state, action) => {
        state.bannerText = action.payload;
      }
    },
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      }
    },

    resetQuestionInfo: (state,action) => {
      state.questionInfo = action.payload;
    },

    // Screening related
    setScreeningWorkTypeList: (state, action) => {
      state.screeningWorkTypeList = action.payload;
    },
    setScreeningSalaryExpectation: (state, action) => {
      state.screeningSalaryExpectation = action.payload;
    },
    setScreeningNationalityIds: (state, action) => {
      state.screeningNationalityIds = action.payload;
    },
    setScreeningCurrencyType: (state, action) => {
      state.screeningCurrencyType = action.payload;
    },
    setScreeningExperience: (state, action) => {
      state.screeningExperience = action.payload;
    },
    

  },

  extraReducers: builder => {
    builder.addCase(applicantAssessmentDetailAction.fulfilled, (state, action) => {
      state.cvCheck = action.payload.isCvNeeded;
      state.logoPath = action?.payload.logoUrl;
      localStorage.setItem('companyName', action.payload.companyName);
      state.companyName = action.payload.companyName;
      state.assessmentTitle = action.payload.companyAssessmentName;
      state.questionCount = action.payload.questionCount;
      state.totalTime = action.payload.totalTime;
      state.languageList = action.payload.questionLanguagesList;
      state.expired = action.payload.companyAssessmentObjStatusId === 2;
      // state.loading = false;
      state.codingCount =
        action.payload.questionType.filter(x => x.questionTypeIdInt === 2)[0]
          ?.questionCountByTypes || 0;
      state.freeCount =
        action.payload.questionType.filter(x => x.questionTypeIdInt === 3)[0]
          ?.questionCountByTypes || 0;
      state.multiCount =
        action.payload.questionType.filter(x => x.questionTypeIdInt === 1)[0]
          ?.questionCountByTypes || 0;

      state.rootAssessmentName = action.payload.rootAssessmentName;
    });
    builder.addCase(applicantAssessmentDetailAction.pending, (state, action) => {
      state.logoPath = null;
      state.companyName = null;
      state.loading = true;
      state.assessmentTitle = null;
      state.questionCount = null;
      state.totalTime = null;
      state.languageList = null;
      state.codingCount = 0;
      state.multiCount = 0;
      state.freeCount = 0;

      state.rootAssessmentName = null
    });

    builder.addCase(applicantAssessmentDetailWithEmailAction.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(applicantAssessmentDetailAction.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(applicantAssessmentDetailWithEmailAction.fulfilled, (state, action) => {
      localStorage.setItem('candidateEmail', action.payload.email);
      state.candidateEmail = action.payload.email;
      state.candidateName = action.payload.name;
      state.candidateSurname = action.payload.surname;
      state.loading = false;
      state.candidateLinkedIn = action.payload.linkedIn;
      state.candidatePhone = action.payload.phone;
      state.cvPath = action.payload.cvPath;
      state.cvCheck = action.payload.isCvNeeded;
    });

    builder.addCase(getCandidateInfoFromEmailAction.fulfilled, (state, action) => {
      state.candidateName = action.payload.name;
      state.candidateSurname = action.payload.surname;
      state.loading = false;
      state.candidateLinkedIn = action.payload.linkedIn;
      state.candidatePhone = action.payload.phone;
      state.cvPath = action.payload.cvPath;
      state.cvCheck = action.payload.isCvNeeded;
    });

    builder.addCase(getCandidateInfoFromUserIdAction.fulfilled, (state, action) => {
      state.candidateEmail = action.payload.email;
      state.candidateName = action.payload.name;
      state.candidateSurname = action.payload.surname;
      state.loading = false;
      state.candidateLinkedIn = action.payload.linkedIn;
      state.candidatePhone = action.payload.phone;
      state.cvPath = action.payload.cvPath;
      state.cvCheck = action.payload.isCvNeeded;
    });

    builder.addCase(getCandidateInfoFromUserIdAction.pending, (state, action) => {
      state.candidateEmail = null;
      state.candidateName = null;
      state.candidateSurname = '';
      state.loading = true;
      state.candidateLinkedIn = '';
      state.candidatePhone = null;
      state.cvPath = null;
      state.cvCheck = null;
    });
  

    builder.addCase(candidateContractCheckAction.fulfilled, (state, action) => {
      state.showContract = !action.payload;
    });
    builder.addCase(startAssessmentSessionAction.fulfilled, (state, action) => {
      localStorage.setItem('assessmentSessionToken', action.payload.assessmentSessionToken);
      state.assessmentSessionToken = action.payload.assessmentSessionToken;
    });
    builder.addCase(getNextQuestionAction.fulfilled, (state, action) => {
      state.questionInfo = action.payload;
      state.nextButtonLoading = false;
    });
    builder.addCase(getCurrentQuestionAction.fulfilled, (state, action) => {
      state.questionInfo = action.payload;
      state.nextButtonLoading = false;
    });
    builder.addCase(submitMultipleChoiceQuestionAnswerAction.pending, (state, action) => {
      state.nextButtonLoading = true;
    });
    builder.addCase(submitFreeTextQuestionAnswerAction.pending, (state, action) => {
      state.nextButtonLoading = true;
    });
    builder.addCase(submitCodingQuestionAnswerAction.pending, (state, action) => {
      state.nextButtonLoading = true;
    });
    builder.addCase(submitMultipleChoiceQuestionAnswerAction.rejected, (state, action) => {
      state.nextButtonLoading = false;
    });
    builder.addCase(submitFreeTextQuestionAnswerAction.rejected, (state, action) => {
      state.nextButtonLoading = false;
    });
    builder.addCase(submitCodingQuestionAnswerAction.rejected, (state, action) => {
      state.nextButtonLoading = false;
    });
    builder.addCase(ChangeOrAddApplicantDetailAction.pending, (state, action) => {
      state.saveLoading = true;
    });
    builder.addCase(ChangeOrAddApplicantDetailAction.fulfilled, (state, action) => {
      state.saveLoading = false;
    });
    builder.addCase(ChangeOrAddApplicantDetailAction.rejected, (state, action) => {
      state.saveLoading = false;
    });
    builder.addCase(getAssessmentSessionDetailAction.fulfilled, (state, action) => {
      state.logoPath = action.payload.companyLogo;
      state.assessmentTitle = action.payload.companyAssessmentName;
    });

    builder.addCase(getNewOpsCompanyListAction.fulfilled, (state, action) => {
      state.newOpLoading = false;
      state.newOpList = action.payload.companyList;
      state.showNewOpFlag = true;
    });
    builder.addCase(getNewOpsCompanyListAction.pending, (state, action) => {
      state.newOpLoading = true;
      state.newOpList = null;
      state.showNewOpFlag = false;
    });
    builder.addCase(getNewOpsCompanyListAction.rejected, (state, action) => {
      state.newOpLoading = false;
      state.newOpList = null;
      state.showNewOpFlag = false;
    });
    
    builder.addCase(getScreeningQuestionsAction.pending, (state, action) => {
      state.screeningLoading = true;
    });
    builder.addCase(getScreeningQuestionsAction.rejected, (state, action) => {
      state.screeningLoading = false;
      state.screeningListObj = null;
    });
    builder.addCase(getScreeningQuestionsAction.fulfilled, (state, action) => {
      state.screeningLoading = false;
      state.screeningListObj = action.payload;
    });
  }
});

export const {
  setCandidateEmail,
  setName,
  setLinkedIn,
  setPhone,
  setSurname,
  setCv,
  setApplicationState,
  setBannerStatus,
  setBannerText,
  setSaveButtonLoading,
  setLoading,
  resetQuestionInfo,
  setScreeningWorkTypeList,
  setScreeningSalaryExpectation,
  setScreeningNationalityIds,
  setScreeningCurrencyType,
  setScreeningExperience
} = assessmentSessionSlice.actions;

export default assessmentSessionSlice.reducer;
