import React, { useState, useEffect } from 'react';

const Highlight = ({ child, isHighlighted = false, borderRadius, paddingPixel = 0, style }) => {
  const [highlightPixel, setHighlightPixel] = useState(isHighlighted ? paddingPixel + 3 : 0);
  const [padding, setPadding] = useState(isHighlighted ? paddingPixel : 0);

  // useEffect(() => {
  //   document.addEventListener('click', event => {
  //     const element = document.getElementById('Highlight');
  //     if (!element.contains(event.target)) {
  //       setHighlightPixel(0);
  //       setPadding(0);
  //     }
  //   });
  // });

  useEffect(() => {
    if (isHighlighted) {
      setHighlightPixel(paddingPixel + 3);
      setPadding(paddingPixel);
    } else {
      setHighlightPixel(0);
      setPadding(0);
    }
  }, [isHighlighted]);

  const highlightStyle = '0px 0px ' + highlightPixel + 'px ' + highlightPixel + 'px #32CBE0';
  const paddingStyle = '0px 0px ' + padding + 'px ' + padding + 'px #ffff';

  return (
    <div id="Highlight" style={{ borderRadius: borderRadius, boxShadow: highlightStyle, ...style }}>
      <div style={{ borderRadius: borderRadius + 1, boxShadow: paddingStyle }}>{child}</div>{' '}
      {/* border radius inside is greater than border radius outside to equalize the ellipticity of 2 divs */}
    </div>
  );
};

export default Highlight;
