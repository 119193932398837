import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Xarrow, { useXarrow } from 'react-xarrows';
import styled from 'styled-components';
import AssessmentDetailContent from '../Components/AssessmentDetailContent';
import Header from '../../../Common/Components/Header';
import styles from './Onboarding.module.css';
import { ReactComponent as Arrow } from '../Images/onboardingArrow.svg';
import { ReactComponent as EmailIcon } from '../Images/email.svg';
import { ReactComponent as Mail } from '../AssessmentDetailHeader/mail.svg';
import InviteCandidatesModal from '../Modals/InviteCandidatesModal';
import {
  setInviteCandidatesModalShow,
  setSettingsError,
  setSettingsSuccess
} from '../../../redux/assessmentDetail/slice';
import {
  inviteCandidatesModalShow,
  settingsErrorRedux,
  settingsSuccessRedux,
  settingsBannerMessageRedux
} from '../../../redux/assessmentDetail/selectors';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';
import mixpanel from '../../../utils/mixpanel';
import { userSelector } from '../../../redux/auth/selectors';
import { isFirstAssessmentCheckAction } from '../../../redux/plan/actions';
import imageSrc from '../Images/inviteCandidatesPopup.svg';
import CreateAssessmentPopup from '../../AssessmentList/Components/CreateAssessmentPopup';
import { getAssessmentDetailVol2Action } from '../../../redux/assessmentDetailVol2/actions';
import Loading from '../../../Common/Components/Loading';
import {
  assessmentDetailLoadingSelector,
  authFlagSelector,
  isMoveEnabledFlagSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  resetCandidateListRelatedStuffForNewFilters,
  resetCandidateListRelatedStuffForNewFiltersWithoutPageNumber,
  setFunnelInfo,
  setFunnelOpen,
  setIsMoveEnabledFlag
} from '../../../redux/assessmentDetailVol2/slice';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import { getParam, urlDynamicQueryUpdateLite, useWindowSize } from '../../../utils/helpers';
import { completedOnboardingNoRedux } from '../../../redux/plan/selectors';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import { useIp } from '../../../hooks/useIp';

const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1001,
    opacity: 0.99
  }
})(Backdrop);

function AssessmentDetailPage() {
  const [loc, currency] = useIp();
  const [open, setOpen] = React.useState(false);

  const inviteModalShow = useSelector(inviteCandidatesModalShow);

  const dispatch = useDispatch();
  const inviteButton = useRef(null);
  const onboardingDiv = useRef(null);

  const updateXarrow = useXarrow();

  const [onboardingOpen, setOnboardingOpen] = useState(false);

  const settingsErrorShow = useSelector(settingsErrorRedux);
  const settingsSuccessShow = useSelector(settingsSuccessRedux);
  const settingsMessage = useSelector(settingsBannerMessageRedux);

  const [onboardingPopupShow, setOnboardingPopupShow] = useState();
  const completedOnboardingNo = useSelector(completedOnboardingNoRedux);

  const [buttonId, setButtonId] = useState(document?.getElementById('reachOutOnboardingId'));

  const { id } = useParams();
  const user = useSelector(userSelector);
  const ref = useRef();
  const myRef = useRef();
  const [windowW, windowH] = useWindowSize();

  const urlParams = window.location.search;

  function handleClick(event) {
    const modalId = document?.getElementById('create_assessment_modal_id');
    if (
      buttonId &&
      modalId &&
      !buttonId?.contains(event.target) &&
      !modalId?.contains(event.target)
    ) {
      setOnboardingPopupShow(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [buttonId]);

  useEffect(() => {
    setButtonId(document?.getElementById('reachOutOnboardingId'));
  }, [onboardingPopupShow, inviteModalShow]);

  useEffect(() => {
    if (
      onboardingOpen &&
      localStorage.getItem('Mixpanel_AssessmentCustomizationRootAssessmentName')
    ) {
      /* Mixpanel pageview */
      mixpanel.track('Welcome Assessment Detail - Pageview', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        'Company Assessment Id': id,
        'Root Assessment Name': localStorage.getItem(
          'Mixpanel_AssessmentCustomizationRootAssessmentName'
        )
      });
      localStorage.removeItem('Mixpanel_AssessmentCustomizationRootAssessmentName');
    }
  }, [onboardingOpen]);

  const assessmentDetailLoadingRedux = useSelector(assessmentDetailLoadingSelector);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(async () => {
    if (getParam(urlParams, 'welcome') === 'true') {
      const data = {
        AssessmentId: id
      };
      const resp = await dispatch(isFirstAssessmentCheckAction(data));
      if (resp.payload && completedOnboardingNo !== 4) {
        setOnboardingPopupShow(true);
      } else {
        setOnboardingOpen(true);
      }
    }
    
    // Clear redux states on page load
    await dispatch(setFunnelInfo([])); // For mixpanel

    // If go back button is used in candidateDetail - reset everything but keep pageNumber
    if (getParam(location.search, 'candidateFlag') === "true") {
      await dispatch(resetCandidateListRelatedStuffForNewFiltersWithoutPageNumber())
    } else {
      await dispatch(resetCandidateListRelatedStuffForNewFilters());
    }
    await dispatch(getAssessmentDetailVol2Action({ CompanyAssessmentId: id }));
    setInitialLoading(false);
  }, []);

  const isMoveEnabledFlag = useSelector(isMoveEnabledFlagSelector);

  useEffect(() => {
    const filterWrap = document.getElementById('filterRowBackdropWrapper');
    if (filterWrap) {
      if (isMoveEnabledFlag) {
        filterWrap.style.zIndex = 1001;
        setOpen(true);
        dispatch(setFunnelOpen(true));
      } else {
        setOpen(false);
        filterWrap.style.zIndex = 0;
      }
    }
  }, [isMoveEnabledFlag]);

  const authFlagRedux = useSelector(authFlagSelector);

  const [screenW, screenH] = useWindowSize();
  const [status, setStatus] = useState();

  useEffect(() => {
    setTimeout(() => {
      setStatus(true);
    }, 5000);
  }, []);

  const [inviteModalShow2, setinviteModalShow2] = useState(false);

  useEffect(() => {
    if (inviteModalShow) {
      setinviteModalShow2(true);
    } else {
      setTimeout(() => {
        setinviteModalShow2(false);
      }, 300);
    }
  }, [inviteModalShow]);

  return (
    <div className={styles.container}>
      {assessmentDetailLoadingRedux || initialLoading || !loc ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : authFlagRedux ? (
        <div style={{ filter: onboardingOpen && 'blur(2px)', width: '100%' }}>
          <LimitedBackdrop open={open} onClick={() => dispatch(setIsMoveEnabledFlag(false))} />
          {screenW > 576 ? <Header /> : null}
          <AssessmentDetailContent />
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          <Header />
          <NotAuthorized />
        </div>
      )}

      {inviteModalShow2 && (
        <InviteCandidatesModal
          style={{ zIndex: 100000000 }}
          show={inviteModalShow}
          onHide={() => {
            dispatch(setInviteCandidatesModalShow(false));
            setOnboardingOpen(false);
          }}
        />
      )}

      <InfoBanner
        setStatus={e => dispatch(setSettingsError(e))}
        show={settingsErrorShow}
        text={settingsMessage}
        CustomIcon={Error}
      />
      <InfoBanner
        setStatus={e => dispatch(setSettingsSuccess(e))}
        show={settingsSuccessShow}
        text={settingsMessage}
      />

      {onboardingPopupShow && !inviteModalShow && (
        <>
          <button
            ref={ref}
            id="reachOutOnboardingId"
            onClick={() => {
              dispatch(setInviteCandidatesModalShow(true));
              setOnboardingPopupShow(false);
            }}
            type="button"
            className={styles.myButton}
            style={{
              marginRight: 15,
              zIndex: 100200,
              position: 'absolute',
              right: '0px',
              top: '75px',
              height: '5.5rem',
              width: '23rem',
              fontSize: '2rem'
            }}
          >
            <EmailIcon className={styles.iconWrapper} />
            <span className={styles.buttonText}>Invite Candidates</span>
          </button>
          <CreateAssessmentPopup
            show={onboardingPopupShow && !inviteModalShow}
            setShow={setOnboardingPopupShow}
            imageSrc={imageSrc}
            title="Reach out more candidates"
            text="You can also invite candidates by email"
            relativeElementId="reachOutOnboardingId"
            xConstant={16}
            popupRef={myRef}
            popupId="create_assessment_modal_id"
          />
        </>
      )}
      {onboardingOpen && windowW > 576 ? (
        <Backdrop
          onClick={() => {
            setOnboardingOpen(false);
            // remove welcome from url
            urlDynamicQueryUpdateLite('welcome', 'false', false);
          }}
          open={onboardingOpen}
          style={{
            zIndex: 10000,
            color: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {!inviteModalShow && (
            <>
              <button
                onClick={() => {
                  dispatch(setInviteCandidatesModalShow(true));
                  // remove welcome from url
                  urlDynamicQueryUpdateLite('welcome', 'false', false);
                }}
                ref={inviteButton}
                type="button"
                className={styles.myButton}
                style={{
                  marginRight: 15,
                  zIndex: 1000000,
                  position: 'absolute',
                  right: '0px',
                  top: '75px',
                  height: '5.5rem',
                  width: '23rem',
                  fontSize: '2rem'
                }}
              >
                <EmailIcon className={styles.iconWrapper} />
                <span className={styles.buttonText}>Invite Candidates</span>
              </button>

              <div className={styles.onboardingElement} onChange={updateXarrow}>
                <Arrow className={styles.arrowStyle} ref={onboardingDiv} onChange={updateXarrow} />
                <span className={styles.onboardingText}>
                  You can also invite the candidates via email!
                </span>
              </div>

              <Xarrow
                arrowBodyProps={{
                  style: {
                    strokeDasharray: '10 8',
                    boxShadow: '2px 2px 12px rgba(255, 255, 255, 0.25)'
                  }
                }}
                divContainerStyle={{ strokeDasharray: '7 4' }}
                className={styles.xarrow}
                start={inviteButton}
                end={onboardingDiv}
                color="rgba(116, 88, 211, 1)"
                endAnchor={{ position: 'top', offset: { y: 5 } }}
                startAnchor={{ position: 'bottom', offset: { y: 5 } }}
                headSize={0}
                curveness={1.2}
                dashness
                strokeWidth={2.2}
              />
            </>
          )}
        </Backdrop>
      ) : onboardingOpen && windowW <= 576 ? (
        <Backdrop
          onClick={() => {
            setOnboardingOpen(false);
            urlDynamicQueryUpdateLite('welcome', 'false', false);
          }}
          open={onboardingOpen}
          style={{
            zIndex: 10000,
            color: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          {!inviteModalShow && (
            <>
              <button
                onClick={() => {
                  dispatch(setInviteCandidatesModalShow(true));
                  // remove welcome from url
                  urlDynamicQueryUpdateLite('welcome', 'false', false);
                }}
                ref={inviteButton}
                type="button"
                className={styles.myButtonMobile}
                style={{
                  zIndex: 1000000,
                  position: 'absolute',
                  right: '20px',
                  height: '32px',
                  width: '40px',
                  fontSize: '2rem'
                }}
              >
                <Mail />
                {/* <span className={styles.buttonText}>Invite Candidates</span> */}
              </button>

              <div className={styles.onboardingElement} onChange={updateXarrow}>
                <Arrow className={styles.arrowStyle} ref={onboardingDiv} onChange={updateXarrow} />
                <span className={styles.onboardingText}>
                  You can also invite the candidates via email!
                </span>
              </div>

              <Xarrow
                arrowBodyProps={{
                  style: {
                    strokeDasharray: '14 13',
                    boxShadow: "2px 2px 12px rgba(255, 255, 255, 0.25)"
                  }
                }}
                divContainerStyle={{ strokeDasharray: '7 4' }}
                className={styles.xarrow}
                start={inviteButton}
                end={onboardingDiv}
                color="rgba(255, 255, 255, 1)"
                endAnchor={{ position: 'top', offset: { y: 5 } }}
                startAnchor={{ position: 'bottom', offset: { y: 5 } }}
                headSize={0}
                curveness={0.6}
                dashness
                strokeWidth={2.5}
              />
            </>
          )}
        </Backdrop>
      ) : null}
    </div>
  );
}

export default AssessmentDetailPage;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
