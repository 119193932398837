import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton from '../../Common/Components/CloseButton';
import CustomButton from '../../Common/Components/CustomButton';
import styles from '../Styles/DeleteUserModal.module.css';
import { userSelector } from '../../redux/auth/selectors';
import { deleteTeamMemberAction, getTeamAction } from '../../redux/auth/actions';
import { setErrorInTeam, setErrorMessageInTeam } from '../../redux/auth/slice';

export default function DeleteUserModal({
  show,
  onHide,
  title,
  description,
  footer,
  userId,
  setDeleteSuccess,
  ...props
}) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      onHide={onHide}
      contentClassName={`${styles.modalContent} ${styles['planModal-content']}`}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <CloseButton onClick={onHide} width={24} height={24} />
      </div>
      <div className={styles.subtitle}>{description}</div>
      <div className={styles.row}>
        <CustomButton
          textField="Cancel"
          buttonOnClick={() => {
            onHide();
          }}
          type={2}
        />
        <CustomButton
          type={6}
          textField="Delete"
          buttonOnClick={async () => {
            const res = await dispatch(deleteTeamMemberAction({ userId: userId }));
            if (res?.meta?.requestStatus === 'rejected') {
              await dispatch(setErrorMessageInTeam(res?.payload?.message));
              await dispatch(setErrorInTeam(true));
            } else {
              await setDeleteSuccess(true);
              await dispatch(getTeamAction({ CompanyId: user?.companyId }));
            }
            onHide();
          }}
          style={{ marginRight: '4px' }}
        />
      </div>
    </Modal>
  );
}
