import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import { oldestInitialDateSelector } from '../../redux/assessmentDetailVol2/selectors';

function MyCalendar({
  setChange = () => {},
  dateStart,
  dateEnd,
  setDateStart,
  setDateEnd,
  ...props
}) {
  const [date, setDate] = useState(new Date());
  const today = new Date();

  useEffect(async () => {
    if (dateStart && dateEnd) {
      setDate([dateStart, dateEnd]);
    }
  }, [dateStart, dateEnd]);

  useEffect(() => {
    if (date) {
      setDateStart(date[0]);
      setDateEnd(date[1]);
    }
  }, [date]);

  useEffect(() => {
    setChange(null);
  }, []);

  return (
    <div>
      <Calendar
        id="calendar"
        showFixedNumberOfWeeks
        defaultView="month"
        locale="en"
        value={date}
        returnValue="range"
        selectRange="true"
        onChange={val => {
          setDate(val);
          setChange('change');
        }}
      />
    </div>
  );
}

export default MyCalendar;
