import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxRaw from '../../../Common/RawCheckbox/Checkbox';
import CustomSearchbar from '../../../Common/Components/CustomSearchbar/CustomSearchbar';
import filterDefault from './Images/filterDefault.svg';
import filter1 from './Images/filter1.svg';
import filter2 from './Images/filter2.svg';
import filter3 from './Images/filter3.svg';
import filter4 from './Images/filter4.svg';
import filter5 from './Images/filter5.svg';
import filter6 from './Images/filter6.svg';
import filter7 from './Images/filter7.svg';
import filter8 from './Images/filter8.svg';
import sortByScore from './Images/score.svg';
import sortByDate from './Images/date.svg';

import styles from './FilterRow.module.css';
import {
  activeFilterCountSelector,
  candidateListSelector,
  isMoveEnabledFlagSelector,
  orderTypeSelector,
  requestAcceptedCandidateListSelector,
  searchParameterSelector,
  selectedAcceptedCandidateListSelector,
  selectedCandidateListSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  resetCandidateListRelatedStuffForNewFilters,
  setIsMoveEnabledFlag,
  setSelectedAcceptedCandidateList,
  setSelectedCandidateList
} from '../../../redux/assessmentDetailVol2/slice';
import { urlDynamicQueryUpdateLite, useWindowSize } from '../../../utils/helpers';
import useHideKeyboard from '../../../hooks/useHideKeyboard';

const FilterRow = ({ setFilterOpen = () => {} }) => {
  const dispatch = useDispatch();
  /* Checkbox */
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [checkBoxIndState, setCheckBoxIndState] = useState(false);

  /* Selector for candidate list */
  const candidateListRedux = useSelector(candidateListSelector);
  const selectedCandidateListRedux = useSelector(selectedCandidateListSelector);
  const acceptedCandidateListRedux = useSelector(requestAcceptedCandidateListSelector);
  const selectedAcceptedCandidateListRedux = useSelector(selectedAcceptedCandidateListSelector);

  /* Selector for Move To behavior */
  const isMoveEnabledFlag = useSelector(isMoveEnabledFlagSelector);

  /* State for search param */
  const [searchParamState, setSearchParamState] = useState();
  const [isSearchExtendedFlag, setIsSearchExtendedFlag] = useState(false);
  const searchParamRedux = useSelector(searchParameterSelector);

  /* Active search count */
  const activeFilterCountRedux = useSelector(activeFilterCountSelector);

  const [screenW, screenH] = useWindowSize();

  const MOBILE_THRESHOLD = 576;


  /* Checkbox related constants */
  const noCandSelected =
    selectedCandidateListRedux?.length === 0 && selectedAcceptedCandidateListRedux?.length === 0;

  const candExists = candidateListRedux?.length !== 0 || acceptedCandidateListRedux?.length !== 0;

  const candExistsButNoneSelected = noCandSelected && candExists;

  const acceptedAllSelected =
    acceptedCandidateListRedux?.length === selectedAcceptedCandidateListRedux?.length;
  const applicantAllSelected = candidateListRedux?.length === selectedCandidateListRedux?.length;

  const allCandSelected = applicantAllSelected && acceptedAllSelected;

  const selectedCandCount = selectedCandidateListRedux?.length + selectedAcceptedCandidateListRedux?.length;
  const totalCandCount = candidateListRedux?.length + acceptedCandidateListRedux?.length;


  const checkboxClickHandler = () => {
    if (!candExists) {
      return;
    }
    if (candExistsButNoneSelected) {
      // Select all
      const candTemp = [];
      candidateListRedux?.forEach(e => {
        candTemp.push({ userId: e.userId, objStatusId: e.objStatusId });
      });
      dispatch(setSelectedCandidateList(candTemp));
      const acceptTemp = [];
      acceptedCandidateListRedux?.forEach(e => {
        acceptTemp.push({ userId: e.userId, objStatusId: e.objStatusId });
      });
      dispatch(setSelectedAcceptedCandidateList(acceptTemp));
      window.dispatchEvent(new Event('enableSelectAll'));
    } else if (allCandSelected) {
      // Deselect all
      window.dispatchEvent(new Event('disableSelectAll'));
      dispatch(setSelectedCandidateList([]));
      dispatch(setSelectedAcceptedCandidateList([]));
    } else {
      // Deselect selected ones
      window.dispatchEvent(new Event('disableSelectAll'));
      dispatch(setSelectedCandidateList([]));
      dispatch(setSelectedAcceptedCandidateList([]));
    }
  };

  /* Selector for filter order type */
  const orderTypeRedux = useSelector(orderTypeSelector);

  const changeOrderType = () => {
    dispatch(resetCandidateListRelatedStuffForNewFilters());
    if (orderTypeRedux === 1) {
      urlDynamicQueryUpdateLite('orderType', '3');
    } else if (orderTypeRedux === 3) {
      urlDynamicQueryUpdateLite('orderType', '1');
    }
  };

  const applySearch = () => {
    if (searchParamState.trim() !== '') {
      dispatch(resetCandidateListRelatedStuffForNewFilters());
      urlDynamicQueryUpdateLite('searchParam', encodeURIComponent(searchParamState));
    }
  };

  const resetSearch = () => {
    if (searchParamRedux?.trim() !== '') {
      dispatch(resetCandidateListRelatedStuffForNewFilters());
      urlDynamicQueryUpdateLite('searchParam', '');
    }
    setSearchParamState('');
    setIsSearchExtendedFlag(false);
  };

  useEffect(() => {
    if (searchParamRedux !== null && searchParamRedux !== '') {
      setSearchParamState(searchParamRedux);
    } else {
      setSearchParamState('');
      setIsSearchExtendedFlag(false);
    }
  }, [searchParamRedux]);

  const [selectOpen, setSelectOpen] = useState(true);

  useEffect(() => {
    if (screenW < 576 && !isSearchExtendedFlag) {
      setTimeout(() => {
        setSelectOpen(true);
      }, 300);
    }
    if (screenW < 576 && isSearchExtendedFlag) {
      setSelectOpen(false);
    }
    if (screenW >= 576) {
      setTimeout(() => {
        setSelectOpen(true);
      }, 300);
    }
  }, [isSearchExtendedFlag]);

  useEffect(() => {
    if (noCandSelected) {
      setCheckBoxState(false);
    } else if (allCandSelected) {
      setCheckBoxState(true);
    }
  }, [
    JSON.stringify(selectedCandidateListRedux),
    JSON.stringify(selectedAcceptedCandidateListRedux)
  ]);

  useHideKeyboard();

  const [filterIcon, setFilterIcon] = useState();

  const filterIconSelector = () => {
    switch (activeFilterCountRedux) {
      case 0:
        setFilterIcon(filterDefault);
        break;
      case 1:
        setFilterIcon(filter1);
        break;
      case 2:
        setFilterIcon(filter2);
        break;
      case 3:
        setFilterIcon(filter3);
        break;
      case 4:
        setFilterIcon(filter4);
        break;
      case 5:
        setFilterIcon(filter5);
        break;
      case 6:
        setFilterIcon(filter6);
        break;
      case 7:
        setFilterIcon(filter7);
        break;
      case 8:
        setFilterIcon(filter8);
        break;

      default:
        setFilterIcon(filterDefault);
        break;
    }
  };

  useEffect(() => {
    filterIconSelector();
  }, [activeFilterCountRedux]);

  return (
    <div className={styles.FilterRow} id="assessmentDetailFilterRow">
      <div className={styles.FilterRowBackdropWrapper} id="filterRowBackdropWrapper">
        <div className={styles.FilterRowLeft}>
          {selectOpen && (
            <>
              <CheckboxRaw
                id="select-all-checkbox"
                isChecked={checkBoxState}
                setIsChecked={setCheckBoxState}
                size={16}
                parentUpdate={() => checkboxClickHandler()}
                indeterminate={
                  // selected cand exists but not all of them
                  !noCandSelected && !allCandSelected
                  // candidateListRedux?.length !== selectedCandidateListRedux?.length &&
                  // selectedCandidateListRedux?.length > 0
                }
                isDisabled={isMoveEnabledFlag}
              />
              <div
                className={styles.selectAll}
                style={noCandSelected ? { cursor: 'pointer' } : {}}
                onClick={noCandSelected ? checkboxClickHandler : null}
              >
                {!noCandSelected
                  ? `${selectedCandCount} of ${totalCandCount} ${
                    selectedCandCount === 1 ? 'is' : 'are'
                    } selected`
                  : 'Select All'}
              </div>
            </>
          )}
          {screenW > MOBILE_THRESHOLD && (
            <div
              className={`${styles.moveTo}  ${
                !isMoveEnabledFlag && !noCandSelected
                  ? styles.moveToHover
                  : ''
              }`}
              style={
                !isMoveEnabledFlag
                  ? !noCandSelected
                    ? { cursor: 'pointer', color: '#7458d3' }
                    : { cursor: 'default' }
                  : { cursor: 'default', color: '#7458d3', fontFamily: 'Jost-500' }
              }
              onClick={
                !isMoveEnabledFlag && !noCandSelected
                  ? () => dispatch(setIsMoveEnabledFlag(true))
                  : null
              }
            >
              {isMoveEnabledFlag ? 'Select a stage from funnel to move' : 'Move to'}
            </div>
          )}
        </div>
        {!isMoveEnabledFlag ? (
          screenW <= MOBILE_THRESHOLD && selectedCandCount > 0 ? (
            <div
              className={`${styles.moveTo}  ${
                !isMoveEnabledFlag && !noCandSelected
                  ? styles.moveToHover
                  : ''
              }`}
              style={
                !isMoveEnabledFlag
                  ? !noCandSelected
                    ? { cursor: 'pointer', color: '#7458d3' }
                    : { cursor: 'default' }
                  : { cursor: 'default', color: '#7458d3', fontFamily: 'Jost-500' }
              }
              onClick={
                !isMoveEnabledFlag && !noCandSelected
                  ? () => dispatch(setIsMoveEnabledFlag(true))
                  : null
              }
            >
              Move to
            </div>
          ) : (
            <div className={styles.FilterRowRight}>
              <CustomSearchbar
                searchbarId="candidate-search"
                isExtended={isSearchExtendedFlag}
                iconOnClickForNotExtended={() => setIsSearchExtendedFlag(true)}
                wrapperStyle={{ height: '4rem', marginRight: '1rem' }}
                extendedStyle={{
                  width: screenW > MOBILE_THRESHOLD ? '30rem' : '26rem',
                  background: '#f4f4f499'
                }}
                notExtendedStyle={{ width: '3.2rem', padding: '0rem' }}
                inputFieldPlaceholder={screenW > MOBILE_THRESHOLD ? 'Search Candidate' : 'Search'}
                inputFieldValue={searchParamState}
                inputFieldOnChange={e => setSearchParamState(e.target.value)}
                crossOnClick={() => resetSearch()}
                autoFocus
                inputFieldOnKeyDown={e => {
                  if (e.keyCode === 13) {
                    // Enter
                    applySearch();
                  }
                }}
                extendedIconOnClick={applySearch}
                optionalHoverForSearchIcon={screenW > 576}
              />
              <div style={{ margin: '0.9rem 0rem', borderRight: '1px solid #F4F4F4' }} />
              <div className={styles.FilterIconWrapper}>
                <div className={styles.FilterIconBackground}>
                  <img
                    className={styles.FilterIcon}
                    src={filterIcon}
                    alt="filterDefault"
                    onClick={() => setFilterOpen(true)}
                  />
                </div>
              </div>
              {screenW > MOBILE_THRESHOLD && (
                <>
                  <div style={{ margin: '0.9rem 0rem', borderRight: '1px solid #F4F4F4' }} />
                  <div className={styles.sortByWrapper} onClick={changeOrderType}>
                    <text className={styles.sortByText}>Sort by</text>
                    <img
                      className={styles.SortIcon}
                      src={orderTypeRedux === 1 ? sortByScore : sortByDate}
                      alt="sortType"
                    />
                    <div className={styles.polygon} />
                  </div>
                </>
              )}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default FilterRow;
