import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/AssessmentAddedPopup.module.css';
import { ReactComponent as Tick } from '../../../images/entypo_check.svg';
import { ReactComponent as Close } from '../../../images/added-close.svg';
import { setShow } from '../../../redux/assessmentsVol2/slice';
import { showPopupRedux } from '../../../redux/assessmentsVol2/selectors';

export default function AssessmentAddedPopup({ type }) {
  const [style, setStyle] = useState({});
  const dispatch = useDispatch();
  const showPopup = useSelector(showPopupRedux);
  useEffect(() => {
    setTimeout(() => {
      setStyle({ width: showPopup.type === 1 ? 270 : 285, top: 15 });
    }, 100);

    setTimeout(() => {
      setStyle({ top: -37 });
    }, 2600);

    setTimeout(() => {
      setStyle({ width: 39, top: 15 });
    }, 2000);

    setTimeout(() => {
      dispatch(setShow({ ...showPopup, appear: false }));
    }, 3000);
  }, []);

  return (
    <div className={`${styles.closeContainer} `} style={style}>
      <Tick className={styles.icon} />
      <div className={styles.text}>
        {showPopup.type === 1 ? 'Question Successfully Added' : 'Question Successfully Removed'}
      </div>
      <Close className={styles.close} />
    </div>
  );
}
