import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../../Common/Components/CustomDropdown/CustomDropdown';
import LabeledTextInput from '../../../../Common/Components/LabeledTextInput/LabeledTextInput';
import TextInput from '../../../../Common/Components/TextInput';
import {
  setScreeningCurrencyType,
  setScreeningSalaryExpectation
} from '../../../../redux/AssessmentSession/slice';
import styles from './ScreeningCardComponent.module.css';

// Input Type: 20
const SalaryQuestion = ({ content, index, locationCurrency }) => {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState(
    content.currentCurrencyType
      ? content.currencyTypes?.filter(e => e.id === content.currentCurrencyType)[0]
      : content.currencyTypes?.filter(e => e.name === locationCurrency)[0] ||
          content.currencyTypes?.filter(e => e.id === 20)[0]  // default TRY id
  );

  const [expectedSalary, setExpectedSalary] = useState(content?.currentSalaryExpectation);

  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    if (content) {
      const temp = [];
      content.currencyTypes?.forEach(e => {
        temp.push({ id: e.id, title: e.name });
      });
      setCurrencyList(temp);
    }
  }, []);

  const [invalidNumberFlag, setInvalidNumberFlag] = useState(false);
  useEffect(() => {
    dispatch(setScreeningSalaryExpectation(expectedSalary));
    if (expectedSalary && expectedSalary?.toString().includes('.')) {
      setInvalidNumberFlag(true);
    } else {
      setInvalidNumberFlag(false);
    }
  }, [expectedSalary]);

  function removeComma(num) {
    if (num && num !== '') {
      let a;
      a = num.toString().replace(/,/g, ''); // 1125, but a string, so convert it to number
      a = parseInt(a, 10);
      return a;
    }
    return null;
  }

  function commafy(num) {
    if (num) {
      const innerNum = removeComma(num);
      return innerNum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return '';
  }

  const [currencyIcon, setCurrencyIcon] = useState();

  function currencyIconSelection(currencyId) {
    if (currencyId) {
      switch (currencyId) {
        case 10:
          return '$';
        case 20:
          return '₺';
        case 30:
          return '€';
        case 40:
          return '£';
        default:
          return '₺';
      }
    }
    return null;
  }

  useEffect(() => {
    dispatch(setScreeningCurrencyType(currency?.id));

    setCurrencyIcon(currencyIconSelection(currency?.id) || '₺');
  }, [currency]);

  return (
    <div className={`${styles.questionWrapper} ${styles.salaryWrapper}`}>
      <div className={styles.question}>{content?.salaryExpectationQuestion}</div>
      <div className={styles.answer}>
        <div className={styles.inputWrapper}>
          {currencyList && (
            <Dropdown
              placeholder=" "
              title="Currency"
              visibleItemNo={2}
              list={currencyList}
              defaultSelectedId={content?.currentCurrencyType || currency?.id}
              setSelectedOption={setCurrency}
              myOnClick={() => setExpectedSalary('')}
              wrapperStyle={{ width: '11rem', height: '100%' }}
            />
          )}

          <LabeledTextInput
            inputstyle={{ width: '10rem' }}
            style={{ marginLeft: '0.8rem' }}
            label="Enter an amount"
            placeholder="10,000"
            leftText={currencyIcon}
            value={commafy(expectedSalary)}
            onChange={e => {
              if (removeComma(e.target.value) >= 0 && removeComma(e.target.value) < 1000000) {
                setExpectedSalary(removeComma(e.target.value));
              }
            }}
            onKeyDown={e => {
              if (
                (e.keyCode < 48 || (e.keyCode > 57 && e.keyCode < 96) || e.keyCode > 105) &&
                e.keyCode !== 8 &&
                e.keyCode !== 0 &&
                e.keyCode !== 39 &&
                e.keyCode !== 37
              ) {
                e.preventDefault();
                e.stopPropagation();
              } else if (e.key === '.' || e.key === ',') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
        </div>
        <div
          className={styles.InvalidNumber}
          style={{ visibility: invalidNumberFlag ? 'visible' : 'hidden' }}
        >
          <sup>*</sup> Special characters are not allowed &apos;- . ,&apos;
        </div>
      </div>
    </div>
  );
};

export default SalaryQuestion;
