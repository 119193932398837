import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../Common/Components/CustomButton';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import {
  addConnectionToAssessmentAction,
  getAssessmentInfoAction
} from '../../../redux/assessmentDetail/actions';
import {
  setSettingsBannerMessage,
  setSettingsError,
  setSettingsSuccess
} from '../../../redux/assessmentDetail/slice';
import { getAtsListAction } from '../../../redux/assessmentsVol2/actions';
import { AtsList, connectionListRedux } from '../../../redux/assessmentsVol2/selectors';
import ConnectionItem from '../../AddAssessment/Components/ConnectionItem';
import styles from './AssessmentSettingsModal.module.css';
import CloseButton from '../../../Common/Components/CloseButton';

export default function AddConnectionModal({ show, onHide, id, mobile }) {
  const dispatch = useDispatch();

  const [switchValue, setSwitchValue] = useState(0);
  const array = [{ name: 'LinkedIn' }, { name: 'LinkedIn Recruiter' }, { name: 'Kariyer.net' }];
  const [selectedAts, setSelectedAts] = useState([]);
  const [connection, setConnection] = useState([]);
  const atsListRedux = useSelector(AtsList);
  const connectionList = useSelector(connectionListRedux);

  async function handleAddConnection() {
    if (selectedAts.length > 0 || connection.length > 0) {
      let data = {
        companyAssessmentId: id,
        connectionList: []
      };
      if (selectedAts.length > 0) {
        data = {
          companyAssessmentId: id,
          connectionList: selectedAts
        };
      } else if (connection.length > 0) {
        data = {
          companyAssessmentId: id,
          connectionList: connection
        };
      }
      const resp = await dispatch(addConnectionToAssessmentAction(data));
      await dispatch(getAssessmentInfoAction({ CompanyAssessmentId: id }));
      if (resp?.payload?.status === 200) {
        // success banner
        await dispatch(setSettingsBannerMessage('Changes saved successfully'));
        await dispatch(setSettingsSuccess(true));
      } else {
        // error banner
        await dispatch(setSettingsBannerMessage(resp?.payload?.message));
        await dispatch(setSettingsError(true));
      }
      await onHide();
    }
  }

  useEffect(async () => {
    const a = await dispatch(getAtsListAction({}));
    return () => {
      setSwitchValue(0);
    };
  }, []);

  const Web = (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        onHide();
      }}
      contentClassName={`${styles.connectionContent}`}
      dialogClassName={styles.dialogCustomModal}
    >
      <div className={styles.nameHeader}>Add Connection</div>
      <div className={styles.atsRow}>
        <div
          className={`${styles.switchContainer} ${switchValue === 1 ? styles.active : ''}`}
          onClick={() => {
            setSwitchValue(switchValue === 1 ? 0 : 1);
          }}
        >
          <div
            style={switchValue === 0 ? { marginLeft: 2 } : { marginLeft: 21 }}
            className={styles.switchButton}
          />
        </div>
        <div className={styles.switchText}>Applicant Tracking System (ATS)</div>
      </div>
      <div className={styles.listWrapper}>
        {atsListRedux &&
          (switchValue === 0 ? connectionList : atsListRedux)?.map((x, index) => {
            if (switchValue === 1) {
              return (
                <ConnectionItem
                  type={2}
                  text={x.name}
                  index={index}
                  active={selectedAts.includes(x.code)}
                  ats={switchValue === 1}
                  myonClick={text => {
                    setSelectedAts([x.code]);
                    setConnection([]);
                  }}
                />
              );
            }
            return (
              <ConnectionItem
                type={2}
                text={
                  // x.name === 'Linkedin Recruiter (Lite)'
                  //   ? 'LinkedIn Recruiter'
                  //   : x.name === 'KariyerNet'
                  //   ? 'Kariyer.net'
                  //   :
                  x.name
                }
                active={connection?.includes(x.code)}
                ats={switchValue === 1}
                index={index}
                myonClick={text => {
                  if (connection?.includes(x.code)) {
                    let temp = [];
                    temp = [...connection];
                    temp.splice(temp.indexOf(x.code), 1);
                    setConnection(temp);
                  } else {
                    setConnection([...connection, x.code]);
                  }
                  setSelectedAts([]);
                }}
              />
            );
          })}
      </div>

      <div className={styles.buttonWrapper}>
        <CustomButton
          size="small"
          type={2}
          textField="Cancel"
          style={{ marginRight: '12px' }}
          buttonOnClick={() => {
            setConnection([]);
            setSelectedAts([]);
            onHide();
          }}
        />
        <CustomButton
          size="small"
          type={1}
          textField="Save"
          buttonOnClick={() => {
            handleAddConnection();
          }}
          isDisabled={selectedAts.length === 0 && connection.length === 0}
        />
      </div>
    </Modal>
  );

  const Mobile = (
    <MobileModal
      outsideClickClose
      backdropStyle={{ overflow: 'hidden' }}
      show={show}
      id="add-connection-modal-mobile"
      onClose={() => {
        onHide();
      }}
      contentStyle={{ minWidth: '375px' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '24px',
          padding: '20px 20px 0px 20px'
        }}
      >
        <div className={styles.nameHeaderMobile}>Add Connection</div>{' '}
        <CloseButton onClick={() => onHide()} />
      </div>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }} className={styles.atsRow}>
        <div
          className={`${styles.switchContainer} ${switchValue === 1 ? styles.active : ''}`}
          onClick={() => {
            setSwitchValue(switchValue === 1 ? 0 : 1);
          }}
        >
          <div
            style={switchValue === 0 ? { marginLeft: 2 } : { marginLeft: 21 }}
            className={styles.switchButton}
          />
        </div>
        <div className={styles.switchTextMobile}>Applicant Tracking System (ATS)</div>
      </div>
      <div
        id={switchValue === 1 && 'connection-list-wrapper-mobile'}
        className={switchValue === 0 ? styles.listWrapperATSMobile : styles.listWrapperMobile}
      >
        {atsListRedux &&
          (switchValue === 0 ? connectionList : atsListRedux)?.map((x, index) => {
            if (switchValue === 1) {
              return (
                <ConnectionItem
                  mobile
                  type={2}
                  text={x.name}
                  index={index}
                  active={selectedAts.includes(x.code)}
                  ats={switchValue === 1}
                  myonClick={text => {
                    setSelectedAts([x.code]);
                    setConnection([]);
                  }}
                />
              );
            }
            return (
              <ConnectionItem
                mobile
                type={2}
                text={x.name}
                active={connection?.includes(x.code)}
                ats={switchValue === 1}
                index={index}
                myonClick={text => {
                  if (connection?.includes(x.code)) {
                    let temp = [];
                    temp = [...connection];
                    temp.splice(temp.indexOf(x.code), 1);
                    setConnection(temp);
                  } else {
                    setConnection([...connection, x.code]);
                  }
                  setSelectedAts([]);
                }}
              />
            );
          })}
      </div>

      <div className={styles.buttonWrapperMobile}>
        <CustomButton
          size="mobile"
          type={8}
          textField="Cancel"
          buttonOnClick={() => {
            setConnection([]);
            setSelectedAts([]);
            onHide();
          }}
        />
        <CustomButton
          size="mobile"
          type={1}
          textField="Save"
          buttonOnClick={() => {
            handleAddConnection();
          }}
          isDisabled={selectedAts.length === 0 && connection.length === 0}
        />
      </div>
    </MobileModal>
  );

  return <>{!mobile ? <>{Web}</> : <>{Mobile}</>}</>;
}
