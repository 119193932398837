export const selectAssessments = state => state?.assessments?.items || [];
export const selectAllAssessments = state => state?.assessments?.allAssessments || [];
export const customHiringFunnel = state => state.assessments.selectedHiringFunnel;
export const filteredAssessments = state => state.assessments.filteredItems;
export const filterData = state => state.assessments.filterData;
export const filterDataStatus = state => state.assessments.filterDataStatus;
export const rootAssessmentList = state => state.assessments.rootAssessmentList;
export const selectQuestions = state => state.assessments.questionList;
export const selectAssetssmentAts = state => state.assessments.assetssmentAts;
export const selectNewAssetssment = state => state.assessments.newAssessment;
export const selectActiveItemCount = state => state.assessments.activeItemCount;
export const selectArchiveItemCount = state => state.assessments.archiveItemCount;
export const selectAssessmentList2 = state => state.assessments.assessmentList2;
// export const assesmentCounts = state => state.assessments.assessmentCountsVol2;
export const publishedCount = state => state.assessments.publishedCount;
export const unpublishedCount = state => state.assessments.unpublishedCount;
export const archivedCount = state => state.assessments.archivedCount;
export const activeTab = state => state.assessments.activeTab;
export const getWidth = state => state.assessments.getWidth;
