import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../Common/Components/CustomButton';
import styles from './UnpaidContactSalesModal.module.css';
import { ReactComponent as SuspendInfo } from '../../../images/suspended.svg';
import { ReactComponent as Hands } from '../../../images/hands.svg';
import { contactSalesAction } from '../../../redux/plan/actions';
import { userSelector } from '../../../redux/auth/selectors';
import { setPaymentMethodModalShow, setShowPackageDetail } from '../../../redux/plan/slice';

export default function UnpaidContactSalesModal({
  show,
  onHide = () => {},
  freeTrialEnded,
  title,
  description,
  isCustom,
  addOnCode,
  setSuccess,
  ...props
}) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  async function handleButtonClick() {
    const data = {
      userId: user?.userId,
      bundleItemCode: addOnCode
    };
    const a = await dispatch(contactSalesAction(data));
    if (a.payload) {
      await onHide();
      await setSuccess(true);
    }
  }

  function goToChangePayment() {
    // onHide();
    dispatch(setPaymentMethodModalShow('change'));
  }

  function goToSeePlans() {
    // onHide();
    dispatch(setShowPackageDetail(true));
  }

  return (
    <Modal
      id="underQS"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={styles.container}
      show={show}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
    >
      {freeTrialEnded ? (
        <Hands className={styles.successIcon} />
      ) : (
        <SuspendInfo className={styles.successIcon} />
      )}
      <div className={styles.title}>{title}</div>
      <div className={styles.textWrapper}>
        <div className={styles.subtitle}>{description}</div>
      </div>
      <CustomButton
        style={{ width: '100%', marginBottom: '8px' }}
        type={1}
        textField={
          freeTrialEnded ? 'See plans' : isCustom ? 'Contact Sales' : 'Change Payment Method'
        }
        buttonOnClick={() => {
          if (freeTrialEnded) {
            goToSeePlans();
          } else if (isCustom) {
            handleButtonClick();
          } else {
            goToChangePayment();
          }
        }}
      />
    </Modal>
  );
}
