

import APIV2 from '../../constant/apiVol2';
import clientV2 from '../../constant/indexVol2';

// export const getCandidateInformationService = data =>
//   new Promise((resolve, reject) => {
//     client
//       .post(API.GetCandidateInformation, data)
//       .then(res => {
//         resolve(res.data.model);
//       })
//       .catch(error => {
//         console.log(error);
//         reject(error);
//       });
//   });

// export const getAssessmentCandidateListService = data =>
//   new Promise((resolve, reject) => {
//     client
//       .post(API.GetAssessmentCandidateList, data)
//       .then(res => {
//         resolve(res.data.model);
//       })
//       .catch(error => {
//         console.log(error);
//         reject(error);
//       });
//   });

// export const getFunnelInfoService = data =>
//   new Promise((resolve, reject) => {
//     client
//       .post(API.funnelItemListCount, data)
//       .then(res => {
//         resolve(res.data.model);
//       })
//       .catch(error => {
//         console.log(error);
//         reject(error);
//       });
//   });
// export const getCandidateFunnelInfoService = data =>
//   new Promise((resolve, reject) => {
//     client
//       .post(API.GetCandidateCurrentFunnel, data)
//       .then(res => {
//         resolve(res.data.model);
//       })
//       .catch(error => {
//         console.log(error);
//         reject(error);
//       });
//   });

// export const getCandidateSolutionsService = data =>
//   new Promise((resolve, reject) => {
//     client
//       .post(API.GetCandidateSolutions, data)
//       .then(res => {
//         resolve(res.data.model);
//       })
//       .catch(error => {
//         console.log(error);
//         reject(error);
//       });
//   });

// export const getCommentCountService = data =>
//   new Promise((resolve, reject) => {
//     client
//       .post(API.getCommentCount, data)
//       .then(res => {
//         resolve(res.data.model);
//       })
//       .catch(error => {
//         console.log(error);
//         reject(error);
//       });
//   });

export const getCandidateAssessmentLabelsService = data =>
  new Promise((resolve, reject) => {
    clientV2
      .get(APIV2.getCandidateAssessmentLabels, { params: data })
      .then(resolve)
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });

export const getAssessmentListForCandidateServiceVol2 = data =>
  new Promise((resolve, reject) => {
    clientV2.post(APIV2.getAssessmentListForCandidate, data).then(resolve).catch(reject);
  });

export const getCandidateSolutionsService = data =>
  new Promise((resolve, reject) => {
    clientV2.get(APIV2.getCandidateSolutions, { params: data }).then(resolve).catch(reject);
  });
