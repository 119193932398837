/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import isEmailFn from '../../utils/isEmail';
import styles from '../Styles/EmailInputInModal.module.css';

function EmailInputInModal({
  style,
  getLabel,
  className = '',
  noClass,
  placeholder,
  onChange,
  emails = [],
  setEmail,
  validateEmail,
  setRemove,
  infoMessageState,
  showInfoMessageState
}) {
  const emailInputRef = React.createRef();
  const [focused, setFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    return () => {
      setEmail([]);
    };
  }, []);

  const findEmailAddress = (value, isEnter) => {
    const validEmails = [];
    let inputValue = '';
    const re = /[ ,;]/g;
    const isEmail = isEmailFn;

    const addEmails = email => {
      if (emails.length >= 100) {
        infoMessageState[1]('You have reached the max count!');
        showInfoMessageState[1](true);
        return false;
      }
      for (let i = 0, l = emails.length; i < l; i += 1) {
        if (emails[i] === email) {
          infoMessageState[1]('This email already exists in invite list!');
          showInfoMessageState[1](true);
          return false;
        }
      }
      validEmails.push(email);
      return true;
    };

    if (value !== '') {
      if (re.test(value)) {
        const splitData = value.split(re).filter(n => n !== '' && n !== undefined && n !== null);
        const setArr = new Set(splitData);
        const arr = [...setArr];
        do {
          if (isEmail(`${arr[0]}`)) {
            addEmails(`${arr.shift()}`);
          } else if (arr.length === 1) {
            inputValue = `${arr.shift()}`;
          } else {
            arr.shift();
          }
        } while (arr.length);
      } else if (isEnter) {
        if (isEmail(value)) {
          addEmails(value);
        } else {
          inputValue = value;
        }
      } else {
        inputValue = value;
      }
    }

    setInputValue(inputValue);
    setEmail([...emails, ...validEmails]);

    if (validEmails.length && onChange) {
      onChange([...emails, ...validEmails]);
    }
  };

  const onChangeInputValue = value => {
    findEmailAddress(value);
  };

  const removeEmail = (index, isDisabled) => {
    if (isDisabled) {
      return;
    }
    setEmail([...emails.slice(0, index), ...emails.slice(index + 1)]);
    // onChange(emails);
  };

  const handleOnKeydown = e => {
    switch (e.which) {
      case 13:
      case 9:
        e.preventDefault();
        break;
      case 8:
        if (!e.currentTarget.value) {
          removeEmail(emails.length - 1, false);
        }
        break;
      default:
    }
  };

  useEffect(() => {
    onChange(emails);
  }, [emails]);

  const handlePaste = data => {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', '\t'];

    const innerData = data
      .split(new RegExp(separators.join('|')))
      .map(d => d.trim())
      .filter(x => x !== '');

    const emailList = [...emails, ...innerData];
    const newSet = [...new Set(emailList)]; // To remove duplicates
    setEmail(newSet.slice(0, 100));
    // return newSet;
  };
  const handleOnKeyup = e => {
    switch (e.which) {
      case 13:
      case 9:
        findEmailAddress(e.currentTarget.value, true);
        break;
      default:
    }
  };

  const handleOnChange = e => onChangeInputValue(e.currentTarget.value);

  const handleOnBlur = e => {
    setFocused(false);
    findEmailAddress(e.currentTarget.value, true);
  };

  const handleOnFocus = () => setFocused(true);

  return (
    <div
      className={`${className} ${noClass ? '' : styles['react-multi-email']} ${
        focused ? styles.focused : ''
      } ${inputValue === '' && emails.length === 0 ? styles.empty : ''}`}
      style={style}
      onClick={() => {
        if (emailInputRef.current) {
          emailInputRef.current.focus();
        }
      }}
    >
      {emails.map((email, index) => (
        <div data-tag key={index} myvalid={isEmailFn(email) ? 'valid' : 'not'}>
          <div data-tag-item>{email}</div>
          <span
            data-tag-handle
            onClick={() => {
              removeEmail(index);
            }}
          >
            ×
          </span>
        </div>
      ))}
      <input
        ref={emailInputRef}
        type="text"
        value={inputValue}
        placeholder="example@mail.com"
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onPaste={e => {
          e.preventDefault();
          const text = e.clipboardData.getData('text/plain');
          // setEmail([...emails, ...handlePaste(text)]);
          handlePaste(text);
        }}
        onKeyDown={handleOnKeydown}
        onKeyUp={handleOnKeyup}
      />
    </div>
  );
}

export default EmailInputInModal;
