export const candidateProfileSelector = state => state.candidateDetail.candidateProfile;
export const candidatePositionsSelector = state => state.candidateDetail.candidatePositions;
export const defaultAssessmentFunnelListSelector = state =>
  state.candidateDetail.defaultAssessmentFunnelList;
export const isLockedPositionsSelector = state => state.candidateDetail.isLockedPositions;
export const positionRequestStatusSelector = state => state.candidateDetail.positionRequestStatus;
export const positionRequestPayStatusSelector = state =>
  state.candidateDetail.positionRequestPayStatus;

export const infoBannerTextSelector = state => state.candidateDetail.infoBannerText;
export const infoBannerShowSelector = state => state.candidateDetail.infoBannerShow;
export const isCandidateAuthorizedSelector = state => state.candidateDetail.isCandidateAuthorized;
export const showRejectionPopupFlagSelector = state => state.candidateDetail.showRejectionPopupFlag;
export const showRejectionSuccessFlagSelector = state =>
  state.candidateDetail.showRejectionSuccessFlag;
export const isRejectionMailSentSelector = state => state.candidateDetail.isRejectionMailSent;

export const notesSelector = state => state.candidateDetail.notes;

export const candidateSolutionsSelector = state => state.candidateDetail.candidateSolutions;
export const solutionAuthorizeSelector = state => state.candidateDetail.solutionAuthorize;
export const priceForUnlockSelector = state => state.candidateDetail.priceForUnlock;
export const loadingForSolutionsSelector = state => state.candidateDetail.loadingForSolutions;

export const candidateBackgroundSelector = state => state.candidateDetail.candidateBackground;

export const candidateCvPathSelector = state => state.candidateDetail.candidateCvPath;

export const currentAssessmentFunnelListSelector = state =>
  state.candidateDetail.currentAssessmentFunnelList;
export const assessmentSummaryPositionRequestStatusSelector = state =>
  state.candidateDetail.assessmentSummaryPositionRequestStatus;
export const assessmentSummaryPositionRequestPayStatusSelector = state =>
  state.candidateDetail.assessmentSummaryPositionRequestPayStatus;

export const loadingForPersonalInfoSelector = state => state.candidateDetail.loadingForPersonalInfo;
export const personalInfoExperienceSelector = state => state.candidateDetail.personalInfoExperience;
export const personalInfoNationalitySelector = state =>
  state.candidateDetail.personalInfoNationality;
export const personalInfoSalarySelector = state => state.candidateDetail.personalInfoSalary;
export const personalInfoWorkTypeSelector = state => state.candidateDetail.personalInfoWorkType;

export const candidateActivitiesSelector = state => state.candidateDetail.candidateActivities;

export const showChangeFunnelPopupSelector = state => state.candidateDetail.showChangeFunnelPopup;
export const currentAssessmentInfoSelector = state => state.candidateDetail.currentAssessmentInfo;

// Request Mail
export const requestModalStateSelector = state => state.candidateDetail.requestModalState;
export const requestInfoBannerStateSelector = state => state.candidateDetail.requestInfoBannerState;
export const requestInfoBannerTextSelector = state => state.candidateDetail.requestInfoBannerText;
export const requestInfoBannerErrorFlagSelector = state =>
  state.candidateDetail.requestInfoBannerErrorFlag;
export const requestNumberExceedSelector = state => state.candidateDetail.requestNumberExceed;
