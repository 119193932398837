import { React, useState } from 'react';
import styles from '../Styles/LanguageDropdown.module.css';

const LanguageDropdownItem = ({
  id,
  title,
  selected = '',
  onSelect,
  key,
  last = false,
  first = false,
  onClick,
  itemStyle={},
  ...props
}) => {
  const [isSelected, setSelected] = useState(selected);
  return (
    <button
      type="button"
      className={`${styles.listItem} ${selected && styles.selectedItem} ${
        first && styles.firstItem
      } ${last && styles.lastItem}`}
      onClick={() => {onSelect();}}
      style={itemStyle}
    >
      {title}
    </button>
  );
};
export default LanguageDropdownItem;
