import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../Styles/CandidateResultPageContent.module.css';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  CvCheck,
  CvPath,
  newOpListRedux,
  rootAssessmentNameRedux,
  screeningListObjRedux,
  showNewOpFlagRedux
} from '../../../redux/AssessmentSession/selectors';

import NewOpComponent from './NewOpComponent/NewOpComponent';
import DoubleArrowIcon from '../Images/doubleArrow.svg';
import DoubleArrowIconMobile from '../Images/doubleArrowMobile.svg';
import CvCardComponent from './CvCardComponent/CvCardComponent';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD, useWindowSize } from '../../../utils/helpers';

import mixpanel from '../../../utils/mixpanel';
import ScreeningCardComponent from './Screening/ScreeningCardComponent';

const CLOSED = 0;
const OPEN = 1;

const CandidateResultPageContent = ({ id, application, innerPhone, isAlreadyPassed }) => {
  const [width, height] = useWindowSize();

  const [cardState, setCardState] = useState(OPEN);

  const cvCheck = useSelector(CvCheck);
  const [path, setPath] = useState(null);
  const companyName = useSelector(CompanyName);

  const [cvComponentSavedState, setCvComponentSavedState] = useState(false);

  /* ------------------------- Upload Your CV related methods -------------------------*/

  /* ------------------------- Upload Your CV related methods -------------------------*/

  /* ------------------------- New Op related methods -------------------------*/
  const newOpCompanyList = useSelector(newOpListRedux);
  const showNewOp = useSelector(showNewOpFlagRedux);

  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const [collapseNewOp, setCollapseNewOp] = useState(false);

  /* ------------------------- New Op related methods -------------------------*/

  /* ------------------------- Screening related methods -------------------------*/

  const questionListObj = useSelector(screeningListObjRedux);
  const [screeningCompleted, setScreeningCompleted] = useState(false);
  /* ------------------------- Screening related methods -------------------------*/

  /* ------------------------- Mixpanel related methods -------------------------*/

  const assessmentTitle = useSelector(AssessmentTitle);
  const candidateEmail = useSelector(CandidateEmail);
  const cvPath = useSelector(CvPath);

  useEffect(() => {
    mixpanel.track('Candidate Result - Pageview', {
      'Application Flag': !!application,
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': candidateEmail,
      'Is CV Needed Flag': cvCheck,
      'CV Flag': !!cvPath,
      'Already Scored Flag': isAlreadyPassed === 'true',
      'NewOpp Flag': !!showNewOp
    });
  }, []);

  /* ------------------------- Mixpanel related methods -------------------------*/

  return (
    <>
      {!screeningCompleted && questionListObj && cvCheck && (
        <ScreeningCardComponent closeScreening={() => setScreeningCompleted(true)} application={application} />
      )}

      { (screeningCompleted || !cvCheck)  && (
        <>
          {application ? (
            <div className={styles.title}>We received your application!</div>
          ) : isAlreadyPassed === 'true' ? (
            <div className={styles.title}>Great News!</div>
          ) : (
            <div className={styles.title}>Congrats!</div>
          )}

          {/* eslint-disable react/jsx-curly-brace-presence */}
          {application &&
            path &&
            isAlreadyPassed === 'true' && ( // done
              <div className={styles.subtitle}>Now check your contact information.</div>
            )}
          {application &&
            path &&
            isAlreadyPassed !== 'true' && ( // done
              <div className={styles.subtitle}>Now check your email for the next step.</div>
            )}
          {application &&
            !path &&
            isAlreadyPassed === 'true' && ( // done
              <div className={styles.subtitle}>
                Complete the missing part of your application and get closer to the next level!
              </div>
            )}
          {application &&
            !path &&
            isAlreadyPassed !== 'true' && ( // done
              <div className={styles.subtitle}>Now check your email for the next step.</div>
            )}

          {!application &&
            path &&
            isAlreadyPassed === 'true' && ( // done
              <div className={styles.subtitle}>
                You solved this assessment for another company before. <br />
                {`We shared your score with ${companyName}. No need to retake the assessment 🥳`}
              </div>
            )}
          {!application &&
            path &&
            isAlreadyPassed !== 'true' && ( // done
              <div className={styles.subtitle}>Thank you for completing the assessment.</div>
            )}
          {!application &&
            !path &&
            isAlreadyPassed === 'true' && ( // done
              <div className={styles.subtitle}>
                You solved this assessment for another company before. <br />
                {`We shared your score with ${companyName}. No need to retake the assessment 🥳`}
              </div>
            )}
          {!application &&
            !path &&
            isAlreadyPassed !== 'true' &&
            cvCheck && ( // done
              <div className={styles.subtitle}>
                Thank you for completing the assessment. <br />
                Complete the missing parts of your application to increase your chance.
              </div>
            )}
          {!application && !path && isAlreadyPassed !== 'true' && !cvCheck && (
            <div className={styles.subtitle}>Thank you for completing the assessment.</div>
          )}

          {cvCheck && (
            <CvCardComponent
              id={id}
              cardState={cardState}
              setCardState={setCardState}
              path={path}
              setPath={setPath}
              innerPhone={innerPhone}
              setParentSavedState={setCvComponentSavedState}
              application={application}
            />
          )}

          {showNewOp && (
            <div className={styles.NewOpWrapper}>
              {!collapseNewOp && (
                <div className={styles.NewOpInfoWrapper}>
                  <div className={styles.NewOpHeaderInfoTextWrapper}>
                    <div className={styles.DoubleArrowIconWrapper}>
                      <img
                        src={
                          width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                            ? DoubleArrowIconMobile
                            : DoubleArrowIcon
                        }
                        alt="doubleArrowIcon"
                      />
                    </div>
                    <div className={styles.NewOpInfoHeader}>
                      {width <= ASSESSMENT_FLOW_MOBILE_THRESHOLD
                        ? 'NEW OPPORTUNITIES ARE HERE!'
                        : 'NEW OPPORTUNITIES ARE JUST AROUND THE CORNER'}
                    </div>
                    <div className={styles.DoubleArrowIconWrapper}>
                      <img
                        src={
                          width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                            ? DoubleArrowIconMobile
                            : DoubleArrowIcon
                        }
                        alt="doubleArrowIcon"
                      />
                    </div>
                  </div>

                  <div className={styles.NewOpInfoSubtitle}>
                    <span>
                      These companies looking for a {rootAssessmentName}, too. Interested?
                    </span>
                    <span>You can also apply to them with just one click! 🚀</span>
                  </div>
                </div>
              )}

              <NewOpComponent
                id={id}
                newOpCompanyList={newOpCompanyList}
                collapseNewOp={setCollapseNewOp}
                application={application}
                path={path}
              />
            </div>
          )}

          {!cvCheck && <div className={styles.subtitle}>They will contact you soon</div>}

          {((((!cardState && (cvComponentSavedState || path)) || !cvCheck) && !showNewOp) ||
            (((!cardState && (cvComponentSavedState || path)) || !cvCheck) && collapseNewOp)) && (
            <div className={styles.closeWindow}>
              <span>You can close this window. 👋🏼</span>
            </div>
          )}
        </>
      )}

      {/* <Confetti
        gravity={0.1}
        friction={1}
        run={run}
        width={width}
        height={height}
        numberOfPieces={20}
        initialVelocityX={{ min: 0.4, max: 1 }}
        initialVelocityY={{ min: -5, max: -5 }}
        confettiSource={{ x: rect?.x + 50 + rect?.width, y: rect?.y, w: 100, h: 0 }}
      />
      <Confetti
        gravity={0.1}
        width={width}
        height={height}
        friction={1}
        run={run}
        numberOfPieces={20}
        initialVelocityX={{ min: -0.4, max: -1 }}
        initialVelocityY={{ min: -5, max: -5 }}
        confettiSource={{ x: rect?.x - 50, y: rect?.y, w: 100, h: 0 }}
      /> */}
    </>
  );
};

export default CandidateResultPageContent;
