import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../Common/Components/CustomButton';
import { ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD, isJsonString } from '../../../../utils/helpers';
import styles from './ScreeningCardComponent.module.css';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  CvCheck,
  rootAssessmentNameRedux,
  screeningCurrencyTypeRedux,
  screeningExperienceRedux,
  screeningListObjRedux,
  screeningNationalityIdsRedux,
  screeningSalaryExpectationRedux,
  screeningWorkTypeListRedux
} from '../../../../redux/AssessmentSession/selectors';
import WorkTypeQuestion from './WorkTypeQuestion';
import ExperienceQuestion from './ExperienceQuestion';
import WorkPermitQuestion from './WorkPermitQuestion';
import SalaryQuestion from './SalaryQuestion';
import { saveScreeningAnswerAction } from '../../../../redux/AssessmentSession/actions';
import mixpanel from '../../../../utils/mixpanel';
import { useIp } from '../../../../hooks/useIp';
import Skeleton from '../../../../Common/Components/Skeleton';

const Card = styled.div`
  background-color: white;
  margin-top: 2.4rem;
  box-shadow: 0px 1px 24px rgba(208, 208, 208, 0.15);
  border-radius: 12px;
  width: 100%;
  padding: 2.6rem 3.2rem 2rem;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  overflow: hidden;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const ScreeningCardComponent = ({ closeScreening = () => {}, application }) => {
  const dispatch = useDispatch();
  const email = useSelector(CandidateEmail);

  const questionListObj = useSelector(screeningListObjRedux);

  const reduxscreeningWorkTypeList = useSelector(screeningWorkTypeListRedux);
  const reduxscreeningExperience = useSelector(screeningExperienceRedux);
  const reduxscreeningNationalityIds = useSelector(screeningNationalityIdsRedux);
  const reduxscreeningCurrencyType = useSelector(screeningCurrencyTypeRedux);
  const reduxscreeningSalaryExpectation = useSelector(screeningSalaryExpectationRedux);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [locationInfo, currency] = useIp();

  // Mixpanel related
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const companyName = useSelector(CompanyName);
  const cvCheck = useSelector(CvCheck);

  const submitAnswers = async () => {
    setButtonLoading(true);

    // (Email = 10), (EncryptUserId = 20), (EncryptEmail = 30);
    const data = {
      userInfo: email,
      userInfoType: 10,
      workTypes: reduxscreeningWorkTypeList,
      salaryExpectation: reduxscreeningSalaryExpectation,
      nationalityIds: reduxscreeningNationalityIds,
      currencyType: reduxscreeningCurrencyType,
      experience: reduxscreeningExperience
    };
    const resp = await dispatch(saveScreeningAnswerAction(data));
    if (resp?.meta?.requestStatus === 'fulfilled') {
      closeScreening();
    }
    setButtonLoading(false);

    // Mixpanel
    mixpanel.track('Screening - Next Button Click', {
      'Application Flag': !!application,
      'Assessment Name': assessmentTitle,
      'Root Assessment Name': rootAssessmentName,
      'Company Name': companyName,
      'Candidate Email': email,
      'Already Scored Flag':
        isJsonString(localStorage.getItem('isCandidateAlreadyPassed')) &&
        JSON.parse(localStorage.getItem('isCandidateAlreadyPassed')),
      'Is CV Needed Flag': cvCheck,

      'Experience Year': questionListObj.experience.experiences
        .filter(e => reduxscreeningExperience === e.id)
        .map(e => e.name)[0],
      'Work Type Preference': questionListObj.workType.workTypes
        .filter(e => reduxscreeningWorkTypeList.includes(e.id))
        .map(e => e.name),
      'Work Permit List': questionListObj.nationality.nationalities
        .filter(e => reduxscreeningNationalityIds.includes(e.id))
        .map(e => e.name),
      'Currency Type': questionListObj.salaryExpectation.currencyTypes
        .filter(e => reduxscreeningCurrencyType === e.id)
        .map(e => e.name)[0],
      'Excted Salary': reduxscreeningSalaryExpectation,

      'Previously Selected Experience Year':
        questionListObj.experience.experiences
          .filter(e => questionListObj.experience.currentExperience === e.id)
          ?.map(e => e.name)[0] || null,
      'Previously Selected Work Type Preference': questionListObj.workType.workTypes
        .filter(e => questionListObj.workType.currentWorkTypeAnswers.includes(e.id))
        .map(e => e.name),
      'Previously Selected Work Permit List': questionListObj.nationality.nationalities
        .filter(e => questionListObj.nationality.currentNationality.includes(e.id))
        .map(e => e.name),
      'Previously Selected Currency Type':
        questionListObj.salaryExpectation.currencyTypes
          .filter(e => questionListObj.salaryExpectation.currentCurrencyType === e.id)
          ?.map(e => e.name)[0] || null,
      'Previously Selected Expected Salary':
        questionListObj.salaryExpectation.currentSalaryExpectation
    });
  };

  const [isAlreadyAnswered, setIsAlreadyAnswered] = useState(false);

  useEffect(() => {
    if (questionListObj) {
      if (
        questionListObj.experience.currentExperience ||
        questionListObj.workType.currentWorkTypeAnswers?.length !== 0 ||
        questionListObj.nationality.currentNationality?.length !== 0 ||
        questionListObj.salaryExpectation.currentCurrencyType ||
        questionListObj.salaryExpectation.currentSalaryExpectation
      ) {
        setIsAlreadyAnswered(true);
      }
    }
  }, [questionListObj]);

  return (
    <>
      {isAlreadyAnswered ? (
        <>
          <div className={styles.title}>Your Application Is In Process 🚀</div>
          <label className={styles.subtitle}>Companies wants to know you better.</label>
          <label className={styles.subtitle}>
            Check your answers if they are up to date and get closer to the next level!
          </label>
        </>
      ) : (
        <>
          <div className={styles.title}>Almost There! 🚀</div>
          <label className={styles.subtitle}>Companies also want to know these.</label>
          <label className={styles.subtitle}>
            Fill out the form to raise your chance of being hired.
          </label>
        </>
      )}

      <Card>
        {locationInfo ? (
          <>
            {isAlreadyAnswered && (
              <div className={styles.alreadyAnsweredTextWrapper}>
                We have your answers, are they up to date? If not,{' '}
                <text style={{ fontFamily: 'Jost-500' }}> you can update them easily!</text>
              </div>
            )}
            {questionListObj && (
              <>
                <ExperienceQuestion content={questionListObj.experience} index={0} />
                <WorkTypeQuestion content={questionListObj.workType} index={1} />
                <WorkPermitQuestion content={questionListObj.nationality} index={2} />
                <SalaryQuestion
                  content={questionListObj.salaryExpectation}
                  index={3}
                  locationCurrency={currency}
                />
              </>
            )}

            <div className={styles.buttonWrapper}>
              <CustomButton
                textField={isAlreadyAnswered ? 'Save & Check CV' : 'Next'}
                buttonOnClick={() => {
                  submitAnswers();
                }}
                size="medium"
                loading={buttonLoading}
                isDisabled={
                  reduxscreeningWorkTypeList?.length === 0 ||
                  !reduxscreeningSalaryExpectation ||
                  reduxscreeningSalaryExpectation === '' ||
                  reduxscreeningSalaryExpectation === '0' ||
                  reduxscreeningSalaryExpectation.toString().includes('.') ||
                  reduxscreeningSalaryExpectation.toString().includes(',') ||
                  reduxscreeningSalaryExpectation.toString().includes('-') ||
                  parseInt(reduxscreeningSalaryExpectation, 10) === 0 ||
                  reduxscreeningNationalityIds?.length === 0 ||
                  !reduxscreeningCurrencyType ||
                  !reduxscreeningExperience
                }
              />
            </div>
          </>
        ) : (
          <Skeleton type="salary" />
        )}
      </Card>
    </>
  );
};

export default ScreeningCardComponent;
