import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import styles from '../Styles/CustomPopover.module.css';
import { ReactComponent as Hand } from '../../images/handEmoji.svg';

const CustomPopover = ({
  trigger = 'click',
  placement = 'left',
  show,
  children,
  className,
  style
}) => {
  return (
    <OverlayTrigger
      show={show}
      placement={placement}
      trigger={trigger}
      overlay={
        <Popover style={style} className={`${styles.popover} ${className}`}>
          <Hand style={{ scale:"1.3" }} />
          <span className={styles.stayTunedText}>Stay Tuned!</span>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomPopover;
