import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import styles from './FilterModal.module.css';
import downArrow from './down-arrow.svg';
import upArrow from './up-arrow.svg';
import Checkbox from '../../../Common/RawCheckbox/Checkbox';
import MultiSelectDropdown from '../../../Common/Components/MultiselectDropdown/MultiselectDropdown';
import Dropdown from '../../../Common/Components/CustomDropdown/CustomDropdown';
import LabeledTextInput from '../../../Common/Components/LabeledTextInput/LabeledTextInput';
import { ReactComponent as Divider } from './divider.svg';
import { screeningFiltersSelector } from '../../../redux/assessmentDetailVol2/selectors';
import { useIp } from '../../../hooks/useIp';
import { useWindowSize } from '../../../utils/helpers';

export default function FilterItemScreening({
  filterName,
  openItem,
  setOpenItem = () => {},
  experienceFilter,
  setExperienceFilter = () => {},
  workTypeFilter,
  setWorkTypeFilter = () => {},
  workPermitFilter,
  setWorkPermitFilter = () => {},
  salaryCurrencyFilter,
  setSalaryCurrencyFilter = () => {},
  salaryMinFilter,
  setSalaryMinFilter = () => {},
  salaryMaxFilter,
  setSalaryMaxFilter = () => {}
}) {
  // Screening Filter Variables
  const screeningData = useSelector(screeningFiltersSelector);
  const [locationInfo, currencyText] = useIp();
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyIcon, setCurrencyIcon] = useState();
  const [screenW, screenH] = useWindowSize();

  // Inner Components
  const ExperienceCheckbox = ({ id, label, index, isSelected, setSelected = () => {} }) => {
    const StyledLabel = styled.div`
      font-family: 'Jost-300';
      font-size: 16px;
      line-height: 23px;
      color: #393939;

      @media screen and (max-width: 576px) {
        font-size: 14px;
      }
    `;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '18px',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => setSelected(!isSelected)}
      >
        <Checkbox
          myStyle={{ maxWidth: '16px', maxHeight: '16px', minWidth: '16px', minHeight: '16px' }}
          id={`filter-screening-experience-${index}`}
          isChecked={isSelected}
          setIsChecked={setSelected}
          key={index}
        />
        <StyledLabel>{label}</StyledLabel>
      </div>
    );
  };

  const WorkTypeCheckbox = ({ id, label, index, isSelected, setSelected = () => {} }) => {
    const StyledLabel = styled.div`
      font-family: 'Jost-300';
      font-size: 16px;
      line-height: 23px;
      color: #393939;

      @media screen and (max-width: 576px) {
        font-size: 14px;
      }
    `;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '18px',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => setSelected(!isSelected)}
      >
        <Checkbox
          myStyle={{ maxWidth: '16px', maxHeight: '16px', minWidth: '16px', minHeight: '16px' }}
          id={`filter-screening-workType-${index}`}
          isChecked={isSelected}
          setIsChecked={setSelected}
          key={index}
        />
        <StyledLabel>{label}</StyledLabel>
      </div>
    );
  };

  function currencyIconSelection(currencyId) {
    if (currencyId) {
      switch (currencyId) {
        case 10:
          return '$';
        case 20:
          return '₺';
        case 30:
          return '€';
        case 40:
          return '£';
        default:
          return '₺';
      }
    }
    return null;
  }

  function removeComma(num) {
    if (num && num !== '') {
      let a;
      a = num.toString().replace(/,/g, ''); // 1125, but a string, so convert it to number
      a = parseInt(a, 10);
      return a;
    }
    return null;
  }

  function commafy(num) {
    if (num) {
      const innerNum = removeComma(num);
      return innerNum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return '';
  }

  useEffect(() => {
    setCurrencyIcon(currencyIconSelection(salaryCurrencyFilter?.id) || '₺');
  }, [salaryCurrencyFilter]);

  useEffect(() => {
    if (screeningData) {
      const temp = [];
      screeningData?.salaryExpectation.currencyTypes.forEach((x, i) => {
        temp.push({ title: x.name, id: x.id, key: x.id });
      });
      setCurrencyData(temp);
    }
  }, [screeningData]);

  function handleAllCheckbox(id) {
    if (experienceFilter?.includes(id)) {
      const temp = [...experienceFilter];
      const sp = temp.splice(temp.indexOf(id), 1);
      setExperienceFilter(temp);
    } else {
      const temp = [...experienceFilter];
      temp.push(id);
      setExperienceFilter(temp);
    }
  }

  function handleAllCheckbox2(id) {
    if (workTypeFilter?.includes(id)) {
      const temp = [...workTypeFilter];
      const sp = temp.splice(temp.indexOf(id), 1);
      setWorkTypeFilter(temp);
    } else {
      const temp = [...workTypeFilter];
      temp.push(id);
      setWorkTypeFilter(temp);
    }
  }

  return (
    <div className={styles.itemContainer}>
      <div
        className={styles.innerHeaderWrapper}
        onClick={() => {
          if (openItem === filterName) {
            setOpenItem('');
          } else {
            setOpenItem(filterName);
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span className={styles.itemHeader}>Filter Candidates by&nbsp;</span>
          <span className={styles.itemHeaderBold}>{filterName}</span>
        </div>
        <div className={styles.rightHeaderWrapper}>
          {experienceFilter?.length > 0 ||
          workTypeFilter?.length > 0 ||
          workPermitFilter?.length > 0 ||
          salaryMinFilter ||
          salaryMaxFilter ? (
            <div className={styles.yellowIcon} />
          ) : null}
          <span className={styles.icon}>
            <img alt="" src={openItem === filterName ? upArrow : downArrow} />
          </span>
        </div>
      </div>
      {openItem === filterName ? (
        <div className={styles.screeningWrapper}>
          <div className={styles.subDivWrapper}>
            <span className={styles.screeningSubheader}>Experience</span>
            <div className={styles.expListWrapper} style={{ gap: screenW < 576 && '20px' }}>
              {screeningData?.experience?.experiences?.map((exp, i) => (
                <ExperienceCheckbox
                  index={i}
                  label={exp?.name}
                  id={exp?.id}
                  isSelected={experienceFilter?.includes(exp?.id)}
                  setSelected={() => handleAllCheckbox(exp?.id)}
                />
              ))}
            </div>
          </div>
          <div className={styles.subDivWrapper}>
            <span className={styles.screeningSubheader}>Work Type Preference</span>
            <div className={styles.expListWrapper}>
              {screeningData?.workType?.workTypes?.map((w, i) => (
                <WorkTypeCheckbox
                  id={w?.id}
                  label={w?.name}
                  index={i}
                  isSelected={workTypeFilter?.includes(w?.id)}
                  setSelected={() => handleAllCheckbox2(w?.id)}
                />
              ))}
            </div>
          </div>
          <div className={styles.subDivWrapper}>
            <span className={styles.screeningSubheader}>Work Permit</span>
            <MultiSelectDropdown
              list={screeningData?.nationality?.nationalities}
              setSelectedList={setWorkPermitFilter}
              selectedList={workPermitFilter}
              placeholder="Select country"
            />
          </div>
          <div className={styles.subDivWrapper} style={{ maxHeight: '7.5rem', height: '7.5rem' }}>
            <span className={styles.screeningSubheader}>Salary Expectation</span>
            <div className={styles.salaryWrapper}>
              {currencyData?.length > 0 ? (
                <Dropdown
                  placeholder=" "
                  title="Currency"
                  visibleItemNo={2}
                  list={currencyData}
                  defaultSelectedId={
                    salaryCurrencyFilter.id ||
                    currencyData?.filter(x => x.title === currencyText)[0]?.id
                  }
                  setSelectedOption={setSalaryCurrencyFilter}
                  myOnClick={() => {
                    setSalaryMinFilter();
                    setSalaryMaxFilter();
                  }}
                  wrapperStyle={{
                    width: '11rem',
                    minHeight: 'calc(4.8rem + 4px)',
                    maxHeight: 'calc(4.8rem + 4px)'
                  }}
                />
              ) : null}
              <LabeledTextInput
                inputstyle={{ width: screenW < 576 ? '9rem' : '10rem' }}
                style={{ marginLeft: '0.8rem', maxHeight: 'calc(4.8rem + 4px)' }}
                label="Minimum"
                placeholder="20,000"
                leftText={screenW < 576 ? null : currencyIcon}
                value={commafy(salaryMinFilter)}
                onChange={e => {
                  if (removeComma(e.target.value) >= 0 && removeComma(e.target.value) < 1000000) {
                    setSalaryMinFilter(removeComma(e.target.value));
                  }
                }}
                onKeyDown={e => {
                  if (
                    (e.keyCode < 48 || (e.keyCode > 57 && e.keyCode < 96) || e.keyCode > 105) &&
                    e.keyCode !== 8 &&
                    e.keyCode !== 0 &&
                    e.keyCode !== 39 &&
                    e.keyCode !== 37
                  ) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else if (e.key === '.' || e.key === ',') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              />

              <Divider style={{ marginLeft: '0.8rem' }} />

              <LabeledTextInput
                inputstyle={{ width: screenW < 576 ? '9rem' : '10rem' }}
                style={{ marginLeft: '0.8rem', maxHeight: 'calc(4.8rem + 4px)' }}
                label="Maximum"
                placeholder="80,000"
                leftText={screenW < 576 ? null : currencyIcon}
                value={commafy(salaryMaxFilter)}
                onChange={e => {
                  if (removeComma(e.target.value) >= 0 && removeComma(e.target.value) < 1000000) {
                    setSalaryMaxFilter(removeComma(e.target.value));
                  }
                }}
                onKeyDown={e => {
                  if (
                    (e.keyCode < 48 || (e.keyCode > 57 && e.keyCode < 96) || e.keyCode > 105) &&
                    e.keyCode !== 8 &&
                    e.keyCode !== 0 &&
                    e.keyCode !== 39 &&
                    e.keyCode !== 37
                  ) {
                    e.preventDefault();
                    e.stopPropagation();
                  } else if (e.key === '.' || e.key === ',') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
