import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../Common/Components/CustomButton';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import { getRootAssessmentDropdownListAction } from '../../../redux/addAssessment/actions';
import {
  rootAssmCategories,
  rootAssmJobRoles,
  rootAssmSeniorities
} from '../../../redux/addAssessment/selectors';
import { setRootAssmCategories, setRootAssmJobRoles } from '../../../redux/addAssessment/slice';
import { selectedRootAssmFiltersRedux } from '../../../redux/assessmentsVol2/selectors';
import styles from '../Styles/MobileAssessmentFilterModal.module.css';

export default function MobileAssessmentFilterModal({
  show,
  onHide,
  id,
  applyFilter = () => {},
  clearAll = () => {},

  selectedCategory,
  selectCategory = () => {},
  selectedJobRole,
  selectJobRole = () => {},
  selectedSeniority,
  selectSeniority = () => {}
}) {
  const dispatch = useDispatch();

  const assmCategories = useSelector(rootAssmCategories);
  const assmJobRoles = useSelector(rootAssmJobRoles);
  const assmSeniorities = useSelector(rootAssmSeniorities);

  const [clearAllDisabled, setClearAllDisabled] = useState('true');
  const [applyDisabled, setApplyDisabled] = useState(true);

  const filtersReduxMobile = useSelector(selectedRootAssmFiltersRedux);

  // Handles disability of clear all button
  useEffect(() => {
    if (selectedCategory || selectedJobRole || selectedSeniority) {
      setClearAllDisabled('false');
    } else {
      setClearAllDisabled('true');
    }
  }, [selectedCategory, selectedJobRole, selectedSeniority]);

  // Clears the selected filters and fetches all data
  function handleClearAll() {
    selectCategory(null);
    selectJobRole(null);
    selectSeniority(null);
    onHide();
    clearAll();
  }

  // Handles cancel events
  function handleCancel() {
    selectCategory(filtersReduxMobile.category);
    selectJobRole(filtersReduxMobile.role);
    selectSeniority(filtersReduxMobile.seniority);
  }

  // Handles category selection according to the job role selected
  async function handleJobRoleSelected(item) {
    if (item?.name) {
      const data = { JobRoleId: item?.roleId };
      const a = await dispatch(getRootAssessmentDropdownListAction(data));
      const tempCategory = assmCategories.filter(
        cat => cat.catId === a.payload.selectedCategory
      )[0];
      selectCategory(tempCategory);
    }
  }

  useEffect(() => {
    return () => {
      setApplyDisabled(true);
    };
  }, [show]);

  return (
    <MobileModal
      backdropStyle={{ overflow: 'hidden' }}
      show={show}
      onClose={onHide}
      id={id}
      outsideClickClose
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.filterText}>Filter</span>
          <span
            mydisabled={clearAllDisabled}
            className={styles.clearAll}
            onClick={() => handleClearAll()}
          >
            Clear all
          </span>
        </div>

        <Dropdown
          height="50px"
          list={assmCategories}
          headerStyle={{ fontSize: '16px' }}
          listStyle={{ fontSize: '16px', position: 'relative' }}
          isSearchable
          isCancellable
          openItemCount={assmCategories?.length < 4 ? assmCategories?.length : 4}
          placeholder="Select category"
          title="Category"
          setSelectedOption={selectCategory}
          selectedOption={selectedCategory}
          crossOnClick={async () => {
            await dispatch(setRootAssmJobRoles([]));
            selectJobRole(null);
            selectCategory(null);
            dispatch(getRootAssessmentDropdownListAction({}));
            setApplyDisabled(false);
          }}
          selectOnClick={async item => {
            await dispatch(setRootAssmJobRoles([]));
            selectJobRole(null);
            dispatch(getRootAssessmentDropdownListAction({ Category: item.catId }));
            setApplyDisabled(false);
          }}
        />
        <Dropdown
          height="50px"
          list={assmJobRoles}
          headerStyle={{ fontSize: '16px' }}
          listStyle={{ fontSize: '16px', position: 'relative' }}
          isSearchable
          isCancellable
          openItemCount={assmJobRoles?.length < 4 ? assmJobRoles?.length : 4}
          placeholder="Select job role"
          title="Job Role"
          setSelectedOption={selectJobRole}
          selectedOption={selectedJobRole}
          selectOnClick={async item => {
            await dispatch(setRootAssmCategories([]));
            await handleJobRoleSelected(item);
            setApplyDisabled(false);
          }}
          crossOnClick={() => {
            selectJobRole(null);
            setApplyDisabled(false);
          }}
        />
        <Dropdown
          height="50px"
          list={assmSeniorities}
          headerStyle={{ fontSize: '16px' }}
          listStyle={{ fontSize: '16px', position: 'relative' }}
          isCancellable
          openItemCount={3}
          placeholder="Select seniority"
          title="Seniority"
          setSelectedOption={selectSeniority}
          selectedOption={selectedSeniority}
          selectOnClick={() => {
            selectSeniority(null);
            setApplyDisabled(false);
          }}
          crossOnClick={() => {
            selectSeniority(null);
            setApplyDisabled(false);
          }}
        />

        <div className={styles.buttonWrapper}>
          <CustomButton
            customStyle={{ width: '100%', fontSize: '16px' }}
            type={8}
            textField="Cancel"
            buttonOnClick={() => {
              handleCancel();
              onHide();
            }}
          />
          <CustomButton
            customStyle={{ width: '100%', fontSize: '16px' }}
            type={1}
            textField="Apply Filter"
            isDisabled={applyDisabled}
            buttonOnClick={() => {
              applyFilter();
              onHide();
            }}
          />
        </div>
      </div>
    </MobileModal>
  );
}
