import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import QuestionHeader from '../../Components/QuestionHeader/QuestionHeader';
import styles from './QuestionPage.module.css';
import Timer from '../../Components/Timer/Timer';
import {
  ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD,
  ASSESSMENT_FLOW_MOBILE_THRESHOLD,
  useWindowSize
} from '../../../../utils/helpers';
import MultipleChoiceQuestion from '../../Components/MultipleChoiceQuestion/multipleChoiceQuestion';
import {
  assessmentSessionTokenRedux,
  ShowBanner,
  questionInfoRedux,
  BannerText
} from '../../../../redux/AssessmentSession/selectors';
import {
  getCurrentQuestionAction,
  getNextQuestionAction,
  saveCurrentCodingAnswerAction,
  saveCurrentFreeTextAnswerAction,
  saveCurrentMultipleChoiceAnswerAction,
  incrementTabSwitchAction,
  submitCodingQuestionAnswerAction,
  submitFreeTextQuestionAnswerAction,
  submitMultipleChoiceQuestionAnswerAction
} from '../../../../redux/AssessmentSession/actions';
import FreeTextQuestion from '../../Components/FreeTextQuestion/freeTextQuestion';
import CodingQuestion from '../../Components/CodingQuestion/codingQuestion';
import { useInterval } from '../../../../utils/useInterval';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import { resetQuestionInfo, setBannerStatus, setBannerText } from '../../../../redux/AssessmentSession/slice';
import { ReactComponent as Error } from '../../../../images/errorSubmit.svg';

const QuestionPage = () => {
  const dispatch = useDispatch();
  const qInfo = useSelector(questionInfoRedux);
  const assessmentToken = useSelector(assessmentSessionTokenRedux);

  const SAVE_INTERVAL = 10000;

  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const history = useHistory();
  const [screenWidth, screenHeight] = useWindowSize();
  const [headersHeight, setHeadersHeight] = useState();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [ftAnswer, setFtAnswer] = useState('');
  const [codingAnswer, setCodingAnswer] = useState('');
  const [codeLanguage, setCodeLanguage] = useState('');
  const [selectedLangIndex, setSelectedLangIndex] = useState(-1);
  const showBanner = useSelector(ShowBanner);
  const bannerText = useSelector(BannerText);
  const [qDescription, setqDescription] = useState();
  const [error, setError] = useState(false);
  const [timerLoading, setTimerLoading] = useState(false);

  const updateAnswerForPhp = code => {
    let ret = code;
    ret = ret.replace('<?php ', '');
    ret = ret.replace('?>', '');
    return ret;
  };

  // Next ve submit tıklandığında çağrılıyor
  const submitQuestion = async () => {
    let data = {};
    let resp;
    switch (qInfo.questionTypeId) {
      case 1:
        data = {
          questionId: qInfo.questionId,
          token: assessmentToken,
          optionIndex: selectedIndex,
          isFinalized: true
        };
        resp = await dispatch(submitMultipleChoiceQuestionAnswerAction(data));
        if (resp.type === 'submitMultipleChoiceQuestionAnswer/fulfilled') {
          return true;
        }
        break;

      case 3:
        data = {
          questionId: qInfo.questionId,
          token: assessmentToken,
          answerText: ftAnswer || '',
          isFinalized: true
        };
        resp = await dispatch(submitFreeTextQuestionAnswerAction(data));
        if (resp.type === 'submitFreeTextQuestionAnswer/fulfilled') {
          return true;
        }
        break;

      case 2:
        data = {
          questionId: qInfo.questionId,
          token: assessmentToken,
          // answerCode: codingAnswer || '',
          answerCode:
            codeLanguage?.label === 'PHP' ? updateAnswerForPhp(codingAnswer) : codingAnswer || '',
          answerCodeLanguages: codeLanguage?.label || 'notSelected',
          isFinalized: true
        };
        resp = await dispatch(submitCodingQuestionAnswerAction(data));
        if (resp.type === 'submitCodingQuestionAnswer/fulfilled') {
          return true;
        }
        break;

      default:
        break;
    }
    return false;
  };

  // Belirli aralıklarla kullanıcı cevaplarını kaydediyor
  const saveAnswer = () => {
    let data = {};
    switch (qInfo.questionTypeId) {
      case 1:
        data = {
          questionId: qInfo.questionId,
          token: assessmentToken,
          optionIndex: selectedIndex,
          isFinalized: false
        };
        dispatch(saveCurrentMultipleChoiceAnswerAction(data));
        break;

      case 3:
        data = {
          questionId: qInfo.questionId,
          token: assessmentToken,
          answerText: ftAnswer || '',
          isFinalized: false
        };
        dispatch(saveCurrentFreeTextAnswerAction(data));
        break;

      case 2:
        data = {
          questionId: qInfo.questionId,
          token: assessmentToken,
          answerCode: codingAnswer || '',
          // answerCode: codeLanguage?.label === "PHP" ? updateAnswerForPhp(codingAnswer) : (codingAnswer || ''),
          answerCodeLanguages: codeLanguage?.label || '',
          isFinalized: false
        };
        dispatch(saveCurrentCodingAnswerAction(data));
        break;

      default:
        break;
    }
  };

  useInterval(
    () => {
      saveAnswer();
    },
    SAVE_INTERVAL,
    qInfo
  );

  // Görseller için path düzenlemesi
  const fixDescription = () => {
    try {
      let questionBody = JSON.parse(qInfo?.descHtmlJson);
      questionBody = questionBody.replaceAll('https://api.coens.io/', '');
      questionBody = questionBody.replaceAll('https://file.coens.io/', '');
      questionBody = questionBody.replaceAll(
        '<img src="',
        `<img src="${process.env.REACT_APP_IMAGE_API}/`
      );
      setqDescription(questionBody);
    } catch (error) {
      console.log(error);
    }
  };

  // Header ve timer componentlarının yüksekliklerini alıyor
  const calculateUpperHeight = () => {
    setTimeout(() => {
      const hHeight = document.getElementById('qHeader')?.getBoundingClientRect().height + 1;
      const tHeight = document.getElementById('qTimer')?.getBoundingClientRect().height + 1;
      setHeadersHeight(hHeight + tHeight);
    }, 200);
  };

  // Kodlama soruları - eğer kullanıcının kayıtlı datası varsa onların getirtilmesi için
  const [readSavedData, setReadSavedData] = useState(false);

  useEffect(async () => {
    // Sayfa yenilendiğinde current question bilgisini çekiyor
    if (!qInfo) {
      const data = {
        Token: assessmentToken
      };
      await dispatch(getCurrentQuestionAction(data));
    }
    calculateUpperHeight();
    if (qInfo) {
      fixDescription();

      if (qInfo?.lastMultipleChoiceAnswer || qInfo?.lastMultipleChoiceAnswer === 0) {
        setSelectedIndex(qInfo.lastMultipleChoiceAnswer);
      } else {
        setSelectedIndex(-1);
      }

      if (qInfo?.lastFreeTextAnswer) {
        setFtAnswer(qInfo.lastFreeTextAnswer);
      } else {
        setFtAnswer('');
      }

      if (qInfo?.lastCodingLanguage) {
        setReadSavedData(true);
        setCodeLanguage({ value: qInfo.lastCodingLanguage, label: qInfo.lastCodingLanguage });
      } else {
        setCodeLanguage('');
      }

      if (qInfo?.lastCodingAnswer) {
        setReadSavedData(true);
        setCodingAnswer(qInfo.lastCodingAnswer);
      } else {
        setCodingAnswer('');
      }

      // Gelen kodlama sorusu sadece tek dil ile çözülebiliyorsa onu seçili getir
      if (qInfo?.language?.length === 1) {
        setSelectedLangIndex(0);
        // Dropdown için hazırlık
        setCodeLanguage({ value: qInfo.language[0], label: qInfo.language[0] });
      } else {
        setSelectedLangIndex(-1);
      }
    }
  }, [qInfo]);

  const onNextClick = async () => {
    const bool = await submitQuestion();
    setTimerLoading(true);
    if (bool) {
      const data2 = {
        Token: assessmentToken
      };
      setError(false);
      setSelectedLangIndex(-1);
      await dispatch(getNextQuestionAction(data2));
      setTimerLoading(false);
    } else {
      dispatch(setBannerText('An error occurred during submission, please try again'));
      setError(true);
      dispatch(setBannerStatus(true));
      setTimerLoading(false);
    }
  };

  function cancelFullscreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      doc.fullscreenElement ||
      doc.mozFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.msFullscreenElement
    ) {
      cancelFullScreen.call(doc);
    }
  }

  const onSubmitClick = async () => {
    const bool = await submitQuestion();
    if (bool) {
      try {
        cancelFullscreen();
      } catch (error) {
        console.log('Fullscreen error', error);
      }
      history.replace(`candidate-result?assessment=${assessmentId}`);
      setError(false);
    } else {
      dispatch(setBannerText('An error occurred during submission, please try again'));
      setError(true);
      dispatch(setBannerStatus(true));
    }
  };

  useEffect(() => {
    if (!readSavedData) {
      if (selectedLangIndex !== -1) {
        if (qInfo?.initialCodeText) {
          setCodingAnswer(qInfo.initialCodeText[selectedLangIndex]);
        }
      }
    }
    setReadSavedData(false);
  }, [selectedLangIndex]);

  const resetToInitialCode = () => {
    setCodingAnswer(qInfo.initialCodeText[selectedLangIndex]);
  };

  /* Assessment rules */

  /* Prevents right click for the assesssment page */
  const handleContextMenu = event => {
    event.preventDefault();
  };

  /* Increment the counter every time there is a switch */
  const handleTabSwitch = async () => {
    if (document.visibilityState === 'visible') {
      const rawData = { token: assessmentToken };
      await dispatch(incrementTabSwitchAction(rawData));
    }
  };
  /* Forwards the history state FIXME: could be buggy */
  const blockBackButton = event => {
    dispatch(resetQuestionInfo());
    event.preventDefault();
    history.go(1);
  };

  useEffect(() => {
    /* Prevents right click for the assesssment page */
    document.addEventListener('contextmenu', handleContextMenu);
    /* Fires every time visibility of the body changes */
    document.addEventListener('visibilitychange', handleTabSwitch);
    /* Prevent back button by going forward */
    window.onpopstate = blockBackButton;

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('visibilitychange', handleTabSwitch);
    };
  }, []);

  return (
    <div className={styles.QuestionMainWrapper}>
      {qInfo && (
        <>
          <QuestionHeader />
          <InfoBanner
            text={bannerText}
            show={showBanner}
            CustomIcon={error ? Error : null}
            setStatus={async type => {
              await dispatch(setBannerStatus(type));
              dispatch(setBannerText('Answer is automatically submitted'));
            }}
          />
          {!timerLoading && (
            <Timer
              qIndex={qInfo.questionIndex}
              qCount={qInfo.totalQuestion}
              totalTime={qInfo.questionTime}
              onNextClick={onNextClick}
              onSubmitClick={onSubmitClick}
              screenWidth={screenWidth}
              qStartDate={qInfo.startDate}
              qNow={qInfo.now}
            />
          )}

          {!timerLoading && qInfo?.questionTypeId === 1 && (
            <MultipleChoiceQuestion
              qData={qInfo}
              qDesc={qDescription}
              headersHeight={headersHeight}
              selected={[selectedIndex, setSelectedIndex]}
              mcMobileThreshold={ASSESSMENT_FLOW_MOBILE_THRESHOLD}
              screenWidth={screenWidth}
            />
          )}
          {!timerLoading && qInfo?.questionTypeId === 3 && (
            <FreeTextQuestion
              qData={qInfo}
              qDesc={qDescription}
              headersHeight={headersHeight}
              ftAnswer={[ftAnswer, setFtAnswer]}
              ftMobileThreshold={ASSESSMENT_FLOW_MOBILE_THRESHOLD}
              screenWidth={screenWidth}
            />
          )}
          {!timerLoading && qInfo?.questionTypeId === 2 && (
            <CodingQuestion
              qData={qInfo}
              qDesc={qDescription}
              headersHeight={headersHeight}
              codingAnswer={[codingAnswer, setCodingAnswer]}
              codingLanguage={[codeLanguage, setCodeLanguage]}
              codingMobileThreshold={ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}
              screenWidth={screenWidth}
              selectedIndex={[selectedLangIndex, setSelectedLangIndex]}
              resetToInitialCode={resetToInitialCode}
            />
          )}
        </>
      )}

      {/* <BottomLogo logoPath={mockImage} /> */}
    </div>
  );
};

export default QuestionPage;
