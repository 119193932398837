import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as Linkedin } from './linkedin.svg';
import {
  getCandidateProfileAction,
  newOpsCandidateCheckAction,
  unlockNewOpsCandidateAction
} from '../../../../redux/candidateDetail/actions';

import styles from './CandidateProfile.module.css';
import CustomButton from '../../../../Common/Components/CustomButton';
import {
  candidatePositionsSelector,
  candidateProfileSelector,
  isCandidateAuthorizedSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector,
  requestInfoBannerErrorFlagSelector,
  requestInfoBannerStateSelector,
  requestInfoBannerTextSelector,
  requestModalStateSelector,
  requestNumberExceedSelector
} from '../../../../redux/candidateDetail/selectors';
import TextOverflow from '../../../../Common/TextOverflow/TextOverflow';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import ShareProfileModal from '../ShareProfileModal/ShareProfileModal';
import anon from './anonymous.svg';
import lock from './lock.svg';
import lockedCand from './lockedCand.svg';
import Skeleton from '../../../../Common/Components/Skeleton';
import HeadsUpModal from '../../../../Auth/Modals/HeadsUpModal';
import ContactSalesSuccess from '../../../../Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import PurchaseAddOnSuccessModal from '../../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import FreeTrialModal from '../../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import { currentPlanRedux } from '../../../../redux/plan/selectors';
import { userSelector } from '../../../../redux/auth/selectors';
import { genericMixpanelCandidateDetailEventGenerator } from '../helpers';
import { ReactComponent as Error } from '../../../../images/errorSubmit.svg';
import RequestMail from '../../../../Common/RequestMail/RequestMail';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import {
  setRequestInfoBannerErrorFlag,
  setRequestInfoBannerState,
  setRequestInfoBannerText,
  setRequestModalState,
  setRequestNumberExceed
} from '../../../../redux/candidateDetail/slice';
import Tooltip from '../../../../Common/Components/Tooltip';
import RequestLimitedModal from '../../../../Auth/Modals/RequestLimitedModal/RequestLimitedModal';

import constants from '../../../../utils/constants';

const CandidateProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const profile = useSelector(candidateProfileSelector);
  const [screenW, screenH] = useWindowSize();
  const [profileLoading, setProfileLoading] = useState(true);

  const [locationWidth, setLocationWidth] = useState();
  const [emailWidth, setEmailWidth] = useState();
  const [nameWidth, setNameWidth] = useState();
  const [jobWidth, setJobWidth] = useState();
  const [shareShow, setShareShow] = useState(false);
  const [shareShow2, setShareShow2] = useState(false);

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [purchasedSuccess, setPurchasedSuccess] = useState(false);
  const myPlan = useSelector(currentPlanRedux);
  const newOpsCandidateCheck = useSelector(isCandidateAuthorizedSelector);
  const showRequestLimitedModal = useSelector(requestNumberExceedSelector);
  const [unlockLoading, setUnlockLoading] = useState(false);
  const [freeTrialLockedCandidate, setFreeTrialLockedCandidate] = useState(false);

  const [linkedinPath, setLinkedinPath] = useState();

  function getProfileData() {
    setProfileLoading(true);
    const candidate = {
      UserId: id,
      CompanyAssessmentId: getParam(location.search, 'defaultAssessmentId')
    };
    dispatch(getCandidateProfileAction(candidate)).then(() => {
      setProfileLoading(false);
    });
  }

  // Mixpanel Unlock Candidate - Button Click
  // #region
  const currentUser = useSelector(userSelector);
  const positionsList = useSelector(candidatePositionsSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);
  function mixpanelUnlockCandidateEvent() {
    genericMixpanelCandidateDetailEventGenerator(
      'Candidate Detail - Unlock Candidate Button Click',
      currentUser,
      positionsList,
      profile,
      loadingForPersonalInfoRedux,
      personalInfoExperienceRedux,
      personalInfoNationalityRedux,
      personalInfoSalaryRedux,
      personalInfoWorkTypeRedux
    );
  }
  // #endregion

  // Mixpanel Share Profile - Click
  // #region
  function mixpanelShareProfileEvent() {
    genericMixpanelCandidateDetailEventGenerator(
      'Share Profile - Click',
      currentUser,
      positionsList,
      profile,
      loadingForPersonalInfoRedux,
      personalInfoExperienceRedux,
      personalInfoNationalityRedux,
      personalInfoSalaryRedux,
      personalInfoWorkTypeRedux
    );
  }
  // #endregion

  async function handleUnlockNewOpsCandidate() {
    mixpanelUnlockCandidateEvent();
    // Covers free trial
    if (myPlan?.name === 'Free Trial') {
      setFreeTrialLockedCandidate(true);
    } else {
      // Checks if user is allowed to unlock this candidate
      await setUnlockLoading(true);
      await dispatch(
        newOpsCandidateCheckAction({
          UserId: id,
          CompanyAssessmentId: getParam(location.search, 'defaultAssessmentId')
        })
      ).then(async res => {
        if (!res.payload.result) {
          // Not allowed: Payment modal or contact sales modal shown to the user
          await setPaymentModal(true);
          setUnlockLoading(false);
        } else {
          // Allowed: unlocks candidate
          await dispatch(
            unlockNewOpsCandidateAction({
              userId: id,
              companyAssessmentId: getParam(location.search, 'defaultAssessmentId')
            })
          ).then(() => setUnlockLoading(false));
          // success
          location.reload();
          // getProfileData();
        }
      });
    }
  }

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    const wrapperE = document
      .getElementById('candidate-profile-wrapper')
      ?.getBoundingClientRect()?.width;
    const locationE = document
      .getElementById('candidate-detail-location')
      ?.getBoundingClientRect()?.width;
    const phoneE = document
      .getElementById('candidate-detail-phone')
      ?.getBoundingClientRect()?.width;
    const emailE = document
      .getElementById('candidate-detail-email')
      ?.getBoundingClientRect()?.width;

    const candidateTag = document.getElementById('profile-tag')?.getBoundingClientRect()?.width;
    const shareProfileWidth = document
      .getElementById('ShareProfileButton')
      ?.getBoundingClientRect()?.width;
    const lockIconWidth = document.getElementById('candidate-lock')?.getBoundingClientRect()?.width;
    const wrapperPadding = 25 + 25;
    const leftIcon = 84 + 25;
    const gap = 25;

    const locationIconWidth = 40;

    if (screenW <= 576) {
      // 0 - 576
      setNameWidth(
        wrapperE -
          wrapperPadding -
          (candidateTag ? candidateTag + 10 : 0) -
          (lockIconWidth ? lockIconWidth + 10 : 0)
      );
      setJobWidth(wrapperE - wrapperPadding);
      setLocationWidth(wrapperE - wrapperPadding - locationIconWidth);
      setEmailWidth(wrapperE - wrapperPadding - locationIconWidth);
    } else {
      // 577 - ...
      setNameWidth(
        wrapperE -
          wrapperPadding -
          gap -
          leftIcon -
          shareProfileWidth -
          (candidateTag ? candidateTag + 10 : 0) -
          (lockIconWidth ? lockIconWidth + 10 : 0)
      );
      setJobWidth(wrapperE - wrapperPadding - gap - leftIcon - shareProfileWidth);

      setLocationWidth(wrapperE - wrapperPadding - phoneE - emailE - 145);
      setEmailWidth(wrapperE - wrapperPadding - phoneE - locationE - 145);
    }
  }, [screenW, JSON.stringify(profile), profileLoading]);

  useEffect(() => {
    if (shareShow) {
      setShareShow2(true);
    } else {
      setTimeout(() => {
        setShareShow2(false);
      }, 300);
    }
  }, [shareShow]);

  useEffect(() => {
    if (profile) {
      // Linkedin Path update
      const linkedinpath = profile?.profileUrl;
      if (
        linkedinpath &&
        linkedinpath !== 'Null' &&
        linkedinpath !== 'NULL' &&
        linkedinpath.length > 0
      ) {
        if (linkedinpath.startsWith('https://www.linkedin')) {
          setLinkedinPath(linkedinpath);
        } else if (linkedinpath.startsWith('www.linkedin')) {
          let temp = '';
          temp = temp.concat('https://');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else if (linkedinpath.startsWith('linkedin')) {
          let temp = '';
          temp = temp.concat('https://www.');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else {
          let temp = '';
          temp = temp.concat('https://www.linkedin.com/in/');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        }
      } else {
        setLinkedinPath(null);
      }
    }
  }, [profile]);

  const [refreshFlag, setRefreshFlag] = useState(false);

  useEffect(() => {
    if (refreshFlag) {
      location.reload();
    }
  }, [refreshFlag]);

  /* Request popup related states */
  const requestModalStateRedux = useSelector(requestModalStateSelector);
  const requestInfoBannerStateRedux = useSelector(requestInfoBannerStateSelector);
  const requestInfoBannerTextRedux = useSelector(requestInfoBannerTextSelector);
  const requestInfoBannerErrorFlagRedux = useSelector(requestInfoBannerErrorFlagSelector);

  const isRequestCandidateFlag = profile?.status === constants.REQUEST_CANDIDATE_OBJECT_STATUS;
  const isNewopCandidateFlag = profile?.status === constants.NEWOP_LOCKED_CANDIDATE_OBJECT_STATUS;

  const isRequestLockedFlag =
    isRequestCandidateFlag &&
    (profile?.requestStatus !== constants.REQUEST_STATUS_ACCEPTED ||
      (profile?.requestStatus === constants.REQUEST_STATUS_ACCEPTED &&
        profile?.requestPayStatus === false));

  // Anonymous candidate logo or initials of the candidate for pp
  const nameInvalidFlag = !profile.fullName || profile?.fullName.trim() === '';
  const isLockedFlag = isRequestLockedFlag || isNewopCandidateFlag;

  /* In case of both 'Requested' and 'Already in your pool',
    the hieararchy is as follows:
    Accepted-Declined-Pending >  Already in your Pool > New
  */
  const alreadyInYourPoolConstant = 'Already in your pool';
  const isUnlockedCandidate = 'Unlocked';

  const statusText = profile?.isUnlocked
    ? isUnlockedCandidate
    : profile?.requestStatus === constants.REQUEST_STATUS_PENDING
    ? 'Pending'
    : profile?.requestStatus === constants.REQUEST_STATUS_ACCEPTED
    ? 'Accepted'
    : profile?.requestStatus === constants.REQUEST_STATUS_DECLINED
    ? 'Declined'
    : profile?.isAlreadyInYourPool
    ? alreadyInYourPoolConstant
    : profile?.requestStatus === constants.REQUEST_STATUS_NEW
    ? 'New'
    : null;

  const positionText = `${
    profile?.latestExperience && profile?.currentCompany ? `${profile?.latestExperience} at ` : ''
  }${profile?.currentCompany || '-'}`;

  // Wrapper for all Modals in Candidate Profile
  const Modals = (
    <>
      {/* START: Unlock candidate add on payment modals */}
      <HeadsUpModal
        addOnCode={40}
        show={paymentModal}
        onHide={() => setPaymentModal(false)}
        title={myPlan?.isCustom ? 'Hello there!' : 'Want to unlock?'}
        description={
          !myPlan?.isCustom
            ? `Unlock this candidate's profile for only ${
                newOpsCandidateCheck?.paymentCurrencyType === 10 ? '₺' : '$'
              } ${newOpsCandidateCheck?.totalPrice}.`
            : `Please contact our sales team to reach the candidate.`
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchasedSuccess}
        newOpData={{
          userId: id,
          companyAssessmentId: getParam(location.search, 'defaultAssessmentId')
        }}
      />

      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />

      {/* TO DO: refresh page */}
      <PurchaseAddOnSuccessModal
        show={purchasedSuccess}
        onHide={() => {
          setPurchasedSuccess(false);
          setRefreshFlag(true);
        }}
        addOnCode={40}
      />
      <FreeTrialModal
        show={freeTrialLockedCandidate}
        onHide={() => setFreeTrialLockedCandidate(false)}
        addOnCode={40}
      />
      {/* END: Unlock candidate add on payment modals */}

      {shareShow2 && (
        <ShareProfileModal show={shareShow} onCloseModal={() => setShareShow(false)} />
      )}

      <RequestMail
        show={requestModalStateRedux}
        onClose={() => {
          dispatch(setRequestModalState(false));
        }}
      />

      <InfoBanner
        show={requestInfoBannerStateRedux}
        text={requestInfoBannerTextRedux}
        CustomIcon={requestInfoBannerErrorFlagRedux ? Error : null}
        setStatus={() => {
          dispatch(setRequestInfoBannerState(false));
          dispatch(setRequestInfoBannerText());
          dispatch(setRequestInfoBannerErrorFlag(false));
        }}
        bannerStyle={{
          border: requestInfoBannerErrorFlagRedux
            ? '1px solid rgba(221, 58, 58, 0.1)'
            : '1px solid rgba(74, 195, 137, 0.1)'
        }}
      />
      <RequestLimitedModal
        show={showRequestLimitedModal}
        onHide={() => dispatch(setRequestNumberExceed(false))}
      />
    </>
  );

  // Candidate pp component
  const IconComponent = (
    <div
      style={{
        background: nameInvalidFlag || isLockedFlag ? '#F4F4F4' : ''
      }}
      className={styles.candidatePP}
    >
      {nameInvalidFlag || isLockedFlag ? (
        <img alt="anonymous-candidate" src={isLockedFlag ? lockedCand : anon} />
      ) : (
        `${profile?.fullName?.split(/\s/)[0]?.slice(0, 1)}${profile?.fullName
          ?.split(/\s/)
          [profile?.fullName?.split(/\s/)?.length - 1]?.slice(0, 1)}`
      )}
    </div>
  );

  const TagComponent = (
    <>
      {statusText && statusText !== alreadyInYourPoolConstant ? (
        <div id="profile-tag" className={styles.inPoolDiv}>
          {statusText}
        </div>
      ) : statusText === alreadyInYourPoolConstant ? (
        <Tooltip
          direction="right"
          text="You already have this candidate in one of previous job postings."
          fontSize="12px"
          fontFamilyForText1="Jost-300"
          maxWidth="165px"
        >
          <div id="profile-tag" className={styles.inPoolDiv}>
            {statusText}
          </div>
        </Tooltip>
      ) : null}
    </>
  );

  // Candidate name + tag for newOp locked candidates
  const LockedCandidateInfoRow = (
    <div className={styles.lockedInfo}>
      <TextOverflow
        itemId="lockedCandidate"
        text="Locked Candidate"
        textClassName={styles.lockedText}
        width={nameWidth}
      />
      <img id="candidate-lock" alt="candidate-lock" src={lock} />
      {TagComponent}
    </div>
  );

  // Candidate name + tag for request locked candidates
  const RequestCandidateInfoRow = (
    <div className={styles.lockedInfo}>
      <TextOverflow
        itemId="lockedCandidate"
        text="Coensio Candidate"
        textClassName={styles.lockedText}
        width={nameWidth}
      />
      <img id="candidate-lock" alt="candidate-lock" src={lock} />
      {TagComponent}
    </div>
  );

  // Web view - Candidate name row + unlock button for newOp locked candidates
  const WebUpperPartLockedCandidate = (
    <>
      {LockedCandidateInfoRow}
      <CustomButton
        textField={
          screenW < 600 || (screenW < 1005 && screenW > 960)
            ? 'Click to Unlock'
            : 'Click to Unlock Candidate'
        }
        type={1}
        size="small"
        loading={unlockLoading}
        customStyle={{ fontSize: '16px' }}
        buttonOnClick={() => handleUnlockNewOpsCandidate()}
      />
    </>
  );

  // Web view - Candidate name row + unlock button for request locked candidates
  const WebUpperPartRequestCandidate = (
    <>
      {RequestCandidateInfoRow}
      <CustomButton
        textField="Click to Send Request"
        type={1}
        size="small"
        loading={unlockLoading}
        customStyle={{ fontSize: '16px' }}
        buttonOnClick={() => dispatch(setRequestModalState(true))}
        isDisabled={profile?.requestStatus !== constants.REQUEST_STATUS_NEW}
        tooltipText="You've already sent a request to this candidate."
      />
    </>
  );

  // Web view - Candidate name row + job + linkedin for unlocked candidates
  const WebUpperPartUnlockedCandidate = (
    <>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '10px' }}>
        <TextOverflow
          itemId="candidate-detail-fullName"
          text={profile?.fullName && profile?.fullName !== ' ' ? profile?.fullName : profile?.email}
          textClassName={styles.candidateName}
          width={nameWidth}
        />

        {TagComponent}
      </div>

      <TextOverflow
        itemId="candidate-detail-job"
        text={positionText}
        textClassName={styles.candidateJob}
        textStyle={{
          fontSize: profile && !profile?.email && '25px',
          fontFamily: profile && !profile?.email && 'Jost-300'
        }}
        width={jobWidth}
      />

      {linkedinPath ? (
        <a
          style={{
            width: 'fit-content',
            cursor: 'pointer',
            opacity: '1'
          }}
          href={linkedinPath}
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin />
        </a>
      ) : (
        <div>&nbsp;</div>
      )}
    </>
  );

  // Web view - Candidate info wrapper
  const WebUpperPart = (
    <div className={styles.infoBoxWrapper}>
      {isNewopCandidateFlag
        ? WebUpperPartLockedCandidate
        : isRequestLockedFlag
        ? WebUpperPartRequestCandidate
        : WebUpperPartUnlockedCandidate}
    </div>
  );

  // Share profile wrapper
  const ShareComponent = (
    <div className={styles.buttonWrapper} id="ShareProfileButton">
      <CustomButton
        Icon={Share}
        customStyle={{
          width: screenW <= 576 || (screenW < 1150 && screenW > 960) ? '101px' : '151px'
        }}
        type={5}
        textField={screenW <= 576 || (screenW < 1150 && screenW > 960) ? 'Share' : 'Share Profile'}
        size="small"
        buttonOnClick={() => {
          mixpanelShareProfileEvent();
          setShareShow(true);
        }}
      />
    </div>
  );

  // Mobile view - Candidate name row + description + unlock button for newOp locked candidates
  const MobileUpperPartLockedCandidate = (
    <>
      {LockedCandidateInfoRow}

      <div className={styles.footerInfoWrapperBlurred}>
        This candidate applied through Coensio from other companies. Unlock the opportunity to meet
        your potential employee!
      </div>

      <CustomButton
        textField="Click to Unlock Candidate"
        type={1}
        size="small"
        customStyle={{ fontSize: '16px', width: '100%' }}
        buttonOnClick={() => handleUnlockNewOpsCandidate()}
      />
    </>
  );

  // Mobile view - Candidate name row + description + unlock button for requested locked candidates
  const MobileUpperPartRequestCandidate = (
    <>
      {RequestCandidateInfoRow}

      <div className={styles.footerInfoWrapperBlurred}>
        This candidate comes from Coensio&apos;s Qualified Candidate Pool. Click to send a request
        to the candidate.
      </div>
      <CustomButton
        textField="Click to Send Request"
        type={1}
        size="small"
        customStyle={{ fontSize: '16px', width: '100%' }}
        buttonOnClick={() => dispatch(setRequestModalState(true))}
        isDisabled={profile?.requestStatus !== constants.REQUEST_STATUS_NEW}
        tooltipText="You've already sent a request to this candidate."
      />
    </>
  );

  // Mobile view - Candidate name row + job + linkedin for unlocked candidates
  const MobileUpperPartUnlockedCandidate = (
    <>
      <div className={styles.lockedInfo}>
        <TextOverflow
          itemId="candidate-detail-fullName"
          text={profile?.fullName && profile?.fullName !== ' ' ? profile?.fullName : profile?.email}
          textClassName={styles.candidateName}
          width={nameWidth}
        />
        {TagComponent}
      </div>

      <TextOverflow
        itemId="candidate-detail-job"
        text={positionText}
        textClassName={styles.candidateJob}
        textStyle={{
          fontSize: profile && !profile?.email && '25px',
          fontFamily: profile && !profile?.email && 'Jost-300'
        }}
        width={jobWidth}
      />

      {linkedinPath ? (
        <a
          style={{
            width: 'fit-content',
            cursor: 'pointer',
            opacity: '1'
          }}
          href={linkedinPath}
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin />
        </a>
      ) : (
        <div>&nbsp;</div>
      )}
    </>
  );

  // Mobile view - Candidate info wrapper
  const MobileUpperPart = (
    <>
      <div className={styles.infoBoxWrapper}>
        {isNewopCandidateFlag
          ? MobileUpperPartLockedCandidate
          : isRequestLockedFlag
          ? MobileUpperPartRequestCandidate
          : MobileUpperPartUnlockedCandidate}
      </div>
    </>
  );

  // Web view - description for locked candidates
  const WebLowerPartLockedCandidate = (
    <div className={styles.footerInfoWrapperBlurred}>
      This candidate applied through Coensio from other companies. Unlock the opportunity to meet
      your potential employee!
    </div>
  );

  // Web view - description for requested candidates
  const WebLowerPartRequestedCandidate = (
    <div className={styles.footerInfoWrapperBlurred}>
      This candidate comes from Coensio&apos;s Qualified Candidate Pool. Click to send a request to
      the candidate.
    </div>
  );

  // Candidate contact information wrapper for unlocked candidates
  const LowerPartUnlockedCandidate = (
    <>
      <div className={styles.footerInfoWrapper}>
        <Location style={{ minWidth: '14px', minHeight: '14px' }} />
        <TextOverflow
          itemId="candidate-detail-location"
          text={profile?.location || '-'}
          textClassName={styles.infoText}
          textStyle={{
            minWidth: '30px',
            fontSize: !profile?.location && '25px',
            fontFamily: !profile?.location && 'Jost-300'
          }}
          width={locationWidth}
        />
      </div>
      <div className={styles.footerInfoWrapper}>
        <Phone style={{ minWidth: '14px', minHeight: '14px' }} />
        <div
          style={{
            fontSize: !profile?.phone && '25px',
            fontFamily: !profile?.phone && 'Jost-300'
          }}
          className={styles.infoText}
          id="candidate-detail-phone"
        >
          {profile?.phone || '-'}
        </div>
      </div>
      <div className={styles.footerInfoWrapper}>
        <Email style={{ minWidth: '14px', minHeight: '14px' }} />

        <TextOverflow
          itemId="candidate-detail-email"
          text={profile?.email || '-'}
          textClassName={styles.infoText}
          textStyle={{
            minWidth: '30px',
            fontSize: !profile?.email && '25px',
            fontFamily: !profile?.email && 'Jost-300'
          }}
          width={emailWidth}
        />
      </div>
    </>
  );

  // Web view - candidate contact information wrapper
  const WebLowerPart = (
    <div className={styles.footerWrapper}>
      {isNewopCandidateFlag
        ? WebLowerPartLockedCandidate
        : isRequestLockedFlag
        ? WebLowerPartRequestedCandidate
        : LowerPartUnlockedCandidate}
    </div>
  );

  // Mobil view - candidate contact information wrapper
  const MobilLowerPart = (
    <div
      className={
        isNewopCandidateFlag || isRequestLockedFlag
          ? styles.footerWrapperBlurredMobile
          : styles.footerWrapper
      }
    >
      {!isNewopCandidateFlag && !isRequestLockedFlag && LowerPartUnlockedCandidate}
    </div>
  );

  return (
    <>
      {profileLoading ? (
        <Skeleton type="candidate-profile" />
      ) : (
        <div className={styles.wrapper} id="candidate-profile-wrapper">
          {Modals}

          <div className={styles.contentWrapper} style={{ marginBottom: screenW <= 576 && '18px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {IconComponent}

              {screenW > 576 ? WebUpperPart : null}
            </div>

            {ShareComponent}
          </div>

          {screenW <= 576 && MobileUpperPart}

          {screenW > 576 ? WebLowerPart : MobilLowerPart}
        </div>
      )}
    </>
  );
};
export default CandidateProfile;
