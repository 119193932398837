import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShortText from '../../../../Common/Components/ShortText';
import BillingTabCard from './BillingTabCard';
import BillingRow from './BillingRow';
import { BillingContext } from './BillingPage';
import { currentPlanRedux, invoiceListRedux } from '../../../../redux/plan/selectors';
import { userSelector } from '../../../../redux/auth/selectors';
import { getInvoiceListAction } from '../../../../redux/plan/actions';
import { setEditBillingShow, setPaymentMethodModalShow } from '../../../../redux/plan/slice';
import edit from '../../../../images/billingEdit.svg';
import { ReactComponent as LeftLight } from '../../../Images/leftLight.svg';
import { ReactComponent as LeftBold } from '../../../Images/leftBold.svg';
import { ReactComponent as RightLight } from '../../../Images/rightLight.svg';
import { ReactComponent as RightBold } from '../../../Images/rightBold.svg';
import styles from './BillingTab.module.css';

function BillingTab() {
  const billingInfo = useContext(BillingContext);

  const dispatch = useDispatch();
  const [shortExpYear, setShortExpYear] = useState(billingInfo?.cardExpireDate);
  const [shortExpMonth, setShortExpMonth] = useState(billingInfo?.cardExpireDate);

  const currentPlan = useSelector(currentPlanRedux);
  const user = useSelector(userSelector);
  const invoices = useSelector(invoiceListRedux);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (billingInfo) {
      setShortExpYear(billingInfo?.cardExpireDate?.split('/')[1].slice(-2));
      setShortExpMonth(billingInfo?.cardExpireDate?.split('/')[0]);
    }
  }, [billingInfo]);

  async function getNewInvoicePage(page) {
    const invoiceData = {
      CompanyId: user?.companyId,
      PageSize: 15,
      CurrentPage: page
    };
    await dispatch(getInvoiceListAction(invoiceData));
  }

  useEffect(() => {
    getNewInvoicePage(pageIndex);
  }, [pageIndex]);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.container}>
        <div className={styles.header}>Billing Information</div>
        <div className={styles.billingInfoContainer}>
          <div className={styles.billedToContainer}>
            {billingInfo && (
              <div className={`${styles.infoWrapper} ${styles.billedTo}`}>
                <div className={styles.infoColumn}>
                  <div className={styles.titleRow} style={{ minHeight: '32px' }}>
                    <div className={styles.infoTitle}>BILLED TO</div>
                    <img
                      src={edit}
                      alt=""
                      className={styles.editIcon}
                      onClick={() => dispatch(setEditBillingShow(true))}
                    />
                  </div>
                </div>
                <div
                  className={styles.infoColumn}
                  offset={0}
                  span={22}
                  style={{ marginBottom: '8px' }}
                >
                  <div className={styles.userName}>{billingInfo?.nameSurname}</div>
                  <div className={styles.userEmail}>{billingInfo?.mail}</div>
                </div>
                <div className={styles.infoColumn} offset={0} span={22}>
                  <ShortText
                    name={billingInfo?.address}
                    textClassName={`${styles.placeInfo}`}
                    staticLimit={52}
                  />

                  <div className={`${styles.placeInfo}`}>
                    <span>{billingInfo?.city}&nbsp;</span>
                    <span>{billingInfo?.zipCode}</span>
                  </div>
                  <div className={`${styles.placeInfo}`} style={{ marginBottom: '18px' }}>
                    {billingInfo?.country}
                  </div>
                </div>
                <div className={styles.infoColumn}>
                  <div className={styles.placeInfo}>{billingInfo?.taxNo}</div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.paymentMethodContainer}>
            {currentPlan && billingInfo && (
              <div className={`${styles.infoWrapper} ${styles.paymentMethod}`}>
                <div className={styles.titleRow}>
                  <div className={styles.paymentTitle}>PAYMENT METHOD</div>
                  {currentPlan?.isCustom !== true && (
                    <img
                      src={edit}
                      alt=""
                      className={styles.editIcon}
                      onClick={() => dispatch(setPaymentMethodModalShow('change'))}
                    />
                  )}
                </div>
                <BillingTabCard
                  cardLastFourDigits={billingInfo && billingInfo.cardLastFourDigits}
                  type={billingInfo && billingInfo.cardAssociation}
                  expired={billingInfo && `${shortExpMonth}/${shortExpYear}`}
                  enterprise={currentPlan?.isCustom === true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!currentPlan?.isCustom && invoices?.invoiceList?.length > 0 && (
        <div className={styles.historyContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div className={styles.header}>Billing History</div>
            {invoices?.totalPageCount > 1 && (
              <div className={styles.paginationWrapper}>
                {pageIndex === 0 ? (
                  <LeftLight />
                ) : (
                  <LeftBold
                    className={styles.paginationArrows}
                    onClick={() => setPageIndex(pageIndex - 1)}
                  />
                )}
                {pageIndex === invoices.totalPageCount - 1 ? (
                  <RightLight />
                ) : (
                  <RightBold
                    className={styles.paginationArrows}
                    onClick={() => setPageIndex(pageIndex + 1)}
                  />
                )}
              </div>
            )}
          </div>
          <div>
            <div className={styles.historyWrapper}>
              <div className={styles.historyTitles}>
                <div
                  className={styles.titleColumn}
                  style={{ gridColumn: 1, gridRow: 1, gridColumnEnd: 1 }}
                >
                  Invoice
                </div>
                <div
                  className={styles.titleColumn}
                  style={{ gridColumn: 2, gridRow: 1, gridColumnEnd: 2 }}
                >
                  Billing Date
                </div>
                <div
                  className={styles.titleColumn}
                  style={{ gridColumn: 3, gridRow: 1, gridColumnEnd: 3 }}
                >
                  Amount
                </div>
                <div
                  className={styles.titleColumn}
                  style={{ gridColumn: 4, gridRow: 1, gridColumnEnd: 4, textAlign: 'center' }}
                />
              </div>
              <div className={styles.listWrapper}>
                {invoices?.invoiceList?.map((e, i) => {
                  return (
                    <BillingRow
                      index={i}
                      invoiceNo={e.invoiceNumber}
                      date={e.billingDate}
                      amount={e.amount}
                      currency={e.paymentCurrencyType}
                      id={e.id}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BillingTab;
