import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
  setLoginAction,
  loginVol2Action,
  changeCompanyAction,
  getCompanyProfileAction,
  updateCompanyProfileAction,
  getTeamAction,
  getCurrentUserDetailAction,
  getUpgradePricesAction
} from './actions';
import { getDomainProtocol, getOnlyDomain } from '../../utils/helpers';

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: () => ({
      ...initialState
    }),
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setReasonNull: (state, action) => {
      state.reason = null;
    },
    setErrorInTeam: (state, action) => {
      state.errorInTeam = action.payload;
    },
    setErrorMessageInTeam: (state, action) => {
      state.errorMessageInTeam = action.payload;
    },
    setSuccessInTeam: (state, action) => {
      state.successInTeam = action.payload;
    },
    setSuccessMessageInTeam: (state, action) => {
      state.successMessageInTeam = action.payload;
    },
    setSettingsPageErrorMessage: (state, action) => {
      state.settingsPageErrorMessage = action.payload;
    },
    setSettingsPageErrorShow: (state, action) => {
      state.settingsPageErrorShow = action.payload;
    },
    setSettingsPageSuccessMessage: (state, action) => {
      state.settingsPageSuccessMessage = action.payload;
    },
    setSettingsPageSuccessShow: (state, action) => {
      state.settingsPageSuccessShow = action.payload;
    },
    clearUserData: (state, action) => {
      state.user = null;
    },
    resetLoginPageErrorFlag: (state, action) => {
      state.loginPageErrorFlag = null;
    },
    resetLoginPageErrorMessage: (state, action) => {
      state.loginPageErrorMessage = null;
    },

    setLocationData: (state, action) => {
      state.locationData= action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loginVol2Action.pending, state => {
      state.loading = true;
      state.loginPageErrorFlag = null;
      state.loginPageErrorMessage = null;
    });
    builder.addCase(loginVol2Action.fulfilled, (state, action) => {
      state.token2 = action.payload.accessToken;
      state.token = action.payload.accessTokenV1;
      localStorage.setItem('authToken', action.payload.accessTokenV1);
      localStorage.setItem('authTokenVol2', action.payload.accessToken);
      if (action.payload.accessTokenV1 && action.payload.accessToken) {
        localStorage.removeItem('user');
        const redirectLink = localStorage.getItem('redirect');
        if (redirectLink) {
          localStorage.removeItem('redirect');
          window.location.href = redirectLink;
        } else window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/assessments`;
      }
      state.loginPageErrorFlag = false;
      state.loginPageErrorMessage = null;
    });
    builder.addCase(loginVol2Action.rejected, (state, action) => {
      if (action.payload.typeCode === 401) {
        state.loginPageErrorMessage = 'Email or password is incorrect!';
      } else {
        state.loginPageErrorMessage = 'An error occurred during your operation. Please try again!';
      }
      state.loginPageErrorFlag = true;
      state.loading = false;
    });
    builder.addCase(getCurrentUserDetailAction.fulfilled, (state, action) => {
      let userModel = action.payload;
      if (Object.keys(userModel).length !== 0) {
        userModel = { ...action.payload, version: 3 };
      }
      state.user = userModel;
      localStorage.setItem('user', JSON.stringify(userModel));
    });

    builder.addCase(getCurrentUserDetailAction.pending, (state, action) => {
      state.user = null;
      localStorage.removeItem('user');
    });

    builder.addCase(setLoginAction.fulfilled, (state, action) => {
      state.isLogged = true;
      state.loading = false;
    });
    builder.addCase(changeCompanyAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(changeCompanyAction.fulfilled, (state, action) => {
      state.loading = false;
      localStorage.setItem('authToken', action.payload.accessTokenV1);
      localStorage.setItem('authTokenVol2', action.payload.accessToken);
    });
    builder.addCase(getCompanyProfileAction.fulfilled, (state, action) => {
      state.companyInfo = action.payload.data;
    });
    builder.addCase(updateCompanyProfileAction.fulfilled, (state, action) => {
      state.companyInfo = action.payload.data;
    });
    builder.addCase(getTeamAction.fulfilled, (state, action) => {
      state.team = action.payload.data;
    });
    builder.addCase(getUpgradePricesAction.fulfilled, (state, action) => {
      state.upgradePrices = action.payload.data;
    });
  }
});

export const {
  logoutUser,
  setLoading,
  setReasonNull,
  setErrorInTeam,
  setErrorMessageInTeam,
  setSuccessInTeam,
  setSuccessMessageInTeam,
  setSettingsPageErrorMessage,
  setSettingsPageErrorShow,
  setSettingsPageSuccessMessage,
  setSettingsPageSuccessShow,
  clearUserData,
  resetLoginPageErrorFlag,
  resetLoginPageErrorMessage,
  setLocationData
} = slice.actions;

export default slice.reducer;
