import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import styles from '../Styles/Banner.module.css';
import CloseButton from './CloseButton';
import { ReactComponent as Arrow } from '../../images/banner_icon.svg';
import { currentPlanRedux } from '../../redux/plan/selectors';
import ContactSalesSuccess from '../../Auth/Modals/UnsubscribeSuccessModal';
import { userSelector } from '../../redux/auth/selectors';
import { contactSalesAction } from '../../redux/plan/actions';
import { setPaymentMethodModalShow, setShowPackageDetail } from '../../redux/plan/slice';
import CustomButton from './CustomButton';

const Text = styled.div`
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: ${isMobile ? '16px' : '18px'};
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-left: ${isMobile ? '10px' : '40px'};
  color: #ffffff;
`;

const TextMobile = styled.div`
  font-family: 'Jost-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-overflow: ellipsis;
`;

function Banner({ setShow }) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const currentPlan = useSelector(currentPlanRedux);
  const [contactSalesModalShow, setContactSalesModalShow] = useState(false);
  const handleClick = event => {
    sessionStorage.setItem('isBannerClosed', true);
    setShow(false);
  };

  const handleSales = async event => {
    const rawData = {
      userId: user?.userId,
      bundleItemCode: 20
    };
    const resp = await dispatch(contactSalesAction(rawData));
    if (resp.payload) {
      await setContactSalesModalShow(true);
    }
  };

  function handleMobileButtonClick() {
    if (currentPlan?.isCustom) {
      handleSales();
    } else if (currentPlan?.name === 'Free Trial') {
      dispatch(setShowPackageDetail(true));
    } else {
      dispatch(setPaymentMethodModalShow('change'));
    }
  }

  return (
    <>
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesModalShow}
        onHide={() => setContactSalesModalShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />
      {!isMobile ? (
        <div className={styles.container}>
          {currentPlan?.isCustom ? (
            <Text>
              Your candidate slots are full. Please contact our sales team to upgrade your plan to
              see all candidates.
            </Text>
          ) : currentPlan?.name === 'Free Trial' ? (
            <Text>Your candidate slots are full. Please subscribe to see all candidates.</Text>
          ) : (
            <Text>
              Your candidate slots are full. Please change your payment method to see all
              candidates.
            </Text>
          )}
          {currentPlan?.isCustom ? (
            <div className={styles.bannerCell}>
              <Arrow />
              <div className={styles.button} onClick={handleSales}>
                Contact Sales
              </div>
              <CloseButton onClick={handleClick} size="small" style={{ marginRight: 8 }} />
            </div>
          ) : currentPlan?.name === 'Free Trial' ? (
            <div className={styles.bannerCell}>
              <Arrow />
              <div className={styles.button} onClick={() => dispatch(setShowPackageDetail(true))}>
                Subscribe now
              </div>
              <CloseButton onClick={handleClick} size="small" style={{ marginRight: 8 }} />
            </div>
          ) : (
            <div className={styles.bannerCell}>
              <Arrow />
              <div
                className={styles.button}
                onClick={() => dispatch(setPaymentMethodModalShow('change'))}
              >
                Change your payment method
              </div>
              <CloseButton onClick={handleClick} size="small" style={{ marginRight: 8 }} />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.mobileContainer}>
          <div className={styles.headerRowMobile}>
            <span className={styles.slotFullText}>Your candidate slots are full.</span>
            <CloseButton onClick={handleClick} size="small" />
          </div>

          <div style={{ marginBottom: '16px' }}>
            {currentPlan?.isCustom ? (
              <TextMobile>
                Please contact our sales team to upgrade your plan to see all candidates.
              </TextMobile>
            ) : currentPlan?.name === 'Free Trial' ? (
              <TextMobile>Please subscribe to see all candidates.</TextMobile>
            ) : (
              <TextMobile>Please change your payment method to see all candidates.</TextMobile>
            )}
          </div>

          <CustomButton
            type={3}
            customStyle={{ width: '100%' }}
            textField={
              currentPlan?.isCustom
                ? 'Contact Sales'
                : currentPlan?.name === 'Free Trial'
                ? 'See Plans'
                : 'Change your payment method'
            }
            size="small"
            buttonOnClick={() => handleMobileButtonClick()}
          />
        </div>
      )}
    </>
  );
}

export default Banner;
