import client from '../../constant/indexVol2';
import API from '../../constant/apiVol2';

export const getAssessmentListServiceVol2 = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyAssessmentList, { params: data }).then(resolve).catch(reject);
  });

export const getCompanyAssessmentFilterCountService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyAssessmentFilterCount, { params: data }).then(resolve).catch(reject);
  });
