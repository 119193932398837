import React from 'react';
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren
} from 'react-circular-progressbar';

export default function MultipleCircularProgressBar({ score, avg }) {
  return (
    <CircularProgressbarWithChildren
      value={score}
      strokeWidth={5}
      styles={buildStyles({
        pathColor:
          Math.round(score) >= avg
            ? 'rgba(110, 207, 161, 0.8)'
            : Math.round(score) >= avg * 0.8
            ? 'rgba(255, 214, 0, 0.6)'
            : 'rgba(255, 126, 98, 0.8)',
        trailColor: 'rgba(244, 244, 244, 0.6)',
        strokeLinecap: 'round'
      })}
    >
      {/*
          Width here needs to be (100 - 2 * strokeWidth)%
          in order to fit exactly inside the outer progressbar.
        */}
      <div style={{ width: '70%' }}>
        <CircularProgressbar
          value={avg}
          styles={buildStyles({
            trailColor: 'rgba(244, 244, 244, 0.6)',
            pathColor: 'rgba(88, 88, 88, 0.6)',
            strokeLinecap: 'round'
          })}
        />
      </div>
    </CircularProgressbarWithChildren>
  );
}
