import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../../Common/CustomModal/Modal';
import styles from '../Styles/CreateAssessmentPopup.module.css';
import { ReactComponent as Tick } from './rightCornerTick.svg';
import { setPopupFlowEnded, setQuickStartOpen } from '../../../redux/plan/slice';

const CreateAssessmentPopup = ({
  popupRef,
  imageSrc,
  title,
  text,
  relativeElementId,
  xCenter,
  yCenter,
  yConstant = 0, // to arrange y position of the modal with a constant(give margin etc.)
  xConstant = 0,
  show,
  setShow,
  givenId,
  popupId
}) => {
  const dispatch = useDispatch();
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  function useRelativeElementPosition() {
    const [position, setPosition] = useState([0, 0]);

    if (relativeElementId) {
      useLayoutEffect(() => {
        function updatePosition() {
          setPosition([
            document.getElementById(relativeElementId)?.getBoundingClientRect()?.top - yConstant,
            document.getElementById(relativeElementId)?.getBoundingClientRect()?.left - xConstant
          ]);
        }
        updatePosition();
        window.addEventListener('DOMContentLoaded', updatePosition);
        window.addEventListener('resize', updatePosition);
        return () => {
          window.removeEventListener('resize', updatePosition);
          window.removeEventListener('DOMContentLoaded', updatePosition);
        };
      }, [yConstant, xConstant]);
    }
    return position;
  }
  const [top, left] = useRelativeElementPosition();

  useEffect(() => {
    setXPosition(left);
    setYPosition(top);
  }, [top, left]);

  const handleOnClose = () => {
    setShow(false);
    if (title === 'Start fast recruitment') {
      dispatch(setPopupFlowEnded(true));
    }
  };
  return (
    <div>
      <Modal
        backdropRef={popupRef}
        backdropStyle={{ backdropFilter: 'blur(2px)' }}
        contentStyle={{ borderRadius: '12px' }}
        onClose={handleOnClose}
        size={{ width: '54.8rem' }}
        bodyStyle={!xCenter && { width: 'fit-content' }}
        position={{ x: `calc(${xPosition}px - 54.8rem)`, y: `${yPosition}px` }}
        centerHorizontally={xCenter}
        centerVertically={yCenter}
        show={show}
        outsideClickClose={title !== 'Reach out more candidates'}
        backdropId={givenId}
        modalId={popupId}
      >
        <div className={styles.popup}>
          <div className={styles.contentWrapper}>
            <img className={styles.image} src={imageSrc} alt="" />
            <div className={styles.textWrapper}>
              <label className={styles.title}>{title}</label>
              <label className={styles.text}>{text}</label>
            </div>
          </div>
          <Tick className={styles.tick} onClick={handleOnClose} />
        </div>
      </Modal>
    </div>
  );
};

export default CreateAssessmentPopup;
