import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../Styles/QuestionBasedAssessmentBody.module.css';
import QuestionBasedLeftColumn from './QuestionBasedLeftColumn';
import { viewHeight } from '../../../redux/user/selectors';

export default function QuestionBasedAssessmentBody() {
  const height = useSelector(viewHeight);
  return (
    <div className={styles.contentBody} style={{ height: `calc(100vh - ${height}px)` }}>
      <div className={styles.pageWrapper}>
        <QuestionBasedLeftColumn />
      </div>
    </div>
  );
}
