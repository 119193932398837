import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader, Oval } from 'react-preloader-icon';
import {
  removeSkillBasedQuestionAction,
  addSkillBasedQuestionAction,
  addSkillBasedCodingQuestionAction
} from '../../../redux/assessmentsVol2/actions';
import Tooltip from '../../../Common/Components/Tooltip';
import plus from '../../../images/plusIcon2.svg';
import disabledPlus from '../Images/disabledPlus.svg';
import minus from '../../../images/minusIcon.svg';
import styles from '../Styles/QuestionList.module.css';
import { customAssessmentId } from '../../../redux/assessmentsVol2/selectors';
import {
  setQuestionBeingAdded,
} from '../../../redux/assessmentsVol2/slice';
import { ReactComponent as WorldIcon } from '../../../images/world.svg';

export default function SkillBasedQuestion({
  qType,
  skills,
  copy,
  onClick,
  isLast,
  id,
  qCategory,
  maxQuestionCount,
  isEmpty,
  difficulty,
  language
}) {
  const rootID = useSelector(customAssessmentId);
  let listlen = 0;
  const url = window.location.href;
  const lastSegment = url.split('/').pop();

  function getListLength() {
    if (typeof skills === 'object') {
      listlen = skills.length;
    }
    return listlen;
  }

  const [changeInQuestion, setChangeInQuestion] = useState(false);
  const [addInQuestion, setAddInQuestion] = useState(false);

  const [typeStr, setTypeStr] = useState();
  const [typeStr2, setTypeStr2] = useState();
  const handleTypeConversion = () => {
    if (qType === 2) {
      setTypeStr('coding');
      setTypeStr2('Coding');
    } else if (qType === 1) {
      setTypeStr('multiple select');
      setTypeStr2('Multiple Select');
    } else if (qType === 3) {
      setTypeStr('free-text');
      setTypeStr2('Free-Text');
    }
  };

  useEffect(() => {
    handleTypeConversion();
  }, []);

  const dispatch = useDispatch();

  const handleRemoveQuestion = async () => {
    setChangeInQuestion(true);
    const data = {
      rootAssessmentId: rootID,
      questionId: id[id.length-1]
    };
    const a = await dispatch(removeSkillBasedQuestionAction(data));
    if (a.meta.requestStatus === "fulfilled") {
      id.pop();
    } 
    setChangeInQuestion(false);
  };

  const handleAddQuestion = async () => {
    setAddInQuestion(true);
    if (qType === 2) {
      const data = {
        rootAssessmentId: rootID,
        languageList: skills,
        difficulty,
        questionLanguage: language
      };
      const a = await dispatch(addSkillBasedCodingQuestionAction(data));
      if (a) {
        setAddInQuestion(false);
      }
    } else {
      const data = {
        rootAssessmentId: rootID,
        questionTypeId: qType,
        categoryLabels: [
          {
            category: qCategory,
            labels: skills
          }
        ],
        questionLanguage: language,
        difficulty
      };
      const a = await dispatch(addSkillBasedQuestionAction(data));
      if (a) {
        setAddInQuestion(false);
      }
    }
    setQuestionBeingAdded(null);
  };

  return (
    <div className={styles.qLine} onClick={onClick}>
      <div className={styles.skills}>
        <div className={styles.lineWrapper}>
          <div
            className={`${styles.verticalLine} ${
              qType === 2 ? styles.codingQ : qType === 1 ? styles.multiselectQ : styles.freetextQ
            }`}
            style={{ height: isLast ? '50%' : '100%' }}
          >
            <div
              className={`${styles.horizontalLine} ${
                qType === 2
                  ? styles.codingQH
                  : qType === 1
                  ? styles.multiselectQH
                  : styles.freetextQH
              }`}
              style={{ height: !isLast ? '50%' : '100%' }}
            />
          </div>
        </div>

        {!isEmpty ? (
          <div className={styles.skillText}>
            {skills &&
              typeof skills === 'object' &&
              skills?.slice(0, 7).map((skill, index) => (
                <span>
                  {skill.trim(' ')}
                  {index !== getListLength() - 1 && ','}&nbsp;&nbsp;
                </span>
              ))}
            {skills?.length >= 8 && (
              <Tooltip
                direction="right"
                text={
                  <ul className={styles.skillList}>
                    {skills?.slice(7, skills?.length).map(x => (
                      <li className={styles.skillElement}>
                        <span>{x}</span>
                      </li>
                    ))}
                  </ul>
                }
              >
                {/* <div 
                className={`${styles.skillWrapper} ${styles.more}`}>{`+${
                  skills?.length - 3
                }`}</div> */}
                <div className={`${styles.skillWrapper} ${styles.more}`}>...</div>
              </Tooltip>
            )}
            {skills && typeof skills === 'string' && <span>{skills}</span>}
            {language !== 'EN' && (
              <div className={styles.languageLabel}>
                <WorldIcon className={styles.worldIcon} />
                <div className={styles.questionLanguage}>{`${language}`}</div>
              </div>
            )}
            <div className={styles.questionDifficulty}>{`${difficulty}`}</div>
          </div>
        ) : (
          <div className={styles.noQWrapper}>
            <span className={styles.noQText}>There is no question.</span>
            <span
              className={styles.noQAddText}
              onClick={() => dispatch(setQuestionBeingAdded(typeStr2))}
            >
              Add first {typeStr} question
            </span>
          </div>
        )}
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.countSingle}>{copy}</div>
        <div className={styles.buttonsWrapper}>
          {!isEmpty && (
            <span
              className={`${!changeInQuestion && styles.minusWrapper} ${
                changeInQuestion && styles.loading
              }`}
              style={{ cursor: 'pointer', gridColumnStart: 1, width: '100%', height: '100%' }}
              onClick={() => {
                if (!changeInQuestion) {
                  handleRemoveQuestion();
                }
              }}
            >
              {!changeInQuestion && (
                <img style={{ width: '100%', height: '100%' }} alt="" src={minus} />
              )}
              {changeInQuestion && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center'
                  }}
                >
                  <Preloader
                    use={Oval}
                    size={16}
                    strokeWidth={17}
                    strokeColor="#7458D3"
                    duration={1200}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center'
                      // paddingBottom: '4px'
                    }}
                  />
                </div>
              )}
            </span>
          )}

          {maxQuestionCount > 0 && !isEmpty ? (
            <span
              style={{
                cursor: 'pointer',
                gridColumnStart: 2,
                width: '100%',
                alignSelf: 'flex-end',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
              className={`${!addInQuestion && styles.plusWrapper} ${
                addInQuestion && styles.loading
              }`}
              onClick={() => {
                if (!addInQuestion) {
                  dispatch(setQuestionBeingAdded(qType));
                  handleAddQuestion();
                }
              }}
            >
              {!addInQuestion && (
                <img style={{ width: '100%', height: '100%' }} alt="" src={plus} />
              )}
              {addInQuestion && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center'
                  }}
                >
                  <Preloader
                    use={Oval}
                    size={16}
                    strokeWidth={17}
                    strokeColor="#7458D3"
                    duration={1200}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center',
                      paddingBottom: '6px'
                    }}
                  />
                </div>
              )}
            </span>
          ) : (
            !isEmpty && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Tooltip
                  position="top"
                  text="No more question is available for this category"
                  font="16px"
                >
                  <span
                    style={{
                      cursor: 'pointer',
                      gridColumnStart: 2,
                      alignSelf: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      justifySelf: 'center'
                    }}
                  >
                    <img alt="" src={disabledPlus} style={{ height: '13px', width: '13px' }} />
                  </span>
                </Tooltip>{' '}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
