import { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";

import { conf, language } from 'monaco-editor/esm/vs/basic-languages/typescript/typescript';
import { MonacoLanguageClient, MonacoServices } from 'monaco-languageclient';
import { StandaloneServices } from 'vscode/services';
// import getNotificationServiceOverride from 'vscode/service-override/notifications';
import getDialogServiceOverride from 'vscode/service-override/dialogs';
import { toSocket, WebSocketMessageReader, WebSocketMessageWriter } from 'vscode-ws-jsonrpc';
import { CloseAction, ErrorAction, MessageTransports } from 'vscode-languageclient';

loader.config({ monaco });

console.error = function () { }

StandaloneServices.initialize({
  // ...getNotificationServiceOverride(document.body),
  ...getDialogServiceOverride()
});

function getInitializationOptionsForLanguage(language) {
  if (language === "ts") {
    return {
      initializationOptions: {
        disableAutomaticTypingAcquisition: true,
        preferences: {
          includeInlayParameterNameHints: 'none',
          includeInlayParameterNameHintsWhenArgumentMatchesName: false,
          includeInlayFunctionParameterTypeHints: false,
          includeInlayVariableTypeHints: true,
          includeInlayVariableTypeHintsWhenTypeMatchesName: false,
          includeInlayPropertyDeclarationTypeHints: false,
          includeInlayFunctionLikeReturnTypeHints: false,
          includeInlayEnumMemberValueHints: false,
        }
      },
    };
  }
  if (language === "java") {
    return {
      settings: [
        {
          java: {
            autobuild: false,
            gradle: {
              offline: true,
            },
            completion: {
              enabled: false
            }
          }
        }
      ]
    }
  }
  return null;
}

function createLanguageClient(transports, documentSelector) {
  const clientOptions = {
    // rootPath: "/tmp",
    // rootUri: "file:///tmp",
    trace: "verbose",
    documentSelector: [documentSelector],
    // disable the default error handler
    errorHandler: {
      error: err => {
        // console.log("LANGUAGE CLIENT ERROR HANDLER:::::", err);
        // return { action: ErrorAction.Shutdown };
        return { action: ErrorAction.Shutdown };
      },
      closed: () => {
        console.log("CLOSED");
        return { action: CloseAction.DoNotRestart };
      }
    }
  };
  if (documentSelector !== "cs") {
    clientOptions.workspaceFolders = ["/tmp"];
  }
  const initializationOptions = getInitializationOptionsForLanguage(documentSelector);
  if (initializationOptions) {
    clientOptions.initializationOptions = initializationOptions;
  }
  return new MonacoLanguageClient({
    name: 'Coensio Language Client',
    clientOptions: clientOptions,
    // create a language client connection from the JSON RPC connection on demand
    connectionProvider: {
      get: () => {
        console.log("__TRACE CONNECTION PROVIDER CALLED")
        return Promise.resolve(transports);
      }
    }
  });
}

function createUrl(hostname, port, path) {
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  // return normalizeUrl(`${protocol}://${hostname}:${port}${path}`);
  if (port === 80) {
    return `${protocol}://${hostname}${path}`;
  }
  return `${protocol}://${hostname}:${port}${path}`;
}

let previousLangClient = null;

export const connectToServerByType = lang => {
  console.log(`connectToServerByType(${lang})`);
  if (lang === "js") {
    lang = "ts";
  }
  const url = createUrl(window.location.hostname, 80, `/lsp/${lang}`);
  // const url = createUrl('18.184.207.179', 4000, `/${lang}`);
  if (lang === "py") {
    lang = 'python';
  }
  // const url = createUrl('test.cenanozen.com', 80, `/${lang}`);
  const webSocket = new WebSocket(url);
  webSocket.onopen = () => {
    const socket = toSocket(webSocket);
    const reader = new WebSocketMessageReader(socket);
    const writer = new WebSocketMessageWriter(socket);
    // if (previousLangClient !== null) {
    //   try {
    //     previousLangClient.stop();
    //     previousLangClient.dispose();
    //   } catch (e) {
    //     console.log("Error stopping previous language client", e);
    //   }
    // }
    if (lang === "cs") {
      lang = "csharp";
    }
    const languageClient = createLanguageClient({
      reader,
      writer
    }, lang);
    previousLangClient = languageClient;
    languageClient.start();
    reader.onClose(() => languageClient.stop());
  };
};

export function initializeMonacoLanguages(monaco) {
  monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: true,
    noSyntaxValidation: true,
  });
  monaco.languages.register({
    id: 'ts',
    extensions: ['.ts', '.tsx'],
    aliases: ['typescript'],
    mimetypes: ['text/plain'],
  }, {
    id: 'js',
    extensions: ['.js', '.jsx'],
    aliases: ['javascript'],
    mimetypes: ['text/plain'],
  }, {
    id: 'kotlin',
    extensions: ['.kt'],
    aliases: ['Kotlin', 'kotlin'],
    mimetypes: ['text/x-kotlin-source', 'text/x-kotlin', 'text/plain'],
  }, {
    id: 'sql',
    extensions: ['.sql'],
    aliases: [],
    mimetypes: ['text/plain'],
  }, {
    id: 'java',
    extensions: ['.java'],
    aliases: [],
    mimetypes: ['text/plain'],
  }, {
    id: 'go',
    extensions: ['.go'],
    aliases: ['go'],
    mimetypes: ['text/plain'],
  }, {
    id: 'py',
    extensions: ['.py'],
    aliases: ['python'],
    mimetypes: ['text/plain'],
  }, {
    id: 'cs',
    extensions: ['.cs'],
    aliases: ['csharp'],
    mimetypes: ['text/plain'],
  });

  monaco.languages.setMonarchTokensProvider('ts', language);
  monaco.languages.setLanguageConfiguration('ts', conf);

  // monaco.editor.createModel("", "ts", monaco.Uri.parse("file.ts"))
  MonacoServices.install();
}

export function getFilePathFromLanguage(language) {
  if (language === "kotlin") {
    return "file:///ws/file.kt";
  }
  if (language === "java") {
    return "file:///jdt/workspace/src/main/java/io/coensio/lsp/file.java";
  }
  if (language === "cs") {
    return "/csws/file.cs";
  }
  if (language === "js") {
    return "file:///tmp/file.ts";
  }
  return `file:///tmp/file.${language}`;
}

