export default {
  candidateProfile: {},
  candidatePositions: [],
  defaultAssessmentFunnelList: [],
  isLockedPositions: null,
  positionRequestStatus: null,
  positionRequestPayStatus: null,

  infoBannerText: null,
  infoBannerShow: null,
  isCandidateAuthorized: false,
  showRejectionPopupFlag: null,
  showRejectionSuccessFlag: null,
  isRejectionMailSent: null,

  notes: [],

  candidateSolutions: null,
  solutionAuthorize: null,
  priceForUnlock: null,
  loadingForSolutions: null,

  candidateBackground: {},
  candidateCvPath: '',

  currentAssessmentFunnelList: [],
  assessmentSummaryPositionRequestStatus: null,
  assessmentSummaryPositionRequestPayStatus: null,

  loadingForPersonalInfo: null,
  personalInfoExperience: null,
  personalInfoNationality: null,
  personalInfoSalary: null,
  personalInfoWorkType: null,
  candidateActivities: null,

  showChangeFunnelPopup: null,
  currentAssessmentInfo: null,

  /* Request Mail related states */
  requestModalState: false,
  requestInfoBannerState: false,
  requestInfoBannerText: null,
  requestInfoBannerErrorFlag: false,
  requestNumberExceed: false
  /* ---------------------- */
};
