import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import styles from './Notes.module.css';
import dots from './dots.svg';
import editNote from './editNote.svg';
import deleteNote from './deleteNote.svg';
import ShortText from '../../../../Common/Components/ShortText';
import CustomButton from '../../../../Common/Components/CustomButton';
import { updateNoteAction, deleteNoteAction } from '../../../../redux/candidateDetail/actions';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import { userSelector } from '../../../../redux/auth/selectors';

const Note = ({ note, setNoteBeingEdited, index }) => {
  const { id } = useParams();
  const user = useSelector(userSelector);
  const [screenW, screenH] = useWindowSize();
  const modalRef = useRef();
  const dotsRef = useRef();
  const buttonRef = useRef();
  const button2Ref = useRef();
  const editAreaRef = useRef();

  const dispatch = useDispatch();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentNote, setCurrentNote] = useState({ note: note.note });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topPosition, setTopPosition] = useState();
  const [rightPosition, setRightPosition] = useState();

  function cancelWriting() {
    setCurrentNote({ note: note.note });
    setEditMode(false);
  }

  async function editNoteAction() {
    await setLoading(true);
    const data = {
      noteId: note.noteId,
      note: currentNote.note,
      CompanyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };
    await dispatch(updateNoteAction(data));
    await setLoading(false);
    await setEditMode(false);
  }

  function deleteNoteMethod() {
    const data = {
      noteId: note.noteId,
      CompanyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };
    dispatch(deleteNoteAction(data));
    setEditModalOpen(false);
  }

  function handleOutsideClick(event) {
    if (
      modalRef &&
      dotsRef &&
      !modalRef?.current?.contains(event?.target) &&
      !dotsRef?.current?.contains(event?.target)
    ) {
      setEditModalOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [editModalOpen]);

  useEffect(() => {
    const dotsElement = document.getElementById(`dots-in-notes-${index}`)?.getBoundingClientRect();
    const page = document.getElementById('candidate-detail-page-wrapper');
    const noteWrapper = document.getElementById('notes-wrapper');
    setTopPosition(dotsElement?.top);
    setRightPosition(dotsElement?.left - 60);

    page?.addEventListener('scroll', e => {
      setEditModalOpen(false);
    });
    noteWrapper?.addEventListener('scroll', e => {
      setEditModalOpen(false);
    });

    return () => {
      page?.removeEventListener('scroll', e => {
        setEditModalOpen(false);
      });
      noteWrapper?.removeEventListener('scroll', e => {
        setEditModalOpen(false);
      });
    };
  });

  function handleOutsideEditClick(event) {
    if (
      editAreaRef &&
      !editAreaRef?.current?.contains(event?.target) &&
      editMode &&
      button2Ref &&
      buttonRef &&
      !buttonRef?.current?.contains(event?.target) &&
      !button2Ref?.current?.contains(event?.target)
    ) {
      cancelWriting();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideEditClick);
    return () => {
      document.removeEventListener('click', handleOutsideEditClick);
    };
  }, [editMode]);

  return (
    <div className={styles.noteWrapper}>
      <div className={styles.firstRow}>
        <div className={styles.firstRowLeft}>
          <span className={styles.authorNote}>{note?.userNameSurname}</span>
          {screenW > 576 && (
            <span className={styles.dateNote}>
              {format(new Date(note?.addDate), 'dd MMM, yyyy')}
            </span>
          )}
        </div>
        {note?.senderId === user?.userId && (
          <img
            ref={dotsRef}
            className={`${styles.dots} ${editModalOpen && styles.deactiveDots}`}
            src={dots}
            alt="edit-note"
            onClick={() => setEditModalOpen(true)}
            id={`dots-in-notes-${index}`}
          />
        )}
        {editModalOpen && (
          <div
            style={{ left: rightPosition, top: topPosition }}
            ref={modalRef}
            className={styles.editWrapper}
          >
            <div
              className={styles.modalRow}
              onClick={async () => {
                await setEditModalOpen(false);
                setTimeout(() => {
                  setEditMode(true);
                }, 100);
              }}
            >
              <img alt="edit-note" src={editNote} />
              <span className={styles.editText}>Edit</span>
            </div>
            <div className={styles.modalRow} onClick={() => deleteNoteMethod()}>
              <img alt="delete-note" src={deleteNote} />
              <span className={styles.deleteText}>Delete</span>
            </div>
          </div>
        )}
      </div>
      {editMode ? (
        <div className={styles.boxActive}>
          <textarea
            ref={editAreaRef}
            id="editNote"
            className={styles.myTextArea}
            style={{ height: 56 }}
            // placeholder="Add a note..."
            defaultValue={currentNote.note}
            onChange={e => {
              setCurrentNote({
                ...note,
                note: e.target.value
              });
            }}
          />
          <div className={styles.buttonWrapper}>
            <CustomButton
              buttonRef={button2Ref}
              customStyle={{ width: 83 }}
              type={2}
              textField="Cancel"
              size="small"
              buttonOnClick={() => cancelWriting()}
            />
            <CustomButton
              buttonRef={buttonRef}
              customStyle={{ width: 83 }}
              type={1}
              textField="Save"
              size="small"
              buttonOnClick={() => editNoteAction()}
              isDisabled={note.note === '' || note.note === currentNote.note}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <ShortText
          name={note?.note}
          staticLimit={140}
          textClassName={styles.textNote}
          id="candidate-note"
        />
      )}
      {screenW <= 576 && (
        <span className={styles.dateNote}>{format(new Date(note?.addDate), 'dd MMM, yyyy')}</span>
      )}
    </div>
  );
};
export default Note;
