/* eslint-disable no-return-assign */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Xarrow, { useXarrow } from 'react-xarrows';
import styles from '../Styles/AssessmentList.module.css';
import emptyIcon from '../../../images/assessment/empty.svg';
import imageSrc from './onboardingComplete.svg';
import { ReactComponent as Plus } from '../../AddAssessment/Images/plusButton.svg';

import {
  filteredAssessments,
  filterData,
  filterDataStatus,
  selectAssessmentList2,
  // assesmentCounts,
  archivedCount,
  unpublishedCount,
  publishedCount,
  activeTab
} from '../../../redux/assessments/selectors';
import route from '../../../constant/route';
import {
  // getCompanyAssessmentCountAction,
  getCompanyAssessmentFilterCountAction,
  getCompanyAssessmentListAction
} from '../../../redux/assessments/actions';
import { filterStatusAssessment, setCompanyAssessmentList } from '../../../redux/assessments/slice';
import AssessmentCardComponent from './AssessmentCardComponent';
import { userSelector } from '../../../redux/auth/selectors';
import TabButtonList from './TabButtonList';
import CustomButton from '../../../Common/Components/CustomButton';
import Loading from '../../../Common/Components/Loading';
import Highlight from '../../../Common/Components/Highlight';
import CreateAssessmentPopup from './CreateAssessmentPopup';
import popupImage from './fastRecruitment.svg';
import QuickStart from '../../../Auth/Components/QuickStart/QuickStart';

import mixpanel, { EXCLUDED_COMPANY_LIST } from '../../../utils/mixpanel';
import WelcomePopupComponent from './WelcomePopupComponent';
import {
  completedOnboardingNoRedux,
  onboardingStepRedux,
  popupFlowEnded,
  stepsReady,
  welcomeOnboardShowRedux
} from '../../../redux/plan/selectors';
import useOutsideClick from '../../../hooks/useOutsideClick';
import {
  setAddLogoBoxHighlight,
  setOnboardingStep,
  setPopupFlowEnded,
  setTeamHighlight
} from '../../../redux/plan/slice';
import useOutsideClickRef from '../../../hooks/useOutsideClickRef';
import { getOnboardingStepsAction } from '../../../redux/plan/actions';
import BackgroundImageWrapper from '../../../Common/Components/BackgroundImageWrapper/BackgroundImageWrapper';
import noCompanyAssessmentImg from '../../../Common/Components/BackgroundImageWrapper/noCompanyAssessment.svg';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import { fetchCompanyAssessmentList, useWindowSize } from '../../../utils/helpers';
import CustomSearchbar from '../../../Common/Components/CustomSearchbar/CustomSearchbar';
import { ReactComponent as MobilePlus } from './mobilePlus.svg';

export default function AssessmentList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [windowW, windowH] = useWindowSize();

  const assessmentList2 = useSelector(selectAssessmentList2);

  const countArchive = useSelector(archivedCount);

  const user = useSelector(userSelector);
  const [loading, setLoading] = React.useState(true);
  const countUnpublished = useSelector(unpublishedCount);
  const countPublished = useSelector(publishedCount);

  const fStatus = useSelector(filterDataStatus);
  const tab = useSelector(activeTab);

  const [onboardingPopupShow, setOnboardingPopupShow] = useState(false);
  const [onboardingCompletePopupShow, setOnboardingCompletePopupShow] = useState(false);
  const [welcomePopupShow, setWelcomePopupShow] = useState(false);

  const updateXarrow = useXarrow();
  useEffect(async () => {
    setLoading(true);
    if (!fStatus) {
      dispatch(filterStatusAssessment('primary'));
    }
    setLoading(false);
  }, [user]);

  const ref = useRef();

  useEffect(async () => {
    setLoading(true);
    if (user && user.companyId !== null) {
      const data = {
        CompanyId: user.companyId,
        ObjectStatusId: 1,
        search: '',
        IsActive: tab === 'active'
      };
      const jsonData = {
        CompanyId: user.companyId
      };

      dispatch(getCompanyAssessmentFilterCountAction(jsonData));
      fetchCompanyAssessmentList(data, setLoading, ref, dispatch);
    }
  }, [user]);

  const addAssessmentButton = useRef(null);
  const parent = useRef();
  const completedOnboardingNo = useSelector(completedOnboardingNoRedux);

  const onboardingStart = useSelector(welcomeOnboardShowRedux);
  const onboardingStep = useSelector(onboardingStepRedux);
  const [QSopen, setQSopen] = useState(true);
  const stepsReadyRedux = useSelector(stepsReady);

  /* --------------- Mixpanel identify + pageview --------------- */

  useEffect(async () => {
    const isExcluded = EXCLUDED_COMPANY_LIST?.includes(user?.companyName);
    if (user && user?.userId && !isExcluded) {
      // Mixpanel Identify
      mixpanel.identify(user.userId);

      if (user.userGroupType !== 'Super Admin') {
        mixpanel.people.set({
          $name: user.userNameSurname,
          $email: user.email,
          'Company Name': user.companyName,
          'Admin Flag': user.isAdmin,
          'User Role': user.userGroupType
        });
        mixpanel.set_group('company_name', user.companyName);
        mixpanel.get_group('company_name', user.companyName).set_once('$name', user.companyName);
      } else {
        mixpanel.people.set({
          $name: user.userNameSurname,
          $email: user.email,
          'Company Name': 'Coensio',
        });
        mixpanel.set_group('company_name', 'Coensio');
      }

      // Mixpanel Pageview
      mixpanel.track('Homepage - Pageview', {
        'User Id': user.userId,
        'Company Name': user.companyName,
        'Name Surname': user.userNameSurname,
        Email: user.email
      });
    }
    if (user?.isFirstAdmin && onboardingStep === 0) {
      dispatch(getOnboardingStepsAction()); // initial call due to fill QS progress bar accordingly + not show QS
    }
  }, [user]);

  useEffect(() => {
    dispatch(setTeamHighlight(false));
  }, []);

  const handleWelcomePopupClose = () => {
    setWelcomePopupShow(false);
  };

  const handleGetStartedClicked = () => {
    setWelcomePopupShow(false);
    setOnboardingPopupShow(true);
  };

  useEffect(async () => {
    if (completedOnboardingNo === 4 && localStorage.getItem('onboarding')) {
      setOnboardingCompletePopupShow(true);
      localStorage.removeItem('onboarding');
    }

    if (user?.isFirstAdmin) {
      if (
        document.cookie.split('; ').find(row => row.startsWith('firstLogin=')) === undefined &&
        completedOnboardingNo === 0
      ) {
        await setQSopen(false);
        await setWelcomePopupShow(true);
        document.cookie = await `firstLogin=DONE;max-age=${180 * 24 * 60 * 60 * 1000}; path=/`;
      }
    }
  }, [completedOnboardingNo, user]);

  useEffect(() => {
    if (onboardingCompletePopupShow === true) {
      setQSopen(false);
    }
  }, [onboardingCompletePopupShow]);

  /* ------------------------------------------------------------ */
  const addAssmRef = useRef();
  const navbar = document?.getElementById('navbarBrand');

  function handleOutsideInAsssessment(event) {
    const AddAssessmentButtonId = document?.getElementById('AddAssessmentButtonId');
    const QSid = document?.getElementById('QSid');
    const OnboardingAddLogoId = document?.getElementById('OnboardingAddLogoId');
    const profileBanner = document?.getElementById('profile_Banner');

    if (
      AddAssessmentButtonId &&
      QSid &&
      OnboardingAddLogoId &&
      // navbar &&
      !AddAssessmentButtonId?.contains(event?.target) &&
      !QSid?.contains(event?.target) &&
      !OnboardingAddLogoId?.contains(event?.target) &&
      !profileBanner?.contains(event?.target) &&
      !navbar?.contains(event?.target)
    ) {
      if (QSopen) {
        setQSopen(false);
      } else {
        dispatch(setOnboardingStep(0));
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideInAsssessment);

    return () => {
      document.removeEventListener('click', handleOutsideInAsssessment);
    };
  }, [QSopen]);

  useEffect(() => {
    if (
      onboardingStep !== 10 &&
      onboardingStep !== 0 &&
      !welcomePopupShow &&
      !onboardingPopupShow &&
      !QSopen
    ) {
      setQSopen(true);
    }
  }, [onboardingPopupShow]);

  /* ------------------------ Search related stuff ------------------------ */
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  const filterAssessments = () => {
    const temp = [];
    assessmentList2.forEach(e => {
      if (
        e.name.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr')) ||
        e.rootAssessmentName.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr'))
      ) {
        temp.push(e);
      }
    });
    setFilteredList(temp);
  };

  const [searchTimerId, setSearchTimerId] = useState(-1);

  useEffect(() => {
    if (searchTerm !== '') {
      if (searchTimerId !== -1) {
        clearTimeout(searchTimerId);
        setSearchTimerId(-1);
      }
      setSearchTimerId(
        setTimeout(() => {
          filterAssessments();
        }, 300)
      );
    } else {
      clearTimeout(searchTimerId);
      setFilteredList(assessmentList2);
    }
  }, [searchTerm, assessmentList2]);

  return (
    <>
      <div className={styles.AssessmentListHeader}>
        <div className={`${styles['assessment-header-container']} `}>
          <TabButtonList setLoading={setLoading} companyId={user && user.companyId} />

          {/* Display: none on mobile */}
          <div className={styles.SearchWrapper} style={{ marginLeft: 'auto' }}>
            <CustomSearchbar
              barId="searchBarIdinAssessmentList"
              isExtended
              wrapperStyle={{
                height: '42px',
                background: '#FFFFFF',
                border: '1px solid rgba(57, 57, 57, 0.15)',
                borderRadius: '10px',
                // margin: '0rem 0rem 2.4rem',
                width: '27rem',
                marginLeft: 'auto',
                marginRight: '16px'
              }}
              inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
              inputFieldValue={searchTerm}
              inputFieldPlaceholder="Search Position"
              inputFieldOnChange={e => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>

          {/* Display: none on mobile */}
          <div className={styles.AddAssessmentButton}>
            <Highlight
              isHighlighted={
                (onboardingStep === 1 || onboardingStep === 4) &&
                !welcomePopupShow &&
                !onboardingPopupShow
              }
              paddingPixel={4}
              borderRadius={12}
              child={
                windowW > 710 ? (
                  <CustomButton
                    buttonRef={addAssmRef}
                    customStyle={{
                      position: onboardingPopupShow && 'relative',
                      zIndex: onboardingPopupShow && '10005'
                    }}
                    buttonId="AddAssessmentButtonId"
                    type={1}
                    textField="Add Position"
                    buttonOnClick={() => {
                      history.push(route.addAssessment);
                    }}
                  />
                ) : (
                  <CustomButton
                    Icon={Plus}
                    buttonRef={addAssmRef}
                    customStyle={{
                      position: onboardingPopupShow && 'relative',
                      zIndex: onboardingPopupShow && '10005',
                      height: 28,
                      maxWidth: 28,
                      padding: '0px 0px 0px 9px'
                    }}
                    buttonId="AddAssessmentButtonId"
                    type={1}
                    buttonOnClick={() => {
                      history.push(route.addAssessment);
                    }}
                  />
                )
              }
            />
          </div>

          {/* Display: none on web view */}
          <MobilePlus
            className={styles.plusIcon}
            onClick={() => history.push(route.addAssessment)}
          />
        </div>

        {/* Display: none on web view */}
        <div className={styles.SearchWrapper}>
          <CustomSearchbar
            searchbarId="searchBarIdinAssessmentListMobile"
            isExtended
            wrapperStyle={{
              height: '42px',
              background: '#FFFFFF',
              border: '1px solid rgba(57, 57, 57, 0.15)',
              borderRadius: '10px'
              // margin: '0rem 0rem 2.4rem',
              // width: '27rem',
              // marginLeft: 'auto',
              // marginRight: '16px'
            }}
            inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
            inputFieldValue={searchTerm}
            inputFieldPlaceholder="Search Position"
            inputFieldOnChange={e => {
              setSearchTerm(e.target.value);
            }}
            inputFieldOnKeyDown={e => {
              if (e.keyCode === 13) {
                // Enter
                document.getElementById(`searchBarIdinAssessmentListMobile-input`).blur();
              }
            }}
          />
        </div>
      </div>

      <div
        className={`${
          !loading &&
          countArchive + countPublished + countUnpublished !== 0 &&
          filteredList?.length !== 0 &&
          styles.Container
        } ${
          !loading &&
          countArchive + countPublished + countUnpublished === 0 &&
          filteredList?.length === 0 &&
          styles.emptyContainer
        }`}
      >
        {assessmentList2 &&
          !loading &&
          countArchive + countPublished + countUnpublished !== 0 &&
          filteredList?.length !== 0 && <div className={styles.assessments}>POSITIONS</div>}
        {filteredList && filteredList.map(item => <AssessmentCardComponent item={item} />)}

        {!loading &&
          ((tab === 'active' && countPublished > 0) || (tab === 'archived' && countArchive > 0)) &&
          assessmentList2?.length > 0 &&
          filteredList?.length === 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '4rem',
                  width: '100%'
                }}
              >
                <BackgroundImageWrapper
                  width="50%"
                  // minWidth="400px"
                  image={noCompanyAssessmentImg}
                  header="No results were found for your criteria"
                  subtext="Please make sure you are looking for the right name"
                />
              </div>
            </>
          )}

        {user && (
          <>
            {!loading &&
              countArchive + countPublished + countUnpublished === 0 &&
              assessmentList2?.length === 0 && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '4rem',
                      width: '100%'
                    }}
                  >
                    <BackgroundImageWrapper
                      width="50%"
                      // minWidth="400px"
                      image={noCompanyAssessmentImg}
                      header={
                        windowW > 576
                          ? 'Add assessments and meet new talents'
                          : 'Get inside the Coensio World'
                      }
                      subtext={
                        windowW > 576
                          ? 'There is no active assessment right now.'
                          : 'Create your first assessment from the “+” button and meet new talents!'
                      }
                    />
                  </div>
                </>
              )}
          </>
        )}

        {loading && (
          <div
            style={{
              height: 'calc(100vh - 280px)',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Loading />
          </div>
        )}
        {!loading &&
          ((tab === 'active' && countPublished > 0) || (tab === 'archived' && countArchive > 0)) &&
          assessmentList2?.length === 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '4rem',
                  width: '100%'
                }}
              >
                <BackgroundImageWrapper
                  width="50%"
                  // minWidth="400px"
                  image={noCompanyAssessmentImg}
                  header="You are not authorized for any of the assessments"
                  subtext="Create an assessment or contact your teammates."
                />
              </div>
            </>
          )}
        {!loading &&
          ((tab === 'active' && countPublished <= 0) ||
            (tab === 'archived' && countArchive <= 0)) &&
          countArchive + countPublished + countUnpublished !== 0 &&
          assessmentList2?.length === 0 && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: '4rem',
                  width: '100%'
                }}
              >
                <BackgroundImageWrapper
                  width="50%"
                  // minWidth="400px"
                  image={noCompanyAssessmentImg}
                  header="There isn’t any assessment with this status."
                />
              </div>
            </>
          )}
      </div>

      <WelcomePopupComponent
        show={welcomePopupShow}
        onClose={handleWelcomePopupClose}
        getStartedClicked={handleGetStartedClicked}
      />
      <CreateAssessmentPopup
        show={onboardingCompletePopupShow}
        setShow={setOnboardingCompletePopupShow}
        xCenter
        yCenter
        imageSrc={imageSrc}
        title="Coensio Master"
        text="Congratulations! You have completed all steps to be a master user"
      />

      <CreateAssessmentPopup
        show={onboardingPopupShow}
        setShow={setOnboardingPopupShow}
        xConstant={16}
        imageSrc={popupImage}
        title="Start fast recruitment"
        text="Meet the most suitable candidates for your job"
        relativeElementId="AddAssessmentButtonId"
        givenId="secondModalId"
      />
      <QuickStart
        quickStartId="QSid"
        open={QSopen}
        setOpen={setQSopen}
        parentRef={parent}
        show={stepsReadyRedux && user?.isFirstAdmin && completedOnboardingNo !== 4}
        relativeItemId="AddAssessmentButtonId"
        innerBannerStyle={
          (welcomePopupShow || onboardingPopupShow || onboardingCompletePopupShow) && {
            zIndex: '0'
          }
        }
      />
    </>
  );
}
