import React, { useEffect, useState } from 'react';
import styles from '../Styles/InfoBanner.module.css';
import { ReactComponent as Tick } from '../../images/successSubmit.svg';
import { useWindowSize } from '../../utils/helpers';
import ShortText from './ShortText';

export default function InfoBanner({ setStatus, show, text, CustomIcon, textColor, bannerStyle }) {
  const [style, setStyle] = useState({});
  const [windowW] = useWindowSize();

  useEffect(() => {
    if (show) {
      if (windowW > 576) {
        setStyle({
          width: document.getElementById('text-banner')?.getBoundingClientRect()?.width + 70,
          top: 15,
          left: `calc(50% - ${
            (document.getElementById('text-banner')?.getBoundingClientRect()?.width + 70) / 2
          }px`
        });
        setTimeout(() => {
          setStyle({
            top: -37,
            left: `calc(50% - ${
              (document.getElementById('text-banner')?.getBoundingClientRect()?.width + 70) / 2
            }px`
          });
        }, 2600);

        setTimeout(() => {
          setStyle({
            width: 39,
            top: 15,
            left: `calc(50% - ${
              (document.getElementById('text-banner')?.getBoundingClientRect()?.width + 70) / 2
            }px`
          });
        }, 2000);
      } else {
        setTimeout(() => {
          setStyle({
            bottom: 90,
            left: `calc(50% - ${
              (document.getElementById('text-banner')?.getBoundingClientRect()?.width + 70) / 2
            }px`
          });
        }, 500);

        setTimeout(() => {
          setStyle({
            bottom: -200,
            left: `calc(50% - ${
              (document.getElementById('text-banner')?.getBoundingClientRect()?.width + 70) / 2
            }px`
          });
        }, 2600);
        setTimeout(() => {
          setStyle({
            bottom: 90,
            left: `calc(50% - ${
              (document.getElementById('text-banner')?.getBoundingClientRect()?.width + 70) / 2
            }px`
          });
        }, 2000);
      }
      setTimeout(() => {
        setStatus(false);
      }, 3000);
    }
  }, [show, text]);

  return (
    <>
      {show && (
        <div
          className={`${windowW > 576 ? styles.closeContainer : styles.closeContainerMobile}`}
          style={{
            ...style,
            backgroundColor: CustomIcon ? '#FFFBFB' : ' #EDF9F2',
            ...bannerStyle
          }}
        >
          {CustomIcon ? <CustomIcon className={styles.icon} /> : <Tick className={styles.icon} />}
          <div
            id="text-banner"
            className={styles.text}
            style={{ textColor, color: CustomIcon ? '#DE4747' : '#4AC389' }}
          >
            {windowW <= 576 ? (
              <ShortText name={text} staticLimit={35} id="info-banner-short-text" />
            ) : (
              text
            )}
          </div>
        </div>
      )}
    </>
  );
}
