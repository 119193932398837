import { React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customAssessmentId } from '../../../redux/assessmentsVol2/selectors';
import {
  setQuestionBeingRemoved,
  setRemoveQuestionShow
} from '../../../redux/assessmentsVol2/slice';
import coding from '../../../images/coding_assessment.svg';
import freetext from '../../../images/freetext_assessment.svg';
import multiselect from '../../../images/multiple_assessment.svg';
import clock from '../Images/clockDuration.svg';
import outSource from '../Images/outSource.svg';
import deleteMinus from '../Images/deleteMinus.svg';
import styles from '../Styles/QuestionBasedQuestion.module.css';
import { ReactComponent as Premium } from '../../../images/premium_icon.svg';
import { ReactComponent as Purchased } from '../../../images/purchased_icon.svg';
import { ReactComponent as WorldIcon } from '../../../images/world.svg';
import { userSelector } from '../../../redux/auth/selectors';
import { questionAvailabilityControlAction } from '../../../redux/plan/actions';
import HeadsUpModal from '../../../Auth/Modals/HeadsUpModal';
import ContactSalesSuccess from '../../../Auth/Modals/UnsubscribeSuccessModal';
import { currentPlanRedux, premiumQuestionCheckRedux } from '../../../redux/plan/selectors';
import Tooltip from '../../../Common/Components/Tooltip';
import FreeTrialModal from '../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import { setToBePurchasedAddOn } from '../../../redux/plan/slice';
import PurchaseAddOnSuccessModal from '../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';

export default function QuestionBasedQuestion({
  qType,
  skills,
  duration,
  title,
  onClick,
  index,
  id,
  status,
  difficulty = 'Easy',
  language
}) {
  const dispatch = useDispatch();
  const rootID = useSelector(customAssessmentId);
  const user = useSelector(userSelector);

  const calculateMin = duration => {
    let timeInSeconds = 0;
    timeInSeconds += parseInt(duration, 10);
    const time = {
      min: Math.floor(timeInSeconds / 60),
      sec: timeInSeconds - Math.floor(timeInSeconds / 60) * 60
    };
    if (time.sec === 0) {
      return `${time.min} min`;
    }
    if (time.min === 0) {
      return `${time.sec} sec`;
    }
    return `${time.min} min ${time.sec} sec`;
  };

  const handleRemoveQ = () => {
    const data = {
      rootAssessmentId: rootID,
      questionId: id
    };
    dispatch(setRemoveQuestionShow(true));
    dispatch(setQuestionBeingRemoved(data));
  };

  const [previewPremiumQ, setPreviewPremiumQ] = useState(false);
  const [freeTrialModalShow, setFreeTrialModalShow] = useState(false);

  const myPlan = useSelector(currentPlanRedux);
  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const isPremiumObject = useSelector(premiumQuestionCheckRedux);
  const [purchaseAddOnsuccess, setPurchaseAddOnsuccess] = useState(false);

  return (
    <div className={styles.wrapper}>
      {/* START OF PURCHASE PQ in HM View (preview Q) */}
      <HeadsUpModal
        addOnCode={61}
        show={previewPremiumQ}
        onHide={() => setPreviewPremiumQ(false)}
        title="This one is a premium question"
        description={
          myPlan?.isCustom
            ? ` Your plan does not include this question.
            Please contact our sales team to upgrade your plan.`
            : `Get this premium question for only $${isPremiumObject?.price} to take your assessment to the next level! `
        }
        isCustom={myPlan?.isCustom}
        setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchaseAddOnsuccess}
        tryAgain={() => console.log('heyyo purchase to preview')}
      />
      <ContactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />

      <FreeTrialModal
        show={freeTrialModalShow}
        onHide={() => setFreeTrialModalShow(false)}
        addOnCode={61}
      />

      {/* Purchas AddOn Success */}
      <PurchaseAddOnSuccessModal
        show={purchaseAddOnsuccess}
        onHide={() => {
          setPurchaseAddOnsuccess(false);
          window.open(`/question/${id}`, '_blank').focus();
        }}
        addOnCode={61}
      />
      {/* END OF PURCHASE PQ in HM View (preview Q) */}

      <div className={styles.titleWrapper}>
        <div className={styles.questionNo}>{index}</div>
        <div className={styles.title}>{title}</div>
        {language !== 'EN' && (
          <div className={styles.languageLabel}>
            <WorldIcon className={styles.worldIcon} />
            <div className={styles.questionLanguage}>{`${language}`}</div>
          </div>
        )}
        <div className={styles.questionDifficulty}>{`${difficulty}`}</div>
      </div>
      <div className={styles.rowWrapper}>
        <span style={{ marginRight: '4px' }}>
          <img
            width={36}
            height={24}
            alt=""
            src={qType === 2 ? coding : qType === 3 ? freetext : multiselect}
          />
        </span>

        {skills[0] !== '' &&
          skills.map((skill, index) => <div className={styles.skillWrapper}>{skill}</div>)}
        <div className={styles.durationWrapper}>
          <span className={styles.imgWrapper}>
            <img alt="" src={clock} />
          </span>
          <div className={styles.duration}>{calculateMin(duration)}</div>
        </div>

        {/* 10 - Purchased
          20 - Free
          30 - Premium   */}
        {status === 30 ? (
          <Tooltip direction="top" text="This one is a premium question. Curious?">
            <Premium className={styles.statusIcon} />
          </Tooltip>
        ) : status === 10 ? (
          <Tooltip direction="top" text="This one is a purchased premium question.">
            <Purchased className={styles.purchasedIcon} />
          </Tooltip>
        ) : null}
        <div className={styles.iconWrapper}>
          <span
            className={styles.imgWrapper1}
            style={{ marginRight: '10px' }}
            onClick={async () => {
              const data2 = {
                CompanyId: user?.companyId,
                QuestionId: id
              };
              const res = await dispatch(questionAvailabilityControlAction(data2));
              if (res?.payload?.isAvailable) {
                window.open(`/question/${id}`, '_blank').focus();
              } else if (myPlan?.name === 'Free Trial') {
                setFreeTrialModalShow(true);
              } else {
                const data = {
                  questionId: id,
                  bundleItemCode: 60
                };
                await dispatch(setToBePurchasedAddOn(data));
                await setPreviewPremiumQ(true);
              }
            }}
          >
            <img className={styles.img1} alt="" src={outSource} />
          </span>
          <span className={styles.imgWrapper2} onClick={() => handleRemoveQ()}>
            <img className={styles.img2} alt="" src={deleteMinus} />
          </span>
        </div>
      </div>
    </div>
  );
}
