import React, { useEffect, useLayoutEffect, useState } from 'react';
import ToolTip from './Tooltip';
import styles from '../Styles/ShortText.module.css';

function useWindowSize() {
  // Use this hook to update the bounding box in the parent!
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function ShortText({
  name = '',
  textClassName = '',
  type = 0,
  staticLimit = null,
  id = '',
  position = 'top'
}) {
  const [width, height] = useWindowSize();
  const [nameLimit, setNameLimit] = useState(0);
  const [showAll, setShowAll] = useState(false);

  // const handleKeyDown = async event => {
  //   event.preventDefault();
  //   const charCode = String.fromCharCode(event.which).toLowerCase();
  //   if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
  //     await navigator.clipboard.writeText(name);
  //   }
  // };

  // TODO: type 0
  const returnMailLimit = width => {
    if (width >= 1700) {
      return 36;
    }
    if (width >= 1480) {
      return 30;
    }
    if (width >= 1360) {
      return 26;
    }
    if (width >= 1240) {
      return 22;
    }
    return 20;
  };
  // TODO: type 1
  const leftNameLimit = width => {
    if (width >= 1700) {
      return 28;
    }
    if (width >= 1480) {
      return 24;
    }
    if (width >= 1360) {
      return 21;
    }
    if (width >= 1240) {
      return 18;
    }
    return 17;
  };
  // TODO: type 2
  const leftRadioLimit = () => {
    if (width >= 1700) {
      return 35;
    }
    if (width >= 1480) {
      return 28;
    }
    if (width >= 1360) {
      return 25;
    }
    if (width >= 1240) {
      return 24;
    }
    return 22;
  };
  // TODO: type 3
  const QuestionLimit = () => {
    if (width >= 1700) {
      return 72;
    }
    if (width >= 1480) {
      return 62;
    }
    if (width >= 1360) {
      return 56;
    }
    if (width >= 1240) {
      return 50;
    }
    return 46;
  };
  // TODO: type 4
  const assessmentNameLimit = width => {
    if (width >= 1700) {
      return 50;
    }
    if (width >= 1480) {
      return 45;
    }
    if (width >= 1360) {
      return 38;
    }
    if (width >= 1240) {
      return 30;
    }
    return 24;
  };

  // type 5
  const labelNameLimit = width => {
    if (width >= 1746) {
      return 26;
    }
    if (width >= 1600) {
      return 24;
    }
    if (width >= 1480) {
      return 22;
    }
    if (width >= 1360) {
      return 20;
    }
    return 18;
  };

  useEffect(() => {
    if (staticLimit) {
      setNameLimit(staticLimit);
    } else {
      if (type === 0) {
        setNameLimit(returnMailLimit(width));
      }
      if (type === 1) {
        setNameLimit(leftNameLimit(width));
      }
      if (type === 2) {
        setNameLimit(leftRadioLimit(width));
      }
      if (type === 3) {
        setNameLimit(QuestionLimit(width));
      }
      if (type === 4) {
        setNameLimit(assessmentNameLimit(width));
      }
      if (type === 5) {
        setNameLimit(labelNameLimit(width));
      }
    }
  }, [width, staticLimit]);

  useEffect(() => {
    if (staticLimit) {
      setShowAll(staticLimit + 3 >= name?.length);
    } else {
      setShowAll(nameLimit + 3 >= name?.length);
    }
  }, [nameLimit, name]);

  if (
    (name?.length > nameLimit && !showAll) ||
    (staticLimit && name?.length > staticLimit && !showAll)
  ) {
    return (
      <ToolTip direction={position} text={name}>
        <span
          spellCheck={false}
          id="short-text"
          className={`${styles.textWrapper} ${styles.text} ${textClassName || ''}`}
          contentEditable
          // onKeyDown={handleKeyDown}
          onCopy={() => {
            const text = window.getSelection().toString();
            if (
              `${text}...` === `${name.slice(0, nameLimit)}...` ||
              text === `${name.slice(0, nameLimit)}...`
            ) {
              navigator.clipboard.writeText(name);
            } else {
              navigator.clipboard.writeText(text);
            }
          }}
        >
          {/* <span className={`${styles.text} ${textClassName || ''}`}> */}
          {showAll ? name : `${name.slice(0, nameLimit)}...`}
          {/* </span> */}
        </span>
      </ToolTip>
    );
  }
  return (
    <span id={id} className={textClassName}>
      {name}
    </span>
  );
}
