import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styles from './SignupComponent2.module.css';
import { ReactComponent as CoensioLogo } from '../../../images/Coensio_newLogo.svg';
import CustomButton from '../../../Common/Components/CustomButton';
import TextInput from '../../../Common/Components/TextInput';
import PasswordInput from '../../../Common/Components/PasswordInput';
import Checkbox from '../../../Common/Components/Checkbox';
import RawCheckbox from '../../../Common/RawCheckbox/Checkbox';
import { signedUserIdRedux, signUpStep2ErrorMessageRedux } from '../../../redux/signup/selectors';
import { signupStep2Action } from '../../../redux/signup/actions';

import mixpanel from '../../../utils/mixpanel';
import { useWindowSize } from '../../../utils/helpers';

const SignupComponent2 = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [isChecked, setChecked] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordAgainError, setPasswordAgainError] = useState(false);
  const [loading, setLoading] = useState(false);

  const signUpStep2ErrorMessage = useSelector(signUpStep2ErrorMessageRedux);
  const [errorMessage, setErrorMessage] = useState();

  const input = React.useRef(null);

  function resizeHandler() {
    const sessionView = document.body;
    sessionView.style.height = window.visualViewport.height.toString() + 'px';
    document.getElementsByTagName('html')[0].style.height =
      window.visualViewport.height.toString() + 'px';
  }

  useEffect(() => {
    if (document.activeElement === input.current) {
      console.log(window.visualViewport);
      if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', resizeHandler);
      }
    }
  }, [document.activeElement]);

  useEffect(() => {
    setErrorMessage(signUpStep2ErrorMessage);
  }, [signUpStep2ErrorMessage]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const userId = useSelector(signedUserIdRedux);
  const dispatch = useDispatch();

  function handleCheckbox() {
    if (isChecked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }

  const handleCreateAccount = async () => {
    setLoading(true);
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const recaptchaToken = await executeRecaptcha('signUp');

    if (recaptchaToken) {
      const data = {
        id: userId,
        name: name,
        surname: surname,
        companyName: companyName,
        password: password,
        confirmPassword: passwordAgain,
        termsAndPolicy: isChecked,
        reCaptchaToken: recaptchaToken
      };
      const resp = await dispatch(signupStep2Action(data));
      setLoading(false);
    } else {
      console.log('Recaptcha error');
      setLoading(false);
    }
  };

  /* --------------- Mixpanel pageview --------------- */
  useEffect(() => {
    if (userId) {
      mixpanel.track('Signup Page 2 - Pageview', {
        'User Id': userId
      });
    }
  }, [userId]);
  /* ------------------------------------------------- */

  const [screenW, screenH] = useWindowSize();

  return (
    <div className={styles.PageWrapper}>
      {/* To disable autofill on form below */}
      {/* Chrome automatically fills first password field and one previous input field */}
      <div style={{ position: 'absolute', top: '0', left: '0', opacity: '0' }}>
        <input
          type="text"
          value=""
          style={{ pointerEvents: 'none' }}
          onChange={e => e.preventDefault()}
          onClick={e => e.preventDefault()}
          onKeyDown={e => e.preventDefault()}
        />
        <input
          type="password"
          value=""
          style={{ pointerEvents: 'none' }}
          onChange={e => e.preventDefault()}
          onClick={e => e.preventDefault()}
          onKeyDown={e => e.preventDefault()}
        />
      </div>
      <div className={styles.CardWrapper}>
        <div className={styles.InnerCard}>
          <div className={styles.Left}>
            <CoensioLogo className={styles.logo} />
            <div className={styles.TextWrapper}>
              {screenW > 576 ? 
                <>
                  <text>Unlock</text>
                  <text>the</text>
                  <text className={styles.TextWrapperLastLine}>potential!</text>
                </>
              : 
                <text>Unlock the potential!</text>
              }
            </div>
          </div>
          <div className={styles.Right}>
            <label className={styles.almostThereText}>Almost There!</label>
            <div className={styles.NameSurnameWrapper}>
              <div className={styles.NameInput}>
                <TextInput
                  inputRef={input}
                  value={name}
                  placeholder="Name*"
                  onChange={e => setName(e.target.value)}
                  showInvisibleError
                />
              </div>
              <div className={styles.NameInput}>
                <TextInput
                  value={surname}
                  placeholder="Surname*"
                  onChange={e => setSurname(e.target.value)}
                  showInvisibleError
                />
              </div>
            </div>
            <div className={styles.TextInputWrapper}>
              <PasswordInput
                value={password}
                secondPasswordForPasswordField={passwordAgain}
                placeholder="Password*"
                onChange={e => setPassword(e.target.value)}
                setParentErrorState={setPasswordError}
                showInvisibleError
              />
            </div>
            <div className={styles.TextInputWrapper}>
              <PasswordInput
                value={passwordAgain}
                isPasswordAgain
                firstPasswordForPasswordAgainField={password}
                placeholder="Password Again*"
                onChange={e => setPasswordAgain(e.target.value)}
                setParentErrorState={setPasswordAgainError}
                showInvisibleError
              />
            </div>
            <div className={styles.TextInputWrapper}>
              <TextInput
                value={companyName}
                placeholder="Company Name*"
                onChange={e => {
                  setCompanyName(e.target.value);
                  setErrorMessage();
                }}
                showInvisibleError
                error={
                  errorMessage === 'Company name exist.' && 'This company name already exists.'
                }
              />
            </div>

            <div className={styles.terms}>
              <RawCheckbox
                isChecked={isChecked}
                setIsChecked={setChecked}
                id="signup-rawcheckbox"
                myStyle={{ marginRight: '1rem' }}
                size={18}
              />

              <div className={styles.text} onClick={() => handleCheckbox()}>
                I agree to the&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.coens.io/terms-of-use/"
                  className={styles.link}
                  onClick={e => e.stopPropagation()}
                >
                  Terms of Services
                </a>
                &nbsp;and&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.coens.io/privacy-policy/"
                  className={styles.link}
                  onClick={e => e.stopPropagation()}
                >
                  Privacy Policy
                </a>
                .
              </div>
            </div>
            <CustomButton
              isDisabled={
                !isChecked ||
                passwordError ||
                passwordAgainError ||
                !name ||
                name.trim() === '' ||
                !surname ||
                surname.trim() === '' ||
                !companyName ||
                companyName.trim() === '' ||
                !password ||
                !passwordAgain
              }
              loading={loading}
              textField="Create My Account"
              customStyle={{ width: '100%' }}
              size="medium"
              buttonOnClick={() => handleCreateAccount()}
              enterEnabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent2;
