import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CvCardComponent from '../../../Assessment/Test/Components/CvCardComponent/CvCardComponent';
import Header from '../../../Assessment/Test/Components/Header/Header';
import NewOpComponent from '../../../Assessment/Test/Components/NewOpComponent/NewOpComponent';
import {
  getCandidateInfoFromEmailAction,
  getScreeningQuestionsAction
} from '../../../redux/AssessmentSession/actions';
import {
  AssessmentTitle,
  CandidateEmail,
  CompanyName,
  CvPath,
  newOpListRedux,
  rootAssessmentNameRedux,
  screeningLoadingRedux
} from '../../../redux/AssessmentSession/selectors';
import {
  ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD,
  ASSESSMENT_FLOW_MOBILE_THRESHOLD
} from '../../../utils/helpers';
import styles from './UnpublishedNewOpComponent.module.css';

import mixpanel from '../../../utils/mixpanel';
import ScreeningCardComponent from '../../../Assessment/Test/Components/Screening/ScreeningCardComponent';

const CLOSED = 0;
const OPEN = 1;

const UnpublishedNewOpComponent = ({ id, application }) => {
  const dispatch = useDispatch();
  const email = useSelector(CandidateEmail);

  const [isNewOpCompleted, setIsNewOpCompleted] = useState(false);
  const newOpCompanyList = useSelector(newOpListRedux);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);
  const companyName = useSelector(CompanyName);

  const [selectedNewOpCompList, setSelectedNewOpCompList] = useState();

  // Those 2 states are not used here but they were neeeded for candidate result page
  // That is why I still declare them here
  const [cardState, setCardState] = useState(OPEN);
  const [path, setPath] = useState(null);

  const [closeWindowFlag, setCloseWindowFlag] = useState(false);

  const [innerPhone, setInnerPhone] = useState();

  /* ------------------ CV component related stuff ------------------ */

  const [candidateInfo, setCandidateInfo] = useState();

  useEffect(async () => {
    const jsonData = {
      Email: email,
      CompanyAssessmentKey: id
    };
    const resp = await dispatch(getCandidateInfoFromEmailAction(jsonData));
    setCandidateInfo(resp?.payload);
    const innerPhone = resp.payload.phone;

    if (innerPhone && !innerPhone.startsWith('+')) {
      setInnerPhone(`+${innerPhone}`);
    }
  }, []);

  /* ------------------ CV component related stuff ------------------ */

  /* ------------------------- Mixpanel related methods -------------------------*/
  const assessmentTitle = useSelector(AssessmentTitle);
  const cvPath = useSelector(CvPath);

  useEffect(() => {
    if (candidateInfo) {
      // To ensure that pageviw event is posted after candidate data is fetched
      mixpanel.track('Unpublished Candidate Result - Pageview', {
        'Application Flag': !!application,
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Is CV Needed Flag': candidateInfo.cvCheck,
        'CV Flag': !!cvPath,
        'Already Scored Flag': candidateInfo.isAlreadyPassed,
        'NewOpp Flag': true
      });
    }
  }, [candidateInfo]);

  /* ------------------------- Mixpanel related methods -------------------------*/

  const screeningLoadingFlag = useSelector(screeningLoadingRedux);
  const [screeningCompleted, setScreeningCompleted] = useState(false);

  useEffect(() => {
    if (isNewOpCompleted) {
      // Email = 10,
      // EncryptUserId = 20,
      // EncryptEmail = 30
      const data = {
        UserInfo: email,
        UserInfoType: 10
      };

      dispatch(getScreeningQuestionsAction(data));
    }
  }, [isNewOpCompleted]);

  return (
    <ContentBody>
      <PageWrapper>
        <Ellipse />
        <div style={{ zIndex: 1 }}>
          <Header transparent expired />
        </div>
        <ContentWrapper>
          {!screeningCompleted && isNewOpCompleted ? (
            <ScreeningCardComponent closeScreening={() => setScreeningCompleted(true)} application={application} />
          ) : !screeningCompleted && !isNewOpCompleted ? (
            <div className={styles.NewOpWrapper}>
              <div className={styles.NewOpInfoWrapper}>
                <div className={styles.NewOpInfoHeader}>
                  It’s your <span style={{ color: '#7458D3' }}>lucky</span> day!{' '}
                </div>
                <div className={styles.NewOpInfoSubtitle}>
                  {companyName} is no longer looking for{' '}
                  <span style={{ color: '#DE4747' }}>{rootAssessmentName}</span>. However, these
                  companies still do!
                </div>
                <div className={styles.NewOpInfoSubtitle}>
                  You can apply to them with just one click. 🚀
                </div>
              </div>
              <NewOpComponent
                id={id}
                newOpCompanyList={newOpCompanyList}
                application={application}
                collapseNewOp={setIsNewOpCompleted}
                setParentSelectedList={setSelectedNewOpCompList}
                path={path}
                scoredFlagForUnpublishedAssessmentCandidate={candidateInfo?.isAlreadyPassed}
              />
            </div>
          ) : (
            <div className={styles.NewOpWrapper}>
              {!closeWindowFlag ? (
                <>
                  {/* Scored + CV'li + one selected company */}
                  {candidateInfo.cvPath &&
                    candidateInfo.isAlreadyPassed &&
                    selectedNewOpCompList.length === 1 && (
                      <div className={styles.NewOpInfoWrapper}>
                        <div className={styles.NewOpInfoHeader}>Great News!</div>
                        <div className={styles.NewOpInfoSubtitle}>
                          You solved this assessment for another company before. We shared your
                          score with {selectedNewOpCompList[0]}.
                        </div>
                        <div className={styles.NewOpInfoSubtitle}>
                          Now you&apos;re closer than ever to the next level!
                        </div>
                      </div>
                    )}

                  {/* Scored + CV'li + more than one selected company */}
                  {candidateInfo.cvPath &&
                    candidateInfo.isAlreadyPassed &&
                    selectedNewOpCompList.length > 1 && (
                      <div className={styles.NewOpInfoWrapper}>
                        <div className={styles.NewOpInfoHeader}>Great News!</div>
                        <div className={styles.NewOpInfoSubtitle}>
                          You solved this assessment for another company before. We shared your
                          score with selected companies.
                        </div>
                        <div className={styles.NewOpInfoSubtitle}>
                          Now you&apos;re closer than ever to the next level!
                        </div>
                      </div>
                    )}

                  {/* Scored + Not CV + one selected company */}
                  {!candidateInfo.cvPath &&
                    candidateInfo.isAlreadyPassed &&
                    selectedNewOpCompList.length === 1 && (
                      <div className={styles.NewOpInfoWrapper}>
                        <div className={styles.NewOpInfoHeader}>Great News!</div>
                        <div className={styles.NewOpInfoSubtitle}>
                          You solved this assessment for another company before. We shared your
                          score with {selectedNewOpCompList[0]}.
                        </div>
                        <div className={styles.NewOpInfoSubtitle}>
                          Complete the missing part of your application and get closer to the next
                          level!
                        </div>
                      </div>
                    )}

                  {/* Scored + Not CV + more than one selected company */}
                  {!candidateInfo.cvPath &&
                    candidateInfo.isAlreadyPassed &&
                    selectedNewOpCompList.length > 1 && (
                      <div className={styles.NewOpInfoWrapper}>
                        <div className={styles.NewOpInfoHeader}>Great News!</div>
                        <div className={styles.NewOpInfoSubtitle}>
                          You solved this assessment for another company before. We shared your
                          score with selected companies.
                        </div>
                        <div className={styles.NewOpInfoSubtitle}>
                          Complete the missing part of your application and get closer to the next
                          level!
                        </div>
                      </div>
                    )}

                  {/* Not Scored + CV'li */}
                  {candidateInfo.cvPath && !candidateInfo.isAlreadyPassed && (
                    <div className={styles.NewOpInfoWrapper}>
                      <div className={styles.NewOpInfoHeader}>Application Received!</div>
                      {/* <div className={styles.NewOpInfoSubtitle} style={{ color: '#DE4747' }}>
                        We received your application!
                      </div> */}
                      <div className={styles.NewOpInfoSubtitle}>
                        Check your inbox, solve the assessment, and show yourself!
                      </div>
                    </div>
                  )}

                  {/* Not Scored + Not CV */}
                  {!candidateInfo.cvPath && !candidateInfo.isAlreadyPassed && (
                    <div className={styles.NewOpInfoWrapper}>
                      <div className={styles.NewOpInfoHeader}>Application Received!</div>
                      {/* <div className={styles.NewOpInfoSubtitle} style={{ color: '#DE4747' }}>
                        We received your application!
                      </div> */}
                      <div className={styles.NewOpInfoSubtitle}>
                        Complete the missing part of your application and get closer to the next
                        level!
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.NewOpInfoWrapper}>
                  <div className={styles.NewOpInfoHeader}>Congrats!</div>
                  <div className={styles.NewOpInfoSubtitle}>
                    You increased your chances by applying to {selectedNewOpCompList.length} compan
                    {selectedNewOpCompList.length > 1 ? 'ies' : 'y'}.
                  </div>
                  <div className={styles.NewOpInfoSubtitle}>
                    Now, you&apos;re closer than ever to the next level!
                  </div>
                </div>
              )}
              <CvCardComponent
                id={id}
                cardState={cardState}
                setCardState={setCardState}
                path={path}
                setPath={setPath}
                innerPhone={innerPhone}
                setParentSavedState={setCloseWindowFlag}
              />
              {closeWindowFlag && !cardState && (
                <div className={styles.closeWindow}>
                  <span>You can close this window. 👋🏼</span>
                </div>
              )}
            </div>
          )}
        </ContentWrapper>
      </PageWrapper>
    </ContentBody>
  );
};

export default UnpublishedNewOpComponent;

const ContentBody = styled.div`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: #fafafa;
  position: relative;
  display: block;
  overflow-x: hidden;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: flex-start;
  display: flex;
  z-index: 0;
  flex-direction: column;
`;

const Ellipse = styled.div`
  width: 120vw;
  position: absolute;
  z-index: 1;
  transform: rotate(180deg);
  height: 40vh;
  background: #f1eff7;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  left: -10vw;
  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    border-radius: 100% / 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 60%;
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px;
  max-width: 700px;
  padding: 0.4rem 2.6rem 2.6rem 2.6rem; // FIXME
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: fit-content;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    max-width: none;
    min-width: 0;
    width: 95%;
    padding: 2.4rem;
  }
`;
