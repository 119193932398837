import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import linkIcon from '../../images/LinkedinCand.svg';
import mailIcon from '../../images/mailIcon.svg';
import saveIcon from '../../images/saveIcon.svg';
import telIcon from '../../images/telIcon.svg';
import exportIcon from '../../images/exportIcon.svg';
import { UserContext } from '../Pages/CandidatePage';
import infoIcon from '../../images/cvTooltipIcon.svg';
import styles from '../Styles/InfoBlockWrapper.module.css';
// import { getCandidateInformationAction } from '../Api/action';
import greyLinkedIn from '../../images/assessmentDetail/linkedIn.svg';
import Skeleton from '../../Common/Components/Skeleton';
import GoBackButton from '../../Common/Components/GoBackButton';
import ToolTip from '../../Common/Components/Tooltip';
import ShortText from '../../Common/Components/ShortText';
import { reduxDefaultAssessmentId } from '../../redux/FilterDetail/selectors';
import { setEmail, setID } from '../../redux/FilterDetail/slice';
import { candidateListSelector } from '../../redux/assessmentDetailVol2/selectors';
import { setSelectedCandidateList } from '../../redux/assessmentDetailVol2/slice';
import { getCandidateInfoAction } from '../../redux/candidate/actions';
import { getCandidatePublicInfo } from '../../redux/CandidatePublic/selector';

function InfoBlockWrapper({ setPhoneVisibility }) {
  const dispatch = useDispatch();
  const reduxInfo = useSelector(getCandidatePublicInfo);

  const candidateEmail = useContext(UserContext) ? useContext(UserContext)[0] : reduxInfo?.email;
  const selectedAssessmentId = useContext(UserContext) ? useContext(UserContext)[2][0] : null;
  const candID = useContext(UserContext) ? useContext(UserContext)[4] : null;
  const defaultAssessmentId =
    useSelector(reduxDefaultAssessmentId) || localStorage.getItem('defaultAssessmentId');

  const [candidateInfo, setCandidateInfo] = useState();
  const [cvPresent, isCvPresent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cvPath, setCvPath] = useState();
  const [linkedinPath, setLinkedinPath] = useState();
  const [visiblePhoneArea, setVisiblePhoneArea] = useState();
  const candidates = useSelector(candidateListSelector);
  // const [index, setIndex] = useState(0);
  const myHistory = useHistory();

  const publicPageCheck = () => {
    if (reduxInfo) {
      setCandidateInfo(reduxInfo);
      isCvPresent(reduxInfo?.cvPath !== null && reduxInfo?.cvPath !== '');
      setLoading(false);
    }
  };

  useEffect(() => {
    publicPageCheck();
  });

  // Mixpanel
  const setCandidateInfos = useContext(UserContext) ? useContext(UserContext)[6] : () => {};
  const setCandidateAssessmentsInfo = useContext(UserContext)
    ? useContext(UserContext)[7]
    : () => {};

  useEffect(async () => {
    // If not public page
    if (candID) {
      setLoading(true);
      const jsonData = {
        Email: candidateEmail,
        companyAssessmentId: selectedAssessmentId
      };
      // const result = await dispatch(getCandidateInformationAction(jsonData));
      const result = await dispatch(getCandidateInfoAction(jsonData));

      setCandidateInfo(result.payload);
      if (result.payload.cvPath !== null && result.payload.cvPath !== '') {
        isCvPresent(true);
      } else {
        isCvPresent(false);
      }
      setLoading(false);

      dispatch(setSelectedCandidateList([{ userId: candID }]));
    }
  }, [candidateEmail, candID]);

  useEffect(() => {
    if (candidateInfo) {
      // Cv Path update
      let cvpath = candidateInfo.cvPath;
      if (cvpath) {
        cvpath = cvpath.replace('https://api.coens.io', '');
        cvpath = cvpath.replace('https://coensioapi.solvera.com.tr', '');
        setCvPath(`${process.env.REACT_APP_IMAGE_API}${cvpath}`);
      }

      // Linkedin Path update
      const linkedinpath = candidateInfo.linkedIn;
      if (
        linkedinpath &&
        linkedinpath !== 'Null' &&
        linkedinpath !== 'NULL' &&
        linkedinpath.length > 0
      ) {
        if (linkedinpath.startsWith('https://www.linkedin')) {
          setLinkedinPath(linkedinpath);
        } else if (linkedinpath.startsWith('www.linkedin')) {
          let temp = '';
          temp = temp.concat('https://');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else if (linkedinpath.startsWith('linkedin')) {
          let temp = '';
          temp = temp.concat('https://www.');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        } else {
          let temp = '';
          temp = temp.concat('https://www.linkedin.com/in/');
          temp = temp.concat(linkedinpath);
          setLinkedinPath(temp);
        }
      } else {
        setLinkedinPath(null);
      }

      if (!candidateInfo.phone || candidateInfo.phone === '' || candidateInfo.phone === 'None') {
        setVisiblePhoneArea(false);
        setPhoneVisibility(false);
      } else {
        setVisiblePhoneArea(true);
        setPhoneVisibility(true);
      }

      // Mixpanel
      setCandidateInfos(candidateInfo);
    }
  }, [candidateInfo, candidateEmail, candID]);

  useEffect(() => {
    console.log(candidates);
  }, [candidates]);

  function getPrevIndex() {
    // eslint-disable-next-line consistent-return
    candidates?.forEach((element, cindex) => {
      if (element.userId === candID) {
        if (cindex - 1 >= 0) {
          myHistory.replace(`/candidate-detail/${candidates[cindex - 1].userId}`);
          setCandidateAssessmentsInfo();
          setCandidateInfos();
          dispatch(setID(candidates[cindex - 1].userId));
          dispatch(setEmail(candidates[cindex - 1].email));
          localStorage.setItem('candidateEmail', candidates[cindex - 1].email);
          localStorage.setItem('candId', candidates[cindex - 1].userId);
        }
      }
    });
  }

  function getNextIndex() {
    // eslint-disable-next-line consistent-return
    candidates?.forEach((element, cindex) => {
      if (element.userId === candID) {
        if (cindex + 1 < candidates.length) {
          myHistory.replace(`/candidate-detail/${candidates[cindex + 1].userId}`);
          setCandidateAssessmentsInfo();
          setCandidateInfos();
          dispatch(setID(candidates[cindex + 1].userId));
          dispatch(setEmail(candidates[cindex + 1].email));
          localStorage.setItem('candidateEmail', candidates[cindex + 1].email);
          localStorage.setItem('candId', candidates[cindex + 1].userId);
        }
      }
    });
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backButtonInfoWrapper}>
        <div className={styles.goBack}>
          {reduxInfo ? (
            <div style={{ width: '34px' }} />
          ) : (
            <GoBackButton
              onClick={() => {
                history.back();
              }}
              style={{ marginRight: '0px' }}
            />
          )}
        </div>
        <div className={styles.infoWrapper}>
          {reduxInfo ? null : (
            <div className={styles.candidateSwitch}>
              <button
                type="button"
                disabled={
                  !candidates ||
                  candidates[0].userId === candID ||
                  defaultAssessmentId !== selectedAssessmentId
                }
                className={styles.switchButton}
                onClick={() => {
                  getPrevIndex();
                }}
              >
                Previous Candidate
              </button>
              <button
                type="button"
                disabled={
                  !candidates ||
                  candidates[candidates.length - 1].userId === candID ||
                  defaultAssessmentId !== selectedAssessmentId
                }
                className={`${styles.switchButton} ${styles.right}`}
                onClick={() => {
                  getNextIndex();
                }}
              >
                Next Candidate
              </button>
            </div>
          )}

          {/*  */}
          <div className={styles.nameWrapper}>
            {loading ? (
              <Skeleton type="name" />
            ) : candidateInfo && candidateInfo?.name ? (
              <ShortText
                name={`${candidateInfo.name
                  .concat(' ')
                  .concat(candidateInfo.surname ? candidateInfo.surname : '')}`}
                textClassName={styles.nameText}
                type={1}
              />
            ) : (
              candidateInfo.email && (
                <ShortText name={candidateInfo.email} textClassName={styles.nameText} />
              )
            )}
            {linkedinPath ? (
              <a href={linkedinPath} target="_blank" rel="noreferrer" className={styles.linkIcon}>
                <img alt="" src={linkIcon} width="26px" height="26px" />
              </a>
            ) : (
              <img
                alt=""
                src={greyLinkedIn}
                className={styles.linkIcon}
                width="26px"
                height="26px"
              />
            )}
          </div>
          {/*  */}
          <div className={styles.infoText}>
            <img alt="" src={mailIcon} className={styles.icon} />

            {loading ? (
              <Skeleton type="email" />
            ) : (
              candidateInfo &&
              candidateInfo.email && <ShortText name={candidateInfo.email} type={0} />
            )}
          </div>

          {loading ? (
            <div className={styles.infoText}>
              <img alt="" src={telIcon} className={styles.icon} />
              <Skeleton type="email" />
            </div>
          ) : (
            visiblePhoneArea && (
              <div className={styles.infoText}>
                <img alt="" src={telIcon} className={styles.icon} />
                {candidateInfo && candidateInfo.phone && (
                  <ShortText name={candidateInfo.phone} type={0} />
                )}
              </div>
            )
          )}
        </div>
      </div>

      {loading ? (
        <Skeleton type="cv" />
      ) : (
        <div className={styles.cvWrapper}>
          <img
            alt=""
            src={saveIcon}
            className={styles.icon}
            style={!cvPresent ? { opacity: 0.2 } : { opacity: 1 }}
          />
          <span className={styles.cvText} style={!cvPresent ? { opacity: 0.2 } : { opacity: 1 }}>
            CV
          </span>

          {cvPresent ? (
            <a href={cvPath} target="_blank" rel="noreferrer">
              <img alt="" src={exportIcon} className={styles.icon} />
            </a>
          ) : (
            <ToolTip
              position="right"
              text="The reminder email has been sent to the candidate. Please wait for the candidate to upload a CV."
            >
              <img alt="" src={infoIcon} className={styles.icon} />
            </ToolTip>
          )}
        </div>
      )}
    </div>
  );
}

export default InfoBlockWrapper;
