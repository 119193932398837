export const planLoadingRedux = state => state.plan.loading;
export const countriesRedux = state => state.plan.countries;
export const currentPlanRedux = state => state.plan.currentPlan;
export const currentUsageRedux = state => state.plan.currentUsage;
export const addOnsRedux = state => state.plan.addOns;
export const caAddOnsRedux = state => state.plan.caAddOns;
export const asAddOnsRedux = state => state.plan.asAddOns;

export const packageDetailRedux = state => state.plan.packageDetail;
export const billingInfoRedux = state => state.plan.billingInfo;
export const unsubscribeModalShow = state => state.plan.unsubscribeModalShow;
export const planUnsubscribedModalShow = state => state.plan.planUnsubscribedModalShow;
export const unsubscribeAddOnModalShow = state => state.plan.unsubscribeAddOnModalShow;
// export const toBeCancelledAddOn = state => state.plan.toBeCancelledAddOn;
export const unsubscribeSuccessShow = state => state.plan.unsubscribeSuccessShow;
export const cardInfoRedux = state => state.plan.cardInfo;
export const purchaseModalPackageIdRedux = state => state.plan.purchaseModalPackageId;
export const purchaseModalShowRedux = state => state.plan.purchaseModalShow;
export const editBillingModalShow = state => state.plan.editBillingModalShow;
export const paymentMethodModalShow = state => state.plan.paymentMethodModalShow;
export const plansDetailJSON = state => state.plan.plansDetailJSON;
export const contactSalesSuccess = state => state.plan.contactSalesSuccess;
export const toBeCancelledAddOnType = state => state.plan.toBeCancelledAddOnType;
export const ShowPackageDetail = state => state.plan.showPackageDetail;
export const downgradeModalShow = state => state.plan.downgradeModalShow;
export const downgradeSuccess = state => state.plan.downgradeSuccess;
export const upgradeModalShow = state => state.plan.upgradeModalShow;
export const upgradeSuccess = state => state.plan.upgradeSuccess;
export const paymentFailedShow = state => state.plan.paymentFailedShow;
export const editCompanyInfoShow = state => state.plan.editCompanyInfoShow;

export const profileObjSelector = state => state.plan.profileObj;

export const unsubscribeAddOnInfoCountRedux = state => state.plan.unsubscribeAddOnInfoCount;
export const unsubscribeAddOnItemCodeRedux = state => state.plan.unsubscribeAddOnItemCode;
export const premiumQControlResultredux = state => state.plan.premiumQControlResult;

export const errorInPlan = state => state.plan.errorInPlan;
export const errorTextInPlan = state => state.plan.errorTextInPlan;

export const errorInSeePlans = state => state.plan.errorInSeePlans;
export const errorTextInSeePlans = state => state.plan.errorTextInSeePlans;

export const abortDowngradeSuccessShow = state => state.plan.abortDowngradeSuccessShow;

export const toBePurchasedAddOnRedux = state => state.plan.toBePurchasedAddOn;
export const premiumQuestionCheckRedux = state => state.plan.premiumQuestionCheck;
export const purchaseSuccessShowRedux = state => state.plan.purchaseSuccessShow;
export const purchasedAddOnCodeRedux = state => state.plan.purchasedAddOnCode;
export const paymentFailedErrorMessageRedux = state => state.plan.paymentFailedErrorMessage;
export const isAuthorizedCRredux = state => state.plan.isAuthorizedCR;

export const invoiceListRedux = state => state.plan.invoiceList;
export const onboardingStepsRedux = state => state.plan.onboardingSteps;
export const completedOnboardingNoRedux = state => state.plan.completedOnboardingNo;
export const welcomeOnboardShowRedux = state => state.plan.welcomeOnboardShow;
export const onboardingStepRedux = state => state.plan.onboardingStep;

export const logoHighlight = state => state.plan.logoHighlight;
export const companyHighlight = state => state.plan.companyHighlight;
export const addAssessmentHighlight = state => state.plan.addAssessmentHighlight;
export const teamHighlight = state => state.plan.teamHighlight;
export const addLogoBoxHighlight = state => state.plan.addLogoBoxHighlight;
export const addNewUserHighlight = state => state.plan.addNewUserHighlight;
export const searchBarHighlight = state => state.plan.searchBarHighlight;
export const coensioLibHighlight = state => state.plan.coensioLibHighlight;
export const customAssessmentHighlight = state => state.plan.customAssessmentHighlight;
export const quickStartOpen = state => state.plan.quickStartOpen;
export const popupFlowEnded = state => state.plan.popupFlowEnded;
export const stepsReady = state => state.plan.stepsReady;
export const isFirstAssm = state => state.plan.isFirstAssm;
