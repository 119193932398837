import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../../Common/Components/CustomButton';
import {
  candidateSolutionsSelector,
  loadingForSolutionsSelector,
  priceForUnlockSelector,
  solutionAuthorizeSelector
} from '../../../../redux/candidateDetail/selectors';
import styles from './AssessmentReport.module.css';
import QuestionBar from './QuestionBar';
import QuestionReport from './QuestionReport';
import lockedIcon from './lockedIcon.svg';
import unlockedIcon from './unlockedIcon.svg';
import { currentPlanRedux } from '../../../../redux/plan/selectors';
import { getParam } from '../../../../utils/helpers';
import HeadsUpModal from '../../../../Auth/Modals/HeadsUpModal';
import FreeTrialModal from '../../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import PurchaseAddOnSuccessModal from '../../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import ContactSalesSuccess from '../../../../Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import { unlockCandidateReportAction } from '../../../../redux/plan/actions';
import { setToBePurchasedAddOn } from '../../../../redux/plan/slice';
import Skeleton from '../../../../Common/Components/Skeleton';
import AssessmentSummary from './AssessmentSummary';

const AssessmentReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const solutionList = useSelector(candidateSolutionsSelector);
  const isAuthorizedFlag = useSelector(solutionAuthorizeSelector);
  const priceForUnlock = useSelector(priceForUnlockSelector);
  const loading = useSelector(loadingForSolutionsSelector);
  const myPlan = useSelector(currentPlanRedux);

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [purchasedSuccess, setPurchasedSuccess] = useState(false);
  const [continuePurchase, setContinuePurchase] = useState(false);

  // TODO: new endpoint with userId
  async function unlockFree() {
    const data = {
      userId: id,
      companyAssessmentId: getParam(location.search, 'currentAssessmentId')
    };

    const a = await dispatch(unlockCandidateReportAction(data));
    if (a?.meta?.requestStatus === 'fulfilled') {
      await setPurchasedSuccess(true);
    } else {
      setContinuePurchase(true);
    }
  }

  const unlockMethod = () => {
    if (myPlan?.name === 'Free Trial') {
      unlockFree();
    } else {
      setPaymentModal(true);
    }
  };

  // TODO: purchaseAddOnAction with userId
  useEffect(() => {
    let data = {};
    if (myPlan?.name !== 'Free Trial') {
      data = {
        bundleItemCode: 30,
        userId: id,
        companyAssessmentId: getParam(location.search, 'currentAssessmentId')
      };
    } else {
      data = {
        userId: id,
        companyAssessmentId: getParam(location.search, 'currentAssessmentId')
      };
    }
    dispatch(setToBePurchasedAddOn(data));
  }, []);

  return (
    <div className={styles.Wrapper}>
      <AssessmentSummary />
      <div className={styles.ReportWrapper}>
        <text className={styles.ReportText}>Report</text>
        {solutionList?.length !== 0 ? (
          <div className={styles.QuestionWithHeader}>
            {loading ? (
              <Skeleton type="candidateDetail-assessmentReport" />
            ) : (
              <>
                <QuestionBar />
                {!isAuthorizedFlag ? (
                  <div className={styles.lockPosition}>
                    <LockIcon onClickBehavior={unlockMethod} />
                  </div>
                ) : null}

                <div
                  className={`${styles.QuestionListWrapper} ${
                    !isAuthorizedFlag ? styles.blurredWrapper : ''
                  }`}
                >
                  {solutionList?.map((e, i) => (
                    <QuestionReport qInfo={e} index={i} />
                  ))}
                </div>
              </>
            )}
          </div>
        ) : (
          <text className={styles.NotScoredText}>
            This candidate has not started the assessment yet. There are no results to show.
          </text>
        )}
      </div>

      <>
        <HeadsUpModal
          addOnCode={30}
          show={paymentModal}
          onHide={() => setPaymentModal(false)}
          title={myPlan?.isCustom ? 'Hello there!' : 'Want to unlock?'}
          description={
            !myPlan?.isCustom
              ? `Unlock this candidate report for only $${priceForUnlock}.`
              : `Please contact our sales team to gain more insight of the candidates by seeing their answers.`
          }
          isCustom={myPlan?.isCustom}
          setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchasedSuccess}
        />

        <FreeTrialModal
          show={continuePurchase}
          onHide={() => {window.dispatchEvent(new Event('locationchange')); setContinuePurchase(false);}}
          addOnCode={30}
        />
        {/* Purchase AddOn Success */}
        <PurchaseAddOnSuccessModal
          show={purchasedSuccess}
          onHide={() => {window.dispatchEvent(new Event('locationchange')); setPurchasedSuccess(false);}}
          addOnCode={30}
        />
        <ContactSalesSuccess
          icon="tick"
          show={contactSalesSuccessShow}
          onHide={() => setContactSalesSuccessShow(false)}
          title="Your request has been received!"
          description="One of our experts will contact you shortly."
        />
      </>
    </div>
  );
};
export default AssessmentReport;



const LockIcon = ({ onClickBehavior = () => {} }) => {
  const [hoverState, setHoverState] = useState(true);

  return (
    <div
      className={styles.LockIconWrapper}
      onMouseOver={() => setHoverState(false)}
      onMouseOut={() => setHoverState(true)}
    >
      <img src={hoverState ? lockedIcon : unlockedIcon} alt="lockIcon" />
      <CustomButton
        type={1}
        size="medium"
        buttonOnClick={onClickBehavior}
        textField="Click to Unlock Report"
      />
    </div>
  );
};