import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CoensioLogo } from '../../../images/Coensio_newLogo.svg';
import styles from './CreateNewPasswordComponent.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import PasswordInput from '../../../Common/Components/PasswordInput';
import { refreshPasswordAction } from '../../../redux/signup/actions';
import { getDomainProtocol, getOnlyDomain } from '../../../utils/helpers';

const CreateNewPasswordComponent = ({ ...props }) => {
  const { search } = useLocation();
  const objId = new URLSearchParams(search).get('objId');
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordAgainError, setPasswordAgainError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateNewPassword = async () => {
    setLoading(true);
    const data = {
      objId,
      password,
      passwordAgain
    };
    const resp = await dispatch(refreshPasswordAction(data));
    if (resp.meta.requestStatus === 'fulfilled') {
      if (resp?.payload?.accessToken) {
        localStorage.setItem('authToken', resp?.payload?.accessTokenV1);
        localStorage.setItem('authTokenVol2', resp?.payload?.accessToken);
        setTimeout(() => {
          window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/assessments`;
        }, 100);
      } else {
        setTimeout(() => {
          window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/login`;
        }, 100);
      }
    } else if (!resp.payload.message.includes('Different passwords')) {
      props.setIsExpired(true);
    }
    setLoading(false);
  };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.outerCard}>
          <div className={styles.innerCard}>
            <CoensioLogo className={styles.logo} />
            <label className={styles.cardTitle}>Reset Password</label>
            <label className={styles.text}>Please enter new password</label>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
              <PasswordInput
                value={password}
                secondPasswordForPasswordField={passwordAgain}
                placeholder="New password*"
                onChange={e => setPassword(e.target.value)}
                setParentErrorState={setPasswordError}
                showInvisibleError
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '0.5rem' }}>
              <PasswordInput
                value={passwordAgain}
                isPasswordAgain
                firstPasswordForPasswordAgainField={password}
                placeholder="New Password Again*"
                onChange={e => setPasswordAgain(e.target.value)}
                setParentErrorState={setPasswordAgainError}
                showInvisibleError
              />
            </div>
            <CustomButton
              loading={loading}
              buttonOnClick={handleCreateNewPassword}
              isDisabled={passwordError || passwordAgainError || !password || !passwordAgain}
              customStyle={{ width: '100%' }}
              textField="Create New Password"
              enterEnabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewPasswordComponent;
