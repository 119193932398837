import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginVol2 } from '../../Assessment/AddAssessment/Api/service';

import { getCompanyProfileService, getUpgradePricesService, updateCompanyProfileService } from './service';

import {
  changeCompanyService,
  getTeamService,
  makeAdminService,
  removeFromAdminsService,
  editTeamMemberService,
  deleteTeamMemberService,
  getCurrentUserDetailService,
  getCompanyListService
} from './serviceVol2';

export const loginVol2Action = createAsyncThunk('auth/login2', async (data, thunkAPI) => {
  try {
    const response = await loginVol2(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const setLoginAction = createAsyncThunk('auth/setlogin', async (data, thunkAPI) => true);

export const getCurrentUserDetailAction = createAsyncThunk(
  'auth/currentUserDetail',
  async (data, thunkAPI) => {
    try {
      const response = await getCurrentUserDetailService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyListAction = createAsyncThunk('auth/getCompanyList', async (data, thunkAPI) => {
  try {
    const response = await getCompanyListService(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const changeCompanyAction = createAsyncThunk(
  'auth/changeCompanyAction',
  async (data, thunkAPI) => {
    try {
      const a = await changeCompanyService(data);
      return a.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyProfileAction = createAsyncThunk(
  'auth/getCompanyProfile',
  async (data, thunkAPI) => {
    try {
      return await getCompanyProfileService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCompanyProfileAction = createAsyncThunk(
  'auth/updateCompanyProfile',
  async (data, thunkAPI) => {
    try {
      return await updateCompanyProfileService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTeamAction = createAsyncThunk('auth/getTeam', async (data, thunkAPI) => {
  try {
    return await getTeamService(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const makeAdminAction = createAsyncThunk('auth/makeAdmin', async (data, thunkAPI) => {
  try {
    return await makeAdminService(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const removeFromAdminsAction = createAsyncThunk(
  'auth/removeFromAdmins',
  async (data, thunkAPI) => {
    try {
      return await removeFromAdminsService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editTeamMemberAction = createAsyncThunk(
  'auth/editTeamMember',
  async (data, thunkAPI) => {
    try {
      return await editTeamMemberService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTeamMemberAction = createAsyncThunk(
  'auth/deleteTeamMember',
  async (data, thunkAPI) => {
    try {
      return await deleteTeamMemberService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUpgradePricesAction = createAsyncThunk(
  'auth/getUpgradePrices',
  async (data, thunkAPI) => {
    try {
      return await getUpgradePricesService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
