export default {
  loading: false,
  cardInfo: null,
  countries: null,
  currentPlan: null,
  currentUsage: null,
  addOns: null,
  packageDetail: null,
  billingInfo: null,
  unsubscribeModalShow: false,
  unsubscribeAddOnModalShow: false,
  // toBeCancelledAddOn: [],
  unsubscribeSuccessShow: false,
  purchaseModalPackageId: {},
  purchaseModalShow: false,
  planUnsubscribedModalShow: false,
  editBillingModalShow: false,
  paymentMethodModalShow: '',
  plansDetailJSON: null,
  contactSalesSuccess: false,
  toBeCancelledAddOnType: '',
  showPackageDetail: false,
  downgradeModalShow: false,
  downgradeSuccess: false,
  upgradeModalShow: false,
  upgradeSuccess: false,
  paymentFailedShow: false,
  editCompanyInfoShow: false,
  profileObj: null,
  unsubscribeAddOnInfoCount: null,
  unsubscribeAddOnItemCode: null,
  premiumQControlResult: null,
  errorInPlan: false,
  errorTextInPlan: '',
  errorInSeePlans: false,
  errorTextInSeePlans: '',
  caAddOns: [],
  asAddOns: [],
  abortDowngradeSuccessShow: false,
  premiumQuestionCheck: {},
  toBePurchasedAddOn: {},
  purchaseSuccessShow: false,
  paymentFailedErrorMessage: '',
  invoiceList: {},
  onboardingSteps: {
    createAssessment: null,
    inviteTeammates: null,
    addLogo: null,
    customAssessment: null
  },
  completedOnboardingNo: 1,
  welcomeOnboardShow: false,
  onboardingStep: 0,
  logoHighlight: false,
  companyHighlight: false,
  addAssessmentHighlight: false,
  teamHighlight: false,
  addLogoBoxHighlight: false,
  addNewUserHighlight: false,
  searchBarHighlight: false,
  coensioLibHighlight: false,
  customAssessmentHighlight: false,
  quickStartOpen: true,
  popupFlowEnded: false,
  stepsReady: false,
  isFirstAssm: false
};
