import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../../Common/Components/Header';
import AssessmentBody from '../Components/AssessmentBody';
import { setInitial } from '../../../redux/FilterDetail/slice';

function AssessmentsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitial());
  }, []);

  return (
    <div>
      <div>
        <Header />
        <AssessmentBody />
      </div>
    </div>
  );
}

export default AssessmentsPage;
