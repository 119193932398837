import React, { useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/FilterItem.module.css';
import { ReactComponent as DropDown } from '../../../Candidate/Images/arrow_assessment.svg';
import Checkbox from '../../../Common/Components/Checkbox';
import { setFilterState } from '../../../redux/assessmentsVol2/slice';
import { customAssessmentId, filterState } from '../../../redux/assessmentsVol2/selectors';
import ShortText from '../../../Common/Components/ShortText';

const FilterType = ({ name, amount, setAmount, extented }) => {
  const [checkBoxState, setCheckBoxState] = React.useState(false);
  const dispatch = useDispatch();
  const filter = useSelector(filterState);
  const id = useSelector(customAssessmentId);

  useEffect(() => {
    if (filter?.skills.indexOf(name) === -1) {
      setCheckBoxState(false);
    } else {
      setCheckBoxState(true);
    }
  }, [filter]);

  const onClickBehavior = () => {
    let temp = [];
    if (checkBoxState) {
      temp = [...filter.skills];
      temp.splice(temp.indexOf(name), 1);
    }
    const data = {
      ...filter,
      currentPage: 1,
      rootAssessmentId: id,
      skills: checkBoxState ? temp : [...filter.skills, name]
    };

    dispatch(setFilterState(data));
  };

  return (
    <div className={`${styles.typeContainer} ${extented ? styles.typeExtented : ''}`}>
      <Checkbox myChecked={checkBoxState} myOnClick={onClickBehavior} />
      <div className={styles.typeText} onClick={onClickBehavior} style={{ cursor: 'pointer' }}>
        <ShortText name={name} type={1} />
      </div>
    </div>
  );
};

export default function FilterItem({ item, index, isExtended, searchTerm }) {
  const [dropDownState, setDropDownState] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const filter = useSelector(filterState);
  const containerRef = useRef();

  useEffect(() => {
    setDropDownState(false);
  }, [isExtended]);

  useEffect(() => {
    if (filter) {
      let temp = 0;
      // eslint-disable-next-line no-return-assign
      item[1].forEach(x => (filter.skills.indexOf(x) !== -1 ? (temp += 1) : null));
      setAmount(temp);
    }
  }, [filter]);

  return (
    <div className={`${styles.mainContainer} ${dropDownState ? styles.extentedMain : ''}`}>
      <div
        className={styles.container}
        onClick={() => {
          setDropDownState(!dropDownState);
        }}
      >
        <div className={styles.item}>{item[0]}</div>
        <div className={styles.rightColumn}>
          {amount > 0 && <div className={styles.amountText}>{amount}</div>}
          <DropDown className={`${styles.icon} ${dropDownState ? styles.extented : ''}`} />
        </div>
      </div>
      <div
        ref={containerRef}
        className={`${styles.mainTypeContainer} ${
          dropDownState && styles.extentedMainTypeContainer
        }`}
      >
        {item[1].map(label => (
          <>
            {(label.toLocaleLowerCase('tr').includes((searchTerm?.length >= 2 ? searchTerm : '').toLocaleLowerCase('tr')) ||
              item[0].toLocaleLowerCase('tr').includes((searchTerm?.length >= 2 ? searchTerm : '').toLocaleLowerCase('tr'))) && (
              <FilterType
                extented={dropDownState}
                name={label}
                amount={amount}
                setAmount={setAmount}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
}
