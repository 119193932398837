import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-dropdown';
import MonacoEditor from '@monaco-editor/react';
import { nextButtonLoadingRedux } from '../../../../redux/AssessmentSession/selectors';
import styles from './codingQuestion.module.css';
import resetButton from '../../Images/refresh_outline.svg';
import { languages } from '../../../Question/Constants/codingLanguageConstants';
import { runCodeTestPageAction } from '../../../../redux/AssessmentSession/actions';
import CustomButton from '../../../../Common/Components/CustomButton';
import { connectToServerByType, getFilePathFromLanguage, initializeMonacoLanguages } from './monacoEditor';

const CodingQuestion = ({
  qData,
  qDesc,
  headersHeight,
  codingAnswer,
  codingLanguage,
  codingMobileThreshold,
  screenWidth,
  selectedIndex,
  resetToInitialCode
}) => {
  const dispatch = useDispatch();

  const buttonLoading = useSelector(nextButtonLoadingRedux);

  const [compilerLang, setCompLang] = useState();

  const [editorLang,  setEditorLang] = useState('py');
  useEffect(() => {
    // Seçilmiş olan dilin gelen değerler arasında kaçıncı dil olduğunu tutmak için
    qData?.language.forEach((e, i) => {
      if (codingLanguage[0].value === e) {
        selectedIndex[1](i);
      }
    });

    if (codingLanguage[0].value === 'SQL') {
      setCompLang('sql');
    } else {
      // Monaco editor'un language değerini setlemek için
      languages.forEach(e => {
        if (e.label === codingLanguage[0].value) {
          setCompLang(e.value);
        }
      });
    }
  }, [codingLanguage[0]]);

  const [totalTestCases, setTotalTestCases] = useState(-1);
  const [passedTestCases, setPassedTestCases] = useState(-1);
  const [runCodeInfoMessage, setRunCodeInfoMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const updateAnswerForPhp = code => {
    let ret = code;
    ret = ret.replace('<?php ', '');
    ret = ret.replace('?>', '');
    return ret;
  }

  const connectToLspServer = useCallback(lang => {
    setTimeout(() => {
      connectToServerByType(lang);
    }, 500);
  }, []);

  useEffect(() => {
    connectToLspServer(editorLang);
  },  [connectToLspServer, editorLang]);

  const runCode = async () => {
    setLoading(true);
    setErrorMessage();
    const data = {
      questionId: qData.questionId,
      codingLanguage: codingLanguage[0].value,
      code: codingLanguage[0].value === "PHP" ? updateAnswerForPhp(codingAnswer[0]) : codingAnswer[0],
    };

    const resp = await dispatch(runCodeTestPageAction(data));
    if (resp.type === 'runCodeTestPage/fulfilled') {
      setTotalTestCases(resp.payload.totalCases);
      setPassedTestCases(resp.payload.passedCases);
      setRunCodeInfoMessage(resp.payload.message);
      // setRunCodeInfoMessage(`<p>${resp.payload.message}</p>`);
      setLoading(false);
    } else {
      setErrorMessage('Your code has compilation errors!');
      setLoading(false);
    }
  };

  const resetToInitial = () => {
    resetToInitialCode();
    setTotalTestCases(-1);
    setPassedTestCases(-1);
    setRunCodeInfoMessage(null);
    setErrorMessage();
    setLoading(false);
  };

  const [compilerHeight, setCompilerHeight] = useState();

  useEffect(() => {
    const compilerWrapperHeight = document.getElementById("CodingCompilerWrapper")?.getBoundingClientRect().height;
    const compilerHeaderHeight = document.getElementById("CompilerHeader")?.getBoundingClientRect().height;
    const compilerFooterHeight = document.getElementById("CompilerFooter")?.getBoundingClientRect().height;
    setCompilerHeight(compilerWrapperHeight - compilerHeaderHeight - compilerFooterHeight);
  })

  const handleEditorOnMount = (editor, monaco) => {
    initializeMonacoLanguages(monaco);
  }

  return (
    <div
      className={
        screenWidth > codingMobileThreshold
          ? styles.CodingQuestionBodyWrapper
          : styles.CodingQuestionBodyWrapperMobile
      }
      style={{
        height: `calc(100vh - ${Math.ceil(headersHeight) + 1}px)`,
        pointerEvents: buttonLoading ? 'none' : 'initial',
        filter: buttonLoading ? 'blur(4px)' : 'blur(0)'
      }}
    >
      <div
        className={
          screenWidth > codingMobileThreshold
            ? styles.CodingQuestionDescriptionWrapper
            : styles.CodingQuestionDescriptionWrapperMobile
        }
        dangerouslySetInnerHTML={{ __html: qDesc }}
        style={{ minWidth: `calc((${codingMobileThreshold}px - 2 * 4.2rem) * 0.5)` }}
      />
      <div
        id="CodingCompilerWrapper"
        className={
          screenWidth > codingMobileThreshold
            ? styles.CodingQuestionCompilerWrapper
            : styles.CodingQuestionCompilerWrapperMobile
        }
        style={{ minWidth: `calc((${codingMobileThreshold}px - 2 * 4.2rem) * 0.5)` }}
      >
        <div id="CompilerHeader" className={styles.CompilerHeader}>
          <Dropdown
            className={styles.LanguageDropdown}
            // options={qData.language}
            // value={codingLanguage[0]}
            options={[
              {label: 'Typescript', value: 'ts'},
              {label: 'Python', value: 'py'},
              {label: 'Javascript', value: 'js'},
              {label: 'C#', value: 'cs'},
              {label: 'Java', value: 'java'},
              {label: 'Kotlin', value: 'kotlin'},
              {label: 'SQL', value: 'sql'},
            ]}
            value={editorLang}
            onChange={e => {
              setEditorLang(e.value);
              codingLanguage[1](e);
            }}
          />
          <div className={styles.ResetButton} onClick={() => resetToInitial()}>
            <img src={resetButton} alt="resetInitialCode" />
            &nbsp; Reset to Initial Code
          </div>
        </div>
        <div className={styles.CompilerBodyWrapper} style={{height: compilerHeight}}>
          <MonacoEditor
            // defaultValue="Please select a coding language"
            theme="vs-dark"
            // language={compilerLang}
            value={
              codingAnswer[0] || (selectedIndex[0] === -1 && 'Please select a coding language')
            }
            onChange={e => codingAnswer[1](e)}
            options={{ wordWrap: 'on', minimap: { enabled: false } }}
            onMount={handleEditorOnMount}
            path={getFilePathFromLanguage(editorLang)}
          />
        </div>

        <div id="CompilerFooter" className={styles.CompilerFooter}>
          <div className={styles.CompilerFooterUpper}>
            Output
            <CustomButton 
              buttonOnClick={() => runCode()}
              size={screenWidth < codingMobileThreshold ? "small" : "medium"}
              type={2}
              textField="Run"
              loading={loading}
            />
            {/* <div className={styles.RunButton} onClick={() => runCode()}>
              Run
            </div> */}
          </div>
          <div className={styles.CompilerFooterBottom}>
            <div className={styles.DefaultColor}>
              {loading && 'Your code is running...'}
              {!loading && errorMessage && errorMessage}
              {/* {!loading &&
                !errorMessage &&
                passedTestCases !== -1 &&
                totalTestCases !== -1 &&
                `You passed ${passedTestCases} of ${totalTestCases} test cases\n
                ${runCodeInfoMessage}`} */}
              {!loading &&
                !errorMessage &&
                passedTestCases !== -1 &&
                totalTestCases !== -1 &&
                // <div style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: runCodeInfoMessage}} />
                <div style={{whiteSpace: "pre-wrap"}}>{runCodeInfoMessage}</div>
              }
              {!loading &&
                !errorMessage &&
                passedTestCases === -1 &&
                totalTestCases === -1 &&
                'Click run to see your output'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodingQuestion;
