import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ShortText from '../../../../Common/Components/ShortText';
import TextOverflow from '../../../../Common/TextOverflow/TextOverflow';
import { userSelector } from '../../../../redux/auth/selectors';
import {
  candidateActivitiesSelector,
  candidatePositionsSelector,
  candidateProfileSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../../redux/candidateDetail/selectors';
import { getParam, useWindowSize } from '../../../../utils/helpers';
import styles from './ActivityTab.module.css';
import { ReactComponent as Dot } from './dot.svg';
import mixpanel from '../../../../utils/mixpanel';
import { genericMixpanelCandidateDetailEventGenerator } from '../helpers';

const ActivityTab = () => {
  const activities = useSelector(candidateActivitiesSelector);
  const [emptyArray, setEmptyArray] = useState();
  const [screenW, screenH] = useWindowSize();
  const [scrollAvailable, setScrollAvailable] = useState();
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (activities?.length > 0) {
      const arrayLen = activities?.length;
      let neededEmptyRows = 0;

      if (arrayLen >= 5) {
        neededEmptyRows = 1;
      } else {
        neededEmptyRows = 5 - arrayLen;
      }
      setEmptyArray(new Array(neededEmptyRows).fill(0));
    }
  }, [activities, screenW, screenH]);

  useEffect(() => {
    const activityWrapper = document.getElementById('activity-wrapper');
    const scrollH = activityWrapper?.scrollHeight;
    const wrapperH = activityWrapper?.clientHeight;
    if (scrollH > wrapperH) {
      setScrollAvailable(true);
    } else {
      setScrollAvailable(false);
    }
  }, [screenW, hover]);

  // Mixpanel Activities - Pageview
  // #region
  const currentUser = useSelector(userSelector);
  const positionsList = useSelector(candidatePositionsSelector);
  const candidateProfile = useSelector(candidateProfileSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  useEffect(() => {
    if (
      currentUser &&
      positionsList &&
      positionsList?.length > 0 &&
      candidateProfile &&
      !loadingForPersonalInfoRedux
    ) {
      genericMixpanelCandidateDetailEventGenerator(
        'Activities - Pageview',
        currentUser,
        positionsList,
        candidateProfile,
        loadingForPersonalInfoRedux,
        personalInfoExperienceRedux,
        personalInfoNationalityRedux,
        personalInfoSalaryRedux,
        personalInfoWorkTypeRedux
      );
    }
  }, [currentUser, positionsList, candidateProfile, loadingForPersonalInfoRedux]);

  // #endregion

  return (
    <>
      {activities?.length === 0 ? (
        <div className={styles.noActivity}>No activities to see yet.</div>
      ) : (
        <div
          style={{ paddingRight: scrollAvailable && !hover ? '32px' : '20px' }}
          className={styles.wrapper}
          id="activity-wrapper"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <span className={styles.header}>Activities</span>
          <div className={styles.rowsWrapper}>
            <>
              {activities?.map((item, index) => (
                <ActivityRow id={index} activity={item} />
              ))}

              {emptyArray?.map((emptyItem, emptyIndex) => (
                <EmptyRow key={emptyIndex} />
              ))}
            </>
          </div>
        </div>
      )}
    </>
  );
};
export default ActivityTab;

const ActivityRow = ({ id, activity }) => {
  const [screenW, screenH] = useWindowSize();
  const [allowedWidth, setAllowedWidth] = useState();
  const [allowedLowerWidth, setAllowedLowerWidth] = useState();

  useEffect(() => {
    const wrapperE = document?.getElementById('activity-wrapper')?.getBoundingClientRect()?.width;
    const marginsPlusLeft = 140 + 32 + 24 + 24 + 20;
    const positionE = document
      ?.getElementById(`position-tag-${id}`)
      ?.getBoundingClientRect()?.width;
    setAllowedWidth(wrapperE - marginsPlusLeft - positionE);
    setAllowedLowerWidth(wrapperE - marginsPlusLeft);
  }, [screenW]);

  return (
    <div className={styles.rowWrapper}>
      <div className={styles.leftContainer}>
        <div className={styles.leftColumn}>
          <span className={styles.leftTextUpper}>
            {format(new Date(activity?.activityDate), 'MMM dd, yyyy')}
          </span>
          <span className={styles.leftTextBottom}>
            {format(new Date(activity?.activityDate), 'HH:mm a')}
          </span>
        </div>
        <Dot className={styles.dotImg} />
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.firstRow}>
          <TextOverflow
            itemId={`activity-text-${id}`}
            text={
              activity?.titleBy !== ' ' && activity?.titleBy
                ? activity?.title.trimEnd().concat(` by ${activity?.titleBy}`)
                : activity?.title
            }
            width={allowedWidth}
          >
            <div className={styles.wholeRow}>
              <span className={styles.boldText}>{`${activity?.title.trimEnd()}`}</span>
              <span className={styles.lightText}>
                {activity?.titleBy !== ' ' && activity?.titleBy ? ` by ${activity?.titleBy}` : null}
              </span>
            </div>
          </TextOverflow>
          <div className={styles.positionTag} id={`position-tag-${id}`}>
            <ShortText name={activity?.companyAssessmentName} staticLimit={25} />
          </div>
        </div>

        <div className={styles.secondRow}>
          <TextOverflow
            itemId={`activity-text-bottom-${id}`}
            text={activity?.description}
            width={allowedLowerWidth}
          />
        </div>
      </div>
    </div>
  );
};

const EmptyRow = ({ key }) => {
  return (
    <div className={styles.rowWrapper} key={key}>
      <div className={styles.leftContainer}>
        <div className={styles.leftColumn}>
          <span className={styles.leftTextUpper}>&nbsp;</span>
          <span className={styles.leftTextBottom}>&nbsp;</span>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.firstRow}>
          <div className={styles.wholeRow}>
            <span className={styles.boldText}>&nbsp;</span>
            <span className={styles.lightText}>&nbsp;</span>
          </div>
        </div>

        <div className={styles.secondRow}>&nbsp;</div>
      </div>
    </div>
  );
};
