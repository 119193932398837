import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import QuestionWeightPanel from '../Components/QuestionWeightPanel';
import Header from '../../../Common/Components/Header';
import { assessmentView } from '../../../redux/addAssessment/selectors';
import SkillBasedAssessmentBody from '../Components/SkillBasedAssessmentBody';
import QuestionBasedAssessmentBody from '../Components/QuestionBasedAssessmentBody';
import ViewSwitch from '../Components/ViewSwitch';
import styles from '../Styles/AssessmentCustomizationPage.module.css';
import LeavingPageModal from '../Modals/LeavingPageModal';
import SendForReviewModal from '../Modals/SendForReviewModal';
import DeleteAssessmentModal from '../Modals/DeleteAssessmentModal';
import ConfimationPopup from '../../../Common/Components/ConfimationPopup';
import RemoveQuestionModal from '../Modals/RemoveQuestionModal';
import InviteUserModal from '../Modals/InviteUserModal';
import {
  publishedAssessment,
  rootAssessmentQuestionList,
  removeQuestionShow,
  sendForReviewShow,
  inviteUserShow,
  removeSkillBasedErrorRedux
} from '../../../redux/assessmentsVol2/selectors';
import {
  sendNotificationForRecruiterAction,
  updateCustomAssessmentAction
} from '../../../redux/assessmentsVol2/actions';
import {
  setCustomAssessment,
  setPublishedAssessment,
  setRemoveQuestionShow,
  setSendForReviewShow,
  setInviteUserShow,
  setRemoveSkillBasedError
} from '../../../redux/assessmentsVol2/slice';
import { viewHeight } from '../../../redux/user/selectors';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';

import { userSelector } from '../../../redux/auth/selectors';
import mixpanel from '../../../utils/mixpanel';

export default function AssessmentCustomizationPage() {
  const activeTab = useSelector(assessmentView);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(null);
  const published = useSelector(publishedAssessment);
  const location = useLocation();
  const { id } = useParams();
  const questions = useSelector(rootAssessmentQuestionList);
  const showRemoveQModal = useSelector(removeQuestionShow);
  const reviewShow = useSelector(sendForReviewShow);
  const inviteShow = useSelector(inviteUserShow);
  const height = useSelector(viewHeight);

  // Mixpanel variable
  const user = useSelector(userSelector);


  useEffect(async () => {
    if (!questions) {
      const data = {
        objStatus: 4,
        rootAssessmentId: id
      };
      await dispatch(updateCustomAssessmentAction(data));
      await dispatch(setCustomAssessment(id));
    }

    /* Mixpanel pageview */
    mixpanel.track('Assessment Customization - Pageview', {
      "User Id": user.userId,
      "Company Name": user.companyName,
      "Name Surname": user.userNameSurname,
      "Root Assessment Id": id,
      "Root Assessment Name": localStorage.getItem('Mixpanel_AssessmentCustomizationRootAssessmentName')
      })

  }, []);

  useEffect(() => {
    const cleanup = () => {
      const { id } = useParams();
      dispatch(sendNotificationForRecruiterAction({ type: 2, rootAssessmentId: id })); // Draft yap
    };

    window.addEventListener('unload', cleanup);

    return () => {
      window.removeEventListener('unload', cleanup);
    };
  }, []);

  const removeSkillBasedErrorFlag = useSelector(removeSkillBasedErrorRedux);

  return (
    <div>
      <Header />
      <div className={styles.pageWrapper}>
        {/* <ViewSwitch />
        {activeTab === 'Recruiter' ? <SkillBasedAssessmentBody /> : <QuestionBasedAssessmentBody />} */}
        <div className={styles.left} style={{ height: `calc(100vh - ${height}px)` }}>
          <ViewSwitch />
          {activeTab === 'Recruiter' ? (
            <SkillBasedAssessmentBody />
          ) : (
            <QuestionBasedAssessmentBody />
          )}
        </div>
        <div className={styles.right} style={{ height: `calc(100vh - ${height}px)` }}>
          <QuestionWeightPanel />
        </div>
      </div>
      <LeavingPageModal />
      <DeleteAssessmentModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        setConfirmedNavigation={setConfirmedNavigation}
      />
      <ConfimationPopup
        when
        pathname={history.location.pathname}
        deleteModal={setShowDeleteModal}
        confirmedNavigation={confirmedNavigation}
        setConfirmedNavigation={setConfirmedNavigation}
        navigate={path => history.push(path)}
        shouldBlockNavigation={clocation => {
          if (
            clocation.pathname === location.pathname ||
            published ||
            clocation.pathname.startsWith('/question-based-assessment/') ||
            clocation.pathname.startsWith('/try-assessment/')
          ) {
            dispatch(setPublishedAssessment(false));
            return false;
          }
          return true;
        }}
      />

      <RemoveQuestionModal
        show={showRemoveQModal}
        onHide={() => dispatch(setRemoveQuestionShow(false))}
      />

      <SendForReviewModal show={reviewShow} onHide={() => dispatch(setSendForReviewShow(false))} />
      <InviteUserModal show={inviteShow} onHide={() => dispatch(setInviteUserShow(false))} />

      <InfoBanner 
        text="An error occurred. Please try again."
        show={removeSkillBasedErrorFlag === true}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(setRemoveSkillBasedError());
        }}
        bannerStyle={{border: '1px solid rgba(221, 58, 58, 0.1)'}}
      />
    </div>
  );
}
