export default {
  filteredRootAssessmentList: [],
  currentUserDetail2: [],
  customAssessmentId: null,
  rootAssessmentId: null,
  categoryLabelList: null,
  assessmentActiveTab: 0,
  rootAssessmentQuestionList: null,
  codingLanguageList: null,
  publishedAssessment: false,
  assessmentToBeDeleted: null,
  jobDescription: null,
  questionBeingAdded: null,
  candidateQuestionList: null,
  filterState: {
    pageSize: 50,
    currentPage: 1,
    rootAssessmentId: '',
    skills: [],
    questionTypeIds: [],
    searchParameter: '',
    difficultyList: [],
    questionLanguages: [],
    purchaseTypes: []
  },
  removeQuestionShow: false,
  questionBeingRemoved: null,
  show: {
    appear: false,
    type: 1
  },
  questionAddedInSession: false,
  sendForReviewShow: false,
  inviteUserShow: false,
  tryAssessmentQuestions: [],
  allUserList: [],
  invitedAllUserList: [],
  HMUserList: [],
  RecUserList: [],
  invitedHMList: [],
  invitedRecList: [],
  skillsOfJobRole: null,
  questionDetail: null,
  maxPageCount: 25,
  authorizedHMList: [],
  authorizedRecList: [],
  isLoading: true,
  userList: [],
  newlyInvitedIDList: [],
  newlyInvitedID: '',
  AtsList: [],
  connectionList: [],
  CompanyAssessmentId: null,
  authorizedUsers: '',
  invitedArray: [],
  pageIndex: 1,
  assessmentDetailAfterCreate: {},
  premiumQuestionModal: true,
  scoredEmailCount: 0,
  invitedCount: 0,
  ibeScoredRedirect: false,
  sentMailSuccess: false,
  mailRejected: false,

  selectedRootAssessmentQuestionList: [],

  // flag for CreateCustomAssessment endpoint
  continueToAssessmentCustomization: 'pending',

  // flag for updateCustomAssessmentAction endpoint
  updateCustomAssessmentCheck: 'pending',

  // flag for skill based add question
  addSkillBasedError: null,
  addSkillBasedErrorMessage: null,

  // flag for add assessment action
  continueToAssessmentDetail: null,

  // flag for remove question on recruiter view
  removeSkillBasedError: null,

  // most popular root assessment list
  mostPopularRootAssessmentList: [],
  selectedRootAssmFilters: {}
};
