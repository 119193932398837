import React from 'react';
import styles from '../Styles/AddAssessmentHeader.module.css';
import { ReactComponent as Tick } from '../../../images/purple_tick.svg';
import { ReactComponent as Arrow } from '../../../images/add_assessment_arrow.svg';
import { useWindowSize } from '../../../utils/helpers';

const HeaderItem = ({ number, activeNumber, text }) => {
  const [windowW, windowH] = useWindowSize();

  return (
    <div className={styles.headerItem}>
      <div
        className={`${styles.number} ${
          activeNumber === number
            ? styles.activeNumber
            : activeNumber > number
            ? styles.pastNumber
            : styles.inactiveNumber
        }`}
      >
        <span className={styles.numberText}>
          {' '}
          {activeNumber > number ? <Tick className={styles.tick} /> : number}
        </span>
      </div>
      {windowW > 700 || (windowW <= 700 && activeNumber === number) ? (
        <span
          className={`${styles.headerText} ${
            activeNumber === number
              ? styles.active
              : activeNumber > number
              ? styles.past
              : styles.inactive
          }`}
        >
          {text}
        </span>
      ) : null}
    </div>
  );
};
export default function AddAssessmentHeader({ activeNumber }) {
  const [windowW, windowH] = useWindowSize();

  return (
    <div className={styles.headerWrapper}>
      <HeaderItem
        text={windowW > 700 ? 'Name Position' : 'Name Assessment'}
        number={1}
        activeNumber={activeNumber}
      />
      <Arrow className={styles.arrow} />
      <HeaderItem text="Authorize User" number={2} activeNumber={activeNumber} />
      <Arrow className={styles.arrow} />
      <HeaderItem text="Add Connection" number={3} activeNumber={activeNumber} />
      <Arrow className={styles.arrow} />
      <HeaderItem
        text={windowW > 700 ? 'Publish' : 'Publish Job Posting'}
        number={4}
        activeNumber={activeNumber}
      />
    </div>
  );
}
