import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import styles from './CompanySignup.module.css';
import {
  CompanyName,
  createCompSuccessRedux,
  Email,
  Name,
  Password,
  PhoneNumber,
  Surname
} from '../../../redux/salesScreen/selectors';
import {
  setCompanyName,
  setCreateCompSuccess,
  setEmail,
  setName,
  setPassword,
  setPhoneNumber,
  setSurname
} from '../../../redux/salesScreen/slice';
import { createCompanyAction } from '../../../redux/salesScreen/actions';

const CompanySignup = () => {
  const [checkBox, setCheckBox] = useState(true);
  const dispatch = useDispatch(); // redux ile function çağırmak için
  const name = useSelector(Name);
  const surname = useSelector(Surname);
  const phoneNumber = useSelector(PhoneNumber);
  const email = useSelector(Email);
  const companyName = useSelector(CompanyName);
  const password = useSelector(Password);
  const history = useHistory();

  const successfullyCreated = useSelector(createCompSuccessRedux);

  useEffect(() => {
    dispatch(setCreateCompSuccess(null));
    dispatch(setName(''));
    dispatch(setSurname(''));
    dispatch(setEmail(''));
    dispatch(setCompanyName(''));
    dispatch(setPassword(''));
    dispatch(setPhoneNumber(null));
  }, [])

  async function handleCreateCompany() {
    const data = {
      name: name,
      surname: surname,
      email: email,
      companyName: companyName,
      phoneNumber: phoneNumber,
      password: password
    };
    await dispatch(createCompanyAction(data));
  }

  useEffect(() => {
    if (successfullyCreated) {
      history.push('/nRdDOsdIFdwEdfdsaSd/3?state=new');
    } else if (successfullyCreated === false) {
      alert("Error");
    }
  }, [successfullyCreated])

  return (
    <div className={styles.contentBody}>
      <h1>Company Sign Up Form</h1>
      <div className={styles.row}>
        <label htmlFor="name">Name</label>
        <input
          onChange={event => {
            dispatch(setName(event.target.value));
          }}
          value={name}
          name="name"
          type="text"
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="surname">Surname</label>
        <input
          value={surname}
          onChange={event => {
            dispatch(setSurname(event.target.value));
          }}
          name="surname"
          type="text"
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="email">Corporate E-mail</label>
        <input
          value={email}
          onChange={event => {
            dispatch(setEmail(event.target.value));
          }}
          name="email"
          type="text"
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="companyName">Company Name</label>
        <input
          value={companyName}
          onChange={event => {
            dispatch(setCompanyName(event.target.value));
          }}
          name="companyName"
          type="text"
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="password">Password</label>
        <input
          value={password}
          onChange={event => {
            dispatch(setPassword(event.target.value));
          }}
          name="password"
          type="text"
        />
      </div>
      <div className={styles.row}>
        <label htmlFor="number">Phone Number</label>
        <PhoneInput
          inputProps={{
            name: 'phone',
            required: true
          }}
          country="tr"
          countryCodeEditable={false}
          specialLabel=""
          value={phoneNumber}
          onChange={value => dispatch(setPhoneNumber(value))}
        />
      </div>
      <div className={styles.row}>
        <input
          disabled
          name="candidateCount"
          style={{ marginLeft: 0, marginBottom: 0 }}
          type="checkbox"
          // value={checkBox}
          checked={checkBox}
          // onChange={() => setCheckBox(true)}
        />
        <label className={styles.privacy} htmlFor="candidateCount">
          Terms of Use & Privacy Policy
        </label>
      </div>
      <div className={styles.row} style={{ marginLeft: '5rem' }}>
        <button
          onClick={() => {
            history.goBack();
          }}
          className={styles.btn}
          type="button"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            handleCreateCompany();
          }}
          className={styles.btn}
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default CompanySignup;
