import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Timer.module.css';
import time from '../../Images/time.svg';
import CustomButton from '../../../../Common/Components/CustomButton';
import { nextButtonLoadingRedux } from '../../../../redux/AssessmentSession/selectors';
import { setBannerStatus, setBannerText } from '../../../../redux/AssessmentSession/slice';
import { useInterval } from '../../../../utils/useInterval';

const Timer = ({
  qIndex,
  qCount,
  totalTime,
  onNextClick,
  onSubmitClick,
  screenWidth,
  qStartDate,
  qNow
}) => {

  // Localdaki tarih ile sunucudaki tarih arasındaki fark
  const [dateDelay, setDateDelay] = useState();

  const [remainingInner, setRemainingInner] = useState();
  const [remainingDigital, setRemainingDigital] = useState('99:99');

  const initialTimeCalculation = () => {
    const startDate = new Date(new Date(qStartDate).toUTCString());
    const now = new Date(new Date(qNow).toUTCString());
    const localDate = new Date();
    setDateDelay(Math.ceil((localDate - now) / 1000 ));
    const ms = now - startDate;
    return totalTime - Math.ceil(ms/1000);
  }

  // qInfo ilk çekildiğinde now ile çağrılacak
  const calculateRemainingTime = () => {
    const startDate = new Date(new Date(qStartDate).toUTCString());
    const localDate = new Date();
    const ms = localDate - startDate;

    return totalTime + dateDelay - Math.ceil(ms / 1000);
  };

  const convertSec = seconds => {
    if (seconds >= 0) {
      const min = Math.floor(seconds / 60);
      const sec = seconds % 60;
      /* eslint-disable-next-line */
      setRemainingDigital(`${('0' + min).slice(-2)}:${('0' + sec).slice(-2)}`);
    } else {
      setRemainingDigital(`00:00`);
    }
  };

  useEffect(() => {
    setRemainingDigital('99:99');
    const remaining = initialTimeCalculation();
    setRemainingInner(remaining);
    convertSec(remaining);
  }, [qIndex]);

  const widthPercentage = `${(remainingInner / totalTime) * 100}%`;
  const transitionStr = `width ${remainingInner}s linear`;

  useInterval(() => {
    const remaining = calculateRemainingTime();
    setRemainingInner(remaining);
    convertSec(remaining);
  }, 1000, qIndex)

  // const widthPercentage = `${(calculateRemainingTime() / totalTime) * 100}%`;
  // const transitionStr = `width ${calculateRemainingTime()}s linear`;


  const buttonLoading = useSelector(nextButtonLoadingRedux);
  const dispatch = useDispatch();

  const [isSubmitAllowed, setIsSubmitAllowed] = useState(true);

  const buttonBehavior = async type => {
    if (qIndex !== qCount) {
      await onNextClick();
      if (type === 'timeout') {
        dispatch(setBannerStatus(true));
      } else {
        // next is Clicked
        dispatch(setBannerText('Answer is successfully submitted'));
        dispatch(setBannerStatus(true));
      }
    } else {
      await onSubmitClick();
      if (type === 'timeout') {
        dispatch(setBannerStatus(true));
      } else {
        // next is clicked
        dispatch(setBannerText('Answer is successfully submitted'));
        dispatch(setBannerStatus(true));
      }
    }
    setIsSubmitAllowed(true);
  };



  useEffect(() => {
    if (remainingInner || remainingInner === 0) {
      if (remainingInner <= -1 && isSubmitAllowed) {
        setIsSubmitAllowed(false);
        buttonBehavior('timeout');
      }
    }
  }, [remainingInner]);

  const [timerStyle, setStyle] = useState();
  useEffect(() => {
    setTimeout(() => {
      setStyle({ width: 0 });
    }, 100);
  }, []);

  return (
    <div id="qTimer" className={styles.TimerHeader}>
      <div className={styles.UpperTimer}>
        <div className={styles.DigitalTimeWrapper}>
          <img src={time} alt="timeIcon" />
          <div
            className={styles.DigitalTime}
            style={{ visibility: remainingDigital === '99:99' ? 'hidden' : 'initial' }}
          >
            {remainingDigital}
          </div>
        </div>
        <div className={styles.QuestionCount}>
          {screenWidth > 710 && 'Question'} {qIndex}/{qCount}
        </div>
        <CustomButton
          type={1}
          size="small"
          textField="Submit"
          style={{ marginRight: '2rem' }}
          loading={buttonLoading}
          buttonOnClick={() => {setIsSubmitAllowed(false); buttonBehavior();}}
        />
      </div>
      <div className={styles.QuestionTimerBar}>
        <div
          style={
            timerStyle
              ? { ...timerStyle, transition: transitionStr }
              : { width: widthPercentage, transition: transitionStr }
          }
          className={styles.innerBar}
        />
      </div>
    </div>
  );
};

export default Timer;
