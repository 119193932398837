import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import { setErrorInTeam, setSuccessInTeam } from '../../../../redux/auth/slice';
import Highlight from '../../../../Common/Components/Highlight';
import CustomButton from '../../../../Common/Components/CustomButton';
import { ReactComponent as NewUser } from '../../../Images/newuserIcon.svg';
import SearchBar from '../../../../Common/Components/SearchBar';
import TeamMember from './TeamMember';
import { setAddNewUserHighlight, setOnboardingStep } from '../../../../redux/plan/slice';
import { viewHeight } from '../../../../redux/user/selectors';
import {
  errorInTeamRedux,
  errorMessageInTeamRedux,
  successInTeamRedux,
  successMessageInTeamRedux,
  teamRedux,
  userSelector
} from '../../../../redux/auth/selectors';
import { onboardingStepRedux } from '../../../../redux/plan/selectors';
import styles from './TeamTab.module.css';
import EditUserModal from '../Modals/EditUserModal';
import { getTeamAction } from '../../../../redux/auth/actions';
import { getUserGroupTypeListAction } from '../../../../redux/user/actions';
import { ReactComponent as Error } from '../../../../images/errorSubmit.svg';

const TeamTab = () => {
  const dispatch = useDispatch();
  const addUserRef = useRef();

  const user = useSelector(userSelector);
  const team = useSelector(teamRedux);
  const teamActionError = useSelector(errorInTeamRedux);
  const teamErrorMessage = useSelector(errorMessageInTeamRedux);
  const teamActionSuccess = useSelector(successInTeamRedux);
  const teamSuccessMessage = useSelector(successMessageInTeamRedux);
  const onboardingStep = useSelector(onboardingStepRedux);
  const height = useSelector(viewHeight);

  const [addUserShow, setAddUserShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(async () => {
    dispatch(getTeamAction({ CompanyId: user?.companyId }));
    const resp = await dispatch(getUserGroupTypeListAction());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setAddNewUserHighlight(false));
    };
  }, []);

  useEffect(() => {
    if (onboardingStep === 2) {
      dispatch(setAddNewUserHighlight(true));
    }
  }, [onboardingStep]);

  const teamdiv = document?.getElementById('teamDiv');
  const newUserButton = document?.getElementById('addNewUserButtonId');

  function handleOutsideInAsssessment(event) {
    if (teamdiv && !teamdiv?.contains(event?.target) && !newUserButton?.contains(event?.target)) {
      dispatch(setOnboardingStep(0));
      dispatch(setAddNewUserHighlight(false));
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideInAsssessment);

    return () => {
      document.removeEventListener('click', handleOutsideInAsssessment);
    };
  }, []);

  return (
    <div className={styles.contentBody}>
      <InfoBanner
        text={teamErrorMessage !== '' ? teamErrorMessage : 'An error occurred. Please try again.'}
        show={teamActionError}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(setErrorInTeam(type));
        }}
        bannerStyle={{ backgroundColor: 'white', border: '1px solid rgba(221, 58, 58, 0.1)' }}
      />

      <InfoBanner
        text={teamSuccessMessage}
        show={teamActionSuccess}
        setStatus={type => {
          dispatch(setSuccessInTeam(type));
        }}
      />
      <EditUserModal show={addUserShow} onClose={() => setAddUserShow(false)} type="addNewUser" />
      <div className={styles.contentWrapper}>
        <div className={styles.headerWrapper}>
          <div className={styles.title}>Team</div>
          <Highlight
            borderRadius={12}
            paddingPixel={4}
            isHighlighted={onboardingStep === 2}
            child={
              <CustomButton
                buttonId="addNewUserButtonId"
                buttonRef={addUserRef}
                textField="Add new user"
                newDesign
                type={1}
                Icon={NewUser}
                size="small"
                customStyle={{height: '35px'}}
                buttonOnClick={() => {
                  setAddUserShow(true);
                  dispatch(setOnboardingStep(0));
                  dispatch(setAddNewUserHighlight(false));
                }}
              />
            }
          />
        </div>
        {isMobile ? (
          <div className={styles.pageWrapper} id="team-page">
            <div className={styles.searchbarWrapper}>
              {team ? <span className={styles.usersText}>{`${team.count} users`}</span> : <span />}
              <div style={{ width: '80%' }}>
                <SearchBar
                  color="gray"
                  size="line"
                  inputFieldPlaceholder="Search"
                  isExtended={1}
                  inputFieldValue={searchTerm}
                  inputFieldOnChange={event => {
                    setSearchTerm(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className={styles.titleWrapper}>
              <span style={{ width: '50%' }}>NAME</span>
            </div>
            <div className={styles.usersWrapper} id="usersWrapper">
              {team?.companyUsers?.map(
                (element, index) =>
                  element.name
                    ?.concat(' ')
                    ?.concat(element.surname)
                    ?.concat(' ')
                    ?.concat(element.email)
                    ?.toLowerCase()
                    ?.includes(searchTerm?.toLowerCase()) && (
                    <TeamMember
                      name={element?.name}
                      surname={element?.surname}
                      role={element.role}
                      invited={!element.isActive}
                      isAdmin={element?.isAdmin}
                      userId={element?.userId}
                      companyId={user?.companyId}
                      email={element?.email}
                      key={index}
                      teamUser={element}
                    />
                  )
              )}
            </div>
          </div>
        ) : (
          <div className={styles.pageWrapper} id="team-page">
            <div className={styles.searchbarWrapper}>
              {team ? <span className={styles.usersText}>{`${team.count} users`}</span> : <span />}
              <div style={{ width: '55%' }}>
                <SearchBar
                  color="gray"
                  size="line"
                  inputFieldPlaceholder="Search"
                  isExtended={1}
                  inputFieldValue={searchTerm}
                  inputFieldOnChange={event => {
                    setSearchTerm(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className={styles.titleWrapper}>
              <span style={{ width: '50%' }}>NAME</span>
              <span style={{ width: '185px', marginLeft: 'auto' }}>ROLE</span>
            </div>
            <div className={styles.usersWrapper} id="usersWrapper">
              {team?.companyUsers?.map(
                (element, index) =>
                  element.name
                    ?.concat(' ')
                    ?.concat(element.surname)
                    ?.concat(' ')
                    ?.concat(element.email)
                    ?.toLowerCase()
                    ?.includes(searchTerm?.toLowerCase()) && (
                    <TeamMember
                      name={element?.name}
                      surname={element?.surname}
                      role={element.role}
                      invited={!element.isActive}
                      isAdmin={element?.isAdmin}
                      userId={element?.userId}
                      companyId={user?.companyId}
                      email={element?.email}
                      key={index}
                      teamUser={element}
                    />
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamTab;
