import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBarWrapper from '../Components/ProgressBarWrapper';
import styles from '../Styles/CandidateResultPublicPage.module.css';
import CandidateQuestionWrapper from '../Components/CandidateQuestionWrapper';
import {
  infoLoading,
  isScored,
  labelLoading,
  questionLoading
} from '../../redux/CandidatePublic/selector';
import {
  getCandidateAssessmentPublicLabelsAction,
  getCandidateisScoredAction,
  getCandidatePublicInfoAction,
  getCandidatePublicSolutionsAction
} from '../../redux/CandidatePublic/actions';
import Loading from '../../Common/Components/Loading';
import InfoBlockWrapper from '../Components/InfoBlockWrapper';

export default function CandidateResultPublicPage() {
  const dispatch = useDispatch();

  const labelisLoading = useSelector(labelLoading);
  const questionisLoading = useSelector(questionLoading);
  const infoisLoading = useSelector(infoLoading);
  const scoredInfo = useSelector(isScored);
  const [id, setId] = useState();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setId(urlParams.get('token'));
  }, []);

  useEffect(async () => {
    if (id) {
      const data = {
        token: id
      };
      await dispatch(getCandidateisScoredAction(data));
      dispatch(getCandidateAssessmentPublicLabelsAction(data));
      dispatch(getCandidatePublicSolutionsAction(data));
      dispatch(getCandidatePublicInfoAction(data));
    }
  }, [id]);

  return (
    <div className={styles.container}>
      {labelisLoading || questionisLoading || infoisLoading ? (
        <div style={{height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <Loading />
        </div>
      ) : (
        <>
          <div className={styles.leftColumn}>
            <InfoBlockWrapper setPhoneVisibility={() => {}} />
          </div>
          <div className={styles.rightColumn}>
            <ProgressBarWrapper isScored={scoredInfo} />
            <CandidateQuestionWrapper />
          </div>
          
        </>
      )}
    </div>
  );
}
