import { React, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloseButton from '../../../Common/Components/CloseButton';
import styles from './InviteCandidatesModal.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import { applicantAssessmentSendEmailAction } from '../../../redux/assessmentsVol2/actions';
import EmailInputInModal from '../../../Common/Components/EmailInputInModal';
import EmailInputInModalMobile from '../../../Common/Components/EmailInputInModalMobile';
import { userSelector } from '../../../redux/auth/selectors';
import { setSentMailSuccess } from '../../../redux/assessmentsVol2/slice';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/errorSubmit.svg';
import isEmailFn from '../../../utils/isEmail';
import { assessmentDetailData } from '../../../redux/assessmentDetail/selectors';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import { useWindowSize } from '../../../utils/helpers';
import mailIcon from '../Images/purpleMail.svg';
import copyLink from './copyLink.svg';

import mixpanel from '../../../utils/mixpanel'
import { getWelcomeCandidateLinkAction } from '../../../redux/assessmentDetailVol2/actions';
import { companyAssessmentNameSelector, rootAssessmentNameSelector } from '../../../redux/assessmentDetailVol2/selectors';

export default function InviteCandidatesModal({ show, onHide, ...props }) {
  const dispatch = useDispatch();
  const [emails, setEmails] = useState([]);
  const [validEmails, setValidEmails] = useState([]);
  const { id } = useParams();
  const [windowW, windowH] = useWindowSize();

  const user = useSelector(userSelector);
  const [sendButtonLoading, setSendButtonLoading] = useState(false);
  
  const [copyAssessmentUrl, setCopyAssessmentUrl] = useState();

  // Mixpanel related states
  const companyAssessmentNameRedux = useSelector(companyAssessmentNameSelector);
  const rootAssessmentNameRedux = useSelector(rootAssessmentNameSelector);

  useEffect(() => {
    const temp = [];
    if (emails.length > 0) {
      emails?.forEach(e => {
        if (isEmailFn(e)) {
          temp.push(e);
        }
        setValidEmails(temp);
      });
    } else {
      setValidEmails([]);
    }
  }, [emails]);

  const sendEmail = async element => {
    const jsonData = {
      emails: element,
      companyAssessmentId: id,
      companyId: user?.companyId
    };
    await dispatch(applicantAssessmentSendEmailAction(jsonData));
    setSendButtonLoading(false);
    await onHide();
  };


  useEffect(async () => {
    if (!show) {
      setEmails([]);
      setValidEmails([]);
      setSendButtonLoading(false);
    } else {
      // TODO: fetch link api
      const data = {
        companyAssessmentId: id,
      }
      const resp = await dispatch(getWelcomeCandidateLinkAction(data));
      if (resp?.meta?.requestStatus === 'fulfilled') {
        setCopyAssessmentUrl(resp?.payload)
      }
    }
  }, [show]);

  const [infoMessage, setInfoMessage] = useState(null);
  const [showInfoMessage, setShowInfoMessage] = useState(null);
  const [isSuccessInfoBanner, setIsSuccessInfoBanner] = useState(false);

  const mixpanelEventForCopyLink = () => {
    mixpanel.track('Copy Assessment Link IBE', {
      'Company Name': user?.companyName,
      'Email': user?.email,
      'User Type': user?.userGroupType,
      'Name Surname': user?.userNameSurname,
      'Assessment Id': id,
      'Assessment Name': companyAssessmentNameRedux,
      'Root Assessment Name': rootAssessmentNameRedux,
    })
  }

  const MobileIBEModal = (
    <MobileModal
      id="mobile-IBE-modal"
      outsideClickClose
      show={show}
      onClose={onHide}
      contentStyle={{ padding: '20px 20px 24px 20px', minWidth: '375px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      <div className={styles.mobileHeaderRow}>
        <div className={styles.mobileHeader}>
          <img alt="" src={mailIcon} />
          <span className={styles.mobileHeader}>Invite Candidates</span>
        </div>
        <CloseButton onClick={() => onHide()} size="small" />
      </div>
      <EmailInputInModalMobile
        style={{ minHeight: '290px', maxHeight: '290px', marginBottom: '24px' }}
        placeholder="Input your email"
        emails={emails}
        setEmail={setEmails}
        onChange={email => setEmails(email)}
        validateEmail={() => true}
        getLabel={(email, index, removeEmail) => (
          <div data-tag key={index}>
            <div data-tag-item>{email}</div>
            <span
              data-tag-handle
              onClick={() => {
                removeEmail(index);
              }}
            >
              ×
            </span>
          </div>
        )}
        infoMessageState={[infoMessage, setInfoMessage]}
        showInfoMessageState={[showInfoMessage, setShowInfoMessage]}
      />
      <CustomButton
        customStyle={{ width: '100%' }}
        type={1}
        isDisabled={validEmails.length === 0}
        loading={sendButtonLoading}
        textField="Send"
        buttonOnClick={() => {
          setSendButtonLoading(true);
          sendEmail(validEmails);
        }}
      />
    </MobileModal>
  );

  const IBEModal = (
    <Modal
      centered
      dialogClassName={styles.modalDialog}
      contentClassName={`${styles.modalContent} ${styles['modal-content']}`}
      show={show}
      onHide={onHide}
      {...props}
    >
      <div className={styles.headerRow}>
        <span className={styles.header}>Invite Candidates</span>
        <CloseButton onClick={() => onHide()} />
      </div>
      <span className={styles.shortText}>Write the email address and press Enter.</span>
      <EmailInputInModal
        style={{ marginTop: '20px', marginBottom: '20px' }}
        placeholder="Input your email"
        emails={emails}
        setEmail={setEmails}
        onChange={email => setEmails(email)}
        validateEmail={() => true}
        getLabel={(email, index, removeEmail) => (
          <div data-tag key={index}>
            <div data-tag-item>{email}</div>
            <span
              data-tag-handle
              onClick={() => {
                removeEmail(index);
              }}
            >
              ×
            </span>
          </div>
        )}
        infoMessageState={[infoMessage, setInfoMessage]}
        showInfoMessageState={[showInfoMessage, setShowInfoMessage]}
      />
      <div className={styles.footerRow}>
        <div style={{display: 'flex', flexDirection: 'row', gap: '12px'}}>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <span className={styles.normalText}>Invite &nbsp;</span>
            <span className={styles.purpleText}>{validEmails?.length}</span>
            <span className={styles.normalText}>/100</span>
          </div>
          <div 
            className={styles.CopyButtonWrapper}
            style={copyAssessmentUrl ? {} : {opacity: '0.4', pointerEvents: 'none'}} 
            onClick={() => {
              navigator.clipboard.writeText(copyAssessmentUrl);
              setInfoMessage('Copied!')
              setIsSuccessInfoBanner(true);
              setShowInfoMessage(true);
              mixpanelEventForCopyLink()
            }}
          >
            <img src={copyLink} alt="copyLink" />
            <text className={styles.copyText}>Copy Assessment Link</text>
          </div>
        </div>

        <CustomButton
          type={1}
          isDisabled={validEmails.length === 0}
          loading={sendButtonLoading}
          textField="Send"
          buttonOnClick={() => {
            setSendButtonLoading(true);
            sendEmail(validEmails);
          }}
        />
      </div>
    </Modal>
  );

  return (
    <div className={styles.container}>
      <InfoBanner
        text={infoMessage}
        show={showInfoMessage}
        CustomIcon={!isSuccessInfoBanner && Error}
        setStatus={type => {
          setShowInfoMessage(null);
          setIsSuccessInfoBanner(null)
          setInfoMessage(null);
        }}
      />
      {windowW > 576 ? <>{IBEModal}</> : <>{MobileIBEModal}</>}
    </div>
  );
}
