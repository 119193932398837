import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInCalendarDays, isSameDay } from 'date-fns';
import Modal from '../../../Common/CustomModal/Modal';
import styles from './FilterModal.module.css';
import CloseButton from '../../../Common/Components/CloseButton';
import SwitchButton from '../../../Common/Components/CustomSwitchButton/SwitchButton';
import CustomButton from '../../../Common/Components/CustomButton';
import {
  resetFiltersForFunnelClick,
  urlDynamicQueryUpdateLite,
  useWindowSize
} from '../../../utils/helpers';
import FilterItemScore from './FilterItemScore';
import FilterItemDate from './FilterItemDate';
import {
  arriveTypeSelector,
  endDateSelector,
  endScoreSelector,
  oldestInitialDateSelector,
  screeningExperiencesSelector,
  screeningFiltersSelector,
  screeningNationalitiesSelector,
  screeningSalaryCurrencySelector,
  screeningSalaryMaxValueSelector,
  screeningSalaryMinValueSelector,
  screeningWorkTypesSelector,
  startDateSelector,
  startScoreSelector,
  industryListSelector,
  schoolListSelector,
  industryIncludeTypeSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import { resetCandidateListRelatedStuffForNewFilters } from '../../../redux/assessmentDetailVol2/slice';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import FilterItemIndustry from './FilterItemIndustry';
import FilterItemScreening from './FilterItemScreening';
import {
  getIndustriesAction,
  getSchoolsAction,
  getScreeningFiltersAction
} from '../../../redux/assessmentDetailVol2/actions';
import { useIp } from '../../../hooks/useIp';
import FilterItemSchool from './FilterItemSchool';

export default function FilterModal({ filterShow, filterOnClose = () => {} }) {
  const dispatch = useDispatch();
  const [loc, currency] = useIp();
  // Date helper:
  const oldestDateInt = useSelector(oldestInitialDateSelector);
  const today = new Date();

  // Filter redux:
  const startDateRedux = useSelector(startDateSelector);
  const endDateRedux = useSelector(endDateSelector);
  const startScoreRedux = useSelector(startScoreSelector);
  const endScoreRedux = useSelector(endScoreSelector);
  const arriveTypeRedux = useSelector(arriveTypeSelector);

  const [openFilterItem, setOpenFilterItem] = useState('Score');
  const [ibeSwitch, setIbeSwitch] = useState(!!(arriveTypeRedux?.length !== 0));

  // Screening Selectors
  const screeningExperiences = useSelector(screeningExperiencesSelector);
  const screeningWorkTypes = useSelector(screeningWorkTypesSelector);
  const screeningWorkPermits = useSelector(screeningNationalitiesSelector);
  const screeningCurrency = useSelector(screeningSalaryCurrencySelector);
  const screeningMinSalary = useSelector(screeningSalaryMinValueSelector);
  const screeningMaxSalary = useSelector(screeningSalaryMaxValueSelector);
  const screeningIndustry = useSelector(industryListSelector);
  const screeiningSchools = useSelector(schoolListSelector);
  const screeningIncludeType = useSelector(industryIncludeTypeSelector);

  const screeningData = useSelector(screeningFiltersSelector);

  // Screening States
  const [industries, setIndustries] = useState(screeningIndustry || []);
  const includeData = ['Current or past', 'Current'];
  const [include, setInclude] = useState(
    screeningIncludeType === 20 ? includeData[0] : includeData[1]
  );

  const filterWrapperRef = useRef();
  const [schoolList, setSchoolList] = useState(screeiningSchools || []);
  const [experienceState, setExperienceState] = useState(screeningExperiences || []);
  const [workTypeState, setWorkTypeState] = useState(screeningWorkTypes || []);
  const [workPermitState, setWorkPermitState] = useState(screeningWorkPermits || []);
  const [currencyState, setCurrency] = useState(
    screeningCurrency
      ? {
          title: screeningData?.salaryExpectation.currencyTypes?.filter(
            x => x.id === screeningCurrency
          )[0]?.name,
          id: screeningCurrency
        }
      : { title: 'hjh', id: 20 }
  );
  const [minSalaryState, setMinSalaryState] = useState(screeningMinSalary || 0);
  const [maxSalaryState, setMaxSalaryState] = useState(screeningMaxSalary || 0);

  const [minmaxScores, setMinMaxScores] = useState([startScoreRedux || 0, endScoreRedux || 100]);
  const [startDate, setStartDate] = useState(
    startDateRedux
      ? new Date(new Date().setDate(new Date().getDate() + startDateRedux))
      : oldestDateInt
      ? new Date(new Date().setDate(new Date().getDate() + oldestDateInt))
      : today
  );
  const [endDate, setEndDate] = useState(
    endDateRedux ? new Date(new Date().setDate(new Date().getDate() + endDateRedux)) : today
  );
  const [filterLoading, setFilterLoading] = useState(false);
  const [schoolDropdownScroll, setSchoolDropdownScroll] = useState(false);

  useEffect(() => {
    if (oldestDateInt) {
      setStartDate(new Date(new Date().setDate(new Date().getDate() + oldestDateInt)));
      setEndDate(today);
    }
  }, [oldestDateInt]);

  useEffect(() => {
    setCurrency(
      screeningCurrency
        ? {
            title: screeningData?.salaryExpectation.currencyTypes?.filter(
              x => x.id === screeningCurrency
            )[0]?.name,
            id: screeningCurrency
          }
        : { title: 'hjh', id: 20 }
    );
  }, [screeningData]);

  async function filter() {
    await setFilterLoading(true);
    await urlDynamicQueryUpdateLite('startScore', minmaxScores[0], false);
    await urlDynamicQueryUpdateLite('endScore', minmaxScores[1], false);
    await urlDynamicQueryUpdateLite('startDate', differenceInCalendarDays(startDate, today), false);
    await urlDynamicQueryUpdateLite('endDate', differenceInCalendarDays(endDate, today), false);
    await urlDynamicQueryUpdateLite('arriveType', ibeSwitch ? [10] : '', false);
    await urlDynamicQueryUpdateLite('industryList', industries, false);
    await urlDynamicQueryUpdateLite(
      'industryIncludeType',
      include === includeData[1] ? 10 : 20,
      false
    );
    await urlDynamicQueryUpdateLite('schoolList', schoolList, false);
    await urlDynamicQueryUpdateLite('experiences', experienceState, false);
    await urlDynamicQueryUpdateLite('workTypes', workTypeState, false);
    await urlDynamicQueryUpdateLite('workPermit', workPermitState, false);
    await urlDynamicQueryUpdateLite('minSalary', minSalaryState || '', false);
    await urlDynamicQueryUpdateLite('maxSalary', maxSalaryState || '', false);
    await urlDynamicQueryUpdateLite('currency', currencyState?.id, false);

    dispatch(resetCandidateListRelatedStuffForNewFilters());
    await window.dispatchEvent(new Event('locationchange'));
    await setFilterLoading(false);
    await filterOnClose();
    await setOpenFilterItem('Score');
  }

  async function resetAll() {
    await setIbeSwitch(false);
    await setMinMaxScores([0, 100]);
    await setStartDate(-1);
    await setEndDate(-1);
    await setMinSalaryState(0);
    await setMaxSalaryState(0);
    await setCurrency({
      title: screeningData?.salaryExpectation.currencyTypes?.filter(
        x => x.id === screeningCurrency
      )[0]?.name,
      id: screeningCurrency
    });
    await setIndustries([]);
    await setSchoolList([]);
    await setInclude(includeData[1]);
    await setExperienceState([]);
    await setWorkTypeState([]);
    await setWorkPermitState([]);

    resetFiltersForFunnelClick(currency);
    dispatch(resetCandidateListRelatedStuffForNewFilters());
    await window.dispatchEvent(new Event('locationchange'));
    await filterOnClose();
    await setOpenFilterItem('Score');
  }

  useEffect(() => {
    if (filterShow) {
      setOpenFilterItem('Score');
      setIbeSwitch(arriveTypeRedux?.includes(10));
      setMinMaxScores([startScoreRedux, endScoreRedux]);

      setStartDate(
        startDateRedux !== null
          ? new Date(new Date().setDate(new Date().getDate() + startDateRedux))
          : oldestDateInt
          ? new Date(new Date().setDate(new Date().getDate() + oldestDateInt))
          : today
      );
      setEndDate(
        endDateRedux !== null
          ? new Date(new Date().setDate(new Date().getDate() + endDateRedux))
          : today
      );
      dispatch(getScreeningFiltersAction({}));
      dispatch(getIndustriesAction({}));
      dispatch(getSchoolsAction({}));
    }
  }, [filterShow]);

  const [windowW] = useWindowSize();

  useEffect(() => {
    if (schoolDropdownScroll) {
      filterWrapperRef.current.scroll({
        top: filterWrapperRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [schoolDropdownScroll]);

  const FilterModal = (
    <Modal
      modalId="filterCandidateModal"
      show={filterShow}
      onClose={filterOnClose}
      centerHorizontally
      centerVertically
      size={{ width: '55.6rem', height: '62rem' }}
      contentStyle={{ borderRadius: '24px', padding: '3.2rem 2rem 3.2rem 2rem' }}
      outsideClickClose
    >
      <div className={styles.wrapper}>
        <div
          className={styles.headerWrapper}
          style={{ marginBottom: '2.4rem' }}
          id="FilterModalHeader"
        >
          <span className={styles.header}>Filter Options</span>
          <CloseButton width={30} height={30} onClick={() => filterOnClose()} />
        </div>

        <div className={styles.filterItemWrapper} ref={filterWrapperRef} id="filter-wrapper">
          <FilterItemScore
            filterName="Score"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            setScores={setMinMaxScores}
            scores={minmaxScores}
          />

          <FilterItemDate
            filterName="Date"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            initialDate={startDate}
            finalDate={endDate}
            setInitialDate={setStartDate}
            setFinalDate={setEndDate}
          />

          <FilterItemIndustry
            filterName="Industry"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            setIndustryFilter={setIndustries}
            industryFilter={industries}
            includeList={includeData}
            includeFilter={include}
            setIncludeFilter={setInclude}
          />
          <FilterItemScreening
            filterName="Screening Questions"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            experienceFilter={experienceState}
            setExperienceFilter={setExperienceState}
            workTypeFilter={workTypeState}
            setWorkTypeFilter={setWorkTypeState}
            workPermitFilter={workPermitState}
            setWorkPermitFilter={setWorkPermitState}
            salaryCurrencyFilter={currencyState}
            setSalaryCurrencyFilter={setCurrency}
            salaryMinFilter={minSalaryState}
            setSalaryMinFilter={setMinSalaryState}
            salaryMaxFilter={maxSalaryState}
            setSalaryMaxFilter={setMaxSalaryState}
          />
          <FilterItemSchool
            filterName="School"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            schoolFilter={schoolList}
            setSchoolFilter={setSchoolList}
            setSchoolDropdownScroll={setSchoolDropdownScroll}
          />

          <div className={styles.ibeWrapper}>
            <SwitchButton isResponsive switchState={ibeSwitch} setSwitchState={setIbeSwitch} />
            <span className={styles.ibeText}>
              Show only the candidates <b>invited by email</b>
            </span>
          </div>
        </div>

        <div className={styles.buttonWrapper} id="FilterModalButtonWrapper">
          <CustomButton
            type={7}
            textField="Reset all"
            size="medium"
            buttonOnClick={() => resetAll()}
          />
          <CustomButton
            isDisabled={isNaN(minmaxScores[0]) || isNaN(minmaxScores[1])}
            type={1}
            textField="Filter"
            size="medium"
            buttonOnClick={() => {
              filter();
            }}
            loading={filterLoading === true}
          />
        </div>
      </div>
    </Modal>
  );

  const MobileFilterModal = (
    <MobileModal
      id="mobile-filterCandidateModal"
      show={filterShow}
      onClose={filterOnClose}
      outsideClickClose
      contentStyle={{ padding: '20px 12px 20px 8px', minWidth: '375px' }}
      backdropStyle={{ overflowY: 'hidden' }}
    >
      <div className={styles.wrapper}>
        <div
          className={styles.headerWrapper}
          style={{ marginBottom: '20px' }}
          id="FilterModalHeader"
        >
          <span className={styles.header}>Filter Options</span>
          <CloseButton width={23} height={23} onClick={() => filterOnClose()} />
        </div>
        <div
          className={styles.filterItemWrapper}
          ref={filterWrapperRef}
          style={{ maxHeight: '60vh' }}
        >
          <FilterItemScore
            filterName="Score"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            setScores={setMinMaxScores}
            scores={minmaxScores}
            isMobile
          />

          <FilterItemDate
            filterName="Date"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            initialDate={startDate}
            finalDate={endDate}
            setInitialDate={setStartDate}
            setFinalDate={setEndDate}
          />
          <FilterItemIndustry
            filterName="Industry"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            setIndustryFilter={setIndustries}
            industryFilter={industries}
            includeList={includeData}
            includeFilter={include}
            setIncludeFilter={setInclude}
          />
          <FilterItemScreening
            filterName="Screening Questions"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            experienceFilter={experienceState}
            setExperienceFilter={setExperienceState}
            workTypeFilter={workTypeState}
            setWorkTypeFilter={setWorkTypeState}
            workPermitFilter={workPermitState}
            setWorkPermitFilter={setWorkPermitState}
            salaryCurrencyFilter={currencyState}
            setSalaryCurrencyFilter={setCurrency}
            salaryMinFilter={minSalaryState}
            setSalaryMinFilter={setMinSalaryState}
            salaryMaxFilter={maxSalaryState}
            setSalaryMaxFilter={setMaxSalaryState}
          />
          <FilterItemSchool
            filterName="School"
            openItem={openFilterItem}
            setOpenItem={setOpenFilterItem}
            schoolFilter={schoolList}
            setSchoolFilter={setSchoolList}
            setSchoolDropdownScroll={setSchoolDropdownScroll}
          />

          <div className={styles.ibeWrapper}>
            <SwitchButton isMobile switchState={ibeSwitch} setSwitchState={setIbeSwitch} />
            <span className={styles.ibeText}>
              Show only the candidates <b>invited by email</b>
            </span>
          </div>
        </div>

        <div className={styles.buttonWrapper} id="FilterModalButtonWrapper">
          <CustomButton
            type={7}
            textField="Reset all"
            size="medium"
            buttonOnClick={() => resetAll()}
            customStyle={{ width: '50%', border: '0.709677px solid #DE4747' }}
          />
          <CustomButton
            isDisabled={isNaN(minmaxScores[0]) || isNaN(minmaxScores[1])}
            type={1}
            textField="Filter"
            size="medium"
            buttonOnClick={() => {
              filter();
            }}
            loading={filterLoading === true}
            customStyle={{ width: '50%' }}
          />
        </div>
      </div>
    </MobileModal>
  );

  return (
    <div className={styles.container}>
      {windowW > 576 ? <>{FilterModal}</> : <>{MobileFilterModal}</>}
    </div>
  );
}
