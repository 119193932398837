import assessmentDetail from './slice';

export const assessmentDetailData = state => state.assessmentDetail.detail;
export const assessmentDetailAfterCreate = state => state?.assessmentDetail?.createDetail || {};
export const customHiringFunnel = state => state.assessments.selectedHiringFunnel;
export const filteredAssessments = state => state.assessments.filteredItems;
export const candidateDetail = state => state.assessments.candidateDetail;
export const buttonStatus = state => state.assessmentDetail.disableButton;
export const candidateFunnelInfo = state => state.assessmentDetail.candidateFunnelInfo;
export const inviteCandidatesModalShow = state => state.assessmentDetail.inviteCandidatesModalShow;
export const assessmentSettingInfoRedux = state => state.assessmentDetail.assessmentSettingInfo;
export const copiedStateRedux = state => state.assessmentDetail.copiedState;
export const settingsSuccessRedux = state => state.assessmentDetail.settingsSuccess;
export const settingsErrorRedux = state => state.assessmentDetail.settingsError;
export const settingsBannerMessageRedux = state => state.assessmentDetail.settingsBannerMessage;

export const funnelCountsRedux = state => state.assessmentDetail.funnelCounts;