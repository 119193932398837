export default {
  weightSum: 0,
  assessmentView: '',
  index: 0,
  AssessmentName: '',
  switchState: 0,
  atsState: null,
  checkBoxState: [],
  userTypeOfAuthComponent: '',
  publishEnabled: false,
  rootAssmCategories: [],
  rootAssmJobRoles: [],
  rootAssmSeniorities: []
};
