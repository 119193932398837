import React, { useEffect, useState } from 'react';
import Tooltip from '../../../../Common/Components/Tooltip';
import styles from './ProgressBar.module.css';

const ProgressBar = ({ score, average, tooltipText }) => {
  const [colorCode, setColorCode] = useState();
  const [percentage, setPercentage] = useState(10);

  useEffect(() => {
    if (average !== null) {
      if (score === -1) {
        setColorCode('#FF7E62');
      } else if (score >= average) {
        setColorCode('#4AC389');
      } else if (score >= average * 0.8 && score < average) {
        setColorCode('#FFD600');
      } else {
        setColorCode('#CA1515');
      }
    } else {
      setColorCode('#FFD600');
    }

    if (!average) {
      setPercentage(100);
    } else {
      setPercentage(
        Math.round((Math.abs(Math.floor(average) - score) / Math.floor(average)) * 100)
      );
    }
  }, [score, average]);

  return (
    <div className={styles.ProgressBarWrapper}>
      <div className={styles.UltimateInnerWrapper}>
        <div className={styles.InnerWrapper}>
          <Tooltip
            customStyle={{ marginLeft: `calc(${average || 0}% - 5px)` }}
            tooltipColor="#585858"
            textColor="#FFFFFF"
            text={tooltipText}
            marginTop="2px"
            fontSize="14px"
            padding="4px 8px"
          >
            <div className={styles.polygon} />
          </Tooltip>

          <div className={styles.ProgressBarBackDiv}>
            <div
              className={styles.ProgressBarFrontDiv}
              style={{
                width: `${(score !== -1 ? score : 0) || 0}%`,
                background: colorCode,
                borderRadius: score === 100 ? '3px' : 'inherit',
                transition: 'width 1s'
              }}
            />
          </div>
        </div>

        <text className={styles.ProgressBarScore}>{score !== -1 ? score : 'N/A'}</text>
      </div>

      <text style={{ color: 'rgba(57, 57, 57, 0.5)', fontSize: '15px' }}>
        {average === null ? (
          <text>Industry average not available</text>
        ) : score !== -1 ? (
          score >= average ? (
            <>
              <text style={{ color: colorCode }}>{percentage}% better</text>&nbsp;than industry
              average
            </>
          ) : score >= average * 0.8 ? (
            <>
              <text style={{ color: colorCode }}>Average</text>&nbsp;in industry
            </>
          ) : (
            <>
              <text style={{ color: colorCode }}>{percentage}% worse</text>&nbsp;than industry
              average
            </>
          )
        ) : (
          <>
            <text style={{ color: colorCode }}>Not take</text>&nbsp;the assessment yet
          </>
        )}
      </text>
    </div>
  );
};

export default ProgressBar;
