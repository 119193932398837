import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { getLogoByCompanyIdAction } from './actions';

const logoStateSlice = createSlice({
  name: 'logoState',
  initialState,
  reducers: {
    setLogo: (state, action) => {
      state.logoPath = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getLogoByCompanyIdAction.fulfilled, (state, action) => {
      const temp = action.payload.companyLogo;
      if (temp && temp.startsWith('CompanyLogo')) {
        state.logoPath = `${process.env.REACT_APP_IMAGE_API}/${temp}`;
      } else {
        state.logoPath = temp;
      }
    })
  }
});

export const { setLogo } = logoStateSlice.actions;

export default logoStateSlice.reducer;
