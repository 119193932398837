import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  // getAssessmentCandidateListService,
  getAssessmentListForCandidateServiceVol2,
  getCandidateAssessmentLabelsService,
  // getCandidateFunnelInfoService,
  // getCandidateInformationService,
  getCandidateSolutionsService,
  //  getCommentCountService,
  // getFunnelInfoService
} from './service';

// export const getCandidateInformationAction = createAsyncThunk(
//   '/getCandidateInformationAction',
//   async (data, thunkAPI) => {
//     try {
//       return await getCandidateInformationService(data);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// export const getAssessmentCandidateListAction = createAsyncThunk(
//   '/getAssessmentCandidateListAction',
//   async (data, thunkAPI) => {
//     try {
//       return await getAssessmentCandidateListService(data);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// export const getFunnelInfo = createAsyncThunk('/getFunnelInfo ', async (data, thunkAPI) => {
//   try {
//     return await getFunnelInfoService(data);
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error);
//   }
// });

// export const getCurrentFunnel = createAsyncThunk(
//   '/getCurrentFunnelInfo ',
//   async (data, thunkAPI) => {
//     try {
//       return await getCandidateFunnelInfoService(data);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// export const getCandidateSolutionsAction = createAsyncThunk(
//   '/getCandidateSolutionsAction ',
//   async (data, thunkAPI) => {
//     try {
//       return await getCandidateSolutionsService(data);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

// export const getCommentCountAction = createAsyncThunk(
//   '/getCommentCountAction ',
//   async (data, thunkAPI) => {
//     try {
//       return await getCommentCountService(data);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const getCandidateAssessmentLabelsAction = createAsyncThunk(
  '/getCandidateAssessmentLabelsAction',
  async (data, thunkAPI) => {
    try {
      const resp = await getCandidateAssessmentLabelsService(data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAssessmentListForCandidateActionVol2 = createAsyncThunk(
  '/getAssessmentListForCandidateActionVol2 ',
  async (data, thunkAPI) => {
    try {
      return await getAssessmentListForCandidateServiceVol2(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidateSolutionsAction = createAsyncThunk(
  '/getCandidateSolutionsAction ',
  async (data, thunkAPI) => {
    try {
      return await getCandidateSolutionsService(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
