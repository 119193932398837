import API from '../../constant/apiVol2';
import client from '../../constant/indexVol2';

export const signupByEmailService = data =>
  new Promise((resolve, reject) => {
    client.post(API.signupByEmail, data).then(resolve).catch(reject);
  });

export const signupStep2Service = data =>
  new Promise((resolve, reject) => {
    client.post(API.signupStep2, data).then(resolve).catch(reject);
  });

export const sendVerificationEmailService = data =>
  new Promise((resolve, reject) => {
    client.post(API.sendVerificationEmail, data).then(resolve).catch(reject);
  });

export const forgotPasswordService = data =>
  new Promise((resolve, reject) => {
    client.post(API.forgotPassword, data).then(resolve).catch(reject);
  });

export const createSignupInfoService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createSignupInfo, data).then(resolve).catch(reject);
  });

export const activateUserService = data =>
  new Promise((resolve, reject) => {
    client.post(API.activateUser, data).then(resolve).catch(reject);
  });

export const quickEmailVerificationService = data =>
  new Promise((resolve, reject) => {
    client.post(API.quickEmailVerification, data).then(resolve).catch(reject);
  });

export const refreshPasswordService = data =>
  new Promise((resolve, reject) => {
    client.post(API.refreshPassword, data).then(resolve).catch(reject);
  });
  
export const invitedUserActivateService = data =>
  new Promise((resolve, reject) => {
    client.post(API.invitedUserActivate, data).then(resolve).catch(reject);
  });

  export const getCompanyLogoByUserIdService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyLogoByUserId, {params: data}).then(resolve).catch(reject);
  });
  
  export const forgotPasswordCheckService = data =>
  new Promise((resolve, reject) => {
    client.get(API.forgotPasswordCheck, {params: data}).then(resolve).catch(reject);
  });
  