import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImageWrapper from '../../../Common/Components/BackgroundImageWrapper/BackgroundImageWrapper';
import GoBackButton from '../../../Common/Components/GoBackButton';
import { useWindowSize } from '../../../utils/helpers';
import notAuthorizedImg from './notAuthorized.svg';

const NotAuthorized = () => {
  const history = useHistory();
  const [screenWidth, screenHeight] = useWindowSize();
  const [headerHeight, setHeaderHeight] = useState();

  useEffect(() => {
    const headerHeight = document.getElementById('header')?.getBoundingClientRect()?.height;
    setHeaderHeight(headerHeight);
  }, [screenWidth]);

  const onClick = () => {
    history.push(`/assessments/`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${headerHeight}px)`
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '1.6rem 3.2rem',
          alignItems: 'center',
          width: 'fit-content'
        }}
        onClick={onClick}
      >
        <GoBackButton style={{ marginRight: '0px', cursor: 'pointer' }} />
        <text
          style={{
            fontFamily: 'Jost-400',
            fontSize: '18px',
            lineHeight: '26px',
            color: '#585858',
            cursor: 'pointer'
          }}
        >
          Back to assessments
        </text>
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}
      >
        <BackgroundImageWrapper
          image={notAuthorizedImg}
          header="You Need Authorization"
          subtext="Please ask your teammates to sign you to the assessment so that you can see the details."
        />
      </div>
    </div>
  );
};
export default NotAuthorized;
