import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AssessmentDetailHeader.module.css';
import { ReactComponent as Mail } from './mail.svg';
import GoBackButton from '../../../Common/Components/GoBackButton';
import link from './link.svg';
import settings from './settings.svg';
import mobileSettings from './settingsMobile.svg';
import ProgressBarComponent from './ProgressBarComponent';
import AssessmentSettingsModal from '../Modals/AssessmentSettingsModal';
import {
  assessmentObjStatusSelector,
  companyAssessmentNameSelector,
  fiveCandidatesExistFlagSelector,
  industryAvgSelector,
  ratingSelector,
  rootAssessmentNameSelector,
  top5AvgSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import { currentPlanRedux } from '../../../redux/plan/selectors';
import { useWindowSize } from '../../../utils/helpers';
import PurchaseAddOnSuccessModal from '../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import FreeTrialModal from '../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import HeadsUpModal from '../../../Auth/Modals/HeadsUpModal';
import ContactSalesSuccess from '../../../Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import { publishEnabled } from '../../../redux/addAssessment/selectors';
import { setInviteCandidatesModalShow } from '../../../redux/assessmentDetail/slice';
import Tooltip from '../../../Common/Components/Tooltip';
import InviteCandSuccessModal from '../Modals/InviteCandSuccessModal';
import { mailRejected, sentMailSuccess } from '../../../redux/assessmentsVol2/selectors';
import InfoBanner from '../../../Common/Components/InfoBanner';
import TextOverflow from '../../../Common/TextOverflow/TextOverflow';
import { fetchShortUrlAction } from '../../../redux/assessmentDetailVol2/actions';

export function AssessmentDetailHeaderComp() {
  const history = useHistory();
  const dispatch = useDispatch();
  const indAvgRedux = useSelector(industryAvgSelector);
  const top5AvgRedux = useSelector(top5AvgSelector);
  const companyAssessmentNameRedux = useSelector(companyAssessmentNameSelector);
  const fiveCandidatesExistFlagRedux = useSelector(fiveCandidatesExistFlagSelector);

  // Height calculation for assm sett modal in mobile
  const [allowedHeight, setAllowedHeight] = useState();

  /* Might be unnecessary */
  const assessmentObjStatusRedux = useSelector(assessmentObjStatusSelector);
  const rootAssessmentNameRedux = useSelector(rootAssessmentNameSelector);
  const ratingRedux = useSelector(ratingSelector);
  /* ----------------------- */

  // Assessment settings modal related states
  const [showModal, setModal] = useState(false);
  const [successModalClosedReadyToGo, setSuccessModalClosedReadyToGo] = useState(false);
  const myPlan = useSelector(currentPlanRedux);
  const [headsUpShow, setHeadsUpShow] = useState(false);
  const [freeTrialShow, setFreeTrialShow] = useState(false);
  const publishEnabledCheck = useSelector(publishEnabled);
  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const mailSuccess = useSelector(sentMailSuccess);
  const mailRejectedRedux = useSelector(mailRejected);

  const [copyLinkFlag, setCopyLinkFlag] = useState(false);

  const [disabledFlag, setDisabledFlag] = useState(assessmentObjStatusRedux !== 10);

  useEffect(() => {
    setDisabledFlag(assessmentObjStatusRedux !== 10);
  }, [assessmentObjStatusRedux]);

  const handleInviteCandClicked = async () => {
    dispatch(setInviteCandidatesModalShow(true));
  };

  const [screenWidth, screenHeight] = useWindowSize();
  const [allowedWidth, setAllowedWidth] = useState();

  const MOBILE_THRESHOLD = 576;

  const { id } = useParams();
  const assessmentId = id;

  const openModal = () => {
    setModal(true);
  };

  function closeModal() {
    setModal(false);
  }

  function editUsers() {
    history.push(`/edit-assessment-user/${assessmentId}`);
  }

  const AssessmentNameDiv = () => {

    useEffect(() => {
      const container = document
        ?.getElementById('assessmentDetailHeader')
        ?.getBoundingClientRect()?.width;
      const containerH = document
        ?.getElementById('assessmentDetailHeader')
        ?.getBoundingClientRect()?.height;
      const bar = document?.getElementById('barInHeader')?.getBoundingClientRect()?.width;
      const button = document
        ?.getElementById('InviteCandidatesButton')
        ?.getBoundingClientRect()?.width;
      setAllowedHeight(screenHeight - containerH);
      if (bar) {
        setAllowedWidth(container - bar - button - 200);
      } else {
        setAllowedWidth(container - button - 140);
      }
    }, [screenWidth]);

    return (
      <>
        <div className={styles.assessmentNameWrapper}>
          <GoBackButton
            style={{ width: '30px', height: '30px' }}
            onClick={() => {
              history.push(`/assessments/`);
            }}
          />
          <TextOverflow
            text={companyAssessmentNameRedux}
            // text="fgdhsbxnajzkmdfjhgbjkdlşjhnbkvmglfdşckvfnbjglkvmdşcvfgnbjklvmdcşsfvnjbklvc"
            textClassName={styles.assessmentName}
            itemId="assessmentName-header"
            width={allowedWidth}
          />
          <div className={styles.imgWrapper}>
            <img
              alt=""
              src={screenWidth > MOBILE_THRESHOLD ? settings : mobileSettings}
              className={styles.settings}
              onClick={openModal}
            />
          </div>
        </div>
      </>
    );
  };

  const InviteCandidatesDiv = () => {
    return screenWidth <= MOBILE_THRESHOLD ? (
      <div
        id="InviteCandidatesButton"
        className={`${styles.inviteCandidatesButton} ${styles.smallInviteButton}`}
        onClick={handleInviteCandClicked}
        style={disabledFlag ? { pointerEvents: 'none', opacity: '0.4' } : {}}
      >
        <Mail />
      </div>
    ) : (
      <Tooltip show={disabledFlag} text="Your assessment is not published." direction="left">
        <div
          id="InviteCandidatesButton"
          onClick={handleInviteCandClicked}
          className={`${styles.inviteCandidatesButton}`}
          style={disabledFlag ? { pointerEvents: 'none', opacity: '0.4' } : {}}
        >
          <Mail />
          <div className={styles.inviteCandText}>Invite Candidates</div>
        </div>
      </Tooltip>
    );
  };

  /* Ignore below - popup animation related states */

  const [showModal2, setShowModal2] = useState(false);
  useEffect(() => {
    if (showModal) {
      setShowModal2(true);
    } else {
      setTimeout(() => {
        setShowModal2(false);
      }, 300);
    }
  }, [showModal]);

  const [mailSuccess2, setMailSuccess2] = useState(false);
  useEffect(() => {
    if (mailSuccess || mailRejectedRedux) {
      setMailSuccess2(true);
    } else {
      setTimeout(() => {
        setMailSuccess2(false);
      }, 100);
    }
  }, [mailSuccess, mailRejectedRedux]);

  const [headsUpShow2, setHeadsUpShow2] = useState(false);
  useEffect(() => {
    if (headsUpShow) {
      setHeadsUpShow2(true);
    } else {
      setTimeout(() => {
        setHeadsUpShow2(false);
      }, 100);
    }
  }, [headsUpShow]);

  const [purchaseSuccess2, setPurchaseSuccess2] = useState(false);
  useEffect(() => {
    if (purchaseSuccess) {
      setPurchaseSuccess2(true);
    } else {
      setTimeout(() => {
        setPurchaseSuccess2(false);
      }, 100);
    }
  }, [purchaseSuccess]);

  const [freeTrialShow2, setFreeTrialShow2] = useState(false);
  useEffect(() => {
    if (freeTrialShow) {
      setFreeTrialShow2(true);
    } else {
      setTimeout(() => {
        setFreeTrialShow2(false);
      }, 100);
    }
  }, [freeTrialShow]);

  const [contactSalesSuccessShow2, setContactSalesSuccessShow2] = useState(false);
  useEffect(() => {
    if (contactSalesSuccessShow) {
      setContactSalesSuccessShow2(true);
    } else {
      setTimeout(() => {
        setContactSalesSuccessShow2(false);
      }, 100);
    }
  }, [contactSalesSuccessShow]);

  return (
    <>
      {screenWidth > 960 ? (
        <div className={styles.headerWrapper} id="assessmentDetailHeader">
          <AssessmentNameDiv />
          <ProgressBarComponent
            id="barInHeader"
            infoPresent
            enoughScores={fiveCandidatesExistFlagRedux}
            assessmentAverage={top5AvgRedux}
            industryAverage={indAvgRedux}
          />
          <InviteCandidatesDiv />
        </div>
      ) : (
        <div className={styles.headerWrapper} id="assessmentDetailHeader">
          <AssessmentNameDiv />
          <InviteCandidatesDiv />
        </div>
      )}
      {/* Popups below */}
      {mailSuccess2 && <InviteCandSuccessModal show={mailSuccess || mailRejectedRedux} />}
      {showModal2 && (
        <AssessmentSettingsModal
          allowedSettingsModalHeight={allowedHeight}
          show={showModal}
          onModalHide={() => closeModal()}
          editUsers={() => {
            editUsers();
          }}
          AssesmentTitle={companyAssessmentNameRedux}
          AssessmentName={rootAssessmentNameRedux} // category
          purchaseSuccess={successModalClosedReadyToGo}
          setAssessmentUnavailable={
            myPlan?.name === 'Free Trial' ? setFreeTrialShow : setHeadsUpShow
          }
        />
      )}
      {/* START OF ASSESSMENT ADD-ON PURCHASE  */}
      {headsUpShow2 && (
        <HeadsUpModal
          addOnCode={10}
          show={headsUpShow}
          onHide={() => {
            setHeadsUpShow(false);
          }}
          title="Hello there!"
          description={
            !myPlan?.isCustom
              ? `Your current plan seems to include ${
                  publishEnabledCheck?.publishAssessmentRightCount
                } published assessment${publishEnabledCheck?.publishAssessmentRightCount > 1 ? `s` : ''}.
          Add an additional published assessment only for $${publishEnabledCheck?.price}.`
              : `Your plan seems to include ${publishEnabledCheck?.publishAssessmentRightCount} published assessments.
          Please contact our sales team to upgrade your plan.`
          }
          isCustom={myPlan?.isCustom}
          setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setPurchaseSuccess}
          tryAgain={() => console.log('assessment purchase try again')}
        />
      )}
      {/* Purchase AddOn Success */}
      {purchaseSuccess2 && (
        <PurchaseAddOnSuccessModal
          show={purchaseSuccess}
          onHide={() => {
            setPurchaseSuccess(false);
            setSuccessModalClosedReadyToGo(true);
          }}
          addOnCode={10}
        />
      )}
      {freeTrialShow2 && (
        <FreeTrialModal
          show={freeTrialShow}
          onHide={() => setFreeTrialShow(false)}
          addOnCode={10}
        />
      )}
      {contactSalesSuccessShow2 && (
        <ContactSalesSuccess
          icon="tick"
          show={contactSalesSuccessShow}
          onHide={() => setContactSalesSuccessShow(false)}
          title="Your request has been received!"
          description="One of our experts will contact you shortly."
        />
      )}
      {/* END OF ASSESSMENT ADD-ON PURCHASE */}
    </>
  );
}

const AssessmentDetailHeader = React.memo(AssessmentDetailHeaderComp);

export default AssessmentDetailHeader;
