export default {
  allAssessments: [],
  items: [],
  selectedAssess: [],
  selectedHiringFunnel: [],
  filteredItems: [],
  filterData: null,
  filterDataStatus: null,
  activeItemCount: 0,
  archiveItemCount: 0,
  rootAssessmentList: [],
  questionList: [],
  assetssmentAts: [],
  newAssessment: null,
  isLoading: false,
  error: null,
  assessmentList2: null,
  // assessmentCountsVol2: [],
  publishedCount: 0,
  unpublishedCount: 0,
  archivedCount: 0,
  activeTab: 'active',
  getWidth: false
};
