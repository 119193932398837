import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from '../../../../utils/helpers';
import {
  abortDowngradeSuccessShow,
  asAddOnsRedux,
  caAddOnsRedux,
  currentPlanRedux,
  currentUsageRedux,
  errorInPlan,
  errorTextInPlan,
  planUnsubscribedModalShow,
  unsubscribeModalShow
} from '../../../../redux/plan/selectors';
import UserIcon from '../../../../Common/Components/UserIcon';
import styles from './PlanTab.module.css';
import {
  setAbortDowngradeSuccessShow,
  setPlanUnsubscribedModalShow,
  setShowPackageDetail,
  setUnsubscribeModalShow,
  setErrorInPlan
} from '../../../../redux/plan/slice';
import TextOverflow from '../../../../Common/TextOverflow/TextOverflow';
import ProgressCircle from '../../../../Candidate/Components/AnimatedProgressBar';
import Tooltip from '../../../../Common/Components/Tooltip';
import AddOnCard from './AddOnCard';
import { userSelector } from '../../../../redux/auth/selectors';
import {
  getAddOnsAction,
  getCurrentPlanAction,
  getCurrentUsageAction
} from '../../../../redux/plan/actions';
import UnsubscribeSuccessModal from '../../../Modals/UnsubscribeSuccessModal';
import UnsubscribeModal from '../../../Modals/UnsubscribeModal';
import PlanUnsubscribedSuccess from '../../../Modals/PlanUnsubscribedSuccess';
import HeadsUpModal from '../../../Modals/HeadsUpModal';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import Skeleton from '../../../../Common/Components/Skeleton';
import { ReactComponent as Error } from '../../../../images/errorSubmit.svg';

const PlanTab = () => {
  const dispatch = useDispatch();
  const [screenW] = useWindowSize();
  const user = useSelector(userSelector);
  const [usageLoading, setUsageLoading] = useState(true);
  const [planLoading, setPlanLoading] = useState(true);


  // Payment related selectors
  const currentPlan = useSelector(currentPlanRedux);
  const currentUsage = useSelector(currentUsageRedux);
  const addOnCa = useSelector(caAddOnsRedux);
  const addOnAs = useSelector(asAddOnsRedux);
  const unsubscribeShow = useSelector(unsubscribeModalShow);
  const unsubscribePlanSuccess = useSelector(planUnsubscribedModalShow);
  const abortDowngradeSuccess = useSelector(abortDowngradeSuccessShow);
  const error = useSelector(errorInPlan);
  const errorText = useSelector(errorTextInPlan);

  // Current usage data states
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [untilDate, setUntilDate] = useState('');

  // Payment related states
  const [contactSalesForUpgrade, setContactSalesForUpgrade] = useState(false);
  const [planNameWidth, setPlanNameWidth] = useState();
  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);

  useEffect(async () => {
    const data = {
      CompanyId: user.companyId
    };
    const resp1 = await dispatch(getCurrentPlanAction(data));
    const resp2 = await dispatch(getCurrentUsageAction(data));
    if (resp1.meta.requestStatus !== 'fulfilled') {
      setPlanLoading(true);
    } else {
      setPlanLoading(false);
    }
    if (resp2.meta.requestStatus !== 'fulfilled') {
      setUsageLoading(true);
    } else {
      setUsageLoading(false);
    }

    dispatch(getAddOnsAction(data));
  }, []);

  useEffect(() => {
    // to set the name and surname of unsubscribed user for userIcon
    if (currentPlan?.companyFuturePlan?.futurePlanUser) {
      const nameSurname = currentPlan?.companyFuturePlan?.futurePlanUser?.split(' ');
      const len = nameSurname?.length;
      setName(nameSurname[0]);
      setSurname(nameSurname[len - 1]);
    }
    if (currentPlan?.endDate) {
      const temp = format(parseISO(currentPlan?.endDate), 'MMM dd, yyyy');
      setUntilDate(temp);
    }
  }, [currentPlan]);

  useEffect(() => {
    const planContainerW = document
      .getElementById('plan-container-settings')
      ?.getBoundingClientRect()?.width;
    // 151: upgrade button width, 60: padding right & left, 20: gap
    if (screenW > 720) {
      // above 720 px, button is on the same row, below flex-direction is column
      setPlanNameWidth(planContainerW - 151 - 60 - 20);
    } else {
      setPlanNameWidth(planContainerW - 60);
    }
  }, [screenW]);

  const UsageRow = ({ numerator, denominator, tooltipText }) => {
    return (
      <div className={styles.barRow}>
        <div className={styles.percentage}>{`${
          numerator > denominator ? denominator : numerator
        }/${denominator}`}</div>
        <div className={styles.circleContainer}>
          <ProgressCircle
            score={
              denominator ? (numerator > denominator ? 100 : (numerator / denominator) * 100) : 0
            }
          />
        </div>
        {!isMobile ? (
          <Tooltip text={tooltipText}>
            <div className={styles.tooltipIcon} />
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const CurrentPlanContainer = () => {
    return (
      <div className={styles.planContainer} id="plan-container-settings">
        <div className={styles.buttonRow}>
          <div className={styles.buttonRowHeader}>
            <span className={styles.subheader}>Current Plan</span>
            <TextOverflow
              width={planNameWidth}
              itemId="plan-name-in-plan-tab"
              textClassName={styles.planName}
              text={currentPlan?.isCustom ? 'Custom Enterprise Plan' : currentPlan?.name}
            />
          </div>

          <button
            onClick={() => {
              if (currentPlan?.isCustom) {
                setContactSalesForUpgrade(true);
              } else {
                dispatch(setShowPackageDetail(true));
              }
            }}
            className={styles.upgradeButton}
            type="button"
          >
            {currentPlan?.name === 'Free Trial'
              ? 'Subscribe now'
              : currentPlan?.isCustom
              ? 'Upgrade Plan'
              : 'Upgrade now'}
          </button>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>Subscription</span>
            <span className={styles.text}>
              {currentPlan?.name === 'Free Trial'
                ? '14-days'
                : currentPlan?.period === '30'
                ? 'Monthly'
                : 'Annualy'}
            </span>
          </div>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>Users</span>
            <span className={styles.text}>
              {currentPlan?.users ? currentPlan.users : 'Unlimited'}
            </span>
          </div>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>Assessments</span>
            <span className={styles.text}>
              {currentPlan?.currentBundleItem?.map(e => e.code === 10 && `${e.count}`)}
            </span>
          </div>
          <div className={styles.tableColumn}>
            <span className={styles.subheader}>Candidates</span>
            <span className={styles.text}>
              {currentPlan?.currentBundleItem?.map(e => e.code === 20 && `${e.count}`)}
            </span>
          </div>
        </div>
        <div className={styles.footerRow}>
          {currentPlan?.isCanceled ? (
            <div className={styles.unsubscribedRow}>
              <div className={styles.unsubscribedInfo}>
                <div>
                  <span className={styles.boldText}>Unsubscribed </span>
                  <span
                    className={styles.lightText}
                  >{`by ${currentPlan?.companyFuturePlan?.futurePlanUser}`}</span>
                </div>
                {!isMobile ? (
                  <UserIcon
                    textClassName={styles.userIconText}
                    className={styles.userIcon}
                    comment
                    name={name}
                    surname={surname}
                  />
                ) : null}
              </div>
              <span className={styles.validInfoText}>*Available until next billing period</span>
            </div>
          ) : (
            <div className={styles.unsubscribeRow}>
              {currentPlan?.name === 'Free Trial' ? (
                <span style={{ textAlign: 'left' }} className={styles.renewText}>
                  Free trial ends automatically on {untilDate}
                </span>
              ) : (
                <div className={styles.renewalRow}>
                  <span
                    className={styles.text}
                    onClick={() => {
                      dispatch(setUnsubscribeModalShow(true));
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Unsubscribe
                  </span>
                  {!(currentPlan?.companyFuturePlan?.planOrder < currentPlan?.order) ||
                    (!currentPlan?.companyFuturePlan?.planOrder ? (
                      <span className={styles.renewText}>Automatically renews on {untilDate}</span>
                    ) : (
                      <span className={styles.renewText}>
                        Will be downgraded to {currentPlan?.companyFuturePlan?.planName} on&nbsp;
                        {untilDate}
                      </span>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const CurrentUsageContainer = () => {
    return (
      <div className={styles.usageContainer}>
        <span className={styles.subheader}>Current Usage</span>
        <div className={styles.usageGroup}>
          <span className={styles.text}>Assessments</span>
          <div className={styles.usageRow}>
            <div className={styles.usageSubheader}>Published</div>
            <UsageRow
              numerator={currentUsage?.usedAssessmentCount}
              denominator={currentUsage?.allowedAssessmentCount}
              tooltipText="The number of published assessments."
            />
          </div>
        </div>

        <div className={styles.usageGroup}>
          <span className={styles.text}>Candidates</span>
          <div className={styles.usageRow}>
            <div className={styles.usageSubheader}>Applied</div>

            <UsageRow
              numerator={currentUsage?.usedCandidates}
              denominator={currentUsage?.allowedCandidates}
              tooltipText="Only scored candidates count!"
            />
          </div>
          <div className={styles.usageRow}>
            <div className={styles.usageSubheader}>Unlocked</div>

            <UsageRow
              numerator={currentUsage?.usedNewOpsCandidate}
              denominator={currentUsage?.allowedNewOpsCandidate}
              tooltipText="NewOpp Candidates"
            />
          </div>
          <div className={styles.usageRow}>
            <div className={styles.usageSubheader}>Request Accepted</div>
            <UsageRow
              numerator={currentUsage?.usedRequestCandidate}
              denominator={currentUsage?.allowedRequestCandidate}
              tooltipText="Coensio Candidates"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.header}>Plan</span>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          {!planLoading ? <CurrentPlanContainer /> : <Skeleton type="current-plan" />}
          {screenW <= 1040 ? (
            !usageLoading ? (
              <CurrentUsageContainer />
            ) : (
              <Skeleton type="current-usage" />
            )
          ) : null}
          <>
            {addOnAs?.length > 0 && <AddOnCard addOnName="assessments" items={addOnAs} />}
            {addOnCa?.length > 0 && <AddOnCard addOnName="candidates" items={addOnCa} />}
          </>
        </div>
        {screenW > 1040 ? (
          <div className={styles.rightColumn}>
            {!usageLoading ? <CurrentUsageContainer /> : <Skeleton type="current-usage" />}
          </div>
        ) : null}
      </div>

      <InfoBanner
        text={errorText || 'An error has occurred, please try again '}
        show={error}
        CustomIcon={Error}
        setStatus={type => {
          dispatch(setErrorInPlan(type));
        }}
      />
      <HeadsUpModal
        addOnCode={10}
        show={contactSalesForUpgrade}
        onHide={() => setContactSalesForUpgrade(false)}
        title="Hello there!"
        description="Do you want to contact our team to upgrade your current plan?"
        isCustom
        setSuccess={setContactSalesSuccessShow}
      />
      <contactSalesSuccess
        icon="tick"
        show={contactSalesSuccessShow}
        onHide={() => setContactSalesSuccessShow(false)}
        title="Your request has been received!"
        description="One of our experts will contact you shortly."
      />
      {/* Unsubscribe Plan confirm modal */}
      <UnsubscribeModal
        show={unsubscribeShow}
        onHide={() => dispatch(setUnsubscribeModalShow(false))}
        title="Are you sure?"
        description="The search for the right one is far from over. Are you sure that you want to unsubscribe?
        "
      />

      {/* Unsubscribe Plan Success Modal */}
      <PlanUnsubscribedSuccess
        show={unsubscribePlanSuccess}
        onHide={() => dispatch(setPlanUnsubscribedModalShow(false))}
      />

      {/* Abort downgrade Success Modal */}
      <UnsubscribeSuccessModal
        show={abortDowngradeSuccess}
        onHide={() => dispatch(setAbortDowngradeSuccessShow(false))}
        title="Successfully Cancelled!"
        footer={`*You will continue to access features of ${currentPlan?.name} on your next billing period.`}
        icon="tick"
      />
    </div>
  );
};

export default PlanTab;
