import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../../Common/Components/Header';
import { getFilteredUserIdAction } from '../../../redux/candidateDetail/actions';
import { getParam } from '../../../utils/helpers';
import CandidateDetailComponent from '../../Components/CandidateDetail/CandidateDetailComponent';
import styles from './CandidateDetailPage.module.css';

import goBackArrow from './goBackArrow.svg';
import lightLeft from './leftScrollLight.svg';
import left from './leftScroll.svg';
import lightright from './rightScrollLight.svg';
import right from './rightScroll.svg';
import mixpanel from '../../../utils/mixpanel';
import { userSelector } from '../../../redux/auth/selectors';
import {
  candidatePositionsSelector,
  candidateProfileSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../redux/candidateDetail/selectors';
import { resetToInitial } from '../../../redux/candidateDetail/slice';
import { genericMixpanelCandidateDetailEventGenerator } from '../../Components/CandidateDetail/helpers';

const CandidateDetailPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [nextCandId, setNextCandId] = useState();
  const [prevCandId, setPrevCandId] = useState();

  async function fetchNextAndPrevCandidates() {
    const searchParamUrl = getParam(location.search, 'searchParam');
    const orderTypeUrl = getParam(location.search, 'orderType');
    const funnelIdUrl = getParam(location.search, 'funnelId');
    const startDateUrl = getParam(location.search, 'startDate');
    const endDateUrl = getParam(location.search, 'endDate');
    const startScoreUrl = getParam(location.search, 'startScore');
    const endScoreUrl = getParam(location.search, 'endScore');
    // const pageNumberUrl = getParam(location.search, 'pageNumber');
    const arriveTypeUrl = getParam(location.search, 'arriveType');

    const industryListUrl = getParam(location.search, 'industryList');
    const schoolListUrl = getParam(location.search, 'schoolList');
    const industryIncludeTypeUrl = getParam(location.search, 'industryIncludeType');
    const experiencesUrl = getParam(location.search, 'experiences');
    const workTypesUrl = getParam(location.search, 'workTypes');
    const nationalitiesUrl = getParam(location.search, 'workPermit');
    const currencyUrl = getParam(location.search, 'currency');
    const minSalaryUrl = getParam(location.search, 'minSalary');
    const maxSalaryUrl = getParam(location.search, 'maxSalary');

    let industryListData;
    const industryIncludeTypeData = parseInt(industryIncludeTypeUrl, 10);
    let schoolListData;
    let screeningExperiencesData;
    let screeningWorkTypesData;
    let screeningNationalitiesData;

    const screeningSalaryCurrencyData = parseInt(currencyUrl, 10);
    const screeningSalaryMinValueData = parseInt(minSalaryUrl, 10) || null;
    const screeningSalaryMaxValueData = parseInt(maxSalaryUrl, 10) || null;

    if (industryListUrl) {
      industryListData = [];
      industryListUrl.split(',')?.forEach(x => industryListData.push(parseInt(x, 10)));
    }

    if (schoolListUrl) {
      schoolListData = [];
      schoolListUrl.split(',')?.forEach(x => schoolListData.push(parseInt(x, 10)));
    }

    if (experiencesUrl) {
      screeningExperiencesData = [];
      experiencesUrl.split(',')?.forEach(x => screeningExperiencesData.push(parseInt(x, 10)));
    }

    if (workTypesUrl) {
      screeningWorkTypesData = [];
      workTypesUrl.split(',')?.forEach(x => screeningWorkTypesData.push(parseInt(x, 10)));
    }

    if (nationalitiesUrl) {
      screeningNationalitiesData = [];
      nationalitiesUrl.split(',')?.forEach(x => screeningNationalitiesData.push(parseInt(x, 10)));
    }

    const data = {
      candidateId: id,
      companyAssessmentId: getParam(location.search, 'defaultAssessmentId'),
      startDate: parseInt(startDateUrl, 10) || (parseInt(startDateUrl, 10) === 0 ? 0 : null),
      endDate: parseInt(endDateUrl, 10) || 0,
      startScore: parseInt(startScoreUrl, 10) || 0,
      endScore: parseInt(endScoreUrl, 10) || 100,
      funnelId: funnelIdUrl,
      orderType: parseInt(orderTypeUrl, 10) || 3,
      search: decodeURIComponent(searchParamUrl),
      arriveTypes: parseInt(arriveTypeUrl, 10) ? [parseInt(arriveTypeUrl, 10)] : [],
      pageNumber: 0,
      items: 0,
      schoolList: schoolListData || null,

      industry: industryListData
        ? {
            industryList: industryListData,
            industryInclude: industryIncludeTypeData
          }
        : null,
      screening:
        screeningExperiencesData ||
        screeningWorkTypesData ||
        screeningNationalitiesData ||
        screeningSalaryMinValueData ||
        screeningSalaryMaxValueData
          ? {
              experiences: screeningExperiencesData,
              workTypes: screeningWorkTypesData,
              nationalities: screeningNationalitiesData,

              salary:
                screeningSalaryMinValueData || screeningSalaryMaxValueData
                  ? {
                      currency: screeningSalaryCurrencyData,
                      minSalary: screeningSalaryMinValueData || 0,
                      maxSalary: screeningSalaryMaxValueData || 1000000
                    }
                  : null
            }
          : null

    };

    const resp = await dispatch(getFilteredUserIdAction(data));
    setNextCandId(resp?.payload?.nextUserId);
    setPrevCandId(resp?.payload?.previousUserId);
  }

  useEffect(() => {
    fetchNextAndPrevCandidates();

    return () => {
      dispatch(resetToInitial());
    };
  }, []);

  // Mixpanel Candidate Detail - Pageview
  // #region
  const currentUser = useSelector(userSelector);
  const positionsList = useSelector(candidatePositionsSelector);
  const candidateProfile = useSelector(candidateProfileSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  useEffect(() => {
    if (
      currentUser &&
      positionsList &&
      positionsList?.length > 0 &&
      candidateProfile &&
      !loadingForPersonalInfoRedux
    ) {
      genericMixpanelCandidateDetailEventGenerator(
        'Candidate Detail - Pageview',
        currentUser,
        positionsList,
        candidateProfile,
        loadingForPersonalInfoRedux,
        personalInfoExperienceRedux,
        personalInfoNationalityRedux,
        personalInfoSalaryRedux,
        personalInfoWorkTypeRedux
      );
    }
  }, [currentUser, positionsList, candidateProfile, loadingForPersonalInfoRedux]);

  // #endregion

  return (
    <div className={styles.PageWrapper} id="candidate-detail-page-wrapper">
      <Header />
      <CandidateDetailNavigation prevCandId={prevCandId} nextCandId={nextCandId} />
      <CandidateDetailComponent />
    </div>
  );
};
export default CandidateDetailPage;

const CandidateDetailNavigation = ({ prevCandId, nextCandId }) => {
  const notRequestCandidate = !getParam(location.search, 'requestStatus');

  function goBackBehavior() {
    history.back();
  }

  function switchToPreviousCandidate() {
    if (prevCandId) {
      window.location.replace(`/candidate-detail/${prevCandId}${location.search}`);
    }
  }

  function switchToNextCandidate() {
    if (nextCandId) {
      window.location.replace(`/candidate-detail/${nextCandId}${location.search}`);
    }
  }

  const [onCurrentPosition, setOnCurrentPosition] = useState(false);

  function updateStatesOnAssessmentChange() {
    const currentAssessId = getParam(location.search, 'currentAssessmentId');
    const defAssessId = getParam(location.search, 'defaultAssessmentId');
    if (currentAssessId === defAssessId) {
      setOnCurrentPosition(true);
    } else {
      setOnCurrentPosition(false);
    }
  }

  useEffect(() => {
    updateStatesOnAssessmentChange();
    window.addEventListener('locationchange', updateStatesOnAssessmentChange);
    return () => {
      window.removeEventListener('locationchange', updateStatesOnAssessmentChange);
    };
  }, []);

  return (
    <div className={styles.ButtonRow}>
      <div className={styles.GoBackWrapper}>
        <img
          src={goBackArrow}
          className={styles.GoBackButton}
          alt="goBack"
          onClick={() => goBackBehavior()}
        />
        Candidate Profile
      </div>
      <div className={styles.NextPrevWrapper}>
        <img
          src={prevCandId && onCurrentPosition && notRequestCandidate ? left : lightLeft}
          alt="previousCandidate"
          className={styles.NextPrevButton}
          style={
            prevCandId && onCurrentPosition && notRequestCandidate
              ? { cursor: 'pointer' }
              : { pointerEvents: 'none' }
          }
          onClick={() => switchToPreviousCandidate()}
        />
        <img
          src={nextCandId && onCurrentPosition && notRequestCandidate ? right : lightright}
          alt="nextCandidate"
          className={styles.NextPrevButton}
          style={
            nextCandId && onCurrentPosition && notRequestCandidate
              ? { cursor: 'pointer' }
              : { pointerEvents: 'none' }
          }
          onClick={() => switchToNextCandidate()}
        />
      </div>
    </div>
  );
};
